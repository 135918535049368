import { useState } from "react";
import { TextField, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import style from "../../../../Style/addData.module.css";
import axios from "axios";
import { API_CONSTANTS } from "../../../../API_CONSTANTS";
import { ToastContainer, toast } from "react-toastify";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import toArrayBuffer from "to-array-buffer";

export default function SendNotifications() {
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [images, setImages] = useState("");
  // uploading the image to get the link
  // upload eprescription to get the link
  const handleUpload = (e) => {
    const [file] = e.target.files || e.dataTransfer.files;
    if (file.size <= 4000000) {
      if (
        file?.type === "image/jpeg" ||
        file?.type === "application/pdf" ||
        file?.type === "image/png" ||
        file?.type === "image/webp"
      ) {
        setImgLoading(true);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        //preview onload function.
        reader.onload = async () => {
          // this is the base64 data
          const fileRes = btoa(reader.result);
          const base64Image = toArrayBuffer(fileRes);
          axios
            .post(
              `${API_CONSTANTS.BASEURL}admin/image-upload-api/user/1/type/new`,
              base64Image,
              {
                headers: API_CONSTANTS.imgheaders(),
              }
            )
            .then((response) => {
              setImgLoading(false);
              setImages(response.data.response.url);
              toast(`File successfully uploaded`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            })
            .catch((error) => {
              setImgLoading(false);
              toast(`Something went wrong please try again later`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "error",
              });
            });
        };
      } else {
        setImgLoading(false);
        if (file?.type) {
          toast(
            `🥺 Sorry, This file format does not supported, Choose the file with you last format: ".jpg, .jpeg, .png, .webp, .pdf"`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "error",
            }
          );
        }
      }
    } else {
      setImgLoading(false);
      if (file?.type) {
        toast(`😵 File size is exceeded. Max size is 4mb `, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "error",
        });
      }
    }
  };
  // hadnle sending notifications
  const handleSendingNotifications = () => {
    setLoading(true);
    axios
      .post(
        API_CONSTANTS.BASEURL_1 + "admin/sendCustomNotification",
        {
          title: title,
          body: text,
          thumbnailImage: images,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setLoading(false);
        setTitle("");
        setText("");
        setImages("");
        toast("Notification has send successfully", {
          type: "success",
          autoClose: 2000,
        });
      })
      .catch((error) => {
        setLoading(false);
        toast("Something went wrong please try again", {
          type: "error",
          autoClose: 2000,
        });
      });
  };
  return (
    <section className="container">
      <div className="row py-4">
        <p className="my-4 text-dark h3">Send Notifications:</p>
        <div className="col-6">
          <div className={style.formFields}>
            <TextField
              label="Notification Title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
              placeholder="Notification Text"
              className={style.notificationTextArea}
              rows={"5"}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <span className={style.notificationsImgContainer}>
              {images && (
                <img
                  src={images}
                  alt="esaafi-upload-notification-image"
                  className={style.notificationImg}
                />
              )}
              {images && (
                <Button
                  className={style.clearImgBtn}
                  onClick={() => setImages("")}
                >
                  {" "}
                  clear
                </Button>
              )}
            </span>
            <Button
              variant={"text"}
              className="d-flex justify-content-start"
              style={{ position: "relative" }}
            >
              <InsertPhotoIcon />
              <input
                type="file"
                className={style.uploadingFile}
                // hidden
                onChange={(e) => handleUpload(e)}
                accept={".jpg, .jpeg, .png, .webp, .pdf"}
              />
              {imgLoading ? (
                <>
                  Loading
                  <div
                    className="spinner-border text-blue spinner-border-sm ms-2"
                    role="status"
                  ></div>
                </>
              ) : (
                <span>{""}</span>
              )}
            </Button>
            <LoadingButton
              variant="contained"
              loading={loading}
              type="button"
              onClick={handleSendingNotifications}
              className={style.sendNotificationBtn}
              disabled={text && title ? false : true}
            >
              Submit
            </LoadingButton>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}
