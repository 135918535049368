import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_CONSTANTS } from "../../../API_CONSTANTS";

export const getProfile = createAsyncThunk("getProfile", async (paramsID) => {
  const response = await axios
    .get(`${API_CONSTANTS.BASEURL}admin/patients/${paramsID}`, {
      headers: API_CONSTANTS.headers(),
    })
    .then((res) => res.data.response.Item);

  return response;
});
export const getProfileSlice = createSlice({
  name: "getPatientProfile",
  initialState: {
    value: {
      Fname: "",
      // Id: '',
      phoneNo: "",
      email: "",
      gender: "",

      is_blocked: false,
      profiles: {
        self: {
          url: "https://img.icons8.com/material/72/000000/user-male-circle--v1.png",
          dob: "",
        },
        other: [],
      },
    },
  },
  reducers: {
    block: (state) => {
      state.value.is_blocked = true;
    },
    unblock: (state) => {
      state.value.is_blocked = false;
    },
  },
  extraReducers: {
    [getProfile.pending]: (state) => {
      state.loading = true;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.value = payload;
    },
    [getProfile.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const getPatientProfileReducer = getProfileSlice.reducer;
