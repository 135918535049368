import React from 'react'
import Modal from 'react-modal'

//
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#ECFCFB',
  },
}

//
export default function ImageViewer({ images, patientName, date }) {
  const [imgItem, setImgItem] = React.useState(0)
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const recordLength = images.length - 1
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }
  return (
    <>
      <span className='image-view' onClick={openModal}>
        View File
      </span>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
        ariaHideApp={false}
      >
        <div className='row'>
          <div className='col-12'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex flex-column'>
                <span style={{ color: '#1E1E1E', fontSize: 14 }}>
                  {patientName}
                </span>
                <span style={{ color: '#1E1E1E', fontSize: 10 }}>{date}</span>
              </div>
              <div className='d-flex'>
                <button className='btn' style={{ color: '#666666' }}>
                  <i className='fas fa-file-download'></i>
                </button>
                <button
                  className='btn'
                  onClick={closeModal}
                  style={{ color: '#666666' }}
                >
                  <i className='fas fa-times'></i>
                </button>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='d-flex justify-content-between align-items-center'>
              <button
                className='btn'
                style={{ color: '#666666' }}
                onClick={() =>
                  setImgItem(imgItem === 0 ? imgItem : imgItem - 1)
                }
              >
                <i
                  className='fas fa-chevron-circle-left'
                  style={{ fontSize: 30 }}
                ></i>
              </button>
              <span>
                <img
                  src={images[imgItem]}
                  alt=''
                  style={{ width: '350px', height: '500px' }}
                  className='rounded'
                />
              </span>
              <button
                className='btn'
                style={{ color: '#666666' }}
                onClick={() =>
                  setImgItem(imgItem === recordLength ? imgItem : imgItem + 1)
                }
              >
                <i
                  className='fas fa-chevron-circle-right'
                  style={{ fontSize: 30 }}
                ></i>
              </button>
            </div>
          </div>
          <div className='col-12 mt-2'>
            <div
              className='d-flex justify-content-center'
              style={{ borderTop: '2px solid #CCCCCC' }}
            >
              {images.map((item, index) => (
                <img
                  onClick={() => setImgItem(index)}
                  key={index}
                  className={index === imgItem ? 'active-image' : ''}
                  src={item}
                  alt=''
                  style={{
                    width: '100px',
                    height: '125px',
                    margin: '5px',
                    cursor: 'pointer',
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </Modal>
      <style jsx='true'>
        {`
          .image-view {
            cursor: pointer;
          }
          .image-view:hover {
            text-decoration: underline;
          }
          .ReactModal__Overlay {
            opacity: 0;
            transition: opacity 300ms ease-in-out;
            background-color: rgba(0, 0, 0, 0.38) !important;
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
          }
          .ReactModal__Content.ReactModal__Content--after-open {
          }
          .active-image {
            border: 3px solid #009b94;
            border-radius: 5px;
          }
        `}
      </style>
    </>
  )
}
