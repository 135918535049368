import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
//

export const consultationFilterSlice = createSlice({
  name: "consultationFilter",
  initialState: {
    value: "",
    value1: "",
    value2: moment().format("yyyy-MM-DD"),
    value3: moment().format("yyyy-MM-DD"),
  },
  reducers: {
    all: (state, action) => {
      state.value1 = "";
    },
    booked: (state, action) => {
      state.value1 = "booked";
    },
    missed: (state, action) => {
      state.value1 = "missed";
    },
    cancelled: (state, action) => {
      state.value1 = "cancelled";
    },
    InProgress: (state, action) => {
      state.value1 = "InProgress";
    },
    completed: (state, action) => {
      state.value1 = "completed";
    },
    unattended: (state, action) => {
      state.value1 = "unattended";
    },
    called: (state, action) => {
      state.value1 = "called";
    },
    testCall: (state, action) => {
      state.value1 = "testCall";
    },
    reschedule: (state, action) => {
      state.value1 = "reschedule";
    },
    fakeCall: (state, action) => {
      state.value1 = "fakeCall";
    },

    pending: (state, action) => {
      state.value1 = "pending";
    },
    appoint_date: (state, action) => {
      const { payload } = action;
      state.value2 = payload;
    },
    appoint_date_from: (state, action) => {
      const { payload } = action;
      state.value3 = payload;
    },
    videoCall: (state, action) => {
      state.value = "";
    },
    Booked: (state, action) => {
      state.value = "Booked";
    },
    Instant: (state, action) => {
      state.value = "Instant";
    },
    FollowUp: (state, action) => {
      state.value = "FollowUp";
    },
    clearFilters: (state) => {
      state.value = "";
      state.value1 = "";
      state.value2 = "";
      state.value3 = "";
    },
  },
});

export const consultationFilterReducer = consultationFilterSlice.reducer;
