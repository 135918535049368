import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_CONSTANTS } from '../../API_CONSTANTS'
// thunk function
export const getData = createAsyncThunk('getData', async () => {
  const response = await axios
    .get(`${API_CONSTANTS.BASEURL_1}admin/settings/Settings/name/App`, {
      headers: API_CONSTANTS.headers(),
    })
    .then((res) => res.data.response)

  return response
})

// slice function
export const getDataSlice = createSlice({
  name: 'getDataSlice',
  initialState: {
    value: {
      Conditions: [],
      Services: [],
      Specializations: [],
    },
  },
  reducers: {
    addService: (state, action) => {
      state.value.Services = [...state.value.Services, action.payload]
    },
    editService: (state, action) => {
      state.value.Services = state.value.Conditions.map((item) => {
        if (item === action.payload.lastValue) {
          return action.payload.newName
        }
        return item
      })
    },
    deleteService: (state, action) => {
      state.value.Services = state.value.Services.filter(
        (item) => item !== action.payload
      )
    },
    addConditions: (state, action) => {
      state.value.Conditions = [...state.value.Conditions, action.payload]
    },
    editConditions: (state, action) => {
      state.value.Conditions = state.value.Conditions.map((item) => {
        if (item === action.payload.lastValue) {
          return action.payload.newName
        }
        return item
      })
    },
    deleteConditions: (state, action) => {
      state.value.Conditions = state.value.Conditions.filter(
        (item) => item !== action.payload
      )
    },
    addSpecializations: (state, action) => {
      state.value.Specializations = [
        ...state.value.Specializations,
        action.payload,
      ]
    },
    editSpecializations: (state, action) => {
      state.value.Specializations = state.value.Specializations.map((item) => {
        if (item.Id === action.payload.lastValue) {
          return {
            ...item,
            Id: action.payload.newName,
            url: action.payload.url,
          }
        }

        return item
      })
    },
    deleteSpecializations: (state, action) => {
      state.value.Specializations = state.value.Specializations.filter(
        (item) => item.Id !== action.payload
      )
    },
  },
  extraReducers: {
    [getData.pending]: (state) => {
      state.loading = true
    },
    [getData.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.value = payload
    },
    [getData.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const getDataReducer = getDataSlice.reducer
