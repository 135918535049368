import React, { useState } from "react";
import { useTable } from "react-table";
import { useSelector } from "react-redux";
import ActionButtons from "./ActionButtons";
import moment from "moment";
import { Modal } from "react-bootstrap";
//
// name component
const Name = ({ path, name }) => {
  return (
    <>
      <span className="d-flex align-items-center">
        <img
          src={
            path
              ? path
              : "https://img.icons8.com/material/72/000000/user-male-circle--v1.png"
          }
          alt=""
          style={{ width: 40, height: 40, borderRadius: 50 }}
        />
        <span style={{ marginLeft: 10 }}>
          {name.charAt(0).toUpperCase() + name.slice(1)}
        </span>
      </span>
    </>
  );
};
// Health Hisory Modal
function HealthHistory({ data }) {
  // States
  const [show, setShow] = useState(false);
  return (
    <>
      <button onClick={() => setShow(true)} className="modalbutton">
        View
      </button>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Health History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {data.patientHealthHistory?.map((item) => (
              <div key={item.Id}>
                {item.subSectionName ? (
                  <div className="border rounded my-3 p-2">
                    <h3>{item.subSectionName}</h3>
                    {item.subQuestions.map((subItem) => (
                      <div key={subItem.Id} className="d-flex align-items-center justify-content-between px-2 py-1" style={{ fontSize: "1rem", fontWeight: '400'}}>
                        <p style={{ marginBottom: "0px" }}>{subItem.text}</p>
                        <div className="d-flex gap-4 align-items-center">
                          <span className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              checked={subItem.isChecked === true}
                              readOnly
                            />
                            <label>Yes</label>
                          </span>
                          <span className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              checked={subItem.isChecked === false}
                              readOnly
                            />
                            <label>No</label>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                    <div className="d-flex align-items-center justify-content-between border rounded my-3 p-2" style={{ fontSize: "1rem", fontWeight: '400',  }}>
                    <p style={{ marginBottom: "0px" }}>{item.text}</p>
                      <div className="d-flex gap-4" style={{ paddingRight: '8px' }}>
                      <span className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={item.isChecked === true}
                          readOnly
                        />
                        <label>Yes</label>
                      </span>
                      <span className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={item.isChecked === false}
                          readOnly
                        />
                        <label>No</label>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
      <style jsx="true">
        {`
          .modalbutton {
            background: none;
            border: none;
            border-bottom: 1px solid #5C5C5C;
          }
          input[type="checkbox"] {
                margin-right: 5px;
                font-size: 1rem;
                font-weight: 400;
                height: 18px;
                width: 18px;
              }
        `}
      </style>
    </>
  );
}
export default function RecordTable() {
  const value = useSelector((state) => state.getPatientProfileReducer.value);
  const data = value.profiles ? value.profiles.other : [];
  // columns
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: (res) => <Name name={res.name} path={res.url} />,
      },
      {
        Header: "Relation",
        accessor: "relation",
      },
      {
        Header: "Age",
        accessor: (patientList) =>
          !patientList.dob
            ? ""
            : moment().diff(patientList.dob, "years") + " years",
        Cell: ({ cell: { value } }) => value || "N/A",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Health History",
        accessor: (res) => res.healthHistoryData ? <HealthHistory data={res.healthHistoryData} /> : "-",
      },

      {
        Header: "Actions",
        accessor: (res) => <ActionButtons response={res} />,
        style: { width: "100px" },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <table
      className="medical-record"
      {...getTableProps()}
      style={{ width: "100%" }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
      <style jsx="true">{`
        .medical-record td {
          text-align: left;
        }
      `}</style>
    </table>
  );
}
