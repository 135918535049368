/*
Author: Umer Shabir 
Date: 17-03-2022
File: Filter modal to render filter Form.
*/
// Dependencies
import Modal from "react-modal";
import React from "react";
import FilterActionButtons from "./FilterActionButtons";
import { useSelector, useDispatch } from "react-redux";
// style variable
const customStyles = {
  content: {
    position: "absolute !important",
    width: "288px !important",
    height: "100% !important",
    bottom: "0 !important",
    right: "0 !important",
    padding: "unset !important",
    backgroundColor: "rgba(0, 0, 0, 0.38) !important",
  },
};
// Filter Side bar Modal Component.
export default function FilterSideBarModal({ Filter }) {
  const { show } = useSelector((state) => state.tableFilterModalReducer);
  const dispatch = useDispatch();
  function openModal() {
    dispatch({ type: "tableFilterModal/setShowModal" });
  }

  function closeModal() {
    dispatch({ type: "tableFilterModal/setHideModal" });
  }
  return (
    <>
      <div className="d-flex">
        <button
          className="btn"
          style={{ fontSize: 14, color: "#000" }}
          onClick={openModal}
          disabled={!Filter}
        >
          <i className="fas fa-filter" style={{ color: "#99D7D4" }}></i>
          <span className="ms-2">Filter</span>
        </button>
        <Modal
          isOpen={show}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
          className="table-filter-modal"
        >
          <div>
            <FilterActionButtons closeModal={closeModal} />
            {Filter}
          </div>
        </Modal>
      </div>
      <style jsx="true">{`
        .ReactModal__Overlay {
          opacity: 0;
          transition: opacity 300ms ease-in-out;
          background-color: rgba(0, 0, 0, 0.38) !important;
        }

        .ReactModal__Overlay--after-open {
          opacity: 1;
          z-index: 9999;
        }

        .ReactModal__Overlay--before-close {
          opacity: 0;
        }
        .table-filter-modal {
          position: absolute !important;
          inset: unset !important;
          width: 288px !important;
          height: 100% !important;
          bottom: 0 !important;
          right: 0 !important;
          padding: unset !important;
          background: #fff;
          border: 1px solid #efefef;
        }
      `}</style>
    </>
  );
}
