import React from 'react'
import RecordTable from './RecordTable'
import AddMember from './AddMember'
//
export default function FamilyRecord() {
  return (
    <>
      <div className='row bg-white mt-2 p-3 rounded'>

        <div className='col-12'>
          <div className='d-flex justify-content-between align-items-center'>
            <span style={{ fontSize: 16, fontWeight: 500 }}>All Members</span>
            <AddMember />
          </div>
        </div>
        <div className='col-12'>
          <RecordTable />
        </div>
      </div>
    </>
  )
}
