import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import QuillMarkdown from 'quilljs-markdown'
// import { htmlToMarkdown } from 'html-to-markdown';
import { NodeHtmlMarkdown } from 'node-html-markdown'

/* 
 * Simple editor component that takes placeholder text as a prop 
 */
const Editorquill = ({ placeholder, html, handleHtmlChange }) => {
    const [editorHtml, setEditorHtml] = useState('');
    const markdown = NodeHtmlMarkdown.translate(editorHtml);
    console.log(markdown)
    const handleChange = (htl) => {
        setEditorHtml(htl);
    };
    useEffect(() => {
        setEditorHtml(html);
    }, [html]);
    return (
        <div>
            <ReactQuill
                onChange={handleChange}
                value={editorHtml}
                modules={Editorquill.modules}
                formats={Editorquill.formats}
                bounds={'.app'}
                placeholder={placeholder}
            />
        </div>
    );
};

Editorquill.modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { header: '3' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
        ],
        ['link', 'image', 'video'],
        ['clean'],
    ],
    clipboard: {
        matchVisual: false,
    },
};
Editorquill.formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
];
Editorquill.propTypes = {
    placeholder: PropTypes.string,
};

export default Editorquill;