import React from 'react'
import BlogsList from './components/BlogList'
import AddNewUserButton from '../../Components/AddNewUserButton'
function Blog() {
  return (
    <>
      <AddNewUserButton
        link={'/blogs/CreateBlog'}
        buttonText={'Add New Blog'}
      />

      <BlogsList />
    </>
  )
}

export default Blog
