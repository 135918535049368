import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'

//
export const getReview = createAsyncThunk('getReview', async (paramsID) => {
  const response = await axios
    .get(`${API_CONSTANTS.BASEURL}admin/feedback?appointmentId=${paramsID}`, {
      headers: API_CONSTANTS.headers(),
    })
    .then((res) => res.data.response)

  return response
})

export const getReviewSlice = createSlice({
  name: 'getReview',
  initialState: {
    data: {
      isReviewVerified: '',
      reviewByPatients: {
        videoCall: '',
        behaviour: '',
        communication: '',
        recommended: '',
        review: '',
        satisfaction: '',
      },
      reviewByDoctors: {
        behaviour: '',
        review: '',
      },
    },
  },
  reducers: {},
  extraReducers: {
    [getReview.pending]: (state) => {
      state.loading = true
    },
    [getReview.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.data = payload
    },
    [getReview.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const getReviewReducer = getReviewSlice.reducer
