import React from 'react'
import { useSelector } from 'react-redux'
import AppointmentStatus from './AppointmentStatus'
import PatientMedicalRecord from './PatientMedicalRecord'

//
//
export default function ConsultationsOverview() {
  //
  const { value, loading } = useSelector(
    (state) => state.getConsultationProfileReducer
  )
  return (
    <>
      {loading && <div className='loading'></div>}
      {value.message === 'No Record found..' && (
        <div className='loading'>There is no record</div>
      )}
      <div className='container-fluid p-4 '>
        <div className='mainover d-flex gap-3'>
          <div className='leftover'>
            <div className='d-flex flex-column '>
              <table className='bg-white rounded profile-card-width mb-3'>
                <thead></thead>
                <tbody>
                  <tr>
                    <th>Booked For</th>
                    <td>{value?.patientInfo?.name}</td>
                  </tr>
                  <tr>
                    <th>Number</th>
                    <td>{value?.patientInfo?.phone_no}</td>
                  </tr>
                  <tr>
                    <th>Relation</th>
                    <td>{value?.patientInfo?.relation}</td>
                  </tr>
                  <tr>
                    <th>D.O.B</th>
                    <td>{value?.patientInfo?.dob}</td>
                  </tr>
                  <tr>
                    <th>Gender</th>
                    <td>{value?.patientInfo?.gender}</td>
                  </tr>
                  <tr>
                    <th>Booked By Number</th>
                    <td>{value?.bookedByPhoneNo}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{value?.patientInfo?.email}</td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td>{value?.patientInfo?.location}</td>
                  </tr>
                </tbody>
              </table>
              <table className='bg-white rounded profile-card-width'>
                {/* <thead></thead> */}
                <tbody>
                  <tr>
                    <th c>Doctor’s Name</th>
                    <td>
                      {value.doctorInfo.title +
                        ' ' +
                        value.doctorInfo.firstName +
                        ' ' +
                        value.doctorInfo.lastName}
                    </td>
                  </tr>
                  <tr>
                    <th>Specialization</th>
                    <td>{value.doctorInfo.specialization}</td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>{value.doctorInfo.contact}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{value.doctorInfo.email}</td>
                  </tr>
                  <tr>
                    <th>Emergency Contact</th>
                    <td>{value.doctorInfo.emergencyContact}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='rightover'>
            <div className='d-flex flex-column'>
              <AppointmentStatus />
              <PatientMedicalRecord />
            </div>
          </div>
        </div>
      </div>
      <style jsx='true'>
        {`
          .leftover{
            width: 30%;
          }
          .rightover{
            width: 70%;
          }
          table {
            font-size: 14px;
          }
          th {
            font-weight: 500;
            padding: 15px;
          }
          td {
            text-align: right;
            padding: 15px;
          }
          tr {
            border-bottom: 1px solid #dadada;
          }
          .loading {
            position: fixed;
            z-index: 999;
            height: 2em;
            width: 2em;
            overflow: show;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }

          /* Transparent Overlay */
          .loading:before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: radial-gradient(
              rgba(20, 20, 20, 0.8),
              rgba(0, 0, 0, 0.8)
            );

            background: -webkit-radial-gradient(
              rgba(20, 20, 20, 0.8),
              rgba(0, 0, 0, 0.8)
            );
          }

          /* :not(:required) hides these rules from IE9 and below */
          .loading:not(:required) {
            /* hide "loading..." text */
            font: 0/0 a;
            color: transparent;
            text-shadow: none;
            background-color: transparent;
            border: 0;
          }

          .loading:not(:required):after {
            content: '';
            display: block;
            font-size: 10px;
            width: 1em;
            height: 1em;
            margin-top: -0.5em;
            -webkit-animation: spinner 150ms infinite linear;
            -moz-animation: spinner 150ms infinite linear;
            -ms-animation: spinner 150ms infinite linear;
            -o-animation: spinner 150ms infinite linear;
            animation: spinner 150ms infinite linear;
            border-radius: 0.5em;
            -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
              rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
              rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
              rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
              rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
              rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
              rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
              rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
            box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
              rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
              rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
              rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
              rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
              rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
              rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
              rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
          }

          /* Animation */

          @-webkit-keyframes spinner {
            0% {
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              -moz-transform: rotate(360deg);
              -ms-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @-moz-keyframes spinner {
            0% {
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              -moz-transform: rotate(360deg);
              -ms-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @-o-keyframes spinner {
            0% {
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              -moz-transform: rotate(360deg);
              -ms-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @keyframes spinner {
            0% {
              -webkit-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              -moz-transform: rotate(360deg);
              -ms-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @media screen and (max-width: 1550px) {
            .leftover{
              width: 50%;
            }
            .rightover{
              width: 100%;
            }
          }
          @media screen and (max-width: 1050px) {
            .mainover{
              flex-wrap: wrap;
            }
          }
          @media screen and (max-width: 700px) {
            .leftover{
              width: 100%;
            }
          }
        `}
      </style>
    </>
  )
}
