/*
Authore: Umer Shabir 
Date: April-2022
File: Admin profile page OverView Component.
*/
// Dependencies
import React from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../../../Components/Loader'
// Admin profile page overview component
export default function AdminProfileOverview() {
  const { value, loading } = useSelector(
    (state) => state.getAdminProfileReducer
  )
  return (
    <>
      {loading && <Loader />}
      <div className='container-fluid p-4'>
        <div className='row'>
          <div className='col'>
            <div className='d-flex '>
              <table className='bg-white rounded profile-card-width'>
                <thead></thead>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>
                      {value.adminName?.charAt(0).toUpperCase() +
                        value.adminName?.slice(1)}
                    </td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>{value?.phoneNo}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{value?.email?.toLowerCase()}</td>
                  </tr>
                  <tr>
                    <th>Department</th>
                    <td>{value?.adminRole}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <style jsx='true'>
        {`
          .profile-card-width {
            width: 350px !important;
          }
          table {
            font-size: 14px;
          }
          th {
            font-weight: 500;
            padding: 15px;
          }
          td {
            text-align: right;
            padding: 15px;
          }
          tr {
            border-bottom: 1px solid #dadada;
          }
        `}
      </style>
    </>
  )
}
