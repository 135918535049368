/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState } from 'react'
import Table from '../../Components/Table/index'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'
import AlertMessage from '../../Components/AlertMessage'
import { API_CONSTANTS } from '../../API_CONSTANTS'
import { ReactComponent as ThumbsUp } from '../../resources/images/svgs/thumbup.svg'
import { ReactComponent as ThumbsDown } from '../../resources/images/svgs/thumbdown.svg'
import ReviewFilterForm from './ReviewFilterForm'
import moment from 'moment/moment'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'

function AdminApproved({ appointmentId, isVerified, setVerifiedStatus }) {
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const approvedReview = () => {
        axios
            .patch(
                `${API_CONSTANTS.BASEURL}admin/feedback/${appointmentId}/verify`,
                {},
                { headers: API_CONSTANTS.headers() }
            )
            .then((res) => {
                setIsLoading(false)
                setShow(false)
                setVerifiedStatus(true)
            })
    }
    return (
        <>
            <button
                className={`btn review ${isVerified ? 'disabled' : ''}`}
                onClick={() => setShow(!isVerified)}
                disabled={isVerified}
            >
                {isVerified ? 'Approved' : 'Not Approved'}
            </button>
            <Modal show={show} onHide={() => setShow(false)} size="m" centered >
                <Modal.Body>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <label>Do you want to approve this Review?</label>
                        <button onClick={approvedReview} disabled={isVerified || isLoading} className="approve-button">
                            {isLoading ? 'Approving...' : 'Approve'}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <style jsx='true'>
                {`
            .review{
              color: #009b94;
              border-bottom: 1px solid;
              padding: 0px;
            }
            .review:hover{
                color: red;
            }
            .approve-button {
                background-color: #009b94;
                color: #fff;
                border: 1px solid #009b94;
                padding: 8px 16px;
                cursor: pointer;
                font-size: 16px;
                border-radius: 4px;
                margin: 5px;
                width: 100px;
            }
            .approve-button:hover {
                background-color: #fff;
                color:#009b94;
                border: 1px solid #009b94
            }
            `}
            </style>
        </>
    )
}

export default function ReviewList() {
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
    const [reviewList, setReviewList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error] = useState(null)
    const [pageCount, setPageCount] = useState(0)
    const fetchIdRef = React.useRef(0)

    const filter_status = {
        approvalStatus: useSelector((state) => state.ReviewFilterReducer.value),
        reviewBy: useSelector((state) => state.ReviewFilterReducer.value1),
        reviewDateFrom: useSelector((state) => state.ReviewFilterReducer.value3),
        reviewDate: useSelector((state) => state.ReviewFilterReducer.value2),
    }
    const handleFilters = () => {
        let filterData = {
            approvalStatus: filter_status.approvalStatus,
            reviewBy: filter_status.reviewBy,
            reviewDateFrom:filter_status.reviewDateFrom ,
            reviewDate: filter_status.reviewDate,
        }
        if (Object.values(filterData).every((key) => key === '')) {
            return {}
        } else
            return Object.fromEntries(
                Object.entries(filterData).filter(([key, value]) => value !== '')
            )
    }
    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            const fetchId = lastEvaluatedKey
            if (fetchId === fetchIdRef.current) {
                const startRow = pageSize * pageIndex
                const endRow = startRow + pageSize
                setReviewList(reviewList.slice(startRow, endRow))
                setPageCount(Math.ceil(reviewList.length / pageSize))
            }
        },
        [reviewList, lastEvaluatedKey]
    )
    const feedbackList = () => {
        axios.get(`${API_CONSTANTS.BASEURL}admin/feedback`,
            {
                headers: API_CONSTANTS.headers(),
                params: handleFilters(),
            }
        )
            .then((res) => {
                setIsLoading(true)
                setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
                setReviewList(res.data.response.Items)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.reload()
                    window.localStorage.clear()
                }
                setIsLoading(true)
                setReviewList([])
            })
    }
    const [page, setPage] = useState(0)

    const handleLoadMoreData = () => {
        axios
            .get(
                `${API_CONSTANTS.BASEURL}admin/feedback?last=${lastEvaluatedKey}`,
                {
                    headers: API_CONSTANTS.headers(),
                    params: handleFilters(),
                }
            )
            .then((response) => {
                setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
                var reviewListNextPage = response.data.response.Items
                setReviewList([...reviewList, ...reviewListNextPage])
                setPage(page + 1)
            })
            .then(() => setLoading(true))
    }
    const handleUpdateVerificationStatus = (appointmentId, status) => {
        setReviewList(prevReviewList => {
            const updatedReviewList = prevReviewList.map(review => {
                if (review.appointmentId === appointmentId) {
                    return { ...review, isReviewVerified: status }
                }
                return review
            })
            return updatedReviewList
        })
    }
    const onDownloadBtn = () => {
        const filters = handleFilters()
        if (!filters || Object.keys(filters).length === 0) {
            alert("Please select valid filters before proceeding.");
            return;
        }
        axios
            .get(
                `${API_CONSTANTS.BASEURL}admin/feedback?loadall=true`,
                {
                    params: handleFilters(),
                    headers: API_CONSTANTS.headers(),
                }
            )
            .then((response) => {
                const s3Url = response.data.response.s3_url;
                const link = document.createElement('a');
                link.href = s3Url;
                link.download = 'consultation.csv'
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link)
                setLoading(true);
            })
            .catch((error) => {
                console.error("An error occurred:", error);
            });
    };
    const columns = useMemo(
        () => [
            {
                Header: 'Appointment Date',
                accessor: (item) => moment(item.appoint_date, 'YYYY-MM-DD HH:mm:ss').format('D-MM-YYYY, hh:mm A'),
            },
            {
                Header: 'Reviews Date',
                accessor: (item) => moment(item.reviewdate, 'YYYY-MM-DD HH:mm:ss').format('D-MM-YYYY, hh:mm A'),
            },
            {
                Header: 'Submitted By',
                accessor: (data) => {
                    if (filter_status.reviewBy === 'doctors') {
                        return data.doctorName
                    } else {
                        return data.patientInfo.name
                    }
                },

            },
            {
                Header: 'Submitted For',
                accessor: (data) => {
                    if (filter_status.reviewBy === 'doctors') {
                        return data.patientInfo.name
                    } else {
                        return data.doctorName
                    }
                },
            },
            {
                Header: 'Review',
                accessor: (item) => {
                    if (filter_status.reviewBy === 'doctors') {
                        return item.reviewByDoctors?.review;
                    } else {
                        return item.reviewByPatients?.review;
                    }
                },
                Cell: ({ cell: { value } }) => {
                    if (value?.length > 0) {
                        return value?.length > 30 ? value.slice(0, 30) + '...' : value;
                    } else {
                        return '-';
                    }
                },
            },
            {
                Header: 'Feedback',
                accessor: 'reviewByPatients',
                Cell: ({ cell: { value } }) => {
                    if (value && value.behaviour >= 1 && value.behaviour <= 5) {
                        const percentage = value.behaviour * 20
                        const feedbackIcon = value.recommended ? <ThumbsUp /> : <ThumbsDown />
                        return (
                            <span>
                                {feedbackIcon} {value.recommended}  {percentage}%
                            </span>
                        )
                    } else {
                        return '-'
                    }
                },
            },
            {
                Header: 'Details',
                accessor: (item) => <Link to={`/consultations/profile/${item.appointmentId}/reviews`} className="btn add-record" >Show</Link>,
            },
            {
                Header: 'Status',
                accessor: (item) => (
                    <AdminApproved
                        appointmentId={item.appointmentId}
                        isVerified={item.isReviewVerified}
                        setVerifiedStatus={(status) => handleUpdateVerificationStatus(item.appointmentId, status)}
                    />
                ),
            },
            // {
            //     Header: 'Status',
            //     accessor: 'isReviewVerified',
            //     Cell: ({ cell: { value } }) => value ? 'Approved' : 'Not Approved',
            // },
        ],
        [feedbackList, filter_status.reviewBy]
    )
    const is_SessionExpire = () => {
        window.localStorage.clear()
        window.location.reload()
    }
    useEffect(() => {
        feedbackList()
    }, [
        filter_status.reviewDate,
        filter_status.reviewDateFrom,
        filter_status.approvalStatus,
        filter_status.reviewBy,
    ])
    // Table rendering
    if (error) {
        return (
            <AlertMessage
                errorMessage={
                    error.message === 'Request failed with status code 401'
                        ? is_SessionExpire()
                        : error.message === 'Request failed with status code 404'
                            ? 'record not found'
                            : error.message
                } />
        )
    } else if (!isLoading) {
        return (
            <div className='d-flex justify-content-center  '>
                <Spinner animation='border' style={{ color: '#24AAA4', marginTop: '20px' }} />
            </div>
        )
    } else {
        return (
            <>
                <div className='row rounded bg-white m-4'>
                    <div>
                        <h3
                            style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}
                        >
                            All Reviews
                        </h3>
                    </div>
                    <Table
                        pageNumber={page}
                        columns={columns}
                        data={reviewList}
                        routingLink={''}
                        pageCount={pageCount}
                        fetchData={fetchData}
                        loadMoreList={handleLoadMoreData}
                        lastEvaluatedKey={lastEvaluatedKey}
                        loading={loading}
                        setLoading={setLoading}
                        FilterForm={<ReviewFilterForm reviewList={feedbackList} />}
                        recordDownload={true}
                        onDownloadBtn={onDownloadBtn}
                    />
                </div>
                <style jsx='true'>{`
                .add-record{
                    background: #009b94 !important;
                    color: #fff;
                  }
            .btn:focus {
              box-shadow: none !important;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            .patient-search-input {
              border-radius: 0;
            }
          `}</style>
            </>
        )
    }
}