import React, { useMemo, useEffect, useState } from 'react'
import Table from '../../../../../Components/Table/index'
import axios from 'axios'
import FilterForm from './FilterForm'
import { useSelector } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import AlertMessage from '../../../../../Components/AlertMessage'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
//
export default function DelayedAppointmentTable() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [appointmentList, setappointmentList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0)
  const [page, setPage] = useState(0)
  //
  const params = useParams()
  //
  const filterValue = useSelector((state) => state.delayedFilterReducer)
  //
  const filter_status = {
    from: filterValue.value1,
    to: filterValue.value2,
  }
  //
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setappointmentList(appointmentList.slice(startRow, endRow))

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(appointmentList.length / pageSize))
      }
    },
    [appointmentList, lastEvaluatedKey]
  )
  //
  // const handleFilters = () => {
  //   let filterData = {
  //     appointment_status: `${filter_status.appointment_status}`,
  //     appoint_date_from: `${filter_status.appoint_date_from}`,
  //     appoint_date_to: `${filter_status.appoint_date_to}`,
  //     created_at: `${filter_status.created_at}`,
  //   }
  //   if (Object.values(filterData).every((key) => key === '')) {
  //     return {}
  //   } else
  //     return Object.fromEntries(
  //       Object.entries(filterData).filter(([key, value]) => value !== '')
  //     )
  // }
  // handling filters
  const handleFilters = () => {
    let filterData = {
      from: `${filter_status.from}`,
      to: `${filter_status.to}`,
    }
    if (Object.values(filterData).every((key) => key === '')) {
      return {}
    } else
      return Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== '')
      )
  }
  // API Hatching data of doctor appointments
  const userList = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/doctors/appointmentsdelayed/${params.id}`,
        handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true)
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
        setappointmentList(
          res.data.response.Items ? res.data.response.Items : []
        )
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setIsLoading(true)
        setError(error)
        console.log(error.response.data.message)
      })
  }

  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/doctors/appointmentsdelayed/${params.id}?last=${lastEvaluatedKey}`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
        var appointmentListNextPage = response.data.response.Items
        setappointmentList([...appointmentList, ...appointmentListNextPage])
        setPage(page + 1)
      })
      .then(() => setLoading(true))
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Date/Time ',
        accessor: (a) => moment(a.appoint_date).format('DD-MM-YYYY hh:mm A'),
      },
      {
        Header: 'Patient Name',
        accessor: 'patientInfo.name',
        Cell: ({ cell: { value } }) => value || 'N/A',
      },
      {
        Header: 'Status',
        accessor: 'appointment_status',
        Cell: ({ cell: { value } }) => value || 'N/A',
      },
      {
        Header: 'Doctor alert',
        accessor: (a) =>
          a.alert.doctorAlert ? (
            <i
              class='fas fa-check'
              style={{ color: '#009B94', fontSize: 20 }}
            ></i>
          ) : (
            <i
              class='fas fa-times'
              style={{ color: '#009B94', fontSize: 20 }}
            ></i>
          ),
      },

      {
        Header: 'Admin alert',
        accessor: (a) =>
          a.alert.adminAlert ? (
            <i
              class='fas fa-check'
              style={{ color: '#009B94', fontSize: 20 }}
            ></i>
          ) : (
            <i
              class='fas fa-times'
              style={{ color: '#009B94', fontSize: 20 }}
            ></i>
          ),
      },
    ],
    []
  )
  const is_SessionExpire = () => {
    // window.localStorage.clear()
    // window.location.reload()
  }
  useEffect(() => {
    userList()
  }, [filter_status.to, filter_status.from])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.response.data.message === 'Error'
            ? 'No delayed appointment to show'
            : error.response.data.message
        }
      />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center mt-4'>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <div className='row rounded bg-white m-4'>
        <div>
          <h3 style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}>
            Delay appointment
          </h3>
        </div>
        <Table
          pageNumber={page}
          columns={columns}
          data={appointmentList}
          routingLink={'/consultations/profile'}
          pageCount={pageCount}
          fetchData={fetchData}
          loadMoreList={handleLoadMoreData}
          lastEvaluatedKey={lastEvaluatedKey}
          loading={loading}
          setLoading={setLoading}
          FilterForm={<FilterForm />}
        />
      </div>
    )
  }
}
//
