import React from 'react'
import NurseUserList from './NurseUserList'
//
import AddNewUserButton from '../../../../Components/AddNewUserButton'
//
export default function NurseList() {
  return (
    <>
      <AddNewUserButton link={'/nurse/new'} buttonText={'Add New Nurse'} />
      <NurseUserList />
      {/* <style jsx='true'>
        {`
          .doctor {
            display: flex;
            justify-content: space-between;
          }

          .form-control:focus {
            background-color: #fff;
            border-color: rgb(0, 155, 148) !important;
            box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 25%) !important;
          }
          .btn:focus {
            box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 25%) !important;
          }
        `}
      </style> */}
    </>
  )
}
