import React from 'react'
import SubscriptionUserList from './SubscriptionList'
import AddMember from './AddMember'
//
export default function SubscriptionList({UserPlanList}) {
  return (
    <>
      <div className='col-12  '>
        <div className='d-flex justify-content-end align-items-center'>
          <span style={{ fontSize: 16, fontWeight: 500 }}></span>
          <AddMember/>
        </div>
      </div>
      <SubscriptionUserList />
    </>
  )
}
