import React, { useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useEffect } from 'react'
import axios from 'axios'
import style from './style.module.css'
import { useSelector } from 'react-redux'
import { API_CONSTANTS, headers } from '../../../API_CONSTANTS'


//
export default function DoctorSearch({ doctorSlots }) {
  const [value, setValue] = useState('')
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState([])
  const doctorName = useSelector(
    (state) => state.getConsultationProfileReducer.value.doctorInfo
  )

  useEffect(() => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/doctors/verified`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => setOptions(res.data.response?.Items))
      .catch((err) => alert(err))
  }, [])
  useEffect(() => {
    if (value) {
      doctorSlots(value.Id)
    }
  }, [value])
  return (
    <div className='my-3'>
      <Autocomplete
        id='searching-medicine'
        getOptionLabel={(options) => options.Fname}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        fullWidth
        includeInputInList
        filterSelectedOptions
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options)
          setValue(newValue)
        }}
        onInputChange={(e) => {
          setInputValue(e.target.value)
        }}
        renderOption={(props, options) => (
          <Box component='li' {...props} key={options}>
            {options.Fname}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={doctorName.firstName + ' ' + doctorName.lastName}
            fullWidth
            disableClearaAble
          />
        )}
      />
    </div>
  )
}
