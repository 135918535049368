import React, { useState } from 'react'
import './NurseService.css'
import { Modal } from 'react-bootstrap'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import toArrayBuffer from 'to-array-buffer'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import CloseIcon from "@mui/icons-material/Close"

export default function Add({getList}) {
  const [modalShow, setModalShow] = useState(false)
  const [type, setType] = useState('')
  const [loading, setLoading] = useState({
    apiLoading: false,
    imgLoading: false,
  });
  const { apiLoading, imgLoading } = loading;
  const [file, setFile] = useState(
    'https://img.icons8.com/material/72/000000/user-male-circle--v1.png'
  )
  const initialValues = {
    title: '',
    servicePrice: '',
    description: '',
    how_it_works: '',
    what_it_includes: '',
    faq: [
      {
        question: '',
        answer: '',
      },
    ],
  }
  // Validation schema using Yup
  const validationSchema = Yup.object({
    title: Yup.string().required('Service Name is required'),
    servicePrice: Yup.string().required('Service Pricing is required'),
    description: Yup.string().required('Description is required'),
    how_it_works: Yup.string().required('How It Works is required'),
    what_it_includes: Yup.string().required('What It Includes? is required'),
    faq: Yup.array()
      .of(
        Yup.object().shape({
          question: Yup.string().required('Question is required'),
          answer: Yup.string().required('Answer is required'),
        })
      )
      .required('At least one FAQ is required'),
  });

  // uploading Icon/Image
  const uploadFile = (e) => {
    setLoading({ imgLoading: true })
    const [file] = e.target.files || e.dataTransfer.files
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = async () => {
      const fileRes = btoa(reader.result)
      setFile(`data:image/png;base64,${fileRes}`)
      const base64Image = toArrayBuffer(fileRes)
      // console.log(file)
      axios
        .post(
          `${API_CONSTANTS.BASEURL
          }admin/image-upload-api/user/${'a80422ab-1f3c-4676-8200-fc38a156599f '}/type/new`,
          base64Image,
          {
            headers: API_CONSTANTS.imgheaders(),
          }
        )
        .then((response) => {
          setFile(response.data.response.url)
          setLoading({ imgLoading: false })
        })
        .catch((e) => alert(e));
    };
  };

  // Adding Data
  const addingData = (values, { setSubmitting }) => {
    setLoading({ apiLoading: true })
    values.type = type
    values.url = file
    values.faq = values.faq.map((faq) => ({
      question: faq.question.trim(),
      answer: faq.answer.trim(),
    }))
    axios
      .post(
        `${API_CONSTANTS.BASEURL_3}admin/nurse/services`,
        values,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setLoading({ apiLoading: false })
        setModalShow(false)
        getList(type)
      })
      .catch((error) => alert(error.response.data.response.message))
      .finally(() => {
        setLoading({ apiLoading: false })
        setSubmitting(false)
      })
  }
  return (
    <>
      <button className='button' onClick={() => setModalShow(true)}>
        <i className={`fas fa-plus iconClass`}></i>
        Add New
      </button>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Body className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <h4>Add New Service</h4>
            <CloseIcon onClick={() => setModalShow(false)} style={{ cursor: "pointer" }} />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={addingData}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <div className='d-flex w-100 flex-column'>
                  <div className='d-flex flex-column'>
                    <img
                      src={file}
                      className='avatar'
                      alt='avatar'
                    />
                    <div className='uploadImage'>
                      <button
                        type="button"
                        style={{
                          display: 'block',
                          fontSize: 25,
                          color: '#79E80E',
                          padding: 0,
                        }}
                        onClick={() => document.getElementById('getFile').click()}
                        className='btn'
                      >
                        {imgLoading ? (
                          <div
                            className='spinner-border spinner-border-sm text-success'
                            role='status'
                          >
                            <span className='sr-only'>Loading...</span>
                          </div>
                        ) : (
                          <i className='fas fa-camera'></i>
                        )}
                      </button>
                    </div>
                  </div>
                  <input
                    type='file'
                    id='getFile'
                    style={{ display: 'none' }}
                    onChange={uploadFile}
                    accept='image/*'
                  />
                  <div className="form-inline row p-2 g-3">
                    <div className="col-md-4 mb-4">
                      <label className="form-label h2_label">
                        Service Name
                      </label>
                      <Field
                        type="text"
                        name="title"
                        // placeholder="Service Name"
                        className="form-control border-0 border-bottom"
                        style={{ paddingTop: '0px' }}
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label h2_label">
                        Service Price
                      </label>
                      <Field
                        type="text"
                        name="servicePrice"
                        // placeholder="Service Price"
                        className="form-control border-0 border-bottom"
                        style={{ paddingTop: '0px' }}
                      />
                      <ErrorMessage
                        name="servicePrice"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="col-md-4 mb-4">
                      <label className="form-label h2_label">
                        Type
                      </label>
                      <div className="dropdown">
                        <select
                          className="col-md-6 form-control border-0 border-bottom"
                          name="type"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          style={{ paddingTop: '0px' }}
                        >
                          <option value=""> </option>
                          <option value="Long">Long</option>
                          <option value="Short">Short</option>
                        </select>
                        <i className="fas fa-chevron-down dropdown-icon"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-2">
                    <label className="form-label h_label" >
                      Description
                    </label>
                    <Field
                      type="text"
                      name="description"
                      placeholder="Description"
                      className="form-control"
                      as="textarea"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className='form-inline p-2 row g-3'>
                    <div className="col-md-6">
                      <label className="form-label h_label">
                        How It Works
                      </label>
                      <Field
                        type="text"
                        name="how_it_works"
                        placeholder="How It Works"
                        className="form-control"
                        as="textarea"
                      />
                      <ErrorMessage
                        name="how_it_works"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label h_label">
                        What It Includes?
                      </label>
                      <Field
                        type="text"
                        name="what_it_includes"
                        placeholder="What It Includes?"
                        className="form-control"
                        as="textarea"
                      />
                      <ErrorMessage
                        name="what_it_includes"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 p-2">

                    <FieldArray name="faq">
                      {(arrayHelpers) => (
                        <div>
                          <div className='d-flex justify-content-between'>
                            <label className="form-label h_label" >
                              FAQs
                            </label>
                            <button className='button' type="button" onClick={() => arrayHelpers.push({ question: "", answer: "" })}>
                              <i className={`fas fa-plus iconClass2`}></i>
                              Add More
                            </button>
                          </div>
                          {values.faq.map((faq, index) => (
                            <div key={index} className='col-md-12 gap-3 d-flex'>
                              <div className="mb-2">
                                <label>Question {index + 1}</label>
                                <Field
                                  type="text"
                                  name={`faq[${index}].question`}
                                  className="form-control"
                                />
                              </div>
                              <div className="mb-2">
                                <label>Answer {index + 1}</label>
                                <Field
                                  type="text"
                                  name={`faq[${index}].answer`}
                                  className="form-control"
                                />
                              </div>
                              {index > 0 && (
                                <button className='btn2' type="button" onClick={() => arrayHelpers.remove(index)}>
                                  Remove
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
                <div className='actionbuttons'>
                  <button
                    className='btn'
                    type="submit"
                    style={{
                      backgroundColor: '#009B94',
                      color: '#fff'
                    }}
                    disabled={isSubmitting}
                  >
                    Save Changes{' '}
                    {apiLoading && (
                      <div
                        className='spinner-border spinner-border-sm text-white'
                        role='status'
                      >
                        <span className='sr-only'>Loading...</span>
                      </div>
                    )}
                  </button>
                  <button
                    className='btn'
                    type='reset'
                    style={{
                      color: '#B00020',
                      border: '1px solid #B00020'
                    }}
                    onClick={() => setModalShow(false)}
                  >
                    Discard
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

    </>
  );
}
