import React from 'react'

export default function InputFieldError({ errorMessage }) {
  return (
    <div>
      <p className='poppins normal weight-400 size-12 color-B00020'>
        {errorMessage}
      </p>
    </div>
  )
}
