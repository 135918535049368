// dependencies
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Loader from "../../../../Components/Loader";
import { Button, Spinner, Modal } from "react-bootstrap";
import axios from "axios";
import { API_CONSTANTS } from "../../../../API_CONSTANTS";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Toast from "../../../../Components/Toast/Toast";
import moment from "moment";
import {
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import ViewFile from "./viewFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
// Doctor profile overview link component
export default function LabOverview() {
  const params = useParams();
  const { value, loading } = useSelector((state) => state.getLabReducer);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingVerified, setLoadingVerified] = useState(false);
  const [appointmentStatus, setAppointmentStatus] = useState(
    value.appointment_status
  );
  const statusClassMap = {
    lab_pending: "Pending",
    lab_booked: "Booked",
    lab_cancelled: "Cancelled",
    lab_confirmed: "Confirmed",
    lab_completed: "Verified",
  };
  const appointmentStatusText = statusClassMap[appointmentStatus] || "";
  const statusClass = statusClassMap[appointmentStatus] || "";
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [selectedReason, setSelectedReason] = useState("Change Mind");
  const [customReason, setCustomReason] = useState("");
  const [ToastStates, setToastStates] = useState({
    message: "",
    show: "",
    color: "",
    icon: faTimesCircle,
  });
  // handling toast
  const handleToast = (message, icon, iconColor, className) => {
    setToastStates({
      ...ToastStates,
      icon: icon,
      message: message,
      color: iconColor,
      show: `show ${className}`,
    });
    setTimeout(() => setToastStates({ ...ToastStates, show: "" }), 2900);
  };

  const cancelAppointment = () => {
    setLoadingCancel(true);
    axios
      .post(
        `${API_CONSTANTS.LABURL}admin/lab/appointments/${params.id}/cancel`,
        {
          cancelReason:
            selectedReason === "Other" ? customReason : selectedReason,
        },
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        if (res.data.message === "Success") {
          setAppointmentStatus("lab_cancelled");
          handleToast(
            "Lab Test Cancel Successfully",
            faCheckCircle,
            "#009b94",
            "show-success"
          );
        } else {
          alert("Failed to cancel appointment. Please try again.");
        }
        setShow2(false);
        setLoadingCancel(false);
      })
      .catch((error) => {
        alert(error);
        setLoadingCancel(false);
      });
  };
  const verifiedAppointment = () => {
    setLoadingVerified(true);
    axios
      .get(
        `${API_CONSTANTS.LABURL}admin/lab/appointments/${params.id}?appoint_type=complete_appoint`,
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        if (res.data.message === "Success") {
          setAppointmentStatus("lab_complete");
          handleToast(
            "Lab Test Verified Successfully",
            faCheckCircle,
            "#009b94",
            "show-success"
          );
        } else {
          alert("Failed to verified appointment. Please try again.");
        }
        setShow3(false);
        setLoadingVerified(false);
      })
      .catch((error) => {
        alert(error);
        setLoadingVerified(false);
      });
  };
  const confirmAppointment = () => {
    setLoadingConfirm(true);
    axios
      .get(
        `${API_CONSTANTS.LABURL}admin/lab/appointments/${params.id}?email=sendEmailLab`,
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        if (res.data.message === "Success") {
          setAppointmentStatus("lab_confirmed");
          handleToast(
            "Lab Test Confirm &  Share Email Successfully",
            faCheckCircle,
            "#009b94",
            "show-success"
          );
        } else {
          alert("Failed to cancel appointment. Please try again.");
        }
        setShow(false);
        setLoadingConfirm(false);
      })
      .catch((error) => {
        alert(error);
        setLoadingConfirm(false);
      });
  };
  useEffect(() => {
    setAppointmentStatus(value.appointment_status || "");
  }, [value]);
  const isPdf =
    value?.prescription_Url && value.prescription_Url.endsWith(".pdf");
  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid my-3">
        <div className="d-flex justify-content-end gap-3 mb-3">
          {appointmentStatus === "lab_pending" && (
            <>
              <Link to={`/lab/profile/${value.appointmentId}/overview/edit`}>
                <Button className="b1"> Edit</Button>
              </Link>
              <Button className="b2" onClick={() => setShow2(true)}>
                Cancel
              </Button>
              <Button className="b1" onClick={() => setShow(true)}>
                Confirm Order
              </Button>
            </>
          )}
          {appointmentStatus === "lab_confirmed" && (
            <>
              <Button className="b2" onClick={() => setShow2(true)}>
                Cancel
              </Button>
              <Button className="b1" onClick={() => setShow3(true)}>
                Verified Order
              </Button>
            </>
          )}
        </div>
        <div className="mainover d-flex gap-3">
          <div className="leftover">
            <table className="table1 bg-white rounded profile-card-width mb-3 w-100">
              <tbody>
                <tr>
                  <th>Booked by</th>
                  <td>{value.name}</td>
                </tr>
                <tr>
                  <th>Emergency Contact</th>
                  <td>{value.patientPhone}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{value.email}</td>
                </tr>
              </tbody>
            </table>
            <table className="table1 bg-white rounded profile-card-width mb-3 w-100">
              <tbody>
                <tr>
                  <th>Booked for</th>
                  <td>{value.patientName}</td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>
                    {moment(value.appoint_date, "YYYY-MM-DD").format(
                      "D-MM-YYYY"
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Time</th>
                  <td>{value.appoint_start_time}</td>
                </tr>
                <tr>
                  <th>Lab</th>
                  <td className="text-capitalize">{value.labName} Lab</td>
                </tr>
                <tr>
                  <th>Type</th>
                  <td>
                    {value.testType === "homeSample"
                      ? "Home Sample"
                      : "Lab Visit"}
                  </td>
                </tr>
                {value.testType === "homeSample" && (
                  <tr>
                    <th>Location</th>
                    <td>{value.patientAddress}</td>
                  </tr>
                )}
                <tr>
                  <th>Age</th>
                  <td>{value?.age === "None" ? "0" : value?.age + " Years"}</td>
                </tr>
                <tr>
                  <th>Sub Total</th>
                  <td>{value.charges || "0"} Rs.</td>
                </tr>
                <tr>
                  <th>Discount</th>
                  <td>{value.discount || "0"}%</td>
                </tr>
                <tr>
                  <th>Grand Total</th>
                  <td>{value.grandTotal || "0"} Rs.</td>
                </tr>
              </tbody>
            </table>
            {value?.prescription_Url ? (
              <div className="bg-white rounded p-3 d-flex justify-content-between">
                <span>
                  <h6 className="mb-2">Uploaded Prescription</h6>
                  <ViewFile />
                </span>
                <span>
                  {isPdf ? (
                    <PictureAsPdfIcon
                      style={{
                        width: "43px",
                        height: "56px",
                        color: "red",
                        boxShadow: "1px 2px 3px 1px rgba(0, 0, 0, 0.30)",
                        borderRadius: "4px",
                      }}
                    />
                  ) : (
                    <img
                      src={value.prescription_Url}
                      alt="Prescripton"
                      style={{
                        maxWidth: "43px",
                        MaxHeight: "56px",
                        boxShadow: "1px 2px 3px 1px rgba(0, 0, 0, 0.30)",
                        borderRadius: "4px",
                      }}
                    />
                  )}
                </span>
              </div>
            ) : null}
          </div>
          <div className="rightover">
            <div className="bg-white rounded profile-card-width mb-3 w-100">
              <div className="d-flex justify-content-between mb-3">
                <h6 className="h6 mb-0">Tests</h6>
                <span
                  className={`d-flex justify-content-center spanhead ${statusClass}`}
                >
                  {appointmentStatusText}
                </span>
              </div>
              <table className="table3 w-100">
                <tbody>
                  <tr>
                    <th>Test Name</th>
                    <th>Lab Name</th>
                    <th>Quantity</th>
                    <th>Discount</th>
                    <th>Price</th>
                  </tr>
                  {value.testDetails.map((testDetail, index) => (
                    <tr key={index}>
                      <td className="text-capitalize">
                        {testDetail.MedicineName}
                      </td>
                      <td>{testDetail.vendor}</td>
                      <td>{testDetail.quantity}</td>
                      <td>{testDetail.discount}%</td>
                      <td>
                        <p style={{ margin: "0px" }}>{testDetail.subTotal}</p>
                        <p
                          style={{
                            margin: "0px",
                            textDecoration: "line-through",
                          }}
                        >
                          {parseInt(testDetail.subPrice) *
                            parseInt(testDetail.quantity)}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Lab Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <div className="bg-white rounded profile-card-width mb-3 w-100">
              <div className="d-flex justify-content-between mb-3">
                <h6 className="h6 mb-0">Details</h6>
                <span
                  className={`d-flex justify-content-center spanhead ${statusClass}`}
                >
                  {appointmentStatusText}
                </span>
              </div>
              <table className="table2">
                <tbody>
                  <tr>
                    <td>Booked by</td>
                    <th>{value.patientName}</th>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <th>
                      {moment(value.appoint_date, "YYYY-MM-DD").format(
                        "D-MM-YYYY"
                      )}
                    </th>
                  </tr>
                  <tr>
                    <td>Time</td>
                    <th>{value.appoint_start_time}</th>
                  </tr>
                  <tr>
                    <td>Lab</td>
                    <th>{value.labName}</th>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <th>{value.testType}</th>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <th>{value.patientAddress}</th>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <th>{value.charges}</th>
                  </tr>
                  {value.testDetails.map((testDetail, index) => (
                    <tr key={index}>
                      <td>{testDetail.MedicineName}</td>
                      <th>{testDetail.type + " X " + testDetail.quantity}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <Button className="b2" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button className="b1" onClick={confirmAppointment}>
              {loadingConfirm && <Spinner animation="border" size="sm" />}{" "}
              Confirm & Share via Email
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={() => setShow2(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Lab Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center gap-3">
          Are you sure you want to cancel this appointment?
          <div className="d-flex flex-column gap-2">
            <div className="radio-option d-flex align-items-center">
              <input
                type="radio"
                id="Change Mind"
                name="Change Mind"
                className="mx-2"
                value="Change Mind"
                checked={selectedReason === "Change Mind"}
                onChange={() => setSelectedReason("Change Mind")}
              />
              <label htmlFor="Change Mind">Change Mind</label>
            </div>
            <div className="radio-option d-flex align-items-center">
              <input
                type="radio"
                id="Rider Arrived Late"
                name="Rider Arrived Late"
                className="mx-2"
                value="Rider Arrived Late"
                checked={selectedReason === "Rider Arrived Late"}
                onChange={() => setSelectedReason("Rider Arrived Late")}
              />
              <label htmlFor="Rider Arrived Late">Rider Arrived Late</label>
            </div>
            <div className="radio-option d-flex align-items-center">
              <input
                type="radio"
                id="Not Available at Home"
                name="Not Available at Home"
                className="mx-2"
                value="Not Available at Home"
                checked={selectedReason === "Not Available at Home"}
                onChange={() => setSelectedReason("Not Available at Home")}
              />
              <label htmlFor="Not Available at Home">
                Not Available at Home
              </label>
            </div>
            <div className="radio-option d-flex align-items-center">
              <input
                type="radio"
                id="other"
                name="reason"
                className="mx-2"
                value="Other"
                checked={selectedReason === "Other"}
                onChange={() => setSelectedReason("Other")}
              />
              <label htmlFor="other">Other</label>
            </div>
            {selectedReason === "Other" && (
              <input
                type="text"
                value={customReason}
                onChange={(e) => setCustomReason(e.target.value)}
                placeholder="Enter your custom reason"
                className="mx-2"
                style={{
                  width: "95%",
                  resize: "vertical",
                  marginTop: "10px",
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  outline: "none",
                }}
              />
            )}
          </div>
          <div className="d-flex gap-3">
            <Button className="b3" onClick={() => setShow2(false)}>
              Close
            </Button>
            <Button className="b2" onClick={cancelAppointment}>
              {loadingCancel && <Spinner animation="border" size="sm" />}{" "}
              Confirm Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show3} onHide={() => setShow3(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Verified Lab Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3">
          Are you sure you want to Verified this appointment?
          <div className="d-flex gap-3">
            <Button className="b2" onClick={() => setShow3(false)}>
              Close
            </Button>
            <Button className="b1" onClick={verifiedAppointment}>
              {loadingVerified && <Spinner animation="border" size="sm" />}{" "}
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Toast
        cName={ToastStates.show}
        icon={ToastStates.icon}
        color={ToastStates.color}
        text={ToastStates.message}
      />
      <style jsx="true">
        {`
          .leftover {
            width: 25%;
          }
          .rightover {
            width: 75%;
          }
          .table2 {
            font-size: 14px;
          }
          .spanhead {
            margin: 10px 16px 0px 0px;
            width: 100px;
            border-radius: 10px;
          }
          input[type="radio"] {
            margin-right: 8px;
            font-size: 1rem;
            font-weight: 400;
            height: 20px;
            width: 24px;
          }
          .h6 {
            padding: 10px 0px 0px 16px;
            font-size: 1rem;
          }
          .table2 th {
            font-weight: 450;
            padding: 7px 16px;
          }
          .table3 th {
            font-weight: 450;
            padding: 7px 16px;
          }
          .table3 tr {
            padding: 7px 16px !important;
            border-bottom: 0.5px solid #dadada;
          }
          .table3 tr:last-child {
            border-bottom: unset;
          }
          .table2 td {
            padding: 0px 16px;
          }
          .table3 td {
            padding: 10px 16px;
          }
          .table1 {
            font-size: 14px;
          }
          .table1 tr:last-child {
            border-bottom: unset;
          }
          .table1 th {
            font-weight: 500;
            padding: 16px;
          }
          .table1 td {
            text-align: right;
            padding: 16px;
          }
          .table1 tr {
            border-bottom: 1px solid #dadada;
          }
          .Cancelled {
            background: #ffe0e0;
            color: Red;
          }
          .Booked {
            background: #d9f7e7a6;
            color: Green;
          }
          .missed {
            background: #ffe0e0;
            color: Red;
          }
          .Pending {
            background: #fff6e9;
            color: Orange;
          }
          .reschedule {
            background: #00b8af1a;
            color: #009b94;
          }
          .InProgress {
            background: #e0f3fb;
            color: #00568a;
          }
          .Confirmed {
            background: #009b94;
            color: #fff;
          }
          .Verified {
            background: #009b94;
            color: #fff;
          }
          .b1 {
            border-radius: 4px;
            border: 1px solid #009b94;
            background-color: #fff !important;
            color: #009b94;
          }
          .b1:hover {
            border-radius: 4px;
            border: 1px solid #009b94;
            background-color: #009b94 !important;
            color: #fff;
          }
          .b2 {
            border-radius: 4px;
            border: 1px solid #b00020;
            background-color: #fff !important;
            color: #b00020;
          }
          .b2:hover {
            border-radius: 4px;
            border: 1px solid #b00020;
            background-color: #b00020 !important;
            color: #fff;
          }
          .b3 {
            border-radius: 4px;
            border: 1px solid #009b94;
            background-color: #009b94 !important;
            color: #fff;
          }
          .b3:hover {
            border-radius: 4px;
            border: 1px solid #077d77;
            background-color: #077d77 !important;
            color: #fff;
          }
          .b1:focus {
            border: 1px solid #009b94;
            background-color: #fff !important;
            color: #009b94;
          }
          .b2:focus {
            border: 1px solid #b00020;
            background-color: #fff !important;
            color: #b00020;
          }
          .b1:focus {
            border: 1px solid #009b94;
            color: #009b94;
          }
          .b1.b2:disabled {
            pointer-events: none;
            background-color: #ccc !important;
            opacity: 0.6;
            color: #333 !important;
            border-color: #333 !important;
          }
          @media screen and (max-width: 1550px) {
            .leftover {
              width: 50%;
            }
            .rightover {
              width: 100%;
            }
          }
          @media screen and (max-width: 1050px) {
            .mainover {
              flex-wrap: wrap;
            }
          }
          @media screen and (max-width: 768px) {
            .leftover {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
}
