import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
//
export default function LockDay({ lock, booked, date, dayAction }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleAction = async () => {
    await dayAction(date)
    handleClose()
  }
  return (
    <>
      <div className='form-check'>
        <input
          className='form-check-input'
          type='checkbox'
          id='flexCheckDefault'
          onClick={handleShow}
          checked={lock}
          style={{ cursor: 'pointer' }}
          disabled={booked ? true : false}
        />
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className='d-flex flex-column p-2'>
          <p className='text-center h4' style={{ fontWeight: 'bold' }}>
            {lock === false ? 'Unlocking?' : 'Locking?'}
          </p>
          <span
            className='d-flex  justify-conten-center align-items-baseline p-2 '
            style={{
              borderLeft:
                lock === false ? '4px solid #009B94' : '4px solid #781F15',
              borderRadius: 5,
              margin: '10px 0',
              background: lock === false ? '#E0F3F2' : '#FFE9DA',
            }}
          >
            <i
              class='fas fa-exclamation-triangle mx-2'
              style={{ color: lock === false ? '#009B94' : '#781F15' }}
            ></i>
            <span className='d-flex flex-column p-1'>
              <p
                className='m-0'
                style={{
                  color: lock === false ? '#009B94' : '#781F15',
                  fontWeight: 'bold',
                }}
              >
                Warning
              </p>
              <p
                className='m-0'
                style={{ color: lock === false ? '#009B94' : '#781F15' }}
              >
                Are you sure you want to {lock === false ? 'unlock' : 'lock'}{' '}
                the day ?
              </p>
            </span>
          </span>
          <div className='d-flex align-self-center'>
            <button
              onClick={handleClose}
              style={{
                margin: '0 5px',
                padding: '5px 25px',
                borderRadius: 5,
                color: '#fff',
                background: '#777',
                border: 'none',
              }}
            >
              No
            </button>
            <button
              onClick={handleAction}
              style={{
                margin: '0 5px',
                padding: '5px 25px',
                borderRadius: 5,
                color: '#fff',
                background: lock === false ? '#009B94' : '#E12D3A',
                border: 'none',
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
