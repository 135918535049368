import React, { useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import { Outlet } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import corporateIcon from "../../../assets/icons/corporateIcon.png";
import { getCorporate } from "./slicer";

//

export default function CorporateProfilePage() {
  const params = useParams();
  const dispatch = useDispatch();
  const { loading, value } = useSelector(
    (state) => state.getCorporateProfileReducer
  );
  const PROFILE_NAV_LINKS = [
    {
      navLink: "Overview",
      navRoute: `/corporates/${params.corporate}/${params.id}/overview`,
    },
    {
      navLink: "Employees",
      navRoute: `/corporates/${params.corporate}/${params.id}/employees`,
    },
    {
      navLink: "Plans",
      navRoute: `/corporates/${params.corporate}/${params.id}/plans`,
    },
    {
      navLink: "Transactions",
      navRoute: `/corporates/${params.corporate}/${params.id}/transactions`,
    },
    {
      navLink: "Settings",
      navRoute: `/corporates/${params.corporate}/${params.id}/settings`,
    },
    {
      navLink: "Consultations",
      navRoute: `/corporates/${params.corporate}/${params.id}/consultation`,
    },
  ];

  useEffect(() => {
    dispatch(getCorporate(params.id));
  }, [params.id]);
  return (
    <>
      <div className="container-fluid profile-header">
        <div className="row pt-4 mb-1 prof">
          <div className="col">
            <div className="d-flex ms-4 align-items-center">
              <span className="me-4">
                <img
                  src={corporateIcon}
                  alt=""
                  style={{ width: 72, borderRadius: 50, height: 72 }}
                />
              </span>
              <div className="d-flex flex-column">
                <h5
                  style={{ fontWeight: 500, marginBottom: 15, marginTop: -4 }}
                >
                  {loading ? (
                    <p className="card-text placeholder-glow">
                      <span className="placeholder col-7"></span>
                    </p>
                  ) : (
                    value.companyName
                  )}
                </h5>
                <h6
                  style={{ fontWeight: 400, color: "#5C5C5C", marginBottom: 0 }}
                >
                  {value.corporateType}
                </h6>
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="d-flex justify-content-end"
              style={{ columnGap: 5 }}
            ></div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col">
            <div className="d-flex ms-4" style={{ columnGap: 5 }}>
              {PROFILE_NAV_LINKS.map((navItem) => (
                <NavLink
                  to={navItem.navRoute}
                  key={navItem.navLink}
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  style={{ fontSize: 14 }}
                >
                  {navItem.navLink}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="containter-fluid">
        <Outlet />
      </div>
      <style jsx="true">
        {`
          .profile-header {
            background: #e0f3f2;
            height: 160px;
          }
          .active {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .inactive {
            padding: 7px 15px;
          }
          @media only screen and (max-width: 1200px) and (min-width: 1050px) {
            .prof {
              margin-bottom: 0px !important;
              padding-top: 10px !important;
            }
          }
          @media screen and (max-width: 950px) {
            .prof {
              margin-bottom: 0px !important;
              padding-top: 10px !important;
            }
          }
        `}
      </style>
    </>
  );
}
