import React, { useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { Outlet } from 'react-router'
//
import { useDispatch, useSelector } from 'react-redux'
//
import { getLabOverview } from './Overview/LabOverviewSlice'
import { Link } from 'react-router-dom';
//
export default function LabProfilePages() {
  const params = useParams()
  const dispatch = useDispatch()

  const { value } = useSelector((state) => state.getLabReducer)

  const PROFILE_NAV_LINKS = [
    {
      navLink: 'Overview',
      navRoute: `/lab/profile/${params.id}/overview`,
    },
  ]
  useEffect(() => {
    let mounted = true
    if (mounted) {
      dispatch(getLabOverview(params.id))
    }
    mounted = false
  }, [])

  return (
    <>
      <div className='container-fluid profile-header'>
        <div className='row pt-4 mb-1'>
          <div className='col'>
            <div className='d-flex ms-4'>
              <Link to={`/patients/profile/${value?.userId}/overview`} className='patient-link'>
                <div className='patient d-flex ms-4 align-items-start me-4'>
                  <span className='me-4'>
                    <div className='patient-avatar'>
                      <img
                        src={
                          value.profileUrl
                            ? value?.profileUrl
                            : 'https://img.icons8.com/material/72/000000/user-male-circle--v1.png'
                        }
                        alt=''
                        style={{ width: 72, borderRadius: 50, height: 72 }}
                      />
                    </div>
                  </span>
                  <div className='d-flex flex-column mt-2'>
                    <h5
                      style={{ fontWeight: 500, marginBottom: 15, marginTop: -4 }}
                    >
                      {value.name}
                    </h5>
                    <h6
                      style={{
                        fontWeight: 400,
                        color: '#5C5C5C',
                        marginBottom: 0,
                      }}
                    >
                      {'Patient ID  : ' + value.patientPhone}
                    </h6>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className='row pt-4'>
          <div className='col'>
            <div className='d-flex ms-4' style={{ columnGap: 10 }}>
              {PROFILE_NAV_LINKS.map((navItem) => (
                <NavLink
                  to={navItem.navRoute}
                  key={navItem.navLink}
                  className={({ isActive }) =>
                    isActive ? 'active' : 'inactive'
                  }
                  style={{ fontSize: 14 }}
                >
                  {navItem.navLink}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='containter-fluid'>
        <Outlet />
      </div>
      <style jsx='true'>
        {`
          .profile-header {
            background: #e0f3f2;
            height: 160px;
          }
          .active {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .active:hover {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .inactive {
            padding: 7px 15px;
          }
          .border-line {
            border-left: 2px solid #009b94;
            border-radius: 5px;
          }
          .patient-link:hover {
            background-color: #c8efe1 !important;
          }
        `}
      </style>
    </>
  )
}
