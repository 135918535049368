import React, { Component } from 'react'
// import AddNewUserButton from '../../../../Components/AddNewUserButton'
import ConsultationTableList from './ConsultationTableList'
export default function ConsultationsList() {
  return (
    <>
      {/* <AddNewUserButton
        link={'/consultations/new'}
        buttonText={'Book Consultation'}
      /> */}
      <ConsultationTableList />
    </>
  )
}
