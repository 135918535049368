/* eslint-disable no-unused-vars */
// Dependencies
import React, { useState } from "react";
import { Route, Routes } from "react-router";
// import firebase from '../../firebase'
// global components
import NavBar from "../NavBar/index";
import SideBar from "../SideBar/index";
import DashboardPage from "./DashboardPage";
// admin routes
import Admin from "../../Pages/admin/index";
import AdminProfilePage from "../../Pages/admin/Components/AdminProfilePage/AdminProfilePage";
import AddAdminForm from "../../Pages/admin/Components/AddAdminForm";
import AdminProfileOverview from "../../Pages/admin/Components/AdminProfilePage/AdminProfileOverview";
// doctor routes
import Doctor from "../../Pages/Doctor/index";
import AddNewDoctorForm from "../../Pages/Doctor/Components/AddDoctor/AddNewDoctorForm";
import DoctorInfoForm from "../../Pages/Doctor/Components/AddDoctor/FormPages";
import DoctorProfilePages from "../../Pages/Doctor/Components/DoctorProfile/DoctorProfilePages";
import DoctorProfileOverview from "../../Pages/Doctor/Components/DoctorProfile/Overview/DoctorProfileOverview";
import Appointments from "../../Pages/Doctor/Components/DoctorProfile/Appointments/Appointments";
import Availability from "../../Pages/Doctor/Components/DoctorProfile/Availability/index.js";
import DelayedAppointments from "../../Pages/Doctor/Components/DoctorProfile/DelayedAppointments/DelayedAppointmentsTable";
import DocNotifications from "../../Pages/Doctor/Components/DoctorProfile/Notifications";
import Logs from "../../Pages/Doctor/Components/DoctorProfile/Logs/Logs";
// doctor sub menu routes
import DiseasePreset from "../../Pages/Doctor/sub-menu/DiseasePreset/index.js";
// consultations routes
import Consultations from "../../Pages/consultation";
import ConsultationProfilePage from "../../Pages/consultation/Components/ConsultationProfile/ConsultationProfilePage";
import ConsultationsOverview from "../../Pages/consultation/Components/ConsultationProfile/overview/ConsultationsOverview";
import Eprescription from "../../Pages/consultation/Components/ConsultationProfile/e-prescription/Eprescription";
import Reviews from "../../Pages/consultation/Components/ConsultationProfile/reviews/Reviews";
import PaymentInfo from "../../Pages/consultation/sub-menu/PaymentStatus.js";
import RecordingHistory from "../../Pages/consultation/Components/ConsultationProfile/recording/Recording";
import CallLogs from "../../Pages/consultation/Components/ConsultationProfile/callLogs/Logs";
//Services Routes
import NurseServices from "../../Pages/servicesnurse";
import ServicesProfilePage from "../../Pages/servicesnurse/Components/ServicesProfile/ServicesProfilePage";
import NurseEprescription from "../../Pages/servicesnurse/Components/ServicesProfile/e-prescription/NurseEprescription";
import ServicesOverview from "../../Pages/servicesnurse/Components/ServicesProfile/overview/ServicesOverview";
import ServicesHistory from "../../Pages/servicesnurse/Components/ServicesProfile/servicesHistory/ServicesHistory";
// Patient Pages and Routes
import Patient from "../../Pages/Patient/index";
import AddPatientForm from "../../Pages/Patient/AddPatientForm/AddPatientForm";
import PatientProfile from "../../Pages/Patient/PatientProfile/index";
import Consultation from "../../Pages/Patient/PatientProfile/profilePages/Consultations";
import Overview from "../../Pages/Patient/PatientProfile/profilePages/Overview/index";
import Services from "../../Pages/Patient/PatientProfile/profilePages/Services/index";
import Lab from "../../Pages/Patient/PatientProfile/profilePages/Lab/index";
import MedicalHistory from "../../Pages/Patient/PatientProfile/profilePages/MedicalHistory/index";
import Wallet from "../../Pages/Patient/PatientProfile/profilePages/Wallet/index.js";
import PatientNotifications from "../../Pages/Patient/PatientProfile/profilePages/Notifications";
import BookService from "../../Pages/Patient/PatientProfile/profilePages/Services/BookService/BookService";
import BookLab from "../../Pages/Patient/PatientProfile/profilePages/Lab/BookLab/BookLab";
// Lab Routes
import LabList from "../../Pages/Lab/LabList";
import LabProfilePages from "../../Pages/Lab/Component/LabProfilePages";
import LabOverview from "../../Pages/Lab/Component/Overview/LabOverview";
// App's FeedBack routes
import Feedback from "../../Pages/Feedback/index.js";
import AddNewDiseasePreset from "../../Pages/Doctor/sub-menu/DiseasePreset/AddNewDiseasePreset";
import EditDisease from "../../Pages/Doctor/sub-menu/DiseasePreset/EditDisease";
// role and permission routes
import RolePermission from "../../Pages/rolePermission";
// add data routes
import AddData from "../../Pages/addData/AddData";
import Specialization from "../../Pages/addData/components/Specializations/Specializaitons";
import Conditions from "../../Pages/addData/components/Conditions/Conditions";
import AddServices from "../../Pages/addData/components/Services/Services";
import AddMedicine from "../../Pages/addData/components/Medicine/medicine";
import Subscription from "../../Pages/addData/components/Subscription/Component/index";
import HealthHistory from "../../Pages/addData/components/HealthHistory/HealthHistory";
import MainPromoList from "../../Pages/addData/components/Promo Code/index.js";
// firebase functions
import { onMessageListener } from "../../firebase";
import { Toast } from "react-bootstrap";
import Transaction from "../../Pages/Transaction/TransactionList/TransactionList";
// blogs routes
import Blog from "../../Pages/Blog";
import EditPost from "../../Pages/Blog/components/EditPost";
// Nurse Routes
import Nurse from "../../Pages/Nurse/index";
import AddNewNurseForm from "../../Pages/Nurse/Components/AddNurse/AddNewNurseForm";
import NurseInfoForm from "../../Pages/Nurse/Components/AddNurse/FormPages";
import NurseProfilePages from "../../Pages/Nurse/Components/NurseProfile/NurseProfilePages";
import NurseProfileOverview from "../../Pages/Nurse/Components/NurseProfile/Overview/NurseProfileOverview";
import Appointment from "../../Pages/Nurse/Components/NurseProfile/Appointments/Appointment";
import Availabilitys from "../../Pages/Nurse/Components/NurseProfile/Availability/index.js";
import DelayedAppointment from "../../Pages/Nurse/Components/NurseProfile/DelayedAppointments/DelayedAppointmentTable";
import NurseNotifications from "../../Pages/Nurse/Components/NurseProfile/Notifications";
// Reviews Routes
import ReviewList from "../../Pages/Review/ReviewList";
import NurseServiceList from "../../Pages/addData/components/Nurse Services/NurseServiceList";
// Corporate routes
import CorportatesList from "../../Pages/corporates/index.jsx";
// Notifications route
import Notifications from "../../Pages/notifications";
// import DocNotifications from '../../Pages/Nurse/Components/NurseProfile/Notifications'
import { gettoken } from "../../firebase";
import EditLab from "../../Pages/Lab/Component/Overview/EditLab/EditLab";
import Review from "../../Pages/Doctor/Components/DoctorProfile/Reviews/Review";
import { Link } from "react-router-dom";
import CorporateProfilePage from "../../Pages/corporates/corporatePage/index.jsx";
import Employees from "../../Pages/corporates/corporatePage/Employees.jsx";
import Plans from "../../Pages/corporates/corporatePage/Plans.jsx";
import Transactions from "../../Pages/corporates/corporatePage/Transactions.jsx";
import CorporatePlans from "../../Pages/corporates/subPages/plans/index.jsx";
import CorporateTransactions from "../../Pages/corporates/subPages/transactions/index.jsx";
import CorporateOverView from "../../Pages/corporates//corporatePage/Overview.jsx";
import CorporateSettings from "../../Pages/corporates//corporatePage/Settings.jsx";
import SendNotifications from "../../Pages/addData/components/SendNotifications/index.jsx";
import Referral from "../../Pages/Patient/PatientProfile/profilePages/Referral/index.js";
import LabPartners from "../../Pages/Lab/subPages/partners/index.js";
import LabPackages from "../../Pages/Lab/subPages/packages/index.js";
import LabTest from "../../Pages/Lab/subPages/partners/Test.js";
import AddPackage from "../../Pages/Lab/subPages/packages/AddPackage.js";
import SubTransaction from "../../Pages/Transaction/sub-menu/TransactionList.js";
import CorporateConsultations from "../../Pages/corporates/corporatePage/consultations.jsx";
// Dashboard Route Component
export default function Dashboard() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    body: "",
    url: "",
    appointmentId: "",
  });
  const [isTokenFound, setTokenFound] = useState(false);
  gettoken(setTokenFound);
  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        url: payload.notification.image || payload.data.image,
        appointmentId: payload.data.appointmentId || payload.notification.appointmentId,
      });
    })
    .catch((err) => console.log("failed: ", err));
  return (
    <>
      <div className="dashboard d-grid w-100 h-100">
        <nav className="custom-navbar">
          <NavBar />
        </nav>
        <aside className="custom-sidebar">
          <SideBar />
        </aside>
        <div className="wrapper-content">
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            {/*admins routes */}
            <Route path="/admins" element={<Admin />} />
            <Route path="/admins/new" element={<AddAdminForm />} />
            <Route path="/admins/profile/:id" element={<AdminProfilePage />}>
              <Route
                path="/admins/profile/:id/overview"
                element={<AdminProfileOverview />}
              />
            </Route>
            {/*Doctors routes */}
            <Route path="/doctors" element={<Doctor />} />
            <Route path="/doctors/new" element={<AddNewDoctorForm />} />
            <Route
              path="/doctors/personal_info/:id"
              element={<DoctorInfoForm />}
            />
            <Route path="/doctors/profile/:id" element={<DoctorProfilePages />}>
              <Route
                path="/doctors/profile/:id/overview"
                element={<DoctorProfileOverview />}
              />
              <Route
                path="/doctors/profile/:id/delayedappointments"
                element={<DelayedAppointments />}
              />
              <Route
                path="/doctors/profile/:id/appointments"
                element={<Appointments />}
              />
              <Route
                path="/doctors/profile/:id/availability"
                element={<Availability />}
              />
              <Route
                path="/doctors/profile/:id/notifications"
                element={<DocNotifications />}
              />
              <Route path="/doctors/profile/:id/logs" element={<Logs />} />
              <Route path="/doctors/profile/:id/reviews" element={<Review />} />
            </Route>
            {/* doctor sub menu routes */}
            <Route path="/disease-preset" element={<DiseasePreset />} />
            <Route
              path="/disease-preset/new"
              element={<AddNewDiseasePreset />}
            />
            <Route
              path="/disease-preset/edit/:name"
              element={<EditDisease />}
            />
            <Route path="/sub-menu" element={<PaymentInfo />} />

            {/*Transaction routes */}
            <Route path="/transactions" element={<Transaction />} />
            <Route path="/subscription-transactions" element={<SubTransaction />} />

            {/*patients routes */}
            <Route path="/patients" element={<Patient />} />
            <Route path="/patients/new" element={<AddPatientForm />} />
            <Route path="/patients/profile/:id" element={<PatientProfile />}>
              <Route
                path="/patients/profile/:id/overview"
                element={<Overview />}
              />
              <Route
                path="/patients/profile/:id/consultations"
                element={<Consultation />}
              />
              <Route
                path="/patients/profile/:id/referral"
                element={<Referral />}
              />
              <Route
                path="/patients/profile/:id/services"
                element={<Services />}
              />
              <Route
                path="/patients/profile/:id/services/bookservices/new"
                element={<BookService />}
              />
              <Route path="/patients/profile/:id/lab" element={<Lab />} />
              <Route
                path="/patients/profile/:id/lab/booklab/new"
                element={<BookLab />}
              />
              <Route
                path="/patients/profile/:id/medicalhistory"
                element={<MedicalHistory />}
              />
              <Route path="/patients/profile/:id/wallet" element={<Wallet />} />
              <Route
                path="/patients/profile/:id/notifications"
                element={<PatientNotifications />}
              />
            </Route>
            {/* Lab Routes */}
            <Route path="/lab" element={<LabList />} />
            <Route path="/lab/profile/:id" element={<LabProfilePages />}>
              <Route
                path="/lab/profile/:id/overview"
                element={<LabOverview />}
              />
              <Route
                path="/lab/profile/:id/overview/edit"
                element={<EditLab />}
              />
            </Route>
            <Route path="/lab/partners" element={<LabPartners />} />
            <Route path="/lab/partners/:id" element={<LabTest />} />
            <Route path="/lab/packages" element={<LabPackages />} />
            <Route path="/lab/packages/new" element={<AddPackage />} />
            {/*consultations routes */}
            <Route path="/consultations" element={<Consultations />} />
            <Route
              path="/consultations/profile/:id"
              element={<ConsultationProfilePage />}
            >
              <Route
                path="/consultations/profile/:id/overview"
                element={<ConsultationsOverview />}
              />
              <Route
                path="/consultations/profile/:id/e-prescription"
                element={<Eprescription />}
              />
              <Route
                path="/consultations/profile/:id/recording"
                element={<RecordingHistory />}
              />
              <Route
                path="/consultations/profile/:id/calllogs"
                element={<CallLogs />}
              />
              <Route
                path="/consultations/profile/:id/reviews"
                element={<Reviews />}
              />
            </Route>
            {/* Reviews Routes */}
            <Route path="/reviews" element={<ReviewList />} />
            {/* Services Of Nurse Routes */}
            <Route path="/services" element={<NurseServices />} />

            <Route
              path="/services/profile/:id"
              element={<ServicesProfilePage />}
            >
              <Route
                path="/services/profile/:id/overview"
                element={<ServicesOverview />}
              />
              <Route
                path="/services/profile/:id/e-prescription"
                element={<NurseEprescription />}
              />
              <Route
                path="/services/profile/:id/service-history"
                element={<ServicesHistory />}
              />
              <Route
                path="/services/profile/:id/reviews"
                element={<Reviews />}
              />
            </Route>

            {/*mkmmkm*/}
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/rolebasedaccess" element={<RolePermission />} />
            <Route path="/adddata" element={<AddData />}>
              <Route
                path="/adddata/specializations"
                element={<Specialization />}
              />
              <Route path="/adddata/conditions" element={<Conditions />} />
              <Route path="/adddata/services" element={<AddServices />} />
              <Route path="/adddata/medicine" element={<AddMedicine />} />
              <Route path="/adddata/subscription" element={<Subscription />} />
              <Route path="/adddata/promo" element={<MainPromoList />} />
              <Route
                path="/adddata/nurseservice"
                element={<NurseServiceList />}
              />
              <Route
                path="/adddata/healthHistory"
                element={<HealthHistory />}
              />
              <Route
                path="/adddata/sendnotifications"
                element={<SendNotifications />}
              />
            </Route>
            {/* Blogs Routes */}
            <Route path="/blogs" element={<Blog />} />
            <Route path="/blogs/CreateBlog" element={<EditPost />} />
            <Route path="/blogs/EditPost/:name" element={<EditPost />} />

            {/* Nurses Routes */}
            <Route path="/nurse" element={<Nurse />} />
            <Route path="/nurse/new" element={<AddNewNurseForm />} />
            <Route
              path="/nurses/personal_info/:id"
              element={<NurseInfoForm />}
            />
            <Route path="/nurses/profile/:id" element={<NurseProfilePages />}>
              <Route
                path="/nurses/profile/:id/overview"
                element={<NurseProfileOverview />}
              />
              <Route
                path="/nurses/profile/:id/delayedappointments"
                element={<DelayedAppointment />}
              />
              <Route
                path="/nurses/profile/:id/appointments"
                element={<Appointment />}
              />
              Availability
              <Route
                path="/nurses/profile/:id/availability"
                element={<Availabilitys />}
              />
              <Route
                path="/nurses/profile/:id/notifications"
                element={<NurseNotifications />}
              />
            </Route>
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/corporates" element={<CorportatesList />} />
            <Route
              path="/corporates/:corporate/:id/"
              element={<CorporateProfilePage />}
            >
              <Route
                path="/corporates/:corporate/:id/overview"
                element={<CorporateOverView />}
              />
              <Route
                path="/corporates/:corporate/:id/employees"
                element={<Employees />}
              />
              <Route
                path="/corporates/:corporate/:id/plans"
                element={<Plans />}
              />
              <Route
                path="/corporates/:corporate/:id/transactions"
                element={<Transactions />}
              />
              <Route
                path="/corporates/:corporate/:id/settings"
                element={<CorporateSettings />}
              />
              <Route
                path="/corporates/:corporate/:id/consultation"
                element={<CorporateConsultations />}
              />
            </Route>
            <Route path="/corporates/plans" element={<CorporatePlans />} />
            <Route
              path="/corporates/transactions"
              element={<CorporateTransactions />}
            />
          </Routes>
        </div>
      </div>
      <Toast
        onClose={() => setShow(false)}
        show={show}
        position={"top-center"}
        delay={3000}
        animation
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          zIndex: 9,
        }}
      >
        <Toast.Header
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <img
            src={
              notification.url === "None"
                ? "https://img.icons8.com/material/72/000000/user-male-circle--v1.png"
                : notification.url
                  ? notification.url
                  : "https://img.icons8.com/material/72/000000/user-male-circle--v1.png"
            }
            className="rounded mr-2"
            style={{ width: "25px", height: "25px" }}
            alt="toast"
          />
          <strong className="mr-auto">{notification.title}</strong>
          <small>just now</small>
        </Toast.Header>
        <Link
          to={
            notification?.appointmentId?.startsWith("LAPTD")
              ? `/lab/profile/${notification?.appointmentId}/overview`
              : `/consultations/profile/${notification?.appointmentId}/overview`
          }
        >
          <Toast.Body>{notification.body}</Toast.Body>
        </Link>
      </Toast>
      <style jsx="true">
        {`
          .dashboard {
            grid-template-rows: 60px 1fr;
            grid-template-columns: 256px 1fr;
          }
          .custom-navbar {
            grid-column: 1/4;
            grid-row: 1/2;
          }
          .custom-sidebar {
            grid-column: 1/2;
            grid-row: 2/3;
          }
          .wrapper-content {
            grid-column: 2/3;
            grid-row: 2/3;
            background: #f6f6f6;
          }
          select:focus-visible {
            outline: none;
          }
          select {
            border: none;
            padding: 7px;
            border-radius: 7px;
          }
          @media screen and (max-width: 1050px) {
            .dashboard {
              grid-template-columns: 1fr;
            }
            .wrapper-content {
              grid-column: 1/2;
              grid-row: 3/3;
            }
          }
        `}
      </style>
    </>
  );
}
