import { createSlice } from '@reduxjs/toolkit'
//

export const appointmentsFilterSlice = createSlice({
  name: 'appointmentFilter',
  initialState: {
    value: '',
    value1: '',
    value2: '',
    value3: '',
    value4: '',
  },
  reducers: {
    all: (state, action) => {
      state.value = ''
    },
    // status filter value
    booked: (state, action) => {
      state.value = 'booked'
    },
    pending: (state, action) => {
      state.value = 'pending'
    },
    cancelled: (state, action) => {
      state.value = 'cancelled'
    },
    // appoint_date_from filter value
    appointmentDateFrom: (state, action) => {
      const { payload } = action
      state.value1 = payload
    },
    // appoint_date_to filter value
    appointmentDateTo: (state, action) => {
      const { payload } = action
      state.value2 = payload
    },
    created_at: (state, action) => {
      const { payload } = action
      state.value3 = payload
    },
    Booked: (state, action) => {
      state.value4 = 'Booked'
    },
    Instant: (state, action) => {
      state.value4 = 'Instant'
    },
    FollowUp: (state, action) => {
      state.value4 = 'FollowUp'
    },
  },
})

export const appointmentsFilterReducer = appointmentsFilterSlice.reducer
