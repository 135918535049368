import { createSlice } from '@reduxjs/toolkit'
//

export const LabFilterSlice2 = createSlice({
  name: 'labFilter',
  initialState: {
    value: '',
    value1: '',
    value2: '',
    value3: '',
  },
  reducers: {
    all: (state, action) => {
      state.value = ''
    },
    homeSample: (state, action) => {
      state.value = 'homeSample'
    },
    labVisit: (state, action) => {
      state.value = 'labVisit'
    },
   
    // second filter value
    alltype: (state, action) => {
      state.value1 = ''
    },
    chugtai: (state, action) => {
      state.value1 = 'LabBooked#chugtai';
    },
    idc: (state, action) => {
      state.value1 = 'LabBooked#idc';
    },    
    //3rd value 
    allstatus: (state, action) => {
      state.value3 = 'all'
    },
    lab_confirmed: (state, action) => {
      state.value3 = 'lab_confirmed'
    },
    lab_completed: (state, action) => {
      state.value3 = 'lab_completed'
    },
    lab_missed: (state, action) => {
      state.value3 = 'lab_missed'
    },
    lab_inprogress: (state, action) => {
      state.value3 = 'lab_inprogress'
    },
    lab_pending: (state, action) => {
      state.value3 = 'lab_pending'
    },
    lab_cancelled: (state, action) => {
      state.value3 = 'lab_cancelled'
    },
    // 4th filter value
    appoint_date: (state, action) => {
      const { payload } = action
      state.value2 = payload
    },
  },
})
export const { all, chugtai, idc, appoint_date } =
LabFilterSlice2.actions
export const LabFilterReducer2 = LabFilterSlice2.reducer