import React, { useMemo, useEffect, useState } from "react";
import Table from "../../../Components/Table/index";
import axios from "axios";
import { API_CONSTANTS } from "../../../API_CONSTANTS";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { useSelector } from "react-redux";
import style from "../../../Style/corporate.module.css";
import { ToastContainer } from "react-toastify";
import { Link, useParams } from "react-router-dom";
// import PatientFilterForm from "./PatientFilterForm";
import AlertMessage from "../../../Components/AlertMessage";
export default function CorporateConsultations() {
    // variables
    const { value } = useSelector((state) => state.getCorporateProfileReducer);
    const params = useParams();
    const [page, setPage] = useState(0);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
    const [patientList, setpatientList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = React.useRef(0);
    
    
    const fetchData = React.useCallback(
        ({ pageSize, pageIndex }) => {
            // This will get called when the table needs new data
            // You could fetch your data from literally anywhere,
            // even a server. But for this example, we'll just fake it.

            // Give this fetch an ID
            const fetchId = lastEvaluatedKey;
            // We'll even set a delay to simulate a server here

            // Only update the data if this is the latest fetch
            if (fetchId === fetchIdRef.current) {
                const startRow = pageSize * pageIndex;
                const endRow = startRow + pageSize;
                setpatientList(patientList.slice(startRow, endRow));

                // Your server could send back total page count.
                // For now we'll just fake it, too
                setPageCount(Math.ceil(patientList.length / pageSize));
            }
        },
        [patientList, lastEvaluatedKey]
    );
    //
    // API Hatching data of all admins
    const userList = () => {
        axios
            .get(
                `${API_CONSTANTS.BASEURL_4}admin/corporates/${params.corporate}/consultations`,
                {
                    headers: API_CONSTANTS.headers(),
                }
            )
            .then((res) => {
                setIsLoading(true);
                setpatientList(res?.data?.response?.Items);
                setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.reload();
                    window.localStorage.clear();
                }
                setIsLoading(true);
                setpatientList([]);
                // setError(error)
            });
    };
    // handling load more data
    const handleLoadMoreData = () => {
        axios
            .get(
                `${API_CONSTANTS.BASEURL_4}admin/corporates/${params.corporate}/consultations?last=${lastEvaluatedKey}`,
                {
                    headers: API_CONSTANTS.headers(),
                }
            )
            .then((response) => {
                setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
                var patientListNextPage = response.data.response.Items;
                setpatientList([...patientList, ...patientListNextPage]);
                setPage(page + 1);
            })
            .then(() => setLoading(true));
    };
    const columns = useMemo(
        () => [
            {
                Header: "Sr",
                accessor: (row, index) => index + 1,
            },
            {
                Header: "Consultation Type",
                accessor: (property) =>
                    property.communicationMode
                        ? `${property.appoint_type} Appointment: ` +
                        property.communicationMode
                        : "",
            },
            {
                Header: "Patient Name",
                accessor: "patientInfo.name",
                Cell: ({ cell: { value } }) => value || "N/A",
            },
            {
                Header: "Patient Phone",
                accessor: "patientPhone",
            },
            {
                Header: `Doctor's Name`,
                accessor: "doctorName",
            },
            {
                Header: "Status",
                accessor: (property) => {
                    const status = property.appointment_status.replace(
                        /(?:^|\s)\S/g,
                        function (a) {
                            return a.toUpperCase();
                        }
                    );
                    if (status === "Reschedule") {
                        return "Rescheduled";
                    } else if (status === "Reschedule") {
                        return "Rescheduled";
                    }
                    return status;
                },
            },
            {
                Header: "Consultation Date/time",
                accessor: (a) => moment(a.appoint_date).format("DD-MM-YYYY h:mm A"),
            },
            {
                Header: "Company",
                accessor: (a) =>
                    a?.corporateInfo ? a?.corporateInfo?.companyName : "-",
            },
            {
                Header: "View",
                accessor: (a) => (
                    <Link to={`/consultations/profile/${a.appointmentId}/overview`}>
                        View
                    </Link>
                ),
            },
        ],
        []
    );
    const is_SessionExpire = () => {
        window.localStorage.clear();
        window.location.reload();
    };

    // rendering API
    useEffect(() => {
        if (patientList.length < 1) {
            userList();
        }
    }, []);
    // Table rendering
    if (error) {
        return (
            <AlertMessage
                errorMessage={
                    error.message === "Request failed with status code 404"
                        ? "This record does not exist"
                        : error.message === "Request failed with status code 401"
                            ? is_SessionExpire()
                            : error.message
                }
            />
        );
    } else {
        return (
            <>
                <section className={style.corporatePageSection}>
                    {/* corporate table listing  */}
                    <div className="row rounded bg-white m-4">
                        <h3
                            style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}
                        >
                            {value.companyName} Consultations
                            {!isLoading && (
                                <Spinner
                                    animation="border"
                                    size="sm"
                                    style={{ color: "#24AAA4" }}
                                />
                            )}
                        </h3>

                        <Table
                            pageNumber={page}
                            columns={columns}
                            data={patientList}
                            routingLink={``}
                            pageCount={pageCount}
                            fetchData={fetchData}
                            loadMoreList={handleLoadMoreData}
                            lastEvaluatedKey={lastEvaluatedKey}
                            loading={loading}
                            recordDownload={true}
                            setLoading={setLoading}
                        />
                    </div>
                </section>
                <ToastContainer />
            </>
        );
    }
}
