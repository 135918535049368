import React from "react";
import ReactDOM from "react-dom";
import App from "./App/index.jsx";
import "./Style/index.css";
import "bootstrap";
import "react-bootstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { createTheme, ThemeProvider } from "@mui/material";
// material ui theming
const theme = createTheme({
  palette: {
    primary: {
      main: "#009B94",
    },
    secondary: {
      main: "#4A4A4A",
    },
    error: {
      main: "#B00020",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
);
