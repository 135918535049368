import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'

// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case 'LOADING_TRUE': {
      return {
        ...state,
        loading: true,
      }
    }
    case 'LOADING_FALSE': {
      return {
        ...state,
        loading: false,
      }
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      }
    }
  }
}

//Doctor related filter search form
export default function FilterForm() {
  const filter_status = {
    appoint_date_from: useSelector(
      (state) => state.delayedFilterReducer.value1
    ),
    appoint_date_to: useSelector((state) => state.delayedFilterReducer.value2),
  }
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    appoint_date_to: filter_status.appoint_date_to,
    appoint_date_from: filter_status.appoint_date_from,
    loading: false,
  })
  //
  const dispatch = useDispatch()
  //
  const {
    appoint_date_to,

    appoint_date_from,
    loading,
  } = formData

  const handleSubmit = (event) => {
    event.preventDefault()
    setFormDataDispatch({ type: 'LOADING_TRUE' })
    if (appoint_date_from !== undefined) {
      dispatch({
        type: 'delayedFilter/from',
        payload: appoint_date_from,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
    if (appoint_date_to !== undefined) {
      dispatch({
        type: 'delayedFilter/to',
        payload: appoint_date_to,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} className=' p-2'>
        {/* <div
          class='form-group mt-3
        '
        >
          <label for='exampleFormControlSelect1' style={{ fontWeight: 400 }}>
            Consultation Type
          </label>
          <select
            class='form-control'
            id='exampleFormControlSelect1'
            onChange={setFormDataDispatch}
            name='appoint_type'
            value={appoint_type}
          >
            <option name='all' value='videoCall'>
              All
            </option>
            <option value='Booked'>Book Appointment video</option>
            <option value='Instant'>Instant Consultation Video</option>
            <option value='FollowUp'>Follow Up's Consultations</option>
          </select>
        </div> */}
        {/* <div class='form-group mt-3'>
          <label for='exampleFormControlSelect1' style={{ fontWeight: 400 }}>
            Appointment Status
          </label>
          <select
            className='form-control filter-input'
            id='exampleFormControlSelect1'
            onChange={setFormDataDispatch}
            name='appointment_status'
            value={appointment_status}
          >
            <option value='all'>All</option>
            <option value='booked'>Booked</option>
            <option value='pending'>Pending</option>
            <option value='cancelled'>Canncelled</option>
          </select>
        </div> */}
        <div class='form-group mt-3'>
          <label for='exampleFormControlSelect1' style={{ fontWeight: 400 }}>
            From
          </label>

          <input
            onChange={setFormDataDispatch}
            name='appoint_date_from'
            type='date'
            class='form-control filter-input'
            id='exampleFormControlSelect1'
            value={appoint_date_from}
          />
        </div>
        <div class='form-group mt-3'>
          <label for='exampleFormControlSelect1' style={{ fontWeight: 400 }}>
            To
          </label>

          <input
            onChange={setFormDataDispatch}
            name='appoint_date_to'
            type='date'
            class='form-control filter-input'
            id='exampleFormControlSelect1'
            value={appoint_date_to}
          />
        </div>
        {/* <div class='form-group mt-3'>
          <label for='exampleFormControlSelect1' style={{ fontWeight: 400 }}>
            Booking Date
          </label>

          <input
            onChange={setFormDataDispatch}
            name='created_at'
            type='date'
            class='form-control filter-input'
            id='exampleFormControlSelect1'
            value={created_at}
          />
        </div> */}
        <button className='btn mt-3 filter-form-button'>
          {loading && (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
              style={{ marginRight: '20px' }}
            />
          )}{' '}
          {loading || 'Apply'}
        </button>
      </form>
      <style jsx>{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 100%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
      `}</style>
    </>
  )
}
