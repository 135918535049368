import React, { useReducer, useEffect, useState, useRef } from "react";
import axios from "axios";
import { API_CONSTANTS } from "../../../../API_CONSTANTS";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import addQuesIcon from "../../../../assets/icons/Vector.png";
import addSecIcon from "../../../../assets/icons/add.png";
const style = {
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  padding: 2,
};
// reducer function for managing states
const reducer = (state, action) => {
  switch (action.type) {
    case "API_SUCC": {
      return {
        ...state,
        apiData: action.payload,
      };
    }
    case "DELETE_SECTION": {
      return {
        ...state,
        apiData: state.apiData.filter(
          (item, index) => index !== action.payload
        ),
      };
    }
    case "DELETE_QUES": {
      return {
        ...state,
        apiData: state.apiData.filter(
          (item, index) => index !== action.payload
        ),
      };
    }
    case "DELETE_SEC_QUES": {
      return {
        ...state,
        apiData: state.apiData.map((item) => {
          if (item.Id === action.payload.parent.Id) {
            item.subQuestions = item.subQuestions.filter(
              (item, index) => index !== action.payload.index
            );
          }
          return item;
        }),
      };
    }
    case "UPDATE_SEC_QUES": {
      return {
        ...state,
        apiData: state.apiData.map((item) => {
          if (item.Id === action.payload.parent.Id) {
            item.subQuestions = item.subQuestions.map((item) => {
              if (item.Id === action.payload.item.Id) {
                return { ...item, text: action.payload.text };
              }
              return item;
            });
          }
          return item;
        }),
      };
    }
    case "UPDATE_QUES": {
      return {
        ...state,
        apiData: state.apiData.map((item) => {
          if (item.Id === action.payload.item.Id) {
            return { ...item, text: action.payload.text };
          }
          return item;
        }),
      };
    }
    default: {
      console.log("h");
    }
  }
};
// Question Component
const EditQuestion = ({
  index,
  item,
  border,
  deleteQuestion,
  parent,
  update,
}) => {
  const [edit, setEdit] = useState(false);
  const inputValue = useRef();

  return !edit ? (
    <div
      key={index}
      className={`d-flex w-100 justify-content-between p-2 align-items-center`}
      style={{
        border: border ? "1px solid #D7DDEA" : "unset",
        borderRadius: 12,
      }}
    >
      <p className="m-0">{item.text}</p>
      <span className="d-flex" style={{ columnGap: "1rem" }}>
        <Button onClick={() => setEdit(true)}>
          <EditIcon style={{ color: "#8E8E8E" }} />
        </Button>
        <Button
          color="error"
          onClick={() => deleteQuestion(index, item, parent)}
        >
          <DeleteIcon style={{ color: "#FF6760" }} />
        </Button>
      </span>
    </div>
  ) : (
    <div
      key={index}
      className={`d-flex w-100 justify-content-between ${
        border ? "border" : "border-0"
      } rounded p-3 align-items-center`}
    >
      <TextField
        variant="standard"
        autoFocus
        defaultValue={item.text}
        inputRef={inputValue}
        className="w-50"
      />
      <span className="d-flex" style={{ columnGap: "1rem" }}>
        <Button onClick={() => setEdit(false)} color="error">
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            update(item, inputValue.current.value, parent);
            setEdit(false);
          }}
        >
          Save
        </Button>
      </span>
    </div>
  );
};
// Section Component
const Section = ({ index, section, deleteSection, deleteQuestion, update }) => {
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div
      style={{
        border: "1px solid #D7DDEA",
        borderRadius: 12,
      }}
      key={index}
    >
      <span className="d-flex">
        <button
          onClick={handleClick}
          className="w-100 d-flex justify-content-between border-0 bg-white p-3 align-items-center"
        >
          <p className="m-0">{section.subSectionName}</p>
          {open ? <ExpandLess /> : <ExpandMore />}
        </button>
        <Button color="error" onClick={() => deleteSection(section, index)}>
          <DeleteIcon style={{ color: "#FF6760" }} />
        </Button>
      </span>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="d-flex flex-column" style={{ rowGap: "1rem" }}>
          {section.subQuestions.map((item, index) => (
            <EditQuestion
              index={index}
              item={item}
              border={false}
              deleteQuestion={deleteQuestion}
              parent={section}
              update={update}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};
// section modal reducer
let count = 0;
const sectionReducer = (state, action) => {
  switch (action.type) {
    case "ADD_QUES": {
      count = count + 1;
      const questionKey = `question${count}`;
      return {
        ...state,
        sQuestion: action.payload
          ? {
              ...state.sQuestion,
              [questionKey]: action.payload,
            }
          : state.sQuestion,
      };
    }
    case "QUES_DEL": {
      return {
        ...state,
        sQuestion: Object.values(state.sQuestion).filter(
          (item, index) => index !== action.payload
        ),
      };
    }
    case "API_INIT": {
      return {
        ...state,
        loading: true,
      };
    }
    case "API_SUCC": {
      return {
        ...state,
        loading: false,
      };
    }
    case "API_FAIL": {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      };
    }
  }
};
// Add section Modal,
const SectionModal = React.memo(({ callData }) => {
  const sectionQuestionRef = useRef(null);
  const [section, setSection] = useReducer(sectionReducer, {
    sName: "",
    sQuestion: {},
    loading: false,
  });
  const { sQuestion, sName, loading } = section;
  const handleSectionModalClose = () => {
    setSectionModal(false);
  };
  const [sectionModal, setSectionModal] = useState(false);
  const addSection = () => {
    setSection({ type: "API_INIT" });
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/health_history`,
        {
          typeRecord: "healthSection",
          sName,
          sQuestion,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then(async (res) => {
        await callData();
        setSection({ type: "API_SUCC" });
        setSectionModal(false);
        console.log(res);
      })
      .catch((error) => {
        setSection({ type: "API_FAIL" });
        setSectionModal(false);
        if (error.response.status === 401) {
          window.localStorage.clear();
          window.location.reload();
        }
        console.log(error);
      });
  };
  return (
    <>
      <Button onClick={() => setSectionModal(true)}>
        <img src={addSecIcon} alt="section-add-icon" />
        <span className="text-dark ms-2">Add Section</span>
      </Button>
      <Modal open={sectionModal} onClose={handleSectionModalClose}>
        <Box sx={style}>
          <Button
            onClick={() => setSectionModal(false)}
            style={{
              alignSelf: "start",
              minWidth: "unset",
              padding: "unset",
              marginBottom: "1rem",
            }}
            color="error"
          >
            <CloseIcon />
          </Button>
          <div className="d-flex flex-column " style={{ rowGap: "1rem" }}>
            <TextField
              variant="standard"
              label="Section Heading"
              name="sName"
              value={sName}
              onChange={setSection}
            />
            <span className="d-flex mb-4" style={{ columnGap: "1rem" }}>
              <TextField
                variant="standard"
                label="Add Question"
                style={{ flexGrow: 2 }}
                inputRef={sectionQuestionRef}
              />
              <Button
                onClick={() => {
                  setSection({
                    type: "ADD_QUES",
                    payload: sectionQuestionRef.current.value,
                  });
                  sectionQuestionRef.current.value = null;
                }}
                className="d-flex align-items-center"
              >
                <img src={addQuesIcon} alt="question-add-icon" />
                <span className="text-dark ms-2 mt-1">Add More</span>
              </Button>
            </span>
            {Object.values(sQuestion).map((item, index) => (
              <span key={index} className="d-flex align-items-center">
                <p className="m-0" style={{ flexGrow: 2 }}>
                  {item}
                </p>
                <Button
                  color="error"
                  onClick={() =>
                    setSection({ type: "QUES_DEL", payload: index })
                  }
                >
                  <DeleteIcon style={{ color: "#FF6760" }} />
                </Button>
              </span>
            ))}
          </div>
          <span
            className="d-flex justify-content-end mt-4"
            style={{ columnGap: "1rem" }}
          >
            <Button
              color="error"
              variant="outlined"
              onClick={() => setSectionModal(false)}
              className="px-4 py-2"
            >
              Discard
            </Button>
            <LoadingButton
              variant="contained"
              onClick={addSection}
              loading={loading}
              className="px-5 py-2"
            >
              Save
            </LoadingButton>
          </span>
        </Box>
      </Modal>
    </>
  );
});
// Add Question Modal,
const QuestionModal = React.memo(({ callData }) => {
  const [states, setStates] = useState({
    loading: false,
    question: "",
  });
  const { loading, question } = states;
  const [questionModal, setQuestionModal] = useState(false);
  const handleQuestionModalClose = () => setQuestionModal(false);
  const addSection = () => {
    setStates({
      loading: true,
      question: question,
    });
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/health_history`,
        {
          typeRecord: "healthQuestions",
          question,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then(async () => {
        await callData();
        setStates({
          loading: false,
          question: "",
        });
        handleQuestionModalClose();
      })
      .catch((error) => {
        setStates({
          loading: false,
          question: "",
        });
        if (error.response.status === 401) {
          window.localStorage.clear();
          window.location.reload();
        }
      });
  };
  return (
    <>
      <Button onClick={() => setQuestionModal(true)}>
        <img src={addQuesIcon} alt="question-add-icon" />
        <span className="text-dark ms-2">Add Question</span>
      </Button>
      <Modal open={questionModal} onClose={handleQuestionModalClose}>
        <Box sx={style}>
          <Button
            onClick={() => setQuestionModal(false)}
            style={{
              alignSelf: "start",
              minWidth: "unset",
              padding: "unset",
              marginBottom: "1rem",
            }}
            color="error"
          >
            <CloseIcon />
          </Button>
          <div className="d-flex flex-column " style={{ rowGap: "1rem" }}>
            <TextField
              variant="standard"
              label="Add Question"
              name="question"
              value={question}
              onChange={(e) =>
                setStates({
                  loading: false,
                  question: e.target.value,
                })
              }
            />
          </div>
          <span
            className="d-flex justify-content-end mt-4"
            style={{ columnGap: "1rem" }}
          >
            <Button
              color="error"
              variant="outlined"
              onClick={() => setQuestionModal(false)}
              className="px-4 py-2"
            >
              Discard
            </Button>
            <LoadingButton
              variant="contained"
              onClick={addSection}
              loading={loading}
              className="px-5 py-2"
            >
              Save
            </LoadingButton>
          </span>
        </Box>
      </Modal>
    </>
  );
});
export default function HealthHistory() {
  const [data, setData] = useReducer(reducer, {
    apiData: [],
    loading: false,
    error: "",
    section: {
      sk: "",
      sQuestion: {},
    },
  });
  const { apiData } = data;
  // getting data
  const getData = () => {
    axios
      .get(`${API_CONSTANTS.BASEURL_1}admin/health_history`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) =>
        setData({
          type: "API_SUCC",
          payload: res.data?.response?.Item?.healthQuestions,
        })
      )
      .catch((error) => {
        if (error.response.status === 401) {
          window.localStorage.clear();
          window.location.reload();
        }
        console.log(error);
      });
  };
  // handling deleting section of questions
  const deleteSection = (item, index) => {
    setData({ type: "DELETE_SECTION", payload: index });
    axios
      .delete(`${API_CONSTANTS.BASEURL_1}admin/health_history/${item.Id}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  // handling deleting quesitons and section's questions
  const deleteQuestion = (index, item, parent) => {
    if (parent) {
      setData({ type: "DELETE_SEC_QUES", payload: { parent, item, index } });
    } else setData({ type: "DELETE_QUES", payload: index });

    axios
      .delete(`${API_CONSTANTS.BASEURL_1}admin/health_history/${item.Id}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  // editing and updating questions heading
  const handlingUpdatingQuestions = (item, text, parent) => {
    // console.log(index, item, text, parent);
    if (parent) {
      setData({ type: "UPDATE_SEC_QUES", payload: { parent, item, text } });
    } else setData({ type: "UPDATE_QUES", payload: { item, text } });
    axios
      .patch(
        `${API_CONSTANTS.BASEURL_1}admin/health_history/${item.Id}`,
        {
          question: text,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  // calling getting data
  useEffect(() => {
    getData();
  }, []);
  return (
    <section className="p-4">
      <div className="container bg-white rounded p-3">
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between w-100">
                <h4>Medical History</h4>
                <span className="d-flex" style={{ columnGap: "1rem" }}>
                  <SectionModal callData={getData} />
                  <QuestionModal callData={getData} />
                </span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex flex-column mt-5" style={{ rowGap: "1rem" }}>
              {apiData.map((item, index) => {
                if (!item?.subSectionName) {
                  return (
                    <EditQuestion
                      index={index}
                      item={item}
                      border={true}
                      deleteQuestion={deleteQuestion}
                      update={handlingUpdatingQuestions}
                    />
                  );
                } else
                  return (
                    <Section
                      index={index}
                      section={item}
                      deleteSection={deleteSection}
                      deleteQuestion={deleteQuestion}
                      update={handlingUpdatingQuestions}
                    />
                  );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
