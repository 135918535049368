/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState } from "react";
import Table from "../../../Components/Table/index";
import axios from "axios";

import { API_CONSTANTS } from "../../../API_CONSTANTS";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { useSelector } from "react-redux";
//
import PatientFilterForm from "./PatientFilterForm";
import AlertMessage from "../../../Components/AlertMessage";
//
export default function Patient() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [patientList, setpatientList] = useState([]);
  const [patientSearch, setPatientSearch] = useState({
    number: "",
    countryCode: "+92",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  const filter_status = {
    gender: useSelector((state) => state.patientFilterReducer.value1),
    created_at: useSelector((state) => state.patientFilterReducer.value2),
    appoint_date_from: useSelector(
      (state) => state.patientFilterReducer.value3
    ),
  };
  const handleFilters = () => {
    let filterData = {
      gender: `${filter_status.gender ? filter_status.gender : ""}`,
      created_at: `${filter_status.created_at ? filter_status.created_at : ""}`,
      appoint_date_from: `${
        filter_status.appoint_date_from ? filter_status.appoint_date_from : ""
      }`,
    };
    if (Object.values(filterData).every((key) => key === "")) {
      return {};
    } else
      return Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== "")
      );
  };
  const { number, countryCode } = patientSearch;
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setpatientList(patientList.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(patientList.length / pageSize));
      }
    },
    [patientList, lastEvaluatedKey]
  );
  //
  // API Hatching data of all admins
  const userList = () => {
    axios
      .post(`${API_CONSTANTS.BASEURL}admin/patients`, handleFilters(), {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setIsLoading(true);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
        setpatientList(res.data.response.Items);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setIsLoading(true);
        setpatientList([]);
        // setError(error)
      });
  };
  const handleSearchInput = (event, state) => {
    if (event.reset) {
      return { number: "", countryCode: "+92" };
    }
    return setPatientSearch({
      ...state,
      number: event.target.value.slice(0, event.target.maxLength),
      countryCode: "+92",
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && patientSearch.number[0] === '3' && patientSearch.number.length === 10) {
      handlePatientSearch();
    }
  };
  const handlePatientSearch = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/patientSearch/${patientSearch.countryCode}${patientSearch.number}`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => setpatientList(res.data.response.Items))
      .then(() => setPatientSearch({ countryCode: "+92", number: "" }))
      .catch((error) => alert(error.response.data.response.message));
  };

  const [page, setPage] = useState(0);
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL}admin/patients?last=${lastEvaluatedKey}`,
        handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var patientListNextPage = response.data.response.Items;
        setpatientList([...patientList, ...patientListNextPage]);
        setPage(page + 1);
      })
      .then(() => setLoading(true));
  };
  const onDownloadBtn = () => {
    const filters = handleFilters();
    if (!filters || Object.keys(filters).length === 0) {
      alert("Please select valid filters before proceeding.");
      return;
    }
    axios
      .post(`${API_CONSTANTS.BASEURL}admin/patients?loadall=true`, filters, {
        headers: API_CONSTANTS.headers(),
      })
      .then((response) => {
        const s3Url = response.data.response.s3_url;
        const link = document.createElement("a");
        link.href = s3Url;
        link.download = "consultation.csv"; // Set the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(true);
      })
      .catch((error) => {
        // Handle error cases here
        console.error("An error occurred:", error);
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (property) =>
          property.Fname
            ? property.Fname.charAt(0).toUpperCase() + property.Fname.slice(1)
            : "UnNamed",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNo",
      },
      {
        Header: "Gender",
        accessor: "gender",
        Cell: ({ cell: { value } }) => value || "N/A",
      },

      {
        Header: "Age",

        accessor: (patientList) =>
          !patientList.dob
            ? ""
            : moment().diff(patientList.dob, "years") + " years",
        Cell: ({ cell: { value } }) => value || "N/A",
      },
      {
        Header: "Type",
        accessor: (item) => (item.corporateUser ? "Corporate" : "Personal"),
      },
      {
        Header: "City",
        accessor: "location",
      },
      {
        Header: "Date",
        accessor: (item) =>
          moment(item.created_at, "YYYY-MM-DD").format("D-MM-YYYY"),
      },
      {
        Header: "Time",
        accessor: (item) => moment(item.created_at).format("h:mm A"),
      },
      // {
      //   Header: 'Upcoming',
      //   accessor: (upcomingAppointmentCount) =>{
      //     if (upcomingAppointmentCount.upcomingAppointmentCount >= 1) {
      //       return upcomingAppointmentCount.upcomingAppointmentCount ;
      //     } else {
      //       return '-';
      //     }
      //   },
      //   Cell: ({ value }) => (
      //     <div style={{ paddingLeft:'10px' }}>{value}</div>
      //   ),
      // },
      {
        Header: "Total Appointment",
        accessor: (totalAppointmentCount) => {
          if (totalAppointmentCount.totalAppointmentCount >= 1) {
            return totalAppointmentCount.totalAppointmentCount;
          } else {
            return "-";
          }
        },
        Cell: ({ value }) => <div style={{ paddingLeft: "7px" }}>{value}</div>,
      },
      {
        Header: "Device Name",
        accessor: "os_name",
      },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  // this a check for filter if patientlist length is equal to one and user want all the patient.
  const patientListCheck = () => {
    if (patientList.length === 1) {
      userList();
    }
  };

  // rendering API
  useEffect(() => {
    userList();
  }, [
    filter_status.created_at,
    filter_status.gender,
    filter_status.appoint_date_from,
  ]);
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 404"
            ? "This record does not exist"
            : error.message === "Request failed with status code 401"
            ? is_SessionExpire()
            : error.message
        }
      />
    );
  } else {
    return (
      <>
        <div className="patient">
          <div className="input-group mt-3 pe-4 ms-4" style={{ width: "50%" }}>
            <div className="form-outline d-flex">
              <select
                name="countryCode"
                style={{
                  borderRadius: "7px 0 0 7px",
                  borderRight: "3px solid #009B94",
                  background: "#009B9414",
                  color: "#4E4E4E99",
                }}
                defaultValue={countryCode}
              >
                <option selected onChange={handleSearchInput}>
                  +92
                </option>
              </select>
              <input
                type="number"
                id="form1"
                className="form-control patient-search-input"
                placeholder="Search"
                value={number}
                name="number"
                onChange={handleSearchInput}
                onKeyPress={handleKeyPress}
                maxLength="10"
              />
            </div>
            <button
              type="button"
              className="btn"
              onClick={handlePatientSearch}
              disabled={
                patientSearch.number.slice(0, 1) === "3" &&
                patientSearch.number.length === 10
                  ? false
                  : true
              }
              style={{ backgroundColor: "rgb(0, 155, 148)", color: "#fff" }}
            >
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
        <div className="row rounded bg-white m-4">
          <div className="d-flex align-items-center">
            <h3
              style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}
            >
              All Patients
            </h3>
            {!isLoading && (
              <div className="d-flex mb-3">
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "#24AAA4" }}
                />
              </div>
            )}
          </div>
          <Table
            pageNumber={page}
            columns={columns}
            data={patientList}
            routingLink={"/patients/profile"}
            pageCount={pageCount}
            fetchData={fetchData}
            loadMoreList={handleLoadMoreData}
            lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            recordDownload={true}
            setLoading={setLoading}
            FilterForm={<PatientFilterForm patientList={patientListCheck} />}
            onDownloadBtn={onDownloadBtn}
          />
        </div>
        <style jsx="true">
          {`
            .patient {
              display: flex;
              justify-content: space-between;
            }
            .patient-search-input {
              border-radius: 0;
            }
            .form-control:focus {
              background-color: #fff;
              border-color: rgb(0, 155, 148) !important;
              box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 25%) !important;
            }
            .btn:focus {
              box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 25%) !important;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          `}
        </style>
      </>
    );
  }
}
