import React from 'react'
import style from '../../addData.module.css'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import { useDispatch } from 'react-redux'
// adding data
export default function Add() {
  const [modalShow, setModalShow] = React.useState(false)
  const [value, setValue] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const addingData = () => {
    setLoading(true)
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/settings`,
        {
          name: value,
          type: 'Services',
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        dispatch({ type: 'getDataSlice/addService', payload: value })
        setValue('')
        setLoading(false)
        setModalShow(false)
      })
      .catch((error) => alert(error.response.data.message))
  }
  return (
    <>
      <button
        className={style.button + ' ' + 'btn'}
        onClick={() => setModalShow(true)}
      >
        <i className={`fas fa-plus ${style.iconClass}`}></i>
        Add New
      </button>
      <Modal
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title id='contained-modal-title-vcenter'></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex w-100'>
            <input
              type='text'
              placeholder='Add Service'
              value={value}
              className='w-100 border-bottom pt-3 '
              style={{ outline: 'none', border: 'none' }}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}>
          <div className={style.actionbuttons}>
            <button
              className={style.saveButton + ' ' + 'btn'}
              onClick={addingData}
            >
              Save Changes{' '}
              {loading && (
                <div
                  className='spinner-border spinner-border-sm text-white'
                  role='status'
                >
                  <span className='sr-only'>Loading...</span>
                </div>
              )}
            </button>
            <button
              className={style.cancelButton + ' ' + 'btn'}
              onClick={() => setModalShow(false)}
            >
              Discard
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
