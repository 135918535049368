/*
Author: Umer Shabir 
Date: 22-march-2022
File: All the side bar links declared here if you want to render a new link you should declare them here.
*/
// Dependencies
import React from "react";
// Side bar Icons
import { ReactComponent as AdminIcon } from "./sidebar icons/admin.svg";
import { ReactComponent as AdminIcon1 } from "./sidebar icons/admin1.svg";
import { ReactComponent as PateintIcon } from "./sidebar icons/pateint.svg";
import { ReactComponent as PateintIcon1 } from "./sidebar icons/pateint1.svg";
import { ReactComponent as DoctorIcon } from "./sidebar icons/Doctor.svg";
import { ReactComponent as DoctorIcon1 } from "./sidebar icons/Doctor1.svg";
import { ReactComponent as ConsultationIcon } from "./sidebar icons/consultation.svg";
import { ReactComponent as ConsultationIcon1 } from "./sidebar icons/consultation1.svg";
import { ReactComponent as TransactionIcon } from "./sidebar icons/transaction.svg";
import { ReactComponent as TransactionIcon1 } from "./sidebar icons/transaction1.svg";
import { ReactComponent as FeedBackIcon } from "./sidebar icons/Feedback.svg";
import { ReactComponent as FeedBackIcon1 } from "./sidebar icons/Feedback1.svg";
// import { ReactComponent as ReviewsIcon } from './sidebar icons/Review.svg'
import { ReactComponent as AddData } from "./sidebar icons/addData.svg";
import { ReactComponent as AddData1 } from "./sidebar icons/addData1.svg";
import { ReactComponent as PermissionIcon } from "./sidebar icons/permissions.svg";
import { ReactComponent as PermissionIcon1 } from "./sidebar icons/permissions1.svg";
import { ReactComponent as Nurse } from "./sidebar icons/Nurse.svg";
import { ReactComponent as Nurse1 } from "./sidebar icons/Nurse1.svg";
import { ReactComponent as Services } from "./sidebar icons/services.svg";
import { ReactComponent as Services1 } from "./sidebar icons/services1.svg";
import { ReactComponent as Review } from "./sidebar icons/Review.svg";
import { ReactComponent as Review1 } from "./sidebar icons/Review1.svg";
import { ReactComponent as Lab } from "./sidebar icons/Lab.svg";
import { ReactComponent as Lab1 } from "./sidebar icons/Lab1.svg";
// Variable Array of object
const NAV_LINKS = [
  {
    id: 1,
    path: "/admins",
    navbarLink: "Admins",
    icon: <AdminIcon />,
    icon1: <AdminIcon1 />,
    activeClass: "active",
  },
  {
    id: 2,
    path: "/patients",
    navbarLink: "Patients",
    icon: <PateintIcon />,
    icon1: <PateintIcon1 />,
    activeClass: "active",
  },
  {
    id: 3,
    path: "/doctors",
    navbarLink: "Doctors",
    icon: <DoctorIcon />,
    icon1: <DoctorIcon1 />,
    activeClass: "active",
  },
  {
    id: 4,
    path: "/nurse",
    navbarLink: "Nurse",
    icon: <Nurse />,
    icon1: <Nurse1 />,
    activeClass: "active",
  },
  {
    id: 5,
    path: "/consultations",
    navbarLink: "Consultations",
    icon: <ConsultationIcon />,
    icon1: <ConsultationIcon1 />,
    activeClass: "active",
  },
  {
    id: 6,
    path: "/services",
    navbarLink: "Services",
    icon: <Services />,
    icon1: <Services1 />,
    activeClass: "active",
  },
  {
    id: 7,
    path: "/lab",
    navbarLink: "Lab Test",
    icon: <Lab />,
    icon1: <Lab1 />,
    activeClass: "active",
  },
  {
    id: 8,
    path: "/adddata",
    navbarLink: "Add Data",
    icon: <AddData />,
    icon1: <AddData1 />,
    activeClass: "active",
  },
  {
    id: 9,
    path: "/feedback",
    navbarLink: "Feedback",
    icon: <FeedBackIcon />,
    icon1: <FeedBackIcon1 />,
    activeClass: "active",
  },
  {
    id: 10,
    path: "/transactions",
    navbarLink: "Transactions",
    icon: <TransactionIcon />,
    icon1: <TransactionIcon1 />,
    activeClass: "active",
  },
  {
    id: 16,
    path: "/corporates",
    navbarLink: "Corporate",
    icon: <TransactionIcon />,
    icon1: <TransactionIcon1 />,
    activeClass: "active",
  },
  {
    id: 11,
    path: "/reviews",
    navbarLink: "Reviews",
    icon: <Review />,
    icon1: <Review1 />,
    activeClass: "active",
  },

  // {
  //   id: 10,
  //   path: '/wallet',
  //   navbarLink: 'Wallet',
  //   icon: <WalletIcon />,
  //   activeClass: 'active',
  // },

  // {
  //   id: 11,
  //   path: '/notifications',
  //   navbarLink: 'Notifications',
  //   icon: <NotificationsIcon />,
  //   activeClass: 'active',
  // },
  // {
  //   id: 12,
  //   path: '/referrals',
  //   navbarLink: 'Referrals & Redeems',
  //   icon: <ReferralIcon />,
  //   activeClass: 'active',
  // },
  // {
  //   id: 13,
  //   path: '/warnings',
  //   navbarLink: 'Warnings',
  //   icon: <WarningIcon />,
  //   activeClass: 'active',
  // },
  // {
  //   id: 14,
  //   path: '/reports',
  //   navbarLink: 'Reports',
  //   icon: <ReportIcon />,
  //   activeClass: 'active',
  // },
  {
    id: 13,
    path: "/rolebasedaccess",
    navbarLink: "Role Based Access",
    icon: <PermissionIcon />,
    icon1: <PermissionIcon1 />,
    activeClass: "active",
  },
  {
    id: 14,
    path: "/blogs",
    navbarLink: "Blogs",
    icon: <TransactionIcon />,
    icon1: <TransactionIcon1 />,
    activeClass: "active",
  },
  {
    id: 15,
    path: "/notifications",
    navbarLink: "Notifications",
    icon: <Review />,
    icon1: <Review1 />,
    activeClass: "active",
  },
];
// exporting the Array of variable objects
export default NAV_LINKS;
