import React from 'react'
import { useSelector } from 'react-redux'
import { saveAs } from 'file-saver'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { noon_svg_icon } from '../../../../../constants_file'

export default function NurseEprescription() {
  const { value, loading } = useSelector(
    (state) => state.getServicesProfileReducer
  )
  const saveFile = () => {
    saveAs(value.prescription.prescription_url)
  }
  return (
    <>
      <div className='container-fluid '>
        <div className='row d-flex m-3'>
          <div className='col-6 bg-white' style={{ borderRadius: '4px' }}>
            <h4
              className='pt-4'
              style={{ fontWeight: '500', fontSize: '16px' }}
            >
              Prescription
            </h4>
            {value.prescription.e_prescription.map((data, index) => {
              return (
                <div
                  key={index}
                  className='d-flex flex-column mt-3 '
                  style={{ borderBottom: '1px solid #CCCCCC' }}
                >
                  <h5 style={{ fontSize: '13px', fontWeight: '500px' }}>
                    {data ? data.medicine_name : ''}
                  </h5>
                  <span style={{ fontSize: '13px', fontWeight: '400px' }}>
                    {data ? data.quantity : ''} {data ? data.category : ''} For{' '}
                    {data ? data.duration : ''} Days /{data.dosage_type}
                  </span>
                  <div className=' d-flex my-2'>
                    <span className='col-2 d-flex'>
                      <i
                        className='fas fa-sun mt-1  '
                        style={{ color: '#009B94' }}
                      />
                      <span style={{ marginLeft: '9px' }}>
                        {data.intake.breakfast === true ? '1' : '0'}
                      </span>
                    </span>
                    <span className='col-2 d-flex '>
                      <img src={noon_svg_icon} alt='' color='009B94' />

                      <span style={{ marginLeft: '9px' }}>
                        {data.intake.lunch === true ? '1' : '0'}
                      </span>
                    </span>
                    <span className='d-flex '>
                      <i
                        className='fas fa-moon mt-1'
                        style={{ color: '#009B94' }}
                      />
                      <span style={{ marginLeft: '9px' }}>
                        {data.intake.dinner === true ? '1' : '0'}
                      </span>
                    </span>
                  </div>
                  <span>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: '400px',
                        color: '#5C5C5C',
                      }}
                    >
                      {data ? data.instruction : ''}
                    </p>
                  </span>
                </div>
              )
            })}

            <div>
              <h4
                className='pt-4'
                style={{ fontWeight: '500', fontSize: '16px' }}
              >
                e-Prescription Download
              </h4>

              <span className='mb-5'>
                <button
                  className='btn custom-button edit-button  my-4'
                  onClick={saveFile}
                >
                  <FontAwesomeIcon
                    icon={faDownload}
                    className={'edit-icon me-2'}
                  />
                  Click For Download
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <style jsx='true'>{`
        .edit-button {
          border: 2px solid #009b94;
          color: #009b94;
        }
        .edit-button:hover {
          background: #009b94 !important;
          color: #fff;
        }
        .btnadd {
          background-color: #009b94;
          border: none;
          color: white;
          padding: 12px 30px;
          cursor: pointer;
          font-size: 20px;
          border-radius: 4px;
        }
      `}</style>
    </>
  )
}
