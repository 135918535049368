import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router'
import Modal from 'react-bootstrap/Modal'

import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../../../Components/Toast/Toast'
//
//

//
export default function EditBalance({ current, getProfileWalletInfo }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [balance, setBalance] = useState(current)
  const params = useParams()
  //
  // ------------------ toast states
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('') //  using this state for show the toast message
  const [ToastIcon, setToastIcon] = useState(faCheckCircle) // using this state for changing the toast message icon color
  const [iconColor, setIconColor] = useState('') // using this state for changing the toast message icon color
  const [spin, setSpin] = useState('')
  useEffect(() => {
    setBalance(current)
  }, [current])
  //
  const handleSubmit = (e) => {
    e.preventDefault()
    const wallet = {
      wallet: parseInt(balance),
    }
    axios
      .patch(`${API_CONSTANTS.BASEURL}admin/user/updateUserWallet/${params.id}`, wallet, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        handleToast(
          res.data.response.message,
          faCheckCircle,
          '#009B94',
          'show-success'
        )
        getProfileWalletInfo()
      })
      .then(() => setShow(false))
      .catch((error) => {
        handleToast(
          error.data.response.message,
          faTimesCircle,
          '#B00020',
          'show-danger'
        )
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
      })
  }
  // ---------------- Handle Toast function
  // --------  This function is for call the Toast message  ------

  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }

  return (
    <>
      {/* Importing the Toast Component  */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <button
        className='align-self-start'
        onClick={handleShow}
        style={{
          margin: 0,
          padding: 0,
          background: 'none',
          outline: 'none',
          border: 'none',
          fontSize: 20,
          color: '#009B94',
        }}
      >
        <i className='fas fa-pencil-alt'></i>
      </button>
      <Modal show={show} onHide={handleClose}>
        <button
          className='btn align-self-end'
          style={{ color: '#009B94', fontSize: 20 }}
          onClick={handleClose}
        >
          <i className='fas fa-times'></i>
        </button>

        <form onSubmit={handleSubmit} className='p-3'>
          <label for='currentBalance'> Current Balance</label>
          <input
            type='number'
            name='currentBalance'
            className='custom-input-field '
            onChange={(e) => setBalance(e.target.value)}
            placeholder={balance}
          />
          <button
            className='btn'
            type='submit'
            style={{ background: '#009B94', color: '#fff' }}
          >
            Save Changes
          </button>
          <button
            className='btn'
            type='button'
            onClick={handleClose}
            style={{ color: '#B00020' }}
          >
            Discard
          </button>
        </form>
      </Modal>
    </>
  )
}
