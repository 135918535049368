import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
//
export default function EditProfileButton({ }) {
  const params = useParams()
  return (
    <>
      <Link
        className='btn custom-button edit-button'
        to={`/nurses/personal_info/${params.id}`}
      >
        <FontAwesomeIcon icon={faCog} className={'edit-icon me-2'} />
        Edit Profile
      </Link>

      <style jsx='true'>
        {`
          .edit-button {
            border: 2px solid #009b94;
            color: #009b94;
          }
          .edit-button:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-save {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
          }
          .custom-input-field {
            width: 100%;
            border: none;
            border-bottom: 1px solid #cccccc;
          }
          .custom-input-field:focus-visible {
            outline: none;
            border-bottom: 1px solid #009b94;
          }
          .p_label {
            font-size: 14px;
            color: #5c5c5c;
          }
          .error-message {
            font-size: 13px;
            color: red;
          }
        `}
      </style>
    </>
  )
}
