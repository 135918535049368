import { useEffect, useReducer } from 'react'
import React from 'react'
import CallLogTable from './CallLogTable'

const reducer = (state, action) => {}
export default function ConsultationHistory() {
  const [consultationHistory] = useReducer(reducer, {
    value: {
      callStartTime: '',
      callReceivedTime: '',
      callEndTime: '',
      callEndedBy: '',
      LogEntryTime: '',
    },
    error: {},
    isLoading: false,
    isError: false,
  })
  const { value} = consultationHistory
  useEffect(() => {
    fetch('/consultationhistory')
      .then((res) => res.json())
      .then((data) => console.log(data))
  }, [])
  return (
    <>
      <div className='container-fluid p-4 '>
        <div className='row'>
          <div className='col-12 col-xxl-3'>
            <div className='d-flex flex-column mb-3 bg-white rounded'>
              <table className='bg-white rounded profile-card-width'>
                <thead></thead>
                <tbody>
                  <tr>
                    <th>Call Start Time</th>
                    <td>{value.callStartTime}</td>
                  </tr>
                  <tr>
                    <th>Call Received</th>
                    <td>{value.callReceivedTime}</td>
                  </tr>
                  <tr>
                    <th>Call End Time</th>
                    <td>{value.callEndTime}</td>
                  </tr>
                  <tr>
                    <th>Call Ended By</th>
                    <td>{value.callEndedBy}</td>
                  </tr>
                  <tr>
                    <th>Log Entry Time</th>
                    <td>{value.LogEntryTime}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-12 col-xxl-9'>
            <CallLogTable />
          </div>
        </div>
      </div>
      <style jsx='true'>{`
        table {
          font-size: 14px;
        }
        th {
          font-weight: 500;
          padding: 15px;
        }
        td {
          text-align: right;
          padding: 15px;
        }
        tr {
          border-bottom: 1px solid #dadada;
        }
      `}</style>
    </>
  )
}
