/*
Author: Zeeshan Naseeb 
Date: 21-March-2022
file: Side bar of the dashboard where all the navigation links are mapped.
modified: 19-10-2022
*/
// Dependencies.
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import NAV_LINKS from "./sideBarNavLinks";
import { useDispatch, useSelector } from "react-redux";
import diseasePreset from "../../assets/icons/disease-preset.png";
import rectangle from "../../assets/icons/Rectangle.png";
import payment from "./sidebar icons/payment.svg";
// sdiebar component

export default function SideBar() {
  const [checked, setChecked] = useState(false);
  const [docCollapse, setDocCollapse] = useState(false);
  const [ConCollapse, setConCollapse] = useState(false);
  const [labCollapse, setLabCollapse] = useState(false);
  const [corporateCollapse, setCorporateCollapse] = useState(false);
  const [transactionCol, setTransactionCol] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const value = useSelector((state) => state.sideBarWidthReducer);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    dispatch({ type: `sideBarWidth/${checked}` });
  };
  function isNavLinkActive(path, location) {
    return location.pathname.startsWith(path);
  }
  const userRole = localStorage.getItem("adminRole");
  const filteredNavLinks = NAV_LINKS.filter((navLink) => {
    if (userRole === "CustomerSupport") {
      return [
        "Patients",
        "Consultations",
        "Doctors",
        "Feedback",
        "Reviews",
        "Lab Test",
        "Transactions",
      ].includes(navLink.navbarLink);
    } else if (userRole === "HealthCare") {
      return ["Blogs"].includes(navLink.navbarLink);
    } else if (userRole === "AccountsDept") {
      return ["Transactions", "Consultations"].includes(navLink.navbarLink);
    } else if (userRole === "DevTeam") {
      return ["Lab Test", "Consultations"].includes(navLink.navbarLink);
    } else {
      return true;
    }
  });
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuItemClick = () => {
    setIsMobileMenuOpen(false);
  };
  const mobileMenu = (
    <div className="sidebar-backdrop" onClick={toggleMobileMenu}>
      <div
        className={`bg-white shadow-lg h-100 admin-side-bar ${isMobileMenuOpen ? "mobile-menu-open" : ""
          }`}
        style={{
          width: value.value.width,
          maxHeight: "93%",
          overflowY: "auto",
        }}
      >
        <div
          className="d-flex flex-column align-items-start w-100  justify-content-between"
          style={{ height: "calc(100% - 100px)" }}
        >
          <div className="d-flex flex-column w-100">
            <div
              className="border-bottom w-100 d-flex justify-content-start align-items-center"
              style={{ paddingLeft: "20px" }}
            >
              <span style={{ fontSize: "46px" }}>
                <i className="fas fa-user-circle"></i>
              </span>
              <span className={value.value.class + " ms-2"}>
                <h5
                  className="m-0"
                  style={{ fontSize: "14px", color: "#1E1E1E" }}
                >
                  {localStorage.getItem("adminName")}
                </h5>
                <small
                  style={{ fontSize: "12px", color: "#5C5C5C" }}
                  className={value.value.class}
                >
                  {localStorage.getItem("adminRole")}
                </small>
              </span>
            </div>

            <div
              className="d-flex flex-column"
              style={{ width: value.value.navBarCollapseWidth }}
            >
              <div
                className="w-100 d-flex flex-column"
                style={{ padding: "10px 10px 18px 10px" }}
              >
                {filteredNavLinks.map((navLink) => (
                  <div
                    style={{ width: value.value.navLinkWidthCollapse }}
                    key={navLink.id}
                  >
                    <NavLink
                      key={navLink.id}
                      to={navLink.path}
                      style={{ padding: "10px 5px" }}
                      className={({ isActive }) =>
                        isActive ? "activee d-flex" : "d-flex inactivee "
                      }
                      onClick={handleMenuItemClick}
                    >
                      <span
                        className="me-3 d-inline"
                        style={{ color: "#009B94" }}
                      >
                        {isNavLinkActive(navLink.path, location)
                          ? navLink.icon
                          : navLink.icon1}
                      </span>
                      <span
                        className={
                          value.value.class +
                          "d-flex justify-content-between align-items-center w-100"
                        }
                        style={{ fontSize: "14px" }}
                      >
                        {navLink.navbarLink}
                      </span>

                      {navLink.navbarLink === "Doctors" && (
                        <button
                          className="btn py-0"
                          style={{
                            color: "#009B94",
                            outline: "none",
                            border: "none",
                            transition: "all ease 0.2s",
                            background: "#fff",
                            borderRadius: "17px",
                            margin: value.value.iconMargin,
                            padding: value.value.iconPadding,
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            setDocCollapse(!docCollapse);
                          }}
                        >
                          {docCollapse ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </button>
                      )}

                      {navLink.navbarLink === "Consultations" && (
                        <button
                          className="btn py-0"
                          style={{
                            color: "#009B94",
                            outline: "none",
                            border: "none",
                            transition: "all ease 0.2s",
                            background: "#fff",
                            borderRadius: "17px",
                            margin: value.value.iconMargin,
                            padding: value.value.iconPadding,
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            setConCollapse(!ConCollapse);
                          }}
                        >
                          {ConCollapse ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </button>
                      )}
                      {navLink.navbarLink === "Lab Test" && (
                        <button
                          className="btn py-0"
                          style={{
                            color: "#009B94",
                            outline: "none",
                            border: "none",
                            transition: "all ease 0.2s",
                            background: "#fff",
                            borderRadius: "17px",
                            margin: value.value.iconMargin,
                            padding: value.value.iconPadding,
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            setLabCollapse(!labCollapse);
                          }}
                        >
                          {labCollapse ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </button>
                      )}
                      {navLink.navbarLink === "Transactions" && (
                        <button
                          className="btn py-0"
                          style={{
                            color: "#009B94",
                            outline: "none",
                            border: "none",
                            transition: "all ease 0.2s",
                            background: "#fff",
                            borderRadius: "17px",
                            margin: value.value.iconMargin,
                            padding: value.value.iconPadding,
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            setTransactionCol(!transactionCol);
                          }}
                        >
                          {transactionCol ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </button>
                      )}
                      {navLink.navbarLink === "Corporate" && (
                        <button
                          className="btn py-0"
                          style={{
                            color: "#009B94",
                            outline: "none",
                            border: "none",
                            transition: "all ease 0.2s",
                            background: "#fff",
                            borderRadius: "17px",
                            margin: value.value.iconMargin,
                            padding: value.value.iconPadding,
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            setCorporateCollapse(!corporateCollapse);
                          }}
                        >
                          {corporateCollapse ? (
                            <i className="fas fa-chevron-up"></i>
                          ) : (
                            <i className="fas fa-chevron-down"></i>
                          )}
                        </button>
                      )}
                    </NavLink>
                    {/* doctor collapse menu */}
                    {navLink.navbarLink === "Doctors" && (
                      <ul
                        style={{
                          display: docCollapse ? "block" : "none",
                          listStyleType: "none",
                          margin: "10px 0",
                          transition: "display ease 0.2s",
                          padding: value.value.ulPadding,
                        }}
                      >
                        <li className="d-flex">
                          <NavLink
                            to="disease-preset"
                            className={({ isActive }) =>
                              isActive ? "activee" : " inactivee "
                            }
                            style={{
                              padding: "7px 5px 7px 20px",
                              width: "100%",
                              fontSize: "14px",
                            }}
                          >
                            <img
                              src={diseasePreset}
                              alt=""
                              className="mx-2 me-3"
                            />
                            <span className={value.value.class}>
                              Disease Preset
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    )}
                    {/* consultation collapse menu */}

                    {navLink.navbarLink === "Consultations" && (
                      <ul
                        style={{
                          display: ConCollapse ? "block" : "none",
                          listStyleType: "none",
                          margin: "10px 0",
                          transition: "display ease 0.2s",
                          padding: value.value.ulPadding,
                        }}
                      >
                        <li className="d-flex">
                          <NavLink
                            to="sub-menu"
                            className={({ isActive }) =>
                              isActive ? "activee" : "inactivee"
                            }
                            style={{
                              padding: "7px 5px 7px 20px",
                              width: "100%",
                              fontSize: "14px",
                            }}
                          >
                            <img src={payment} alt="" className="mx-2 me-3" />
                            <span className={value.value.class}>
                              Check Payments
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    )}
                    {/* Lab Test collapse menu */}
                    {navLink.navbarLink === "Lab Test" && (
                      <ul
                        style={{
                          display: labCollapse ? "block" : "none",
                          listStyleType: "none",
                          margin: "10px 0",
                          transition: "display ease 0.2s",
                          padding: value.value.ulPadding,
                        }}
                      >
                        <li className="d-flex">
                          <NavLink
                            to="/lab/partners"
                            className={({ isActive }) =>
                              isActive ? "activee" : "inactivee"
                            }
                            style={{
                              padding: "7px 5px 7px 20px",
                              width: "100%",
                              fontSize: "14px",
                            }}
                          >
                            Lab Partners
                          </NavLink>
                        </li>
                        <li className="d-flex">
                          <NavLink
                            to="/lab/packages"
                            className={({ isActive }) =>
                              isActive ? "activee" : "inactivee"
                            }
                            style={{
                              padding: "7px 5px 7px 20px",
                              width: "100%",
                              fontSize: "14px",
                            }}
                          >
                            Lab Packages
                          </NavLink>
                        </li>
                      </ul>
                    )}
                    {/* corporate collapse menu */}
                    {navLink.navbarLink === "Transactions" && (
                      <ul
                        style={{
                          display: transactionCol ? "block" : "none",
                          listStyleType: "none",
                          margin: "10px 0",
                          transition: "display ease 0.2s",
                          padding: value.value.ulPadding,
                        }}
                      >
                        <li className="d-flex">
                          <NavLink
                            to="/subscription-transactions"
                            className={({ isActive }) =>
                              isActive ? "activee" : "inactivee"
                            }
                            style={{
                              padding: "7px 5px 7px 20px",
                              width: "100%",
                              fontSize: "14px",
                            }}
                          >
                            Buy Subscription
                          </NavLink>
                        </li>
                      </ul>
                    )}
                    {navLink.navbarLink === "Corporate" && (
                      <ul
                        style={{
                          display: corporateCollapse ? "block" : "none",
                          listStyleType: "none",
                          margin: "10px 0",
                          transition: "display ease 0.2s",
                          padding: value.value.ulPadding,
                        }}
                      >
                        <li className="d-flex">
                          <NavLink
                            to="/corporates/plans"
                            className={({ isActive }) =>
                              isActive ? "activee" : "inactivee"
                            }
                            style={{
                              padding: "7px 5px 7px 20px",
                              width: "100%",
                              fontSize: "14px",
                            }}
                          >
                            Plans
                          </NavLink>
                        </li>
                        <li className="d-flex">
                          <NavLink
                            to="/corporates/transactions"
                            className={({ isActive }) =>
                              isActive ? "activee" : "inactivee"
                            }
                            style={{
                              padding: "7px 5px 7px 20px",
                              width: "100%",
                              fontSize: "14px",
                            }}
                          >
                            Transactions
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const regularSidebar = (
    <div
      className={`bg-white shadow-lg h-100 admin-side-bar ${isMobileMenuOpen ? "mobile-menu-open" : ""
        }`}
      style={{
        width: value.value.width,
        maxHeight: "94%",
        overflowY: "auto",
        paddingBottom: "80px",
      }}
    >
      <div
        className="d-flex flex-column align-items-start w-100  justify-content-between"
        style={{ height: "calc(100% - 100px)" }}
      >
        <div className="d-flex flex-column w-100">
          <div
            className="border-bottom w-100 d-flex justify-content-start align-items-center"
            style={{ paddingLeft: "20px" }}
          >
            <span style={{ fontSize: "46px" }}>
              <i className="fas fa-user-circle"></i>
            </span>
            <span className={value.value.class + " ms-2"}>
              <h5
                className="m-0"
                style={{ fontSize: "14px", color: "#1E1E1E" }}
              >
                {localStorage.getItem("adminName")}
              </h5>
              <small
                style={{ fontSize: "12px", color: "#5C5C5C" }}
                className={value.value.class}
              >
                {localStorage.getItem("adminRole")}
              </small>
            </span>
          </div>

          <div
            className="d-flex flex-column"
            style={{ width: value.value.navBarCollapseWidth }}
          >
            <div
              className="w-100 d-flex flex-column"
              style={{ padding: "10px 10px 18px 10px" }}
            >
              {filteredNavLinks.map((navLink) => (
                <div
                  style={{ width: value.value.navLinkWidthCollapse }}
                  key={navLink.id}
                >
                  <NavLink
                    key={navLink.id}
                    to={navLink.path}
                    style={{ padding: "10px 5px" }}
                    className={({ isActive }) =>
                      isActive ? "activee d-flex" : "d-flex inactivee "
                    }
                    onClick={handleMenuItemClick}
                  >
                    <span
                      className="me-3 d-inline"
                      style={{ color: "#009B94" }}
                    >
                      {isNavLinkActive(navLink.path, location)
                        ? navLink.icon
                        : navLink.icon1}
                    </span>
                    <span
                      className={
                        value.value.class +
                        "d-flex justify-content-between align-items-center w-100"
                      }
                      style={{ fontSize: "14px" }}
                    >
                      {navLink.navbarLink}
                    </span>

                    {navLink.navbarLink === "Doctors" && (
                      <button
                        className="btn py-0"
                        style={{
                          color: "#009B94",
                          outline: "none",
                          border: "none",
                          transition: "all ease 0.2s",
                          background: "#fff",
                          borderRadius: "17px",
                          margin: value.value.iconMargin,
                          padding: value.value.iconPadding,
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setDocCollapse(!docCollapse);
                        }}
                      >
                        {docCollapse ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </button>
                    )}

                    {navLink.navbarLink === "Consultations" && (
                      <button
                        className="btn py-0"
                        style={{
                          color: "#009B94",
                          outline: "none",
                          border: "none",
                          transition: "all ease 0.2s",
                          background: "#fff",
                          borderRadius: "17px",
                          margin: value.value.iconMargin,
                          padding: value.value.iconPadding,
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setConCollapse(!ConCollapse);
                        }}
                      >
                        {ConCollapse ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </button>
                    )}
                    {navLink.navbarLink === "Lab Test" && (
                      <button
                        className="btn py-0"
                        style={{
                          color: "#009B94",
                          outline: "none",
                          border: "none",
                          transition: "all ease 0.2s",
                          background: "#fff",
                          borderRadius: "17px",
                          margin: value.value.iconMargin,
                          padding: value.value.iconPadding,
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setLabCollapse(!labCollapse);
                        }}
                      >
                        {labCollapse ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </button>
                    )}
                    {navLink.navbarLink === "Transactions" && (
                      <button
                        className="btn py-0"
                        style={{
                          color: "#009B94",
                          outline: "none",
                          border: "none",
                          transition: "all ease 0.2s",
                          background: "#fff",
                          borderRadius: "17px",
                          margin: value.value.iconMargin,
                          padding: value.value.iconPadding,
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setTransactionCol(!transactionCol);
                        }}
                      >
                        {transactionCol ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </button>
                    )}
                    {navLink.navbarLink === "Corporate" && (
                      <button
                        className="btn py-0"
                        style={{
                          color: "#009B94",
                          outline: "none",
                          border: "none",
                          transition: "all ease 0.2s",
                          background: "#fff",
                          borderRadius: "17px",
                          margin: value.value.iconMargin,
                          padding: value.value.iconPadding,
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          setCorporateCollapse(!corporateCollapse);
                        }}
                      >
                        {corporateCollapse ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </button>
                    )}
                  </NavLink>

                  {navLink.navbarLink === "Doctors" && (
                    <ul
                      style={{
                        display: docCollapse ? "block" : "none",
                        listStyleType: "none",
                        margin: "10px 0",
                        transition: "display ease 0.2s",
                        padding: value.value.ulPadding,
                      }}
                    >
                      <li className="d-flex">
                        <NavLink
                          to="disease-preset"
                          className={({ isActive }) =>
                            isActive ? "activee" : " inactivee "
                          }
                          style={{
                            padding: "7px 5px 7px 20px",
                            width: "100%",
                            fontSize: "14px",
                          }}
                        >
                          <img
                            src={diseasePreset}
                            alt=""
                            className="mx-2 me-3"
                          />
                          <span className={value.value.class}>
                            Disease Preset
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  )}
                  {navLink.navbarLink === "Consultations" && (
                    <ul
                      style={{
                        display: ConCollapse ? "block" : "none",
                        listStyleType: "none",
                        margin: "10px 0",
                        transition: "display ease 0.2s",
                        padding: value.value.ulPadding,
                      }}
                    >
                      <li className="d-flex">
                        <NavLink
                          to="sub-menu"
                          className={({ isActive }) =>
                            isActive ? "activee" : "inactivee"
                          }
                          style={{
                            padding: "7px 5px 7px 20px",
                            width: "100%",
                            fontSize: "14px",
                          }}
                        >
                          <img src={payment} alt="" className="mx-2 me-3" />
                          <span className={value.value.class}>
                            Check Payments
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  )}
                  {navLink.navbarLink === "Lab Test" && (
                    <ul
                      style={{
                        display: labCollapse ? "block" : "none",
                        listStyleType: "none",
                        margin: "10px 0",
                        transition: "display ease 0.2s",
                        padding: value.value.ulPadding,
                      }}
                    >
                      <li className="d-flex">
                        <NavLink
                          to="/lab/partners"
                          className={({ isActive }) =>
                            isActive ? "activee" : "inactivee"
                          }
                          style={{
                            padding: "7px 5px 7px 20px",
                            width: "100%",
                            fontSize: "14px",
                          }}
                        >
                          Lab Partners
                        </NavLink>
                      </li>
                      <li className="d-flex">
                        <NavLink
                          to="/lab/packages"
                          className={({ isActive }) =>
                            isActive ? "activee" : "inactivee"
                          }
                          style={{
                            padding: "7px 5px 7px 20px",
                            width: "100%",
                            fontSize: "14px",
                          }}
                        >
                          Lab Packages
                        </NavLink>
                      </li>
                    </ul>
                  )}
                  {navLink.navbarLink === "Transactions" && (
                    <ul
                      style={{
                        display: transactionCol ? "block" : "none",
                        listStyleType: "none",
                        margin: "10px 0",
                        transition: "display ease 0.2s",
                        padding: value.value.ulPadding,
                      }}
                    >
                      <li className="d-flex">
                        <NavLink
                          to="subscription-transactions"
                          className={({ isActive }) =>
                            isActive ? "activee" : "inactivee"
                          }
                          style={{
                            padding: "7px 5px 7px 20px",
                            width: "100%",
                            fontSize: "14px",
                          }}
                        >
                          Buy Subscription
                        </NavLink>
                      </li>
                    </ul>
                  )}
                  {navLink.navbarLink === "Corporate" && (
                    <ul
                      style={{
                        display: corporateCollapse ? "block" : "none",
                        listStyleType: "none",
                        margin: "10px 0",
                        transition: "display ease 0.2s",
                        padding: value.value.ulPadding,
                      }}
                    >
                      <li className="d-flex">
                        <NavLink
                          to="/corporates/plans"
                          className={({ isActive }) =>
                            isActive ? "activee" : "inactivee"
                          }
                          style={{
                            padding: "7px 5px 7px 20px",
                            width: "100%",
                            fontSize: "14px",
                          }}
                        >
                          Plans
                        </NavLink>
                      </li>
                      <li className="d-flex">
                        <NavLink
                          to="/corporates/transactions"
                          className={({ isActive }) =>
                            isActive ? "activee" : "inactivee"
                          }
                          style={{
                            padding: "7px 5px 7px 20px",
                            width: "100%",
                            fontSize: "14px",
                          }}
                        >
                          Transactions
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ padding: "10px 35px" }}>
          <input
            type="checkbox"
            onChange={handleChange}
            value={checked}
            id="burgerMenu"
            className="burgerMenu"
          />
          <label
            htmlFor="burgerMenu"
            className="menubars d-flex justify-content-center align-items-center"
          >
            <img src={rectangle} alt="toggle-icon" />
            <span
              className={value.value.class + " ms-3"}
              style={{ fontSize: 14, color: "#000" }}
            >
              Toggle Sidebar
            </span>
          </label>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <button className="mobile-toggle-btn" onClick={toggleMobileMenu}>
        <i className={`fas ${isMobileMenuOpen ? "fa-times" : "fa-bars"}`}></i>
      </button>
      {isMobileMenuOpen ? mobileMenu : regularSidebar}
      <style jsx="true">
        {`
          .admin-side-bar {
            position: fixed;
            z-index:9;
          }
          .activee {
            color: #009b94;
            background-color: rgba(0, 155, 148, 0.08);
            border-radius: 0px 5px 5px 0px;
            width: 100%;
            border-left: 3px solid #009b94;
          }
          .activee:hover {
            color: #009b94;
          }
          .inactivee:hover {
            background-color: rgba(0, 155, 148, 0.08);
            border-radius: 0px 5px 5px 0px;
            width: 100%;
          }
          .sidebar-backdrop {
            position: fixed;
            top: 60;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5); 
            z-index: 998; 
          }
          @media screen and (min-width: 1051px) {
            .mobile-toggle-btn {
              display: none !important; 
            }
          }
          @media screen and (max-width: 1050px) {
            .admin-side-bar {
              transform: translateX(-100%);
              transition: transform 0.3s ease;
            }
            .mobile-menu-open{
              transform: translateX(0);
            }
            .mobile-toggle-btn {
              position: fixed;
              top: 10px;
              left: 10px;
              z-index: 999;
              background: transparent;
              border: 2px solid;
              padding: 0 7px;
              font-size: 24px;
              color: #009b94;
              cursor: pointer;
            }
          }
          }
        `}
      </style>
    </>
  );
}
