import React, { useReducer } from 'react'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faCheckCircle } from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../../Components/Toast/Toast.js'
import ReactTagInput from '@pathofdev/react-tag-input'
import '@pathofdev/react-tag-input/build/index.css'

// Reducer function
const formReducer = (state, action) => {
  switch (action.type) {
    case 'DOS_TAGS_ARRAY': {
      return {
        ...state,
        dosTags: action.payload.tags,
      }
    }
    case 'DONT_TAGS_ARRAY': {
      return {
        ...state,
        dontTags: action.payload.tags,
      }
    }
    case 'ERROR_DOS': {
      return {
        ...state,
        errorDos: true,
        errorDonts: false,
      }
    }
    case 'ERROR_DOS_DONTS': {
      return {
        ...state,
        errorDos: true,
        errorDonts: true,
      }
    }
    case 'ERROR_DONTS': {
      return {
        ...state,
        errorDonts: true,
        errorDos: false,
      }
    }
    case 'REQUEST_INIT': {
      return {
        ...state,
        loading: true,
        errorDos: false,
        errorDonts: false,
      }
    }
    case 'REQUEST_FALSE': {
      return {
        ...state,
        loading: false,
      }
    }

    case 'TOAST_TRUE': {
      return {
        ...state,
        loading: false,
        ToastMessage: action.payload.message,
        showToast: 'show show-success',
        ToastIcon: faCheckCircle,
        iconColor: '#009b94',
      }
    }
    case 'TOAST_FALSE': {
      return {
        ...state,
        showToast: ' ',
      }
    }
    case 'RESET': {
      return {
        ...state,
        dosTags: [],
        dontTags: [],
        title: '',
      }
    }

    default:
      return {
        ...state,
        [action.target.name]: action.target.value,
      }
  }
}
// Add Disease Preset Component
export default function AddNewDiseasePreset() {
  const [formData, setFormData] = useReducer(formReducer, {
    title: '',
    loading: false,
    showToast: '',
    ToastIcon: '',
    iconColor: '',
    ToastMessage: '',
    dosTags: [],
    dontTags: [],
    errorDos: false,
    errorDonts: false,
  })
  const {
    title,
    loading,
    showToast,
    ToastIcon,
    iconColor,
    ToastMessage,
    dosTags,
    dontTags,
    errorDos,
    errorDonts,
  } = formData
  // tags handling
  const handleDoTags = (tags) => {
    setFormData({
      type: 'DOS_TAGS_ARRAY',
      payload: { tags: tags },
    })
  }
  const handleDontTags = (tags) => {
    setFormData({
      type: 'DONT_TAGS_ARRAY',
      payload: { tags: tags },
    })
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    if (dosTags.length < 2 && dontTags.length < 2) {
      setFormData({ type: 'ERROR_DOS_DONTS' })
    } else if (dosTags.length < 2) {
      setFormData({ type: 'ERROR_DOS' })
    } else if (dontTags.length < 2) {
      setFormData({ type: 'ERROR_DONTS' })
    } else if (dontTags.length >= 2 && dosTags.length >= 2) {
      setFormData({ type: 'REQUEST_INIT' })
      event.preventDefault()
      axios
        .post(
          `${API_CONSTANTS.BASEURL_1}admin/diseaseSet`,
          {
            title: title,
            dos: dosTags,
            donts: dontTags,
          },
          {
            headers: API_CONSTANTS.headers(),
          }
        )
        .then((res) => {
          setFormData({
            type: 'TOAST_TRUE',
            payload: { message: res.data.response.message },
          })
        })
        .then(() => {
          setTimeout(() => setFormData({ type: 'TOAST_FALSE' }), 2000)
        })
        .then(() => {
          setFormData({ type: 'RESET' })
        })
        .catch((err) => alert(err.data.response.message))
    }
  }
  return (
    <>
      <div className='row m-4 bg-white rounded p-4'>
        <div className='col-12'>
          <div className='d-flex justify-content-between'>
            <p style={{ fontWeight: 500, color: '#1E1E1E' }}>
              Add Disease Preset
            </p>
            <Link to='/disease-preset'>
              <FontAwesomeIcon
                icon={faList}
                className={'me-2 fa-icon color-99D7D4'}
                style={{ color: '#009B94' }}
              />
              Disease Preset List
            </Link>
          </div>
        </div>
        <div className='col-12'>
          <div className='d-flex'>
            <form onSubmit={handleSubmit} className='d-flex flex-column w-100'>
              <div className='form-group w-25'>
                <label
                  htmlFor='exampleInputEmail1'
                  style={{ fontWeight: 400, fontSize: 12, color: '#8E8E8E' }}
                >
                  Disease Name
                </label>
                <input
                  type='text'
                  className='form-control disease-input'
                  id='exampleInputEmail1'
                  aria-describedby='emailHelp'
                  placeHolder='Enter disease'
                  name='title'
                  value={title}
                  onChange={setFormData}
                />
              </div>
              <div class='form-group w-75 my-4 mb-0'>
                <label
                  for='exampleFormControlTextarea1'
                  style={{ fontWeight: 400, fontSize: 14, color: '#8E8E8E' }}
                >
                  Do's
                </label>
                <ReactTagInput
                  tags={dosTags}
                  placeholder={`Add Do's`}
                  editable={true}
                  removeOnBackspace={true}
                  onChange={(newTags) => handleDoTags(newTags)}
                />
                {errorDos && (
                  <p style={{ fontSize: 12, color: 'red' }}>
                    Add minimum of 2 Do's
                  </p>
                )}
                {/* <textarea
                  class='form-control disease-text'
                  id='exampleFormControlTextarea1'
                  rows='4'
                  name='dos'
                  value={dos}
                  onChange={setFormData}
                ></textarea> */}
              </div>
              <div class='form-group w-75 my-4 mb-0'>
                <label
                  for='exampleFormControlTextarea1'
                  style={{ fontWeight: 400, fontSize: 14, color: '#8E8E8E' }}
                >
                  Don'ts
                </label>
                <ReactTagInput
                  tags={dontTags}
                  placeholder={`Add Don'ts`}
                  editable={true}
                  removeOnBackspace={true}
                  onChange={(newTags) => handleDontTags(newTags)}
                />
                {errorDonts && (
                  <p style={{ fontSize: 12, color: 'red' }}>
                    Add minimum of 2 Don'ts
                  </p>
                )}
              </div>
              <div class='form-group w-75 my-4 d-flex justify-content-end'>
                <button
                  type='submit'
                  className='align-self-end disease-button'
                  style={{
                    border: '1px solid #009B94',
                    background: 'none',
                    borderRadius: '5px',
                    padding: '10px 20px',
                    color: '#009B94',
                    fontWeight: 500,
                  }}
                >
                  Save Preset{' '}
                  {loading && (
                    <div class='spinner-border spinner-border-sm' role='status'>
                      <span class='visually-hidden'>Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <style jsx='true'>{`
          .react-tag-input {
            display: flex;
            align-items: flex-start;
          }
          .disease-input {
            border: none !important;
            border-bottom: 1px solid #8e8e8e !important;
            border-radius: 0px !important;
            padding-left: 0px !important;
          }
          .disease-input:focus {
            box-shadow: none !important;
          }
          .disease-text:focus {
            box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 25%) !important;
          }
          .disease-button:hover {
            background: #009b94 !important;
            color: #fff !important;
          }
          .disease-button:disabled {
            background: #ccc !important;
            color: #fff !important;
            border: none !important;
            cursor: no-drop;
          }
          .react-tag-input__tag__remove:before,
          .react-tag-input__tag__remove:after {
            background-color: #1ea7a1 !important;
          }
        `}</style>
      </div>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}
