/*
author: Umer Shabir 
date: 09-March-2022
title: this is a generic Alert message.
*/
// dependencies
import React from 'react'
// Alert component.
export default function AlertMessage({ errorMessage }) {
  const handleClose = () => {
    window.location.reload()
  }
  return (
    <div
      className='alert alert-danger d-flex align-items-center w-25 justify-content-center mx-auto my-4'
      role='alert'
    >
      <i class='fas fa-exclamation-circle' style={{ fontSize: '25px' }}></i>
      <div className='mx-3'>{errorMessage}</div>
      <i
        class='fas fa-times'
        onClick={handleClose}
        style={{ cursor: 'pointer' }}
      ></i>
    </div>
  )
}
