/*
Author: Umer Shabir 
Date: April-2022
File: this file render two component admin users list and add new user button 
*/
// dependencies
import React from 'react'
import AddNewUserButton from '../../../../Components/AddNewUserButton'
import AdminUsersList from './AdminUsersList'
// Admin List index component.
export default function AdminsList() {
  return (
    <>
      <AddNewUserButton link={'/admins/new'} buttonText={'Add New Admin'} />
      <AdminUsersList />
    </>
  )
}
