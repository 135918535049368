import React, { useState, useReducer } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import AddTimeSlot from "./AddTimeSlot.js";
import axios from "axios";
import { useParams } from "react-router";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Toast from "../../../../../../Components/Toast/Toast.js";
import { API_CONSTANTS } from "../../../../../../API_CONSTANTS/index.js";
import calendarEditIcon from "../../../../../../assets/icons/calendar-edit-icon.png";

let in_array = require("in_array");

// timeSlotReducer function
const timeSlotReducer = (state, action) => {
  switch (action.type) {
    case "UPDATED_SLOTS": {
      return {
        ...state,
        data: {
          ...state.data,
          day_sessions: action.payload.data,
        },
      };
    }
    case "ADD_TIME_SLOT": {
      return {
        ...state,
        data: {
          ...state.data,
          day_sessions: [...state.data.day_sessions, action.payload.data],
        },
      };
    }
    case "UPDATE_TIME_SLOT": {
      return {
        ...state,
        data: {
          ...state.data,
          day_sessions: action.payload.data,
        },
      };
    }
    case "DELETE_TIME_SLOT": {
      return {
        ...state,
        data: {
          ...state.data,
          day_sessions: action.payload.data.filter(
            (item, index) => index !== action.payload.index
          ),
        },
      };
    }
    case "LOADING_TRUE": {
      return {
        ...state,
        loading: true,
      };
    }
    case "LOADING_FALSE": {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      };
    }
  }
};
// Edit indivisual day slot.
export default function Edit({ day, session, booked, update, slotDuration }) {
  let timeDuration = slotDuration;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    handleUpdateOnComponentMounting();
  };
  const params = useParams();
  const [toast, setToast] = useState({
    icon: "",
    message: "",
    iconColor: "",
    cName: "",
  });
  const [timeSlot, setTimeSlot] = useReducer(timeSlotReducer, {
    data: {
      day_sessions: session,
      duration: timeDuration,
    },
    loading: false,
  });
  const {
    data: { day_sessions, duration },
    loading,
  } = timeSlot;

  // adding the time slot
  const addingTimeSlot = (data) => {
    setTimeSlot({
      type: "ADD_TIME_SLOT",
      payload: {
        data: data,
      },
    });
  };
  // updating the time slot onChange
  const handleUpdateSlot = (
    currentValue,
    lastValue,
    slots,
    currentSlot,
    slotIndex
  ) => {
    if (currentSlot.startTime === lastValue) {
      let updatedSlots = slots.map((item, index) => {
        if (index === slotIndex) {
          return { ...item, startTime: currentValue };
        }
        return item;
      });
      setTimeSlot({
        type: `UPDATE_TIME_SLOT`,
        payload: {
          data: updatedSlots,
        },
      });
    } else {
      let updatedSlots = slots.map((item, index) => {
        if (index === slotIndex) {
          return { ...item, endTime: currentValue };
        }
        return item;
      });
      setTimeSlot({
        type: `UPDATE_TIME_SLOT`,
        payload: {
          data: updatedSlots,
        },
      });
    }
  };
  // deleting the time slot
  const handleDelete = (data, slot, index) => {
    setTimeSlot({ type: `DELETE_TIME_SLOT`, payload: { data, slot, index } });
  };
  // handle submit the data
  const handleSubmit = (e) => {
    e.preventDefault();
    setTimeSlot({ type: "LOADING_TRUE" });
    axios
      .patch(
        `${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/sessions/${day.slot_date}`,
        { day_sessions, duration },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setTimeSlot({ type: "LOADING_FALSE" });
        setToast({
          icon:
            res.data.response.message ===
            "You have already Changed current day session."
              ? faTimesCircle
              : faCheckCircle,
          message: res.data.response.message,
          iconColor: "",
          cName:
            res.data.response.message ===
            "You have already Changed current day session."
              ? "show show-danger"
              : "show show-success",
        });
        setTimeout(() => {
          setToast({
            icon: "",
            message: "",
            iconColor: "",
            cName: "",
          });
        }, 2000);
        if (
          res.data.response.message !==
          "You have already Changed current day session."
        ) {
          update();
        }
      })
      .then(() => handleClose())
      .catch((err) => {
        setTimeSlot({ type: "LOADING_FALSE" });
        // console.log(err.response.data.response.message)
        setToast({
          icon: faTimesCircle,
          message: err.response.data.response.message,
          iconColor: "",
          cName: "show show-danger",
        });
        setTimeout(() => {
          setToast({
            icon: "",
            message: "",
            iconColor: "",
            cName: "",
          });
        }, 1500);
      });
  };

  const handleUpdateOnComponentMounting = () => {
    setTimeSlot({
      type: "UPDATED_SLOTS",
      payload: {
        data: session,
      },
    });
  };
  return (
    <>
      <button
        type="button"
        className="btn p-0 mx-1"
        style={{ color: "#009B94" }}
        onClick={handleShow}
        disabled={day.lock_day ? true : false || booked ? true : false}
      >
        <img src={calendarEditIcon} alt="" />
      </button>
      <Modal show={show} onHide={handleClose} size="lg">
        <div className="p-3 d-flex flex-column">
          <span>
            {day.slot_day.slice(0, 1).toUpperCase() + day.slot_day.slice(1, 3)}
          </span>
          <div className="d-flex justify-content-between">
            <p className="text-start m-0 p-0 display-6">
              {day.slot_date.slice(-2)}
            </p>
            <AddTimeSlot addTimeSlot={addingTimeSlot} />
          </div>
          <div className="w-100">
            <form
              action=""
              className="d-flex flex-column w-100"
              onSubmit={handleSubmit}
            >
              <fieldset
                className=" d-flex justify-content-between align-items-start w-100"
                // disabled={item.check === true ? false : true}
              >
                <div className="d-flex flex-column w-100">
                  {day_sessions === undefined
                    ? []
                    : day_sessions.map((slot, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center w-100 justify-content-between my-3"
                          style={{ columnGap: 20 }}
                        >
                          <div className="d-flex w-50 my-1 justify-content-center align-items-center flex-grow-1">
                            <span className="me-3" style={{ width: "30px" }}>
                              {index + 1}.
                            </span>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Stack spacing={3}>
                                <TimePicker
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  value={
                                    slot.startTime !== ""
                                      ? new Date(
                                          0,
                                          0,
                                          0,
                                          slot.startTime !== undefined
                                            ? moment(
                                                moment().format("MM/DD/YYYY") +
                                                  " " +
                                                  slot.startTime
                                              ).hour()
                                            : 0,
                                          moment(
                                            moment().format("MM/DD/YYYY") +
                                              " " +
                                              slot.startTime
                                          ).minute()
                                        )
                                      : null
                                  }
                                  label="Start Time"
                                  onChange={(e) => {
                                    handleUpdateSlot(
                                      moment(e.$d).format("HH:mm"),
                                      slot.startTime,
                                      day_sessions,
                                      slot,
                                      index
                                    );
                                  }}
                                  minutesStep={5}
                                  // disabled={
                                  //   formDisable.form === true
                                  //     ? true
                                  //     : false || item.check === true
                                  //     ? false
                                  //     : true
                                  // }
                                />
                              </Stack>
                            </LocalizationProvider>
                            <p className="m-0 mx-2">to</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Stack spacing={3}>
                                <TimePicker
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  value={
                                    slot.endTime !== ""
                                      ? new Date(
                                          0,
                                          0,
                                          0,
                                          slot.endTime !== undefined
                                            ? moment(
                                                moment().format("MM/DD/YYYY") +
                                                  " " +
                                                  slot.endTime
                                              ).hour()
                                            : 0,
                                          moment(
                                            moment().format("MM/DD/YYYY") +
                                              " " +
                                              slot.endTime
                                          ).minute()
                                        )
                                      : null
                                  }
                                  label="End Time"
                                  onChange={(e) => {
                                    handleUpdateSlot(
                                      moment(e.$d).format("HH:mm"),
                                      slot.endTime,
                                      day_sessions,
                                      slot,
                                      index
                                    );
                                  }}
                                  minutesStep={5}
                                  // disabled={
                                  //   formDisable.form === true
                                  //     ? true
                                  //     : false || item.check === true
                                  //     ? false
                                  //     : true
                                  // }

                                  shouldDisableTime={(timeValue, clockType) => {
                                    let x = {
                                      slotInterval: duration,
                                      openTime:
                                        slot.startTime !== ""
                                          ? new Date(
                                              0,
                                              0,
                                              0,
                                              slot.startTime !== undefined
                                                ? moment(
                                                    moment().format(
                                                      "MM/DD/YYYY"
                                                    ) +
                                                      " " +
                                                      slot.startTime
                                                  ).hour()
                                                : 0,
                                              moment(
                                                moment().format("MM/DD/YYYY") +
                                                  " " +
                                                  slot.startTime
                                              ).minute()
                                            )
                                          : null,
                                      closeTime:
                                        slot.endTime !== ""
                                          ? new Date(
                                              0,
                                              0,
                                              0,
                                              slot.endTime !== undefined
                                                ? moment(
                                                    moment().format(
                                                      "MM/DD/YYYY"
                                                    ) +
                                                      " " +
                                                      slot.endTime
                                                  ).hour()
                                                : 0,
                                              moment(
                                                moment().format("MM/DD/YYYY") +
                                                  " " +
                                                  slot.endTime
                                              ).minute()
                                            )
                                          : null,
                                    };
                                    let allTimes = [];
                                    let startTime = moment(x.openTime, "HH:mm");
                                    let endTime = moment(
                                      x.closeTime,
                                      "HH:mm"
                                    ).add(1, "hours");
                                    let activeHour = moment(
                                      slot.endTime !== ""
                                        ? new Date(
                                            0,
                                            0,
                                            0,
                                            slot.endTime !== undefined
                                              ? moment(
                                                  moment().format(
                                                    "MM/DD/YYYY"
                                                  ) +
                                                    " " +
                                                    slot.endTime
                                                ).hour()
                                              : 0,
                                            moment(
                                              moment().format("MM/DD/YYYY") +
                                                " " +
                                                slot.endTime
                                            ).minute()
                                          )
                                        : null
                                    ).format("HH");

                                    while (startTime < endTime) {
                                      if (
                                        activeHour === startTime.format("HH")
                                      ) {
                                        allTimes.push(startTime.format("mm"));
                                      }
                                      startTime.add(x.slotInterval, "minutes");
                                    }
                                    if (
                                      moment(x.openTime).format("HH") ===
                                      moment(x.closeTime).format("HH")
                                    ) {
                                      allTimes.splice(0, 1);
                                    }
                                    return (
                                      clockType === "minutes" &&
                                      !in_array(timeValue, allTimes)
                                    );
                                  }}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </div>
                          <div className="d-flex" style={{ columnGap: 20 }}>
                            <div>
                              <input
                                type="checkbox"
                                checked={slot.video}
                                readOnly
                                className="form-check-input mx-2"
                              />
                              <label
                                htmlFor="videoChat"
                                className="form-check-label "
                                style={{ color: "#5C5C5C" }}
                              >
                                {" "}
                                <i className="fas fa-video"></i>
                              </label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                defaultChecked={slot.chat}
                                disabled
                                className="form-check-input mx-2"
                              />
                              <label
                                htmlFor="Chat"
                                className="form-check-label"
                                style={{ color: "#5C5C5C" }}
                              >
                                <i className="fas fa-comment-medical"></i>
                              </label>
                            </div>
                          </div>
                          <div>
                            {/* <deleteTimeSlot /> */}
                            <button
                              type="button"
                              className=" btn"
                              style={{
                                color: "#FF6760",
                              }}
                              disabled={day_sessions.length === 1}
                              onClick={() =>
                                handleDelete(day_sessions, slot, index)
                              }
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                </div>
              </fieldset>
              <span className="d-flex justify-content-end">
                <button
                  type="button"
                  style={{
                    background: "none",
                    color: "#B00020",
                    borderRadius: 4,
                    padding: "10px 15px",
                    margin: "0 5px",
                    border: "1px solid #79747E",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                  onClick={handleClose}
                >
                  Discard
                </button>
                <button
                  type="submit"
                  className="d-flex align-items-center"
                  style={{
                    background: "#009B94",
                    color: "#fff",
                    borderRadius: 4,
                    padding: "10px 15px",
                    margin: "0 5px",
                    border: "1px solid #009B94",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                >
                  Save Changes
                  {loading && (
                    <div
                      className="spinner-border spinner-border-sm text-light mx-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}{" "}
                </button>
              </span>
            </form>
          </div>
        </div>
      </Modal>
      <Toast
        cName={toast.cName}
        icon={toast.icon}
        text={toast.message}
        color={toast.iconColor}
      />
    </>
  );
}
