import { createSlice } from "@reduxjs/toolkit";
export const packageSlicer = createSlice({
    name: "packageSlicer",
    initialState: {
        packages: [],
        totalAmount: 0,
    },
    reducers: {
        addToPackage: (state, action) => {
            state.packages = [...state.packages, action.payload];
            state.totalAmount = state.packages.reduce(
                (accumulator, item) => accumulator + parseInt(item.totalPrice),
                0
            );
            state.subTotalPrice = state.packages.reduce(
                (accumulator, item) => accumulator + parseInt(item.subTotalPrice),
                0
            );
        },
        addAmount: (state) => {
            state.totalAmount = state.packages.reduce(
                (accumulator, item) => accumulator + item.productPrice,
                0
            );
        },
        removePackageItem: (state, action) => {
            state.packages = state.packages.filter((item) => item.barcode !== action.payload);
            state.totalAmount = state.packages.reduce(
                (accumulator, item) => accumulator + parseInt(item.productPrice),
                0
            );
        },
        removeAllItems: (state) => {
            state.packages = [];
            state.totalAmount = 0;
        },
        addQuantity: (state, action) => {
            state.packages = state.packages.map((packageItem) => {
                if (packageItem.barcode === action.payload.barcode) {
                    packageItem.totalPrice = action.payload.totalPrice
                    packageItem.subTotalPrice = action.payload.subTotalPrice
                    return {
                        ...packageItem,
                        quantity: action.payload.quantity,
                    }
                } else return packageItem
            })
            state.totalAmount = state.packages.reduce(
                (accumulator, item) => accumulator + parseInt(item.totalPrice),
                0
            );
        }
    },
});

export const { addToPackage } = packageSlicer.actions;
export const packageReducer = packageSlicer.reducer;
