import React, { useMemo, useEffect, useState } from 'react'
import Table from '../../../../../Components/Table/index'
import axios from 'axios'
import AppointmentFilterForm from './LogsFilterForm'
import { useSelector } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import AlertMessage from '../../../../../Components/AlertMessage'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
import Modal from 'react-modal'
import { Button } from 'react-bootstrap'
//
export default function Logs() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [logList, setLogList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0)
  const [page, setPage] = useState(0)
  const [show, setShow] = useState(false)
  //
  const params = useParams()
  //
  const filterValue = useSelector((state) => state.appointmentFilterReducer)
  //
  const filter_status = {
    appointment_status: filterValue.value,
    appoint_date_from: filterValue.value1,
    appoint_date_to: filterValue.value2,
    created_at: filterValue.value3,
  }
  //
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = lastEvaluatedKey
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setLogList(logList.slice(startRow, endRow))
        setPageCount(Math.ceil(logList.length / pageSize))
      }
    },
    [logList, lastEvaluatedKey]
  )
  //
  const handleFilters = () => {
    let filterData = {
      appointment_status: `${filter_status.appointment_status}`,
      appoint_date_from: `${filter_status.appoint_date_from}`,
      appoint_date_to: `${filter_status.appoint_date_to}`,
      created_at: `${filter_status.created_at}`,
    }
    if (Object.values(filterData).every((key) => key === '')) {
      return {}
    } else
      return Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== '')
      )
  }
  // API Hatching data of doctor appointments
  const userList = () => {
    axios
      .post(`${API_CONSTANTS.BASEURL_1}admin/doctors/${params.id}/logs/lock-slots`, handleFilters(), {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setIsLoading(true)
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
        setLogList(
          res.data.response.Items ? res.data.response.Items : []
        )
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setIsLoading(true)
        setError(error)
      })
  }

  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/doctors/${params.id}/logs/lock-slots?last=${lastEvaluatedKey}`,
        handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
        var logListNextPage = response.data.response.Items
        setLogList([...logList, ...logListNextPage])
        setPage(page + 1)
      })
      .then(() => setLoading(true))
  }
  const [modalData, setModalData] = useState([]);

  const openModal = (data) => {
    setModalData(data);
    setShow(true);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (a) => moment(a.created_at).format('DD-MM-YYYY'),
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        Cell: ({ cell: { value } }) => value || 'N/A',
      },
      {
        Header: 'Lock Time',
        accessor: 'slotsList',
        Cell: ({ cell: { value } }) => {
          const lockedSlots = value.filter(slot => slot.locked);
          const unlockedSlots = value.filter(slot => !slot.locked);
          return (
            <>
              <Button onClick={() => openModal(value)} className='b1'>
                {`${value.length} Slots`}
                {lockedSlots.length > 0 && ` (${lockedSlots.length} Locked)`}
                {unlockedSlots.length > 0 && ` (${unlockedSlots.length} Unlocked)`}
              </Button>
              <Modal
                isOpen={show}
                onRequestClose={() => setShow(false)}
                className="modal-dialog modal-dialog-centered"
                style={{
                  overlay: {
                    opacity: '0.3'
                  }
                }}
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Slot Details</h5>
                    <button type="button" className="close" onClick={() => setShow(false)}>
                      <span>&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <table className="custom-table">
                      <tbody>
                        {modalData.map((slot, index) => (
                          <tr key={index}>
                            <td>{`${moment(slot.start, 'HH:mm').format('hh:mm A')}`}</td>
                            <td>{slot.locked ? 'Locked' : 'Unlocked'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Modal>
            </>
          );
        },
      },
    ],
    [show, modalData]
  )
  const is_SessionExpire = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  useEffect(() => {
    userList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter_status.appoint_date_to,
    filter_status.appoint_date_from,
    filter_status.appointment_status,
    filter_status.created_at,
  ])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 404'
            ? is_SessionExpire()
            : error.message
        }
      />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center mt-4'>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <div className='row rounded bg-white m-4'>
        <div>
          <h3 style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}>
            All Appointments
          </h3>
        </div>
        <Table
          pageNumber={page}
          columns={columns}
          data={logList}
          routingLink={''}
          pageCount={pageCount}
          fetchData={fetchData}
          loadMoreList={handleLoadMoreData}
          lastEvaluatedKey={lastEvaluatedKey}
          loading={loading}
          setLoading={setLoading}
          FilterForm={<AppointmentFilterForm />}
        />
        <style jsx='true'>{`
            .custom-table td {
                padding: 4px 15px;
            }
            .b1{
              border-radius: 4px;
              border: 1px solid #009B94;
              background-color: #fff !important;
              color: #009B94;
            }
            .b1:hover{
              border-radius: 4px;
              border: 1px solid #009B94;
              background-color: #009B94 !important;
              color: #fff;
            }
            .b1:focus{
              border:1px solid #009B94;
              background-color: #fff !important;
              color: #009B94;
            }
      `}</style>
      </div>
    )
  }
}
//
