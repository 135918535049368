import React, { useState, useReducer } from 'react'
import Modal from 'react-bootstrap/Modal'
import jazzCash from '../../../../../resources/images/jazzCash.png'
import easyPaisa from '../../../../../resources/images/easyPaisa.png'
import creditCard from '../../../../../resources/images/creditCard.png'
import creditCardModal from '../../../../../resources/images/creditcardModal.png'
import jazzCashdModal from '../../../../../resources/images/jazzcashModal.png'
import easyPaisaModal from '../../../../../resources/images/easyPaisaModal.png'
import axios from 'axios'
import { useParams } from 'react-router'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../../../Components/Toast/Toast'
//
//
//
// Credit/Debit Card paymentState
function CreditDebitCardState() {
  const handleSubmit = (e) => {
    e.preventDefault()
    alert('hello')
  }
  return (
    <div className='d-flex flex-column align-items-center'>
      <img src={creditCardModal} alt='' style={{ width: 115, height: 80 }} />
      <p className='text-center my-3'>
        Please enter your Credit/Debit card <br />
        details to save payment method
      </p>
      <form onSubmit={handleSubmit}>
        <div className='row px-5 gy-2'>
          <div className='col-12'>
            <input
              className='form-control form-control-lg'
              type='text'
              placeholder='Name on Card'
            />
          </div>
          <div className='col-12'>
            <input
              className='form-control form-control-lg'
              type='text'
              placeholder='Card Number'
            />
          </div>
          <div className='col-6'>
            <input
              className='form-control form-control-lg'
              type='text'
              placeholder='Expiry Date'
            />
          </div>
          <div className='col-6'>
            <input
              className='form-control form-control-lg'
              type='text'
              placeholder='CVV'
            />
          </div>
          <div className='col-12'>
            <button className='btn btn-block done-btn w-100' type='submit'>
              Done
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
// jazzCashState paymentState
function JazzCashState({ getPaymentDetails }) {
  // ------------------ toast states
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('') //  using this state for show the toast message
  const [ToastIcon, setToastIcon] = useState(faCheckCircle) // using this state for changing the toast message icon color
  const [iconColor, setIconColor] = useState('') // using this state for changing the toast message icon color
  const [spin, setSpin] = useState('')
  const params = useParams()
  const [submitting, setSubmitting] = useState({
    form: false,
    dangerClass: false,
  })
  const jazzFormReducer = (state, event) => {
    setSubmitting({
      form: false,
      dangerClass: false,
    })
    if (event.reset) {
      return {
        mobileNo: '',
        CNICLastDigits: '',
      }
    }
    return {
      ...state,
      [event.target.name]: event.target.value.slice(0, event.target.maxLength),
      type: 'JazzCash',
    }
  }
  const [formData, setFormData] = useReducer(jazzFormReducer, {
    mobileNo: '',
    CNICLastDigits: '',
  })
  const { mobileNo, CNICLastDigits } = formData
  //
  const handleSubmit = (e) => {
    e.preventDefault()
    if (mobileNo.slice(0, 2) !== '03') {
      setSubmitting({
        form: false,
        dangerClass: true,
      })
    } else {
      axios
        .post(`${API_CONSTANTS.BASEURL}admin/patients/${params.id}/paymentMethod`, formData, {
          headers: API_CONSTANTS.headers(),
        })
        .then(setFormData({ reset: true }))
        .then((res) => {
          handleToast(
            res.data.response.message,
            faCheckCircle,
            '#009B94',
            'show-success'
          )
          getPaymentDetails()
        })
        .catch((error) => {
          handleToast(
            error.data.response.message,
            faTimesCircle,
            '#B00020',
            'show-danger'
          )
        })
    }
  }
  // ---------------- Handle Toast function
  // --------  This function is for call the Toast message  ------

  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  return (
    <div className='d-flex flex-column align-items-center'>
      <img src={jazzCashdModal} alt='' style={{ width: 175, height: 80 }} />
      <p className='text-center my-3'>
        Please enter your JazzCash account
        <br /> to save payment method
      </p>
      <form onSubmit={handleSubmit}>
        <fieldset disabled={submitting.form}>
          <div className='row px-5 gy-2'>
            <div className='col-12'>
              <input
                className={`form-control form-control-lg`}
                type='number'
                placeholder='Mobile Account Number'
                name='mobileNo'
                value={mobileNo}
                onChange={setFormData}
                required
                maxLength='11'
              />
              {submitting.dangerClass && (
                <small className='text-danger'>
                  Please enter a valid Phone Number in the format 0300-0000000.
                </small>
              )}
            </div>
            <div className='col-12'>
              <input
                className={`form-control form-control-lg `}
                type='number'
                placeholder='Last 6 Digits of CNIC'
                name='CNICLastDigits'
                value={CNICLastDigits}
                onChange={setFormData}
                maxLength='6'
              />
            </div>
            <div className='col-12'>
              <button
                className='btn btn-block done-btn w-100'
                type='submit'
                disabled={
                  mobileNo.length !== 11 || CNICLastDigits.length !== 6
                    ? true
                    : submitting.dangerClass === true
                    ? true
                    : false
                }
              >
                Done
              </button>
            </div>
          </div>
        </fieldset>
      </form>
      <style jsx='true'>{``}</style>
      {/* Importing the Toast Component  */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </div>
  )
}
// EasyPaisaState paymentState
function EasyPaisaState({ getPaymentDetails }) {
  // ------------------ toast states
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('') //  using this state for show the toast message
  const [ToastIcon, setToastIcon] = useState(faCheckCircle) // using this state for changing the toast message icon color
  const [iconColor, setIconColor] = useState('') // using this state for changing the toast message icon color
  const [spin, setSpin] = useState('')
  const params = useParams()
  const [submitting, setSubmitting] = useState({
    form: false,
    dangerClass: false,
  })

  const easyPaisaFormReducer = (state, event) => {
    setSubmitting({
      form: false,
      dangerClass: false,
    })
    if (event.reset) {
      return {
        mobileNo: '',
      }
    }
    return {
      ...state,
      [event.target.name]: event.target.value.slice(0, event.target.maxLength),
      type: 'EasyPaisa',
    }
  }
  const [formData, setFormData] = useReducer(easyPaisaFormReducer, {
    mobileNo: '',
  })
  const { mobileNo } = formData
  //
  const handleSubmit = (e) => {
    e.preventDefault()
    if (mobileNo.slice(0, 2) !== '03') {
      setSubmitting({
        form: false,
        dangerClass: true,
      })
    } else {
      axios
        .post(`${API_CONSTANTS.BASEURL}admin/patients/${params.id}/paymentMethod`, formData, {
          headers: API_CONSTANTS.headers(),
        })
        .then(setFormData({ reset: true }))
        .then((res) => {
          handleToast(
            res.data.response.message,
            faCheckCircle,
            '#009B94',
            'show-success'
          )
          getPaymentDetails()
        })
        .catch((error) => {
          handleToast(
            error.data.response.message,
            faTimesCircle,
            '#B00020',
            'show-danger'
          )
        })
    }
  }
  // ---------------- Handle Toast function
  // --------  This function is for call the Toast message  ------

  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  return (
    <div className='d-flex flex-column align-items-center'>
      <img src={easyPaisaModal} alt='' style={{ width: 69, height: 80 }} />
      <p className='text-center my-3'>
        Please enter your Easypaisa account <br /> details to save payment
        method
      </p>
      <form onSubmit={handleSubmit}>
        <fieldset disabled={submitting.form}>
          <div className='row px-5 gy-2'>
            <div className='col-12'>
              <input
                className={`form-control form-control-lg`}
                type='number'
                placeholder='Mobile Account Number'
                name='mobileNo'
                value={mobileNo}
                onChange={setFormData}
                required
                maxLength='11'
              />
              {submitting.dangerClass && (
                <small className='text-danger'>
                  Please enter a valid Phone Number in the format 0300-0000000.
                </small>
              )}
            </div>

            <div className='col-12'>
              <button
                className='btn btn-block done-btn w-100'
                type='submit'
                disabled={
                  mobileNo.length !== 11
                    ? true
                    : submitting.dangerClass === true
                    ? true
                    : false
                }
              >
                Done
              </button>
            </div>
          </div>
        </fieldset>
      </form>
      <style jsx='true'>{``}</style>
      {/* Importing the Toast Component  */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </div>
  )
}

// default state of paymentModal
function PaymentModalStateOne({ stateValue, getPaymentDetails }) {
  const handleSubmit = (e) => {
    e.preventDefault()
  }
  const handleRadioValue = (e) => {
    stateValue(
      e.target.value === 'creditCard' ? (
        <CreditDebitCardState />
      ) : e.target.value === 'jazzcash' ? (
        <JazzCashState getPaymentDetails={getPaymentDetails} />
      ) : (
        <EasyPaisaState getPaymentDetails={getPaymentDetails} />
      )
    )
  }
  return (
    <>
      <div className='d-flex bg-white flex-column rounded'>
        <div className='d-flex flex-column'>
          <div className='d-flex payment-images align-items-center justify-content-center'>
            <img src={jazzCash} alt='' />
            <img src={creditCard} alt='' />
            <img src={easyPaisa} alt='' />
          </div>
          <div className='d-flex payment-images align-items-center justify-content-center text-center my-3'>
            <p>
              Please choose your preferred <br />
              payment method
            </p>
          </div>
          <div className='d-flex payment-images align-items-center justify-content-center my-3'>
            <form onSubmit={handleSubmit} className='d-flex flex-column w-50'>
              <input
                type='radio'
                className='btn-check custom-radio'
                name='options'
                id='option1'
                autoComplete='off'
                value={'creditCard'}
                onClick={handleRadioValue}
              />
              <label
                className='btn border d-flex align-items-center my-3'
                for='option1'
              >
                <img
                  src={creditCard}
                  alt=''
                  style={{ width: 23, height: 16, marginRight: 20 }}
                />{' '}
                Credit/Debit Card
              </label>

              <input
                type='radio'
                className='btn-check custom-radio'
                name='options'
                id='option2'
                autoComplete='off'
                value={'jazzcash'}
                onClick={handleRadioValue}
              />
              <label
                className='btn border d-flex align-items-center my-3'
                for='option2'
              >
                <img
                  src={jazzCash}
                  alt=''
                  style={{ width: 20, height: 16, marginRight: 20 }}
                />{' '}
                JazzCash
              </label>
              <input
                type='radio'
                className='btn-check custom-radio'
                name='options'
                id='option4'
                autoComplete='off'
                value='easyPaisa'
                onClick={handleRadioValue}
              />
              <label
                className='btn border d-flex align-items-center my-3'
                for='option4'
              >
                <img
                  src={easyPaisa}
                  alt=''
                  style={{ width: 13, height: 16, marginRight: 20 }}
                />{' '}
                EasyPaisa
              </label>
            </form>
          </div>
        </div>
      </div>
      <style jsx='true'>{`
        .payment-images {
          column-gap: 35px;
        }
        .custom-radio:focus + .btn {
          box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 40%) !important;
        }
        .custom-radio:hover + .btn {
          box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 40%) !important;
        }
        .custom-radio + .btn {
          font-size: 20px;
        }
      `}</style>
    </>
  )
}

// payment method modal
export default function AddPaymentMethod({ getPaymentDetails }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleStateValue = (stateChange) => {
    setPaymentState(stateChange)
  }
  const [paymentState, setPaymentState] = useState(
    <PaymentModalStateOne
      stateValue={handleStateValue}
      getPaymentDetails={getPaymentDetails}
    />
  )
  //
  // handleBack
  const handleBack = () => {
    setPaymentState(
      <PaymentModalStateOne
        stateValue={handleStateValue}
        getPaymentDetails={getPaymentDetails}
      />
    )
  }
  return (
    <>
      <button
        className='align-self-start'
        onClick={handleShow}
        style={{
          margin: 0,
          padding: '3px 4px',
          background: 'none',
          outline: 'none',
          // border: 'none',
          fontSize: 15,
          color: '#009B94',
          border: '2px solid #009B94',
          borderRadius: '50%',
          display: 'flex',
        }}
      >
        <i className='fas fa-plus'></i>
      </button>

      <Modal show={show}>
        <div className='d-flex bg-white flex-column rounded'>
          <div className='d-flex justify-content-between w-100 my-1'>
            <button
              className='btn'
              style={{ color: '#009B94', fontSize: 20 }}
              onClick={handleBack}
            >
              <i className='fas fa-arrow-left'></i>
            </button>
            <button
              className='btn'
              style={{ color: '#009B94', fontSize: 20 }}
              onClick={handleClose}
            >
              <i className='fas fa-times'></i>
            </button>
          </div>
          {paymentState}
        </div>
      </Modal>
      <style jsx='true'>{`
        .done-btn {
          background: #009b94;
          color: #fff;
          padding-top: 10px;
          padding-bottom: 10px;
          margin: 15px 0 15px 0;
        }
        .done-btn:hover {
          color: #fff;
        }
      `}</style>
    </>
  )
}
