import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case "LOADING_TRUE": {
      return {
        ...state,
        loading: true,
      };
    }
    case "LOADING_FALSE": {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      };
    }
  }
}

//Doctor related filter search form
export default function DoctorFilterForm({ doctorList }) {
  const filter_status = {
    specialization: useSelector((state) => state.doctorFilterReducer.value),
    gender: useSelector((state) => state.doctorFilterReducer.value1),
    created_at: useSelector((state) => state.doctorFilterReducer.value2),
    appoint_date_from: useSelector((state) => state.doctorFilterReducer.value3),
  };
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    speciality: filter_status.specialization,
    created_at: filter_status.created_at,
    appoint_date_from: filter_status.appoint_date_from,
    gender: filter_status.gender,
    loading: false,
  });

  const dispatch = useDispatch();
  //
  const { speciality, created_at, appoint_date_from, gender, loading } =
    formData;
  //
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormDataDispatch({ type: "LOADING_TRUE" });
    if (speciality !== null) {
      dispatch({
        type: "doctorFilter/" + speciality,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }

    if (appoint_date_from !== null) {
      dispatch({
        type: "doctorFilter/appoint_date_from",
        payload: appoint_date_from,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
    if (created_at !== null) {
      dispatch({
        type: "doctorFilter/created_at",
        payload: created_at,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
    if (gender !== null) {
      dispatch({
        type: "doctorFilter/" + gender,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
  };

  return (
    <>
      <div style={{ marginTop: "-34px", float: "right" }}>
        <button
          className="btn btn-hover"
          style={{ color: "#009B93", fontSize: 16 }}
        >
          <i className="fas fa-redo"></i>
        </button>
      </div>

      <form onSubmit={handleSubmit} className="mt-4 p-2">
        <div className="form-group">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Speciality
          </label>
          <select
            className="form-control filter-input"
            onChange={setFormDataDispatch}
            name="speciality"
            value={speciality}
          >
            <option value="all">All</option>
            <option value={"Dermatologist"}>Dermatologist</option>
            <option value={"Cardiologist"}>Cardiologist</option>
            <option value={"Hematologist"}>Hematologist</option>
            <option value={"Nephrologist"}>Nephrologist</option>
            <option value={"GeneralPhysician"}>General Physician</option>
          </select>
        </div>
        <div class="form-group mt-3">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Gender
          </label>
          <select
            className="form-control"
            onChange={setFormDataDispatch}
            name="gender"
            value={gender}
          >
            <option value="allgender">All</option>
            <option>Male</option>
            <option>Female</option>
            <option>Other</option>
          </select>
        </div>
        <div className="form-group mt-3">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Date From
          </label>

          <input
            onChange={setFormDataDispatch}
            name="appoint_date_from"
            type="date"
            className="form-control filter-input"
            value={appoint_date_from}
          />
        </div>
        <div className="form-group mt-3">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Date To
          </label>

          <input
            onChange={setFormDataDispatch}
            name="created_at"
            type="date"
            className="form-control filter-input"
            value={created_at}
          />
        </div>
        <button className="btn mt-3 filter-form-button">
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: "20px" }}
            />
          )}{" "}
          {loading || "Apply"}
        </button>
      </form>
      <style jsx>{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 100%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
        .btn-hover:hover {
          background: #eee;
        }
      `}</style>
    </>
  );
}
