import React, { useMemo, useEffect, useState } from 'react'
import Table from '../../../../Components/Table'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner'
import { useSelector } from 'react-redux'
// import moment from 'moment'
import FilterForm from './FilterForm'
//
import AlertMessage from '../../../../Components/AlertMessage/index'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
//
//
export default function ServicesTableList() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [adminList, setAdminList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0)
  //
  const filter_status = {
    appoint_type: useSelector((state) => state.ServicesFilterReducer.value),

    appointment_status: useSelector(
      (state) => state.ServicesFilterReducer.value1
    ),
    // appoint_date: useSelector(
    //   (state) => state.ServicesFilterReducer.value2
    // ),
  }
  //
  //
  // pagination call back
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey
      // We'll even set a delay to simulate a server here
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setAdminList(adminList.slice(startRow, endRow))

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(adminList.length / pageSize))
      }
    },
    [adminList, lastEvaluatedKey]
  )
  const handleFilters = () => {
    let filterData = {
      appoint_type: `${filter_status.appoint_type ? filter_status.appoint_type : ''
        }`,
      appointment_status: `${filter_status.appointment_status ? filter_status.appointment_status : ''
        }`,
      appoint_date: `${filter_status.appoint_date ? filter_status.appoint_date : ''
        }`,
    }
    if (Object.values(filterData).every((key) => key === '')) {
      return {}
    } else
      return Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== '')
      )
  }
  //
  // API Hatching data of all admins
  const userList = () => {
    axios
      .post(`${API_CONSTANTS.BASEURL_3}admin/appointments/nurse`,
        {
          "appoint_type": "NurseBooked"
        },
        {
          headers: API_CONSTANTS.headers(),
        })
      .then((res) => {
        setIsLoading(true)
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
        setAdminList(res.data.response.Items ? res.data.response.Items : [])
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setIsLoading(true)
        setAdminList([])
        // setError(error)
      })
  }
  const [page, setPage] = useState(0)

  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_3}admin/appointments/nurse?last=${lastEvaluatedKey}`,
        {
          "appoint_type": "NurseBooked"
        },
        handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
        var adminListNextPage = response.data.response.Items
        setAdminList([...adminList, ...adminListNextPage])
        setPage(page + 1)
      })
      .then(() => setLoading(true))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Services Type',
        accessor: 'appoint_type',
        // accessor: (property) =>
        //   property.type
        //     ? `${property.appoint_type} type: ` +
        //       property.type
        //     : '',
      },
      {
        Header: 'Patient Name',
        accessor: 'patientInfo.name',
        Cell: ({ cell: { value } }) => value || 'N/A',
      },
      {
        Header: 'Patient Phone',
        accessor: 'patientPhone',
      },
      {
        Header: `Nurse's Name`,
        accessor: 'nurseName',
      },
      {
        Header: 'Status',
        accessor: 'appointment_status'
        // accessor: (property) =>
        //   property.status.replace(/(?:^|\s)\S/g, function (a) {
        //     return a.toUpperCase()
        //   }),
      },
      // {
      //   Header: 'Consultation Date/time',
      //   accessor: (a) => moment(a.appoint_date).format('DD-MM-YYYY hh:mm A'),
      // },
    ],
    []
  )
  const is_SessionExpire = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  // rendering API
  useEffect(() => {
    userList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // filter_status.appoint_date,
    filter_status.appoint_type,
    filter_status.appointment_status,
  ])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 404'
            ? 'This record does not exist'
            : error.message === 'Request failed with status code 401'
              ? is_SessionExpire()
              : error.message
        }
      />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <div id='table-view' className='row rounded bg-white m-4'>
        <div>
          {' '}
          <h3 style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}>
            All Services
          </h3>
        </div>
        <Table
          pageNumber={page}
          columns={columns}
          data={adminList}
          routingLink={'/services/profile'}
          pageCount={pageCount}
          fetchData={fetchData}
          loadMoreList={handleLoadMoreData}
          lastEvaluatedKey={lastEvaluatedKey}
          loading={loading}
          setLoading={setLoading}
          FilterForm={<FilterForm />}
        />
      </div>
    )
  }
}
