/*
Authore: Umer Shabir
Date: April-2022
File: this component is responsible for editing an admin profile.
*/
// dependencies
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { API_CONSTANTS, headers } from '../../../../API_CONSTANTS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
// import Formik & yup libraries
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { getProfile } from './AdminProfileSlice'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../../Components/Toast/Toast'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// Edit Profile button
export default function EditPasswordButton() {
  const [show, setShow] = useState(false)
  const [saveSpin, setsaveSpin] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const params = useParams()
  const dispatch = useDispatch()
  const [toast, setToast] = useState({
    icon: '',
    message: '',
    iconColor: '',
    cName: '',
  })
  const [spin, setSpin] = useState('')
  const handle_update_Admin = (values) => {
    setsaveSpin('spinner-border spinner-border-sm');
    const data = { password: values.password };
    axios
      .patch(`${API_CONSTANTS.BASEURL}admin/updateAdmin/${params.id}?changepassword=true`, data, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setToast({
          message: res.data.response.message,
          icon: faCheckCircle,
          iconColor: '#009B94',
          cName: `show show-success`,
        })
        setTimeout(() => {
          setToast({
            icon: '',
            message: '',
            iconColor: '',
            cName: '',
          })
        }, 1500)
        setSpin('')
        setsaveSpin('')
        setShow(false)
        dispatch(getProfile(params.id))
      })

      .catch((error) => {
        setToast({
          message: error.data.response.message,
          icon: faTimesCircle,
          iconColor: '#B00020',
          cName: `show show-danger`,
        })
        setTimeout(() => {
          setToast({
            icon: '',
            message: '',
            iconColor: '',
            cName: '',
          })
        }, 1500)
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setsaveSpin('')
      })
  }

  return (
    <>
      <button
        className='btn custom-button edit-button'
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faCog} className={'edit-icon me-2'} />
        Change Password
      </button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <Formik
            initialValues={{
              password: '',
            }}
            validationSchema={Yup.object({
              password: Yup.string()
                .required('Please enter a new password')
                .min(8, 'Password must be at least 8 characters')
                .max(15, 'Password must be at most 15 characters')
                .matches(
                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                  'Password must have letters uppercase, numbers, and special characters'
                ),
            })}
            onSubmit={(values) => {
              handle_update_Admin(values);
            }}
          >
            {(formik) => (
              <Form>
                <div className='row py-3'>
                  <div className={'col-md-12 py-2'}>
                    <div className="mb-3">
                      <label htmlFor="exampleInputPassword1" className="form-label">New Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword ? 'text' : 'password'} // Toggle password visibility
                          className="form-control"
                          id="exampleInputPassword1"
                          name='password'
                          value={formik.values.password}
                          onChange={formik.handleChange}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={() => setShowPassword(!showPassword)} // Toggle password visibility on button click
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                          />
                        </button>
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div className={'error-message'}>
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={'col-md-12 pt-3'}>
                    <button type={'submit'} className={'btn btn-save '}>
                      Save Changes
                      <span className={saveSpin} />
                    </button>
                    <button
                      className={'btn btn-discard'}
                      onClick={() => setShow(false)}
                      type={'button'}
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <style jsx='true'>
        {`
          .edit-button {
            border: 2px solid #009b94;
            color: #009b94;
          }
          .edit-button:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-save {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
          }
          .custom-input-field {
            width: 100%;
            border: none;
            border-bottom: 1px solid #cccccc;
            text-transform: capitalize;
          }
          .custom-input-field:focus-visible {
            outline: none;
            border-bottom: 1px solid #009b94;
          }
          .p_label {
            font-size: 14px;
            color: #5c5c5c;
          }
          .error-message {
            font-size: 13px;
            color: red;
          }
        `}
      </style>

      <Toast
        cName={toast.cName}
        icon={toast.icon}
        color={toast.iconColor}
        text={toast.message}
      />
    </>
  )
}
