/*
author: Umer Shabir.
file: doctor availability module: It will set time slots for doctor avaialabilty schedule.
date: 16-08-2022.
edited:12-09-2022.
updated: 25-10-2022.
*/
// dependencies
import React, { useEffect, useReducer, useState } from 'react'
import AddTimeSlot from './AddTimeSlot'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../../../API_CONSTANTS'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { getSession } from './sessionSlice'
import Toast from '../../../../../../Components/Toast/Toast'
import moment from 'moment'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import Loader from '../../../../../../Components/Loader/index.js'

let in_array = require('in_array')
// Availability Component
export default function SetTimeSlots() {
  // setting up all the states
  const params = useParams()
  const dispatch = useDispatch()
  // getting redux thunk state
  const { value, loading } = useSelector((state) => state.getSessionReducer)
  const [spin, setSpin] = useState()
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [formDisable, setFormDisable] = useState({
    form: false,
    button: true,
  })
  // to update all the state asyncronously I am putting reducer function inside the component above the useReducer hook
  const formReducer = (state, action) => {
    switch (action.type) {
      case 'GET_DATA':
        return {
          chat: value.chat === undefined ? false : value.chat,
          video: value.video === undefined ? true : value.video,
          sundaySlots:
            value.sundaySlots === undefined
              ? []
              : value.sundaySlots
                .slice()
                .sort(
                  (a, b) => parseInt(a.startTime) - parseInt(b.startTime)
                ),
          saturday: value.saturday === undefined ? false : value.saturday,
          fridaySlots:
            value.fridaySlots === undefined
              ? []
              : value.fridaySlots
                .slice()
                .sort(
                  (a, b) => parseInt(a.startTime) - parseInt(b.startTime)
                ),
          tuesdaySlots:
            value.tuesdaySlots === undefined
              ? []
              : value.tuesdaySlots
                .slice()
                .sort(
                  (a, b) => parseInt(a.startTime) - parseInt(b.startTime)
                ),
          wednesdaySlots:
            value.wednesdaySlots === undefined
              ? []
              : value.wednesdaySlots
                .slice()
                .sort(
                  (a, b) => parseInt(a.startTime) - parseInt(b.startTime)
                ),
          thursday: value.thursday === undefined ? false : value.thursday,
          thursdaySlots:
            value.thursdaySlots === undefined
              ? []
              : value.thursdaySlots
                .slice()
                .sort(
                  (a, b) => parseInt(a.startTime) - parseInt(b.startTime)
                ),
          sunday: value.sunday === undefined ? false : value.sunday,
          tuesday: value.tuesday === undefined ? false : value.tuesday,
          mondaySlots:
            value.mondaySlots === undefined
              ? []
              : value.mondaySlots
                .slice()
                .sort(
                  (a, b) => parseInt(a.startTime) - parseInt(b.startTime)
                ),
          friday: value.friday === undefined ? false : value.friday,
          wednesday: value.wednesday === undefined ? false : value.wednesday,
          saturdaySlots:
            value.saturdaySlots === undefined
              ? []
              : value.saturdaySlots
                .slice()
                .sort(
                  (a, b) => parseInt(a.startTime) - parseInt(b.startTime)
                ),
          monday: value.monday === undefined ? false : value.monday,
          duration: value.duration === undefined ? 10 : value.duration,
          schedule: value.schedule === undefined ? 7 : value.schedule,
          chatAppointmentFee:
            value.chatAppointmentFee === undefined
              ? 10
              : value.chatAppointmentFee,
          videoAppointmentFee: value.videoAppointmentFee,
        }
      case 'monday':
        return {
          ...state,
          mondaySlots: [...state.mondaySlots, action.payload],
        }
      case 'tuesday':
        return {
          ...state,
          tuesdaySlots: [...state.tuesdaySlots, action.payload],
        }
      case 'wednesday':
        return {
          ...state,
          wednesdaySlots: [...state.wednesdaySlots, action.payload],
        }
      case 'thursday':
        return {
          ...state,
          thursdaySlots: [...state.thursdaySlots, action.payload],
        }
      case 'friday':
        return {
          ...state,
          fridaySlots: [...state.fridaySlots, action.payload],
        }
      case 'saturday':
        return {
          ...state,
          saturdaySlots: [...state.saturdaySlots, action.payload],
        }
      case 'sunday':
        return {
          ...state,
          sundaySlots: [...state.sundaySlots, action.payload],
        }
      case 'monday_DELETE':
        return {
          ...state,
          mondaySlots: action.payload.data.filter(
            (item, index) => index !== action.payload.slot
          ),
        }
      case 'tuesday_DELETE':
        return {
          ...state,
          tuesdaySlots: action.payload.data.filter(
            (item, index) => index !== action.payload.slot
          ),
        }
      case 'wednesday_DELETE':
        return {
          ...state,
          wednesdaySlots: action.payload.data.filter(
            (item, index) => index !== action.payload.slot
          ),
        }
      case 'thursday_DELETE':
        return {
          ...state,
          thursdaySlots: action.payload.data.filter(
            (item, index) => index !== action.payload.slot
          ),
        }
      case 'friday_DELETE':
        return {
          ...state,
          fridaySlots: action.payload.data.filter(
            (item, index) => index !== action.payload.slot
          ),
        }
      case 'saturday_DELETE':
        return {
          ...state,
          saturdaySlots: action.payload.data.filter(
            (item, index) => index !== action.payload.slot
          ),
        }
      case 'sunday_DELETE':
        return {
          ...state,
          sundaySlots: action.payload.data.filter(
            (item, index) => index !== action.payload.slot
          ),
        }
      case 'monday_UPDATE': {
        return {
          ...state,
          mondaySlots: action.payload.data,
        }
      }
      case 'tuesday_UPDATE': {
        return {
          ...state,
          tuesdaySlots: action.payload.data,
        }
      }
      case 'wednesday_UPDATE': {
        return {
          ...state,
          wednesdaySlots: action.payload.data,
        }
      }
      case 'thursday_UPDATE': {
        return {
          ...state,
          thursdaySlots: action.payload.data,
        }
      }
      case 'friday_UPDATE': {
        return {
          ...state,
          fridaySlots: action.payload.data,
        }
      }
      case 'saturday_UPDATE': {
        return {
          ...state,
          saturdaySlots: action.payload.data,
        }
      }
      case 'sunday_UPDATE': {
        return {
          ...state,
          sundaySlots: action.payload.data,
        }
      }
      default:
        return {
          ...state,
          [action.target.name]:
            action.target.type === 'checkbox'
              ? action.target.checked
              : action.target.type === 'number'
                ? parseInt(action.target.value)
                : action.target.name === 'duration' ||
                  action.target.name === 'schedule'
                  ? parseInt(action.target.value)
                  : action.target.value,
        }
    }
  }
  // initializing the useReducer hook for state management
  const [formData, setFormData] = useReducer(formReducer, {
    chat: false,
    video: true,
    sundaySlots: [],
    saturday: false,
    fridaySlots: [],
    tuesdaySlots: [],
    wednesdaySlots: [],
    thursday: false,
    thursdaySlots: [],
    sunday: false,
    tuesday: false,
    mondaySlots: [],
    friday: false,
    wednesday: false,
    saturdaySlots: [],
    monday: false,
    duration: '',
    schedule: '',
    chatAppointmentFee: 10,
    videoAppointmentFee: '',
  })
  // extracting all the values from Reducer to update the state
  const {
    chat,
    video,
    sundaySlots,
    saturday,
    fridaySlots,
    tuesdaySlots,
    wednesdaySlots,
    thursday,
    thursdaySlots,
    sunday,
    tuesday,
    mondaySlots,
    friday,
    wednesday,
    saturdaySlots,
    monday,
    duration,
    schedule,
    chatAppointmentFee,
    videoAppointmentFee,
  } = formData
  // an array to map all the weeks and it's data.
  const weekName = [
    {
      name: 'monday',
      check: monday,
      Slots: mondaySlots,
      id: 0,
    },
    {
      name: 'tuesday',
      check: tuesday,
      Slots: tuesdaySlots,
      id: 1,
    },
    {
      name: 'wednesday',
      check: wednesday,
      Slots: wednesdaySlots,
      id: 2,
    },
    {
      name: 'thursday',
      check: thursday,
      Slots: thursdaySlots,
      id: 3,
    },
    {
      name: 'friday',
      check: friday,
      Slots: fridaySlots,
      id: 4,
    },
    {
      name: 'saturday',
      check: saturday,
      Slots: saturdaySlots,
      id: 5,
    },
    {
      name: 'sunday',
      check: sunday,
      Slots: sundaySlots,
      id: 6,
    },
  ]
  // Toast function
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  // add time slot callback function
  const addTimeSlot = async (data, itemIndex) => {
    setFormData({
      type: weekName[itemIndex].name,
      payload: data,
    })
  }

  // handle delete function
  const handleDelete = (data, name, slot) => {
    setFormData({ type: `${name}_DELETE`, payload: { data, name, slot } })
    setFormDisable((formDisable.button = false))
  }
  // updating the timeslot object
  const handleUpdateSlot = (
    currentValue,
    slots,
    lastValue,
    name,
    currentSlot,
    slotIndex
  ) => {
    if (currentSlot.startTime === lastValue) {
      let updatedSlots = slots.map((item, index) => {
        if (index === slotIndex) {
          return { ...item, startTime: currentValue }
        }
        return item
      })
      setFormData({
        type: `${name}_UPDATE`,
        payload: {
          data: updatedSlots,
        },
      })
    } else {
      let updatedSlots = slots.map((item, index) => {
        if (index === slotIndex) {
          return { ...item, endTime: currentValue }
        }
        return item
      })
      setFormData({
        type: `${name}_UPDATE`,
        payload: {
          data: updatedSlots,
        },
      })
    }
  }
  // submiting the data
  const handleSubmit = (e) => {
    e.preventDefault()
    setSpin('spinner-border spinner-border-sm')
    setFormDisable({
      form: true,
      button: true,
    })

    axios
      .patch(
        `${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/sessions`,
        formData,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        // console.log(res.data.response.message)
        handleToast(
          res.data.response.message,
          faCheckCircle,
          '#009b94',
          'show-success'
        )
        setSpin('')
      })
      .then(() =>
        setFormDisable({
          form: false,
          button: true,
        })
      )
      .catch((e) => {
        handleToast(
          e.response?.data?.response?.message
            ? e.response.data.response.message
            : 'Something is get wrong',
          faExclamationTriangle,
          'ffffff',
          'show-danger'
        )
        setSpin(false)
        setFormDisable({
          form: false,
          button: false,
        })
      })
  }
  // dispatching action to the redux thunk to fetch previous data
  useEffect(async () => {
    await dispatch(getSession(params.id)).then(() =>
      setFormData({ type: 'GET_DATA' })
    )
  }, [])
  return (
    <>
      {loading && <Loader />}
      <div className='row m-4 rounded p-2'>
        <div className='col-12'>
          <div className='d-flex'>
            <form
              onSubmit={handleSubmit}
              className='w-100'
              onChange={() => setFormDisable((formDisable.button = false))}
              style={{ cursor: formDisable.form ? 'no-drop' : '' }}
            >
              <fieldset
                disabled={formDisable.form}
                style={{ cursor: formDisable.form ? 'no-drop' : '' }}
              >
                <div className='d-flex justify-content-between rounded p-3 bg-light mb-3 align-items-center'>
                  <div className='d-flex flex-column w-25 px-3'>
                    <label htmlFor='days'> Schedule Valid For</label>
                    <select
                      name='schedule'
                      type='select'
                      id='days'
                      className='bg-light border-bottom form-select'
                      value={schedule}
                      onChange={setFormData}
                      style={{
                        cursor: formDisable.form ? 'no-drop' : '',
                      }}
                    >
                      <option value='7'>7 days</option>
                      <option value='14'>14 days</option>
                      <option value='21'>21 days</option>
                      <option value='28'>28 days</option>
                    </select>
                  </div>
                  <div className='d-flex flex-column  w-25 px-3'>
                    <label htmlFor='appoint'> Duration of Appointment</label>
                    <select
                      name='duration'
                      id='appoint'
                      className='bg-light border-bottom form-select'
                      value={duration}
                      onChange={setFormData}
                      style={{
                        cursor: formDisable.form ? 'no-drop' : '',
                      }}
                    >
                      <option value='10'>10 minutes</option>
                      <option value='15'>15 minutes</option>
                      <option value='20'>20 minutes</option>
                      <option value='25'>25 minutes</option>
                      <option value='30'>30 minutes</option>
                      <option value='35'>35 minutes</option>
                      <option value='40'>40 minutes</option>
                      <option value='45'>45 minutes</option>
                      <option value='50'>50 minutes</option>
                      <option value='55'>55 minutes</option>
                      <option value='60'>60 minutes</option>
                    </select>
                  </div>
                  <div className='d-flex flex-column  w-25 px-3'>
                    <label htmlFor='videoFee'> Video Appointment Fee </label>
                    <input
                      type='number'
                      name='videoAppointmentFee'
                      id='videoFee'
                      className='bg-light border-bottom form-control'
                      required
                      placeholder='Ex 300 Rs'
                      value={videoAppointmentFee}
                      onChange={setFormData}
                      style={{
                        cursor: formDisable.form ? 'no-drop' : '',
                      }}
                    />
                  </div>
                  <div className='d-flex flex-column  w-25 px-3'>
                    <label htmlFor='chatFee'> Online Chat Fee </label>
                    <input
                      type='number'
                      name='chatAppointmentFee'
                      id='chatFee'
                      className='bg-light border-bottom form-control'
                      required
                      placeholder='Ex 200 Rs'
                      value={chatAppointmentFee}
                      onChange={setFormData}
                      disabled
                      style={{
                        cursor: formDisable.form ? 'no-drop' : '',
                      }}
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset
                className='d-flex flex-column bg-light rounded mb-3'
                disabled={formDisable.form}
                style={{ cursor: formDisable.form ? 'no-drop' : 'default' }}
              >
                {weekName.map((item, index) => (
                  <fieldset
                    className='d-flex w-100 border-bottom'
                    key={index}
                    disabled={formDisable.form}
                    style={{ cursor: formDisable.form ? 'no-drop' : 'default' }}
                  >
                    <div className='w-25 d-flex justify-content-between align-items-start form-check form-switch p-3'>
                      <label htmlFor='monday' className='form-check-label'>
                        {item.name.slice(0, 1).toUpperCase() +
                          item.name.slice(1)}
                      </label>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        name={item.name}
                        onChange={setFormData}
                        checked={item.check}
                        style={{
                          cursor: formDisable.form ? 'no-drop' : 'default',
                        }}
                      />
                    </div>
                    <fieldset
                      className='w-75 d-flex justify-content-between align-items-start p-3'
                      style={{
                        background: '#E0F3F2',
                        cursor: formDisable.form ? 'no-drop' : 'default',
                        flexDirection: 'column-reverse',
                      }}
                      disabled={item.check === true ? false : true}
                      onClick={() =>
                        setFormDisable((formDisable.button = false))
                      }
                    >
                      <div className='d-flex flex-column time-slot-responsive w-100'>
                        {item.Slots === undefined
                          ? []
                          : item.Slots.map((slot, index) => (
                            <div
                              key={index}
                              className='d-flex align-items-center w-100 my-1'
                              style={{ columnGap: 20 }}
                            >
                              <div className='d-flex my-1 align-items-center'>
                                <span
                                  className='me-3'
                                  style={{ width: '30px' }}
                                >
                                  {index + 1}.
                                </span>
                                <span>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Stack spacing={3}>
                                      <TimePicker
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                        value={
                                          slot.startTime !== ''
                                            ? new Date(
                                              0,
                                              0,
                                              0,
                                              slot.startTime !== undefined
                                                ? moment(
                                                  moment().format(
                                                    'MM/DD/YYYY'
                                                  ) +
                                                  ' ' +
                                                  slot.startTime
                                                ).hour()
                                                : 0,
                                              moment(
                                                moment().format(
                                                  'MM/DD/YYYY'
                                                ) +
                                                ' ' +
                                                slot.startTime
                                              ).minute()
                                            )
                                            : null
                                        }
                                        label='Start Time'
                                        onChange={(e) => {
                                          handleUpdateSlot(
                                            moment(e.$d).format('HH:mm'),
                                            item.Slots,
                                            slot.startTime,
                                            item.name,
                                            slot,
                                            index
                                          )
                                        }}
                                        minutesStep={5}
                                        disabled={
                                          formDisable.form === true
                                            ? true
                                            : false || item.check === true
                                              ? false
                                              : true
                                        }
                                        style={{
                                          cursor: formDisable.form
                                            ? 'no-drop'
                                            : 'default',
                                        }}
                                        shouldDisableTime={(
                                          timeValue,
                                          clockType
                                        ) => {
                                          let disableTimes = []
                                          let disableTimeSlots = []

                                          if (item.Slots.length > 1) {
                                            item.Slots.map((item) => {
                                              if (
                                                slot.startTime < item.endTime
                                              ) {
                                                let openingTime =
                                                  item.startTime.slice(0, 2)
                                                let closingTime =
                                                  item.endTime.slice(0, 2)
                                                disableTimeSlots.push({
                                                  start: item.startTime,
                                                  end: item.endTime,
                                                })
                                                // while (
                                                //   openingTime <= closingTime
                                                // ) {
                                                //   if (openingTime !== '') {
                                                //     disableTimes.push(
                                                //       openingTime.toString()
                                                //     )
                                                //     disableTimes.sort(
                                                //       (a, b) => a - b
                                                //     )
                                                //   }
                                                //   console.log(disableTimes)
                                                //   openingTime++
                                                // }
                                              }
                                            })
                                            // console.log(
                                            //   item.Slots[
                                            //     item.Slots.length - 2
                                            //   ]
                                            // )
                                            //debugger;
                                            console.log(
                                              slot.startTime +
                                              '   ' +
                                              slot.endTime
                                            )

                                            if (
                                              index ===
                                              item.Slots.length - 1 &&
                                              slot.startTime <
                                              item.Slots[
                                                item.Slots.length - 2
                                              ].endTime
                                            ) {
                                              let currentValue =
                                                parseInt(timeValue)
                                              if (clockType === 'hours') {
                                                let availableMinutes = 60
                                                disableTimeSlots.forEach(
                                                  function (
                                                    timeSlot,
                                                    index,
                                                    arr
                                                  ) {
                                                    let startHr = parseInt(
                                                      timeSlot.start.slice(
                                                        0,
                                                        2
                                                      )
                                                    )
                                                    let endHr = parseInt(
                                                      timeSlot.end.slice(0, 2)
                                                    )

                                                    if (
                                                      currentValue >=
                                                      startHr &&
                                                      currentValue <= endHr
                                                    ) {
                                                      let startTime = ''
                                                      let endTime = ''
                                                      if (
                                                        currentValue > startHr
                                                      ) {
                                                        startTime = moment(
                                                          currentValue +
                                                          ':00',
                                                          'hh:mm'
                                                        )
                                                      } else {
                                                        startTime = moment(
                                                          timeSlot.start,
                                                          'hh:mm'
                                                        )
                                                      }
                                                      if (
                                                        endHr > currentValue
                                                      ) {
                                                        endTime = moment(
                                                          currentValue +
                                                          1 +
                                                          ':00',
                                                          'hh:mm'
                                                        )
                                                      } else {
                                                        endTime = moment(
                                                          timeSlot.end,
                                                          'hh:mm'
                                                        )
                                                      }
                                                      availableMinutes =
                                                        availableMinutes -
                                                        Math.abs(
                                                          endTime.diff(
                                                            startTime,
                                                            'minutes'
                                                          )
                                                        )
                                                    }
                                                  }
                                                )
                                                if (availableMinutes <= 0) {
                                                  return true
                                                }
                                              }
                                              if (clockType === 'minutes') {
                                                let disableMinutes = false
                                                disableTimeSlots.forEach(
                                                  function (
                                                    timeSlot,
                                                    index,
                                                    arr
                                                  ) {
                                                    let temp =
                                                      moment().format(
                                                        'YYYY-MM-DD'
                                                      )
                                                    disableMinutes = moment(
                                                      temp +
                                                      ' ' +
                                                      slot.startTime.slice(
                                                        0,
                                                        2
                                                      ) +
                                                      ':' +
                                                      currentValue
                                                    ).isBetween(
                                                      temp +
                                                      ' ' +
                                                      timeSlot.start,
                                                      temp +
                                                      ' ' +
                                                      timeSlot.end
                                                    )
                                                  }
                                                )
                                                return disableMinutes
                                              }
                                            }
                                            //  if (
                                            //    index ===
                                            //    item.Slots.length - 1 && slot.startTime <
                                            //  ) {
                                            //    return (
                                            //      clockType === 'hours' &&
                                            //      in_array(
                                            //        timeValue,
                                            //        disableTimes
                                            //      )
                                            //    )
                                            //  }
                                          }
                                          // console.log(
                                          //   slot.startTime.slice(0, 2)
                                          // )
                                          // console.log(
                                          //   slot.endTime.slice(0, 2)
                                          // )
                                          // let openingTime =
                                          //   slot.startTime.slice(0, 2)
                                          // let closingTime =
                                          //   slot.endTime.slice(0, 2)
                                          // while (openingTime <= closingTime) {
                                          //   disableTimes.push(
                                          //     openingTime.toString()
                                          //   )
                                          //   console.log(disableTimes)
                                          //   openingTime++
                                          // }

                                          // if (item.Slots.length !== 1) {
                                          //   item.Slots.map((item, index) => {
                                          //     let openingTime = item.startTime
                                          //     let endingtime = item.endTime
                                          //     console.log(openingTime)
                                          //   })
                                          //   return (
                                          //     clockType === 'hours' &&
                                          //     !in_array(
                                          //       timeValue,
                                          //       disableTimes
                                          //     )
                                          //   )
                                          // }
                                        }}
                                      />
                                    </Stack>
                                  </LocalizationProvider>
                                </span>
                                <p className='m-0 mx-2'>to</p>
                                <span>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Stack spacing={3}>
                                      <TimePicker
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                        value={
                                          slot.endTime !== ''
                                            ? new Date(
                                              0,
                                              0,
                                              0,
                                              slot.endTime !== undefined
                                                ? moment(
                                                  moment().format(
                                                    'MM/DD/YYYY'
                                                  ) +
                                                  ' ' +
                                                  slot.endTime
                                                ).hour()
                                                : 0,
                                              moment(
                                                moment().format(
                                                  'MM/DD/YYYY'
                                                ) +
                                                ' ' +
                                                slot.endTime
                                              ).minute()
                                            )
                                            : null
                                        }
                                        label='End Time'
                                        onChange={(e) => {
                                          handleUpdateSlot(
                                            moment(e.$d).format('HH:mm'),
                                            item.Slots,
                                            slot.endTime,
                                            item.name,
                                            slot,
                                            index
                                          )
                                        }}
                                        minutesStep={5}
                                        disabled={
                                          formDisable.form === true
                                            ? true
                                            : false || item.check === true
                                              ? false
                                              : true
                                        }
                                        style={{
                                          cursor: formDisable.form
                                            ? 'no-drop'
                                            : 'default',
                                        }}
                                        shouldDisableTime={(
                                          timeValue,
                                          clockType
                                        ) => {
                                          let x = {
                                            slotInterval: duration,
                                            openTime:
                                              slot.startTime !== ''
                                                ? new Date(
                                                  0,
                                                  0,
                                                  0,
                                                  slot.startTime !==
                                                    undefined
                                                    ? moment(
                                                      moment().format(
                                                        'MM/DD/YYYY'
                                                      ) +
                                                      ' ' +
                                                      slot.startTime
                                                    ).hour()
                                                    : 0,
                                                  moment(
                                                    moment().format(
                                                      'MM/DD/YYYY'
                                                    ) +
                                                    ' ' +
                                                    slot.startTime
                                                  ).minute()
                                                )
                                                : null,
                                            closeTime:
                                              slot.endTime !== ''
                                                ? new Date(
                                                  0,
                                                  0,
                                                  0,
                                                  slot.endTime !== undefined
                                                    ? moment(
                                                      moment().format(
                                                        'MM/DD/YYYY'
                                                      ) +
                                                      ' ' +
                                                      slot.endTime
                                                    ).hour()
                                                    : 0,
                                                  moment(
                                                    moment().format(
                                                      'MM/DD/YYYY'
                                                    ) +
                                                    ' ' +
                                                    slot.endTime
                                                  ).minute()
                                                )
                                                : null,
                                          }
                                          let allTimes = []
                                          let startTime = moment(
                                            x.openTime,
                                            'HH:mm'
                                          )
                                          let endTime = moment(
                                            x.closeTime,
                                            'HH:mm'
                                          ).add(1, 'hours')
                                          let activeHour = moment(
                                            slot.endTime !== ''
                                              ? new Date(
                                                0,
                                                0,
                                                0,
                                                slot.endTime !== undefined
                                                  ? moment(
                                                    moment().format(
                                                      'MM/DD/YYYY'
                                                    ) +
                                                    ' ' +
                                                    slot.endTime
                                                  ).hour()
                                                  : 0,
                                                moment(
                                                  moment().format(
                                                    'MM/DD/YYYY'
                                                  ) +
                                                  ' ' +
                                                  slot.endTime
                                                ).minute()
                                              )
                                              : null
                                          ).format('HH')

                                          while (startTime < endTime) {
                                            if (
                                              activeHour ===
                                              startTime.format('HH')
                                            ) {
                                              allTimes.push(
                                                startTime.format('mm')
                                              )
                                            }
                                            startTime.add(
                                              x.slotInterval,
                                              'minutes'
                                            )
                                          }
                                          if (
                                            moment(x.openTime).format(
                                              'HH'
                                            ) ===
                                            moment(x.closeTime).format('HH')
                                          ) {
                                            allTimes.splice(0, 1)
                                          }
                                          return (
                                            clockType === 'minutes' &&
                                            !in_array(timeValue, allTimes)
                                          )
                                        }}
                                      />
                                    </Stack>
                                  </LocalizationProvider>
                                </span>
                              </div>
                              <div
                                className='d-flex'
                                style={{ columnGap: '20px' }}
                              >
                                <div>
                                  <input
                                    type='checkbox'
                                    checked={slot.video}
                                    readOnly
                                    className='form-check-input mx-2'
                                    style={{
                                      cursor: formDisable.form
                                        ? 'no-drop'
                                        : '',
                                    }}
                                  />
                                  <label
                                    htmlFor='videoChat'
                                    className='form-check-label '
                                    style={{ color: '#5C5C5C' }}
                                  >
                                    {' '}
                                    <i className='fas fa-video'></i>
                                  </label>
                                </div>
                                <div>
                                  <input
                                    type='checkbox'
                                    defaultChecked={slot.chat}
                                    disabled
                                    className='form-check-input mx-2'
                                    style={{
                                      cursor: formDisable.form
                                        ? 'no-drop'
                                        : '',
                                    }}
                                  />
                                  <label
                                    htmlFor='Chat'
                                    className='form-check-label'
                                    style={{ color: '#5C5C5C' }}
                                  >
                                    <i className='fas fa-comment-medical'></i>
                                  </label>
                                </div>
                              </div>
                              <div>
                                {/* <deleteTimeSlot /> */}
                                <button
                                  type='button'
                                  className=' btn'
                                  style={{
                                    color: '#FF6760',
                                    cursor: formDisable.form ? 'no-drop' : '',
                                  }}
                                  disabled={item.Slots.length === 1}
                                  onClick={() =>
                                    handleDelete(item.Slots, item.name, index)
                                  }
                                >
                                  <i className='fas fa-trash-alt'></i>
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className='d-flex justify-content-end w-100'>
                        <AddTimeSlot
                          addTimeSlot={addTimeSlot}
                          itemIndex={item.id}
                          item={item}
                          duration={duration}
                          activeForm={setFormDisable}
                        />
                      </div>
                    </fieldset>
                  </fieldset>
                ))}
              </fieldset>
              <div
                className='d-flex w-100 justify-content-end '
                style={{ columnGap: '10px' }}
              >
                {/* <button
                  className='btn'
                  type='submit'
                  style={{
                    border: '1px solid #009B94',
                    color: '#009B94',
                    padding: '5px 50px',
                  }}
                >
                  Reset
                </button> */}
                <button
                  className='btn d-flex align-items-center'
                  style={{
                    background: '#009B94',
                    color: '#fff',
                    padding: '5px 50px',
                    cursor: formDisable.form ? 'no-drop' : '',
                  }}
                  type='submit'
                  disabled={formDisable.button}
                >
                  Save
                  <span className={spin} />
                </button>
              </div>
            </form>
          </div>
        </div>
        <Toast
          cName={showToast}
          icon={ToastIcon}
          color={iconColor}
          text={ToastMessage}
        />
        <style jsx='true'>{`
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
          .custom-button:hover {
            text-decoration: underline;
          }
          .form-check-input:checked {
            background-color: #009b94;
            border-color: #009b94;
          }
          .form-select:focus {
            border-color: #009b94;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 30%);
          }
          .form-control:focus {
            border-color: #009b94;
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 30%);
          }

          select:selection {
            background-color: red;
            color: white;
          }
          .mouse-cursor {
            cursor: no-drop;
          }
          .time-slot-responsive {
            width: 50%;
          }
          @media only screen and (max-width: 1600px) {
            .time-slot-responsive {
              width: 70%;
            }
          }
        `}</style>
      </div>
    </>
  )
}
