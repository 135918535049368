/*
Author: Umer Shabir
Date: april-2022
File: Admin user list filter form.
*/
// dependencies.
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case "LOADING_TRUE": {
      return {
        ...state,
        loading: true,
      };
    }
    case "LOADING_FAlSE": {
      return {
        ...state,
        loading: false,
      };
    }

    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      };
    }
  }
}

// Admin related filter search form
export default function AdminFilterForm() {
  const filter_Status = useSelector(
    (state) => state.adminFilteredReducer.value
  );
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    department: filter_Status,
    loading: false,
  });
  //
  const dispatch = useDispatch();
  //
  const { department, loading } = formData;
  //
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormDataDispatch({ type: "LOADING_TRUE" });
    dispatch({ type: "adminFilter/" + department });
    //
    setTimeout(() => {
      setFormDataDispatch({ type: "LOADING_FAlSE" });
      dispatch({ type: "tableFilterModal/setHideModal" });
    }, 1000);
  };
  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4 p-2">
        <div className="form-group">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Department
          </label>
          <select
            className="form-control filter-input my-2"
            id="exampleFormControlSelect1"
            onChange={setFormDataDispatch}
            name="department"
            value={department}
          >
            <option value={"all"}>All</option>
            <option value={"Admin"}>Admin</option>
            <option value={"Consultant"}>Consultant</option>
            <option value={"DoctorAdmin"}>DoctorAdmin</option>
            <option value={"CustomerSupport"}>CustomerSupport</option>
            <option value={"HealthCare"}>HealthCare</option>
            <option value={"AccountsDept"}>Accounts Department</option>
            <option value={"DevTeam"}>Development Team</option>
          </select>
        </div>

        <button className="btn mt-3 filter-form-button">
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: "20px" }}
            />
          )}
          {loading || "Apply"}
        </button>
      </form>
      <style jsx="true">{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 100%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
      `}</style>
    </>
  );
}
