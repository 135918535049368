/*
Author: Umer Shabir 
Date: April-2022
File: admin users list,
*/
// dependencies
import React, { useMemo, useEffect, useState, useRef } from "react";
import Table from "../../../../Components/Table";
import axios from "axios";
import { API_CONSTANTS } from "../../../../API_CONSTANTS";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import AdminFilterForm from "./AdminFilterForm";
import AlertMessage from "../../../../Components/AlertMessage/index";
// admin users table list component.
export default function AdminUsersList() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [adminList, setAdminList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  const [page, setPage] = useState(0);
  const filter_Status = useSelector(
    (state) => state.adminFilteredReducer.value
  );
  const isMounted = useRef(false);
  // pagination call back
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setAdminList(adminList.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(adminList.length / pageSize));
      }
    },
    [adminList, lastEvaluatedKey]
  );
  // API Hatching data of all admins
  const userList = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL}admin`,
        {
          adminRole: `${filter_Status}`,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
        setAdminList(res.data.response.Items);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.localStorage.clear();
          window.location.reload();
        }
        setIsLoading(true);
        setAdminList([]);
      });
  };

  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL}admin?last=${lastEvaluatedKey}`,
        { adminRole: `${filter_Status}` },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var adminListNextPage = response.data.response.Items;
        setAdminList([...adminList, ...adminListNextPage]);
        setPage(page + 1);
      })
      .then(() => setLoading(true));
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (property) =>
          property.adminName.charAt(0).toUpperCase() +
          property.adminName.slice(1),
      },
      {
        Header: "Email",
        accessor: (property) => property.email.toLowerCase(),
      },
      // {
      //   Header: 'Password',
      //   accessor: '',
      // },
      {
        Header: "Department",
        accessor: "adminRole",
      },
      // {
      //   Header: 'Permissions',
      //   accessor: '',
      // },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  // rendering API
  useEffect(() => {
    !isMounted.current && userList();
  }, [filter_Status]);
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 401"
            ? is_SessionExpire()
            : error.message === "Request failed with status code 404"
              ? "record not found"
              : error.message
        }
      />
    );
  } else if (!isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" style={{ color: "#24AAA4" }} />
      </div>
    );
  } else {
    return (
      <div className="row rounded bg-white m-4 overflow-auto">
        <div className="p-0">
          <h3 style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}>
            All Admin
          </h3>
        </div>
        <Table
          pageNumber={page}
          columns={columns}
          data={adminList}
          routingLink={"/admins/profile"}
          pageCount={pageCount}
          fetchData={fetchData}
          loadMoreList={handleLoadMoreData}
          lastEvaluatedKey={lastEvaluatedKey}
          loading={loading}
          setLoading={setLoading}
          FilterForm={<AdminFilterForm />}
        />
      </div>
    );
  }
}
