/*
Author: Umer Shabir 
Date: april-2022
File: Filter form redux reducer 
*/
// dependencies
import { createSlice } from '@reduxjs/toolkit'
// admin filter Slice.
export const adminFilterSlice = createSlice({
  name: 'adminFilter',
  initialState: {
    value: '',
  },
  reducers: {
    all: (state) => {
      state.value = ''
    },
    Admin: (state) => {
      state.value = 'Admin'
    },
    DoctorAdmin: (state) => {
      state.value = 'DoctorAdmin'
    },
    Consultant: (state) => {
      state.value = 'Consultant'
    },
    HealthCare: (state) => {
      state.value = 'HealthCare'
    },
    CustomerSupport: (state) => {
      state.value = 'CustomerSupport'
    },
    AccountsDept: (state) => {
      state.value = 'AccountsDept'
    },
    DevTeam: (state) => {
      state.value = 'DevTeam'
    },
  },
})
//exporting the variables.
export const { all, Admin, Consultant } = adminFilterSlice.actions
export default adminFilterSlice.reducer
