/*
author: umer shabir.
file: doctor availability module: It will set time slots for doctor avaialabilty schedule.
date: 16-08-2022.
*/
// dependencies
import React from 'react'
// Add time slot object
export default function AddTimeSlot({ addTimeSlot, itemIndex, activeForm }) {
  const handleClick = () => {
    addTimeSlot(
      {
        startTime: '',
        endTime: '',
      },
      itemIndex
    )
    activeForm({
      form: false,
      button: false,
    })
  }
  return (
    <button
      className='custom-button btn  d-flex justify-content-end align-items-center'
      type='button'
      onClick={handleClick}
      style={{ color: '#009B94', columnGap: '10px' }}
    >
      <i className='fas fa-plus'></i> Add Time Slot
    </button>
  )
}
//
