import React, { useEffect } from 'react'
import '../Style/App.css'
import { Routes, Route, Navigate } from 'react-router'
import Dashboard from '../Components/Dashboard/index'
import Login from '../Components/Login'
import Pdf from '../Pages/consultation/Components/Epricription/Pdf'
import { useSelector, useDispatch } from 'react-redux'
export default function App() {
  const authenticationToken = useSelector(
    (state) => state.adminLoginReducer.adminIdToken
  )
  const dispatch = useDispatch()
// inactive on tab then LogOut Function 
  const handleInactiveLogout = () => {
    const inactivityTimeout = 5 * 60 * 60 * 1000
    let timeoutId

    const resetTimer = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        dispatch({ type: 'adminLoginSlice/accessDenyied'})
      }, inactivityTimeout)
    }
    document.addEventListener('mousemove', resetTimer)
    document.addEventListener('keydown', resetTimer)
    document.addEventListener('focus', resetTimer);
    resetTimer()
  }

  useEffect(() => {
    handleInactiveLogout()
  }, [authenticationToken])

  const renderRoutes = () => {
    if (authenticationToken) {
      return (
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route path='*' element={<Dashboard />} />
        </Routes>
      )
    } else {
      return (
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='*' element={<Navigate to='/login' />} />
          <Route path='/eprescription-pdf' element={<Pdf />} />
        </Routes>
      )
    }
  }

  return <>{renderRoutes()}</>
}
