import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_CONSTANTS } from "../../API_CONSTANTS";
import axios from "axios";
export const getNotifications = createAsyncThunk(
  "getNotifications",
  async ({token}) => {
    const Id = localStorage.getItem("editorId")
    if (!token) {
      const response = await axios
        .post(`${API_CONSTANTS.BASEURL_1}admin/users/notificaiton/${Id}`,{},
        {
          headers: API_CONSTANTS.headers(),
        })
        .then((res) => {
          return res.data.response
        })  
        .catch((error) => {
          console.log(error.response.data)
          if (
            error.response.data.response.message ===
            "Signing In will Log Out your account from another device"
          ) {
          } else if (
            error.response.data.message === "The incoming token has expired"
          ) {
          } else {
            console.log(error.response.data)
            alert(error.message);
          }
        });
      return response;
    } else if (token) {
      const response = await axios
        .post(`${API_CONSTANTS.BASEURL_1}admin/users/notificaiton/${Id}?last=${token}`,{}, {
          headers: API_CONSTANTS.headers(),
        })
        .then((res) => {
          return res.data.response
        })
        .catch((error) => {
          console.log(error.response.data)
          if (
            error.response.data.response.message ===
            "Signing In will Log Out your account from another device"
          ) {
          } else if (
            error.response.data.message === "The incoming token has expired"
          ) {
          } else {
            console.log(error.response.data)
            alert(error.message)
          }
        });
      return response
    }
  }
)
const notificationsSlicer = createSlice({
  name: "notificationsSlicer",
  initialState: {
    value: [],
    lastToken: null, 
  },
  reducers: {},
  extraReducers: {
    [getNotifications.pending]: (state) => {
      state.loading = true;
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.value = [...state.value, ...payload.Items]
      state.lastToken = payload.LastEvaluatedKey
    },
    [getNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
      state.value = payload
    },
  },
});
export const notificationReducer = notificationsSlicer.reducer;
