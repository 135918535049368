/*
author: Umer Shabir 
date: 15-june-2022
title: state of dashboard API 
*/
// dependencies
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_CONSTANTS } from '../../API_CONSTANTS'
// thunk action
export const getDashboardData = createAsyncThunk(
  'getDashboardData',
  async () => {
    const response = await axios
      .get(`${API_CONSTANTS.BASEURL_1}admin/getdashboard`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => res.data.response)
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
      })
    return response
  }
)
// redux Slice function and all the initial states.
export const getDashboardDataSlice = createSlice({
  name: 'getDashboardData',
  initialState: {
    value: {
      consulations: [
        {
          appointment_status: '',
          appointmentId: '',
          patientInfo: {
            name: '',
          },
          doctorName: '',
          userId: '',
          appoint_date: '',
          doctorId: '',
        },
      ],
      pending_doctors: [
        {
          Fname: '',
          specialization: '',
          Id: '',
          url: '',
        },
      ],
      top_doctors: [
        {
          Fname: '',
          specialization: '',
          Id: '',
          url: '',
        },
      ],
      low_doctors: [
        {
          Fname: '',
          specialization: '',
          Id: '',
          url: '',
        },
      ],
      admins: '',
      patients: '',
      doctors: '',
    },
  },
  reducers: {},
  extraReducers: {
    [getDashboardData.pending]: (state) => {
      state.loading = true
    },
    [getDashboardData.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.value = payload
    },
    [getDashboardData.rejected]: (state) => {
      state.loading = false
    },
  },
})
// cashflow thunk  API
export const getDashboardNumbers = createAsyncThunk(
  'getDashboardNumbers',
  async (filterValue) => {
    const response = await axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/cashFlow`,
        {
          week: filterValue.week,
          month: filterValue.month,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => res.data.response)
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
      })
    return response
  }
)
// dashboard cash flow slice and api states
export const getDashboardNumbersSlice = createSlice({
  name: 'getDashboardNumbers',
  initialState: {
    valueState: {},
  },
  reducers: {},
  extraReducers: {
    [getDashboardNumbers.pending]: (state) => {
      state.loading = true
    },
    [getDashboardNumbers.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.valueState = payload
    },
    [getDashboardNumbers.rejected]: (state) => {
      state.loading = false
    },
  },
})
// exporting the Reducer function for Redux store.
export const getDashboardDataReducer = getDashboardDataSlice.reducer
export const getDashboardNumbersReducer = getDashboardNumbersSlice.reducer
