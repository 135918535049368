/* eslint-disable no-unused-vars */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Table from '../../../../Components/Table';
import axios from 'axios';
import { API_CONSTANTS } from '../../../../API_CONSTANTS';

export default function LabPartners() {
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
    const [labList, setLabList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);
    const fetchData = useCallback(
        ({ pageSize, pageIndex }) => {
            const fetchId = lastEvaluatedKey;
            if (fetchId === fetchIdRef.current) {
                const startRow = pageSize * pageIndex;
                const endRow = startRow + pageSize;
                setLabList(labList.slice(startRow, endRow));
                setPageCount(Math.ceil(labList.length / pageSize));
            }
        },
        [labList, lastEvaluatedKey]
    );
    // all labs data API 
    const lab = () => {
        axios
            .get(`${API_CONSTANTS.LABURL}admin/labs`, {
                headers: API_CONSTANTS.headers(),
            })
            .then((res) => {
                setIsLoading(true);
                setLabList(res?.data?.response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.reload();
                    window.localStorage.clear();
                }
                setIsLoading(true);
                setLabList([]);
            });
    };
    const columns = useMemo(
        () => [
            {
                Header: "No.",
                accessor: (row, index) => index + 1,
            },
            {
                Header: "Lab Name",
                accessor: "name",
            },
            {
                Header: "Discount",
                accessor: row => `${row.discount}%`,
            },
            {
                Header: "No. of Tests",
                accessor: "noOfTests",
            },
            {
                Header: "Action",
                accessor: "key",
                Cell: ({ row }) => (
                    <Link
                        to={`/lab/partners/${row.original.key}`}
                        style={{ borderBottom: "1px solid #5C5C5C" }}
                    >
                        View File
                    </Link>
                )
            },
        ],
        []
    );
    useEffect(() => {
        lab();
    }, []);
    return (
        <>
            <div className="row rounded bg-white m-4">
                <div className="d-flex align-items-start">
                    <h3
                        style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            padding: "18px",
                        }}
                    >
                        All Lab Partners
                    </h3>
                    {!isLoading && (
                        <div className="d-flex justify-content-center  ">
                            <Spinner
                                animation="border"
                                size="sm"
                                style={{ color: "#24AAA4", marginTop: "20px" }}
                            />
                        </div>
                    )}
                </div>
                <Table
                    columns={columns}
                    data={labList}
                    routingLink={""}
                    pageCount={pageCount}
                    fetchData={fetchData}
                    // loadMoreList={handleLoadMoreData}
                    lastEvaluatedKey={lastEvaluatedKey}
                    loading={loading}
                    setLoading={setLoading}
                    // FilterForm={<LabFilterForm lab={lab} />}
                    recordDownload={true}
                // onDownloadBtn={onDownloadBtn}
                />
            </div>
            <style jsx="true">{`
          .add-record {
            background: #009b94 !important;
            color: #fff;
          }
          .btn:focus {
            box-shadow: none !important;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .patient-search-input {
            border-radius: 0;
          }
        `}</style>
        </>
    )
}
