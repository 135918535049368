import { createSlice } from "@reduxjs/toolkit";

export const TransactionFilterSlice = createSlice({
  name: "TransactionFilter",
  initialState: {
    value: "",
    value1: "",
    value2: [],
  },
  reducers: {
    appoint_date_from: (state, action) => {
      const { payload } = action;
      state.value1 = payload;
    },
    date: (state, action) => {
      const { payload } = action;
      state.value = payload;
    },
    paid_through: (state, action) => {
      state.value2 = action.payload;
    },
    DebitCard: (state, action) => {
      state.value2 = state.value2.concat("DebitCard");
    },
    wallet: (state, action) => {
      state.value2 = state.value2.concat("wallet");
    },
    JazzCash: (state, action) => {
      state.value2 = state.value2.concat("JazzCash");
    },
    EasyPaisa: (state, action) => {
      state.value2 = state.value2.concat("EasyPaisa");
    },
    CorporatePayment: (state, action) => {
      state.value2 = state.value2.concat("CorporatePayment");
    },
    SubscriptionPayment: (state, action) => {
      state.value2 = state.value2.concat("SubscriptionPayment");
    },
    referralInvite: (state, action) => {
      state.value2 = state.value2.concat("referralInvite");
    },
    payFast: (state, action) => {
      state.value2 = state.value2.concat("payFast");
    },
    removeValueFromValue2: (state, action) => {
      const { payload } = action;
      state.value2 = state.value2.filter((item) => item !== payload);
    },
  },
});

export const { date, appoint_date_from, removeValueFromValue2 } =
  TransactionFilterSlice.actions;

export const TransactionFilterReducer = TransactionFilterSlice.reducer;
