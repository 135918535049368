/*
author: Umer Shabir 
date: 07-March-2022
title: this is a generic button for adding new user.
*/
// dependencies
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
// Add new user button component
export default function AddNewUserButton({ link, buttonText }) {
  return (
    <>
      <div className='d-flex justify-content-end  mt-3 pe-4'>
        <Link to={link} className={'btn add-user-btn'}>
          <FontAwesomeIcon icon={faPlus} className={'me-2'} /> {buttonText}
        </Link>
      </div>
      <style jsx='true'>{`
        .add-user-btn {
          background: #009b94;
          color: #fff;
        }
        .add-user-btn:hover {
          background: #fff;
          color: #009b94;
        }
      `}</style>
    </>
  )
}
