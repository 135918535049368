import React, { useState } from 'react';
import CallRecordTable from './CallRecordTable';

export default function RecordingHistory() {
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const handlePasswordChange = (event) => {
    const enteredPassword = event.target.value;
    setPassword(enteredPassword);
    setIsDisabled(enteredPassword !== 'eshaafirec@755');
  };

  return (
    <>
      <div className='container-fluid p-4'>
        <div className='row'>
          <div className='col-12 col-xxl-9'>
            <div className='password-input-container'>
              <input
                type='password'
                value={password}
                onChange={handlePasswordChange}
                placeholder='Enter password'
                disabled={!isDisabled}
                className={isDisabled ? 'disabled' : ''}
              />
              {isDisabled && <p className='error-message'>Please enter the correct password to view the records.</p>}
            </div>
            {!isDisabled && <CallRecordTable />}
          </div>
        </div>
      </div>
      <style jsx>{`
        .password-input-container {
          position: relative;
        }
        
        input {
          font-size: 16px;
          padding: 10px 15px;
          border: 1px solid #ccc;
          border-radius: 4px;
          width: 100%;
        }
        
        input.disabled {
          background-color: #f3f3f3;
        }
        
        .error-message {
          color: red;
          font-size: 14px;
          margin-top: 5px;
        }
        
        table {
          font-size: 14px;
          width: 100%;
          border-collapse: collapse;
        }
        
        th {
          font-weight: 500;
          padding: 15px;
          border-bottom: 1px solid #ccc;
        }
        
        td {
          text-align: right;
          padding: 15px;
          border-bottom: 1px solid #ccc;
        }
      `}</style>
    </>
  );
}
