import { createSlice } from '@reduxjs/toolkit'
// delayed form filter slice
export const delayedFilterSlice = createSlice({
  name: 'delayedFilter',
  initialState: {
    value1: '',
    value2: '',
  },
  reducers: {
    // second filter value
    from: (state, action) => {
      const { payload } = action
      state.value1 = payload
    },
    // 3rd filter value
    to: (state, action) => {
      const { payload } = action
      state.value2 = payload
    },
  },
})

export const delayedFilterReducer = delayedFilterSlice.reducer
