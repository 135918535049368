/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useReducer, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import SeeMore from './SeeMoreModal'
import Spinner from 'react-bootstrap/Spinner'
import { API_CONSTANTS } from '../../../API_CONSTANTS'
import { Link } from 'react-router-dom';
//
const notificationsReducer = (state, action) => {
  switch (action.type) {
    case 'NOTIFICATION_FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case 'NOTIFICATION_FETCH_INIT_MORE_TRUE': {
      return {
        ...state,
        moreLoading: true,
      }
    }
    case 'NOTIFICATION_FETCH_INIT_MORE_FALSE': {
      return {
        ...state,
        moreLoading: false,
      }
    }
    case 'NOTIFICATION_FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload.list,
        token: action.payload.key,
      }
    case 'NOTIFICATION_FETCH_MORE':
      return {
        ...state,
        moreLoading: false,
        isError: false,
        data: state.data.concat(action.payload.list),
        token: action.payload.key,
      }
    case 'NOTIFICATION_FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorData: action.payload,
      }
    default:
      throw new Error()
  }
}
//
function PatientfeedBack() {
  const [showMore] = useState(false)
  const [notifications, dispatchNotifications] = useReducer(
    notificationsReducer,
    {
      data: [],
      isLoading: false,
      token: '',
      isError: false,
      errorData: {},
      moreLoading: false,
      key: '',
    }
  )
  // variables
  const { data, isLoading, token, moreLoading } =
    notifications
  // load more notifications
  function LoadMore() {
    dispatchNotifications({ type: 'NOTIFICATION_FETCH_INIT_MORE_TRUE' })
    axios
      .get(`${API_CONSTANTS.BASEURL_1}admin/users/feedbacks?last=${token}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((result) =>
        dispatchNotifications({
          type: 'NOTIFICATION_FETCH_MORE',
          payload: {
            list: result.data.response.Items,
            key: result.data.response?.LastEvaluatedKey,
          },
        })
      )
      .then(() =>
        dispatchNotifications({
          type: 'NOTIFICATION_FETCH_INIT_MORE_FALSE',
        })
      )
      .catch((error) =>
        dispatchNotifications({
          type: 'NOTIFICATION_FETCH_FAILURE',
          payload: error.response.data.response.message,
        })
      )
  }
  // fetching notification
  const handleFetchNotifications = () => {
    dispatchNotifications({
      type: 'NOTIFICATION_FETCH_INIT',
    })
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/users/feedbacks`,

        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        dispatchNotifications({
          type: 'NOTIFICATION_FETCH_SUCCESS',
          payload: {
            list: res.data.response.Items,
            key: res.data.response.LastEvaluatedKey,
          },
        })
      })
      .catch((error) => {
        dispatchNotifications({
          type: 'NOTIFICATION_FETCH_FAILURE',
          payload: error.response.data.response.message,
        })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
      })
  }
  // useEffect
  useEffect(() => {
    handleFetchNotifications()
  }, [])

  return (
    <section className='p-2' style={{ backgroundColor: '#F6F6F6' }}>
      <div className={'m-4 card feedback-back'}>
        {isLoading && (
          <div className='d-flex justify-content-center mt-3'>
            <Spinner animation='border' style={{ color: '#24AAA4' }} />
          </div>
        )}
        <div className={' d-flex m-3 '}>
          <span className={'feedback'}>Feedback</span>

        </div>
        {data.map((item, index) => (
          <div
            className={'col-11 card mb-3 mx-3 allcard'}
            key={index}
            style={{
              borderLeft: item.read ? '8px solid #99D7D4' : '8px solid #009b94',
              background: item.read ? '#FFFFFF' : 'rgba(0, 155, 148, 0.08)',
              borderRadius: '8px',
              width: '80%',
            }}
          >
            <span className={'justify-content-between d-flex mt-3 m-0 mx-4'}>
              <Link to={`/patients/profile/${item?.userId}/overview`} className='patient-link'>
                <span className={''}>
                  <span>
                    <img
                      src={item.profile.url ? item.profile.url :
                        'https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black-thumbnail.png'}
                      width={'25px'}
                      height={'25px'}
                      style={{ borderRadius: '50%' }}
                    />
                  </span>
                  <span className={'mx-2 namefeedback'}>{item.Fname}</span>
                </span>
              </Link>
              <span className={'namefeedback'}>
                {moment(item.Sk).startOf('minute').fromNow()}
              </span>
            </span>
            <div className={'col d-flex justify-content-between m-2'}>
              <p className={'text-feedback'}
                style={{ width: '75%' }}>
                {showMore
                  ? item.feedback
                  : `${item.feedback.substring(0, 150)}  . . .`}
                { }
                { }{' '}
              </p>
              <div className='d-flex justify-content-end align-items-end p-2'
                style={{ width: '25%' }} >
                <SeeMore feedback={item} reset={handleFetchNotifications} />
              </div>
            </div>
          </div>
        ))}
        <div className={'justify-content-end d-flex col-11 m-2'}>
          <button
            type='button'
            className='btn d-flex btn-dark align-items-center mb-2 '
            style={{ background: '#009B94', border: '#009B94' }}
            onClick={LoadMore}
            disabled={!token}
          >
            Load more
            {moreLoading && (
              <div
                className='spinner-border spinner-border-sm ms-1'
                role='status'
              />
            )}
          </button>
        </div>
      </div>

      <style jsx='true'>{`
        .namefeedback {
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          letter-spacing: -0.078px;
          text-transform: uppercase;
          color: #5c5c5c;
        }
        .text-feedback {
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #5c5c5c;
          margin-left: 15px;
        }

        .feedback-back {
          background: #F6F6F6;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
        }
        .feedback {
          font-family: Roboto;
          font-style: medium;
          font-weight: 500;
          font-size: 20px;
          line-height: 19px;
          color: #000000;
        }
        .patient-link:hover {
          background-color: #c8efe1 !important;
        }
        @media (max-width: 800px){
          .feedback-back{
            width: 100% !important
          }
        }
      `}</style>
    </section>
  )
}

export default PatientfeedBack
