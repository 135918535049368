/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState } from "react";
import Table from "../../../Components/Table/index";
import axios from "axios";
import { API_CONSTANTS } from "../../../API_CONSTANTS";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { useSelector } from "react-redux";
import style from "../../../Style/corporate.module.css";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AlertMessage from "../../../Components/AlertMessage";
// styling
const partnerStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
};
export default function Employees() {
  // variables
  const { value } = useSelector((state) => state.getCorporateProfileReducer);
  const today = moment();
  const [open, setOpen] = useState(false);
  const [addEmployee, setAddEmployee] = useState({
    name: "",
    phone_no: "",
    dob: "",
    location: "",
    gender: "",
    employeeID: "",
  });
  const { name, phone_no, dob, location, gender, employeeID } = addEmployee;
  const params = useParams();
  const [page, setPage] = useState(0);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [employeesList, setEmployeesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addEmployeeLoading, setAddEmployeeLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [patientSearch, setPatientSearch] = useState({
    number: "",
    countryCode: "+92",
  });
  const { number, countryCode } = patientSearch;
  const fetchIdRef = React.useRef(0);
  const phoneRegex = /^03[0-5][0-9]{8}$/;
  const employeIdRegex = /^\d{2,}$/;
  // functions
  const getDataFromFields = (e) => {
    setAddEmployee((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setAddEmployeeLoading(false);
    setAddEmployee({
      name: "",
      dob: "",
      location: "",
      phone_no: "",
      gender: "",
      employeeID: "",
    });
    setOpen(false);
  };

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setEmployeesList(employeesList.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(employeesList.length / pageSize));
      }
    },
    [employeesList, lastEvaluatedKey]
  );
  //
  // API Hatching data of all admins
  const userList = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_4}admin/corporates/${params.corporate}/employees`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
        setEmployeesList(res.data.response.Items);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setIsLoading(true);
        setEmployeesList([]);
        // setError(error)
      });
  };
  //
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_4}admin/corporates/${params.corporate}/employees?last=${lastEvaluatedKey}`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var patientListNextPage = response.data.response.Items;
        setEmployeesList([...employeesList, ...patientListNextPage]);
        setPage(page + 1);
      })
      .then(() => setLoading(true));
  };
  const handleSearchInput = (event, state) => {
    if (event.reset) {
      return { number: "", countryCode: "+92" };
    }
    return setPatientSearch({
      ...state,
      number: event.target.value.slice(0, event.target.maxLength),
      countryCode: "+92",
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && patientSearch.number[0] === '3' && patientSearch.number.length === 10) {
      handlePatientSearch();
    }
  };
  const handlePatientSearch = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/patientSearch/${patientSearch.countryCode}${patientSearch.number}?corporateId=${params.corporate}`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => setEmployeesList(res.data.response.Items))
      .then(() => setPatientSearch({ countryCode: "+92", number: "" }))
      .catch((error) => alert(error.response.data.response.message));
  };

  // handle add new partner
  const handleAddPartner = (e) => {
    setAddEmployeeLoading(true);
    axios
      .post(
        API_CONSTANTS.BASEURL_4 + `/admin/corporates/employees/${params.id}`,
        {
          name,
          phone_no: "+92" + phone_no.slice(1),
          dob,
          location,
          gender,
          employeeID,
          type: 1,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setAddEmployeeLoading(false);
        toast(res.data.response.message, {
          type: "success",
        });
        setAddEmployee({
          name: "",
          dob: "",
          location: "",
          phone_no: "",
          gender: "",
          employeeID: "",
        });
      })
      .catch((err) => {
        setAddEmployeeLoading(false);
        toast(err.response.data.response.message, {
          type: "error",
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Name",
        accessor: (property) =>
          property.Fname
            ? property.Fname.charAt(0).toUpperCase() + property.Fname.slice(1)
            : "UnNamed",
      },
      {
        Header: "Phone Number",
        accessor: (property) => (property.phoneNo ? property.phoneNo : "N/A"),
      },

      {
        Header: "Age",
        accessor: (property) => {
          if (property.dob) {
            const birthdate = moment(property.dob, "YYYY-MM-DD");
            return `${today.diff(birthdate, "years")} Y`;
          } else return "-";
        },
      },
      {
        Header: "Gender",
        accessor: (item) => (item.gender ? item.gender : "-"),
      },
      {
        Header: "Location",
        accessor: (item) => (item.location ? item.location : "-"),
      },
      {
        Header: "DOB",
        accessor: (item) =>
          item.dob ? moment(item.dob, "YYYY-MM-DD").format("D-MM-YYYY") : "-",
      },
      {
        Header: "Calls Available",
        accessor: (item) => item?.corporateInfo?.totalCalls ? item?.corporateInfo?.totalCalls : "-",
      },
      {
        Header: "Calls Availed",
        accessor: (item) => item?.corporateInfo?.totalCalls - item?.corporateInfo?.remainingCalls ? item?.corporateInfo?.totalCalls - item?.corporateInfo?.remainingCalls : "0"
      },
      {
        Header: " ",
        accessor: (property) => (
          <Link
            to={`/patients/profile/${property.Id}/overview/`}
            className={style.viewPartnerLink}
          >
            View
          </Link>
        ),
      },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  // rendering API
  useEffect(() => {
    if (employeesList.length < 1) {
      userList();
    }
  }, []);
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 404"
            ? "This record does not exist"
            : error.message === "Request failed with status code 401"
              ? is_SessionExpire()
              : error.message
        }
      />
    );
  } else {
    return (
      <main>
        <section className={style.corporatePageSectionInner}>
          {/* add new corporate  */}
          <div className="d-flex justify-content-between">
            <div className="input-group mt-3 pe-4 ms-4 d-flex" style={{ width: "50%" }}>
              <div className="form-outline d-flex">
                <select
                  name="countryCode"
                  style={{
                    borderRadius: "7px 0 0 7px",
                    borderRight: "3px solid #009B94",
                    background: "#009B9414",
                    color: "#4E4E4E99",
                  }}
                  defaultValue={countryCode}
                >
                  <option selected onChange={handleSearchInput}>
                    +92
                  </option>
                </select>
                <input
                  type="number"
                  id="form1"
                  className="form-control patient-search-input"
                  placeholder="Search"
                  value={number}
                  name="number"
                  onChange={handleSearchInput}
                  onKeyPress={handleKeyPress}
                  maxLength="10"
                />
              </div>
              <button
                type="button"
                className="btn"
                onClick={handlePatientSearch}
                disabled={
                  patientSearch.number.slice(0, 1) === "3" &&
                    patientSearch.number.length === 10
                    ? false
                    : true
                }
                style={{ backgroundColor: "rgb(0, 155, 148)", color: "#fff" }}
              >
                <i className="fas fa-search"></i>
              </button>
            </div>
            <Button
              variant="contained"
              className={style.addPartnerBtn}
              onClick={handleOpen}
            >
              <AddIcon />
              Add New Employee
            </Button>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={partnerStyles}>
              <div className={style.addPartnerModalContainer}>
                <Button
                  className={style.closeBtnPartnerModal}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Button>
                <div className={style.addPartnerModal}>
                  <div>
                    <TextField
                      id="standard-basic"
                      label="Phone No"
                      variant="standard"
                      name="phone_no"
                      onChange={getDataFromFields}
                      required
                      type="tel"
                      value={phone_no}
                    />
                    {!phone_no && (
                      <p
                        style={{
                          fontSize: 11,
                          marginBottom: 0,
                        }}
                      >
                        Phone # format 03xxxxxxxxx
                      </p>
                    )}
                    {phone_no && (
                      <p
                        style={{
                          color: phoneRegex.test(phone_no) ? "green" : "red",
                          fontSize: 12,
                          marginBottom: 0,
                        }}
                      >
                        {phoneRegex.test(phone_no)
                          ? ""
                          : `Must follow the format 03xxxxxxxxx`}
                      </p>
                    )}
                  </div>

                  <div>
                    <TextField
                      id="standard-basic"
                      label="EmployeeID"
                      variant="standard"
                      name="employeeID"
                      onChange={getDataFromFields}
                      required
                      value={employeeID}
                    />
                    {!employeeID && (
                      <p
                        style={{
                          fontSize: 11,
                          marginBottom: 0,
                        }}
                      >
                        Employee ID must be greater than 2 digit
                      </p>
                    )}
                    {employeeID && (
                      <p
                        style={{
                          color: employeIdRegex.test(employeeID)
                            ? "green"
                            : "red",
                          fontSize: 12,
                          marginBottom: 0,
                        }}
                      >
                        {employeIdRegex.test(employeeID)
                          ? ""
                          : `Employee ID must be greater than 6 digit`}
                      </p>
                    )}
                  </div>
                  <TextField
                    id="standard-basic"
                    label="Name"
                    variant="standard"
                    name="name"
                    onChange={getDataFromFields}
                    required
                    value={name}
                  />
                  {/* <TextField type="date" max="2023-12-26">
                    <input type="date" max="2023-12-26" />
                  </TextField> */}
                  <TextField
                    id="dateInput"
                    label="Age"
                    variant="standard"
                    name="dob"
                    onChange={getDataFromFields}
                    required
                    value={dob}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: moment().format("YYYY-MM-DD"),
                    }}
                  />
                  {/* <TextField
                    id="standard-basic"
                    label="Age"
                    variant="standard"
                    name="dob"
                    onChange={getDataFromFields}
                    required
                    value={dob}
                    type="date"
                    max={"2023-12-26"}
                  /> */}
                  <TextField
                    id="standard-basic"
                    label="Location"
                    name="location"
                    variant="standard"
                    onChange={getDataFromFields}
                    required
                    type="text"
                    value={location}
                  />
                  <div>
                    <FormControl variant="standard">
                      <InputLabel id="demo-simple-select-standard-label">
                        Gender
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={gender}
                        label="Gender"
                        name="gender"
                        onChange={getDataFromFields}
                      >
                        <MenuItem value="" selected disabled>
                          --Select--
                          {/* <em>None</em> */}
                        </MenuItem>
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={style.actionBtnModal + " mt-4 ps-3"}>
                  <LoadingButton
                    className={style.actionBtn}
                    variant="contained"
                    loading={addEmployeeLoading}
                    // loadingPosition="end"
                    onClick={handleAddPartner}
                    disabled={
                      name && phone_no && location && gender && dob
                        ? false
                        : true
                    }
                  >
                    Add Employee
                  </LoadingButton>
                  <Button
                    onClick={handleClose}
                    color="error"
                    className={style.actionBtn}
                  >
                    Discard
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
          {/* corporate table listing  */}
          <div className="row rounded bg-white m-4">
            <h3
              style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}
            >
              {value.companyName} Employees
              {!isLoading && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "#24AAA4" }}
                />
              )}
            </h3>
            <Table
              pageNumber={page}
              columns={columns}
              data={employeesList}
              routingLink={``}
              pageCount={pageCount}
              fetchData={fetchData}
              loadMoreList={handleLoadMoreData}
              lastEvaluatedKey={lastEvaluatedKey}
              loading={loading}
              recordDownload={true}
              setLoading={setLoading}
            />
          </div>
        </section>
        <ToastContainer />
        <style jsx="true">
          {`
            .patient {
              display: flex;
              justify-content: space-between;
            }
            .patient-search-input {
              border-radius: 0;
            }
            .form-control:focus {
              background-color: #fff;
              border-color: rgb(0, 155, 148) !important;
              box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 25%) !important;
            }
            .btn:focus {
              box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 25%) !important;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          `}
        </style>
      </main>
    );
  }
}
