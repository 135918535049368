import { createSlice } from '@reduxjs/toolkit'
//

export const nurseFilterSlice = createSlice({
  name: 'nurseFilter',
  initialState: {
    value: 'All',
    value1: 'All',
    value2: 'All',
    value3: 'All',
  },
  reducers: {
    all: (state, action) => {
      state.value = 'All'
    },
    available: (state, action) => {
      state.value = 'available'
    },
    unavailable: (state, action) => {
      state.value = 'unavailable'
    },
    // second filter value
    allgender: (state, action) => {
      state.value1 = 'All'
    },
    Male: (state, action) => {
      state.value1 = 'Male'
    },
    Female: (state, action) => {
      state.value1 = 'Female'
    },
    Other: (state, action) => {
      state.value1 = 'Other'
    },

    //3rd filter value
    // Age: (state, action) => {
    //   state.value2 = ''
    // },
    // Age1: (state, action) => {
    //   state.value2 = '20-30'
    // },
    // Age2: (state, action) => {
    //   state.value2 = '30-40'
    // },
    // Age3: (state, action) => {
    //   state.value2 = '40 +'
    // },
    // 4th filter value
    created_at: (state, action) => {
      const { payload } = action
      state.value2 = payload
    },
    appoint_date_from: (state, action) => {
      const { payload } = action
      state.value3 = payload
    },
  },
})
export const { all, available, unavailable, created_at, appoint_date_from } =
  nurseFilterSlice.actions
export const nurseFilterReducer = nurseFilterSlice.reducer
