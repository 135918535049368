import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from "react-bootstrap/Spinner";

// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case 'LOADING_TRUE': {
      return {
        ...state,
        loading: true,
      }
    }
    case 'LOADING_FALSE': {
      return {
        ...state,
        loading: false
      }
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      }
    }
  }

}

//Doctor related filter search form
export default function AppointmentFilterForm() {
  const filter_status = {
    created_at: useSelector((state) => state.appointmentFilterReducer.value3),
    appointment_status: useSelector(
      (state) => state.appointmentFilterReducer.value
    ),
    appoint_date_from: useSelector(
      (state) => state.appointmentFilterReducer.value1
    ),
    appoint_date_to: useSelector(
      (state) => state.appointmentFilterReducer.value2
    ),
  }
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    created_at: filter_status.created_at,
    appointment_status: filter_status.appointment_status,
    appoint_date_to: filter_status.appoint_date_to,
    appoint_date_from: filter_status.appoint_date_from,
    appoint_type: filter_status.appoint_type,
    loading: false,
  })
  //
  const dispatch = useDispatch()
  //
  const {
    created_at,
    appointment_status,
    appoint_date_to,
    appoint_type,
    appoint_date_from,
    loading } = formData

  const handleSubmit = (event) => {
    event.preventDefault()
    setFormDataDispatch({ type: 'LOADING_TRUE' })
    if (appointment_status !== undefined) {
      dispatch({
        type: 'appointmentFilter/' + appointment_status,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }

    if (appoint_date_to !== undefined) {
      dispatch({
        type: 'appointmentFilter/appointmentDateFrom',
        payload: appoint_date_from,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }

    if (appoint_date_to !== undefined) {
      dispatch({
        type: 'appointmentFilter/appointmentDateTo',
        payload: appoint_date_to,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }

    if (created_at !== undefined) {
      dispatch({
        type: 'appointmentFilter/created_at',
        payload: created_at,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
    if (appoint_type !== undefined) {
      dispatch({
        type: 'appointmentFilter/' + appoint_type,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }

  }

  return (
    <>
      <form onSubmit={handleSubmit} className='mt-4 p-2'>
        {/* <div
          class='form-group mt-3
        '
        >
          <label for='exampleFormControlSelect1' style={{ fontWeight: 400 }}>
            Consultation Type
          </label>
          <select
            class='form-control'
            id='exampleFormControlSelect1'
            onChange={setFormDataDispatch}
            name='appoint_type'
            value={appoint_type}
          >
            <option name='all' value='videoCall'>
              All
            </option>
            <option value='Booked'>Book Appointment video</option>
            <option value='Instant'>Instant Consultation Video</option>
            <option value='FollowUp'>Follow Up's Consultations</option>
          </select>
        </div> */}
        <div class='form-group mt-3'>
          <label for='exampleFormControlSelect1' style={{ fontWeight: 400 }}>
            Appointment Status
          </label>
          <select
            className='form-control filter-input'
            id='exampleFormControlSelect1'
            onChange={setFormDataDispatch}
            name='appointment_status'
            value={appointment_status}
          >
            <option value={'all'}>All</option>
            <option value={'booked'}>Booked</option>
            <option value={'completed'}>Completed</option>
            <option value={'testCall'}>Test Call</option>
            <option value={'fakeCall'}>Fake Call</option>
            <option value={'cancelled'}>Cancelled</option>
            <option value={'InProgress'}>InProgress</option>
            <option value={'missed'}>Missed</option>
            <option value={'unattended'}>Unattended</option>
            <option value={'called'}>Called</option>
            <option value={'reschedule'}>Reschedule</option>
          </select>
        </div>
        <div class='form-group mt-3'>
          <label for='exampleFormControlSelect1' style={{ fontWeight: 400 }}>
            Date From
          </label>

          <input
            onChange={setFormDataDispatch}
            name='appoint_date_from'
            type='date'
            class='form-control filter-input'
            id='exampleFormControlSelect1'
            value={appoint_date_from}
          />
        </div>
        <div class='form-group mt-3'>
          <label for='exampleFormControlSelect1' style={{ fontWeight: 400 }}>
            Date To
          </label>

          <input
            onChange={setFormDataDispatch}
            name='appoint_date_to'
            type='date'
            class='form-control filter-input'
            id='exampleFormControlSelect1'
            value={appoint_date_to}
          />
        </div>
        <div class='form-group mt-3'>
          <label for='exampleFormControlSelect1' style={{ fontWeight: 400 }}>
            Booking Date
          </label>

          <input
            onChange={setFormDataDispatch}
            name='created_at'
            type='date'
            class='form-control filter-input'
            id='exampleFormControlSelect1'
            value={created_at}
          />
        </div>
        <button className='btn mt-3 filter-form-button'>


          {loading && <Spinner
            as='span'
            animation='border'
            size='sm'
            role='status'
            aria-hidden='true'
            style={{ marginRight: '20px' }}
          />} {loading || 'Apply'}</button>
      </form>
      <style jsx>{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 100%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
      `}</style>
    </>
  )
}
