import { createSlice } from '@reduxjs/toolkit'
//

export const patientTransactionFilterSlice = createSlice({
  name: 'TransactionFilter',
  initialState: {
    value: '',
    value1: '',
    value2: '',
    // value3: '',
  },
  reducers: {
    all: (state, action) => {
      state.value2 = ''
    },
    date: (state, action) => {
      const { payload } = action
      state.value = payload
    },
    appoint_date_from: (state, action) => {
      const { payload } = action
      state.value3 = payload
    },
    amount: (state, action) => {
      state.value1 = action.payload
    },
    completed: (state, action) => {
      state.value2 = 'completed'
    },
    refunded: (state, action) => {
      state.value2 = 'refunded'
    },
  },
})

export const patientTransactionFilterReducer =
  patientTransactionFilterSlice.reducer
