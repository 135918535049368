/*
Authore: Umer Shabir 
modifiet_Date: 11-10-2022
file: this file about patient and family member medical history record
*/
// dependencies
import React, { useMemo, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import AlertMessage from "../../../../../Components/AlertMessage";
import { useParams } from "react-router";
import Table from "../../../../../Components/Table";
import Spinner from "react-bootstrap/Spinner";
import ImageViewer from "../../../../../Components/ImageViewer";
import FilterForm from "./FilterForm";
import { API_CONSTANTS } from "../../../../../API_CONSTANTS";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// medical history component
export default function MedicalHistory() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [medicalRecord, setmedicalRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const fetchIdRef = React.useRef(0);
  const params = useParams();
  // dispatching to clear the states.
  // dispatch({ type: 'MedicalHistoryFilter/resetState' })
  const filter_status = {
    memberId: useSelector(
      (state) => state.patientMedicalHistoryFilterReducer.value
    ),
    date: useSelector(
      (state) => state.patientMedicalHistoryFilterReducer.value1
    ),
    medicalRecordFrom: useSelector(
      (state) => state.patientMedicalHistoryFilterReducer.value2
    ),
  };
  const handleFilters = () => {
    let filterData = {
      memberId: `${filter_status.memberId ? filter_status.memberId : ""}`,
      date: `${filter_status.date ? filter_status.date : ""}`,
      medicalRecordFrom: `${
        filter_status.medicalRecordFrom ? filter_status.medicalRecordFrom : ""
      }`,
    };
    if (Object.values(filterData).every((key) => key === "")) {
      return {};
    } else
      return Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== "")
      );
  };
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.
      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setmedicalRecord(medicalRecord.slice(startRow, endRow));
        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(medicalRecord.length / pageSize));
      }
    },
    [medicalRecord, lastEvaluatedKey]
  );
  const getMedicalRecord = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL}admin/patients/${params.id}/medicalHistory`,
        {
          headers: API_CONSTANTS.headers(),
          params: handleFilters(),
        }
      )
      .then((res) => {
        setIsLoading(true);
        setLastEvaluatedKey(res.data.response.data.LastEvaluatedKey);
        setmedicalRecord(
          res.data.response.data ? res.data.response.data.Items : []
        );
        console.log(res);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }

        setIsLoading(true);
        setmedicalRecord([]);
        setError(error);
      });
  };
  // filters function

  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL}admin/patients/${params.id}/medicalHistory?last=${lastEvaluatedKey}`,
        {
          headers: API_CONSTANTS.headers(),
          params: handleFilters(),
        }
      )
      .then((res) => {
        setLastEvaluatedKey(res.data.response.data.LastEvaluatedKey);
        var patientListNextPage = res.data.response.data.Items;
        setmedicalRecord([...medicalRecord, ...patientListNextPage]);
        setPage(page + 1);
        console.log(res);
      })
      .then(() => setLoading(true))
      .catch((err) => alert(err));
  };
  const columns = useMemo(
    () => [
      {
        Header: "Record For",
        accessor: (record) =>
          record.shared_record.patient_name
            ? record.shared_record.patient_name
            : "N/A",
      },
      {
        Header: "Doctor’s name",
        accessor: (record) =>
          record.shared_record.doctor_name
            ? record.shared_record.doctor_name
            : "N/A",
      },
      {
        Header: "Record Type",
        accessor: (record) => record.shared_record.record_type,
      },

      {
        Header: "Record Date",
        accessor: (record) =>
          moment(record.shared_record.created_at).format("DD-MM-YYYY"),
      },
      {
        Header: "Attachments",
        accessor: (record) => (
          <ImageViewer
            images={
              record.shared_record.image.length > 0
                ? record.shared_record.image
                : record.shared_record.pdf
            }
            patientName={
              record.shared_record.patient_name
                ? record.shared_record.patient_name
                : ""
            }
            date={record.shared_record.date ? record.shared_record.date : ""}
            text={"View Attachments"}
            fileType={record.shared_record.image.length > 0 ? "imgae" : "pdf"}
          />
        ),
      },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  // //
  // const checkForId = () => {
  //   if (profiles === []) {
  //     dispatch({ type: 'MedicalHistoryFilter/resetState' })
  //   }
  //   // profiles.map((item) => {
  //   //   if (item.profileId !== memberId) {
  //   //     dispatch({ type: 'MedicalHistoryFilter/resetState' })
  //   //     // console.log(item.profileId)
  //   //   }
  //   // })
  //   console.log(profiles)
  // }
  // //

  // checkForId()

  useEffect(() => {
    getMedicalRecord();
  }, [
    filter_status.memberId,
    filter_status.date,
    filter_status.medicalRecordFrom,
  ]);

  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 404"
            ? "This record does not exist"
            : error.message === "Request failed with status code 401"
            ? is_SessionExpire()
            : error.message
        }
      />
    );
  } else if (!isLoading) {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Spinner animation="border" style={{ color: "#24AAA4" }} />
      </div>
    );
  } else {
    return (
      <>
        <div className="row rounded bg-white m-4 ">
          <div className="p-0">
            <h3
              style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}
            >
              All Records
            </h3>
          </div>
          <Table
            pageNumber={page}
            columns={columns}
            data={medicalRecord}
            routingLink={""}
            pageCount={pageCount}
            fetchData={fetchData}
            loadMoreList={handleLoadMoreData}
            lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            setLoading={setLoading}
            FilterForm={<FilterForm />}
          />
        </div>
      </>
    );
  }
}
