import React from 'react'
import PromoList from './PromoList'
import AddPromo from './AddPromo'
//
export default function MainPromoList({UserPlanList}) {
  return (
    <>
      <div className='col-12'>
        <div className='d-flex justify-content-end align-items-center'>
          <span style={{ fontSize: 16, fontWeight: 500 }}></span>
          <AddPromo/>
        </div>
      </div>
      <PromoList />
    </>
  )
}
