import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import styles from './doctorStyle.module.css'
import { addDoctorUser } from './add_doctor_Reducers'
import {
  faList,
  faCheckCircle,
  faTimesCircle,
  faLongArrowAltRight,
} from '@fortawesome/fontawesome-free-solid'

import Toast from '../../../../Components/Toast/Toast'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'

const AddNewDoctorForm = () => {
  const dispatch = useDispatch()
  const [paramId, setParamId] = useState('')

  // ----------------- States declaration for Toast Message ---------
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')

  // ----------------- States declaration classname ---------
  const [PhoneClass, setPhoneClass] = useState('')
  const [OTPClass, setOTPClass] = useState('')

  // ----------------------------- States declaration Messages ---------
  const [errorMessage, setErrorMessage] = useState('')
  const [errorMessageOTP, setErrorMessageOTP] = useState('')

  // ----------------------------- States declaration phone_no ---------
  const [phoneNoLength, setPhoneNoLength] = useState(11)
  const [btnToggle, setBtnToggle] = useState(true)
  // ----------------------------- States declaration Button ---------
  const [btn_OTP, setBtnOTP] = useState('Send OTP')
  const [Sendspin, setSendSpin] = useState('')
  const [Createspin, setCreateSpin] = useState('')

  // ----------------------------- States declaration Input fields ---------
  const [ApiPhoneNo, setApiPhoneNo] = useState('')
  const [getNum, setNum] = useState('')
  const [verfiyOTP, setVerfiyOTP] = useState('')

  // --------  This function is for call the Toast message  ------
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }

  // --------  This function is for handle phoneNo field  ------
  const getPhone = (e) => {
    let element = e.target
    element.value = element.value.replace(/[^0-9]/gi, '')
    setNum(e.target.value)
    setErrorMessage('')
    setPhoneClass('')

    if (getNum.charAt(0) === '0') {
      console.log('11')
      setPhoneNoLength(11)
    }
    if (getNum.charAt(0) === '3') {
      console.log('10')
      setPhoneNoLength(10)
    }
  }
  const validatePhone = () => {
    // console.log(phone);
    if (getNum === '') {
      // console.log("error");
      setErrorMessage('Please enter a number')
      setPhoneClass('input-danger')
      // setApiPhoneNo("");
      return ''
    }
    if (getNum !== '') {
      if (getNum.length < phoneNoLength) {
        setErrorMessage('Please enter a valid number')
        setPhoneClass('input-danger')
        // setApiPhoneNo("");

        return ''
      } else {
        if (getNum.charAt(0) === '0') {
          if (getNum.charAt(1) === '3') {
            if (
              getNum.charAt(2) === '0' ||
              getNum.charAt(2) === '1' ||
              getNum.charAt(2) === '2' ||
              getNum.charAt(2) === '3' ||
              getNum.charAt(2) === '4' ||
              getNum.charAt(2) === '5'
            ) {
              setErrorMessage('')
              setPhoneClass('')
              let remove_zero = parseInt(getNum, 10)
              let number = '+92' + remove_zero.toString()
              setApiPhoneNo(number)
              dispatch(addDoctorUser(number))
              handlePhoneOTP(number)
              setSendSpin('spinner-border spinner-border-sm ')

              return number
            } else {
              setErrorMessage('Please enter a valid number')
              setPhoneClass('input-danger')
              return ''
            }
          } else {
            setErrorMessage('Please enter a valid number')
            setPhoneClass('input-danger')
            // setApiPhoneNo("");

            return ''
          }
        } else if (getNum.charAt(0) === '3') {
          // console.log("invalidee");

          if (
            getNum.charAt(1) === '0' ||
            getNum.charAt(1) === '1' ||
            getNum.charAt(1) === '2' ||
            getNum.charAt(1) === '3' ||
            getNum.charAt(1) === '4' ||
            getNum.charAt(1) === '5'
          ) {
            // console.log("10 sdfsdf +1");
            let number = ''
            setErrorMessage('')
            setPhoneClass('')
            number = '+92' + getNum
            dispatch(addDoctorUser(number))
            setApiPhoneNo(number)
            handlePhoneOTP(number)
            setSendSpin('spinner-border spinner-border-sm')

            return number
          } else {
            setErrorMessage('Please enter a valid number')
            setPhoneClass('input-danger')
            return ''
          }
        } else {
          setErrorMessage('Please enter a valid number')
          setPhoneClass('input-danger')
          // setApiPhoneNo("");
          return ''
        }
      }
    }
  }

  // --------  This function is for handle OTP field  ------
  const handle_OTP = (e) => {
    let element = e.target
    element.value = element.value.replace(/[^0-9]/gi, '')
    setVerfiyOTP(e.target.value)
    setOTPClass('')
    setErrorMessageOTP('')
  }
  const handle_verify_OTP = (e) => {
    e.preventDefault()

    if (verfiyOTP !== '') {
      if (verfiyOTP.length < 6) {
        setOTPClass('input-danger')
        setErrorMessageOTP('Enter the valid OTP')
        setTimeout(() => setCreateSpin(''), 200)
      } else {
        setOTPClass('')
        setErrorMessageOTP('')
        setCreateSpin('spinner-border spinner-border-sm')
        handle_verfiyOTP_Api_Call(verfiyOTP)
      }
    } else {
      setTimeout(() => setCreateSpin(''), 200)
      setOTPClass('input-danger')
      setErrorMessageOTP('Enter the OTP')
    }
  }

  // --------  This function is for handle phoneNo OTP  ------
  const handlePhoneOTP = (number) => {
    const data = {
      phone_no: number,
      type: 2,
    }
    axios
      .post(`${API_CONSTANTS.DOCTORSENDOTPBASEURL}auth/sendOtp`, data, {
        Headers: API_CONSTANTS.headers(),
      })
      .then((r) => {
        // console.log(r.data);
        if (r.data.message === 'Success') {
          setBtnToggle(false)
          setSendSpin('')
          // setCheck(false);
          handleToast(
            'OTP send successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
        }
      })
      .catch((e) => {
        console.log(e.message)
        setSendSpin('')
      })
  }

  const handle_verfiyOTP_Api_Call = (code) => {
    const data = {
      phone_no: ApiPhoneNo,
      type: 2,
      code: code,
    }
    // console.log(data);
    axios
      .post(`${API_CONSTANTS.DOCTORSENDOTPBASEURL}auth/verifyOtp`, data, {
        Headers: API_CONSTANTS.headers(),
      })
      .then((r) => {
        setCreateSpin('')
        // console.log(r.data)
        setParamId(r.data.response.uuid)
        if (r.data.message === 'Success') {
          setSendSpin('')
          localStorage.setItem(
            'doctor_idToken',
            JSON.stringify(r.data.response.IdToken)
          )
          // setCheck(false);
          handleToast(
            'Doctor add successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
        }
      })
      .catch((e) => {
        setCreateSpin('')
      })
  }

  return (
    <>
      <section className={'content '}>
        <div className={'container-fluid p-3'}>
          <div className={'card w-100'} style={{ height: '560px' }}>
            <div className={'card-body'}>
              <div className={' d-flex justify-content-between px-1 pb-4'}>
                <h5 className={'form-heading m-0'}>Add New Doctor</h5>
                <Link to={'/doctors'}>
                  <span className={'form-backLink-form'}>
                    <FontAwesomeIcon icon={faList} className={' fa-icon'} />
                    <span className={'d_list-form'}>Doctor List</span>
                  </span>
                </Link>
              </div>
              <div className={'row mt-4 pl-2'}>
                <div className='col-md-4'>
                  <form>
                    <div
                      className={'group mb-2'}
                      style={{ position: 'relative', height: '61px' }}
                    >
                      <select>
                        <option>+92</option>
                      </select>
                      <input
                        type='text'
                        className={PhoneClass}
                        style={{ paddingLeft: '60px' }}
                        onChange={getPhone}
                        maxLength={phoneNoLength}
                        autoComplete={'false'}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'}> Phone Number</label>
                      <p className={'m-0 error-message'}>{errorMessage}</p>
                    </div>

                    <div className='group mb-2' style={{ height: '61px' }}>
                      <input
                        type='text'
                        className={OTPClass}
                        onChange={handle_OTP}
                        maxLength={'6'}
                        autoComplete={'false'}
                        required
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'}> Enter OTP</label>
                      <p className={'m-0 error-message'}>{errorMessageOTP}</p>
                    </div>
                    <button
                      className={`btn btn-createAccount`}
                      onClick={handle_verify_OTP}
                      disabled={btnToggle}
                    >
                      <span className={Createspin} />
                      Create Account
                    </button>
                  </form>
                </div>
                <div className='col-md-3'>
                  <button className={styles.btnOtp} onClick={validatePhone}>
                    <span className={Sendspin} />
                    {btn_OTP}
                  </button>
                </div>
              </div>

              <div className={' d-flex justify-content-end mt-4'}>
                <button
                  className={'btn btn-custom btn-proceed'}
                  disabled={btnToggle}
                >
                  <Link
                    to={`/doctors/personal_info/${paramId}`}
                    className={'btn btn-custom btn-proceed'}
                  >
                    Proceed to Profile
                    <FontAwesomeIcon
                      icon={faLongArrowAltRight}
                      className={'fontarrowright'}
                    />
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -------- Toast Message Code --------*/}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style jsx>{`
        .fontarrowright {
          margin-left: 7px !important;
        }
        .btn-otp {
          margin: 0px !important;
          margin-left: 16px !important;
          padding: 8px 16px !important;
          font-size: 14px !important;
          height: 40px !important;
          border: 1px solid #009b94;
          border-radius: 4px;
          letter-spacing: 1.25px;
          color: #009b94;
          background-color: #ffffff;
        }

        .btn-otp:hover {
          background-color: rgba(0, 155, 148, 0.07) !important;
        }

        .btn-otp:active {
          outline: none;
        }

        .btn-otp:focus {
          outline: none;
        }

        .btn-createAccount:not(:disabled) {
          margin: 0px !important;
          background-color: #009B94 !important;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 1.25px;
          color: #ffffff;
          border: none;
          transition: 0.2s ease-in-out;
        }

        .btn-createAccount:hover {
          background-color: #99d7d4 !important;
        }

        .btn-createAccount:disabled {
          background-color: #99d7d4 !important;
          cursor: not-allowed !important;
          margin: 0px !important;
          color: #ffffff;
          border: none;
          transition: 0.2s ease-in-out;
        }

        /*.bind-group > select input {*/
        /*  border-bottom: none !important;*/
        /*}*/

        .input-danger,
        .input-danger:focus {
          border-bottom: 1px solid #e52444 !important;
        }

        .error-message {
          font-size: 14px;
          letter-spacing: 0.5px;
          color: #e52444;
        }

        /*--------Add Doctor CSS-------*/

        .fa-icon {
          color: #99d7d4 !important;
        }

        .btn-proceed {
          border: none !important;
          color: #009b94;
        }

        .btn-proceed:hover {
          border: none;
          box-shadow: none !important;
          text-decoration: underline;
          color: rgba(1, 190, 181, 0.87);
        }

        .d_list-form {
          color: #1e1e1e;
          font-size: 14px;
          padding-left: 5px !important;
        }

        .d_list-form:hover {
          color: #1e1e1e;
          cursor: pointer;
          text-decoration: underline;
          font-size: 14px;
        }

        .card-footer {
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }

        /*========Input field CSS Styling=====*/

        .group {
          position: relative;
          margin-bottom: 25px;
          width: 100% !important;
        }

        input,
        .group select {
          font-size: 15px;
          padding: 12px 4px 2px 0px;
          display: block;
          border: none;
          border-bottom: 1px solid #cccccc;
        }

        input {
          width: 100% !important;
        }

        .group select {
          width: 53px;
          height: 19px;
          margin-top: 10px;
          padding: 0px;
          -webkit-appearance: none;

          background-repeat: no-repeat;
          background-position-x: 38px;
          background-position-y: 6px;
          background-size: 8px 8px;
          cursor: pointer;
          border: none;
          position: absolute;
          top: 3px;
          left: 0px;
        }

        input:focus,
        select {
          outline: none;
          border-bottom: 1px solid #009b94;
        }

        /*===================+
         LABEL=========== */
        label.p_label {
          color: #8e8e8e;
          font-size: 14px;
          font-weight: 400 !important;
          position: absolute;
          pointer-events: none;
          left: 1px;
          top: -10px;
          transition: 0.2s ease all;
          -moz-transition: 0.2s ease all;
          -webkit-transition: 0.2s ease all;
        }

        /* active state */
        input:focus ~ label,
        input:valid ~ label,
        .group select:valid ~ label,
        .group select:focus ~ label {
          top: -10px;
          font-size: 14px;
          color: #8e8e8e;
        }

        /* active state */
        input:focus ~ .bar:before,
        input:focus ~ .bar:after,
        .group select:focus ~ .bar:before,
        .group select:focus ~ .bar:after {
          width: 50%;
        }

        /* HIGHLIGHTER ================================== */
        .highlight {
          position: absolute;

          pointer-events: none;
          opacity: 0.5;
        }

        /* active state */
        input:focus ~ .highlight,
        .group select:focus ~ .highlight {
          -webkit-animation: inputHighlighter 0.3s ease;
          -moz-animation: inputHighlighter 0.3s ease;
          animation: inputHighlighter 0.3s ease;
        }

        .input {
          position: absolute;
          font-size: 20px;
        }

        #custom-button {
          height: 40px;
          margin-bottom: 100px;
          margin-left: 15px;
          color: #009b94;
          background-color: #ffffff;
          border: 1px solid;
          border-radius: 6px;
          padding-left: 10px;
          padding-right: 10px;
        }

        #custom-button:focus,
        .delete-btn:focus {
          outline: none;
        }

        select {
          background-color: white !important;
        }
      `}</style>
    </>
  )
}

export default AddNewDoctorForm
