import React from 'react'
import NurseList from './Components/NurseList/index'
//
export default function Nurses() {
  return (
    <>
      <NurseList />
    </>
  )
}
