import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import CloseIcon from '@mui/icons-material/Close'
import Toast from '../../../../Components/Toast/Toast'
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'

export default function AddTest() {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [ToastMessage, setToastMessage] = useState('')
    const [showToast, setShowToast] = useState('')
    const [ToastIcon, setToastIcon] = useState(faTimesCircle)
    const [vendor, setVendor] = useState('')
    const [iconColor, setIconColor] = useState('')
    const handleToast = (message, icon, iconColor, className) => {
        setToastIcon(icon)
        setToastMessage(message)
        setShowToast(`show ${className}`)
        setIconColor(iconColor)
        setTimeout(() => setShowToast(''), 2900)
    }

    const initialValues = {
        displayName: '',
        barcode: '',
        subPrice: '',
        productPrice: '',
        type: '',
        reportingDay: 'DAILY',
        discount: '',
    }
    const validationSchema = Yup.object({
        displayName: Yup.string().required('Test Name is Missing'),
        barcode: Yup.string().required('Barcode is Missing'),
        subPrice: Yup.string().required('Original Price is Missing'),
        productPrice: Yup.string().required('Product Price is Missing'),
        type: Yup.string().required('Type is Missing'),
        discount: Yup.string().required('discount is Missing'),
    })

    const handleSubmit = (values, { setSubmitting }) => {
        values.vendor = vendor
        axios
            .post(`${API_CONSTANTS.LABURL}admin/labs/tests`, values, {
                headers: API_CONSTANTS.headers(),
            })
            .then(response => {
                if (response.data.message === 'Success') {
                    handleToast(
                        'Test added successfully',
                        faCheckCircle,
                        '#009b94',
                        'show-success'
                    )
                }
                handleClose()
            })
            .catch(error => {
                // toast.error('Failed to add medicine')
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
    return (
        <>
            <button className="add-record btn mx-4 " onClick={handleShow}>
                <i className="fas fa-plus" style={{ marginRight: 8 }}></i>
                Add Test
            </button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Body className="d-flex flex-column">
                    <div className='d-flex justify-content-between'>
                        <h4>Add Test</h4>
                        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </div>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        <Form className="form-inline row g-3">
                            <div className="col-md-6">
                                <label htmlFor="inputName1" className="form-label">
                                    Test Name
                                </label>
                                <Field
                                    type="text"
                                    name="displayName"
                                    placeholder="X-Ray"
                                    className="form-control"
                                    id="inputName1"
                                />
                                <ErrorMessage name="displayName" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputName2" className="form-label">
                                    Lab Name
                                </label>
                                <div className="dropdown">
                                    <select
                                        className="col-md-6 form-control"
                                        name="vendor"
                                        value={vendor}
                                        onChange={(e) => setVendor(e.target.value)}
                                    >
                                        <option value="">Choose One Lab</option>
                                        <option value="Chughtai Lab">Chughtai Lab</option>
                                        <option value="IDC Lab">IDC Lab</option>
                                    </select>
                                    <div className="dropdown-icon-container">
                                        <i className="fas fa-chevron-down dropdown-icon"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputName2" className="form-label">
                                    Barcode
                                </label>
                                <Field
                                    type="text"
                                    name="barcode"
                                    placeholder="91254"
                                    className="form-control"
                                    id="inputName2"
                                />
                                <ErrorMessage name="barcode" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputName3" className="form-label">
                                    Original Price
                                </label>
                                <Field
                                    type="text"
                                    name="subPrice"
                                    placeholder="Original Price (Rs.123)"
                                    className="form-control"
                                    id="inputName3"
                                />
                                <ErrorMessage name="subPrice" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputName4" className="form-label">
                                    Discounted Price
                                </label>
                                <Field
                                    type="number"
                                    name="productPrice"
                                    placeholder="Discounted Price (Rs.123)"
                                    className="form-control"
                                    id="inputName4"
                                />
                                <ErrorMessage name="productPrice" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputName4" className="form-label">
                                    Type
                                </label>
                                <Field
                                    type="text"
                                    name="type"
                                    placeholder="Radiology"
                                    className="form-control"
                                    id="inputName4"
                                />
                                <ErrorMessage name="type" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputName3" className="form-label">
                                    Discount in %
                                </label>
                                <Field
                                    type="number"
                                    name="discount"
                                    placeholder="20%"
                                    className="form-control"
                                    id="inputName3"
                                />
                                <ErrorMessage name="discount" component="div" className="error-message" />
                            </div>
                            <div className="mt-3 mb-3 d-flex gap-2">
                                <button type="submit" className="btn btn-save">
                                    Add Test
                                </button>
                                <button type="reset" className="btn btn-outline-danger" onClick={handleClose} style={{ height: '43px' }}>
                                    Discard
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </Modal.Body>
            </Modal>
            <style jsx='true'>
                {`
            .btn-save:hover {
              background: #009b94 !important;
              color: #fff;
            }
            .btn-discard {
              background: none;
              color: red;
              padding: 10px 10px !important;
            }
            .fas fa-plus:hover{
              color:#009b94 !important;
            }
            .error-message {
              color: red;
            }
            .add-record {
              background: #009b94 !important;
              color: #fff;
            }
            .add-record:hover{
              background:  #fff !important;
              color: #009b94 !important;
            }
            `}
            </style>
            {/* <ToastContainer position="top-center" /> */}
            <Toast
                cName={showToast}
                icon={ToastIcon}
                color={iconColor}
                text={ToastMessage}
            />
        </>
    )
}
