import { createSlice } from '@reduxjs/toolkit'
//

export const appointmentFilterSlice = createSlice({
  name: 'appointmentFilter',
  initialState: {
    value: '',
    value1: '',
    value2: '',
    value3: '',
    value4: '',
  },
  reducers: {
    all: (state, action) => {
      state.value = ''
    },
    booked: (state, action) => {
      state.value = 'booked'
    },
    missed: (state, action) => {
      state.value = 'missed'
    },
    cancelled: (state, action) => {
      state.value = 'cancelled'
    },
    InProgress: (state, action) => {
      state.value = 'InProgress'
    },
    completed: (state, action) => {
      state.value = 'completed'
    },
    unattended: (state, action) => {
      state.value = 'unattended'
    },
    called: (state, action) => {
      state.value = 'called'
    },
    testCall: (state, action) => {
      state.value = 'testCall'
    },
    reschedule: (state, action) => {
      state.value = 'reschedule'
    },
    fakeCall: (state, action) => {
      state.value = 'fakeCall'
    },
    
    // appoint_date_from filter value
    appointmentDateFrom: (state, action) => {
      const { payload } = action
      state.value1 = payload
    },
    // appoint_date_to filter value
    appointmentDateTo: (state, action) => {
      const { payload } = action
      state.value2 = payload
    },
    created_at: (state, action) => {
      const { payload } = action
      state.value3 = payload
    },
    Booked: (state, action) => {
      state.value4 = 'Booked'
    },
    Instant: (state, action) => {
      state.value4 = 'Instant'
    },
    FollowUp: (state, action) => {
      state.value4 = 'FollowUp'
    },
  },
})

export const appointmentFilterReducer = appointmentFilterSlice.reducer
