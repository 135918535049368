import React, { useEffect, useState } from 'react'
// import { ReactComponent as AdminProfileIcon } from './asset/icons/adminprofile_icon.svg'
import { useParams, NavLink } from 'react-router-dom'
import { Outlet } from 'react-router'
//
import { useDispatch, useSelector } from 'react-redux'
import { getProfile } from './patientProfileSlice'
//
import BlockProfileButton from './BlockProfileButton'
import EditProfileButton from './EditProfileButton'
import UnblockProfileButton from './UnblockProfileButton'
// import FontawesomeIcon libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
//
export default function PatientProfile() {
  const params = useParams()
  const dispatch = useDispatch()
  const { value } = useSelector((state) => state.getPatientProfileReducer)
  const [overview, setOverview] = useState(value)

  //
  const PROFILE_NAV_LINKS = [
    {
      navLink: 'Overview',
      navRoute: `/patients/profile/${params.id}/overview`,
    },
    {
      navLink: 'Consultation',
      navRoute: `/patients/profile/${params.id}/consultations`,
    },
    {
      navLink: 'Referral',
      navRoute: `/patients/profile/${params.id}/referral`, 
    },
    {
      navLink: 'Services',
      navRoute: `/patients/profile/${params.id}/services`,
    },
    {
      navLink: 'Lab Tests',
      navRoute: `/patients/profile/${params.id}/lab`,
    },
    {
      navLink: 'Medical Record',
      navRoute: `/patients/profile/${params.id}/medicalhistory`,
    },
    {
      navLink: 'Wallet',
      navRoute: `/patients/profile/${params.id}/wallet`,
    },
    {
      navLink: 'Notification',
      navRoute: `/patients/profile/${params.id}/notifications`,
    },
    {
      navLink: 'Healthtracker',
      navRoute: `/patients/profile/${params.id}/healthtracker`,
    },
  ]
  // get user detail by it's id

  useEffect(() => {
    let mounted = true
    if (mounted) {
      dispatch(getProfile(params.id))
    }
    mounted = false
  }, [dispatch, params.id])
  // onSaveCallBack
  return (
    <>
      <div className='container-fluid profile-header'>
        <div className='row pt-4 mb-1 prof'>
          <div className='col'>
            <div className='d-flex ms-4 align-items-center'>
              <span className='me-4'>
                <img
                  src={value.profiles?.self.url ? value.profiles?.self.url : 'https://img.icons8.com/material/72/000000/user-male-circle--v1.png'}
                  alt=''
                  style={{ width: 72, borderRadius: 50, height: 72 }}
                />
              </span>
              <div className='d-flex flex-column'>
                <h5
                  style={{ fontWeight: 500, marginBottom: 15, marginTop: -4 }}
                >
                  {value.Fname?.charAt(0).toUpperCase() + value.Fname?.slice(1)}
                </h5>
                <h6
                  style={{ fontWeight: 400, color: '#5C5C5C', marginBottom: 0 }}
                >
                  Patient ID {value.phoneNo}
                </h6>
              </div>
              {value.is_blocked && (
                <span
                  className='btn mx-2'
                  style={{ background: '#b00020', color: '#fff' }}
                >
                  <FontAwesomeIcon icon={faBan} className={'mx-1'} />
                  Blocked
                </span>
              )}
            </div>
          </div>
          <div className='col'>
            <div
              className='d-flex justify-content-end'
              style={{ columnGap: 5 }}
            >
              <EditProfileButton />
              {value.is_blocked || <BlockProfileButton overview={overview} />}
              {/* {value.is_blocked || <DeactiveProfileButton />} */}
              {value.is_blocked && <UnblockProfileButton overview={overview} />}
            </div>
          </div>
        </div>
        <div className='row pt-4'>
          <div className='col'>
            <div className='d-flex ms-4' style={{ columnGap: 5 }}>
              {PROFILE_NAV_LINKS.map((navItem) => (
                <NavLink
                  to={navItem.navRoute}
                  key={navItem.navLink}
                  className={({ isActive }) =>
                    isActive ? 'active' : 'inactive'
                  }
                  style={{ fontSize: 14 }}
                >
                  {navItem.navLink}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='containter-fluid'>
        <Outlet />
      </div>
      <style jsx='true'>
        {`
          .profile-header {
            background: #e0f3f2;
            height: 160px;
          }
          .active {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .inactive {
            padding: 7px 15px;
          }
          @media only screen and (max-width: 1200px) and (min-width: 1050px) {
            .prof{
              margin-bottom: 0px !important;
              padding-top: 10px !important;
            }
          }
          @media screen and (max-width: 950px) {
            .prof{
              margin-bottom: 0px !important;
              padding-top: 10px !important;
            }
          }
        `}
      </style>
    </>
  )
}
