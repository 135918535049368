import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'

export const getConsultation = createAsyncThunk(
  'getConsultation',
  async (paramsID) => {
    const response = await axios
      .get(`${API_CONSTANTS.BASEURL}admin/appointment/${paramsID}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => res.data.response)

    return response
  }
)
export const getConsultationSlice = createSlice({
  name: 'getConsultation',
  initialState: {
    value: {
      sharedMedicalRecord: [],
      userId: '',
      appointmentInfo: {
        appointment_date: '',
        appointment_status: '',
        appointment_type: '',
        booked_on: '',
        charges: '',
        medium: '',
        name: '',
        profileUrl:
          'https://img.icons8.com/material/72/000000/user-male-circle--v1.png',
        relation: '',
      },
      doctorInfo: {
        email: '',
        contact: '',
        educationTitle: '',
        emergencyContact: '',
        firstName: '',
        lastName: '',
        name: '',
        pmdc: '',
        profileUrl:
          'https://img.icons8.com/material/72/000000/user-male-circle--v1.png',
        specialization: '',
        title: '',
      },
      patientInfo: {
        email: '',
        phone_no: '',
        dob: '',
        gender: '',
        name: '',
        phone: '',
        profileId: '',
        relation: '',
        url: 'https://img.icons8.com/material/72/000000/user-male-circle--v1.png',
        userId: '',
      },
      prescription: {
        e_prescription: [
          {
            duration: '',
            quantity: '',
            instruction: '',
            dosage_type: '',
            category: '',
            medicine_name: '',
            intake: {
              breakfast: '',
              dinner: '',
              lunch: '',
            },
          },
        ],
        prescription_url: ' ',
      },
    },
  },
  reducers: {},
  extraReducers: {
    [getConsultation.pending]: (state) => {
      state.loading = true
    },
    [getConsultation.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.value = payload
    },
    [getConsultation.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const getConsultationProfileReducer = getConsultationSlice.reducer
