/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { API_CONSTANTS } from '../../../../API_CONSTANTS';
import { useParams } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import Table from '../../../../Components/Table';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close'
import Toast from '../../../../Components/Toast/Toast'
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import PDelete from '../../../../resources/images/svgs/Plan_Delete.svg'

function EditTest({ data }) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [ToastMessage, setToastMessage] = useState('')
    const [showToast, setShowToast] = useState('')
    const [ToastIcon, setToastIcon] = useState(faTimesCircle)
    const [vendor, setVendor] = useState(data.vendor)
    const [iconColor, setIconColor] = useState('')
    const handleToast = (message, icon, iconColor, className) => {
        setToastIcon(icon)
        setToastMessage(message)
        setShowToast(`show ${className}`)
        setIconColor(iconColor)
        setTimeout(() => setShowToast(''), 2900)
    }

    const initialValues = {
        displayName: data.displayName,
        barcode: data.barcode,
        subPrice: data.subPrice,
        productPrice: data.productPrice,
        type: data.type,
        reportingDay: 'DAILY',
        discount: data.discount,
    }
    const validationSchema = Yup.object({
        displayName: Yup.string().required('Test Name is Missing'),
        barcode: Yup.string().required('Barcode is Missing'),
        subPrice: Yup.string().required('Original Price is Missing'),
        productPrice: Yup.string().required('Product Price is Missing'),
        type: Yup.string().required('Type is Missing'),
        discount: Yup.string().required('discount is Missing'),
    })

    const handleSubmit = (values, { setSubmitting }) => {
        values.vendor = vendor
        axios
            .put(`${API_CONSTANTS.LABURL}admin/labs/tests/${data.Sk}`, values, {
                headers: API_CONSTANTS.headers(),
            })
            .then(response => {
                if (response.data.message === 'Success') {
                    handleToast(
                        'Test Edit Successfully',
                        faCheckCircle,
                        '#009b94',
                        'show-success'
                    )
                }
                handleClose()
            })
            .catch(error => {
                // toast.error('Failed to add medicine')
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
    return (
        <>
            <button className='btn' onClick={handleShow}>
                <i className='fas fa-pen' style={{ color: '#8E8E8E' }}></i>
            </button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Body className="d-flex flex-column">
                    <div className='d-flex justify-content-between'>
                        <h4>Add Test</h4>
                        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </div>
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        <Form className="form-inline row g-3">
                            <div className="col-md-6">
                                <label htmlFor="inputName1" className="form-label">
                                    Test Name
                                </label>
                                <Field
                                    type="text"
                                    name="displayName"
                                    placeholder="X-Ray"
                                    className="form-control"
                                    id="inputName1"
                                />
                                <ErrorMessage name="displayName" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                                {/* <label htmlFor="inputName2" className="form-label">
                                    Lab Name
                                </label>
                                <Field
                                    type="text"
                                    name="vendor"
                                    placeholder="Chughtai"
                                    className="form-control"
                                    id="inputName2"
                                />*/}
                                <label htmlFor="inputName2" className="form-label">
                                    Lab Name
                                </label>
                                <div className="dropdown">
                                    <select
                                        className="col-md-6 form-control"
                                        name="vendor"
                                        value={vendor}
                                        onChange={(e) => setVendor(e.target.value)}
                                    >
                                        <option value="">Choose One Lab</option>
                                        <option value="Chughtai Lab">Chughtai Lab</option>
                                        <option value="IDC Lab">IDC Lab</option>
                                    </select>
                                    <div className="dropdown-icon-container">
                                        <i className="fas fa-chevron-down dropdown-icon"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputName2" className="form-label">
                                    Barcode
                                </label>
                                <Field
                                    type="text"
                                    name="barcode"
                                    placeholder="91254"
                                    className="form-control"
                                    id="inputName2"
                                />
                                <ErrorMessage name="barcode" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputName3" className="form-label">
                                    Original Price
                                </label>
                                <Field
                                    type="text"
                                    name="subPrice"
                                    placeholder="Original Price (Rs.123)"
                                    className="form-control"
                                    id="inputName3"
                                />
                                <ErrorMessage name="subPrice" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputName4" className="form-label">
                                    Discounted Price
                                </label>
                                <Field
                                    type="number"
                                    name="productPrice"
                                    placeholder="Discounted Price (Rs.123)"
                                    className="form-control"
                                    id="inputName4"
                                />
                                <ErrorMessage name="productPrice" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputName4" className="form-label">
                                    Type
                                </label>
                                <Field
                                    type="text"
                                    name="type"
                                    placeholder="Radiology"
                                    className="form-control"
                                    id="inputName4"
                                />
                                <ErrorMessage name="type" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="inputName3" className="form-label">
                                    Discount in %
                                </label>
                                <Field
                                    type="number"
                                    name="discount"
                                    placeholder="20%"
                                    className="form-control"
                                    id="inputName3"
                                />
                                <ErrorMessage name="discount" component="div" className="error-message" />
                            </div>
                            <div className="mt-3 mb-3 d-flex gap-2">
                                <button type="submit" className="btn btn-save">
                                    Add Test
                                </button>
                                <button type="reset" className="btn btn-outline-danger" onClick={handleClose} style={{ height: '43px' }}>
                                    Discard
                                </button>
                            </div>
                        </Form>
                    </Formik>
                </Modal.Body>
            </Modal>
            <style jsx='true'>
                {`
            .btn-save:hover {
              background: #009b94 !important;
              color: #fff;
            }
            .btn-discard {
              background: none;
              color: red;
              padding: 10px 10px !important;
            }
            .fas fa-plus:hover{
              color:#009b94 !important;
            }
            .error-message {
              color: red;
            }
            .add-record {
              background: #009b94 !important;
              color: #fff;
            }
            .add-record:hover{
              background:  #fff !important;
              color: #009b94 !important;
            }
            `}
            </style>
            {/* <ToastContainer position="top-center" /> */}
            <Toast
                cName={showToast}
                icon={ToastIcon}
                color={iconColor}
                text={ToastMessage}
            />
        </>
    )
}
function Delete({ data }) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [isLoading, setIsLoading] = useState(false)
    const [ToastMessage, setToastMessage] = useState('')
    const [showToast, setShowToast] = useState('')
    const [ToastIcon, setToastIcon] = useState(faTimesCircle)
    const [iconColor, setIconColor] = useState('')
    const handleToast = (message, icon, iconColor, className) => {
        setToastIcon(icon)
        setToastMessage(message)
        setShowToast(`show ${className}`)
        setIconColor(iconColor)
        setTimeout(() => setShowToast(''), 2900)
    }
    const handleDelete = () => {
        setIsLoading(true);
        axios
            .delete(`${API_CONSTANTS.LABURL}admin/labs/tests/${data.Sk}`,
                {
                    headers: API_CONSTANTS.headers(),
                })
            .then((response) => {
                if (response.data.message === 'Success') {
                    handleToast(
                        'Test Deleted Successfully',
                        faCheckCircle,
                        '#009b94',
                        'show-success'
                    )
                }
                setIsLoading(false)
                setShow(false)
            })
            .catch((err) => {
                setIsLoading(false)
                console.log(err)
            })
    }
    return (
        <>
            <button className='btn' onClick={handleShow}>
                <i className='fas fa-trash-alt' style={{ color: '#FF6760' }}></i>
            </button>
            <Modal show={show}
                className='d-flex justify-content-center align-items-center'
            >
                <Modal.Body className='d-flex justify-content-around align-items-center flex-column' style={{ maxHeight: '255px' }}>
                    <div style={{
                        width: '32px',
                        height: '32px'
                    }}>
                        <img src={PDelete} alt='' />
                    </div>
                    <h4 style={{ color: '#AF1300' }}>Delete !</h4>
                    <p style={{ textAlign: 'center' }}>Are you sure you want to Delete this Test?</p>
                    <div className='d-flex' style={{ gap: '20px' }}>
                        <Button style={{ color: '#FFFFFF', backgroundColor: '#B00020', border: 'none' }} onClick={handleDelete}>
                            Delete {isLoading && <Spinner animation='border' size='sm' />}
                        </Button>
                        <Button
                            style={{ color: '#4A4A4A', backgroundColor: '#FFFFFF', border: '1px solid #4A4A4A' }}
                            onClick={() => handleClose()}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Toast
                cName={showToast}
                icon={ToastIcon}
                color={iconColor}
                text={ToastMessage}
            />
        </>
    )
}
// Edit And Delete Component Call Component 
function ActionButtons({ data }) {
    return (
        <div style={{ width: "125px" }} className='d-flex'>
            <EditTest data={data} />
            <Delete data={data} />
        </div>
    )
}
export default function LabTests() {
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
    const [testList, setTestList] = useState([]);
    const [searchTest, setSearchTest] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);
    const [searchQuery, setSearchQuery] = useState("");
    const { id } = useParams()
    const fetchData = useCallback(
        ({ pageSize, pageIndex }) => {
            const fetchId = lastEvaluatedKey;
            if (fetchId === fetchIdRef.current) {
                const startRow = pageSize * pageIndex;
                const endRow = startRow + pageSize;
                setTestList(testList.slice(startRow, endRow));
                setPageCount(Math.ceil(testList.length / pageSize));
            }
        },
        [testList, lastEvaluatedKey]
    );
    // Search Test Here
    const handleSearch = () => {
        setSearchLoading(true);
        axios
            .get(`${API_CONSTANTS.LABURL}admin/labs/tests`, {
                headers: API_CONSTANTS.headers(),
                params: {
                    name: searchQuery,
                    vendor: id,
                },
            })
            .then((res) => {
                setSearchTest(res.data.response.Items);
                setSearchLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setSearchLoading(true);
            });
    };
    useEffect(() => {
        if (searchQuery.length >= 1) {
            handleSearch();
        } else {
            setSearchTest([]);
        }
    }, [searchQuery]);
    // all labs data API 
    const lab = () => {
        axios
            .get(`${API_CONSTANTS.LABURL}admin/labs/tests`, {
                headers: API_CONSTANTS.headers(),
                params: {
                    vendor: id,
                },
            })
            .then((res) => {
                setIsLoading(true);
                setTestList(res?.data?.response?.Items);
                setLastEvaluatedKey(res?.data?.response?.LastEvaluatedKey)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.reload();
                    window.localStorage.clear();
                }
                setIsLoading(true);
            });
    };
    const handleLoadMoreData = () => {
        axios
            .get(
                `${API_CONSTANTS.LABURL}admin/labs/tests`,
                {
                    headers: API_CONSTANTS.headers(),
                    params: {
                        vendor: id,
                        last: lastEvaluatedKey,
                    },
                }
            )
            .then((response) => {
                setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
                var labListNextPage = response.data.response.Items;
                setTestList([...testList, ...labListNextPage]);
                setLoading(true)
            })
    };

    const columns = useMemo(
        () => [
            {
                Header: "Test Name",
                accessor: "MedicineName",
            },
            {
                Header: "Lab Name",
                accessor: "vendor",
            },
            {
                Header: "Price",
                accessor: "subPrice",
            },
            {
                Header: "Discounted Price",
                accessor: "productPrice",
            },
            {
                Header: "Discount",
                accessor: row => `${row.discount}%`,
            },
            {
                Header: "Action",
                accessor: (data) => <ActionButtons data={data} />
            },
        ],
        []
    );
    useEffect(() => {
        lab();
    }, []);
    return (
        <>
            <div className="d-flex pb-4">
                <input
                    placeholder="Search Test Here"
                    value={searchQuery}
                    className="p-2 w-50 mx-4 rounded searchTest"
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button className='p-2 rounded border-0 searchButton' onClick={handleSearch}>
                    {searchLoading ?
                        <div className="d-flex justify-content-center p-1 ">
                            <Spinner
                                animation="border"
                                size="sm"
                                style={{ color: "#FFF" }}
                            />
                        </div>
                        : "Search"}
                </button>
            </div>
            <div className="row rounded bg-white mt-0 m-4">
                <div className="d-flex align-items-start">
                    <h3
                        style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            padding: "18px",
                        }}
                    >
                        All Tests
                    </h3>
                    {!isLoading && (
                        <div className="d-flex justify-content-center ">
                            <Spinner
                                animation="border"
                                size="sm"
                                style={{ color: "#24AAA4", marginTop: "20px" }}
                            />
                        </div>
                    )}
                </div>
                <Table
                    columns={columns}
                    data={searchQuery ? searchTest : testList}
                    routingLink={""}
                    pageCount={pageCount}
                    fetchData={fetchData}
                    loadMoreList={handleLoadMoreData}
                    lastEvaluatedKey={lastEvaluatedKey}
                    loading={loading}
                    setLoading={setLoading}
                    // FilterForm={<LabFilterForm lab={lab} />}
                    recordDownload={true}
                // onDownloadBtn={onDownloadBtn}
                />
            </div>
            <style jsx="true">{`
          .add-record {
            background: #009b94 !important;
            color: #fff;
          }
          .btn:focus {
            box-shadow: none !important;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .patient-search-input {
            border-radius: 0;
          }
          .searchTest{
            border: 2px solid #009b94 !important;
            outline: none;
          }
          .searchButton{
            background: #009b94 !important;
            color: #fff;
          }
        `}</style>
        </>
    )
}