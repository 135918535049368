import React, { useState, useRef, useReducer, } from 'react'
import { useParams } from 'react-router-dom'
import toArrayBuffer from 'to-array-buffer'
import axios from 'axios'
import moment from 'moment'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faPlus,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import uploadIcon from '../../../../../resources/images/svgs/uploadIcon.svg'
import './FormPages.css'
import Toast from '../../../../../Components/Toast/Toast'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
import ReactTagInput from '@pathofdev/react-tag-input'
import Modal from 'react-bootstrap/Modal'
import { edit_svg_icon, del_svg_icon } from '../../../../../constants_file'
import edit_Icon from '../../../../../resources/images/svgs/edit.svg'
import delete_Icon from '../../../../../resources/images/svgs/delete.svg'
import { useSelector } from 'react-redux'

//Reducer state Function 
function dataReducer(state, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'DROP_INIT': {
      return {
        ...state, drop: true
      }
    }
    case 'DROP_END': {
      return {
        ...state, drop: false
      }
    }
    case 'SHOW_DEL_INIT': {
      return {
        ...state, show_del: true
      }
    }
    case 'SHOW_DEL_END': {
      return {
        ...state, show_del: false
      }
    }
    case 'SHOW_DEL_C_INIT': {
      return {
        ...state, show_del_c: true
      }
    }
    case 'SHOW_DEL_C_END': {
      return {
        ...state, show_del_c: false
      }
    }
    case 'SHOW_DEL_B_INIT': {
      return {
        ...state, show_del_b: true
      }
    }
    case 'SHOW_DEL_B_END': {
      return {
        ...state, show_del_b: false
      }
    }
    case 'DOC_UPLOAD_INIT': {
      return {
        ...state, doc_upload: true
      }
    }
    case 'DOC_UPLOAD_END': {
      return {
        ...state, doc_upload: false
      }
    }
    case 'EDU_DOC_UPLOAD_INIT': {
      return {
        ...state, edu_doc_upload: true
      }
    }
    case 'EDU_DOC_UPLOAD_END': {
      return {
        ...state, edu_doc_upload: false
      }
    }
    case 'CER_DOC_UPLOAD_INIT': {
      return {
        ...state, cer_doc_upload: true
      }
    }
    case 'CER_DOC_UPLOAD_END': {
      return {
        ...state, cer_doc_upload: false
      }
    }
    case 'SHOW_INIT': {
      return {
        ...state, show: true
      }
    }
    case 'SHOW_END': {
      return {
        ...state, show: false
      }
    }
    case 'SHOW2_INIT': {
      return {
        ...state, show2: true
      }
    }
    case 'SHOW2_END': {
      return {
        ...state, show2: false
      }
    }
    case 'SHOW3_INIT': {
      return {
        ...state, show3: true
      }
    }
    case 'SHOW3_END': {
      return {
        ...state, show3: false
      }
    }
    case 'SHOW4_INIT': {
      return {
        ...state, show4: true
      }
    }
    case 'SHOW4_END': {
      return {
        ...state, show4: false
      }
    }
    case 'SHOW5_INIT': {
      return {
        ...state, show5: true
      }
    }
    case 'SHOW5_END': {
      return {
        ...state, show5: false
      }
    }
    case 'SHOW_UPDATE_EXP_MODAL_INIT': {
      return {
        ...state, Show_update_exp_modal: true
      }
    }
    case 'SHOW_UPDATE_EXP_MODAL_END': {
      return {
        ...state, Show_update_exp_modal: false
      }
    }
    case 'SHOW_UPDATE_EDU_MODAL_INIT': {
      return {
        ...state, Show_update_edu_modal: true
      }
    }
    case 'SHOW_UPDATE_EDU_MODAL_END': {
      return {
        ...state, Show_update_edu_modal: false
      }
    }
    case 'SHOW_UPDATE_CER_MODAL_INIT': {
      return {
        ...state, Show_update_cer_modal: true
      }
    }
    case 'SHOW_UPDATE_CER_MODAL_END': {
      return {
        ...state, Show_update_cer_modal: false
      }
    }
    case 'SHOW_UPDATE_VOLUNTEER_MODAL_INIT': {
      return {
        ...state, Show_update_volunteer_modal: true
      }
    }
    case 'SHOW_UPDATE_VOLUNTEER_MODAL_END': {
      return {
        ...state, Show_update_volunteer_modal: false
      }
    }
    case 'SHOW_UPDATE_ACHIEVEMENT_MODAL_INIT': {
      return {
        ...state, Show_update_achievement_modal: true
      }
    }
    case 'SHOW_UPDATE_ACHIEVEMENT_MODAL_END': {
      return {
        ...state, Show_update_achievement_modal: false
      }
    }
  }
}

const Personal_Info_Form = (props) => {

  const value = useSelector((state) => state.getNurseReducer.value)
  // console.log(value)

  const date = moment(new Date())
  const today_date = date.format('YYYY-MM-DD')
  const params = useParams()

  // state for img in array buffer form
  const [sendImg, setSendImg] = useState(value.personal_info?.profileUrl ? value.personal_info?.profileUrl :
    'https://terafort.s3.ap-southeast-1.amazonaws.com/download.jpg'
  )
  const [CNICfront, setCNICfront] = useState(value.cnicFront ? value.cnicFront : '')
  const [CNICback, setCNICback] = useState(value.cnicBack ? value.cnicBack : '')

  // state for img in base64 form
  const [preview, setPreview] = useState('')
  const [front_preview, setfront_preview] = useState('')
  const [back_preview, setback_preview] = useState('')

  const [data, setData] = useReducer(dataReducer, {
    drop: false,
    show_del: false,
    show_del_c: false,
    show_del_b: false,
    doc_upload: false,
    edu_doc_upload: false,
    cer_doc_upload: false,
    show: false,
    show2: false,
    show3: false,
    show4: false,
    show5: false,
    Show_update_exp_modal: false,
    Show_update_edu_modal: false,
    Show_update_cer_modal: false,
    Show_update_volunteer_modal: false,
    Show_update_achievement_modal: false,
  })
  const { drop, show_del, show_del_c, show_del_b, doc_upload,
    edu_doc_upload, cer_doc_upload, show, show2, show3, show4, show5,
    Show_update_exp_modal, Show_update_edu_modal, Show_update_cer_modal,
    Show_update_achievement_modal, Show_update_volunteer_modal,
  } = data
  const stage = '3'
  const [index_update, setIndex_update] = useState('')

  const fileInputRef = useRef()
  const cnicFrontside = useRef()
  const cnicBackside = useRef()
  const exp_InputRef = useRef()
  const edu_InputRef = useRef()
  const cer_InputRef = useRef()

  // let indexkey;
  const [doc_name, setDoc_name] = useState(
    'Upload the related documents in jpg, png or pdf.'
  )
  const [edu_doc_name, setEdu_Doc_name] = useState(
    'Upload the related documents in jpg, png or pdf.'
  )
  const [cer_doc_name, setCer_doc_name] = useState(
    'Upload the related documents in jpg, png or pdf.'
  )
  // const [uploadExpImg, setUploadExpImg] = useState("");

  const handlebalShow = () => setData({ type: 'SHOW_INIT' })
  const close_update_exp_modal = () => setData({ type: 'SHOW_UPDATE_EXP_MODAL_END' })
  const close_update_edu_modal = () => setData({ type: 'SHOW_UPDATE_EDU_MODAL_END' })
  const close_update_cre_modal = () => setData({ type: 'SHOW_UPDATE_CER_MODAL_END' })

  const [Createspin, setCreateSpin] = useState('')
  const [docUploadSpin, setDocUploadSpin] = useState('')
  const [edudocUploadSpin, setEduDocUploadSpin] = useState('')
  const [cerdocUploadSpin, setCerdocUploadSpin] = useState('')

  // add experince modale state
  const [addExperince, setAddExperince] = useState({
    title: '',
    employment_type: '',
    company: '',
    location: '',
    start_date: '',
    end_date: '',
    currently_work: false,
    url: '',
  })
  // add education modal state
  const [addEducation, setAddEducation] = useState({
    institute_name: '',
    degree: '',
    field_of_study: '',
    location: '',
    start_date: '',
    end_date: '',
    url: '',
  })

  const [addCertification, setAddCertification] = useState({
    title: '',
    institute: '',
    field_of_study: '',
    start_date: '',
    end_date: '',
    url: '',
  })

  // form data state
  const [Fname, setFname] = useState(value.personal_info?.firstName ? value.personal_info?.firstName : '')
  const [Lname, setLname] = useState(value.personal_info?.lastName ? value.personal_info?.lastName : '')
  // const [Title, setTitle] = useState('')
  const [services, setServices] = useState(value.services ? value.services : '')
  const [Experience, setExperience] = useState(value.experience ? value.experience : '')
  const [LicenseNumber, setLicenseNumber] = useState(value.liscenseNumber ? value.liscenseNumber : '')
  const [Email, setEmail] = useState(value.email ? value.email : '')
  const [DOB, setDOB] = useState(value.dob ? value.dob : '')
  const [Gender, setGender] = useState(value.gender ? value.gender : '')
  const [EphoneNo, setEphoneNo] = useState(value.backupNumber ? value.backupNumber : '')
  const [City, setCity] = useState(value.city ? value.city : '')
  const [NOK_name, setNOK_name] = useState(value.nextOfKin?.name ? value.nextOfKin?.name : '')
  const [NOK_phoneNo, setNOK_phoneNo] = useState(value.nextOfKin?.phone_no ? value.nextOfKin?.phone_no : '')
  const [NOK_relation, setNOK_relation] = useState(value.nextOfKin?.relation ? value.nextOfKin?.relation : '')

  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [experince_Detailes, setExperince_Detailes] = useState(value.experiences ? value.experiences : '')
  const [education_Detailes, setEaducation_Detailes] = useState(value.educationalDocuments ? value.educationalDocuments : '')
  const [certificationc_Detailes, setCertification_Detailes] = useState(value.certifications ? value.certifications : '')
  const [coverletter, setCoverletter] = useState(value.coverLetter ? value.coverLetter : '')
  const [volunteerExperience, setVolunteerExperience] = useState(value.voluntaryservices ? value.voluntaryservices : '')
  const [achievements, setAchievements] = useState(value.achievementAwards ? value.achievementAwards : '')

  const handleClose2 = () => setData({ type: 'SHOW4_END' })
  const handleClose3 = () => setData({ type: 'SHOW5_END' })
  const close_update_volunteer_modal = () =>
    setData({ type: 'SHOW_UPDATE_VOLUNTEER_MODAL_END' })
  const close_update_achievement_modal = () =>
    setData({ type: 'SHOW_UPDATE_ACHIEVEMENT_MODAL_END' })


  const handle_Remove_volunteer_Detailes = (e) => {
    const name = e.target.getAttribute('data-id')
    console.log(name)
    const index = parseInt(name)
    setVolunteerExperience([
      ...volunteerExperience.slice(0, index),
      ...volunteerExperience.slice(index + 1),
    ])
  }
  const handle_Remove_achievement_Detailes = (e) => {
    const name = e.target.getAttribute('data-id')
    console.log(name)
    const index = parseInt(name)
    setAchievements([
      ...achievements.slice(0, index),
      ...achievements.slice(index + 1),
    ])
  }

  const handle_update_volunteer_Detailes = (e) => {
    setData({ type: 'SHOW_UPDATE_VOLUNTEER_MODAL_INIT' })
    const index = e.target.getAttribute('data-id')
    console.log(index)
    setIndex_update(index)
  }

  const handle_update_achievement_Detailes = (e) => {
    setData({ type: 'SHOW_UPDATE_ACHIEVEMENT_MODAL_INIT' })
    const index = e.target.getAttribute('data-id')
    console.log(index)
    setIndex_update(index)
  }
  const handleEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // console.log("enter!");
  }

  const handleOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // console.log("over!");
  }

  const handleLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // console.log("leave!");
  }

  //upload Profile images
  const handleUpload = (e) => {
    e.preventDefault()
    setData({ type: 'SHOW_DEL_INIT' })
    setData({ type: 'DROP_INIT' })
    const [file] = e.target.files || e.dataTransfer.files
    uploadFile(file)
  }

  const uploadFile = (file) => {
    // preview img
    const reader = new FileReader()
    reader.readAsBinaryString(file)

    //preview onload function.
    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      setPreview(`data:image/jpg;base64,${fileRes}`)
      const testImg = toArrayBuffer(fileRes)
      let imgURL = await callApi(testImg)
      console.log(imgURL);
      // console.log(typeof imgURL)
      setSendImg(imgURL)
      setData({ type: 'SHOW_DEL_END' })
    }

    reader.onerror = () => {
      // console.log('There is a problem while uploading...')
    }
  }

  function CNIC_front_upload(e) {
    e.preventDefault()
    // e.stopPropagation();
    setData({ type: 'SHOW_DEL_C_INIT' })

    const [file] = e.target.files || e.dataTransfer.files
    const reader = new FileReader()
    reader.readAsBinaryString(file)

    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      setfront_preview(`data:image/jpg;base64,${fileRes}`)
      const testImg = toArrayBuffer(fileRes)
      let imgURL = await callApi(testImg)
      setCNICfront(imgURL)
      setData({ type: 'SHOW_DEL_C_END' })
    }

    reader.onerror = () => {
      // console.log('There is a problem while uploading...')
    }
  }

  // upload CNIC Back-side images

  function CNIC_back_upload(e) {
    e.preventDefault()
    e.stopPropagation()
    setData({ type: 'SHOW_DEL_B_INIT' })
    const [file] = e.target.files || e.dataTransfer.files
    const reader = new FileReader()
    reader.readAsBinaryString(file)

    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      setback_preview(`data:image/jpg;base64,${fileRes}`)
      const testImg = toArrayBuffer(fileRes)
      let imgURL = await callApi(testImg)
      setCNICback(imgURL)
      setData({ type: 'SHOW_DEL_B_END' })
    }

    reader.onerror = () => {
      // console.log('There is a problem while uploading...')
    }
  }

  // upload img api call function.
  const callApi = async (imgBuffer) => {
    // console.log(imgBuffer);
    let apiData

    const data = imgBuffer
    await axios
      .post(`${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${params.id}/type/new`, data, {
        headers: API_CONSTANTS.imgheaders(),
      })
      .then((r) => {
        // console.log(r.data.response.url)
        apiData = r.data.response.url
      })
      .catch((e) => console.log())

    return apiData
  }

  // --------  This function is for call the Toast message  ------
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  const handle_Remove_experince_Detailes = (e) => {
    const name = e.target.getAttribute('data-id')
    const index = parseInt(name)
    setExperince_Detailes([
      ...experince_Detailes.slice(0, index),
      ...experince_Detailes.slice(index + 1),
    ])
  }
  const handle_Remove_education_Detailes = (e) => {
    const name = e.target.getAttribute('data-id')
    const index = parseInt(name)
    setEaducation_Detailes([
      ...education_Detailes.slice(0, index),
      ...education_Detailes.slice(index + 1),
    ])
    // console.log(experince_Detailes);
  }
  const handle_Remove_certification_Detailes = (e) => {
    const name = e.target.getAttribute('data-id')
    const index = parseInt(name)
    setCertification_Detailes([
      ...certificationc_Detailes.slice(0, index),
      ...certificationc_Detailes.slice(index + 1),
    ])
    // console.log(experince_Detailes);
  }
  // update data modal's
  const handle_update_exp_Detailes = (e) => {
    setData({ type: 'SHOW_UPDATE_EXP_MODAL_INIT' })
    const index = e.target.getAttribute('data-id')
    setIndex_update(index)
  }
  const handle_update_edu_Detailes = (e) => {
    setData({ type: 'SHOW_UPDATE_EDU_MODAL_INIT' })
    const index = e.target.getAttribute('data-id')
    setIndex_update(index)
  }
  const handle_update_cre_Detailes = (e) => {
    setData({ type: 'SHOW_UPDATE_CER_MODAL_INIT' })
    const index = e.target.getAttribute('data-id')
    setIndex_update(index)
  }

  //upload experince document
  const handle_Upload_Experience_File = (e) => {
    setDocUploadSpin('spinner-border spinner-border-sm')
    setData({ type: 'DOC_UPLOAD_END' })
    e.preventDefault()
    setDoc_name(e.target.files[0].name)
    console.log(e.target.files[0].name)
    const [file] = e.target.files || e.dataTransfer.files

    uploadExperienceFile(file)
  }

  const uploadExperienceFile = (file) => {
    // preview img
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    //preview onload function.
    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      const binaryImg = toArrayBuffer(fileRes)
      await upload_experince_document_api(binaryImg)
    }
  }

  const upload_experince_document_api = async (imgBuffer) => {
    const data = imgBuffer
    await axios
      .post(`${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${params.id}/type/new`,
       data, {
        headers: API_CONSTANTS.imgheaders(),
      })
      .then((r) => {
        console.log(r.data.response.url)
        setAddExperince({
          ...addExperince,
          url: r.data.response.url,
        })
        setData({ type: 'DOC_UPLOAD_INIT' })
      })
      .catch((e) => {
        console.log(e + 'stuck')
        setDocUploadSpin('')
      })
  }
  // upload education document
  const handle_Upload_Education_File = (e) => {
    setEduDocUploadSpin('spinner-border spinner-border-sm')
    setData({ type: 'EDU_DOC_UPLOAD_END' })
    e.preventDefault()
    setEdu_Doc_name(e.target.files[0].name)
    console.log(e.target.files[0].name)
    const [file] = e.target.files || e.dataTransfer.files
    uploadEducationFile(file)
  }
  const uploadEducationFile = (file) => {
    // preview img
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    //preview onload function.
    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      const binaryImg = toArrayBuffer(fileRes)
      await upload_Education_document_api(binaryImg)
    }
  }

  const upload_Education_document_api = async (imgBuffer) => {

    const data = imgBuffer
    await axios
      .post(`${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${params.id}/type/new`, data, {
        headers: API_CONSTANTS.imgheaders(),
      })
      .then((r) => {
        setAddEducation({ ...addEducation, url: r.data.response.url })
        setData({ type: 'EDU_DOC_UPLOAD_INIT' })
      })
      .catch((e) => {
        console.log()
        setEduDocUploadSpin('')
      })
  }
  const handle_Upload_Certification_File = (e) => {
    setCerdocUploadSpin('spinner-border spinner-border-sm')
    setData({ type: 'CER_DOC_UPLOAD_END' })
    e.preventDefault()
    setCer_doc_name(e.target.files[0].name)
    console.log(e.target.files[0].name)
    const [file] = e.target.files || e.dataTransfer.files

    uploadCertificationFile(file)
  }

  const uploadCertificationFile = (file) => {
    // preview img
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    //preview onload function.
    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      const binaryImg = toArrayBuffer(fileRes)
      await upload_Certification_document_api(binaryImg)
    }
  }

  const upload_Certification_document_api = async (imgBuffer) => {
    const data = imgBuffer
    await axios
      .post(`${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${params.id}/type/new`, data, {
        headers: API_CONSTANTS.imgheaders(),
      })
      .then((r) => {
        setAddCertification({ ...addCertification, url: r.data.response.url })
        setData({ type: 'CER_DOC_UPLOAD_INIT' })
      })
      .catch((e) => {
        console.log(e + 'stuck')
        setEduDocUploadSpin('')
      })

    // return apiData;
  }

  // close modal and reset the form values
  const handleClose = () => {
    setData({ type: 'SHOW_END' })
    setData({ type: 'DOC_UPLOAD_END' })
    setDocUploadSpin('')
    setAddExperince({
      ...addExperince,
      title: '',
      employment_type: '',
      company: '',
      location: '',
      start_date: '',
      end_date: '',
      currently_work: false,
      url: '',
    })
    setDoc_name('Upload the related documents in jpg, png or pdf.')
  }

  const handle_Close_Education_Modal = () => {
    setData({ type: 'SHOW2_END' })
    setData({ type: 'EDU_DOC_UPLOAD_END' })
    setEduDocUploadSpin('')
    setEdu_Doc_name('Upload the related documents in jpg, png or pdf.')
    setAddEducation({
      ...addEducation,
      institute_name: '',
      degree: '',
      field_of_study: '',
      location: '',
      start_date: '',
      end_date: '',
      url: '',
    })
  }

  const handle_Close_Certificate_Modal = () => {
    setData({ type: 'SHOW3_END' })
    setData({ type: 'CER_DOC_UPLOAD_END' })
    setCerdocUploadSpin('')
    setCer_doc_name('Upload the related documents in jpg, png or pdf.')
    setAddCertification({
      ...addCertification,
      title: '',
      institute: '',
      field_of_study: '',
      start_date: '',
      end_date: '',
      url: '',
    })
  }

  // currently work here function
  const handle_currently_work_here = () => {
    setAddExperince({
      ...addExperince,
      end_date: '',
      currently_work: !addExperince.currently_work,
    })
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: Fname,
          lastName: Lname,
          dob: DOB,
          experience: parseInt(Experience),
          gender: Gender,
          backupNumber: EphoneNo,
          city: City,
          services: services,
          profileUrl: sendImg,
          cnicFront: CNICfront,
          cnicBack: CNICback,
          liscenseNumber: LicenseNumber,
          email: Email,
          nextOfKin: {
            name: NOK_name,
            phone_no: NOK_phoneNo,
            relation: NOK_relation,
          },
          experiences: experince_Detailes,
          educationalDocuments: education_Detailes,
          certifications: certificationc_Detailes,
          coverLetter: coverletter,
          profileSubmitted: true,
          specialization: "General",
          voluntaryservices: volunteerExperience,
          achievementAwards: achievements,
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Please enter the valid name'),
          lastName: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Please enter the valid name'),
          backupNumber: Yup.string()
            .max(15, "Must be 15 characters or less")
            .required("Please enter the valid Phone No"),
          experience: Yup.number().required('Please select experience'),
          liscenseNumber: Yup.string().required(' Please enter License Number'),
          gender: Yup.string().required('Please enter the select gender'),
          dob: Yup.string().required('Please select Date of Birth'),
          city: Yup.string().required('Please enter the select city'),
          services: Yup.array().required('Please enter the Services'),
          cnicFront: Yup.string().required('Please upload the cnic picture.'),
          cnicBack: Yup.string().required('Please upload the cnic picture.'),
          // email: Yup.string().required('Please Enter the Email'),

          nextOfKin: Yup.object({
            name: Yup.string().required('Enter the fullname'),
            phone_no: Yup.string().required('Enter the phone no'),
            relation: Yup.string().required('Enter the relation'),
          }),
          experiences: Yup.array().min(
            1,
            'Enter atleast one record of experience. '
          ),
          educationalDocuments: Yup.array().min(
            1,
            'Enter atleast one record of education. '
          ),
          coverLetter: Yup.string().required('Please enter a cover letter.'),
        })}
        onSubmit={(values) => {
          setCreateSpin('spinner-border spinner-border-sm')
          // console.log(values)
          axios
            .patch( `${API_CONSTANTS.BASEURL_3}admin/nurses/${params.id}/personalInfo`, values, {
              headers: API_CONSTANTS.headers(),
            })
            .then((e) => {
              // console.log(e.data.response)
              setCreateSpin('')
              handleToast(
                e.data.response.message,
                faCheckCircle,
                'rgba(0,155,148,0.93)',
                'show-success'
              )
              setTimeout(() => props.alert(stage), 2000)
            })
            .catch((e) => {
              console.log(e)
              setCreateSpin('')
            })
        }}

      >
        {(formik) => {
          return (
            <Form>
              <div className={'form-group'}>
                <div className='d-flex' style={{ paddingLeft: '8px' }}>
                  <div className='rapper'>
                    <div
                      className='image'
                      onDragEnter={(e) => handleEnter(e)}
                      onDragLeave={(e) => handleLeave(e)}
                      onDragOver={(e) => handleOver(e)}
                      onDrop={(e) => handleUpload(e)}
                    >
                      {(sendImg === '' ? preview : sendImg) && (
                        <img
                          src={sendImg === '' ? preview : sendImg}
                          className={'profileImg'}
                          alt='profile pic'
                        ></img>
                      )}
                    </div>
                    <div>
                      <div className='text d-flex justify-content-center'>
                        <img src={uploadIcon} alt={'profile-pic'} />
                      </div>
                      <div className='text '>
                        <h6
                          className={
                            'img-heading d-flex justify-content-center mb-0 mt-3'
                          }
                        >
                          Profile Photo
                        </h6>
                        <h6
                          className={
                            'img-heading d-flex justify-content-center'
                          }
                        >
                          Drag / Click to upload
                        </h6>
                      </div>
                    </div>
                  </div>
                  <input
                    id='default-btn'
                    ref={fileInputRef}
                    accept='image/*'
                    onChange={(e) => handleUpload(e)}
                    type='file'
                    hidden
                  />
                  <button
                    id='custom-button'
                    type='button'
                    onClick={() => fileInputRef.current.click()}
                  >
                    Upload Image
                  </button>
                  {show_del ? (
                    <button
                      className={'delete-btn'}
                      type={'button'}
                      title={'img upload successfully'}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </button>
                  ) : null}
                </div>
                <div className={'row mt-5 d-flex'}>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <input
                        autoComplete='off'
                        type='text'
                        name='firstName'
                        value={formik.values.firstName}
                        onChange={(e) => {
                          let element = e.target
                          element.value = element.value.replace(
                            /[^A-Za-z\s]/gi,
                            ''
                          )
                          setFname(e.target.value)
                          formik.handleChange(e)
                        }}
                        validateOnChange={true}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'} for='name'>
                        First Name
                      </label>
                    </div>
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className={'error-field '}>
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <input
                        type='text'
                        autoComplete={'false'}
                        value={formik.values.lastName}
                        name='lastName'
                        onChange={(e) => {
                          setLname(e.target.value)

                          formik.handleChange(e)
                        }}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'}>Last Name</label>
                    </div>
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div className={'error-field '}>
                        {formik.errors.lastName}
                      </div>
                    ) : null}
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group col-md-2'>
                      <div className='input-block'>
                        <select
                          className={'select'}
                          value={formik.values.experience}
                          name='experience'
                          style={{ margintop: '8px' }}
                          onChange={(e) => {
                            setExperience(e.target.value)
                            formik.handleChange(e)
                          }}
                        >
                          <option value=''>Years</option>
                          <option value='1'>Less Then 1 Year</option>
                          <option value='2'>2 Year</option>
                          <option value='3'>3 Year</option>
                          <option value='4'>4 Year</option>
                          <option value='5'>5 Year</option>
                          <option value='6'>6 Year</option>
                          <option value='7'>More Then 6 Year</option>
                        </select>

                        <label className={'p_label placeholder'} id='place'>
                          Experience
                        </label>
                      </div>
                    </div>
                    {formik.touched.experience && formik.errors.experience ? (
                      <div className={'error-field mt-5 ml-2 '}>
                        {formik.errors.experience}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={'row mt-4'}>
                  <div className={'col-md-4'}>
                    <div className='group'>
                      <input
                        type='date'
                        value={formik.values.dob}
                        name='dob'
                        data-date-inline-picker='true'
                        onChange={(e) => {
                          setDOB(e.target.value)
                          formik.handleChange(e)
                        }}
                        max={today_date}
                        style={{ height: '39px' }}
                      />
                      <span className='highlight'></span>
                      <label className={'p_label'}>Date of Birth</label>
                      <span className='bar'></span>
                      {formik.touched.dob && formik.errors.dob ? (
                        <div className={'error-field mt-2'}>
                          {formik.errors.dob}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <div className='input-block'>
                        <select
                          spellCheck='false'
                          className={'select p_label'}
                          value={formik.values.gender}
                          name='gender'
                          style={{ margintop: '8px' }}
                          onChange={(e) => {
                            setGender(e.target.value)
                            formik.handleChange(e)
                          }}
                        >
                          <option value=''></option>
                          <option value='Male'>Male</option>
                          <option value='Female'>Female</option>
                          <option value='Other'>Other</option>
                        </select>

                        <label className={'p_label placeholder'} id='place'>
                          Gender
                        </label>
                      </div>
                    </div>
                    {formik.touched.gender && formik.errors.gender ? (
                      <div className={'error-field mt-5 '}>
                        {formik.errors.gender}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-md-4'} style={{ paddingTop: '2px' }}>
                    <div className='group mb-2'>
                      <input
                        type='text'
                        value={formik.values.backupNumber}
                        name='backupNumber'
                        onChange={(e) => {
                          let element = e.target
                          element.value = element.value.replace(/[^0-9]/g, '')
                          setEphoneNo(e.target.value)
                          formik.handleChange(e)
                        }}
                        autoComplete={'off'}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'}>
                        Backup Contact
                      </label>
                    </div>
                    {formik.touched.backupNumber &&
                      formik.errors.backupNumber ? (
                      <div className={'error-field '}>
                        {formik.errors.backupNumber}
                      </div>
                    ) : null}
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <div className='input-block'>
                        <select
                          spellCheck='false'
                          className={'select'}
                          name='city'
                          value={formik.values.city}
                          onChange={(e) => {
                            setCity(e.target.value)
                            formik.handleChange(e)
                          }}
                        >
                          <option value=''></option>
                          <option value='Lahore'>Lahore</option>
                          <option value='Islamabad'>Islamabad</option>
                          <option value='Mianwali'>Mianwali</option>
                        </select>

                        <label className={'p_label '} id='place'>
                          City
                        </label>
                      </div>
                    </div>
                    {formik.touched.city && formik.errors.city ? (
                      <div className={'error-field mt-5 '}>
                        {formik.errors.city}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={'row mt-3'}>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <input
                        autoComplete='off'
                        type='text'
                        name='email'
                        value={formik.values.email}
                        onChange={(e) => {
                          setEmail(e.target.value)
                          formik.handleChange(e)
                        }}
                        validateOnChange={true}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'} for='name'>
                        Email Address (optional)
                      </label>
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className={'error-field '}>
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className={'col-md-4 '}>
                    <div className='group mb-2'>
                      <input
                        autoComplete='off'
                        type='text'
                        name='licenseNumber'
                        value={formik.values.liscenseNumber}
                        onChange={(e) => {
                          setLicenseNumber(e.target.value)
                          formik.handleChange(e)
                        }}
                        validateOnChange={true}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'} for='name'>
                        License Number
                      </label>
                    </div>
                    {formik.touched.liscenseNumber && formik.errors.liscenseNumber ? (
                      <div className={'error-field mt-5 '}>
                        {formik.errors.liscenseNumber}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={'row mt-3'}>  

                  <div className={'col-md-4 '}>
                    <div className='group mb-2'>
                      <ReactTagInput
                        tags={services.length < 1 ? [] : services}
                        placeholder={` `}
                        editable={true}
                        name='services'
                        value={formik.values.services}
                        removeOnBackspace={true}
                        onChange={(e) => {
                          // console.log(e)
                          setServices(e)
                        }}
                        validateOnChange={true}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'} for='services'>
                        Services
                      </label>
                    </div>
                    {formik.touched.services && formik.errors.services ? (
                      <div className={'error-field '}>
                        {formik.errors.services}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className=' d-flex px-2 mt-4'>
                  <input
                    id='default-btn'
                    ref={cnicFrontside}
                    name='cnicFront'
                    accept='image/*'
                    onChange={(e) => CNIC_front_upload(e)}
                    type='file'
                    hidden
                  />
                  <button
                    id='custom-button'
                    type='button'
                    onClick={() => cnicFrontside.current.click()}
                    className={'m-0'}
                  >
                    Upload
                  </button>
                  <p className={'cnic-text'}>
                    Front side of your CNIC
                    {formik.touched.cnicFront && formik.errors.cnicFront ? (
                      <div className={'error-field'}>
                        {formik.errors.cnicFront}
                      </div>
                    ) : null}
                  </p>

                  {(CNICfront === '' ? front_preview : CNICfront) && (
                    <div
                      className='rapper front-cnic'
                      style={{ marginLeft: '85px' }}
                    >
                      <div className='image front-cnic'>
                        <img
                          src={CNICfront === '' ? front_preview : CNICfront}
                          className={'front-cnic'}
                          alt='profile pic'
                        ></img>
                      </div>
                    </div>
                  )}
                  {show_del_c ? (
                    <button
                      className={'delete-btn'}
                      title={'Img Upload Successfully'}
                      type={'button'}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </button>
                  ) : null}
                </div>
                <div className='d-flex px-2 mt-4'>
                  <input
                    id='default-btn'
                    ref={cnicBackside}
                    accept='image/*'
                    onChange={(e) => CNIC_back_upload(e)}
                    type='file'
                    hidden
                  />
                  <button
                    id='custom-button'
                    type='button'
                    onClick={() => cnicBackside.current.click()}
                    className={'m-0'}
                  >
                    Upload
                  </button>
                  <p className={'cnic-text'}>
                    Back side of your CNIC
                    {formik.touched.cnicBack && formik.errors.cnicBack ? (
                      <div className={'error-field'}>
                        {formik.errors.cnicBack}
                      </div>
                    ) : null}
                  </p>
                  {(CNICback === '' ? back_preview : CNICback) && (
                    <div
                      className='rapper front-cnic'
                      style={{ marginLeft: '85px' }}
                    >
                      <div className='image front-cnic'>
                        <img
                          src={CNICback === '' ? back_preview : CNICback}
                          className={'front-cnic'}
                          alt='profile pic'
                        ></img>
                      </div>
                    </div>
                  )}
                  {show_del_b ? (
                    <button
                      className={'delete-btn'}
                      type={'button'}
                      title={'img upload successfully'}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </button>
                  ) : null}
                </div>
                <h6 className={'mt-4 next-of-kin'}>Next of Kin</h6>
                <div className={'row mt-4 d-flex'}>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <input
                        type='text'
                        id={'nextOfKin.name'}
                        name={'nextOfKin.name'}
                        value={formik.values.nextOfKin.name}
                        onChange={(e) => {
                          let element = e.target
                          element.value = element.value.replace(
                            /[^A-Za-z\s]/gi,
                            ''
                          )
                          setNOK_name(e.target.value)
                          // formik.handleChange(e);
                        }}
                        autoComplete={'off'}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'}>Full Name</label>
                    </div>
                    {formik.touched.nextOfKin &&
                      formik.touched.nextOfKin.name &&
                      formik.errors.nextOfKin &&
                      formik.errors.nextOfKin.name ? (
                      <div className={'error-field '}>
                        {formik.errors.nextOfKin &&
                          formik.errors.nextOfKin.name}
                      </div>
                    ) : null}
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <input
                        type='text'
                        id={'nextOfKin.phone_no'}
                        name={'nextOfKin.phone_no'}
                        value={formik.values.nextOfKin.phone_no}
                        onChange={(e) => {
                          let element = e.target
                          element.value = element.value.replace(/[^0-9]/g, '')
                          setNOK_phoneNo(e.target.value)
                        }}
                        autoComplete={'off'}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'}>Phone No</label>
                    </div>
                    {formik.touched.nextOfKin &&
                      formik.touched.nextOfKin.phone_no &&
                      formik.errors.nextOfKin &&
                      formik.errors.nextOfKin.phone_no ? (
                      <div className={'error-field'}>
                        {formik.errors.nextOfKin &&
                          formik.errors.nextOfKin.phone_no}
                      </div>
                    ) : null}
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group col-md-7'>
                      <div className='input-block'>
                        <select
                          className={'select'}
                          value={formik.values.nextOfKin.relation}
                          name='nextOfKin.relation'
                          id={'nextOfKin.relation'}
                          onChange={(e) => {
                            setNOK_relation(e.target.value)
                          }}
                        >
                          <option value=''></option>
                          <option value='father'>Father</option>
                          <option value='son'>Son</option>
                          <option value='Other'>Other</option>
                        </select>

                        <label className={'p_label placeholder'} id='place'>
                          Relation
                        </label>
                      </div>
                    </div>
                    {formik.touched.nextOfKin &&
                      formik.touched.nextOfKin.relation &&
                      formik.errors.nextOfKin &&
                      formik.errors.nextOfKin.relation ? (
                      <div className={'error-field mt-5 ml-2'}>
                        {formik.errors.nextOfKin &&
                          formik.errors.nextOfKin.relation}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='row py-3 ml-3 gutter-flx'>
                  <div className='col-md-8 col-sm-12 border-bottom py-2'>
                    <div className={'d-flex justify-content-between w-100'}>
                      <span>Experience</span>
                      <span>
                        <FontAwesomeIcon
                          icon={faPlus}
                          onClick={handlebalShow}
                          className={'fa-lpus'}
                        />
                      </span>
                    </div>

                    {formik.values.experiences.length < 1 ? [] : formik.values.experiences.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className={
                              'd-flex justify-content-between w-100 py-1'
                            }
                          >
                            <div className={'col-md-10 p-0'}>
                              <h6 className={'m-0 title-heading '}>
                                {data.title}
                              </h6>
                              <p className={'m-0 sub-title-heading py-1'}>
                                {data.company} |{' '}
                                {moment(data.start_date).format('MMMM YYYY')} -{' '}
                                {data.currently_work
                                  ? 'Currently Work here.'
                                  : moment(data.end_date).format('MMMM YYYY')}
                              </p>
                              <p className={'m-0 sub-title-heading '}>
                                {data.location}
                              </p>
                            </div>
                            <div
                              className={
                                'col-md-2 p-0 justify-content-end d-flex'
                              }
                            >
                              <span>
                                <img
                                  src={edit_svg_icon}
                                  width={15}
                                  height={16}
                                  className={'mx-3'}
                                  alt={'edit-icon'}
                                  data-id={index}
                                  onClick={handle_update_exp_Detailes}
                                />
                                <img
                                  src={del_svg_icon}
                                  width={16}
                                  height={16}
                                  data-id={index}
                                  onClick={handle_Remove_experince_Detailes}
                                  alt={'delete-icon'}
                                />
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                  {formik.touched.experiences && formik.errors.experiences ? (
                    <>
                      <br />
                      <div className={'error-field col-md-8 p-0'}>
                        {formik.errors.experiences}
                      </div>
                    </>
                  ) : null}
                </div>
                <div className='row py-3 gutter-flx'>
                  <div className='col-md-8 col-sm-12 border-bottom py-2'>
                    <div className={'justify-content-between d-flex'}>
                      <span>Education</span>
                      <span>
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={'fa-lpus'}
                          onClick={() => setData({ type: 'SHOW2_INIT' })}
                        />
                      </span>
                    </div>
                    {formik.values.educationalDocuments.length < 1 ? [] : formik.values.educationalDocuments.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className={
                              'd-flex justify-content-between w-100 py-1'
                            }
                          >
                            <div className={'col-md-10 p-0'}>
                              <h6 className={'m-0 title-heading '}>
                                {data.institute_name}
                              </h6>
                              <p className={'m-0 sub-title-heading py-1'}>
                                {data.degree} |{' '}
                                {moment(data.start_date).format('MMMM YYYY')} -{' '}
                                {moment(data.end_date).format('MMMM YYYY')}
                              </p>
                              <p className={'m-0 sub-title-heading '}>
                                {data.location}
                              </p>
                            </div>
                            <div
                              className={
                                'col-md-2 p-0 justify-content-end d-flex'
                              }
                            >
                              <span>
                                <img
                                  src={edit_svg_icon}
                                  width={15}
                                  height={16}
                                  className={'mx-3'}
                                  data-id={index}
                                  onClick={handle_update_edu_Detailes}
                                  alt={'edit-icon'}
                                />
                                <img
                                  src={del_svg_icon}
                                  width={16}
                                  height={16}
                                  data-id={index}
                                  onClick={handle_Remove_education_Detailes}
                                  alt={'delete-icon'}
                                />
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                  {formik.touched.educationalDocuments &&
                    formik.errors.educationalDocuments ? (
                    <>
                      <br />
                      <div className={'error-field col-md-8 p-0'}>
                        {formik.errors.educationalDocuments}
                      </div>
                    </>
                  ) : null}
                </div>
                <div className='row py-3 gutter-flx'>
                  <div className='col-md-8 col-sm-12 border-bottom py-2'>
                    <div className={'justify-content-between d-flex w-100'}>
                      <span>Certifications</span>
                      <span>
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={'fa-lpus'}
                          onClick={() => setData({ type: 'SHOW3_INIT' })}
                        />
                      </span>
                    </div>
                    {formik.values.certifications.length < 1 ? [] : formik.values.certifications.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className={
                              'd-flex justify-content-between w-100 py-1'
                            }
                          >
                            <div className={'col-md-10 p-0'}>
                              <h6 className={'m-0 title-heading '}>
                                {data.title}
                              </h6>
                              <p className={'m-0 sub-title-heading py-1'}>
                                {data.institute} |{' '}
                                {moment(data.start_date).format('MMMM YYYY')} -{' '}
                                {moment(data.end_date).format('MMMM YYYY')}
                              </p>
                              <p className={'m-0 sub-title-heading '}>
                                {data.field_of_study}
                              </p>
                            </div>
                            <div
                              className={
                                'col-md-2 p-0 justify-content-end d-flex'
                              }
                            >
                              <span>
                                <img
                                  src={edit_svg_icon}
                                  width={15}
                                  height={16}
                                  className={'mx-3'}
                                  alt={'edit-icon'}
                                  data-id={index}
                                  onClick={handle_update_cre_Detailes}
                                />
                                <img
                                  src={del_svg_icon}
                                  width={16}
                                  height={16}
                                  data-id={index}
                                  onClick={handle_Remove_certification_Detailes}
                                  alt={'delete-icon'}
                                />
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
                <div className='row py-3 gutter-flx'>
                  <div className='col-md-8 col-sm-12 border-bottom py-2'>
                    <div className={'justify-content-between d-flex'}>
                      <span>Volunteer Experience (optional)</span>
                      <span>
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={'fa-lpus'}
                          onClick={() => setData({ type: 'SHOW4_INIT' })}
                        />
                      </span>
                    </div>
                    {formik.values.voluntaryservices.length < 1 ? [] : formik.values.voluntaryservices.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className={
                              'd-flex justify-content-between w-100 py-1'
                            }
                          >
                            <div className={'col-md-10 p-0'}>
                              <h6 className={'m-0 title-heading '}>
                                {data.role}
                              </h6>
                              <p className={'m-0 sub-title-heading py-1'}>
                                {data.organization} | {data.start_date} -{' '}
                                {data.end_date}
                              </p>
                              <p className={'m-0 sub-title-heading '}>
                                {data.cause}
                              </p>
                            </div>
                            <div
                              className={
                                'col-md-2 p-0 justify-content-end d-flex'
                              }
                            >
                              <span>
                                <img
                                  src={edit_Icon}
                                  width={15}
                                  height={16}
                                  className={'mx-3'}
                                  alt={'edit-icon'}
                                  data-id={index}
                                  onClick={handle_update_volunteer_Detailes}
                                />
                                <img
                                  src={delete_Icon}
                                  width={16}
                                  height={16}
                                  data-id={index}
                                  onClick={handle_Remove_volunteer_Detailes}
                                  alt={'delete-icon'}
                                />
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
                <div className='row py-3 gutter-flx'>
                  <div className='col-md-8 col-sm-12 border-bottom py-2'>
                    <div className={'justify-content-between d-flex'}>
                      <span>Achievements (optional)</span>
                      <span>
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={'fa-lpus'}
                          onClick={() => setData({ type: 'SHOW5_INIT' })}
                        />
                      </span>
                    </div>
                    {formik.values.achievementAwards.length < 1 ? [] : formik.values.achievementAwards.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className={
                              'd-flex justify-content-between w-100 py-1'
                            }
                          >
                            <div className={'col-md-10 p-0'}>
                              <h6 className={'m-0 title-heading '}>
                                {data.title}
                              </h6>
                              <p className={'m-0 sub-title-heading py-1'}>
                                {data.associated_with} | {data.issue_date}
                              </p>
                              <p className={'m-0 sub-title-heading '}>
                                {data.issuer}
                              </p>
                            </div>
                            <div
                              className={
                                'col-md-2 p-0 justify-content-end d-flex'
                              }
                            >
                              <span>
                                <img
                                  src={edit_Icon}
                                  width={15}
                                  height={16}
                                  className={'mx-3'}
                                  alt={'edit-icon'}
                                  data-id={index}
                                  onClick={handle_update_achievement_Detailes}
                                />
                                <img
                                  src={delete_Icon}
                                  width={16}
                                  height={16}
                                  data-id={index}
                                  onClick={handle_Remove_achievement_Detailes}
                                  alt={'delete-icon'}
                                />
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
                <div className='row py-3 border-0 gutter-flx'>
                  <div className='col-md-8 border-0 p-0 pt-4'>
                    <div className={'d-flex'} style={{ position: 'relative' }}>
                      <span className={'cover-letter'}>
                        {formik.values.coverLetter.length}/{1000}
                      </span>
                      <textarea
                        className='form-control'
                        placeholder={'Cover letter'}
                        style={{ resize: 'none' }}
                        maxLength={1000}
                        value={coverletter}
                        rows='7'
                        onChange={(e) => {
                          setCoverletter(e.target.value)
                        }}
                      />
                    </div>
                    {formik.touched.coverLetter && formik.errors.coverLetter ? (
                      <>
                        <div className={'error-field col-md-8 p-0'}>
                          {formik.errors.coverLetter}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className={'card-footer bg-white border-0 px-0'}>
                  <div className={'  py-4'}>
                    <button className={'btn btn-save-Procced'} type={'submit'}>
                      <span className={Createspin} />
                      Save & Proceed
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Experience</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              title: addExperince.title,
              employment_type: addExperince.employment_type,
              company: addExperince.company,
              location: addExperince.location,
              start_date: addExperince.start_date,
              end_date: addExperince.end_date,
              currently_work: addExperince.currently_work,
              url: addExperince.url,
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the valid job title'),
              employment_type: Yup.string().required(
                'Please select the valid job type'
              ),
              company: Yup.string().required('Please enter the company name'),
              location: Yup.string().required('Please enter the location'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().when('currently_work', {
                is: (currently_work) => currently_work === true,
                then: Yup.string(),
                otherwise: Yup.string().required('Please enter the end date'),
              }),
              url: Yup.string().required('Please upload image'),
            })}
            onSubmit={(values) => {
              console.log(values)
              let data = values
              for (var propName in data) {
                if (data[propName] === '' || data[propName] === false) {
                  delete data[propName]
                }
              }
              console.log(data)
              setExperince_Detailes((oldArray) => [...oldArray, data])
              handleClose()
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Senior Registrar'}
                          value={formik.values.title}
                          onChange={(e) => {
                            let element = e.target
                            element.value = element.value.replace(
                              /[^A-Za-z\s]/gi,
                              ''
                            )
                            setAddExperince({
                              ...addExperince,
                              title: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={'col-md-12 '}
                      style={{
                        overflow: 'hidden',
                        height: '53px',
                        paddingTop: '6px',
                      }}
                    >
                      <div className='group'>
                        <div className='input-block'>
                          <select
                            className={'select'}
                            name={'employment_type'}
                            value={formik.values.employment_type}
                            style={{ margintop: '8px' }}
                            onChange={(e) => {
                              setAddExperince({
                                ...addExperince,
                                employment_type: e.target.value,
                              })
                            }}
                          >
                            <option value=''></option>
                            <option value='full_time'>Full Time</option>
                            <option value='part_time'>Part Time</option>
                          </select>

                          <label className={'p_label placeholder'} id='place'>
                            {' '}
                            Employment Type
                          </label>
                        </div>
                      </div>
                    </div>
                    {formik.touched.employment_type &&
                      formik.errors.employment_type ? (
                      <div className={'error-field ml-2'}>
                        {formik.errors.employment_type}
                      </div>
                    ) : null}
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Hameed Latif Hospital'}
                          name={'company'}
                          value={formik.values.company}
                          onChange={(e) => {
                            let element = e.target
                            element.value = element.value.replace(
                              /[^A-Za-z\s]/gi,
                              ''
                            )
                            setAddExperince({
                              ...addExperince,
                              company: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Company *</label>
                        {formik.touched.company && formik.errors.company ? (
                          <div className={'error-field'}>
                            {formik.errors.company}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Lahore, Pakistan'}
                          name={'location'}
                          value={formik.values.location}
                          onChange={(e) => {
                            setAddExperince({
                              ...addExperince,
                              location: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Location</label>
                        {formik.touched.location && formik.errors.location ? (
                          <div className={'error-field'}>
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => {
                            setAddExperince({
                              ...addExperince,
                              start_date: e.target.value,
                            })
                          }}
                          max={today_date}
                          autoComplete={'off'}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                          formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          disabled={addExperince.currently_work}
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => {
                            setAddExperince({
                              ...addExperince,
                              end_date: e.target.value,
                            })
                          }}
                          min={formik.values.start_date}
                          max={today_date}
                          style={{ height: '39px' }}
                          autoComplete={'off'}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>End Date *</label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input
                          type='checkbox'
                          onClick={handle_currently_work_here}
                          className={'checkbox'}
                        />
                        <label
                          className={'ml-5'}
                          style={{ marginLeft: '10px' }}
                        >
                          I currently work here
                        </label>
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input
                          id='default-btn'
                          ref={exp_InputRef}
                          name={'url'}
                          accept='image/*'
                          onChange={(e) => handle_Upload_Experience_File(e)}
                          type='file'
                          hidden
                        />
                        <button
                          id='custom-button'
                          onClick={() => exp_InputRef.current.click()}
                          className='px-3 ml-0 mb-0'
                          type='button'
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid #009b94',
                            color: '#009b94',
                            borderRadius: '6px',
                          }}
                        >
                          upload
                        </button>

                        <label
                          className={'m-0 ml-2 py-2'}
                          style={{ fontSize: '14px', fontWeight: '400' }}
                        >
                          {doc_name}
                          {formik.touched.url && formik.errors.url ? (
                            <div className={'error-field'}>
                              {formik.errors.url}
                            </div>
                          ) : null}
                        </label>
                        {doc_upload ? (
                          <label className={'m-0 ml-2 py-2'}>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </label>
                        ) : (
                          <span className={docUploadSpin + ' m-2'} />
                        )}
                      </div>
                    </div>

                    <div className={'col-md-12 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/*  Add Education Modal */}
      <Modal show={show2} onHide={handle_Close_Education_Modal}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Education</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              institute_name: addEducation.institute_name,
              degree: addEducation.degree,
              field_of_study: addEducation.field_of_study,
              location: addEducation.location,
              start_date: addEducation.start_date,
              end_date: addEducation.end_date,
              url: addEducation.url,
            }}
            validationSchema={Yup.object({
              institute_name: Yup.string()
                .max(50, 'Must be 15 characters or less')
                .required('Please enter the valid job title'),
              degree: Yup.string().required('Please select the valid job type'),
              field_of_study: Yup.string().required(
                'Please select the valid job type'
              ),
              location: Yup.string().required('Please enter the location'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
              url: Yup.string().required('Please upload image'),
            })}
            onSubmit={(values) => {
              console.log(values)
              setEaducation_Detailes((oldArray) => [...oldArray, values])
              setData({ type: 'SHOW2_END' })
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Agh Khan Medical University'}
                          name={'institute_name'}
                          value={formik.values.institute_name}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              institute_name: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Institute *</label>
                        {formik.touched.institute_name &&
                          formik.errors.institute_name ? (
                          <div className={'error-field'}>
                            {formik.errors.institute_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Hameed Latif Hospital'}
                          name={'degree'}
                          value={formik.values.degree}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              degree: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Degree *</label>
                        {formik.touched.degree && formik.errors.degree ? (
                          <div className={'error-field'}>
                            {formik.errors.degree}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Dentistry'}
                          name={'field_of_study'}
                          value={formik.values.field_of_study}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              field_of_study: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Field of Study </label>
                        {formik.touched.field_of_study &&
                          formik.errors.field_of_study ? (
                          <div className={'error-field'}>
                            {formik.errors.field_of_study}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Lahore, Pakistan'}
                          name={'location'}
                          value={formik.values.location}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              location: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Location</label>
                        {formik.touched.location && formik.errors.location ? (
                          <div className={'error-field'}>
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              start_date: e.target.value,
                            })
                          }}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                          formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => {
                            setAddEducation({
                              ...addEducation,
                              end_date: e.target.value,
                            })
                          }}
                          min={formik.values.start_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>
                          End Date (or expected) *
                        </label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input
                          id='default-btn'
                          ref={edu_InputRef}
                          name={'url'}
                          accept='image/*'
                          onChange={(e) => handle_Upload_Education_File(e)}
                          type='file'
                          hidden
                        />
                        <button
                          id='custom-button'
                          onClick={() => edu_InputRef.current.click()}
                          className='px-3 ml-0 mb-0'
                          type='button'
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid #009b94',
                            color: '#009b94',
                            borderRadius: '6px',
                          }}
                        >
                          upload
                        </button>

                        <label
                          className={'m-0 ml-2 py-2'}
                          style={{ fontSize: '14px', fontWeight: '400' }}
                        >
                          {edu_doc_name}
                          {formik.touched.url && formik.errors.url ? (
                            <div className={'error-field'}>
                              {formik.errors.url}
                            </div>
                          ) : null}
                        </label>
                        {edu_doc_upload ? (
                          <label className={'m-0 ml-2 py-2'}>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </label>
                        ) : (
                          <span className={edudocUploadSpin + ' m-2'} />
                        )}
                      </div>
                    </div>

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/*  Add certifications Modal */}
      <Modal show={show3} onHide={handle_Close_Certificate_Modal}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Certification</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              title: addCertification.title,
              institute: addCertification.institute,
              field_of_study: addCertification.field_of_study,
              start_date: addCertification.start_date,
              end_date: addCertification.end_date,
              url: addCertification.url,
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the valid job title'),
              institute: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the Institute name'),
              field_of_study: Yup.string().required(
                'Please enter the field of study'
              ),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              console.log(values)
              setCertification_Detailes((oldArray) => [...oldArray, values])
              setData({ type: 'SHOW3_END' })
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Registered Medical Assistant '}
                          value={formik.values.title}
                          onChange={(e) => {
                            setAddCertification({
                              ...addCertification,
                              title: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'institute'}
                          placeholder={'Ex: Agh Khan Medical University'}
                          value={formik.values.institute}
                          onChange={(e) => {
                            setAddCertification({
                              ...addCertification,
                              institute: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Institute *</label>
                        {formik.touched.institute && formik.errors.institute ? (
                          <div className={'error-field'}>
                            {formik.errors.institute}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Dentistry'}
                          name={'field_of_study'}
                          value={formik.values.field_of_study}
                          onChange={(e) => {
                            setAddCertification({
                              ...addCertification,
                              field_of_study: e.target.value,
                            })
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Field of Study</label>
                        {formik.touched.field_of_study &&
                          formik.errors.field_of_study ? (
                          <div className={'error-field'}>
                            {formik.errors.field_of_study}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => {
                            setAddCertification({
                              ...addCertification,
                              start_date: e.target.value,
                            })
                          }}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                          formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          name='end_date'
                          type='date'
                          min={formik.values.start_date}
                          value={formik.values.end_date}
                          onChange={(e) => {
                            setAddCertification({
                              ...addCertification,
                              end_date: e.target.value,
                            })
                          }}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>
                          End Date (or expected) *
                        </label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input
                          id='default-btn'
                          ref={cer_InputRef}
                          name={'url'}
                          accept='image/*'
                          onChange={(e) => handle_Upload_Certification_File(e)}
                          type='file'
                          hidden
                        />
                        <button
                          id='custom-button'
                          onClick={() => cer_InputRef.current.click()}
                          className=' ml-0 mb-0'
                          type='button'
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid #009b94',
                            color: '#009b94',
                            borderRadius: '6px',
                          }}
                        >
                          upload
                        </button>

                        <label
                          className={'m-0 ml-5 py-2'}
                          style={{ fontSize: '14px', fontWeight: '400' }}
                        >
                          {cer_doc_name}
                          {formik.touched.url && formik.errors.url ? (
                            <div className={'error-field'}>
                              {formik.errors.url}
                            </div>
                          ) : null}
                        </label>
                        {cer_doc_upload ? (
                          <label className={'m-0 ml-2 py-2'}>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </label>
                        ) : (
                          <span className={cerdocUploadSpin + ' m-2'} />
                        )}
                      </div>
                    </div>

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Add Volunteer Experience Modal*/}
      <Modal show={show4} onHide={handleClose2}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Volunteer Experience</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              organization: '',
              role: '',
              cause: '',
              start_date: '',
              end_date: '',
            }}
            validationSchema={Yup.object({
              organization: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the organization'),
              role: Yup.string().required('Please select the job role'),
              cause: Yup.string().required('Please select the cause'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              setVolunteerExperience((oldArray) => [...oldArray, values])
              setData({ type: 'SHOW4_END' })
              // handle_update_Admin(values);
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'organization'}
                          placeholder={'Ex: Red Cross'}
                          value={formik.values.organization}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Organization *</label>
                        {formik.touched.organization &&
                          formik.errors.organization ? (
                          <div className={'error-field'}>
                            {formik.errors.organization}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'role'}
                          placeholder={'Ex: Fundraising Volunteer'}
                          value={formik.values.role}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Role *</label>
                        {formik.touched.role && formik.errors.role ? (
                          <div className={'error-field'}>
                            {formik.errors.role}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Human Welfare'}
                          name={'cause'}
                          value={formik.values.cause}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Cause </label>
                        {formik.touched.cause && formik.errors.cause ? (
                          <div className={'error-field'}>
                            {formik.errors.cause}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => formik.handleChange(e)}
                          // onBlur={(e) => (e.target.type = 'text')}
                          // onFocus={(e) => (e.target.type = 'date')}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                          formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => formik.handleChange(e)}
                          onBlur={(e) => (e.target.type = 'text')}
                          onFocus={(e) => (e.target.type = 'date')}
                          min={formik.values.start_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>End Date *</label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input type='checkbox' className={'checkbox'} />
                        <label
                          className={'ml-2'}
                          style={{ marginLeft: '10px' }}
                        >
                          I am currently volunteering in this role
                        </label>
                      </div>
                    </div> */}

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* Add Achievement Modal*/}
      <Modal show={show5} onHide={handleClose3}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Achievement</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              title: '',
              associated_with: '',
              issuer: '',
              issue_date: '',
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the achiever award title'),
              associated_with: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the associated Institute name'),
              issuer: Yup.string().required(
                'Please enter the issuer Institute name'
              ),
              issue_date: Yup.string().required('Please enter the issue date'),
            })}
            onSubmit={(values) => {
              setAchievements((oldArray) => [...oldArray, values])
              setData({ type: 'SHOW5_END' })
              // handle_update_Admin(values);
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Registered Medical Assistant '}
                          value={formik.values.title}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'associated_with'}
                          placeholder={
                            'Ex: Nishtar Medical College MBBS Program'
                          }
                          value={formik.values.associated_with}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Associated With</label>
                        {formik.touched.associated_with &&
                          formik.errors.associated_with ? (
                          <div className={'error-field'}>
                            {formik.errors.associated_with}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Nishtar Medical College'}
                          name={'issuer'}
                          value={formik.values.issuer}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Issuer *</label>
                        {formik.touched.issuer && formik.errors.issuer ? (
                          <div className={'error-field'}>
                            {formik.errors.issuer}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='issue_date'
                          value={formik.values.issue_date}
                          onChange={(e) => formik.handleChange(e)}
                          // onBlur={(e) => (e.target.type = "text")}
                          // onFocus={(e) => (e.target.type = "date")}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Issue Date *</label>
                        <span className='bar'></span>
                        {formik.touched.issue_date &&
                          formik.errors.issue_date ? (
                          <div className={'error-field'}>
                            {formik.errors.issue_date}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* ------------------------- Update Modal Code  ------------------------------- */}

      {/*  update Experience Modal */}
      <Modal show={Show_update_exp_modal} onHide={close_update_exp_modal}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Experience</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              title:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].title,
              employment_type:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].employment_type,
              company:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].company,
              location:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].location,
              start_date:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].start_date,
              end_date:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].end_date,
              currently_work:
                experince_Detailes[index_update] &&
                experince_Detailes[index_update].currently_work,
              url: 'https://eshaafi-doctor-image-upload-bucket-us-east-1-stage.s3.us-east-1.amazonaws.com/1642483795.387346.png',
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the valid job title'),
              employment_type: Yup.string().required(
                'Please select the valid job type'
              ),
              company: Yup.string().required('Please enter the company name'),
              location: Yup.string().required('Please enter the location'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              let temp_state = [...experince_Detailes]
              let temp_element = { ...temp_state[index_update] }
              temp_element = values
              temp_state[index_update] = temp_element
              setExperince_Detailes(temp_state)
              setData({ type: 'SHOW_UPDATE_EXP_MODAL_END' })
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Senior Registrar'}
                          value={formik.values.title}
                          onChange={(e) => {
                            let element = e.target
                            element.value = element.value.replace(
                              /[^A-Za-z\s]/gi,
                              ''
                            )
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={'col-md-12 '}
                      style={{
                        overflow: 'hidden',
                        height: '53px',
                        paddingTop: '6px',
                      }}
                    >
                      <div className='group'>
                        <div className='input-block'>
                          <select
                            className={'select'}
                            name={'employment_type'}
                            value={formik.values.employment_type}
                            style={{ margintop: '8px' }}
                            onChange={(e) => {
                              formik.handleChange(e)
                            }}
                          >
                            <option value=''></option>
                            <option value='full_time'>Full Time</option>
                            <option value='part_time'>Part Time</option>
                          </select>
                          <span className='placeholder' id='place'>
                            Employment Type
                          </span>
                        </div>
                      </div>
                    </div>
                    {formik.touched.employment_type &&
                      formik.errors.employment_type ? (
                      <div className={'error-field ml-2'}>
                        {formik.errors.employment_type}
                      </div>
                    ) : null}
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Hameed Latif Hospital'}
                          name={'company'}
                          value={formik.values.company}
                          onChange={(e) => {
                            let element = e.target
                            element.value = element.value.replace(
                              /[^A-Za-z\s]/gi,
                              ''
                            )
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Company *</label>
                        {formik.touched.company && formik.errors.company ? (
                          <div className={'error-field'}>
                            {formik.errors.company}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Lahore, Pakistan'}
                          name={'location'}
                          value={formik.values.location}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Location</label>
                        {formik.touched.location && formik.errors.location ? (
                          <div className={'error-field'}>
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                          max={today_date}
                          autoComplete={'off'}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                          formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          disabled={formik.values.currently_work}
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => formik.handleChange(e)}
                          min={formik.values.start_date}
                          max={today_date}
                          style={{ height: '39px' }}
                          autoComplete={'off'}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>End Date *</label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input
                          type='checkbox'
                          name='currently_work'
                          value={formik.values.currently_work}
                          checked={formik.values.currently_work}
                          onClick={(e) => {
                            formik.handleChange(e)
                          }}
                          className={'checkbox'}
                        />
                        <label>I currently work here</label>
                      </div>
                    </div>

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/*  update Education Modal */}
      <Modal show={Show_update_edu_modal} onHide={close_update_edu_modal}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Education</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              institute_name:
                education_Detailes[index_update] &&
                education_Detailes[index_update].institute_name,
              degree:
                education_Detailes[index_update] &&
                education_Detailes[index_update].degree,
              field_of_study:
                education_Detailes[index_update] &&
                education_Detailes[index_update].field_of_study,
              location:
                education_Detailes[index_update] &&
                education_Detailes[index_update].location,
              start_date:
                education_Detailes[index_update] &&
                education_Detailes[index_update].start_date,
              end_date:
                education_Detailes[index_update] &&
                education_Detailes[index_update].end_date,
              url: 'https://eshaafi-doctor-image-upload-bucket-us-east-1-stage.s3.us-east-1.amazonaws.com/1642483795.387346.png',
            }}
            validationSchema={Yup.object({
              institute_name: Yup.string()
                .max(50, 'Must be 15 characters or less')
                .required('Please enter the valid job title'),
              degree: Yup.string().required('Please select the valid job type'),
              field_of_study: Yup.string().required(
                'Please select the valid job type'
              ),
              location: Yup.string().required('Please enter the location'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              console.log(values)
              let temp_state = [...education_Detailes]
              let temp_element = { ...temp_state[index_update] }
              temp_element = values
              temp_state[index_update] = temp_element
              setEaducation_Detailes(temp_state)
              setData({ type: 'SHOW_UPDATE_EDU_MODAL_END' })
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Agh Khan Medical University'}
                          name={'institute_name'}
                          value={formik.values.institute_name}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Institute *</label>
                        {formik.touched.institute_name &&
                          formik.errors.institute_name ? (
                          <div className={'error-field'}>
                            {formik.errors.institute_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Hameed Latif Hospital'}
                          name={'degree'}
                          value={formik.values.degree}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Degree *</label>
                        {formik.touched.degree && formik.errors.degree ? (
                          <div className={'error-field'}>
                            {formik.errors.degree}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Dentistry'}
                          name={'field_of_study'}
                          value={formik.values.field_of_study}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Field of Study </label>
                        {formik.touched.field_of_study &&
                          formik.errors.field_of_study ? (
                          <div className={'error-field'}>
                            {formik.errors.field_of_study}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Lahore, Pakistan'}
                          name={'location'}
                          value={formik.values.location}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Location</label>
                        {formik.touched.location && formik.errors.location ? (
                          <div className={'error-field'}>
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                          formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          disabled={formik.values.currently_work}
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => formik.handleChange(e)}
                          min={formik.values.start_date}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>End Date *</label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/*  update certifications Modal */}
      <Modal show={Show_update_cer_modal} onHide={close_update_cre_modal}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Certification</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              title:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].title,
              institute:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].institute,
              field_of_study:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].field_of_study,
              start_date:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].start_date,
              end_date:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].end_date,
              url:
                certificationc_Detailes[index_update] &&
                certificationc_Detailes[index_update].url,
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the valid job title'),
              institute: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the Institute name'),
              field_of_study: Yup.string().required(
                'Please enter the field of study'
              ),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              console.log(values)
              let temp_state = [...certificationc_Detailes]
              let temp_element = { ...temp_state[index_update] }
              temp_element = values
              temp_state[index_update] = temp_element
              setCertification_Detailes(temp_state)
              setData({ type: 'SHOW_UPDATE_CER_MODAL_END' })
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className='row py-3 '>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Registered Medical Assistant '}
                          value={formik.values.title}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'institute'}
                          placeholder={'Ex: Agh Khan Medical University'}
                          value={formik.values.institute}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Institute *</label>
                        {formik.touched.institute && formik.errors.institute ? (
                          <div className={'error-field'}>
                            {formik.errors.institute}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Dentistry'}
                          name={'field_of_study'}
                          value={formik.values.field_of_study}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Field of Study</label>
                        {formik.touched.field_of_study &&
                          formik.errors.field_of_study ? (
                          <div className={'error-field'}>
                            {formik.errors.field_of_study}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => formik.handleChange(e)}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                          formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          name='end_date'
                          type='date'
                          min={formik.values.start_date}
                          value={formik.values.end_date}
                          onChange={(e) => formik.handleChange(e)}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>
                          End Date (or expected) *
                        </label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Update Volunteer Experience Modal*/}
      <Modal
        show={Show_update_volunteer_modal}
        onHide={close_update_volunteer_modal}
      >
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Volunteer Experience</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              organization:
                volunteerExperience[index_update] &&
                volunteerExperience[index_update].organization,
              role:
                volunteerExperience[index_update] &&
                volunteerExperience[index_update].role,
              cause:
                volunteerExperience[index_update] &&
                volunteerExperience[index_update].cause,
              start_date:
                volunteerExperience[index_update] &&
                volunteerExperience[index_update].start_date,
              end_date:
                volunteerExperience[index_update] &&
                volunteerExperience[index_update].end_date,
              // currently_volunteer: "",
            }}
            validationSchema={Yup.object({
              organization: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the organization'),
              role: Yup.string().required('Please select the job role'),
              cause: Yup.string().required('Please select the cause'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              // setVolunteerExperience((oldArray) => [...oldArray, values]);
              let temp_state = [...volunteerExperience]
              let temp_element = { ...temp_state[index_update] }
              temp_element = values
              temp_state[index_update] = temp_element
              setVolunteerExperience(temp_state)
              setData({ type: 'SHOW_UPDATE_VOLUNTEER_MODAL_END' })
              // handle_update_Admin(values);
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'organization'}
                          placeholder={'Ex: Red Cross'}
                          value={formik.values.organization}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Organization *</label>
                        {formik.touched.organization &&
                          formik.errors.organization ? (
                          <div className={'error-field'}>
                            {formik.errors.organization}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'role'}
                          placeholder={'Ex: Fundraising Volunteer'}
                          value={formik.values.role}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Role *</label>
                        {formik.touched.role && formik.errors.role ? (
                          <div className={'error-field'}>
                            {formik.errors.role}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Human Welfare'}
                          name={'cause'}
                          value={formik.values.cause}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Cause </label>
                        {formik.touched.cause && formik.errors.cause ? (
                          <div className={'error-field'}>
                            {formik.errors.cause}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => formik.handleChange(e)}
                          onBlur={(e) => (e.target.type = 'text')}
                          onFocus={(e) => (e.target.type = 'date')}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                          formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => formik.handleChange(e)}
                          onBlur={(e) => (e.target.type = 'text')}
                          onFocus={(e) => (e.target.type = 'date')}
                          min={formik.values.start_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>End Date *</label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input type='checkbox' className={'checkbox'} />
                        <label
                          className={'ml-2'}
                          style={{ marginLeft: '10px' }}
                        >
                          I am currently volunteering in this role
                        </label>
                      </div>
                    </div> */}

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Update Achievement Modal*/}
      <Modal
        show={Show_update_achievement_modal}
        onHide={close_update_achievement_modal}
      >
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Achievement</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              title:
                achievements[index_update] && achievements[index_update].title,
              associated_with:
                achievements[index_update] &&
                achievements[index_update].associated_with,
              issuer:
                achievements[index_update] && achievements[index_update].issuer,
              issue_date:
                achievements[index_update] &&
                achievements[index_update].issue_date,
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the achiever award title'),
              associated_with: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the associated Institute name'),
              issuer: Yup.string().required(
                'Please enter the issuer Institute name'
              ),
              issue_date: Yup.string().required('Please enter the issue date'),
            })}
            onSubmit={(values) => {
              // console.log(values)
              // setAchievements((oldArray) => [...oldArray, values]);
              let temp_state = [...achievements]
              let temp_element = { ...temp_state[index_update] }
              temp_element = values
              temp_state[index_update] = temp_element
              setAchievements(temp_state)
              setData({ type: 'SHOW_UPDATE_ACHIEVEMENT_MODAL_END' })
              // handle_update_Admin(values);
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Registered Medical Assistant '}
                          value={formik.values.title}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'associated_with'}
                          placeholder={
                            'Ex: Nishtar Medical College MBBS Program'
                          }
                          value={formik.values.associated_with}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Associated With</label>
                        {formik.touched.associated_with &&
                          formik.errors.associated_with ? (
                          <div className={'error-field'}>
                            {formik.errors.associated_with}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Nishtar Medical College'}
                          name={'issuer'}
                          value={formik.values.issuer}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Issuer *</label>
                        {formik.touched.issuer && formik.errors.issuer ? (
                          <div className={'error-field'}>
                            {formik.errors.issuer}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='issue_date'
                          value={formik.values.issue_date}
                          onChange={(e) => formik.handleChange(e)}
                          // onBlur={(e) => (e.target.type = "text")}
                          // onFocus={(e) => (e.target.type = "date")}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Issue Date *</label>
                        <span className='bar'></span>
                        {formik.touched.issue_date &&
                          formik.errors.issue_date ? (
                          <div className={'error-field'}>
                            {formik.errors.issue_date}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* ------------------------- Toast Message Code  ------------------------------- */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style>{`
    /* form starting stylings ------------------------------- */
.add-doctor-form {
  width: 320px !important;
}

.btn-otp {
  margin: 0px !important;
  margin-left: 16px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  height: 40px !important;
  border: 1px solid #009b94;
  border-radius: 4px;
  letter-spacing: 1.25px;
  color: #009b94;
  background-color: #ffffff;
}

.btn-otp:hover {
  background-color: rgba(0, 155, 148, 0.07) !important;
}

.btn-otp:active {
  outline: none;
}

.btn-otp:focus {
  outline: none;
}
error-feild{
   font-size: 14px;
          letter-spacing: 0.5px;
          color: #e52444;
}
button.search-field {
  padding: 0 15px;
  background-color: #009b94;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.searchInput {
  border: none;
  border-bottom: 1px solid white;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.searchInput:focus {
  border-color: #009b94 !important;
}
.search-field i {
  color: white;
}

.btn-createAccount:not(:disabled) {
  margin: 0px !important;
  background-color: #009b94 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.25px;
  color: #ffffff;
  border: none;
  transition: 0.2s ease-in-out;
}

.btn-createAccount:hover {
  background-color: #99d7d4 !important;
}

.btn-createAccount:disabled {
  background-color: #99d7d4 !important;
  cursor: not-allowed !important;
  margin: 0px !important;
  color: #ffffff;
  border: none;
  transition: 0.2s ease-in-out;
}
.checkbox {
  width: fit-content !important;
  margin-top: 3px;
}

/*.bind-group > select input {*/
/*  border-bottom: none !important;*/
/*}*/

.input-danger,
.input-danger:focus {
  border-bottom: 1px solid #e52444 !important;
}

.error-field {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #e52444;
}

/*  -------------------------------------   Add Doctor CSS   ----------------------------  */

/*.phone_input {*/
/*  width: fit-content;*/
/*  border: 1px none gray;*/
/*  border-bottom-style: solid;*/
/*}*/

.form-backLink {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #1e1e1e;
}
.fa-icon {
  color: #99d7d4 !important;
}

.perv,
.next {
  color: #99d7d4 !important;
}

.perv:hover,
.next:hover {
  color: #009b94 !important;
}

.btn-account {
  background: #009b94;
  border: 1px solid #ffffff;
  padding: 12px 16px;
  color: #ffffff;
  transition: 0.5s ease-in-out;
}

.btn-account:hover {
  background: #006b66;
  border: 1px solid #009b94;
  color: #ffffff;
  transition: 0.5s ease-in-out;
}

.btn-proceed {
  border: none !important;
  color: #009b94;
}

.btn-proceed:hover {
  border: none;
  box-shadow: none !important;
  text-decoration: underline;
  color: rgba(1, 190, 181, 0.87);
}

.d_list {
  color: #1e1e1e;
  font-size: 14px;
}

.d_list:hover {
  color: #1e1e1e;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.card-footer {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/*====================  Input field CSS Styling  ===========================*/

.group {
  position: relative;
  margin-bottom: 25px;
  width: 100% !important;
}

input,
.group select {
  font-size: 15px;
  padding: 12px 4px 2px 0px;
  display: block;
  border: none;
  border-bottom: 1px solid #cccccc;
}

/*.remove-style {*/
/*  border: none !important;*/
/*}*/

input {
  width: 100% !important;
}
.fa-lpus{
color: #99d7d4 !important
}
.fa-lpus:hover{
color: #5ba39f !important;
cursor: pointer;
}
.group select {
      width: 326px;
    /* height: 19px; */
    margin-top: 10px;
    /* padding: 0px; */
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-position-x: 38px;
    background-position-y: 21px;
    background-size: 551px 5px;
    cursor: pointer;
    // border: none;
    position: absolute;
    top: -9px;
    left: 0px;
}

.df-select {
  margin-top: 8px !important;
  background-position-x: 265px !important;
}


.cnic-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #5c5c5c;
  padding-top: 10px;
  margin-bottom: 0px;
  margin-left: 16px;
}

.front-cnic {
  width: 55px !important;
  height: 40px !important;
  border-radius: 2px;
}

.next-of-kin {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #5c5c5c;
  margin-top: 25px !important;
}

input:focus,
select {
  outline: none;
  border-bottom: 1px solid #009b94;
}

/* LABEL ======================================= */
label.p_label {
  color: #8e8e8e;
  font-size: 14px;
  font-weight: 400 !important;
  position: absolute;
  pointer-events: none;
  left: 1px;
  top: -10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label,
.group select:valid ~ label,
.group select:focus ~ label {
  top: -10px;
  font-size: 14px;
  color: #8e8e8e;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after,
.group select:focus ~ .bar:before,
.group select:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;

  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight,
.group select:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #f7f7f7;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #f3f3f3;
  }

  to {
    width: 0;
    background: transparent;
  }
}

.input {
  position: absolute;
  font-size: 20px;
}

.div {
  position: relative;
  overflow: hidden;
}

/* .dropdown-form {
  width: 100%;
  margin: 0 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);
  font-family: "Lato", sans-serif;
}

@media (min-width: 768px) {
  .dropdown{
    width: 600px;
  }
} */

----

.dropdown__select {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 5px;
  overflow: hidden;
  box-shadow: 0 5px 10px 0 rgba(152, 152, 152, 0.6);
  transform: scaleY(0);
  transform-origin: top;
  font-weight: 300;
  transition: 0.2s ease-in-out;
}

.dropdown__select-option {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6;
  transition: 0.3s;
}

.dropdown__select-option:last-of-type {
  border-bottom: 0;
}

.dropdown__select-option:hover {
  background-color: #f9f9f9;
}

.rapper {
  position: relative;
  height: 160px;
  width: 160px;
  border: 1px dashed #cccccc;
  border-radius: 6px;
  background-color: #f6f6f6;
  align-items: center;
  justify-content: center;
  display: flex;
}

.rapper .image {
  position: absolute;
  height: 160px;
  width: 160px;
  align-items: center;
  justify-content: center;
}

.rapper .image img {
  height: 160px;
  width: 160px;
  object-fit: cover;
}

.icon {
  color: #99d7d4;
  margin-right: 90px;
  font-size: 100px;
  margin-bottom: 20px;
  align-items: center;
  position: relative;
  left: 17%;
}

.rapper .text {
  font-size: 15px;
  font-weight: 400;
  /*margin-bottom: 100px;*/
  /*justify-content: center;*/
}

.img-heading {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #5c5c5c;
}

.profileImg {
  border-radius: 6px;
}

#custom-button {
  height: 40px;
  margin-bottom: 100px;
  margin-left: 15px;
  color: #009b94;
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.delete-btn {
  width: 23px;
  height: 25px;
  margin-left: 12px;
  margin-top: 5px;
  border: none;
  outline: none;
  background-color: white;
  color: #ff6760;
}

.delete-btn:hover {
  color: rgba(255, 103, 96, 0.87);
}

#custom-button:focus,
.delete-btn:focus {
  outline: none;
}

.upload-btn {
  color: #009b94;
  background-color: #ffffff;
  border: 1px solid #009b94;
  border-radius: 6px;
  padding: 8px 20px;
}

.upload-btn:focus {
  outline: none;
}

.pra {
  margin-top: 8px;
  color: #5c5c5c;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 10px;
  padding-top: 10px;
}

.upload-bt {
  color: #009b94;
  background-color: #ffffff;
  border: 1px solid #009b94;
  border-radius: 6px;
  padding: 8px 20px;
}

.upload-bt:focus {
  outline: none;
}

.para {
  margin-top: 8px;
  color: #5c5c5c;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 10px;
  padding-top: 10px;
}

.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}

.customModal {
  background: #b2dbbf;
  max-width: 500px;
  width: 100%;
}

.btn-save-Procced,
.btn-save-exit {
  margin: 0px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  height: 40px !important;
  border: 1px solid #009b94;
  border-radius: 4px;
  letter-spacing: 1.25px;
  color: #009b94;
  background-color: #ffffff;
}

.btn-save-exit {
  color: #5c5c5c;
  border: 1px solid #5c5c5c;
}

.title-heading {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #1e1e1e;
  font-weight: 400;
}
.sub-title-heading {
  font-size: 12px;
  color: #5c5c5c;
  font-weight: 400;
}

.react-tag-input__input {
  width: auto !important;
}
.react-tag-input__tag {
  width: auto !important;
  background: #eeeeee !important;
}

.react-tag-input {
  border: none !important;
  border-bottom: 1px solid #cccccc !important;
  border-radius: 0 !important;
  padding: 0.1875em 0 !important;
}

.react-tag-input__tag__remove {
  background: #eeeeee !important;
}

.react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  background-color: #1ea7a1 !important;
}

textarea:focus {
  border-color: #009b94 !important;
}

.cover-letter {
  position: absolute;
  bottom: 4px;
  right: 10px;
  color: #8e8e8e;
}

.btn-white {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  color: #009b94;
  border: 1px solid #009b94;
}

select {
  background-color: white !important;
}
      `}</style>
    </>
  )
}

export default Personal_Info_Form
