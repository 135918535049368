import { createSlice } from '@reduxjs/toolkit'
const add_nurse_Reducers = createSlice({
  name: 'Add_Nurse',
  initialState: {
    phoneNo: '',
  },
  reducers: {
    addNurseUser: (state, action) => {
      state.phoneNo = action.payload
    },
  },
})
export const { addNurseUser } = add_nurse_Reducers.actions
export default add_nurse_Reducers.reducer
