import React, { useMemo, useEffect, useState } from "react";
import Table from "../../../../../Components/Table";
import { API_CONSTANTS } from "../../../../../API_CONSTANTS";
import axios from "axios";
import { useParams } from "react-router";

export default function Referral() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [referral, setReferral] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setReferral(referral.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(referral.length / pageSize));
      }
    },
    [referral, lastEvaluatedKey]
  );
  const userList = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/patients/${params.id}/referrals`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setLoading(true);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
        setReferral(res.data.response.Items ? res.data.response.Items : []);
      })
      .catch((error) => {
        if (error.response.state === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setLoading(true);
      });
  };
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/patients/${params.id}/referrals?last=${lastEvaluatedKey}`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var patientListNextPage = response.data.response.Items;
        setReferral([...referral, ...patientListNextPage]);
      })
      .then(() => setLoading(true));
  };
  const columns = useMemo(
    () => [
      {
        Header: "No.",
        // accessor: "index",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Code Shared",
        accessor: "",
      },
      {
        Header: "Name",
        accessor: "Fname",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNo",
      },
      {
        Header: "Amount Transferred",
        accessor: "",
      },
    ],
    []
  );
  useEffect(() => {
    userList();
  }, []);
  return (
    <>
      <div className="row rounded bg-white m-4">
        <h3 style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}>
          Referral
        </h3>
        <Table
          columns={columns}
          data={referral}
          routingLink={""}
          loadMoreList={handleLoadMoreData}
          pageCount={pageCount}
          fetchData={fetchData}
          lastEvaluatedKey={lastEvaluatedKey}
          loading={loading}
          setLoading={setLoading}
          recordDownload={true}
        />
      </div>
    </>
  );
}
