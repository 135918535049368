import React, { useMemo, useEffect, useState } from 'react'
import Table from '../../../../Components/Table/index'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'
import AlertMessage from '../../../../Components/AlertMessage'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import Status from './Status'
import { Button, Modal } from 'react-bootstrap'
import CloseIcon from '@mui/icons-material/Close'
import PDelete from '../../../../resources/images/svgs/Plan_Delete.svg'
import Toast from '../../../../Components/Toast/Toast'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
//Edit Function Component
function Edit({ response, userPromoList }) {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const [isLoading, setIsLoading] = useState(false)
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [promoStatus, setPromoStatus] = useState(response?.promoStatus)
  const [discountType, setDiscountType] = useState(response?.discountType)
  const [startDateTime, setStartDateTime] = useState(response?.startDateTime)
  const [expiryDate, setExpiryDate] = useState(response?.expires_at)
  const [promoName, setPromoName] = useState(response?.promoName)
  const [promoCode, setPromoCode] = useState(response?.promoCode)
  const [discountAmount, setDiscountAmount] = useState(response?.discountAmount)
  const [isfocused, setIsFocused] = useState(false)
  const [isfocused2, setIsFocused2] = useState(false)
  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur = () => {
    setIsFocused(false)
  }
  const handleFocus2 = () => {
    setIsFocused2(true)
  }
  const handleBlur2 = () => {
    setIsFocused2(false)
  }
  const handleClose = () => {
    setShow(false)
  }
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  // Custom validation function
  const validateForm = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const showErrorToast = (message) => {
      handleToast(message, faTimesCircle, 'red', 'show-error');
      return false;
    }
    const isInvalidPromoCode = promoCode.length < 5 || promoCode.length > 50;
    const isInvalidPromoName = promoName.length < 3 || promoName.length > 50;
    if (isInvalidPromoName) {
      return showErrorToast('Promo Name must be at least 5 characters.');
    }
    if (isInvalidPromoCode) {
      return showErrorToast('Promo Code must be at least 3 characters.');
    }
    if (!promoName || !promoCode || !startDateTime || !expiryDate || !discountType || !discountAmount || !promoStatus) {
      return showErrorToast('Please fill in all fields.');
    }
    if (isNaN(discountAmount) || discountAmount < 1 || discountAmount > 1000) {
      return showErrorToast('Discount amount must be a number between 1 and 1000.');
    }
    if (expiryDate < currentDate) {
      return showErrorToast('Please select an upcoming expiry date.');
    }
    return true;
  };
  // API integration of Add subsrciption Form 
  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    const values = {
      promoName: promoName,
      promoCode: promoCode,
      promoStatus: promoStatus,
      discountAmount: parseInt(discountAmount),
      startDateTime: startDateTime,
      expires_at: expiryDate,
      discountType: discountType,
    }
    axios.patch(
      `${API_CONSTANTS.BASEURL_1}admin/promo_code/${response.promoCodeId}`,
      values,
      {
        headers: API_CONSTANTS.headers(),
      }
    )
      .then(response => {
        if (response.data.message === 'Success') {
          handleToast(
            'Promo Code Update Successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
        }
        userPromoList()
        handleClose()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const expiryDates = () => {
    if ((expiryDate) && !isNaN(new Date(expiryDate))) {
      return new Date(expiryDate).toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        // hour: 'numeric',
        // minute: 'numeric',
        // hour12: true,
      });
    } else {
      return expiryDate
    }
  }
  const startDateTimes = () => {
    if ((startDateTime) && !isNaN(new Date(startDateTime))) {
      return new Date(startDateTime).toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        // hour: 'numeric',
        // minute: 'numeric',
        // hour12: true,
      });
    } else {
      return startDateTime
    }
  }
  return (
    <>
      <button className='btn' onClick={handleShow}>
        <i className='fas fa-pen' style={{ color: '#8E8E8E' }}></i>
      </button>
      <Modal show={show} size="lg">
        <Modal.Body className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <h4>Edit Promo Code</h4>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
          <div className="d-flex gap-1 mb-4">
            <div className="col-md-6">
              <TextField
                type="text"
                name="promoName"
                value={promoName}
                onChange={(e) => setPromoName(e.target.value)}
                label="Promo Name"
                variant="outlined"
                fullWidth
                required
              />
            </div>
            <div className="col-md-6">
              <TextField
                type="text"
                name="promoCode"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                label="Promo Code"
                variant="outlined"
                fullWidth
                required
              />
            </div>
          </div>
          <div className="d-flex gap-1 mb-4">
            <div className="col-md-6">
              <TextField
                type={isfocused ? "date" : "text"}
                name="startDateTime"
                value={isfocused ? startDateTime : startDateTimes()}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => setStartDateTime(e.target.value)}
                label="Start Date and Time"
                variant="outlined"
                fullWidth
                required
              />
            </div>
            <div className="col-md-6">
              <TextField
                type={isfocused2 ? "date" : "text"}
                name="expiryDate"
                value={isfocused2 ? expiryDate : expiryDates()}
                onFocus={handleFocus2}
                onBlur={handleBlur2}
                onChange={(e) => setExpiryDate(e.target.value)}
                label="Expiry Date and Time"
                variant="outlined"
                fullWidth
                required
              />
            </div>
          </div>
          <div className="d-flex gap-1 mb-4">
            <div className="col-md-6">
              <TextField
                select
                name='discountType'
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
                label='Discount In'
                variant='outlined'
                fullWidth
                required
              >
                <MenuItem value="Rupees">Rs.</MenuItem>
                <MenuItem value="Percentage">Percentage</MenuItem>
              </TextField>
            </div>
            <div className="col-md-6 mb-4">
              <TextField
                type="number"
                name="discountAmount"
                value={discountAmount}
                onChange={(e) => setDiscountAmount(e.target.value)}
                label="Discount"
                variant="outlined"
                fullWidth
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <TextField
              select
              name='promoStatus'
              value={promoStatus}
              onChange={(e) => setPromoStatus(e.target.value)}
              label='Status'
              variant='outlined'
              fullWidth
              required
            >
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value="inactive">InActive</MenuItem>
            </TextField>
          </div>
          <div className="row d-flex justify-content-center mt-5 mb-3 gap-3">
            <div className="col-md-5">
              <button type="submit" className="btn btn-save w-100" onClick={handleSubmit}>
                Save Changes{" "}
                {isLoading && <Spinner animation="border" size="sm" />}
              </button>
            </div>
            <div className="col-md-5">
              <button
                type="reset"
                className="btn btn-outline-danger w-100"
                onClick={handleClose}
                style={{ height: "43px" }}
              >
                Discard Changes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <style jsx='true'>
        {`
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
            padding: 10px 10px !important;
          }
          .error-message {
            color: red;
          }
          .add-record {
            background: #009b94 !important;
            color: #fff;
          }
          .dropdown-icon-container {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }
          .drop{
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-140%);
          }
        `}
      </style>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  );
}
// Delete Function Component 
function Delete({ response, userPromoList }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [isLoading, setIsLoading] = useState(false)
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  const handleDelete = () => {
    setIsLoading(true);
    axios
      .delete(`${API_CONSTANTS.BASEURL_1}admin/promo_code/${response.promoCodeId}`,
        {
          headers: API_CONSTANTS.headers(),
        })
      .then((response) => {
        if (response.data.message === 'Success') {
          handleToast(
            'Promo Code Deleted Successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
        }
        setIsLoading(false)
        userPromoList()
        setShow(false)
      })
  }
  return (
    <>
      <button className='btn' onClick={handleShow}>
        <i className='fas fa-trash-alt' style={{ color: '#FF6760' }}></i>
      </button>
      <Modal show={show}
        className='d-flex justify-content-center align-items-center'
      >
        <Modal.Body className='d-flex justify-content-around align-items-center flex-column' style={{ maxHeight: '255px' }}>
          <div style={{
            width: '32px',
            height: '32px'
          }}>
            <img src={PDelete} alt='' />
          </div>
          <h4 style={{ color: '#AF1300' }}>Delete !</h4>
          <p style={{ textAlign: 'center' }}>Are you sure you want to Delete this Promo Code?</p>
          <div className='d-flex' style={{ gap: '20px' }}>
            <Button style={{ color: '#FFFFFF', backgroundColor: '#B00020', border: 'none' }} onClick={handleDelete}>
              Delete {isLoading && <Spinner animation='border' size='sm' />}
            </Button>
            <Button
              style={{ color: '#4A4A4A', backgroundColor: '#FFFFFF', border: '1px solid #4A4A4A' }}
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}

// Edit And Delete Component Call Component 
function ActionButtons({ response, userPromoList }) {
  return (
    <div style={{ width: "125px" }} className='d-flex'>
      <Edit response={response} userPromoList={userPromoList} />
      <Delete response={response} userPromoList={userPromoList} />
    </div>
  )
}
// SubScription List Component
export default function PromoList() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [planList, setPlanList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0);
  const fetchIdRef = React.useRef(0)
  //Filters
  const filter_status = {
    status: useSelector((state) => state.nurseFilterReducer.value),
    gender: useSelector((state) => state.nurseFilterReducer.value1),
    // age: useSelector((state) => state.nurseFilterReducer.value2),
    created_at: useSelector((state) => state.nurseFilterReducer.value2),
  }

  //
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = lastEvaluatedKey
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setPlanList(planList.slice(startRow, endRow))
        setPageCount(Math.ceil(planList.length / pageSize))
      }
    },
    [planList, lastEvaluatedKey]
  )
  // Get API of Subscription Plan 
  const userPromoList = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/promo_code`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true)
        setLastEvaluatedKey(res.data.response.Items.LastEvaluatedKey)
        setPlanList(res.data.response?.Items)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setIsLoading(true)
        setPlanList([])
      })
  }
  const handleLoadMoreData = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/promo_code?last=${lastEvaluatedKey}`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
        var planListNextPage = response.data.response.Items
        setPlanList([...planList, ...planListNextPage])
        setPage(page + 1)
        setCurrentPage(currentPage + 1)
      })
      .then(() => setLoading(true))
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Sr',
        accessor: (row, index) => index + 1 + currentPage * pageCount,
      },
      {
        Header: 'Code',
        accessor: 'promoCode',
      },
      {
        Header: 'Discount',
        accessor: a => `${a.discountAmount}${a.discountType === 'Percentage' ? "%" : " Rupees"}`,
      },
      {
        Header: 'Start Date',
        accessor: (item) => moment(item.startDateTime).format('D-MM-YYYY'),
      },
      {
        Header: 'End Date',
        accessor: (item) => moment(item.expires_at).format('D-MM-YYYY'),
      },
      {
        Header: 'Status',
        accessor: (response) => <Status response={response} />
      },
      {
        Header: 'Action',
        accessor: (response) => <ActionButtons response={response} userPromoList={userPromoList} />,
      },
    ],
    []
  )
  const is_SessionExpire = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  useEffect(() => {
    userPromoList()
  }, [
    filter_status.created_at,
    filter_status.gender,
    filter_status.status,
  ])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 401'
            ? is_SessionExpire()
            : error.message === 'Request failed with status code 404'
              ? 'record not found'
              : error.message
        } />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center  '>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <>
        <div className='row rounded bg-white m-4'>
          <div>
            <h3
              style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}
            >
              All Promo Plan
            </h3>
          </div>
          <Table
            // pageNumber={page}
            columns={columns}
            data={planList}
            routingLink={''}
            pageCount={pageCount}
            fetchData={fetchData}
            loadMoreList={handleLoadMoreData}
            lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            setLoading={setLoading}
          // FilterForm={<NurseFilterForm nurseList={userList} />}
          />
        </div>
        <style jsx='true'>{`
          .btn:focus {
            box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 25%) !important;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .patient-search-input {
            border-radius: 0;
          }
        `}</style>
      </>
    )
  }
}
