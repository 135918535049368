/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import Table from '../../../../Components/Table';
import axios from 'axios';
import { API_CONSTANTS } from '../../../../API_CONSTANTS';
import Toast from '../../../../Components/Toast/Toast'
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import "../../Component/Overview/EditLab/Editlab.css";
import PDelete from '../../../../resources/images/svgs/Plan_Delete.svg'
import EditPackage from './EditPackage';


function Delete({ data }) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [isLoading, setIsLoading] = useState(false)
    const [ToastMessage, setToastMessage] = useState('')
    const [showToast, setShowToast] = useState('')
    const [ToastIcon, setToastIcon] = useState(faTimesCircle)
    const [iconColor, setIconColor] = useState('')
    const handleToast = (message, icon, iconColor, className) => {
        setToastIcon(icon)
        setToastMessage(message)
        setShowToast(`show ${className}`)
        setIconColor(iconColor)
        setTimeout(() => setShowToast(''), 2900)
    }
    const handleDelete = () => {
        setIsLoading(true);
        axios
            .delete(`${API_CONSTANTS.LABURL}admin/labs/plans/${data.pId}`,
                {
                    headers: API_CONSTANTS.headers(),
                })
            .then((response) => {
                if (response.data.message === 'Success') {
                    handleToast(
                        'Package Deleted Successfully',
                        faCheckCircle,
                        '#009b94',
                        'show-success'
                    )
                }
                setIsLoading(false)
                setShow(false)
            })
            .catch((err) => {
                setIsLoading(false)
                console.log(err)
            })
    }
    return (
        <>
            <button className='btn' onClick={handleShow}>
                <i className='fas fa-trash-alt' style={{ color: '#FF6760' }}></i>
            </button>
            <Modal show={show}
                className='d-flex justify-content-center align-items-center'
            >
                <Modal.Body className='d-flex justify-content-around align-items-center flex-column' style={{ maxHeight: '255px' }}>
                    <div style={{
                        width: '32px',
                        height: '32px'
                    }}>
                        <img src={PDelete} alt='' />
                    </div>
                    <h4 style={{ color: '#AF1300' }}>Delete !</h4>
                    <p style={{ textAlign: 'center' }}>Are you sure you want to Delete this Package?</p>
                    <div className='d-flex' style={{ gap: '20px' }}>
                        <Button style={{ color: '#FFFFFF', backgroundColor: '#B00020', border: 'none' }} onClick={handleDelete}>
                            Delete {isLoading && <Spinner animation='border' size='sm' />}
                        </Button>
                        <Button
                            style={{ color: '#4A4A4A', backgroundColor: '#FFFFFF', border: '1px solid #4A4A4A' }}
                            onClick={() => handleClose()}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Toast
                cName={showToast}
                icon={ToastIcon}
                color={iconColor}
                text={ToastMessage}
            />
        </>
    )
}
// Edit And Delete Component Call Component 
function ActionButtons({ data }) {
    return (
        <div style={{ width: "125px" }} className='d-flex'>
            <EditPackage data={data} />
            <Delete data={data} />
        </div>
    )
}

export default function PackagesList() {
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
    const [packageList, setPackageList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const fetchIdRef = useRef(0);
    const fetchData = useCallback(
        ({ pageSize, pageIndex }) => {
            const fetchId = lastEvaluatedKey;
            if (fetchId === fetchIdRef.current) {
                const startRow = pageSize * pageIndex;
                const endRow = startRow + pageSize;
                setPackageList(packageList.slice(startRow, endRow));
                setPageCount(Math.ceil(packageList.length / pageSize));
            }
        },
        [packageList, lastEvaluatedKey]
    );
    // all labs data API 
    const Package = () => {
        axios
            .get(`${API_CONSTANTS.LABURL}admin/labs?package=True`, {
                headers: API_CONSTANTS.headers(),
            })
            .then((res) => {
                setIsLoading(true);
                setPackageList(res?.data?.response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.reload();
                    window.localStorage.clear();
                }
                setIsLoading(true);
                setPackageList([]);
            });
    };
    const columns = useMemo(
        () => [
            {
                Header: "Package Name",
                accessor: "subscriptionName",
            },
            {
                Header: "Lab Name",
                accessor: "labName",
            },
            {
                Header: "Total Price",
                accessor: "subCharges",
            },
            {
                Header: "Discount",
                accessor: row => `${row.saveRupees}%`,
            },
            {
                Header: "Discounted Price",
                accessor: "charges",
            },
            {
                Header: "No. of Tests",
                accessor: "noOfTests",
            },
            {
                Header: "Action",
                accessor: (data) => <ActionButtons data={data} />
            },
        ],
        []
    );
    useEffect(() => {
        Package();
    }, []);
    return (
        <>
            <div className="row rounded bg-white m-4">
                <div className="d-flex align-items-start">
                    <h3
                        style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            padding: "18px",
                        }}
                    >
                        All Lab Packages
                    </h3>
                    {!isLoading && (
                        <div className="d-flex justify-content-center  ">
                            <Spinner
                                animation="border"
                                size="sm"
                                style={{ color: "#24AAA4", marginTop: "20px" }}
                            />
                        </div>
                    )}
                </div>
                <Table
                    columns={columns}
                    data={packageList}
                    routingLink={""}
                    pageCount={pageCount}
                    fetchData={fetchData}
                    // loadMoreList={handleLoadMoreData}
                    lastEvaluatedKey={lastEvaluatedKey}
                    loading={loading}
                    setLoading={setLoading}
                    // FilterForm={<LabFilterForm lab={lab} />}
                    recordDownload={true}
                // onDownloadBtn={onDownloadBtn}
                />
            </div>
            <style jsx="true">{`
          .add-record {
            background: #009b94 !important;
            color: #fff;
          }
          .btn:focus {
            box-shadow: none !important;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .patient-search-input {
            border-radius: 0;
          }
        `}</style>
        </>
    )
}
