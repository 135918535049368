import { createSlice } from '@reduxjs/toolkit'
//

export const ReviewFilterSlice = createSlice({
  name: 'reviewFilter',
  initialState: {
    value: '',
    value1: '',
    value2: '',
    value3: '',
  },
  reducers: {
    all: (state, action) => {
      state.value = 'all'
    },
    approve: (state, action) => {
      state.value = 'approve'
    },
    notapprove: (state, action) => {
      state.value = 'notapprove'
    },

    // second filter value
    allreview: (state, action) => {
      state.value1 = 'all'
    },
    doctors: (state, action) => {
      state.value1 = 'doctors'
    },
    patients: (state, action) => {
      state.value1 = 'patients'
    },
    // 3th filter value
    reviewdate: (state, action) => {
      const { payload } = action
      state.value2 = payload
    },
    reviewDateFrom: (state, action) => {
      const { payload } = action
      state.value3 = payload
    },
  },
})
export const { all, approve, notapprove, reviewDateFrom, reviewDate } =
  ReviewFilterSlice.actions
export const ReviewFilterReducer = ReviewFilterSlice.reducer