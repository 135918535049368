import React, { useMemo, useEffect, useState } from "react";
import Table from "../../../../Components/Table/index";
import axios from "axios";
import { API_CONSTANTS } from "../../../../API_CONSTANTS";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { useSelector } from "react-redux";
import style from "../../../../Style/corporate.module.css";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
// import PatientFilterForm from "./PatientFilterForm";
import AlertMessage from "../../../../Components/AlertMessage";
// styling
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};
const partnerStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
};
export default function CorporatePlans() {
  // variables
  const [open, setOpen] = useState(false);
  const [addPlanLoading, setAddPlanLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [addNewPlan, setAddNewPlan] = useState({
    planName: "",
    noOfCalls: "",
    employeeCalls: "",
    noOfEmployee: "",
    pricePerEmployee: "",
    planStatus: "inActive",
    planDuration: "",
    totalPrice: "",
  });
  const {
    planName,
    noOfCalls,
    employeeCalls,
    noOfEmployee,
    pricePerEmployee,
    planStatus,
    planDuration,
    totalPrice,
  } = addNewPlan;
  const [page, setPage] = useState(0);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [plansList, setPlansList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  // functions
  const getDataFromFields = (e) => {
    setAddNewPlan((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setAddNewPlan({
      planName: "",
      noOfCalls: "",
      employeeCalls: "",
      noOfEmployee: "",
      pricePerEmployee: "",
      planStatus: "Active",
      planDuration: "",
      totalPrice: "",
    });
    setOpen(false);
    setEdit(false);
  };

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setPlansList(plansList.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(plansList.length / pageSize));
      }
    },
    [plansList, lastEvaluatedKey]
  );
  //
  // API Hatching data of all admins
  const userList = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/subscriptionplan?corporateplan=true`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setPlansList(res.data.response.Items[0].list);
        setIsLoading(true);
        // setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setIsLoading(true);
        setPlansList([]);
        // setError(error)
      });
  };
  //
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_4}admin/corporates?last=${lastEvaluatedKey}`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var patientListNextPage = response.data.response.Items;
        setPlansList([...plansList, ...patientListNextPage]);
        setPage(page + 1);
      })
      .then(() => setLoading(true));
  };
  // handle add new plan or edit plan function
  const handleAddPartner = (e, id) => {
    setAddPlanLoading(true);
    const config = () => {
      if (edit) {
        return {
          method: "patch",
          url:
            API_CONSTANTS.BASEURL_1 +
            `admin/subscriptionplan/${addNewPlan?.pId}?corporateplan=true`,
          data: {
            planName,
            noOfCalls,
            employeeCalls,
            noOfEmployee: parseInt(noOfEmployee),
            pricePerEmployee: parseInt(pricePerEmployee),
            planStatus,
            planDuration,
            totalPrice: parseInt(totalPrice),
          },
          headers: API_CONSTANTS.headers(),
        };
      } else {
        return {
          method: "post",
          url: API_CONSTANTS.BASEURL_1 + `admin/subscriptionplan`,
          params: { corporateplan: true },
          data: {
            planName,
            noOfCalls,
            employeeCalls,
            noOfEmployee: parseInt(noOfEmployee),
            pricePerEmployee: parseInt(pricePerEmployee),
            planStatus,
            planDuration,
            totalPrice: parseInt(totalPrice),
          },
          headers: API_CONSTANTS.headers(),
        };
      }
    };
    axios(config())
      .then((res) => {
        if (edit) {
          setPlansList((prev) =>
            prev.map((item) => {
              if (item.pId === addNewPlan.pId) {
                return {
                  ...item,
                  planName: addNewPlan.planName,
                  noOfCalls: addNewPlan.noOfCalls,
                  employeeCalls: addNewPlan.employeeCalls,
                  noOfEmployee: addNewPlan.noOfEmployee,
                  pricePerEmployee: addNewPlan.pricePerEmployee,
                  planDuration: addNewPlan.planDuration,
                  totalPrice: addNewPlan.totalPrice,
                };
              } else {
                return item;
              }
            })
          );
        } else {
          // console.log(res.data.response.Items);
          setPlansList(res.data.response[0].Items);
        }
        setAddPlanLoading(false);
        toast(res.data.response.message, {
          type: "success",
        });
        setAddNewPlan({
          planName: "",
          noOfCalls: "",
          employeeCalls: "",
          noOfEmployee: "",
          pricePerEmployee: "",
          planStatus: "Active",
          planDuration: "",
          totalPrice: "",
        });
        setOpen(false);
      })
      .catch((err) => {
        setAddPlanLoading(false);
        toast(
          err.response?.data?.response?.message
            ? err.response.data.response.message
            : "Something is not working right now please try again",
          {
            type: "error",
          }
        );
      });
  };
  // handle edit plan
  const handleEdit = (data) => {
    setOpen(true);
    setEdit(true);
    setAddNewPlan({
      planName: data.planName,
      noOfCalls: data.noOfCalls,
      employeeCalls: data.employeeCalls,
      noOfEmployee: data.noOfEmployee,
      pricePerEmployee: data.pricePerEmployee,
      planStatus: "Active",
      planDuration: data.planDuration,
      totalPrice: data.totalPrice,
      pId: data.pId,
    });
  };
  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Plan Name",
        accessor: "planName",
      },
      {
        Header: "No. of Employees ",
        accessor: "noOfEmployee",
      },

      {
        Header: "Calls/Employee",
        accessor: "employeeCalls",
      },
      {
        Header: "Duration",
        accessor: "planDuration",
      },

      {
        Header: "Price/Employee",
        accessor: "pricePerEmployee",
      },
      {
        Header: "Total Price",
        accessor: "totalPrice",
      },
      {
        Header: "Status",
        accessor: (item) => (
          <ActivatedDeactivated
            planStatus={item.planStatus}
            plan={item}
            setPlansList={setPlansList}
          />
        ),
      },
      {
        Header: "Action",
        accessor: (item) => (
          <ActionBtn
            item={item}
            onEdit={handleEdit}
            setPlansList={setPlansList}
          />
        ),
      },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  // rendering API
  useEffect(() => {
    if (plansList.length < 1) {
      userList();
    }
  }, []);
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 404"
            ? "This record does not exist"
            : error.message === "Request failed with status code 401"
              ? is_SessionExpire()
              : error.message
        }
      />
    );
  } else {
    return (
      <>
        <section className={style.corporatePageSection}>
          {/* add new corporate  */}
          <Button
            variant="contained"
            className={style.addPartnerBtn}
            onClick={handleOpen}
          >
            <AddIcon />
            Add New Plan
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={partnerStyles}>
              <div className={style.addPartnerModalContainer}>
                <Button
                  className={style.closeBtnPartnerModal}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Button>
                <div className={style.addPartnerModal}>
                  <TextField
                    id="standard-basic"
                    label="Plan Name"
                    variant="standard"
                    name="planName"
                    onChange={getDataFromFields}
                    required
                    value={planName}
                  />
                  <TextField
                    id="standard-basic"
                    label="No. of Employees"
                    variant="standard"
                    name="noOfEmployee"
                    onChange={getDataFromFields}
                    required
                    type="number"
                    value={noOfEmployee}
                  />
                  <div>
                    <FormControl variant="standard">
                      <InputLabel id="demo-simple-select-standard-label">
                        Total Calls
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={noOfCalls}
                        label="Total Calls"
                        name="noOfCalls"
                        onChange={getDataFromFields}
                      >
                        <MenuItem value="" selected disabled>
                          --Select--
                          {/* <em>None</em> */}
                        </MenuItem>
                        <MenuItem value={100}>100 Calls</MenuItem>
                        <MenuItem value={500}>500 Calls</MenuItem>
                        <MenuItem value={1000}>1000 Calls </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl variant="standard">
                      <InputLabel id="demo-simple-select-standard-label">
                        No of Calls per employee
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={employeeCalls}
                        label="No of Calls per employee"
                        name="employeeCalls"
                        onChange={getDataFromFields}
                      >
                        <MenuItem value="" selected disabled>
                          --Select--
                          {/* <em>None</em> */}
                        </MenuItem>
                        <MenuItem value={3}>3 Calls</MenuItem>
                        <MenuItem value={5}>5 Calls</MenuItem>
                        <MenuItem value={7}>7 Calls</MenuItem>
                        <MenuItem value={10}>10 Calls</MenuItem>
                        <MenuItem value={12}>12 Calls</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl variant="standard">
                      <InputLabel id="demo-simple-select-standard-label">
                        Duration
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={planDuration}
                        label="Duration"
                        name="planDuration"
                        onChange={getDataFromFields}
                      >
                        <MenuItem value="" selected disabled>
                          --Select--
                          {/* <em>None</em> */}
                        </MenuItem>
                        <MenuItem value={1}>1 Month</MenuItem>
                        <MenuItem value={2}>2 Month</MenuItem>
                        <MenuItem value={3}>3 Month</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <TextField
                    id="standard-basic"
                    label="Price per Employee"
                    variant="standard"
                    name="pricePerEmployee"
                    onChange={getDataFromFields}
                    required
                    type="number"
                    value={pricePerEmployee}
                  />
                  <TextField
                    id="standard-basic"
                    label="Total Price"
                    variant="standard"
                    name="totalPrice"
                    onChange={getDataFromFields}
                    required
                    type="number"
                    value={totalPrice}
                  />
                </div>
                <div className={style.actionBtnModal + " mt-4 ps-3"}>
                  <LoadingButton
                    className={style.actionBtn}
                    variant="contained"
                    loading={addPlanLoading}
                    // loadingPosition="end"
                    onClick={handleAddPartner}
                    disabled={
                      planName &&
                        noOfCalls &&
                        employeeCalls &&
                        noOfEmployee &&
                        pricePerEmployee &&
                        planStatus &&
                        planDuration &&
                        totalPrice
                        ? false
                        : true
                    }
                  >
                    {edit ? "Save Plan" : " Add Plan"}
                  </LoadingButton>
                  <Button
                    onClick={handleClose}
                    color="error"
                    className={style.actionBtn}
                  >
                    Discard
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
          {/* corporate table listing  */}
          <div className="row rounded bg-white m-4">
            <h3
              style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}
            >
              All Plans
              {!isLoading && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "#24AAA4" }}
                />
              )}
            </h3>

            <Table
              pageNumber={page}
              columns={columns}
              data={plansList}
              routingLink={``}
              pageCount={pageCount}
              fetchData={fetchData}
              loadMoreList={handleLoadMoreData}
              lastEvaluatedKey={lastEvaluatedKey}
              loading={loading}
              recordDownload={true}
              setLoading={setLoading}
            // FilterForm={<PatientFilterForm plansList={patientListCheck} />}
            // onDownloadBtn={onDownloadBtn}
            />
          </div>
        </section>
        <ToastContainer />
      </>
    );
  }
}
/////----------- activate plans/ deactivate plans --///
const ActivatedDeactivated = ({ setPlansList, planStatus, plan }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(
    planStatus === "Active" ? true : false
  );
  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };
  const handleChange = (e) => {
    // e.target.checked;
    setOpen(true);
  };
  // handle api response
  const handleClick = () => {
    setLoading(true);
    axios
      .patch(
        API_CONSTANTS.BASEURL_1 +
        `admin/subscriptionplan/${plan.pId}?corporateplan=true`,
        {
          planName: plan.planName,
          noOfCalls: plan.noOfCalls,
          employeeCalls: plan.employeeCalls,
          noOfEmployee: plan.noOfEmployee,
          pricePerEmployee: plan.pricePerEmployee,
          totalPrice: plan.totalPrice,
          planDuration: plan.planDuration,
          planStatus: plan.planStatus === "Active" ? "inActive" : "Active",
        },
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        setPlansList((prev) =>
          prev.map((item) => {
            if (item.pId === plan.pId) {
              return {
                ...item,
                planStatus:
                  plan.planStatus === "Active" ? "inActive" : "Active",
              };
            } else return item;
          })
        );
        setLoading(false);
        setChecked(!checked);
        setOpen(false);
        toast(`Status changed successfully`, {
          type: "success",
        });
      })
      .catch((erro) => {
        setLoading(false);
        toast(`Something goes wrong try again`, {
          type: "error",
        });
      });
  };
  return (
    <div>
      <Switch checked={checked} onChange={handleChange} />
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div className={style.plansModalContainer}>
            {checked ? (
              <CancelIcon color="error" style={{ fontSize: "2rem" }} />
            ) : (
              <CheckCircleIcon color="success" style={{ fontSize: "2rem" }} />
            )}
            <p
              className={style.modalTextError}
              style={{
                color: checked ? " #af1300" : "#1E1E1E",
              }}
            >
              {checked ? "Deactivate Plan" : "Activate Plan"}
            </p>
            <p className={style.modalText}>
              {checked
                ? `Are you sure you want to Deactivate ${plan.planName}`
                : `Are you sure you want to Activate ${plan.planName}.`}
            </p>
            <div className={style.actionBtnModal}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                className={style.actionBtn}
              >
                Close
              </Button>
              <LoadingButton
                variant="contained"
                color={checked ? "error" : "success"}
                size="small"
                onClick={handleClick}
                className={style.actionBtn}
                loading={loading}
              // loadingPosition="end"
              >
                {checked ? "Deactivate" : "Activate"}
              </LoadingButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
/// action button of plans
const ActionBtn = ({ onEdit, item, setPlansList }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // planStatus === "Active" ? true : false
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };
  // handle api response
  const handleClick = () => {
    setLoading(true);
    axios
      .delete(
        API_CONSTANTS.BASEURL_1 +
        `admin/subscriptionplan/${item.pId}?corporateplan=true`,
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        setPlansList((prev) => prev.filter((plan) => plan.pId !== item.pId));
        setLoading(false);
        setOpen(false);
        toast(`Plan deleted successfully`, {
          type: "success",
        });
      })
      .catch((erro) => {
        setLoading(false);
        toast(`Something goes wrong try again`, {
          type: "error",
        });
      });
  };
  return (
    <div>
      <div>
        <Button onClick={() => onEdit(item)} color="secondary">
          <EditIcon />
        </Button>
        <Button color="error" onClick={handleOpen}>
          <DeleteIcon />
        </Button>
      </div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div className={style.plansModalContainer}>
            <ErrorIcon color="error" style={{ fontSize: "2rem" }} />

            <p
              className={style.modalTextError}
              style={{
                color: " #af1300",
              }}
            >
              {"Delete Plan"}
            </p>
            <p className={style.modalText}>
              Are you sure you want to Delete this plan
            </p>
            <div className={style.actionBtnModal}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                className={style.actionBtn}
              >
                Close
              </Button>
              <LoadingButton
                variant="contained"
                color={"error"}
                size="small"
                onClick={handleClick}
                className={style.actionBtn}
                loading={loading}
              // loadingPosition="end"
              >
                {"Delete"}
              </LoadingButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
