/*
Authore: Umer Shabir 
Date: april-2022
File: the whole admin module file.
*/
import React from 'react'
import AdminList from './Components/AdminList/index'
// the admin module
export default function Admin() {
  return <AdminList />
}
