/*
Authore: Umer Shabir
Date: april-2022
File: admin profile page, all the related nav link of profile page will render here.
*/
// dependencies
import React, { useEffect, useState } from 'react'
import { ReactComponent as AdminProfileIcon } from './asset/icons/adminprofile_icon.svg'
import { useParams, NavLink } from 'react-router-dom'
import { Outlet } from 'react-router'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile } from './AdminProfileSlice'
// profile buttons
import BlockProfileButton from './BlockProfileButton'
import EditProfileButton from './EditProfileButton'
import UnblockProfileButton from './UnblockProfileButton'
import EditPasswordButton from './EditPasswordButton'
// import FontawesomeIcon libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
// admin profile page component
export default function AdminProfilePage() {
  const params = useParams()
  const dispatch = useDispatch()
  const { value } = useSelector((state) => state.getAdminProfileReducer)
  const [overview, setOverview] = useState(value)
  // Admin profile page nav links object
  const PROFILE_NAV_LINKS = [
    {
      navLink: 'Overview',
      navRoute: `/admins/profile/${params.id}/overview`,
    },
  ]
  // get user detail by it's id
  const get_admin_details = () => {
    axios
      .get(`${API_CONSTANTS.BASEURL}admin/${params.id}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((e) => {
        setOverview(e.data.response.Item)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
      })
  }
  useEffect(() => {
    let mounted = true
    if (mounted) {
      dispatch(getProfile(params.id))
    }
    mounted = false
  }, [dispatch, params.id])
  // UnBlock Admin User Function.
  return (
    <>
      <div className='container-fluid profile-header'>
        <div className='row pt-4 mb-1'>
          <div className='col'>
            <div className='d-flex ms-4 align-items-start pt-3'>
              <span className='me-4'>
                <AdminProfileIcon />
              </span>
              <div className='d-flex flex-column'>
                <h5
                  style={{ fontWeight: 500, marginBottom: 15, marginTop: -4 }}
                >
                  {value.adminName?.charAt(0).toUpperCase() +
                    value.adminName?.slice(1)}
                </h5>
                <h6
                  style={{ fontWeight: 400, color: '#5C5C5C', marginBottom: 0 }}
                >
                  {value.adminRole}
                </h6>
              </div>
              {value.is_blocked && (
                <span
                  className='btn mx-2'
                  style={{ background: '#b00020', color: '#fff' }}
                >
                  <FontAwesomeIcon icon={faBan} className={'mx-1'} />
                  Blocked
                </span>
              )}
            </div>
          </div>
          <div className='col'>
            <div
              className='d-flex justify-content-end'
              style={{ columnGap: 5 }}
            >
                <EditPasswordButton
                overview={value}
                get_admin_details={get_admin_details}
              />
              <EditProfileButton
                overview={value}
                get_admin_details={get_admin_details}
              />
              {value.is_blocked || (
                <BlockProfileButton
                  overview={overview}
                  get_admin_details={get_admin_details}
                />
              )}
              {/* {value.is_blocked || <DeactiveProfileButton />} */}
              {value.is_blocked && (
                <UnblockProfileButton
                  overview={overview}
                  get_admin_details={get_admin_details}
                />
              )}
            </div>
          </div>
        </div>
        <div className='row pt-4'>
          <div className='col'>
            <div className='d-flex ms-4' style={{ columnGap: 10 }}>
              {PROFILE_NAV_LINKS.map((navItem) => (
                <NavLink
                  to={navItem.navRoute}
                  key={navItem.navLink}
                  className={({ isActive }) =>
                    isActive ? 'active' : 'inactive'
                  }
                  style={{ fontSize: 14 }}
                >
                  {navItem.navLink}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='containter-fluid'>
        <Outlet />
      </div>
      <style jsx='true'>
        {`
          .profile-header {
            background: #e0f3f2;
            height: 160px;
          }
          .active {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .inactive {
            padding: 7px 15px;
          }
        `}
      </style>
    </>
  )
}
