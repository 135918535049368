import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import toArrayBuffer from 'to-array-buffer'
import axios from 'axios'
import moment from 'moment'
// import Formik & yup libraries
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
// import the fontawesomeIcon Library
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import uploadIcon from '../../../../../resources/images/svgs/uploadIcon.svg'
import './FormPages.css'
import Toast from '../../../../../Components/Toast/Toast'
import { faTimesCircle } from '@fortawesome/fontawesome-free-solid'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'

const Personal_Info_Form = (props) => {
  const date = moment(new Date())
  const today_date = date.format('YYYY-MM-DD')
  const params = useParams()

  const stage = '2'
  const [Createspin, setCreateSpin] = useState('')
  // state for img in array buffer form
  const [sendImg, setSendImg] = useState(
    'https://d2jq593czhrhl9.cloudfront.net/1720672078.709602.png'
  )
  const [CNICfront, setCNICfront] = useState('')
  const [CNICback, setCNICback] = useState('')

  // state for img in base64 form
  const [preview, setPreview] = useState('')
  const [front_preview, setfront_preview] = useState('')
  const [back_preview, setback_preview] = useState('')

  // state for delete img
  const [drop, setDrop] = useState(false)
  const [show_del, setShow_del] = useState(false)
  const [show_del_c, setShow_del_c] = useState(false)
  const [show_del_b, setShow_del_b] = useState(false)

  const fileInputRef = useRef()
  const cnicFrontside = useRef()
  const cnicBackside = useRef()

  // form data state
  const [Fname, setFname] = useState('')
  const [Lname, setLname] = useState('')
  const [Title, setTitle] = useState('')
  const [DOB, setDOB] = useState('')
  const [Gender, setGender] = useState('')
  const [EphoneNo, setEphoneNo] = useState('')
  const [City, setCity] = useState('')
  const [NOK_name, setNOK_name] = useState('')
  const [NOK_phoneNo, setNOK_phoneNo] = useState('')
  const [NOK_relation, setNOK_relation] = useState('')

  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')

  const handleEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // console.log("enter!");
  }

  const handleOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // console.log("over!");
  }

  const handleLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    // console.log("leave!");
  }

  //upload Profile images
  const handleUpload = (e) => {
    e.preventDefault()
    setShow_del(false)
    setDrop(true)
    const [file] = e.target.files || e.dataTransfer.files
    uploadFile(file)
  }

  const uploadFile = (file) => {
    // preview img
    const reader = new FileReader()
    reader.readAsBinaryString(file)

    //preview onload function.
    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      setPreview(`data:image/jpg;base64,${fileRes}`)
      const testImg = toArrayBuffer(fileRes)
      let imgURL = await callApi(testImg)
      // console.log(imgURL);
      setSendImg(imgURL)
      setShow_del(true)
    }

    reader.onerror = () => {
      // console.log('There is a problem while uploading...')
    }
  }

  function CNIC_front_upload(e) {
    e.preventDefault()
    // e.stopPropagation();
    setShow_del_c(false)

    const [file] = e.target.files || e.dataTransfer.files
    const reader = new FileReader()
    reader.readAsBinaryString(file)

    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      setfront_preview(`data:image/jpg;base64,${fileRes}`)
      const testImg = toArrayBuffer(fileRes)
      let imgURL = await callApi(testImg)
      setCNICfront(imgURL)
      setShow_del_c(true)
    }

    reader.onerror = () => {
      // console.log('There is a problem while uploading...')
    }
  }

  // upload CNIC Back-side images

  function CNIC_back_upload(e) {
    e.preventDefault()
    e.stopPropagation()
    setShow_del_b(false)
    const [file] = e.target.files || e.dataTransfer.files
    const reader = new FileReader()
    reader.readAsBinaryString(file)

    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      setback_preview(`data:image/jpg;base64,${fileRes}`)
      const testImg = toArrayBuffer(fileRes)
      let imgURL = await callApi(testImg)
      setCNICback(imgURL)
      setShow_del_b(true)
    }

    reader.onerror = () => {
      // console.log('There is a problem while uploading...')
    }
  }

  // upload img api call function.
  const callApi = async (imgBuffer) => {
    // console.log(imgBuffer);
    let apiData

    const data = imgBuffer
    await axios
      .post( `${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${params.id}/type/new`, data, {
        headers: API_CONSTANTS.imgheaders(),
      })
      .then((r) => {
        apiData = r.data.response.url
      })
      .catch((e) => console.log())

    return apiData
  }

  // --------  This function is for call the Toast message  ------
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }

  useEffect(() => {
    axios
      .get(`${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/updateDoctor/personal_info`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((e) => {
        // console.log(e.data.response)
        setCity(e.data.response.city)
        setFname(e.data.response.firstName)
        setLname(e.data.response.lastName)
        setGender(e.data.response.gender)
        setTitle(e.data.response.title)
        setDOB(e.data.response.dob)
        setEphoneNo(e.data.response.emergencyNumber)
        setSendImg(e.data.response.profileUrl)
        setNOK_name(e.data.response.nextOfKin.name)
        setNOK_phoneNo(e.data.response.nextOfKin.phone_no)
        setNOK_relation(e.data.response.nextOfKin.relation)
        setCNICfront(e.data.response.cnicFront)
        setCNICback(e.data.response.cnicBack)
      })
      .catch((e) => console.log(e))
  }, [])
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: Fname,
          lastName: Lname,
          dob: DOB,
          title: Title,
          gender: Gender,
          emergencyNumber: EphoneNo,
          city: City,
          profileUrl: sendImg,
          cnicFront: CNICfront,
          cnicBack: CNICback,
          nextOfKin: {
            name: NOK_name,
            phone_no: NOK_phoneNo,
            relation: NOK_relation,
          },
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Please enter the valid name'),
          lastName: Yup.string()
            .max(15, 'Must be 15 characters or less')
            .required('Please enter the valid name'),
          // emergencyNumber: Yup.string()
          //   .max(15, "Must be 15 characters or less")
          //   .required("Please enter the valid Phone No"),
          title: Yup.string().required('Please enter the select title'),
          gender: Yup.string().required('Please enter the select gender'),
          dob: Yup.string().required('Please select Date of Birth'),
          city: Yup.string().required('Please enter the select city'),
          cnicFront: Yup.string().required('Please upload the cnic picture.'),
          cnicBack: Yup.string().required('Please upload the cnic picture.'),

          nextOfKin: Yup.object({
            name: Yup.string().required('Enter the fullname'),
            phone_no: Yup.string().required('Enter the phone no'),
            relation: Yup.string().required('Enter the relation'),
          }),
        })}
        onSubmit={(values) => {
          setCreateSpin('spinner-border spinner-border-sm')
          // console.log("done22");
          axios
            .patch( `${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/personalInfo`, values, {
              headers: API_CONSTANTS.headers(),
            })
            .then((e) => {
              setCreateSpin('')
              handleToast(
                e.data.response.message,
                faCheckCircle,
                'rgba(0,155,148,0.93)',
                'show-success'
              )
              setTimeout(() => props.alert(stage), 2000)
            })
            .catch((e) => {
              setCreateSpin('')
            })
        }}
      >
        {(formik) => {
          // console.log(overview.adminRole);
          return (
            <Form>
              <div className={'form-group'}>
                <div className='d-flex' style={{ paddingLeft: '8px' }}>
                  <div className='rapper'>
                    <div
                      className='image'
                      onDragEnter={(e) => handleEnter(e)}
                      onDragLeave={(e) => handleLeave(e)}
                      onDragOver={(e) => handleOver(e)}
                      onDrop={(e) => handleUpload(e)}
                    >
                      {(sendImg === '' ? preview : sendImg) && (
                        <img
                          src={sendImg === '' ? preview : sendImg}
                          className={'profileImg'}
                          alt='profile pic'
                        ></img>
                      )}
                    </div>
                    <div>
                      <div className='text d-flex justify-content-center'>
                        <img src={uploadIcon} alt={'profile-pic'} />
                      </div>
                      <div className='text '>
                        <h6
                          className={
                            'img-heading d-flex justify-content-center mb-0 mt-3'
                          }
                        >
                          Profile Photo
                        </h6>
                        <h6
                          className={
                            'img-heading d-flex justify-content-center'
                          }
                        >
                          Drag / Click to upload
                        </h6>
                      </div>
                    </div>
                  </div>
                  <input
                    id='default-btn'
                    ref={fileInputRef}
                    accept='image/*'
                    onChange={(e) => handleUpload(e)}
                    type='file'
                    hidden
                  />
                  <button
                    id='custom-button'
                    type='button'
                    onClick={() => fileInputRef.current.click()}
                    // onClick={uploadFile}
                  >
                    Upload Image
                  </button>
                  {show_del ? (
                    <button
                      className={'delete-btn'}
                      type={'button'}
                      title={'img upload successfully'}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </button>
                  ) : null}
                </div>
                <div className={'row mt-5 d-flex'}>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <input
                        autoComplete='off'
                        type='text'
                        name='firstName'
                        value={formik.values.firstName}
                        onChange={(e) => {
                          let element = e.target
                          element.value = element.value.replace(
                            /[^A-Za-z\s]/gi,
                            ''
                          )
                          setFname(e.target.value)
                          formik.handleChange(e)
                        }}
                        validateOnChange={true}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'} for='name'>
                        First Name
                      </label>
                    </div>
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className={'error-field '}>
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <input
                        type='text'
                        autoComplete={'false'}
                        value={formik.values.lastName}
                        name='lastName'
                        onChange={(e) => {
                          setLname(e.target.value)

                          formik.handleChange(e)
                        }}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'}>Last Name</label>
                    </div>
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div className={'error-field '}>
                        {formik.errors.lastName}
                      </div>
                    ) : null}
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group col-md-2'>
                      <div className='input-block'>
                        <select
                          className={'select'}
                          value={formik.values.title}
                          name='title'
                          style={{ margintop: '8px' }}
                          onChange={(e) => {
                            setTitle(e.target.value)
                            formik.handleChange(e)
                          }}
                        >
                          <option value=''></option>
                          <option value='Dr'>Dr</option>
                          <option value='Ms'>Ms</option>
                        </select>

                        <label className={'p_label placeholder'} id='place'>
                          Title (e.g. Dr, Ms)
                        </label>
                      </div>
                    </div>
                    {formik.touched.title && formik.errors.title ? (
                      <div className={'error-field mt-5 ml-2 '}>
                        {formik.errors.title}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={'row mt-4'}>
                  <div className={'col-md-4'}>
                    <div className='group'>
                      <input
                        type='date'
                        value={formik.values.dob}
                        name='dob'
                        data-date-inline-picker='true'
                        onChange={(e) => {
                          setDOB(e.target.value)
                          formik.handleChange(e)
                        }}
                        max={today_date}
                        style={{ height: '39px' }}
                      />
                      <span className='highlight'></span>
                      <label className={'p_label'}>Date of Birth</label>
                      <span className='bar'></span>
                      {formik.touched.dob && formik.errors.dob ? (
                        <div className={'error-field mt-2'}>
                          {formik.errors.dob}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <div className='input-block'>
                        <select
                          spellCheck='false'
                          className={'select p_label'}
                          value={formik.values.gender}
                          name='gender'
                          style={{ margintop: '8px' }}
                          onChange={(e) => {
                            setGender(e.target.value)
                            formik.handleChange(e)
                          }}
                        >
                          <option value=''></option>
                          <option value='Male'>Male</option>
                          <option value='Female'>Female</option>
                          <option value='Other'>Other</option>
                        </select>

                        <label className={'p_label placeholder'} id='place'>
                          Gender
                        </label>
                      </div>
                    </div>
                    {formik.touched.gender && formik.errors.gender ? (
                      <div className={'error-field mt-5 '}>
                        {formik.errors.gender}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={'row mt-2'}>
                  <div className={'col-md-4'} style={{ paddingTop: '2px' }}>
                    <div className='group mb-2'>
                      <input
                        type='text'
                        value={formik.values.emergencyNumber}
                        name='emergencyNumber'
                        onChange={(e) => {
                          let element = e.target
                          element.value = element.value.replace(/[^0-9]/g, '')
                          setEphoneNo(e.target.value)
                          formik.handleChange(e)
                        }}
                        autoComplete={'off'}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'}>
                        Emergency Contact (optional)
                      </label>
                    </div>
                    {formik.touched.emergencyNumber &&
                    formik.errors.emergencyNumber ? (
                      <div className={'error-field '}>
                        {formik.errors.emergencyNumber}
                      </div>
                    ) : null}
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <div className='input-block'>
                        <select
                          spellCheck='false'
                          className={'select'}
                          name='city'
                          value={formik.values.city}
                          onChange={(e) => {
                            setCity(e.target.value)
                            formik.handleChange(e)
                          }}
                        >
                          <option value=''></option>
                          <option value='Lahore'>Lahore</option>
                          <option value='Islamabad'>Islamabad</option>
                          <option value='Mianwali'>Mianwali</option>
                        </select>

                        <label className={'p_label '} id='place'>
                          City
                        </label>
                      </div>
                    </div>
                    {formik.touched.city && formik.errors.city ? (
                      <div className={'error-field mt-5 '}>
                        {formik.errors.city}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className=' d-flex px-2 mt-4'>
                  <input
                    id='default-btn'
                    ref={cnicFrontside}
                    name='cnicFront'
                    accept='image/*'
                    onChange={(e) => CNIC_front_upload(e)}
                    type='file'
                    hidden
                  />
                  <button
                    id='custom-button'
                    type='button'
                    onClick={() => cnicFrontside.current.click()}
                    className={'m-0'}
                  >
                    Upload
                  </button>
                  <p className={'cnic-text'}>
                    Front side of your CNIC
                    {formik.touched.cnicFront && formik.errors.cnicFront ? (
                      <div className={'error-field'}>
                        {formik.errors.cnicFront}
                      </div>
                    ) : null}
                  </p>

                  {(CNICfront === '' ? front_preview : CNICfront) && (
                    <div
                      className='rapper front-cnic'
                      style={{ marginLeft: '85px' }}
                    >
                      <div className='image front-cnic'>
                        <img
                          src={CNICfront === '' ? front_preview : CNICfront}
                          className={'front-cnic'}
                          alt='profile pic'
                        ></img>
                      </div>
                    </div>
                  )}
                  {show_del_c ? (
                    <button
                      className={'delete-btn'}
                      title={'Img Upload Successfully'}
                      type={'button'}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </button>
                  ) : null}
                </div>
                <div className='d-flex px-2 mt-4'>
                  <input
                    id='default-btn'
                    ref={cnicBackside}
                    accept='image/*'
                    onChange={(e) => CNIC_back_upload(e)}
                    type='file'
                    hidden
                  />
                  <button
                    id='custom-button'
                    type='button'
                    onClick={() => cnicBackside.current.click()}
                    className={'m-0'}
                  >
                    Upload
                  </button>
                  <p className={'cnic-text'}>
                    Back side of your CNIC
                    {formik.touched.cnicBack && formik.errors.cnicBack ? (
                      <div className={'error-field'}>
                        {formik.errors.cnicBack}
                      </div>
                    ) : null}
                  </p>
                  {(CNICback === '' ? back_preview : CNICback) && (
                    <div
                      className='rapper front-cnic'
                      style={{ marginLeft: '85px' }}
                    >
                      <div className='image front-cnic'>
                        <img
                          src={CNICback === '' ? back_preview : CNICback}
                          className={'front-cnic'}
                          alt='profile pic'
                        ></img>
                      </div>
                    </div>
                  )}
                  {show_del_b ? (
                    <button
                      className={'delete-btn'}
                      type={'button'}
                      title={'img upload successfully'}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </button>
                  ) : null}
                </div>
                <h6 className={'mt-4 next-of-kin'}>Next of Kin</h6>
                <div className={'row mt-4 d-flex'}>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <input
                        type='text'
                        id={'nextOfKin.name'}
                        name={'nextOfKin.name'}
                        value={formik.values.nextOfKin.name}
                        onChange={(e) => {
                          let element = e.target
                          element.value = element.value.replace(
                            /[^A-Za-z\s]/gi,
                            ''
                          )
                          setNOK_name(e.target.value)
                          // formik.handleChange(e);
                        }}
                        autoComplete={'off'}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'}>Full Name</label>
                    </div>
                    {formik.touched.nextOfKin &&
                    formik.touched.nextOfKin.name &&
                    formik.errors.nextOfKin &&
                    formik.errors.nextOfKin.name ? (
                      <div className={'error-field '}>
                        {formik.errors.nextOfKin &&
                          formik.errors.nextOfKin.name}
                      </div>
                    ) : null}
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group mb-2'>
                      <input
                        type='text'
                        id={'nextOfKin.phone_no'}
                        name={'nextOfKin.phone_no'}
                        value={formik.values.nextOfKin.phone_no}
                        onChange={(e) => {
                          let element = e.target
                          element.value = element.value.replace(/[^0-9]/g, '')
                          setNOK_phoneNo(e.target.value)
                          // formik.handleChange(e);
                        }}
                        autoComplete={'off'}
                      />
                      <span className='highlight'></span>
                      <span className='bar'></span>
                      <label className={'p_label'}>Phone No</label>
                    </div>
                    {formik.touched.nextOfKin &&
                    formik.touched.nextOfKin.phone_no &&
                    formik.errors.nextOfKin &&
                    formik.errors.nextOfKin.phone_no ? (
                      <div className={'error-field'}>
                        {formik.errors.nextOfKin &&
                          formik.errors.nextOfKin.phone_no}
                      </div>
                    ) : null}
                  </div>
                  <div className={'col-md-4'}>
                    <div className='group col-md-7'>
                      <div className='input-block'>
                        <select
                          className={'select'}
                          // style={{ margintop: "8px" }}
                          value={formik.values.nextOfKin.relation}
                          name='nextOfKin.relation'
                          id={'nextOfKin.relation'}
                          onChange={(e) => {
                            setNOK_relation(e.target.value)
                            // formik.handleChange(e);
                          }}
                        >
                          <option value=''></option>
                          <option value='father'>Father</option>
                          <option value='son'>Son</option>
                          <option value='Other'>Other</option>
                        </select>

                        <label className={'p_label placeholder'} id='place'>
                          Relation
                        </label>
                      </div>
                    </div>
                    {formik.touched.nextOfKin &&
                    formik.touched.nextOfKin.relation &&
                    formik.errors.nextOfKin &&
                    formik.errors.nextOfKin.relation ? (
                      <div className={'error-field mt-5 ml-2'}>
                        {formik.errors.nextOfKin &&
                          formik.errors.nextOfKin.relation}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={'card-footer bg-white border-0 px-0'}>
                  <div className={' px-2 py-4'}>
                    <button
                      className={'btn btn-save-Procced'}
                      type='submit'
                      // onClick={() => props.alert(stage)}
                    >
                      <span className={Createspin} />
                      Save & Proceed
                    </button>
                    {/* <button
                      className={"btn btn btn-submit mx-3 "}
                      disabled={true}
                    >
                      Submit
                    </button>
                    <button className={"btn btn-save-exit"}>Save & Exit</button> */}
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
      {/* ------------------------- Toast Message Code  ------------------------------- */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style>{`
    /* form starting stylings ------------------------------- */
.add-doctor-form {
  width: 320px !important;
}

.btn-otp {
  margin: 0px !important;
  margin-left: 16px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  height: 40px !important;
  border: 1px solid #009b94;
  border-radius: 4px;
  letter-spacing: 1.25px;
  color: #009b94;
  background-color: #ffffff;
}

.btn-otp:hover {
  background-color: rgba(0, 155, 148, 0.07) !important;
}

.btn-otp:active {
  outline: none;
}

.btn-otp:focus {
  outline: none;
}
error-feild{
   font-size: 14px;
          letter-spacing: 0.5px;
          color: #e52444;
}
button.search-field {
  padding: 0 15px;
  background-color: #009b94;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.searchInput {
  border: none;
  border-bottom: 1px solid white;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.searchInput:focus {
  border-color: #009b94 !important;
}
.search-field i {
  color: white;
}

.btn-createAccount:not(:disabled) {
  margin: 0px !important;
  background-color: #009b94 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1.25px;
  color: #ffffff;
  border: none;
  transition: 0.2s ease-in-out;
}

.btn-createAccount:hover {
  background-color: #99d7d4 !important;
}

.btn-createAccount:disabled {
  background-color: #99d7d4 !important;
  cursor: not-allowed !important;
  margin: 0px !important;
  color: #ffffff;
  border: none;
  transition: 0.2s ease-in-out;
}
.checkbox {
  width: fit-content !important;
  margin-top: 3px;
}

/*.bind-group > select input {*/
/*  border-bottom: none !important;*/
/*}*/

.input-danger,
.input-danger:focus {
  border-bottom: 1px solid #e52444 !important;
}

.error-field {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #e52444;
}

/*  -------------------------------------   Add Doctor CSS   ----------------------------  */

/*.phone_input {*/
/*  width: fit-content;*/
/*  border: 1px none gray;*/
/*  border-bottom-style: solid;*/
/*}*/

.form-backLink {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #1e1e1e;
}

.fa-icon {
  color: #99d7d4 !important;
}

.perv,
.next {
  color: #99d7d4 !important;
}

.perv:hover,
.next:hover {
  color: #009b94 !important;
}

.btn-account {
  background: #009b94;
  border: 1px solid #ffffff;
  padding: 12px 16px;
  color: #ffffff;
  transition: 0.5s ease-in-out;
}

.btn-account:hover {
  background: #006b66;
  border: 1px solid #009b94;
  color: #ffffff;
  transition: 0.5s ease-in-out;
}

.btn-proceed {
  border: none !important;
  color: #009b94;
}

.btn-proceed:hover {
  border: none;
  box-shadow: none !important;
  text-decoration: underline;
  color: rgba(1, 190, 181, 0.87);
}

.d_list {
  color: #1e1e1e;
  font-size: 14px;
}

.d_list:hover {
  color: #1e1e1e;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.card-footer {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/*====================  Input field CSS Styling  ===========================*/

.group {
  position: relative;
  margin-bottom: 25px;
  width: 100% !important;
}

input,
.group select {
  font-size: 15px;
  padding: 12px 4px 2px 0px;
  display: block;
  border: none;
  border-bottom: 1px solid #cccccc;
}

/*.remove-style {*/
/*  border: none !important;*/
/*}*/

input {
  width: 100% !important;
}

.group select {
      width: 326px;
    /* height: 19px; */
    margin-top: 10px;
    /* padding: 0px; */
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-position-x: 38px;
    background-position-y: 21px;
    background-size: 551px 5px;
    cursor: pointer;
    // border: none;
    position: absolute;
    top: -9px;
    left: 0px;
}

.df-select {
  margin-top: 8px !important;
  background-position-x: 265px !important;
}


.cnic-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #5c5c5c;
  padding-top: 10px;
  margin-bottom: 0px;
  margin-left: 16px;
}

.front-cnic {
  width: 55px !important;
  height: 40px !important;
  border-radius: 2px;
}

.next-of-kin {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #5c5c5c;
  margin-top: 25px !important;
}

input:focus,
select {
  outline: none;
  border-bottom: 1px solid #009b94;
}

/* LABEL ======================================= */
label.p_label {
  color: #8e8e8e;
  font-size: 14px;
  font-weight: 400 !important;
  position: absolute;
  pointer-events: none;
  left: 1px;
  top: -10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label,
.group select:valid ~ label,
.group select:focus ~ label {
  top: -10px;
  font-size: 14px;
  color: #8e8e8e;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after,
.group select:focus ~ .bar:before,
.group select:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;

  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight,
.group select:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #f7f7f7;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #f3f3f3;
  }

  to {
    width: 0;
    background: transparent;
  }
}

.input {
  position: absolute;
  font-size: 20px;
}

.div {
  position: relative;
  overflow: hidden;
}

/* .dropdown-form {
  width: 100%;
  margin: 0 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);
  font-family: "Lato", sans-serif;
}

@media (min-width: 768px) {
  .dropdown{
    width: 600px;
  }
} */

----

.dropdown__select {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 5px;
  overflow: hidden;
  box-shadow: 0 5px 10px 0 rgba(152, 152, 152, 0.6);
  transform: scaleY(0);
  transform-origin: top;
  font-weight: 300;
  transition: 0.2s ease-in-out;
}

.dropdown__select-option {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6;
  transition: 0.3s;
}

.dropdown__select-option:last-of-type {
  border-bottom: 0;
}

.dropdown__select-option:hover {
  background-color: #f9f9f9;
}

.rapper {
  position: relative;
  height: 160px;
  width: 160px;
  border: 1px dashed #cccccc;
  border-radius: 6px;
  background-color: #f6f6f6;
  align-items: center;
  justify-content: center;
  display: flex;
}

.rapper .image {
  position: absolute;
  height: 160px;
  width: 160px;
  align-items: center;
  justify-content: center;
}

.rapper .image img {
  height: 160px;
  width: 160px;
  object-fit: cover;
}

.icon {
  color: #99d7d4;
  margin-right: 90px;
  font-size: 100px;
  margin-bottom: 20px;
  align-items: center;
  position: relative;
  left: 17%;
}

.rapper .text {
  font-size: 15px;
  font-weight: 400;
  /*margin-bottom: 100px;*/
  /*justify-content: center;*/
}

.img-heading {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #5c5c5c;
}

.profileImg {
  border-radius: 6px;
}

#custom-button {
  height: 40px;
  margin-bottom: 100px;
  margin-left: 15px;
  color: #009b94;
  background-color: #ffffff;
  border: 1px solid;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.delete-btn {
  width: 23px;
  height: 25px;
  margin-left: 12px;
  margin-top: 5px;
  border: none;
  outline: none;
  background-color: white;
  color: #ff6760;
}

.delete-btn:hover {
  color: rgba(255, 103, 96, 0.87);
}

#custom-button:focus,
.delete-btn:focus {
  outline: none;
}

.upload-btn {
  color: #009b94;
  background-color: #ffffff;
  border: 1px solid #009b94;
  border-radius: 6px;
  padding: 8px 20px;
}

.upload-btn:focus {
  outline: none;
}

.pra {
  margin-top: 8px;
  color: #5c5c5c;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 10px;
  padding-top: 10px;
}

.upload-bt {
  color: #009b94;
  background-color: #ffffff;
  border: 1px solid #009b94;
  border-radius: 6px;
  padding: 8px 20px;
}

.upload-bt:focus {
  outline: none;
}

.para {
  margin-top: 8px;
  color: #5c5c5c;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 10px;
  padding-top: 10px;
}

.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}

.customModal {
  background: #b2dbbf;
  max-width: 500px;
  width: 100%;
}

.btn-save-Procced,
.btn-save-exit {
  margin: 0px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  height: 40px !important;
  border: 1px solid #009b94;
  border-radius: 4px;
  letter-spacing: 1.25px;
  color: #009b94;
  background-color: #ffffff;
}

.btn-save-exit {
  color: #5c5c5c;
  border: 1px solid #5c5c5c;
}

.title-heading {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #1e1e1e;
  font-weight: 400;
}
.sub-title-heading {
  font-size: 12px;
  color: #5c5c5c;
  font-weight: 400;
}

.react-tag-input__input {
  width: auto !important;
}
.react-tag-input__tag {
  width: auto !important;
  background: #eeeeee !important;
}

.react-tag-input {
  border: none !important;
  border-bottom: 1px solid #cccccc !important;
  border-radius: 0 !important;
  padding: 0.1875em 0 !important;
}

.react-tag-input__tag__remove {
  background: #eeeeee !important;
}

.react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  background-color: #1ea7a1 !important;
}

textarea:focus {
  border-color: #009b94 !important;
}

.cover-letter {
  position: absolute;
  bottom: 4px;
  right: 10px;
  color: #8e8e8e;
}

.btn-white {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  color: #009b94;
  border: 1px solid #009b94;
}

select {
  background-color: white !important;
}



      
      `}</style>
    </>
  )
}

export default Personal_Info_Form
