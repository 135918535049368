import { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import Notification from '../../resources/images/Notification.png'
import { useSelector } from "react-redux";
import { getNotifications } from "./notificationsSlice";
import { useDispatch } from "react-redux";
import Spinner from 'react-bootstrap/Spinner'
// notifications
export default function Notifications() {
  const dispatch = useDispatch()
  const { loading, value, lastToken } = useSelector((state) => state.notificationReducer)
  const loadMore = () => {
    dispatch(getNotifications({ token: lastToken }))
  };
  useEffect(() => {
    if (value.length === 0) {
      dispatch(getNotifications({ token: null }))
    }
  }, []);
  return (
    <section style={{ backgroundColor: '#F6F6F6' }}>
      <div className="row pt-5 mt-0 profile-header">
        <div className="col">
          <div className="d-flex ms-4">
            <div className="patient d-flex ms-4 align-items-start me-4">
              <span className="me-4">
                <div className="patient-avatar">
                  <img
                    src={Notification}
                    alt=""
                    style={{ width: 48, borderRadius: 50, height: 60 }}
                  />
                </div>
              </span>
              <div className="d-flex flex-column mt-2">
                <h5
                  style={{ fontWeight: 500, marginBottom: 10, marginTop: -4 }}
                >
                  Notifications
                </h5>
                <h6
                  style={{
                    fontWeight: 400,
                    color: "#5C5C5C",
                    marginBottom: 0,
                  }}
                >
                  View & Custom
                </h6>
              </div>
            </div>
            <span className="border-line"></span>
          </div>
        </div>
      </div>
      <div className={'m-4 card d-flex flex-column feedback-back'}>
        {loading && (
          <div className='d-flex justify-content-center mt-3'>
            <Spinner animation='border' style={{ color: '#24AAA4' }} />
          </div>
        )}
        {value.map((item, index) => {
          if (item.notification_type === "delayAlert") {
            return (
              <Link
                className={`card mb-3 px-2 m-3`}
                key={index}
                to={`/consultations/profile/${item?.appointmentId}/overview`}
                style={{
                  borderLeft: item.read ? '8px solid #99D7D4' : '8px solid #009b94',
                  background: item.read ? '#FFFFFF' : 'rgba(0, 155, 148, 0.08)',
                  borderRadius: '8px',
                  width: '80%',
                }}
              >
                <div className="d-flex justify-content-between mt-3 m-0 ">
                  <span className="d-flex gap-2">
                    <img
                      src={(item?.url === " " || item?.url === "None") ?
                        'https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black-thumbnail.png'
                        : item?.url}
                      alt=""
                      style={{ width: 25, height: 25, borderRadius: '50%' }}
                    />
                    <h5 className="capitalize namefeedback">{item.name}</h5>
                  </span>
                  <p className="namefeedback">{moment(item.created_at).endOf().fromNow()}</p>
                </div>
                <h5
                  style={{
                    color:
                      item.title === "Appointment Alert"
                        ? "red "
                        : "rgba(46, 212, 122, 1)",
                    marginTop: '0px'
                  }}
                >
                  {item?.title}
                </h5>
                <p>{item?.body}</p>
              </Link>
            );
          } else if (item.notification_type === "feedback") {
            // Redirect to feedback page
            return (
              <Link
                className={`card mb-3 px-2 m-3`}
                key={index}
                to={`/feedback`}
                style={{
                  borderLeft: item.read ? '8px solid #99D7D4' : '8px solid #009b94',
                  background: item.read ? '#FFFFFF' : 'rgba(0, 155, 148, 0.08)',
                  borderRadius: '8px',
                  width: '80%',
                }}
              ><div className="d-flex justify-content-between mt-3 m-0 ">
                  <span className="d-flex gap-2">
                    <img
                      src={(item?.url === " " || item?.url === "None") ?
                        'https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black-thumbnail.png'
                        : item?.url}
                      alt=""
                      style={{ width: 25, height: 25, borderRadius: '50%' }}
                    />
                    <h5 className="capitalize namefeedback">{item.name}</h5>
                  </span>
                  <p className="namefeedback">{moment(item.created_at).endOf().fromNow()}</p>
                </div>
                <h5
                  style={{
                    color:
                      item.title === "Appointment Alert"
                        ? "red "
                        : "rgba(46, 212, 122, 1)",
                    marginTop: '0px'
                  }}
                >
                  {item?.title}
                </h5>
                <p>{item?.body}</p>
              </Link>
            );
          } else if (item.notification_type === "lab appointment Reminder") {
            // Redirect to feedback page
            return (
              <Link
                className={`card mb-3 px-2 m-3`}
                key={index}
                to={`/lab/profile/${item?.appointmentId}/overview`}
                style={{
                  borderLeft: item.read ? '8px solid #99D7D4' : '8px solid #009b94',
                  background: item.read ? '#FFFFFF' : 'rgba(0, 155, 148, 0.08)',
                  borderRadius: '8px',
                  width: '80%',
                }}
              ><div className="d-flex justify-content-between mt-3 m-0 ">
                  <span className="d-flex gap-2">
                    <img
                      src={(item?.url === " " || item?.url === "None") ?
                        'https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black-thumbnail.png'
                        : item?.url}
                      alt=""
                      style={{ width: 25, height: 25, borderRadius: '50%' }}
                    />
                    <h5 className="capitalize namefeedback">{item.name}</h5>
                  </span>
                  <p className="namefeedback">{moment(item.created_at).endOf().fromNow()}</p>
                </div>
                <h5
                  style={{
                    color:
                      item.title === "Appointment Alert"
                        ? "red "
                        : "rgba(46, 212, 122, 1)",
                    marginTop: '0px'
                  }}
                >
                  {item?.title}
                </h5>
                <p>{item?.body}</p>
              </Link>
            );
          } else {
            return (
              <div
                className={`card mb-3 px-2 m-3`}
                key={index}
                style={{
                  borderLeft: item.read ? '8px solid #99D7D4' : '8px solid #009b94',
                  background: item.read ? '#FFFFFF' : 'rgba(0, 155, 148, 0.08)',
                  borderRadius: '8px',
                  width: '80%',
                }}
              >
                <div className="d-flex justify-content-between mt-3 m-0 ">
                  <span className="d-flex gap-2">
                    <img
                      src={(item?.url === " " || item?.url === "None") ?
                        'https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black-thumbnail.png'
                        : item?.url}
                      alt=""
                      style={{ width: 25, height: 25, borderRadius: '50%' }}
                    />
                    <h5 className="capitalize namefeedback">{item.name}</h5>
                  </span>
                  <p className="namefeedback">{moment(item.created_at).endOf().fromNow()}</p>
                </div>
                <h5
                  style={{
                    color:
                      item.title === "Appointment Alert"
                        ? "red "
                        : "rgba(46, 212, 122, 1)",
                    marginTop: '0px'
                  }}
                >
                  {item?.title}
                </h5>
                <p>{item?.body}</p>
              </div>
            )
          }
        })}
        <div className="d-flex justify-content-center mb-3">
          <LoadingButton variant="contained" onClick={loadMore}>
            Load more
          </LoadingButton>
        </div>
      </div>
      <style jsx='true'>{`
      .profile-header {
        background: #e0f3f2;
        height: 160px;
      }
      .namefeedback {
        display: flex;
        align-items: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.078px;
        text-transform: uppercase;
        color: #5c5c5c;
      }
      .feedback-back {
        background: #F6F6F6;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
      }
      .feedback {
        font-family: Roboto;
        font-style: medium;
        font-weight: 500;
        font-size: 20px;
        line-height: 19px;
        color: #000000;
      }
      @media (max-width: 1000px){
        .feedback-back{
          width: 100% !important
        }
      }
       `}</style>
    </section>
  )
}
