/*
Authore: Umer Shabir
Date: 22-02-20223
File: add data page and it's all related routes.
*/
// dependencies
// Dependencies
import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Outlet } from 'react-router'
import { PROFILE_NAV_LINKS } from './helper'
import SettingIcon from '../resources/settings.png'
import { useDispatch } from 'react-redux'
import { getData } from '../addDataSlice'
//Component
export default function AddDataPage() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getData())
  }, [])
  return (
    <>
      <div className='container-fluid profile-header'>
        <div className='row pt-4 mb-1 prof'>
          <div className='col'>
            <div className='d-flex ms-4 align-items-center pt-3'>
              <span className='me-4'>
                <img src={SettingIcon} alt='settingIcon' />
              </span>
              <div className='d-flex flex-column'>
                <h5
                  style={{ fontWeight: 500, marginBottom: 15, marginTop: -4 }}
                >
                  Settings
                </h5>
                <h6
                  style={{ fontWeight: 400, color: '#5C5C5C', marginBottom: 0 }}
                >
                  Add & Customize Data
                </h6>
              </div>
            </div>
          </div>
          <div className='col'>
            <div
              className='d-flex justify-content-end'
              style={{ columnGap: 5 }}
            ></div>
          </div>
        </div>
        <div className='row pt-2'>
          <div className='col'>
            <div className='d-flex ms-4' style={{ columnGap: 10 }}>
              {PROFILE_NAV_LINKS.map((navItem) => (
                <NavLink
                  to={navItem.navRoute}
                  key={navItem.navLink}
                  className={({ isActive }) =>
                    isActive ? 'active' : 'inactive'
                  }
                  style={{ fontSize: 14 }}
                >
                  {navItem.navLink}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='containter-fluid'>
        <Outlet />
      </div>
      <style jsx='true'>
        {`
          .profile-header {
            background: #e0f3f2;
            height: 160px;
          }
          .active {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .inactive {
            padding: 7px 15px;
          }
          @media only screen and (max-width: 1270px) and (min-width: 1050px) {
            .prof{
              margin-bottom: 0px !important;
              padding-top: 10px !important;
            }
          }
          @media screen and (max-width: 1010px) {
            .prof{
              margin-bottom: 0px !important;
              padding-top: 10px !important;
            }
          }
        `}
      </style>
    </>
  )
}
