import React, { useMemo, useEffect, useState } from 'react'
import Table from '../../../../Components/Table/index'
import axios from 'axios'
import NurseFilterForm from './NurseFilterForm'
import { useSelector } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
//

import AlertMessage from '../../../../Components/AlertMessage'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
//
export default function NurseUsersList() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [nurseList, setNurseList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0)
  const [nurseSearch, setNurseSearch] = useState({
    number: '',
    countryCode: '+92',
  })
  const { number, countryCode } = nurseSearch
  //
  //

  const filter_status = {
    status: useSelector((state) => state.nurseFilterReducer.value),
    gender: useSelector((state) => state.nurseFilterReducer.value1),
    // age: useSelector((state) => state.nurseFilterReducer.value2),
    created_at: useSelector((state) => state.nurseFilterReducer.value2),
    appoint_date_from: useSelector((state) => state.nurseFilterReducer.value3),
  }
  //
  //
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = lastEvaluatedKey
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setNurseList(nurseList.slice(startRow, endRow))
        setPageCount(Math.ceil(nurseList.length / pageSize))
      }
    },
    [nurseList, lastEvaluatedKey]
  )
  const handleSearchInput = (event, state) => {
    if (event.reset) {
      return { number: '', countryCode: '+92' }
    }
    return setNurseSearch({
      ...state,
      number: event.target.value.slice(0, event.target.maxLength),
      countryCode: '+92',
    })
  }

  const handleNurseSearch = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_3}/admin/nurseSearch/${nurseSearch.countryCode}${nurseSearch.number}`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => setNurseList(res.data.response))

      .then(() => setNurseSearch({ countryCode: '+92', number: '' }))
      .catch((error) => alert(error.response.data.response.message))
  }
  const userList = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_3}admin/nurses`,
        {
          params: filter_status,
          headers: API_CONSTANTS.headers(),
        }
        )
      .then((res) => {
        setIsLoading(true)
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
        setNurseList(res.data.response.Items)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setIsLoading(true)
        setNurseList([])
      })
  }
  const [page, setPage] = useState(0)

  // handling load more data
  const handleLoadMoreData = () => {
    axios
    .get(
      `${API_CONSTANTS.BASEURL_3}admin/nurses?last=${lastEvaluatedKey}`,
      {
        params: filter_status,
        headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
        var nurseListNextPage = response.data.response.Items
        setNurseList([...nurseList, ...nurseListNextPage])
        setPage(page + 1)
      })
      .then(() => setLoading(true))
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (data) => data.Fname || 'No Name',
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNo',
      },
      {
        Header: 'Status',
        accessor: 'profileStatus',
      },
      // {
      //   Header: 'Speciality',
      //   accessor: 'specialization',
      //   Cell: ({ cell: { value } }) => value || 'N/A',
      // },
      {
        Header: 'Age',
        accessor: (data) => {
          const dob = moment(data.dob);
          const age = moment().diff(dob, 'years');
          return age ? `${age} years` : '-';
        },
      },
      // {
      //   Header: 'Gender',
      //   accessor: 'gender',
      //   Cell: ({ cell: { value } }) => value || 'N/A',
      // },
      {
        Header: 'Upcoming Appointments',
        accessor: (upcomingAppointmentCount) =>
          upcomingAppointmentCount.upcomingAppointmentCount === '1'
            ? upcomingAppointmentCount.upcomingAppointmentCount + ' Appointment'
            : (upcomingAppointmentCount.upcomingAppointmentCount +
              'Appointments' &&
              upcomingAppointmentCount.upcomingAppointmentCount === '0') ||
              upcomingAppointmentCount.upcomingAppointmentCount === undefined
              ? 'No Appointment'
              : upcomingAppointmentCount.upcomingAppointmentCount +
              ' Appointments',
      },
      {
        Header: 'Registered On',
        accessor: (a) => moment(a.created_at).format('DD-MM-YYYY'),
      },
    ],
    []
  )
  const is_SessionExpire = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  useEffect(() => {
    userList()
  }, [
    filter_status.created_at,
    filter_status.appoint_date_from,
    filter_status.gender,
    filter_status.status,
    // filter_status.age,
  ])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 401'
            ? is_SessionExpire()
            : error.message === 'Request failed with status code 404'
              ? 'record not found'
              : error.message
        }
      />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center  '>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <>
        <div className='nurse'>
          <div className='input-group mt-3 pe-4 ms-4' style={{ width: '50%' }}>
            <div className='form-outline d-flex'>
              <select
                name='countryCode'
                style={{
                  borderRadius: '7px 0 0 7px',
                  borderRight: '3px solid #009B94',
                  background: '#009B9414',
                  color: '#4E4E4E99',
                }}
              >
                <option
                  selected
                  defaultValue={countryCode}
                  onChange={handleSearchInput}
                >
                  +92
                </option>
              </select>
              <input
                type='number'
                id='form1'
                className='form-control patient-search-input'
                placeholder='Search'
                value={number}
                name='number'
                onChange={handleSearchInput}
                maxLength='10'
              />
            </div>
            <button
              type='button'
              className='btn'
              onClick={handleNurseSearch}
              disabled={
                nurseSearch.number.slice(0, 1) === '3' &&
                  nurseSearch.number.length === 10
                  ? false
                  : true
              }
              style={{ backgroundColor: 'rgb(0, 155, 148)', color: '#fff' }}
            >
              <i className='fas fa-search'></i>
            </button>
          </div>
        </div>
        <div className='row rounded bg-white m-4'>
          <div>
            <h3
              style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}
            >
              All Nurses
            </h3>
          </div>
          <Table
            pageNumber={page}
            columns={columns}
            data={nurseList}
            routingLink={'/nurses/profile'}
            pageCount={pageCount}
            fetchData={fetchData}
            loadMoreList={handleLoadMoreData}
            lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            setLoading={setLoading}
            FilterForm={<NurseFilterForm nurseList={userList} />}
          />
        </div>
        <style jsx='true'>{`
            .btn:focus {
              box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 25%) !important;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            .patient-search-input {
              border-radius: 0;
            }
          `}</style>
      </>
    )
  }
}
