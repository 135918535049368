import React, { useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { API_CONSTANTS } from "../../../../API_CONSTANTS";
import Toast from "../../../../Components/Toast/Toast";
import CloseIcon from "@mui/icons-material/Close";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/fontawesome-free-solid";

function Edit({ responseState }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ToastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState("");
  const [ToastIcon, setToastIcon] = useState(faTimesCircle);
  const [iconColor, setIconColor] = useState("");
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon);
    setToastMessage(message);
    setShowToast(`show ${className}`);
    setIconColor(iconColor);
    setTimeout(() => setShowToast(""), 2900);
  };

  const initialValues = {
    barcode: responseState.barcode,
    category: responseState.category,
    costPrice: responseState.costPrice,
    manufacturer: responseState.manufacturer,
    medicineName: responseState.MedicineName,
    productPrice: responseState.productPrice,
    purchaseConversionFactor: responseState.purchaseConversionFactor,
    suplierName: responseState.suplierName,
  };

  const validationSchema = Yup.object({
    medicineName: Yup.string().required("Medicine Name is Required"),
    category: Yup.string().required("Category is Required"),
    barcode: Yup.string().required("Barcode is required field"),
    costPrice: Yup.string().required("Cost Price  is Required"),
    manufacturer: Yup.string().required("Manufacturer is Required"),
    productPrice: Yup.string().required("Product Price is Required"),
    purchaseConversionFactor: Yup.string().required(
      "Conversion Factor is required field"
    ),
    suplierName: Yup.string().required("Suplier Name is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    axios
      .patch(
        `${API_CONSTANTS.BASEURL_1}admin/medicines/${responseState.Sk}`,
        values,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        if (response.data.message === "Success") {
          handleToast(
            "Medicine Updated Successfully",
            faCheckCircle,
            "#009b94",
            "show-success"
          );
        }
        handleClose();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <button className="btn" onClick={handleShow}>
        <i className="fas fa-pen" style={{ color: "#8E8E8E" }}></i>
      </button>
      <Modal show={show} size="lg">
        <Modal.Body className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <h4>Edit Medicine</h4>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="form-inline row g-3">
              <div className="col-md-6">
                <label htmlFor="inputName1" className="form-label">
                  Medicine Name
                </label>
                <Field
                  type="text"
                  name="medicineName"
                  placeholder="Medicine Name"
                  className="form-control"
                  id="inputName1"
                />
                <ErrorMessage
                  name="medicineName"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName2" className="form-label">
                  Category
                </label>
                <Field
                  type="text"
                  name="category"
                  placeholder="Tab"
                  className="form-control"
                  id="inputName2"
                />
                <ErrorMessage
                  name="category"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName3" className="form-label">
                  Barcode
                </label>
                <Field
                  type="text"
                  name="barcode"
                  placeholder="Code"
                  className="form-control"
                  id="inputName3"
                />
                <ErrorMessage
                  name="barcode"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName4" className="form-label">
                  Cost Price
                </label>
                <Field
                  type="number"
                  name="costPrice"
                  placeholder="Price in Number 1234"
                  className="form-control"
                  id="inputName4"
                />
                <ErrorMessage
                  name="costPrice"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName3" className="form-label">
                  Manufacturer
                </label>
                <Field
                  type="text"
                  name="manufacturer"
                  placeholder="Manufacturer"
                  className="form-control"
                  id="inputName3"
                />
                <ErrorMessage
                  name="manufacturer"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName4" className="form-label">
                  Product Price
                </label>
                <Field
                  type="number"
                  name="productPrice"
                  placeholder="Price in Number 1234"
                  className="form-control"
                  id="inputName4"
                />
                <ErrorMessage
                  name="productPrice"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName3" className="form-label">
                  Purchase Conversion Factor
                </label>
                <Field
                  type="text"
                  name="purchaseConversionFactor"
                  placeholder="Purchase Conversion Factor"
                  className="form-control"
                  id="inputName3"
                />
                <ErrorMessage
                  name="purchaseConversionFactor"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName4" className="form-label">
                  Supplier Name
                </label>
                <Field
                  type="text"
                  name="suplierName"
                  placeholder="Supplier Name"
                  className="form-control"
                  id="inputName4"
                />
                <ErrorMessage
                  name="suplierName"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="row mt-5 mb-3">
                <div className="col-md-6">
                  <button type="submit" className="btn btn-save w-100">
                    Save Changes
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-outline-danger w-100"
                    onClick={handleClose}
                    style={{ height: "43px" }}
                  >
                    Discard Changes
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>

      <style jsx="true">
        {`
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
            padding: 10px 10px !important;
          }

          .error-message {
            color: red;
          }
          .add-record {
            background: #009b94 !important;
            color: #fff;
          }
        `}
      </style>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  );
}
function Delete({ responseState }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ToastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState("");
  const [ToastIcon, setToastIcon] = useState(faTimesCircle);
  const [iconColor, setIconColor] = useState("");
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon);
    setToastMessage(message);
    setShowToast(`show ${className}`);
    setIconColor(iconColor);
    setTimeout(() => setShowToast(""), 2900);
  };

  const handleDelete = () => {
    axios
      .delete(API_CONSTANTS.DELETEMEDICINE_LIST(responseState.Sk), {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        if (res.data.message === "Success") {
          handleToast(
            "Medicine Deleted Successfully",
            faCheckCircle,
            "#009b94",
            "show-success"
          );
        }
        setShow(false);
      });
  };
  return (
    <>
      <button className="btn" onClick={handleShow}>
        <i className="fas fa-trash-alt" style={{ color: "#FF6760" }}></i>
      </button>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: 18 }}>Caution!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Medicine?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            style={{
              border: "1px solid #79747E",
              color: "#B00020",
              background: "none",
            }}
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn"
            style={{ background: "#009B93", color: "#fff" }}
            onClick={handleDelete}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  );
}

export default function ActionButtons({ responseState }) {
  return (
    <div style={{ width: "125px" }} className="d-flex">
      {/*edit button and modal */}
      <Edit responseState={responseState} />
      <Delete responseState={responseState} />
    </div>
  );
}
