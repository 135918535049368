import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import CloseIcon from '@mui/icons-material/Close'
import Toast from '../../../../Components/Toast/Toast'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'

export default function AddMember() {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }

  const initialValues = {
    barcode: '',
    category: '',
    costPrice: '',
    manufacturer: '',
    medicineName: '',
    productPrice: '',
    purchaseConversionFactor: '',
    suplierName: '',
  }
  const validationSchema = Yup.object({
    medicineName: Yup.string().required('Medicine Name is Required'),
    category: Yup.string().required('Category is Required'),
    barcode: Yup.string().required('Barcode is required field'),
    costPrice: Yup.string().required('Cost Price  is Required'),
    manufacturer: Yup.string().required('Manufacturer is Required'),
    productPrice: Yup.string().required('Product Price is Required'),
    purchaseConversionFactor: Yup.string().required('Conversion Factor is required field'),
    suplierName: Yup.string().required('Suplier Name is required'),
  })


  const handleSubmit = (values, { setSubmitting }) => {
    axios
      .post(`${API_CONSTANTS.BASEURL_1}admin/medicines`, values, {
        headers: API_CONSTANTS.headers(),
      })
      .then(response => {
        if (response.data.message === 'Success') {
          handleToast(
            'Medicine added successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
        }
        // toast.success('Medicine added successfully')
        handleClose()
      })
      .catch(error => {
        // toast.error('Failed to add medicine')
      })
      .finally(() => {
        setSubmitting(false);
      });
  }
  return (
    <>
      <button className="add-record btn mx-4 " onClick={handleShow}>
        <i className="fas fa-plus" style={{ marginRight: 8 }}></i>
        Add Medicines
      </button>
      <Modal show={show} size="lg">
        <Modal.Body className="d-flex flex-column">
          <div className='d-flex justify-content-between'>
            <h4>Add Medicine</h4>
            <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </div>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            <Form className="form-inline row g-3">
              <div className="col-md-6">
                <label htmlFor="inputName1" className="form-label">
                  Medicine Name
                </label>
                <Field
                  type="text"
                  name="medicineName"
                  placeholder="Medicine Name"
                  className="form-control"
                  id="inputName1"
                />
                <ErrorMessage name="medicineName" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName2" className="form-label">
                  Category
                </label>
                <Field
                  type="text"
                  name="category"
                  placeholder="Tab"
                  className="form-control"
                  id="inputName2"
                />
                <ErrorMessage name="category" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName3" className="form-label">
                  Barcode
                </label>
                <Field
                  type="text"
                  name="barcode"
                  placeholder="Code"
                  className="form-control"
                  id="inputName3"
                />
                <ErrorMessage name="barcode" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName4" className="form-label">
                  Cost Price
                </label>
                <Field
                  type="number"
                  name="costPrice"
                  placeholder="Price in Number 1234"
                  className="form-control"
                  id="inputName4"
                />
                <ErrorMessage name="costPrice" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName3" className="form-label">
                  Manufacturer
                </label>
                <Field
                  type="text"
                  name="manufacturer"
                  placeholder="Manufacturer"
                  className="form-control"
                  id="inputName3"
                />
                <ErrorMessage name="manufacturer" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName4" className="form-label">
                  Product Price
                </label>
                <Field
                  type="number"
                  name="productPrice"
                  placeholder="Price in Number 1234"
                  className="form-control"
                  id="inputName4"
                />
                <ErrorMessage name="productPrice" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName3" className="form-label">
                  Purchase Conversion Factor
                </label>
                <Field
                  type="text"
                  name="purchaseConversionFactor"
                  placeholder="Purchase Conversion Factor"
                  className="form-control"
                  id="inputName3"
                />
                <ErrorMessage name="purchaseConversionFactor" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <label htmlFor="inputName4" className="form-label">
                  Supplier Name
                </label>
                <Field
                  type="text"
                  name="suplierName"
                  placeholder="Supplier Name"
                  className="form-control"
                  id="inputName4"
                />
                <ErrorMessage name="suplierName" component="div" className="error-message" />
              </div>
              {/* <div className="col-md-6">
                  <label htmlFor="inputName4" className="form-label">
                  Generic
                  </label>
                  <Field
                    type="text"
                    name="generic"
                    placeholder="Generic"
                    className="form-control"
                    id="inputName4"
                  />
                  <ErrorMessage name="generic" component="div" className="error-message" />
                </div> */}
              <div className="row mt-5 mb-3">
                <div className="col-md-6">
                  <button type="submit" className="btn btn-save w-100">
                    Save Changes
                  </button>
                </div>
                <div className="col-md-6" >
                  <button type="button" className="btn btn-outline-danger w-100" onClick={handleClose} style={{ height: '43px' }}>
                    Discard Changes
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
      <style jsx='true'>
        {`
            .btn-save:hover {
              background: #009b94 !important;
              color: #fff;
            }
            .btn-discard {
              background: none;
              color: red;
              padding: 10px 10px !important;
            }
            .fas fa-plus:hover{
              color:#009b94 !important;
            }
            .error-message {
              color: red;
            }
            .add-record {
              background: #009b94 !important;
              color: #fff;
            }
            .add-record:hover{
              background:  #fff !important;
              color: #009b94 !important;
            }
            `}
      </style>
      {/* <ToastContainer position="top-center" /> */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}
