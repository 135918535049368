import React from 'react'
import AddNewUserButton from '../../../../Components/AddNewUserButton'
import PackagesList from './PackageList'
export default function LabPackages() {
    return (
        <>
            <AddNewUserButton link={'/lab/packages/new'} buttonText={'Add New Package'} />
            <PackagesList />
        </>
    )
}
