import AvailabilityCalender from './AvalabilityCalender/index.jsx'
import SetTimeSlots from './SetTimeSlots/index.jsx'

export default function Availabilitys() {
  return (
    <div className='d-flex flex-column justify-content-start'>
      <SetTimeSlots />
      <AvailabilityCalender />
    </div>
  )
}
