import AdminLogin from "./AdminLogin";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
// Login component
export default function Login() {
  const dispatch = useDispatch();
  useEffect(() => {
    window.localStorage.removeItem("doctorIdToken");
    dispatch({ type: "doctorLoginSlice/clearValues" });
  }, []);
  return (
    <div className="container-fluid">
      <div
        className="d-flex  justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <AdminLogin />
      </div>
      <style jsx="true">{`
        .login-or {
          position: relative;
        }
        .login-or:before {
          content: "";
          width: 4px;
          height: 350px;
          background: #cccccc;
          position: absolute;

          top: 55px;
        }
        .login-or:after {
          content: "";
          width: 4px;
          height: 350px;
          background: #cccccc;
          position: absolute;

          bottom: 55px;
        }
      `}</style>
    </div>
  );
}
