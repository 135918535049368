import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'

export const getNurseOverview = createAsyncThunk(
  'getNurseOverview',
  async (paramsID) => {
    const response = await axios
      .get(`${API_CONSTANTS.BASEURL_3}admin/nurses/${paramsID}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => res.data.response
        // console.log(res.data.response)
      )
    return response
  }
)
// reducer
export const getNurseOverviewSlice = createSlice({
  name: 'getNurseOverview',
  initialState: {
    value: {
      cnicFront: '',
      cnicBack: '',
      specialization: '',
      pastAppointmentId: '',
      validDays: '',
      coverLetter: '',
      delay: {
        currentMonth: '0',
        totaldelay: '0',
      },
      experience: [],
      certifications: [],
      nextOfKin: {
        name: '',
        phone_no: '',
        relation: '',
      },
      phoneNo: '',
      city: '',
      personal_info: {
        profileUrl: '',
        firstName: '',
        lastName: '',
        title: '',
      },
      chatCharges: '',
      upcomingAppointmentCount: '',
      videoCallCharges: '',
      dob: '',
      educationalDocuments: [
      ],
      achievementAwards: [],
      recommended: '',
      voluntaryservices: [],
      pastAppointmentCount: '',
      totalAppointmentCount: '',
      yearsOfExperience: '',
      rating: {
        count: '',
        behaviour: '',
        satisfaction: '',
        communication: '',
        recommended: '',
        averageRating: '',
      },
      services: [],
      review: [
        {
          patientInfo: '',
          appoint_date: '',
          review: '',
          recommended: false,
        },
        {
          patientInfo: '',
          appoint_date: '',
          review: '',
          recommended: true,
        },
        {
          patientInfo: '',
          appoint_date: '',
          review: '',
          recommended: true,
        },
      ],
      conditionsTreated: [],
      gender: '',
      upcomingAppointment: {
        appointmentId: ' ',
        dateTime: '',
        appointment_status: ' ',
        doctorProfileUrl: ' ',
        doctorName: ' ',
        patientName: ' ',
        patientProfileUrl: ' ',
      },
      backupNumber: '',
      Fname: '',
      Id: '',
      experiences: [
      ],
      pastAppointment: {
        appointmentId: '',
        dateTime: '',
        appointment_status: '',
        doctorProfileUrl: '',
        doctorName: '',
        patientName: '',
        patientProfileUrl: '',
      },
      liscenseNumber: '',
      is_blocked: false,
    },
  },
  reducers: {
    block: (state) => {
      state.value.is_blocked = true
    },
    unblock: (state) => {
      state.value.is_blocked = false
    },
    clearState: (state) => {
      state.value = {
        specialization: '',
        pastAppointmentId: '',
        validDays: '',
        coverLetter: '',
        delay: {
          currentMonth: '0',
          totaldelay: '0',
        },
        certifications: [],
        nextOfKin: {
          name: '',
          phone_no: '',
          relation: '',
        },
        phoneNo: '',
        city: '',
        personal_info: {
          profileUrl: '',
          firstName: '',
          lastName: '',
          title: '',
        },
        chatCharges: '',
        upcomingAppointmentCount: '',
        videoCallCharges: '',
        dob: '',
        educationalDocuments: [
        ],
        achievementAwards: [],
        recommended: '',
        voluntaryservices: [],
        pastAppointmentCount: '',
        totalAppointmentCount: '',
        yearsOfExperience: '',
        rating: {
          count: '',
          behaviour: '',
          satisfaction: '',
          communication: '',
          recommended: '',
          averageRating: '',
        },
        services: [],
        review: [
          {
            patientInfo: '',
            appoint_date: '',
            review: '',
            recommended: false,
          },
          {
            patientInfo: '',
            appoint_date: '',
            review: '',
            recommended: true,
          },
          {
            patientInfo: '',
            appoint_date: '',
            review: '',
            recommended: true,
          },
        ],
        conditionsTreated: [],
        gender: '',
        upcomingAppointment: {
          appointmentId: ' ',
          dateTime: '',
          appointment_status: ' ',
          doctorProfileUrl: ' ',
          doctorName: ' ',
          patientName: ' ',
          patientProfileUrl: ' ',
        },
        backupNumber: '',
        Fname: '',
        Id: '',
        experiences: [
        ],
        pastAppointment: {
          appointmentId: '',
          dateTime: '',
          appointment_status: '',
          doctorProfileUrl: '',
          doctorName: '',
          patientName: '',
          patientProfileUrl: '',
        },
        liscenseNumber: '',
        is_blocked: false,
      }
    }
  },
  extraReducers: {
    [getNurseOverview.pending]: (state) => {
      state.loading = true
    },
    [getNurseOverview.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.value = payload
    },
    [getNurseOverview.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const getNurseReducer = getNurseOverviewSlice.reducer
