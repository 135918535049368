import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
// Cancel appointment
export default function CancelAppoint({
  status,
  appointmentTimeToCompare,
  currentTime,
  handle_Cancel_Appointment,
  Createspin,
}) {
  const [show, setShow] = useState(false)
  const [reason, setReason] = useState('')
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleCancelAppoint = () => {
    handle_Cancel_Appointment(reason, handleClose)
  }
  return (
    <>
      <span>
        {status === 'booked' && appointmentTimeToCompare >= currentTime && (
          <button className='rounded hover-cancel' onClick={handleShow}>
            Cancel {}
          </button>
        )}
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reason❗</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>
              What is the reason to cancel this appointment?
            </Form.Label>
            <Form.Control
              type='input'
              placeholder='write here'
              onChange={(e) => setReason(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button
            variant='danger'
            onClick={handleCancelAppoint}
            disabled={!reason}
          >
            Save Changes <span className={Createspin} />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
