// import { useEffect, useReducer } from 'react'
import React from 'react'
// import CallRecordTable from './CallRecordTable'
import CallRecordTable from './CallLogsTable'

// const reducer = (state, action) => { }
export default function CallLogs() {

    return (
        <>
            <div className='container-fluid p-4 '>
                <div className='row'>
                    <div className='col-12'>
                        <CallRecordTable />
                    </div>
                </div>
            </div>
            <style jsx='true'>{`
        table {
          font-size: 14px;
        }
        th {
          font-weight: 500;
          padding: 15px;
        }
        td {
          text-align: right;
          padding: 15px;
        }
        tr {
          border-bottom: 1px solid #dadada;
        }
      `}</style>
        </>
    )
}
