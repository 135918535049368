/*

*/
// Dependencies
import React from 'react'
// Loader button component.
export default function ButtonLoader() {
  return (
    <div
      className='spinner-border'
      role='status'
      style={{ width: '15px', height: '15px' }}
    >
      <span className='sr-only'>Loading...</span>
    </div>
  )
}
