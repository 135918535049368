/*
Authore: Umer Shabir 
Date: april-2022
File: this component is responsible to unblock the admin profile.
*/
// Dependencies
import React, { useState } from 'react'
import { API_CONSTANTS, headers } from '../../../../API_CONSTANTS'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Toast from '../../../../Components/Toast/Toast'
import { useDispatch } from 'react-redux'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
// Unblock the admin profile button.
export default function UnblockProfileButton({ overview }) {
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [spin, setSpin] = useState('')
  const params = useParams()
  const dispatch = useDispatch()
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }

  const handle_Unblocked = () => {
    setSpin('spinner-border spinner-border-sm')
    const data = {
      email: overview.email,
    }
    axios
      .patch(`${API_CONSTANTS.BASEURL}admin/enableAdmin/${params.id}`, data, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        handleToast(
          res.data.response.message === 'Enabled Successfully'
            ? 'Unblocked Successfully'
            : '',
          faCheckCircle,
          '#009b94',
          'show-success'
        )

        setSpin('')
      })
      .then(() => {
        setTimeout(() => dispatch({ type: 'getAdminProfile/unBlock' }), 1000)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setSpin('')
      })
  }
  return (
    <>
      <button
        className='btn custom-button unblock-button'
        onClick={handle_Unblocked}
      >
        <span className={spin + ' me-2'} />
        Unblock
      </button>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style jsx='true'>{`
        .unblock-button {
          background: #009b94 !important;
          border: 2px solid #009b94;
          color: #fff;
        }
        .unblock-button:hover {
          background: #009b94c4 !important;
          color: #fff !important;
        }
      `}</style>
    </>
  )
}
