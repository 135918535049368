import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case 'LOADING_TRUE': {
      return {
        ...state,
        loading: true,
      }
    }
    case 'LOADING_FALSE': {
      return {
        ...state,
        loading: false,
      }
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      }
    }
  }
}

//Doctor related filter search form
export default function FilterForm() {
  const values = useSelector((state) => state.patientConsultationFilterReducer.values)
  const filter_status = {
    appointment_status:values.value,
    appoint_type: values.value2,
    appoint_date_from: values.value3,
    appoint_date_to: values.value1,
  }
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    appoint_type: filter_status.appoint_type,
    appoint_date_from: filter_status.appoint_date_from,
    appoint_date_to: filter_status.appoint_date_to,
    appointment_status: filter_status.appointment_status,
    loading: false,
  })
  //
  const dispatch = useDispatch()
  //
  const { appoint_type, appoint_date_to, appoint_date_from, appointment_status, loading } = formData

  const handleSubmit = (event) => {
    event.preventDefault()
    setFormDataDispatch({ type: 'LOADING_TRUE' })
    if (appoint_date_to !== undefined || appoint_date_to !== '') {
      dispatch({
        type: 'ServicesFilters/appoint_date_to',
        payload: appoint_date_to,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
    if (appoint_date_from !== undefined || appoint_date_from !== '') {
      dispatch({
        type: 'ServicesFilters/appoint_date_from',
        payload: appoint_date_from,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
    if (appointment_status !== undefined || appointment_status !== '') {
      dispatch({
        type: 'ServicesFilters/' + appointment_status,
      })
    }
    if (appoint_type !== undefined || appoint_type !== '') {
      dispatch({
        type: 'ServicesFilters/' + appoint_type,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className='mt-4 d-flex flex-column justify-content-center align-items-center'
      >
        <div
          class='form-group mt-3 w-100
        px-2'
        >
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, color: '#000', fontSize: 16 }}
          >
            Consultation Type
          </label>
          <select
            className='form-control'
            id='exampleFormControlSelect1'
            onChange={setFormDataDispatch}
            name='appoint_type'
            value={appoint_type}
          >
            <option name='all' value='allTypes'>
              All
            </option>
            <option value='Booked'>Book Appointment video</option>
            <option value='Instant'>Instant Consultation Video</option>
            <option value='FollowUp'>Follow Up's Consultations</option>
          </select>
        </div>
        <div className='form-group mt-3 w-100 px-2'>
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, color: '#000', fontSize: 16 }}
          >
            Consultation Status
          </label>
          <select
            className='form-control'
            id='exampleFormControlSelect1'
            onChange={setFormDataDispatch}
            name='appointment_status'
            value={appointment_status}
          >
            <option name='all' value='all'>
              All
            </option>
            <option value={'booked'}>Booked</option>
            <option value={'completed'}>Completed</option>
            <option value={'testCall'}>Test Call</option>
            <option value={'fakeCall'}>Fake Call</option>
            <option value={'cancelled'}>Cancelled</option>
            <option value={'InProgress'}>InProgress</option>
            <option value={'missed'}>Missed</option>
            <option value={'unattended'}>Unattended</option>
            <option value={'called'}>Called</option>
            <option value={'reschedule'}>Reschedule</option>
          </select>
        </div>
        <div class='form-group mt-3  w-100 px-2'>
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, color: '#000', fontSize: 16 }}
          >
            Date From
          </label>
          <input
            onChange={setFormDataDispatch}
            name='appoint_date_from'
            type='date'
            class='form-control filter-input'
            id='appointmentDate'
            value={appoint_date_from}
          />
        </div>
        <div class='form-group mt-3  w-100 px-2'>
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, color: '#000', fontSize: 16 }}
          >
            Date To
          </label>
          <input
            onChange={setFormDataDispatch}
            name='appoint_date_to'
            type='date'
            class='form-control filter-input'
            id='appointmentDate'
            value={appoint_date_to}
          />
        </div>
        <button className='btn mt-3 filter-form-button'>
          {loading || 'Apply'}
          {loading && (
            <div
              className='spinner-border spinner-border-sm text-white mx-1'
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </div>
          )}
        </button>
      </form>
      <style jsx>{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 95%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
      `}</style>
    </>
  )
}
