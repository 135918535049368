import React from 'react';
import RecordTable from './RecordTable';
import AddMember from './AddMember';


export default function AddMedicine() {
  
  return (
    <>
      <div className='mt-4 d-flex justify-content-end'>
        <AddMember />
      </div>
      
      <div className="col-12">
        <RecordTable   />
      </div>
    </>
  );
}
