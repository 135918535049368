import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close';

export default function ViewFile() {
    const { value } = useSelector((state) => state.getLabReducer)
    const [show, setShow] = useState(false)
    const handleOpen = () => setShow(true)
    const handleClose = () => setShow(false)
    const isPdf = value?.prescription_Url && value.prescription_Url.endsWith('.pdf');
    return (
        <>
            <span
                onClick={handleOpen}
                // className='border-bottom'
                style={{
                    color: '#009B94',
                    fontSize: '1rem',
                    borderBottom: '1px solid #009B94',
                    cursor: 'pointer'
                }}
            >
                View File
            </span>
            <Modal show={show} onHide={handleClose} centered size='xl'>
                <Modal.Header style={{ backgroundColor: '#ECFCFB', display: 'flex', justifyContent: 'end', border: 'none' }}>
                    <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: '#ECFCFB', padding: '2rem' }}>
                    <div className='d-flex justify-content-center'>
                        {isPdf ? (
                            <embed
                                src={value?.prescription_Url}
                                type='application/pdf'
                                width='100%'
                                height='600px'
                            />
                        ) : (
                            <img
                                src={value?.prescription_Url}
                                alt='Prescription'
                                style={{
                                    maxWidth: '400px',
                                    maxHeight: '600px',
                                    boxShadow: '0px 4px 11px 0px rgba(0, 0, 0, 0.25)',
                                }}
                            />
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
