/*
Authore: Umer Shabir
Date: April-2022
File: this component is responsible for editing an admin profile.
*/
// dependencies
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { API_CONSTANTS, headers } from '../../../../API_CONSTANTS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
// import Formik & yup libraries
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { getProfile } from './AdminProfileSlice'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../../Components/Toast/Toast'
// Edit Profile button
export default function EditProfileButton({ overview }) {
  const [show, setShow] = useState(false)
  const [saveSpin, setsaveSpin] = useState('')
  const params = useParams()
  const dispatch = useDispatch()
  const [toast, setToast] = useState({
    icon: '',
    message: '',
    iconColor: '',
    cName: '',
  })
  const [spin, setSpin] = useState('')
  const handle_update_Admin = (values) => {
    setsaveSpin('spinner-border spinner-border-sm')
    const data = values
    axios
      .patch(`${API_CONSTANTS.BASEURL}admin/updateAdmin/${params.id}`, data, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setToast({
          message: res.data.response.message,
          icon: faCheckCircle,
          iconColor: '#009B94',
          cName: `show show-success`,
        })
        setTimeout(() => {
          setToast({
            icon: '',
            message: '',
            iconColor: '',
            cName: '',
          })
        }, 1500)
        setSpin('')
        setsaveSpin('')
        setShow(false)
        dispatch(getProfile(params.id))
      })

      .catch((error) => {
        setToast({
          message: error.data.response.message,
          icon: faTimesCircle,
          iconColor: '#B00020',
          cName: `show show-danger`,
        })
        setTimeout(() => {
          setToast({
            icon: '',
            message: '',
            iconColor: '',
            cName: '',
          })
        }, 1500)
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setsaveSpin('')
      })
  }

  return (
    <>
      <button
        className='btn custom-button edit-button'
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faCog} className={'edit-icon me-2'} />
        Edit Profile
      </button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <Formik
            initialValues={{
              email: overview.email,
              name: overview.adminName,
              role: overview.adminRole,
              phoneNo: overview.phoneNo,
              // phoneNo: overview.password,
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Please enter the valid name'),
              email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            })}
            onSubmit={(values) => {
              if (
                overview.email === values.email &&
                overview.adminName === values.name &&
                overview.password === values.password &&
                overview.phoneNo === values.phoneNo &&
                overview.adminRole === values.role
              ) {
                setShow(false)
              } else {
                handle_update_Admin(values)
              }
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <label className={'p_label'}>Email</label>
                        <input
                          type='email'
                          className='custom-input-field'
                          style={{ textTransform: 'lowercase' }}
                          autoComplete={'off'}
                          name={'email'}
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className={'error-message'}>
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <label className={'p_label'}>Phone No</label>
                        <input
                          type='tel'
                          className='custom-input-field number-input'
                          autoComplete={'off'}
                          name={'phoneNo'}
                          value={formik.values.phoneNo}
                          maxLength='13'
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />

                        {formik.touched.phoneNo && formik.errors.phoneNo ? (
                          <div className={'error-message'}>
                            {formik.errors.phoneNo}
                            {overview.phoneNo}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <label className='p_label'>Full Name</label>
                        <input
                          type='text'
                          className='custom-input-field'
                          name='name'
                          value={formik.values.name}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className={'error-message'}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <label className='p_label'>Password</label>
                        <input
                          type='text'
                          className='custom-input-field'
                          name='password'
                          value={formik.values.password}
                          onChange={(e) => formik.handleChange(e)}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className={'error-message'}>
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={'col-md-12'}
                      style={{
                        overflow: 'hidden',
                        height: '53px',
                        paddingTop: '6px',
                      }}
                    >
                      <div className='groupd'>
                        <div
                          className='input-block'
                          style={{ marginLeft: '-3px' }}
                        >
                          <select
                            className='custom-input-field p-0 py-2'
                            name={'role'}
                            value={formik.values.role}
                            style={{ margintop: '8px' }}
                            onChange={(e) => {
                              formik.handleChange(e)
                            }}
                          >
                            <option
                              value=''
                              disabled
                              selected
                              style={{ color: '#8E8E8E' }}
                            >
                              Department
                            </option>
                            <option value='Admin'>Admin</option>
                            <option value='DoctorAdmin'>Doctor Admin</option>
                            <option value='Consultant'>Consultant</option>
                            <option value='HealthCare'>Healthcare Team</option>
                            <option value='AccountsDept'>Accounts Department</option>
                            <option value='DevTeam'>Development Team</option>
                            <option value='customerSupport'>
                              Customer Support
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className={'col-md-12 pt-3'}>
                      <button type={'submit'} className={'btn btn-save '}>
                        Save Changes
                        <span className={saveSpin} />
                      </button>
                      <button
                        className={'btn btn-discard'}
                        onClick={() => setShow(false)}
                        type={'button'}
                      >
                        Discard
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      <style jsx='true'>
        {`
          .edit-button {
            border: 2px solid #009b94;
            color: #009b94;
          }
          .edit-button:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-save {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
          }
          .custom-input-field {
            width: 100%;
            border: none;
            border-bottom: 1px solid #cccccc;
            text-transform: capitalize;
          }
          .custom-input-field:focus-visible {
            outline: none;
            border-bottom: 1px solid #009b94;
          }
          .p_label {
            font-size: 14px;
            color: #5c5c5c;
          }
          .error-message {
            font-size: 13px;
            color: red;
          }
        `}
      </style>
      <Toast
        cName={toast.cName}
        icon={toast.icon}
        color={toast.iconColor}
        text={toast.message}
      />
    </>
  )
}
