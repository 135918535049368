import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import edit_Icon from '../../../../../resources/images/svgs/edit.svg'
import delete_Icon from '../../../../../resources/images/svgs/delete.svg'
import {
  faCheckCircle,
  faPlus,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import Modal from 'react-bootstrap/Modal'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Toast from '../../../../../Components/Toast/Toast'
import moment from 'moment'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'

const Additional_Info_Form = (props) => {
  const date = moment(new Date())
  const today_date = date.format('YYYY-MM-DD')
  const params = useParams()

  const stage = '5'

  const [index_update, setIndex_update] = useState('')
  const [Createspin, setCreateSpin] = useState('')
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')

  const [Show_update_volunteer_modal, setShow_update_volunteer_modal] =
    useState(false)
  const [Show_update_achievement_modal, setShow_update_achievement_modal] =
    useState(false)

  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const handleClose2 = () => setShow2(false)
  const handleClose3 = () => setShow3(false)
  const close_update_volunteer_modal = () =>
    setShow_update_volunteer_modal(false)
  const close_update_achievement_modal = () =>
    setShow_update_achievement_modal(false)

  const [volunteerExperience, setVolunteerExperience] = useState([])
  const [achievements, setAchievements] = useState([])

  const handle_Remove_volunteer_Detailes = (e) => {
    const name = e.target.getAttribute('data-id')
    console.log(name)
    const index = parseInt(name)
    setVolunteerExperience([
      ...volunteerExperience.slice(0, index),
      ...volunteerExperience.slice(index + 1),
    ])
  }
  const handle_Remove_achievement_Detailes = (e) => {
    const name = e.target.getAttribute('data-id')
    console.log(name)
    const index = parseInt(name)
    setAchievements([
      ...achievements.slice(0, index),
      ...achievements.slice(index + 1),
    ])
  }

  const handle_update_volunteer_Detailes = (e) => {
    setShow_update_volunteer_modal(true)
    const index = e.target.getAttribute('data-id')
    console.log(index)
    setIndex_update(index)
  }

  const handle_update_achievement_Detailes = (e) => {
    setShow_update_achievement_modal(true)
    const index = e.target.getAttribute('data-id')
    console.log(index)
    setIndex_update(index)
  }
  // --------  This function is for call the Toast message  ------
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }

  useEffect(() => {
    axios
      .get(`${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/updateDoctor/additional_info`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setAchievements(res.data.response.achievementAwards || [])
        setVolunteerExperience(res.data.response.voluntaryservices || [])
        console.log(res.data.response)
      })
      .catch((e) => console.log(e))
  }, [])
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          voluntaryservices: volunteerExperience,
          achievementAwards: achievements,
        }}
        // validationSchema={Yup.object()}
        onSubmit={(values) => {
          setCreateSpin('spinner-border spinner-border-sm')

          axios
            .patch(`${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/additionalInfo`, values, {
              headers: API_CONSTANTS.headers(),
            })
            .then((res) => {
              setCreateSpin('')
              console.log(res)
              handleToast(
                res.data.response.message,
                faCheckCircle,
                'rgba(0,155,148,0.93)',
                'show-success'
              )
              setTimeout(() => props.alert(stage), 2000)
            })
            .catch((e) => {
              setCreateSpin('')
              // console.log(e.response.data.response.message)
              handleToast(
                e.response.data.response.message,
                faTimesCircle,
                '#b20909',
                'show-danger'
              )
            })
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className='row py-3 gutter-flx'>
                <div className='col-md-8 col-sm-12 border-bottom py-2'>
                  <div className={'justify-content-between d-flex'}>
                    <span>Volunteer Experience (optional)</span>
                    <span>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={'fa-lpus'}
                        onClick={() => setShow2(true)}
                      />
                    </span>
                  </div>
                  {formik.values.voluntaryservices.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={
                            'd-flex justify-content-between w-100 py-1'
                          }
                        >
                          <div className={'col-md-10 p-0'}>
                            <h6 className={'m-0 title-heading '}>
                              {data.role}
                            </h6>
                            <p className={'m-0 sub-title-heading py-1'}>
                              {data.organization} | {data.start_date} -{' '}
                              {data.end_date}
                            </p>
                            <p className={'m-0 sub-title-heading '}>
                              {data.cause}
                            </p>
                          </div>
                          <div
                            className={
                              'col-md-2 p-0 justify-content-end d-flex'
                            }
                          >
                            <span>
                              <img
                                src={edit_Icon}
                                width={15}
                                height={16}
                                className={'mx-3'}
                                alt={'edit-icon'}
                                data-id={index}
                                onClick={handle_update_volunteer_Detailes}
                              />
                              <img
                                src={delete_Icon}
                                width={16}
                                height={16}
                                data-id={index}
                                onClick={handle_Remove_volunteer_Detailes}
                                alt={'delete-icon'}
                              />
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
              <div className='row py-3 gutter-flx'>
                <div className='col-md-8 col-sm-12 border-bottom py-2'>
                  <div className={'justify-content-between d-flex'}>
                    <span>Achievements (optional)</span>
                    <span>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={'fa-lpus'}
                        onClick={() => setShow3(true)}
                      />
                    </span>
                  </div>
                  {formik.values.achievementAwards.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={
                            'd-flex justify-content-between w-100 py-1'
                          }
                        >
                          <div className={'col-md-10 p-0'}>
                            <h6 className={'m-0 title-heading '}>
                              {data.title}
                            </h6>
                            <p className={'m-0 sub-title-heading py-1'}>
                              {data.associated_with} | {data.issue_date}
                            </p>
                            <p className={'m-0 sub-title-heading '}>
                              {data.issuer}
                            </p>
                          </div>
                          <div
                            className={
                              'col-md-2 p-0 justify-content-end d-flex'
                            }
                          >
                            <span>
                              <img
                                src={edit_Icon}
                                width={15}
                                height={16}
                                className={'mx-3'}
                                alt={'edit-icon'}
                                data-id={index}
                                onClick={handle_update_achievement_Detailes}
                              />
                              <img
                                src={delete_Icon}
                                width={16}
                                height={16}
                                data-id={index}
                                onClick={handle_Remove_achievement_Detailes}
                                alt={'delete-icon'}
                              />
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
              <div className={'card-footer bg-white px-0 border-0'}>
                <div className={' px-2 py-4'}>
                  <button
                    className={'btn btn-save-Procced'}
                    type={'submit'}
                    // onClick={() => props.alert(stage)}
                  >
                    <span className={Createspin} />
                    Save & Proceed
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>

      {/* Add Volunteer Experience Modal*/}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Volunteer Experience</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              organization: '',
              role: '',
              cause: '',
              start_date: '',
              end_date: '',
            }}
            validationSchema={Yup.object({
              organization: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the organization'),
              role: Yup.string().required('Please select the job role'),
              cause: Yup.string().required('Please select the cause'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              setVolunteerExperience((oldArray) => [...oldArray, values])
              setShow2(false)
              // handle_update_Admin(values);
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'organization'}
                          placeholder={'Ex: Red Cross'}
                          value={formik.values.organization}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Organization *</label>
                        {formik.touched.organization &&
                        formik.errors.organization ? (
                          <div className={'error-field'}>
                            {formik.errors.organization}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'role'}
                          placeholder={'Ex: Fundraising Volunteer'}
                          value={formik.values.role}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Role *</label>
                        {formik.touched.role && formik.errors.role ? (
                          <div className={'error-field'}>
                            {formik.errors.role}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Human Welfare'}
                          name={'cause'}
                          value={formik.values.cause}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Cause </label>
                        {formik.touched.cause && formik.errors.cause ? (
                          <div className={'error-field'}>
                            {formik.errors.cause}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => formik.handleChange(e)}
                          // onBlur={(e) => (e.target.type = 'text')}
                          // onFocus={(e) => (e.target.type = 'date')}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                        formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => formik.handleChange(e)}
                          onBlur={(e) => (e.target.type = 'text')}
                          onFocus={(e) => (e.target.type = 'date')}
                          min={formik.values.start_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>End Date *</label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input type='checkbox' className={'checkbox'} />
                        <label
                          className={'ml-2'}
                          style={{ marginLeft: '10px' }}
                        >
                          I am currently volunteering in this role
                        </label>
                      </div>
                    </div> */}

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* Add Achievement Modal*/}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Achievement</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              title: '',
              associated_with: '',
              issuer: '',
              issue_date: '',
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the achiever award title'),
              associated_with: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the associated Institute name'),
              issuer: Yup.string().required(
                'Please enter the issuer Institute name'
              ),
              issue_date: Yup.string().required('Please enter the issue date'),
            })}
            onSubmit={(values) => {
              setAchievements((oldArray) => [...oldArray, values])
              setShow3(false)
              // handle_update_Admin(values);
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Registered Medical Assistant '}
                          value={formik.values.title}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'associated_with'}
                          placeholder={
                            'Ex: Nishtar Medical College MBBS Program'
                          }
                          value={formik.values.associated_with}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Associated With</label>
                        {formik.touched.associated_with &&
                        formik.errors.associated_with ? (
                          <div className={'error-field'}>
                            {formik.errors.associated_with}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Nishtar Medical College'}
                          name={'issuer'}
                          value={formik.values.issuer}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Issuer *</label>
                        {formik.touched.issuer && formik.errors.issuer ? (
                          <div className={'error-field'}>
                            {formik.errors.issuer}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='issue_date'
                          value={formik.values.issue_date}
                          onChange={(e) => formik.handleChange(e)}
                          // onBlur={(e) => (e.target.type = "text")}
                          // onFocus={(e) => (e.target.type = "date")}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Issue Date *</label>
                        <span className='bar'></span>
                        {formik.touched.issue_date &&
                        formik.errors.issue_date ? (
                          <div className={'error-field'}>
                            {formik.errors.issue_date}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* ------------------------- Update Modal Code  ------------------------------- */}

      <Modal
        show={Show_update_volunteer_modal}
        onHide={close_update_volunteer_modal}
      >
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Volunteer Experience</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              organization:
                volunteerExperience[index_update] &&
                volunteerExperience[index_update].organization,
              role:
                volunteerExperience[index_update] &&
                volunteerExperience[index_update].role,
              cause:
                volunteerExperience[index_update] &&
                volunteerExperience[index_update].cause,
              start_date:
                volunteerExperience[index_update] &&
                volunteerExperience[index_update].start_date,
              end_date:
                volunteerExperience[index_update] &&
                volunteerExperience[index_update].end_date,
              // currently_volunteer: "",
            }}
            validationSchema={Yup.object({
              organization: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the organization'),
              role: Yup.string().required('Please select the job role'),
              cause: Yup.string().required('Please select the cause'),
              start_date: Yup.string().required('Please enter the start date'),
              end_date: Yup.string().required('Please enter the end date'),
            })}
            onSubmit={(values) => {
              // setVolunteerExperience((oldArray) => [...oldArray, values]);
              let temp_state = [...volunteerExperience]
              let temp_element = { ...temp_state[index_update] }
              temp_element = values
              temp_state[index_update] = temp_element
              setVolunteerExperience(temp_state)
              setShow_update_volunteer_modal(false)
              // handle_update_Admin(values);
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'organization'}
                          placeholder={'Ex: Red Cross'}
                          value={formik.values.organization}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Organization *</label>
                        {formik.touched.organization &&
                        formik.errors.organization ? (
                          <div className={'error-field'}>
                            {formik.errors.organization}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'role'}
                          placeholder={'Ex: Fundraising Volunteer'}
                          value={formik.values.role}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Role *</label>
                        {formik.touched.role && formik.errors.role ? (
                          <div className={'error-field'}>
                            {formik.errors.role}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Human Welfare'}
                          name={'cause'}
                          value={formik.values.cause}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Cause </label>
                        {formik.touched.cause && formik.errors.cause ? (
                          <div className={'error-field'}>
                            {formik.errors.cause}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='start_date'
                          value={formik.values.start_date}
                          onChange={(e) => formik.handleChange(e)}
                          onBlur={(e) => (e.target.type = 'text')}
                          onFocus={(e) => (e.target.type = 'date')}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Start Date *</label>
                        <span className='bar'></span>
                        {formik.touched.start_date &&
                        formik.errors.start_date ? (
                          <div className={'error-field'}>
                            {formik.errors.start_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-6'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='end_date'
                          value={formik.values.end_date}
                          onChange={(e) => formik.handleChange(e)}
                          onBlur={(e) => (e.target.type = 'text')}
                          onFocus={(e) => (e.target.type = 'date')}
                          min={formik.values.start_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>End Date *</label>
                        <span className='bar'></span>
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <div className={'error-field'}>
                            {formik.errors.end_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className={'col-md-12'}>
                      <div className={'group d-flex'}>
                        <input type='checkbox' className={'checkbox'} />
                        <label
                          className={'ml-2'}
                          style={{ marginLeft: '10px' }}
                        >
                          I am currently volunteering in this role
                        </label>
                      </div>
                    </div> */}

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* Update Achievement Modal*/}
      <Modal
        show={Show_update_achievement_modal}
        onHide={close_update_achievement_modal}
      >
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Achievement</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              title:
                achievements[index_update] && achievements[index_update].title,
              associated_with:
                achievements[index_update] &&
                achievements[index_update].associated_with,
              issuer:
                achievements[index_update] && achievements[index_update].issuer,
              issue_date:
                achievements[index_update] &&
                achievements[index_update].issue_date,
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the achiever award title'),
              associated_with: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the associated Institute name'),
              issuer: Yup.string().required(
                'Please enter the issuer Institute name'
              ),
              issue_date: Yup.string().required('Please enter the issue date'),
            })}
            onSubmit={(values) => {
              // console.log(values)
              // setAchievements((oldArray) => [...oldArray, values]);
              let temp_state = [...achievements]
              let temp_element = { ...temp_state[index_update] }
              temp_element = values
              temp_state[index_update] = temp_element
              setAchievements(temp_state)
              setShow_update_achievement_modal(false)
              // handle_update_Admin(values);
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'title'}
                          placeholder={'Ex: Registered Medical Assistant '}
                          value={formik.values.title}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Title *</label>
                        {formik.touched.title && formik.errors.title ? (
                          <div className={'error-field'}>
                            {formik.errors.title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'associated_with'}
                          placeholder={
                            'Ex: Nishtar Medical College MBBS Program'
                          }
                          value={formik.values.associated_with}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Associated With</label>
                        {formik.touched.associated_with &&
                        formik.errors.associated_with ? (
                          <div className={'error-field'}>
                            {formik.errors.associated_with}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: Nishtar Medical College'}
                          name={'issuer'}
                          value={formik.values.issuer}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Issuer *</label>
                        {formik.touched.issuer && formik.errors.issuer ? (
                          <div className={'error-field'}>
                            {formik.errors.issuer}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12'}>
                      <div className='group'>
                        <input
                          type='date'
                          name='issue_date'
                          value={formik.values.issue_date}
                          onChange={(e) => formik.handleChange(e)}
                          // onBlur={(e) => (e.target.type = "text")}
                          // onFocus={(e) => (e.target.type = "date")}
                          max={today_date}
                          style={{ height: '39px' }}
                        />
                        <span className='highlight'></span>
                        <label className={'p_label'}>Issue Date *</label>
                        <span className='bar'></span>
                        {formik.touched.issue_date &&
                        formik.errors.issue_date ? (
                          <div className={'error-field'}>
                            {formik.errors.issue_date}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* ------------------------- Toast Message Code  ------------------------------- */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style>{`

      .gutter-flx{
        --bs-gutter-x: -1.rem;
      }
.error-field{
   font-size: 14px;
          letter-spacing: 0.5px;
          color: #e52444;
}

.checkbox {
  width: fit-content !important;
  margin-top: 3px;
  
}

.input-danger,
.input-danger:focus {
  border-bottom: 1px solid #e52444 !important;
}


.btn-proceed {
  border: none !important;
  color: #009b94;
}

.btn-proceed:hover {
  border: none;
  box-shadow: none !important;
  text-decoration: underline;
  color: rgba(1, 190, 181, 0.87);
}


.d_list:hover {
  color: #1e1e1e;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.card-footer {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/*====================  Input field CSS Styling  ===========================*/

.group {
  position: relative;
  margin-bottom: 25px;
  width: 100% !important;
}

input,
.group select {
  font-size: 15px;
  padding: 12px 4px 2px 0px;
  display: block;
  border: none;
  border-bottom: 1px solid #cccccc;
}

/*.remove-style {*/
/*  border: none !important;*/
/*}*/

input {
  width: 100% !important;
}
input:focus,
select {
  outline: none;
  border-bottom: 1px solid #009b94;
}

/* LABEL ======================================= */
label.p_label {
  color: #8e8e8e;
  font-size: 14px;
  font-weight: 400 !important;
  position: absolute;
  pointer-events: none;
  left: 1px;
  top: -10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label,
.group select:valid ~ label,
.group select:focus ~ label {
  top: -10px;
  font-size: 14px;
  color: #8e8e8e;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after,
.group select:focus ~ .bar:before,
.group select:focus ~ .bar:after {
  width: 50%;
}
.customModal {
  background: #b2dbbf;
  max-width: 500px;
  width: 100%;
}

.btn-save-Procced,
.btn-save-exit {
  margin: 0px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  height: 40px !important;
  border: 1px solid #009b94;
  border-radius: 4px;
  letter-spacing: 1.25px;
  color: #009b94;
  background-color: #ffffff;
}

.btn-save-exit {
  color: #5c5c5c;
  border: 1px solid #5c5c5c;
}



      
      `}</style>
    </>
  )
}

export default Additional_Info_Form
