import { createSlice } from '@reduxjs/toolkit'
const add_doctor_Reducers = createSlice({
  name: 'Add_Doctor',
  initialState: {
    phoneNo: '',
  },
  reducers: {
    addDoctorUser: (state, action) => {
      state.phoneNo = action.payload
    },
  },
})
export const { addDoctorUser } = add_doctor_Reducers.actions
export default add_doctor_Reducers.reducer
