import React, { useState, useMemo, useEffect } from 'react'
import Table from '../../../../Components/Table'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import AlertMessage from '../../../../Components/AlertMessage'
import { Spinner } from 'react-bootstrap'
import axios from 'axios'
import ViewDisease from './ViewDisease'
import moment from 'moment/moment'
// Disease preset table component
export default function DiseasePresetTable() {
  // const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [diseaseList, setDiseaseList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0)

  //
  //

  // const filter_status = {
  //   specialization: useSelector((state) => state.doctorFilterReducer.value),
  //   gender: useSelector((state) => state.doctorFilterReducer.value1),
  //   created_at: useSelector((state) => state.doctorFilterReducer.value2),
  // }
  //
  //
  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = 1
    // We'll even set a delay to simulate a server here

    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      const startRow = pageSize * pageIndex
      const endRow = startRow + pageSize
      setDiseaseList(diseaseList.slice(startRow, endRow))

      // Your server could send back total page count.
      // For now we'll just fake it, too
      // setPageCount(Math.ceil(diseaseList.length / pageSize))
    }
  }, [])

  // API Hatching data of all doctor
  // const handleSearchInput = (event, state) => {
  //   if (event.reset) {
  //     return { number: '', countryCode: '+92' }
  //   }
  //   return setDoctorSearch({
  //     ...state,
  //     number: event.target.value.slice(0, event.target.maxLength),
  //     countryCode: '+92',
  //   })
  // }

  // const handleDoctorSearch = () => {
  //   axios
  //     .get(
  //       API_CONSTANTS.DOCTOR_SEARCHBYNUMBER(
  //         doctorSearch.countryCode,
  //         doctorSearch.number
  //       ),
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((res) => setDiseaseList(res.data.response))

  //     // .then(() => setDiseaseSearch({ countryCode: '+92', number: '' }))
  //     .catch((error) => alert(error.response.data.response.message))
  // }
  const List = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/diseaseSet`,
        // {
        //   gender: `${filter_status.gender}`,
        //   created_at: `${filter_status.created_at}`,
        //   specialization: `${filter_status.specialization}`,
        // },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true)
        // setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
        console.log(res.data)
        setDiseaseList(res.data.response)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setIsLoading(true)
        // setError(error)
        setDiseaseList([])
      })
  }
  // const [page, setPage] = useState(0)

  // handling load more data
  // const handleLoadMoreData = () => {
  //   axios
  //     .post(
  //       `${API_CONSTANTS.DOCTORT_LIST()}?last=${lastEvaluatedKey}`,
  //       {
  //         gender: `${filter_status.gender}`,
  //         created_at: `${filter_status.created_at}`,
  //         specialization: `${filter_status.specialization}`,
  //       },
  //       {
  //         headers: headers,
  //       }
  //     )
  //     .then((response) => {
  //       setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
  //       var doctorListNextPage = response.data.response.Items
  //       setDoctorList([...doctorList, ...doctorListNextPage])
  //       setPage(page + 1)
  //     })
  //     .then(() => setLoading(true))
  // }
  const columns = useMemo(
    () => [
      {
        Header: 'Disease Name',
        accessor: (a) => a.title.slice(0, 1).toUpperCase() + a.title.slice(1),
        Cell: ({ cell: { value } }) => value || 'N/A',
      },
      {
        Header: 'Date/Time',
        accessor: (a) =>
          a.updated_at
            ? moment(a.updated_at).format('l') +
              ' ' +
              moment(a.updated_at).format('LT')
            : moment(a.created_at).format('l') +
              ' ' +
              moment(a.created_at).format('LT'),
      },
      {
        Header: 'Do’s',
        accessor: (a) => a.dos.slice(0, 2) + ' ...',
        Cell: ({ cell: { value } }) => value || 'N/A',
      },

      {
        Header: 'Don’ts ',
        accessor: (a) => a.donts.slice(0, 2) + ' ...',
        Cell: ({ cell: { value } }) => value || 'N/A',
      },
      {
        Header: ' ',
        accessor: (row) => <ViewDisease disease={row} diseaseList={List} />,
      },
    ],
    []
  )
  const is_SessionExpire = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  useEffect(() => {
    List()
  }, [])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 401'
            ? is_SessionExpire()
            : error.message === 'Request failed with status code 404'
            ? 'record not found'
            : error.message
        }
      />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center  '>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <>
        <div className='doctor'>
          <div className='input-group mt-3 pe-4 ms-4' style={{ width: '50%' }}>
            {/* <div className='form-outline d-flex'>
              <select
                name='countryCode'
                style={{
                  borderRadius: '7px 0 0 7px',
                  borderRight: '3px solid #009B94',
                  background: '#009B9414',
                  color: '#4E4E4E99',
                }}
              >
                <option
                  selected
                  defaultValue={countryCode}
                  onChange={handleSearchInput}
                >
                  +92
                </option>
              </select>
              <input
                type='number'
                id='form1'
                className='form-control patient-search-input'
                placeholder='Search'
                value={number}
                name='number'
                onChange={handleSearchInput}
                maxLength='10'
              />
            </div> */}
            {/* <button
              type='button'
              className='btn'
              onClick={handleDoctorSearch}
              disabled={
                doctorSearch.number.slice(0, 1) === '3' &&
                doctorSearch.number.length === 10
                  ? false
                  : true
              }
              style={{ backgroundColor: 'rgb(0, 155, 148)', color: '#fff' }}
            >
              <i className='fas fa-search'></i>
            </button> */}
          </div>
        </div>
        <div className='row rounded bg-white m-4'>
          <div>
            <h3
              style={{
                fontSize: '16px',
                fontWeight: '500',
                padding: '18px 10px',
              }}
            >
              Disease Preset
            </h3>
          </div>
          <Table
            // pageNumber={page}
            columns={columns}
            data={diseaseList}
            routingLink={''}
            pageCount={pageCount}
            fetchData={fetchData}
            // loadMoreList={handleLoadMoreData}
            // lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            setLoading={setLoading}
            FilterForm={''}
          />
        </div>
        <style jsx='true'>{`
          .btn:focus {
            box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 25%) !important;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .patient-search-input {
            border-radius: 0;
          }
        `}</style>
      </>
    )
  }
}
