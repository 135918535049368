// import DataTable_component from "./Components/DataTable/DataTable";
// import loadingGif from "./resources/loading.json";
import profile_img from './resources/images/profile_img.png'
import logo from './resources/images/logo.png'
import calender from './resources/images/svgs/calendar.svg'
import thumsUp from './resources/images/svgs/thumsUp.svg'
import edit from './resources/images/svgs/selectIcon/edit_selected.svg'
import del from './resources/images/svgs/delete.svg'
import clock from './resources/images/svgs/clock.svg'
import likeIcon from './resources/images/svgs/likeIcon.svg'
import moonIcon from './resources/images/svgs/unSelectIcons/moon.svg'
import noonIcon from './resources/images/svgs/unSelectIcons/noon.svg'
import morningIcon from './resources/images/svgs/unSelectIcons/morning.svg'
import flagIcon from './resources/images/svgs/selectIcon/flag.svg'
import selectStarIcon from './resources/images/svgs/selectIcon/select-star.svg'
import redDislike from './resources/images/svgs/selectIcon/red_dislike.svg'
import StarIcon from './resources/images/svgs/unSelectIcons/star.svg'
import MessageIcon from './resources/images/svgs/message.svg'
import VideoIcon from './resources/images/svgs/video.svg'
import AddIcon from './resources/images/svgs/add.svg'
import LikeIcon from './resources/images/svgs/selectIcon/like.svg'
import disLikeIcon from './resources/images/svgs/selectIcon/disLike.svg'
import grayLikeIcon from './resources/images/svgs/unSelectIcons/gray_dislike.svg'
import tickIcon from './resources/images/svgs/selectIcon/tick_svg_icon.svg'

// export const DataTable = DataTable_component;
export const AvatarProfile_img = profile_img
export const eshaafi_Logo = logo
export const calender_svg_icon = calender
export const thumsUp_svg_icon = thumsUp
export const edit_svg_icon = edit
export const del_svg_icon = del
export const clock_svg_icon = clock
export const like_svg_icon = likeIcon
export const moon_svg_icon = moonIcon
export const noon_svg_icon = noonIcon
export const morning_svg_icon = morningIcon
export const flag_svg_icon = flagIcon
export const fill_star_svg_icon = selectStarIcon
export const Like_svg_icon = LikeIcon
export const disLike_svg_icon = disLikeIcon
export const Star_svg_icon = StarIcon
export const Message_svg_icon = MessageIcon
export const Video_svg_icon = VideoIcon
export const Add_svg_icon = AddIcon
export const Red_dislike_svg_icon = redDislike
export const gray_dislike_svg_icon = grayLikeIcon
// export const Loading_Gif = loadingGif;
export const Tick_svg_icon = tickIcon
