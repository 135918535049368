/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Spinner } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import "./Editlab.css";
import { API_CONSTANTS } from "../../../../../API_CONSTANTS";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import Toast from "../../../../../Components/Toast/Toast";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { getLabOverview } from "../LabOverviewSlice";
import { addToCart } from "./labEditCartSlice";
// booking component
export default function EditLab() {
  const { value } = useSelector((state) => state.getLabReducer);
  const { cart } = useSelector((state) => state.labEditCartReducer);
  const [show, setShow] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [patientName, setPatientName] = useState(value.patientName);
  const [dateTime, setDateTime] = useState(
    moment(value.appoint_date, "YYYY-MM-DDTHH:mm").format("MM/DD/YYYY hh:mm A")
  );
  const [labName, setLabName] = useState(value.labName);
  const [typeTest, setTypeTest] = useState(value.testType);
  const [address, setAddress] = useState(value.patientAddress);
  const [secondaryPhone, setSecondaryPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [services, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [ToastStates, setToastStates] = useState({
    message: "",
    show: "",
    color: "",
    icon: faTimesCircle,
  });
  // handling toast
  const handleToast = (message, icon, iconColor, className) => {
    setToastStates({
      ...ToastStates,
      icon: icon,
      message: message,
      color: iconColor,
      show: `show ${className}`,
    });
    setTimeout(() => setToastStates({ ...ToastStates, show: "" }), 2900);
  };
  // handling modal show/hide functions
  const handleClose = () => {
    setShow(false);
    // setBookingTest({ type: "SET_MODAL_SERVICE_LIST", payload: [] });
  };
  // getting services data
  const getLabsData = () => {
    let param = '';
    if (labName === 'chugtai') {
      param = 'chughtai';
    } else if (labName === 'idc') {
      param = 'idc';
    }
    setIsLoading(true);
    axios
      .get(`${API_CONSTANTS.LABURL}admin/labs/tests?vendor=${param}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setService(res.data.response.Items);
        setIsLoading(false);
      })
      .catch((error) => {
        alert(error);
        setIsLoading(false);
      });
  };
  const handleSearch = () => {
    let param = '';
    if (labName === 'chugtai') {
      param = 'chughtai';
    } else if (labName === 'idc') {
      param = 'idc';
    }
    setIsLoading(true);
    axios
      .get(`${API_CONSTANTS.LABURL}admin/labs/tests`, {
        headers: API_CONSTANTS.headers(),
        params: {
          name: searchQuery,
          vendor: param,
        }
      })
      .then((res) => {
        setSearchResults(res.data.response.Items);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const handleShowNew = () => {
    getLabsData();
    setShow(true);
  };
  useEffect(() => {
    if (searchQuery.length >= 1) {
      handleSearch();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);
  function handleSubmit() {
    setLoading(true);
    const formattedDate = dayjs(dateTime).format("YYYY-MM-DD");
    const formattedTime = dayjs(dateTime).format("HH:mm");
    const data = {
      patientName: patientName,
      date: formattedDate,
      time: formattedTime,
      labName: labName,
      typeTest: typeTest,
      age: age,
      ...(secondaryPhone && { secondaryPhone: secondaryPhone }),
      ...(email && { email: email }),
      testUid: cart.map((item) => ({
        uuid: item.Sk,
        quantity: item.quantity.toString(),
      })),
    };
    axios
      .patch(
        `${API_CONSTANTS.LABURL}admin/lab/appointments/${params.id}/update`,
        typeTest === "homeSample" ? { ...data, address } : data,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.message === "Success") {
          handleToast(
            "Lab Test Update Successfully",
            faCheckCircle,
            "#009b94",
            "show-success"
          );
          navigate(`/lab/profile/${value?.appointmentId}/overview`);
          dispatch(getLabOverview(params.id));
          // window.location.href = `/lab/profile/${value?.appointmentId}/overview`;
          dispatch({
            type: "labEditCartSlicer/removeAllItems",
          });
        } else {
          // Handle API error here
          handleToast(
            "Error updating Lab Test",
            faTimesCircle,
            "#B00020",
            "show-error"
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        handleToast(
          "Please Check Date & Time while updating Lab Test",
          faTimesCircle,
          "#B00020",
          "show-error"
        );
      });
  }
  useEffect(() => {
    setPatientName(value.patientName);
    if (value && value.testDetails) {
      value.testDetails.forEach((item) => {
        dispatch(
          addToCart({
            ...item,
            quantity: parseInt(item.quantity),
            totalPrice: parseInt(item.productPrice) * parseInt(item.quantity),
            subTotalPrice: parseInt(item.subPrice) * parseInt(item.quantity),
          })
        );
      });
    }
    setDateTime(
      moment(value.appoint_date, "YYYY-MM-DDTHH:mm").format(
        "MM/DD/YYYY hh:mm A"
      )
    );
    setLabName(value.labName);
    setTypeTest(value.testType);
    setAddress(value.patientAddress);
    setSecondaryPhone(value.secondaryPhone);
    setEmail(value.email);
    setAge(value.age);
  }, [value]);

  const handleAddToCart = (item) => {
    const itemId = item.Sk;
    // Check if the cart already has items and get the vendor of the first item
    const cartHasItems = cart.length > 0;
    const firstItemVendor = cartHasItems ? cart[0].vendor : null;

    // Check if the new item's vendor matches the vendor of the items in the cart
    const isVendorMatch = cartHasItems ? item.vendor === firstItemVendor : true;
    if (!isVendorMatch) {
      // If the vendor doesn't match, show a toast message and don't dispatch the add to cart action
      handleToast(
        "Please select tests from the same lab you have selected already",
        faTimesCircle,
        '#B00020',
        'show-danger'
      )
    } else {
      const isAlreadyInCart = cart.some((cartItem) => cartItem.Sk === itemId);
      // Dispatch the addToCart action from the cart slice
      if (!isAlreadyInCart) {
        // Dispatch the addToCart action from the cart slice
        dispatch(
          addToCart({
            ...item,
            quantity: 1,
            totalPrice: item.productPrice,
            subTotalPrice: item.subPrice,
          })
        );
        handleToast(
          "Test Added to Cart",
          faCheckCircle,
          "#009b94",
          "show-success"
        );
      } else {
        // Show a message or notification indicating that the test is already in the cart
        handleToast(
          "Test is already in the Cart",
          faCheckCircle,
          "#009b94",
          "show-success"
        );
      }
    }
  };
  const handleCancel = () => {
    dispatch({ type: "labEditCartSlicer/removeAllItems" });
  };
  // handle disable for updating button checks
  const handleDisabled = () => {
    if (typeTest === "homeSample") {
      if (patientName && dateTime && labName && address) {
        return false;
      }
      return true;
    } else {
      if (patientName && dateTime && labName) {
        return false;
      }
      return true;
    }
  };
  const handleFocus = () => {
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          patientName: patientName,
          dateTime: dateTime,
          labName: labName,
          typeTest: typeTest,
          address: address,
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, touched, errors }) => (
          <Form>
            <section className={"form-group"}>
              <div className={"container-fluid"} style={{ padding: "32px" }}>
                <div className={"bg-white w-100"}>
                  <div className={"card_body"} style={{ padding: "1rem" }}>
                    <span> Book Test </span>
                    <div className="row mt-4 gap-2">
                      <div className={"col-md-5"}>
                        <Field
                          as={TextField}
                          type="text"
                          name="patientName"
                          value={patientName}
                          onChange={(e) => setPatientName(e.target.value)}
                          label="Patient Name"
                          variant="outlined"
                          fullWidth
                          error={touched.patientName && !!errors.patientName}
                          helperText={touched.patientName && errors.patientName}
                        />
                      </div>
                      <div className={"col-md-3"}>
                        <Field
                          as={TextField}
                          type="number"
                          name="age"
                          value={age}
                          onChange={(e) => setAge(e.target.value)}
                          label="Age (in Years)"
                          variant="outlined"
                          fullWidth
                          error={
                            touched.secondaryPhone && !!errors.secondaryPhone
                          }
                          helperText={
                            touched.secondaryPhone && errors.secondaryPhone
                          }
                        />
                      </div>
                      <div className={"col-md-3"}>
                        <TextField
                          type={focused ? "datetime-local" : "text"}
                          name="scheduleDateTime"
                          value={dateTime}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onChange={(e) => setDateTime(e.target.value)}
                          label="Select Date and Time"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="row gap-2">
                      <div className="col-md-5 my-4">
                        <TextField
                          select
                          name="labName"
                          value={labName}
                          onChange={(e) => setLabName(e.target.value)}
                          label="Lab"
                          variant="outlined"
                          fullWidth
                          error={touched.labName && !!errors.labName}
                          helperText={touched.labName && errors.labName}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="chugtai">Chugtai Lab</MenuItem>
                          <MenuItem value="idc">
                            Islamabad Diagnostic Centre
                          </MenuItem>
                        </TextField>
                      </div>
                      <div className="col-md-6 my-4">
                        <TextField
                          select
                          name="typeTest"
                          value={typeTest}
                          onChange={(e) => setTypeTest(e.target.value)}
                          label="Type"
                          variant="outlined"
                          fullWidth
                          error={touched.typeTest && !!errors.typeTest}
                          helperText={touched.typeTest && errors.typeTest}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="homeSample">Home Sample</MenuItem>
                          <MenuItem value="labVisit">Lab Visit</MenuItem>
                        </TextField>
                      </div>
                    </div>
                    <div className="row gap-2">
                      {typeTest === "homeSample" ? (
                        <div className={"col-md-5"}>
                          <Field
                            as={TextField}
                            type="text"
                            name="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            label="Address"
                            variant="outlined"
                            fullWidth
                            error={touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                          />
                        </div>
                      ) : null}
                      <div className={"col-md-3"}>
                        <Field
                          as={TextField}
                          type="number"
                          name="secondaryPhone"
                          value={secondaryPhone}
                          onChange={(e) => setSecondaryPhone(e.target.value)}
                          label="Phone No. (Optional)"
                          variant="outlined"
                          fullWidth
                          error={
                            touched.secondaryPhone && !!errors.secondaryPhone
                          }
                          helperText={
                            touched.secondaryPhone && errors.secondaryPhone
                          }
                        />
                      </div>
                      <div className={"col-md-3"}>
                        <Field
                          as={TextField}
                          type="text"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          label="Email (Optional)"
                          variant="outlined"
                          fullWidth
                          error={touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                        />
                      </div>
                    </div>
                    <div className="row mt-1 py-2 gutter-flx">
                      <div className="col-md-11">
                        <div
                          className={
                            "justify-content-between d-flex border-bottom my-4"
                          }
                        >
                          <span>Test</span>
                          <Button
                            onClick={handleShowNew}
                            style={{
                              backgroundColor: "#009B94",
                              marginBottom: "3px",
                              border: "none",
                            }}
                            disabled={!labName}
                          >
                            Add
                          </Button>
                        </div>
                        <ul className="testList">
                          {cart?.map((item, index) => (
                            <li className="cart w-100" key={index}>
                              <div className="d-flex justify-content-between flex-wrap">
                                <h6>
                                  {" "}
                                  Test:{" "}
                                  {item.MedicineName.length > 20
                                    ? item.MedicineName.slice(0, 20) + "..."
                                    : item.MedicineName}{" "}
                                </h6>
                                <p
                                  style={{
                                    color: "#009B94",
                                    fontWeight: "500",
                                  }}
                                >
                                  {item.discount}% off
                                </p>
                              </div>
                              <div className="card_footer d-flex justify-content-between align-items-center flex-wrap">
                                <p
                                  style={{
                                    color: "#009B94",
                                    fontWeight: "600",
                                  }}
                                >
                                  <span>Rs. {item.totalPrice} </span>
                                  <span
                                    style={{
                                      textDecoration: "line-through",
                                      color: "#989898",
                                    }}
                                  >
                                    Rs. {item.subTotalPrice}
                                  </span>
                                </p>
                                <div
                                  className="modal_count justify-content-between align-items-center d-flex border p-0"
                                  key={index}
                                >
                                  {item.quantity > 1 ? (
                                    <button
                                      className="cartButton"
                                      type="button"
                                      onClick={() =>
                                        dispatch({
                                          type: "labEditCartSlicer/addQuantity",
                                          payload: {
                                            barcode: item.barcode,
                                            quantity:
                                              parseInt(item.quantity) - 1,
                                            totalPrice:
                                              parseInt(item.totalPrice) -
                                              parseInt(item.productPrice),
                                            subTotalPrice:
                                              parseInt(item.subTotalPrice) -
                                              parseInt(item.subPrice),
                                          },
                                        })
                                      }
                                    >
                                      <RemoveIcon
                                        style={{
                                          borderRight: "1px solid #D7DDEA",
                                          color: "#009B94",
                                          width: "20px",
                                        }}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      className="cartButton"
                                      type="button"
                                      onClick={() =>
                                        dispatch({
                                          type: "labEditCartSlicer/removeCartItem",
                                          payload: item.barcode,
                                        })
                                      }
                                    >
                                      <DeleteOutlineIcon
                                        style={{
                                          borderRight: "1px solid #D7DDEA",
                                          color: "#009B94",
                                          width: "20px",
                                        }}
                                      />
                                    </button>
                                  )}
                                  <p>{item.quantity}</p>
                                  <button
                                    className="cartButton"
                                    type="button"
                                    onClick={() =>
                                      dispatch({
                                        type: "labEditCartSlicer/addQuantity",
                                        payload: {
                                          barcode: item.barcode,
                                          quantity: parseInt(item.quantity) + 1,
                                          totalPrice:
                                            parseInt(item.productPrice) +
                                            parseInt(item.totalPrice),
                                          subTotalPrice:
                                            parseInt(item.subPrice) +
                                            parseInt(item.subTotalPrice),
                                        },
                                      })
                                    }
                                  >
                                    <AddIcon
                                      style={{
                                        borderLeft: "1px solid #D7DDEA",
                                        color: "#009B94",
                                        width: "20px",
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="mt-5 d-flex">
                      <button
                        className="book"
                        onClick={handleSubmit}
                        disabled={handleDisabled()}
                      >
                        {loading && <Spinner animation="border" size="sm" />}
                        Update
                      </button>
                      <button
                        className="discard"
                        type="button"
                        onClick={handleCancel}
                      >
                        <Link
                          to={`/lab/profile/${value?.appointmentId}/overview`}
                        >
                          Cancel
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Form>
        )}
      </Formik>
      <Modal show={show} onHide={handleClose} size="m">
        <Modal.Header closeButton style={{ paddingBottom: 0 }}>
          <span
            className={" d-flex justify-content-center"}
            style={{
              color: "#5C5C5C",
              fontSize: "16px",
              fontWeightL: "600",
              textAlign: "center",
              width: "100%",
            }}
          >
            Add Labs Test
          </span>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="d-flex gap-2 pb-5">
              <input
                placeholder="Search Test Here"
                value={searchQuery}
                className="p-2 w-100 rounded searchTest"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div
              className="d-flex flex-column"
              style={{ maxHeight: "450px", overflowY: "auto" }}
            >
              {searchQuery
                ? searchResults.map((item, index) => (
                  <div
                    className="card w-100 mb-3"
                    style={{
                      boxShadow: "0px 1px 2px rgba(30, 30, 30, 0.16)",
                      borderRadius: "12px",
                    }}
                    key={index}
                  >
                    <div className="card_body_modal">
                      <div className="d-flex justify-content-between">
                        <div className="modal_des">
                          <h6>{item.MedicineName}</h6>
                          <p style={{ color: "#009B94", fontWeight: "600" }}>
                            <span>Rs. {item.productPrice} </span>
                            <span
                              style={{
                                textDecoration: "line-through",
                                color: "#989898",
                              }}
                            >
                              Rs. {item.subPrice}
                            </span>
                          </p>
                        </div>
                        <div
                          className="d-flex flex-column justify-content-between"
                          style={{ color: "#009B94", textAlign: "end" }}
                        >
                          <p>{item.discount}% off</p>
                          <Button
                            className="addTestButton"
                            onClick={() => handleAddToCart(item)}
                          >
                            Add Test
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : services.map((item, index) => (
                  <div
                    className="card w-100 mb-3"
                    style={{
                      boxShadow: "0px 1px 2px rgba(30, 30, 30, 0.16)",
                      borderRadius: "12px",
                    }}
                    key={index}
                  >
                    <div className="card_body_modal">
                      <div className="d-flex justify-content-between">
                        <div className="modal_des">
                          <h6>{item.MedicineName}</h6>
                          <p style={{ color: "#009B94", fontWeight: "600" }}>
                            <span>Rs. {item.productPrice} </span>
                            <span
                              style={{
                                textDecoration: "line-through",
                                color: "#989898",
                              }}
                            >
                              Rs. {item.subPrice}
                            </span>
                          </p>
                        </div>
                        <div
                          className="d-flex flex-column justify-content-between"
                          style={{ color: "#009B94", textAlign: "end" }}
                        >
                          <p>{item.discount}% off</p>
                          <Button
                            className="addTestButton"
                            onClick={() => handleAddToCart(item)}
                          >
                            Add Test
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="d-flex justify-content-center pb-2">
                {isLoading && (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ color: "#009b94" }}
                  />
                )}
              </div>
            </div>
            <div className="mt-3">
              {/* <Button
                onClick={handleServicesList}
                style={{
                  background: "#009B94",
                  border: "none",
                  width: "129px",
                  height: "48px",
                }}
              >
                Add Test
              </Button> */}
              {/* <Button
                type="button"
                style={{
                  background: "none",
                  color: "#B00020",
                  border: "none",
                  width: "129px",
                  height: "48px",
                }}
                onClick={handleClose}
              >
                Discard
              </Button> */}
            </div>
          </Modal.Body>
        </form>
      </Modal>
      <Toast
        cName={ToastStates.show}
        icon={ToastStates.icon}
        color={ToastStates.color}
        text={ToastStates.message}
      />
      <style jsx="true">
        {`
          .btn:focus {
            box-shadow: none !important;
          }
          .btn:active {
            border: none !important;
          }
          .searchTest {
            border: 1px solid #d7ddea;
            border-radius: 6px;
            outline: none;
          }
        `}
      </style>
    </>
  );
}
