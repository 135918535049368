import React, { useEffect } from 'react'

import { useParams, NavLink } from 'react-router-dom'
import { Outlet } from 'react-router'
//
import { useDispatch, useSelector } from 'react-redux'
//
import { getServices } from './overview/ServicesOverviewSlice'


export default function ServicesProfilePage() {
  const params = useParams()
  const dispatch = useDispatch()

  const { value } = useSelector((state) => state.getServicesProfileReducer)

  const PROFILE_NAV_LINKS = [
    {
      navLink: 'Overview',
      navRoute: `/services/profile/${params.id}/overview`,
    },
    {
      navLink: 'e-Prescription',
      navRoute: `/services/profile/${params.id}/e-prescription`,
    },
    {
      navLink: 'Services History',
      navRoute: `/services/profile/${params.id}/Service-history`,
    },
    {
      navLink: 'Reviews',
      navRoute: `/services/profile/${params.id}/reviews`,
    },
  ]

  useEffect(() => dispatch(getServices(params.id)), [params.id, dispatch])

  return (
    <>
      {value.message === 'No Record found..' && (
        <div className='loading'>There is no record</div>
      )}
      <div className='container-fluid profile-header'>
        <div className='row pt-4 mb-1'>
          <div className='col'>
            <div className='d-flex ms-4'>
              <div className='patient d-flex ms-4 align-items-start me-4'>
                <span className='me-4'>
                  <div className='patient-avatar'>
                    <img
                      src={value?.patientInfo?.url}
                      alt=''
                      style={{ width: 72, borderRadius: 50, height: 72 }}
                    />
                  </div>
                </span>
                <div className='d-flex flex-column mt-2'>
                  <h5
                    style={{ fontWeight: 500, marginBottom: 15, marginTop: -4 }}
                  >
                    {value.patientInfo.name}
                  </h5>
                  <h6
                    style={{
                      fontWeight: 400,
                      color: '#5C5C5C',
                      marginBottom: 0,
                    }}
                  >
                    {'Patient ID ' + value?.patientInfo?.phone}
                  </h6>
                </div>
              </div>
              <span className='border-line'></span>
              <div className='doctor d-flex ms-4 align-items-start'>
                <span className='me-4'>
                  <div className='patient-avatar'>
                    <img
                      src={value?.doctorInfo?.profileUrl}
                      alt=''
                      style={{ width: 72, borderRadius: 50, height: 72 }}
                    />
                  </div>
                </span>
                <div className='d-flex flex-column mt-2'>
                  <h5
                    style={{ fontWeight: 500, marginBottom: 15, marginTop: -4 }}
                  >
                    {value?.doctorInfo?.title +
                      ' ' +
                      value?.doctorInfo?.firstName +
                      ' ' +
                      value?.doctorInfo?.lastName}
                  </h5>
                  <h6
                    style={{
                      fontWeight: 400,
                      color: '#5C5C5C',
                      marginBottom: 0,
                    }}
                  >
                    {'Doctor ID ' + value?.doctorInfo?.pmdc}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row pt-4'>
          <div className='col'>
            <div className='d-flex ms-4' style={{ columnGap: 10 }}>
              {PROFILE_NAV_LINKS.map((navItem) => (
                <NavLink
                  to={navItem.navRoute}
                  key={navItem.navLink}
                  className={({ isActive }) =>
                    isActive ? 'active' : 'inactive'
                  }
                  style={{ fontSize: 14 }}
                >
                  {navItem.navLink}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='containter-fluid'>
        <Outlet />
      </div>
      <style jsx='true'>
        {`
          .profile-header {
            background: #e0f3f2;
            height: 160px;
          }
          .active {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .active:hover {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .inactive {
            padding: 7px 15px;
          }
          .border-line {
            border-left: 2px solid #009b94;
            border-radius: 5px;
          }
        `}
      </style>
    </>
  )
}
