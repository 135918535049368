import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import ReactTagInput from "@pathofdev/react-tag-input";
// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case "LOADING_TRUE": {
      return {
        ...state,
        loading: true,
      };
    }
    case "LOADING_FALSE": {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      };
    }
  }
}

//Doctor related filter search form
export default function FilterForm() {
  const filter_status = {
    date: useSelector((state) => state.TransactionFilterReducer.value),
    appoint_date_from: useSelector(
      (state) => state.TransactionFilterReducer.value1
    ),
    paid_through: useSelector(
      (state) => state.TransactionFilterReducer.value2 || []
    ),
  };

  const dispatch = useDispatch();
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    date: filter_status.date,
    appoint_date_from: filter_status.appoint_date_from,
    paid_through: filter_status.paid_through,
    loading: false,
  });
  const { appoint_date_from, date, paid_through, loading } = formData;
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormDataDispatch({ type: "LOADING_TRUE" });
    if (date !== undefined) {
      dispatch({
        type: "TransactionFilter/date",
        payload: date,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
    if (appoint_date_from !== undefined) {
      dispatch({
        type: "TransactionFilter/appoint_date_from",
        payload: appoint_date_from,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
    if (paid_through !== undefined) {
      paid_through.forEach((paid_through) => {
        dispatch({
          type: "TransactionFilter/" + paid_through,
        });
      });
    } else {
      dispatch({
        type: "TransactionFilter/all",
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
  };
  const handleDropdownChange = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption !== "all" && !paid_through.includes(selectedOption)) {
      const updatedPaidThrough = [...paid_through, selectedOption];
      setFormDataDispatch({
        type: "TransactionFilter/",
        target: {
          name: "paid_through",
          value: updatedPaidThrough,
        },
      });
    }
  };
  const handleTagInputChange = (newTags) => {
    dispatch({
      type: "TransactionFilter/paid_through",
      payload: newTags,
    });
    setFormDataDispatch({
      type: "TransactionFilter/",
      target: {
        name: "paid_through",
        value: newTags,
      },
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="for-group mt-3 px-2">
          <label
            for="exampleFormControlSelect1"
            style={{ fontWeight: 400, fontSize: 16, color: "#000" }}
          >
            Date From
          </label>
          <input
            onChange={setFormDataDispatch}
            name="appoint_date_from"
            type="date"
            className="form-control filter-input"
            id="appointmentDate"
            value={appoint_date_from}
          />
        </div>
        <div className="for-group mt-3 px-2">
          <label
            for="exampleFormControlSelect1"
            style={{ fontWeight: 400, fontSize: 16, color: "#000" }}
          >
            Date To
          </label>
          <input
            onChange={setFormDataDispatch}
            name="date"
            type="date"
            className="form-control filter-input"
            id="appointmentDate"
            value={date}
          />
        </div>

        <div className="form-group mt-3 px-2">
          <label
            htmlFor="exampleFormControlSelect1"
            style={{ fontWeight: 400, fontSize: 16, color: "#000" }}
          >
            Paid Through
          </label>
          <select
            className="form-control mb-2"
            id="exampleFormControlSelect1"
            value="all"
            onChange={handleDropdownChange}
          >
            <option value="all">All</option>
            <option value="DebitCard">DebitCard</option>
            <option value="wallet">Wallet</option>
            <option value="EasyPaisa">EasyPaisa</option>
            <option value="JazzCash">JazzCash</option>
            <option value="CorporatePayment">Corporate Payment</option>
            <option value="referralInvite">ReferralInvite</option>
            <option value="SubscriptionPayment">Subscription Payment</option>
            <option value="payFast">PayFast</option>
          </select>
          <ReactTagInput
            tags={paid_through}
            editable={true}
            name="paid_through"
            removeOnBackspace={true}
            className="form-control border-0 border-bottom"
            onChange={handleTagInputChange}
            validateOnChange={true}
          />
        </div>

        <button className="btn mt-3 filter-form-button ms-2">
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: "20px" }}
            />
          )}{" "}
          {loading || "Apply"}
        </button>
      </form>
      <style jsx>{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 95%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
      `}</style>
    </>
  );
}
