import React from 'react'
import './toastStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// toast component
export default function Toast(props) {
  return (
    <>
      <div id='snackbar' className={props.cName}>
        <FontAwesomeIcon
          icon={props.icon}
          color={props.color}
          style={{ marginRight: '8px' }}
        />
        {props.text}
      </div>
    </>
  )
}
