// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8KybozTI6ipKv5kKwodcmHi4FmutYGhA",
  authDomain: "eshaafi-patientapp.firebaseapp.com",
  databaseURL: "https://eshaafi-patientapp-default-rtdb.firebaseio.com",
  projectId: "eshaafi-patientapp",
  storageBucket: "eshaafi-patientapp.appspot.com",
  messagingSenderId: "651637779464",
  appId: "1:651637779464:web:a5924f75a2679126b8f5bd",
  measurementId: "G-12TSTRGVRQ",
};
// const firebaseConfig = {
//   apiKey: "AIzaSyCI9NHAl2zwkd6T5DSHn-Veicx2V1omv5c",
//   authDomain: "eshaafi-196c4.firebaseapp.com",
//   projectId: "eshaafi-196c4",
//   storageBucket: "eshaafi-196c4.appspot.com",
//   messagingSenderId: "59636761923",
//   appId: "1:59636761923:web:d1654f6c555f3dd7dadbf6",
//   measurementId: "G-29J95HTBL6",
// };
// initializing the app
const firebaseApp = initializeApp(firebaseConfig, "adminPanel");
// getting messages
const messaging = getMessaging(firebaseApp);
// Initialize Firebase
export const gettoken = async (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      // "BGjIWfnyirp_0V3lfH5BzOkhU6Ic12SmSw9OAwfNzgj-4kd5szIzhfPQinNoLIJ7gp50LrDk8fORRSN8WVz_Im0",
      "BPGO6oIB5SgKIPvqtRJWDJXC3laLFVlRbnARGjdgU03sQsnHLQxyhlE_W1OFNG0Qa8KO0zggGDQxKSefIZg9kGU",
  })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("deviceToken", JSON.stringify(currentToken));
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
// on messages listen
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
