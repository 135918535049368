/*
Author: Umer Shabir 
Date: 24-march-2022
File: state changed styling depend on the state of side bar and dashboard viewpoint, I managed all the state in redux store.
*/
// dependencies
import { createSlice } from '@reduxjs/toolkit'
// Sidebar width changing Slice function for redux store
export const sideBarWidthSlice = createSlice({
  name: 'sideBarWidth',
  initialState: {
    value: {
      width: '256px',
      gridTemplateColumns: '256px 1fr',
      class: 'd-inline',
      ulPadding: '0 0 0 5px',
    },
  },
  reducers: {
    true: (state) => {
      state.value = {
        width: '256px',
        gridTemplateColumns: '256px 1fr',
        class: 'd-inline',
        ulPadding: '0 0 0 5px',
      }
    },
    false: (state) => {
      state.value = {
        width: '80px',
        gridTemplateColumns: '80px 1fr',
        class: 'd-none',
        navBarCollapseWidth: '60px',
        ulPadding: '0 0 0 5px',
        navLinkWidthCollapse: '54PX',
        iconPadding: 0,
        iconMargin: '-5px',
      }
    },
  },
})
// exporting the reducer function.
export const sideBarWidthReducer = sideBarWidthSlice.reducer
