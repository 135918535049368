/*
author: umer shabir.
file: doctor availability module: It will set time slots for doctor avaialabilty schedule.
date: 16-08-2022.
*/
// dependencies
import React from 'react'
// Add time slot object
export default function AddTimeSlot({ addTimeSlot }) {
  const handleClick = () => {
    addTimeSlot({
      startTime: '',
      endTime: '',
      video: true,
      chat: false,
    })
  }
  return (
    <button
      className='custom-button btn'
      type='button'
      onClick={handleClick}
      style={{ color: '#009B94' }}
    >
      <i className='fas fa-plus'></i> Add Time Slot
    </button>
  )
}
//
