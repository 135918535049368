/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState } from "react";
import Table from "../../../../Components/Table";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import moment from "moment";
import FilterForm from "./FilterForm";
//
import AlertMessage from "../../../../Components/AlertMessage/index";
import { API_CONSTANTS } from "../../../../API_CONSTANTS";
import { Link } from "react-router-dom";
//
//
export default function ConsultationTableList() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [adminList, setAdminList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const fetchIdRef = React.useRef(0);
  //
  const filter_status = {
    appoint_type: useSelector((state) => state.consultationFilterReducer.value),

    appointment_status: useSelector(
      (state) => state.consultationFilterReducer.value1
    ),
    appoint_date: useSelector(
      (state) => state.consultationFilterReducer.value2
    ),
    appoint_date_from: useSelector(
      (state) => state.consultationFilterReducer.value3
    ),
  };
  // pagination call back
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here
      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setAdminList(adminList.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(adminList.length / pageSize));
      }
    },
    [adminList, lastEvaluatedKey]
  );
  const handleFilters = () => {
    // let currentDate = moment().format("yyyy-MM-DD");
    let filterData = {
      appoint_type: `${
        filter_status.appoint_type ? filter_status.appoint_type : ""
      }`,
      appointment_status: `${
        filter_status.appointment_status ? filter_status.appointment_status : ""
      }`,
      // appoint_date: "",
      // appoint_date: filter_status.appoint_date || currentDate,
      appoint_date: filter_status.appoint_date,
      // appoint_date_from: "",
      // appoint_date_from: filter_status.appoint_date_from || currentDate,
      appoint_date_from: filter_status.appoint_date_from,
    };
    if (Object.values(filterData).every((key) => key === "")) {
      return {};
    } else
      return Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== "")
      );
  };
  //
  // API Hatching data of all admins
  const userList = () => {
    axios
      .post(`${API_CONSTANTS.BASEURL}admin/appointments`, handleFilters(), {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setIsLoading(true);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
        setAdminList(res.data.response.Items ? res.data.response.Items : []);
        // Set typeCount directly from the length of Items
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setIsLoading(true);
        setAdminList([]);
        // setError(error)
      });
  };
  const [page, setPage] = useState(0);

  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL}admin/appointments?last=${lastEvaluatedKey}`,
        handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var adminListNextPage = response.data.response.Items;
        setAdminList([...adminList, ...adminListNextPage]);
        setPage(page + 1);
        setCurrentPage(currentPage + 1);
      })
      .then(() => setLoading(true));
  };
  const onDownloadBtn = () => {
    const filters = handleFilters();
    if (!filters || Object.keys(filters).length === 0) {
      alert("Please select valid filters before proceeding.");
      return;
    }
    axios
      .post(
        `${API_CONSTANTS.BASEURL}admin/appointments?loadall=true`,
        filters, // Use the valid filters here
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        const s3Url = response.data.response.s3_url;
        const link = document.createElement("a");
        link.href = s3Url;
        link.download = "consultation.csv"; // Set the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(true);
      })
      .catch((error) => {
        // Handle error cases here
        console.error("An error occurred:", error);
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: "Sr",
        accessor: (row, index) => index + 1 + currentPage * pageCount,
      },
      {
        Header: "Consultation Type",
        accessor: (property) =>
          property.communicationMode
            ? `${property.appoint_type} Appointment: ` +
              property.communicationMode
            : "",
      },
      {
        Header: "Patient Name",
        accessor: "patientInfo.name",
        Cell: ({ cell: { value } }) => value || "N/A",
      },
      {
        Header: "Patient Phone",
        accessor: "patientPhone",
      },
      {
        Header: `Doctor's Name`,
        accessor: "doctorName",
      },
      {
        Header: "Status",
        accessor: (property) => {
          const status = property.appointment_status.replace(
            /(?:^|\s)\S/g,
            function (a) {
              return a.toUpperCase();
            }
          );
          if (status === "Reschedule") {
            return "Rescheduled";
          } else if (status === "Reschedule") {
            return "Rescheduled";
          }
          return status;
        },
      },
      {
        Header: "Consultation Date/time",
        accessor: (a) => moment(a.appoint_date).format("DD-MM-YYYY h:mm A"),
      },
      {
        Header: "Company",
        accessor: (a) =>
          a?.corporateInfo ? a?.corporateInfo?.companyName : "-",
      },
      {
        Header: "View",
        accessor: (a) => (
          <Link to={`/consultations/profile/${a.appointmentId}/overview`}>
            View
          </Link>
        ),
      },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  // rendering API
  useEffect(() => {
    setCurrentPage(0);
    userList();
  }, [
    filter_status.appoint_date,
    filter_status.appoint_date_from,
    filter_status.appoint_type,
    filter_status.appointment_status,
  ]);
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 404"
            ? "This record does not exist"
            : error.message === "Request failed with status code 401"
            ? is_SessionExpire()
            : error.message
        }
      />
    );
  } else if (!isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" style={{ color: "#24AAA4" }} />
      </div>
    );
  } else {
    return (
      <div id="table-view" className="row rounded bg-white m-4">
        <div>
          {" "}
          <h3
            style={{ fontSize: "16px", fontWeight: "500", paddingTop: "1rem" }}
          >
            All Consultation
          </h3>
        </div>
        <Table
          pageNumber={currentPage}
          columns={columns}
          data={adminList}
          routingLink={"/consultations/profile"}
          pageCount={pageCount}
          fetchData={fetchData}
          loadMoreList={handleLoadMoreData}
          recordDownload={true}
          lastEvaluatedKey={lastEvaluatedKey}
          loading={loading}
          setLoading={setLoading}
          FilterForm={<FilterForm />}
          onDownloadBtn={onDownloadBtn}
        />
      </div>
    );
  }
}
