import { useState, useReducer } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Path from './Path'
import axios from 'axios'
import { API_CONSTANTS } from '../../API_CONSTANTS'
//
const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT': {
      return {
        ...state,
        loading: true,
      }
    }
    case 'FETCH_SUCCESS': {
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    }
    case 'EDIT_ROUTE': {
      return {
        ...state,
        data: action.payload,
      }
    }
    case 'DELETE_ROUTE': {
      return {
        ...state,
        data: action.payload,
      }
    }
    case 'ADD_ROUTE': {
      return {
        ...state,
        data: action.payload,
      }
    }
    default: {
      console.log('alert')
    }
  }
}
//
export default function RolePermission() {
  const [getData, setGetData] = useReducer(reducer, {
    data: [],
    loading: false,
    error: false,
  })
  const { loading, error, data } = getData
  // console.log(getData)
  const [value, setValue] = useState('')
  const editRoute = (lastValue, newValue, name) => {
    let updatedData = data.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          path: item.path.map((item) => {
            if (item === lastValue) {
              return newValue
            }
            return item
          }),
        }
      }
      return item
    })
    setGetData({ type: 'EDIT_ROUTE', payload: updatedData })
  }
  // deleting locally
  const deleteroute = (value, method) => {
    let updatedData = data.map((item) => {
      if (item.name === method) {
        return { ...item, path: item.path.filter((item) => item !== value) }
      }
      return item
    })
    setGetData({ type: 'DELETE_ROUTE', payload: updatedData })
  }
  // adding route locally
  const addroute = (value, method) => {
    let updateData = data.map((item) => {
      if (item.name === method) {
        return { ...item, path: [...item.path, value] }
      }
      return item
    })
    setGetData({ type: 'ADD_ROUTE', payload: updateData })
  }
  // fetching data on change value reques
  const handleChange = (event) => {
    setValue(event.target.value)
    console.log(event.target.value)
    setGetData({ type: 'FETCH_INIT' })
    axios
      .get(API_CONSTANTS.BASEURL_2 + 'admin/roles/' + event.target.value, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        console.log(res.data.response.Items)

        setGetData({ type: 'FETCH_SUCCESS', payload: res.data.response.Items })
      })
  }
  return (
    <div className='container-fluid '>
      <div className='row justify-content-center my-4'>
        <div className='col-12 col-md-6'>
          <div className='d-flex flex-column w-100 justify-content-center bg-white rounded border'>
            <div className=' border-bottom my-2'>
              <p className='m-0 m-2'>Role Based Access Control</p>
            </div>
            <div className='m-4'>
              <FormControl variant='standard' sx={{ width: '100%' }}>
                <InputLabel id='demo-simple-select-standard-label'>
                  Department
                </InputLabel>
                <Select
                  labelId='demo-simple-select-standard-label'
                  id='demo-simple-select-standard'
                  value={value}
                  onChange={handleChange}
                  label='department'
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='CustomerSupport'>Customer Support</MenuItem>
                  <MenuItem value='Accountant'>Accountant</MenuItem>
                  <MenuItem value='DevTeam'>Development Team</MenuItem>
                   <MenuItem value='HealthCare'>Health Care</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              {data.map((item, index) => (
                <Path
                  // getList={getList}
                  // putList={putList}
                  // deleteList={deleteList}
                  // postList={postList}
                  data={item}
                  editRoute={editRoute}
                  deleteroute={deleteroute}
                  key={index}
                  addroute={addroute}
                />
              ))}
            </div>
          </div>
        </div>
      <style jsx='true'>
        {`
        @media screen and (max-width: 1050px) {
          .main1{
            width: 100% !important;
          }
        }
        `}
      </style>
    </div>
    </div>
  )
}
