/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState } from "react";
import Table from "../../../Components/Table/index";
import axios from "axios";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { useSelector } from "react-redux";
import FilterForm from "./FilterForm";
//
import AlertMessage from "../../../Components/AlertMessage";
import { API_CONSTANTS } from "../../../API_CONSTANTS";
//
export default function Transaction() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const fetchIdRef = React.useRef(0);

  const filter_status = {
    date: useSelector((state) => state.TransactionFilterReducer.value),
    appoint_date_from: useSelector(
      (state) => state.TransactionFilterReducer.value1
    ),
    paid_through: useSelector((state) => state.TransactionFilterReducer.value2),
  };
  const handleFilters = () => {
    let filterData = {
      date: filter_status.date ? filter_status.date : "",
      appoint_date_from: filter_status.appoint_date_from
        ? filter_status.appoint_date_from
        : "",
      paid_through: Array.isArray(filter_status.paid_through)
        ? filter_status.paid_through
        : [],
    };

    // Remove empty values from the paid_through array
    // filterData.paid_through = filterData.paid_through.filter((value) => value.trim() !== '');

    filterData.paid_through = [...new Set(filterData.paid_through)];
    // Create a new object to filter out empty or falsy properties
    const filteredPayload = {};
    if (filterData.date) {
      filteredPayload.date = filterData.date;
    }

    if (filterData.appoint_date_from) {
      filteredPayload.appoint_date_from = filterData.appoint_date_from;
    }

    if (filterData.paid_through.length) {
      filteredPayload.paid_through = filterData.paid_through;
    }

    if (Object.keys(filteredPayload).length === 0) {
      return {};
    } else {
      return filteredPayload;
    }
  };

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = lastEvaluatedKey;
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setTransactions(transactions.slice(startRow, endRow));
        setPageCount(Math.ceil(transactions.length / pageSize));
      }
    },
    [transactions, lastEvaluatedKey]
  );
  // API Hatching data of all transactions
  const transactionsList_API = () => {
    axios
      .post(`${API_CONSTANTS.BASEURL_1}admin/transactions`, handleFilters(), {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setIsLoading(true);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
        setTransactions(res.data.response.Items);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setIsLoading(true);
        setError(error);
      });
  };
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/transactions?last=${lastEvaluatedKey}`,
        handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var patientListNextPage = response.data.response.Items
          ? response.data.response.Items
          : [];
        setTransactions([...transactions, ...patientListNextPage]);
        setPage(page + 1);
      })
      .then(() => setLoading(true));
  };
  const onDownloadBtn = () => {
    const filters = handleFilters();
    if (!filters || Object.keys(filters).length === 0) {
      alert("Please select valid filters before proceeding.");
      return;
    }
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/transactions?loadall=true`,
        filters,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        const s3Url = response.data.response.s3_url;
        const link = document.createElement("a");
        link.href = s3Url;
        link.download = "consultation.csv"; // Set the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(true);
      })
      .catch((error) => {
        // Handle error cases here
        console.error("An error occurred:", error);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date/time",
        accessor: (a) => moment(a.created_at).format("DD-MM-YYYY"),
      },
      {
        Header: "Service",
        accessor: (property) =>
          property.service
            ? "Transaction For: " + property.service
            : "Transaction For: " +
              (property.purpose === "SubscriptionPlanFee"
                ? "Subscription Plan Fee"
                : property.purpose),
      },
      {
        Header: "Amount (PKR)",
        accessor: "amount",
      },
      {
        Header: "Paid through",
        accessor: (a) =>
          a.paid_through === "SubscriptionPayment"
            ? "Subscription Payment"
            : a.paid_through === "referralInvite"
            ? "Referral Invite"
            : a.paid_through,
      },
      {
        Header: " Status",
        accessor: (property) =>
          property.transaction_status.replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase();
          }),
      },
      {
        Header: "Action",
        accessor: (a) => {
          if (a.purpose === "ReferralReward") {
            return (
              <Link to={`/patients/profile/${a.uuid}/referral`}>View</Link>
            );
          } else if (a.appointmentId?.startsWith("SUB")) {
            return (
              <Link to={`/patients/profile/${a.uuid}/overview`}>View</Link>
            );
          } else {
            return (
              <Link to={`/consultations/profile/${a.appointmentId}/overview`}>
                View
              </Link>
            );
          }
        },
      },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  // rendering API
  useEffect(() => {
    transactionsList_API();
  }, [
    filter_status.appoint_date_from,
    filter_status.date,
    filter_status.paid_through,
  ]);
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 404"
            ? "This record does not exist"
            : error.message === "Request failed with status code 401"
            ? is_SessionExpire()
            : error.message
        }
      />
    );
  } else {
    return (
      <>
        <div className="row row rounded bg-white m-4">
          <div className="d-flex align-items-start">
            <h3
              style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}
            >
              All Transactions
            </h3>
            {!isLoading && (
              <div className="d-flex justify-content-center mt-4">
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "#24AAA4" }}
                />
              </div>
            )}
          </div>
          <Table
            pageNumber={page}
            columns={columns}
            data={transactions}
            routingLink={""}
            pageCount={pageCount}
            fetchData={fetchData}
            loadMoreList={handleLoadMoreData}
            recordDownload={true}
            lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            setLoading={setLoading}
            FilterForm={<FilterForm />}
            onDownloadBtn={onDownloadBtn}
          />
        </div>
      </>
    );
  }
}
