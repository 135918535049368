import React from 'react'

import { useState } from "react";
import axios from 'axios'
import { API_CONSTANTS } from '../../../API_CONSTANTS'
import Reschedule from './BookAppointment.js'
export default function PaymentInfo() {
 const [type, setType] = useState('');
  const [orderId, setOrderId] = useState('');
 const [responseData, setResponseData] = useState({});
  const [responseBook, setResponseBook] = useState({});
 
 const handleSubmit = (event) => {
    event.preventDefault();
 axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/users/paymentStatus/${type}/orderId/${orderId}`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
     setResponseData(res.data.response.alfaData);
     setResponseBook(res.data.response.bookingDetails)
      })
      .catch((error) => {

      })

  };
  const amount = responseData.TransactionAmount
  return (
    <>
     <div className="  m-4  bg-white rounded ">
         <h4 className= "pt-4 mx-4">
           Appointment Payment Status
         </h4>
         <div class="form d-flex gx-3 gy-2 align-items-center pb-4 mx-3  ">
          <div class="col-sm-5">
          <label class="visually-hidden" for="specificSizeInputName">Name</label>
           <input type="text"
            class="form-control"

            required
            placeholder="Place Payment  order ID here"
              id="orderId"
            value={orderId}
             onChange={(event) => setOrderId(event.target.value)}
           />
          </div>
           <div class="col-sm-3 mx-2">
          <label class="visually-hidden" for="specificSizeSelect">Preference</label>
          <select class="form-select"
            required
            id="type"
           value={type}
            onChange={(event) => setType(event.target.value)}
          >
             <option selected>Choose...</option>
            <option value="DebitCard">Debit Card</option>
          <option value="jazzcash">jazzcash</option>
          <option value="easypaisa">easypaisa</option>
           </select>
           </div>
          <div class="col-auto">
          <button type="submit" className="btn butnst" onClick ={handleSubmit} >Submit</button>
           </div>
           </div>

               <div className="row mx-3 py-4">
              <div className="col-5 flex-wrap ">
               <h4>AlfaData  Details :</h4>
                  <table className='bg-white rounded profile-card-width w-100'>
                <thead></thead>
                   <tbody>
                   <tr>
                    <th>Description</th>
                   <td>{responseData.Description}</td>
                  </tr>
                  <tr>
                    <th>MerchantId</th>
                       <td>{responseData.MerchantId}</td>
                  </tr>
                  <tr>
                    <th>
                    MerchantName
                    </th>
                   <td>{responseData.MerchantName}</td>
                  </tr>
                  <tr>
                    <th>StoreId</th>
                   <td>{responseData.StoreId}</td>
                  </tr>
                   <tr>
                    <th>StoreName</th>
                       <td>{responseData.StoreName}</td>
                  </tr>
                   <tr>
                    <th>TransactionTypeId</th>
                       <td>{responseData.TransactionTypeId}</td>
                  </tr>
                   <tr>
                    <th>TransactionReferenceNumber</th>
                       <td>{responseData.TransactionReferenceNumber}</td>
                  </tr>
                   <tr>
                    <th>OrderDateTime</th>
                       <td>{responseData.OrderDateTime}</td>
                  </tr>
                   <tr>
                    <th>TransactionId</th>
                       <td>{responseData.TransactionId}</td>
                  </tr>
                   <tr>
                    <th>TransactionReferenceNumber</th>
                       <td>{responseData.TransactionReferenceNumber}</td>
                  </tr>
                   <tr>
                    <th>TransactionDateTime</th>
                       <td>{responseData.TransactionDateTime}</td>
                  </tr>
                   <tr>
                    <th>TransactionAmount</th>
                       <td>{responseData.TransactionAmount}  PKR</td>
                  </tr> <tr>
                    <th>MobileNumber</th>
                       <td>{responseData.MobileNumber}</td>
                  </tr>
                   <tr>
                    <th>TransactionStatus</th>
                       <td>{responseData.TransactionStatus}</td>
                  </tr>
               </tbody>
              </table>
              </div>
              <div className="col-6 flex-wrap">
                 <h4>Booking Details :</h4>
                 <table className='bg-white rounded profile-card-width w-100'>
                 <thead></thead>
                <tbody>
                    
                    <tr>
                    <th>appoint_date</th>
                    <td>{responseBook.appoint_date}</td>
                  </tr>

                   <tr>
                    <th>appoint_date</th>
                    <td>{responseBook.appoint_start_time}</td>
                  </tr>
                     <tr>
                    <th>appointment_status</th>
                    <td>{responseBook.appointment_status}</td>
                  </tr>
                     <tr>
                    <th>charges</th>
                    <td>{responseBook.charges}</td>
                  </tr>
                     <tr>
                    <th>communicationMode</th>
                    <td>{responseBook.communicationMode}</td>
                  </tr>
                     <tr>
                    <th>doctorId</th>
                    <td>{responseBook.doctorId}</td>
                  </tr>
                    <tr>
                    <th>userId</th>
                    <td>{responseBook ? responseBook.userId :"na"}</td>
                  </tr>
                  <tr>
                  <th>Dr Name</th>
                    <td>{responseBook.doctorInfo?responseBook.doctorInfo.title:''} {}
                    {responseBook.doctorInfo?responseBook.doctorInfo.firstName:""}{responseBook.doctorInfo?responseBook.doctorInfo.lastName:''}</td>
                   </tr>
                          </tbody>
                       </table>
                       <h4 className='mt-4'> Booking Appointment</h4>
                      <Reschedule  orderId={orderId} amountProp={amount}/></div>
                    </div> 
                     
                   
             </div>







            <style jsx='true'>{`

           .butnst{
            margin: 0px !important;
           background-color: #009b94 !important;
            font-weight: 500;
           font-size: 14px;
           line-height: 24px;
           text-align: center;
           letter-spacing: 1.25px;
           color: #ffffff;
           border: none;
           transition: 0.2s ease-in-out;
           }
            table {
          font-size: 12px;
        }
        th {
          font-weight: 700;
          padding: 12px;
        }
        td {
          text-align: center;
          padding: 12px;
        }
        `}</style>
    </>
  )
}


