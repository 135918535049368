import React, { useMemo, useEffect, useState } from 'react'
import Table from '../../../../../Components/Table'
import axios from 'axios'

import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import FilterForm from './Transaction/FilterForm'
//
import AlertMessage from '../../../../../Components/AlertMessage'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
//
//
export default function TransactionsList() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [transactions, setTransactions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(0)
  const fetchIdRef = React.useRef(0)
  
  const params = useParams()
  const filter_status = {
    appoint_date_from: useSelector(
      (state) => state.patientTransactionFilterReducer.value3
    ),
    date: useSelector(
      (state) => state.patientTransactionFilterReducer.value
    ),
    amount: useSelector(
      (state) => state.patientTransactionFilterReducer.value1
    ),
    transaction_status: useSelector(
      (state) => state.patientTransactionFilterReducer.value2
    ),
  }
  const handleFilters = () => {
    let filterData = {
      appoint_date_from: `${filter_status.appoint_date_from ? filter_status.appoint_date_from : ''
        }`,
      date: `${filter_status.date ? filter_status.date : ''
        }`,
      amount: `${filter_status.amount ? filter_status.amount : ''
        }`,
      transaction_status: `${filter_status.transaction_status ? filter_status.transaction_status : ''
        }`,
    }
    if (Object.values(filterData).every((key) => key === '')) {
      return {}
    } else
      return Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== '')
      )
  }
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setTransactions(transactions.slice(startRow, endRow))

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(transactions.length / pageSize))
      }
    },
    [transactions, lastEvaluatedKey]
  )
  //
  // API Hatching data of all transactions
  const transactionsList_API = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL}admin/patients/${params.id}/transactions`,
        handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true)
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
        setTransactions(res.data.response.Items)
      })
      .catch((error) => {
        // if (error.response.status === 401) {
        //   window.location.reload()
        //   window.localStorage.clear()
        // }
        setIsLoading(true)
        setError(error)
      })
  }
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL}admin/patients/${params.id}/transactions?last=${lastEvaluatedKey}`,
        handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
        var patientListNextPage = response.data.response.Items
          ? response.data.response.Items
          : []
        setTransactions([...transactions, ...patientListNextPage])
        setPage(page + 1)
      })
      .then(() => setLoading(true))
  }
  const onDownloadBtn = () => {
    const filters = handleFilters()
    if (!filters || Object.keys(filters).length === 0) {
      alert("Please select valid filters before proceeding.");
      return;
    }
    axios
      .post(
        `${API_CONSTANTS.BASEURL}admin/patients/${params.id}/transactions?loadall=true`,handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        const s3Url = response.data.response.s3_url;
        const link = document.createElement('a');
        link.href = s3Url;
        link.download = 'consultation.csv'; // Set the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
        setLoading(true);
      })
      .catch((error) => {
        // Handle error cases here
        console.error("An error occurred:", error);
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (a) => moment(a.created_at).format('DD-MM-YYYY'),
      },
      {
        Header: 'Service',
        accessor: (property) =>
          property.service ? 'Booked Appointment: ' + property.service : '',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },

      {
        Header: 'Paid through',
        accessor: 'paid_through',
        // accessor: (property) => {
        //   property.paid_through.slice(1)
        // },
      },
      {
        Header: 'Status',
        accessor: (property) =>
          property.transaction_status.replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase()
          }),
      },
      // {
      //   Header: ' ',
      //   accessor: (res) => <TransactionActionButtons response={res} />,
      //   style: { width: '100px' },
      // },
    ],
    []
  )
  const is_SessionExpire = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  // rendering API
  useEffect(() => {
    transactionsList_API()
  }, [
    filter_status.amount,
    filter_status.appoint_date_from,
    filter_status.date,
    filter_status.transaction_status,
  ])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 404'
            ? 'This record does not exist'
            : error.message === 'Request failed with status code 401'
              ? is_SessionExpire()
              : error.message
        }
      />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <>
        <div className='row rounded bg-white me-1'>
          <div>
            <h3
              style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}
            >
              All Transactions
            </h3>
          </div>
          <Table
            pageNumber={page}
            columns={columns}
            data={transactions}
            routingLink={'/consultations/profile'}
            pageCount={pageCount}
            fetchData={fetchData}
            loadMoreList={handleLoadMoreData}
            lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            setLoading={setLoading}
            FilterForm={<FilterForm />}
            recordDownload={true}
            onDownloadBtn={onDownloadBtn}
          />
        </div>
      </>
    )
  }
}
