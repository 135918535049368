import React, { useMemo, useState, useEffect } from 'react';
import Table from '../../../../../Components/Table';
import axios from 'axios';
import { useParams } from 'react-router';
import { API_CONSTANTS } from '../../../../../API_CONSTANTS';
import moment from 'moment';

export default function CallRecordTable() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('');
  const [recording, setRecording] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  const { id } = useParams();

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    const fetchId = lastEvaluatedKey;

    if (fetchId === fetchIdRef.current) {
      const startRow = pageSize * pageIndex;
      const endRow = startRow + pageSize;
      const slicedRecording = recording.slice(startRow, endRow);

      setRecording(slicedRecording);
      setPageCount(Math.ceil(slicedRecording.length / pageSize));
    }
  }, [recording, lastEvaluatedKey]);

  useEffect(() => {
    const recordingList_API = async () => {
      try {
        const res = await axios.get(
          `${API_CONSTANTS.BASEURL_1}admin/appointmentlogs/${id}`,
          { headers: API_CONSTANTS.headers() }
        );
        setRecording(res.data.response.Items);
        setIsLoading(true);
      } catch (error) {
        setIsLoading(true);
        console.error(error);
      }
    };

    recordingList_API();
  }, [id]);

  const columns = useMemo(
    () => [
      {
        Header: 'Log Enter Time',
        accessor: 'logEnterTimer',
        Cell: ({ value }) => {
          const formattedTime = moment(value, 'HH:mm:ss').format('hh:mm A');
          return <span>{formattedTime}</span>;
        },
      },
    
      {
        Header: 'Start Call Time',
        accessor: 'startCallTime',
        Cell: ({ value }) => {
          const formattedTime = moment(value, 'HH:mm:ss').format('hh:mm:ss A');
          return <span>{formattedTime}</span>;
        },
      },
      {
        Header: 'Call Received Time',
        accessor: 'callRecievedTime',
        Cell: ({ value }) => {
          if (value === 'notAttended') {
            return (
              <span style={{ color: 'red', fontWeight: 'bold' }}>Not Attended</span>
            );
          } else {
            const formattedTime = moment(value, 'HH:mm:ss').format('hh:mm:ss A');
            return <span>{formattedTime}</span>;
          }
        },
      },
      {
        Header: 'End Call Time',
        accessor: 'endCallTime',
        Cell: ({ value }) => {
          if (value === 'null') {
            return (
              <span style={{ color: 'red', fontWeight: 'bold' }}>-</span>
            );
          } else{
          const formattedTime = moment(value, 'HH:mm:ss').format('hh:mm:ss A');
          return <span>{formattedTime}</span>;
          }
        },
      },
      {
        Header: 'Call Duration',
        accessor: 'callDuration',
        Cell: ({ value }) => {
          if (value === 'null') {
            return <span style={{ color: 'red', fontWeight: 'bold' }}>-</span>;
          } else {
            const duration = moment.duration(value);
            const minutes = duration.minutes();
            const seconds = duration.seconds();
            return (
              <span>
                {minutes} mins {seconds} secs
              </span>
            );
          }
        },
      },
      
      {
        Header: 'Call End By',
        accessor: 'callEndBy',
        Cell: ({ value }) => {
          if (value === 'Patient') {
            return (
              <span style={{ color: '#00568a', fontWeight: 'bold' }}>{value}</span>
            );

          } if(value === 'null') {
            return (
              <span style={{ color: 'red', fontWeight: 'bold' }}>-</span>
            );
            
          } 
          else  {
            return (
              <span style={{ color: '#ff9900', fontWeight: 'bold' }}>{value}</span>
            );
          }
        },
      },
    ],
    []
  );

  const calculateTotalCallDuration = () => {
    let totalMinutes = 0;
    let totalSeconds = 0;
  
    recording.forEach((item) => {
      if (item.callDuration && item.callDuration !== 'null') {
        const durationParts = item.callDuration.split(':');
  
        // Check if the durationParts array has the expected format
        if (durationParts.length === 3) {
          const minutes = parseInt(durationParts[1]);
          const seconds = parseInt(durationParts[2]);
  
          // Check if the parsed minutes and seconds are valid numbers
          if (!isNaN(minutes) && !isNaN(seconds)) {
            totalMinutes += minutes;
            totalSeconds += seconds;
          }
        }
      }
    });
  
    // Handle overflow from seconds to minutes
    totalMinutes += Math.floor(totalSeconds / 60);
    totalSeconds = totalSeconds % 60;
  
    return (
      <div className='bg-white d-flex col-3 rounded py-2 justify-content-between'>
        <span className='mx-2' style={{ color: 'black', fontWeight: 'bold' }}>Total Call Duration :</span>
        <span   className='mx-2'>
          <span style={{ fontWeight: 'bold' }}>{totalMinutes}</span> Mins{' '}
          <span style={{ fontWeight: 'bold' }}>{totalSeconds}</span> Secs
        </span>
      </div>
    );
  };

  return (
    <>
      {calculateTotalCallDuration()}

      <div className='bg-white d-flex flex-column rounded mt-3'>
        <div>
          <h3 style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}>
            Call Logs
          </h3>
        </div>
        <Table
          columns={columns}
          data={recording}
          routingLink=''
          pageCount={pageCount}
          fetchData={fetchData}
          lastEvaluatedKey={lastEvaluatedKey}
          loading={loading}
          setLoading={setLoading}
          FilterForm={null}
          rowClassName={(row) =>
            row.original.callRecievedTime === 'notAttended' ? 'not-attended-row' : ''
          }
        />
      </div>
      <style jsx='true'>
        {`
          .table-row:hover {
            box-shadow: inset 1px 0 0 #dadce0,
              inset -1px 0 0 #dadce0,
              0 1px 2px 0 rgb(60 64 67 / 30%),
              0 1px 3px 1px rgb(60 64 67 / 15%) !important;
            cursor: auto !important;
          }
          td {
            text-align: left !important;
          }
          .not-attended-row {
            background-color: #ffcccc;
          }
        `}
      </style>
    </>
  );
}
