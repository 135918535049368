/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState } from 'react'
import Table from '../../../../../Components/Table'
import axios from 'axios'

import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import AlertMessage from '../../../../../Components/AlertMessage'
import FilterForm from './FilterForm'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
import AddNewUserButton from '../../../../../Components/AddNewUserButton'
import { Link } from 'react-router-dom';

export default function Lab() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [patientList, setpatientList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0)
  const params = useParams()
  const [page, setPage] = useState(1)

  const filter_status = {
    test_type: useSelector((state) => state.LabFilterReducer.value),
    appoint_type: useSelector((state) => state.LabFilterReducer.value1),
    appoint_date: useSelector((state) => state.LabFilterReducer.value2),
    appointment_status: useSelector((state) => state.LabFilterReducer.value3),
  }
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = lastEvaluatedKey
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setpatientList(patientList.slice(startRow, endRow))
        setPageCount(Math.ceil(patientList.length / pageSize))
      }
    },
    [patientList, lastEvaluatedKey]
  )
  // API Hatching data of all admins
  const userList = () => {
    axios
      .post(`${API_CONSTANTS.LABURL}admin/patients/${params.id}/lab/appointments`, filter_status, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        // console.log(res)
        setIsLoading(true)
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
        setpatientList(res.data.response.Items)
      })
      .catch((error) => {
        if (error.response.state === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setIsLoading(true)
        setError(error)
      })
  }
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.LABURL}admin/patients/${params.id}/lab/appointments?last=${lastEvaluatedKey}`,
        filter_status,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
        var patientListNextPage = response.data.response.Items
        setpatientList([...patientList, ...patientListNextPage])
        setPage(page + 1)
      })
      .then(() => setLoading(true))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Patient Name',
        accessor: 'patientName'
        // accessor: (item) =>
        //   item.appoint_type === 'NurseBooked'
        //   && 'Nurse Booked'
      },
      {
        Header: 'Date',
        accessor: (item) => moment(item.appoint_date, 'YYYY-MM-DD').format('D-MM-YYYY'),
      },
      {
        Header: 'Time',
        accessor: (item) => moment(item.appoint_date).format('h:mm A'),
      },
      {
        Header: 'Lab',
        accessor: 'labName'
      },
      {
        Header: 'Status',
        accessor: 'appointment_status',
        Cell: ({ value }) => (
          value === 'lab_confirmed'
            ? <span>Confirmed</span>
            : value === 'lab_pending'
              ? <span>Pending</span>
              : value === 'lab_cancelled'
                ? <span>Canceled</span>
                : <span>{value}</span>
        )
      },
      {
        Header: 'Type',
        accessor: 'testType',
        Cell: ({ value }) => (
          value === 'labVisit'
            ? <span>Lab Visit</span>
            : value === 'homeSample'
              ? <span>Home Sample</span>
              : <span>{value}</span>
        )
      },
      {
        Header: 'Action',
        accessor: 'View File',
        Cell: ({ row }) => (
          <Link to={`/lab/profile/${row.original.appointmentId}/overview`}
            style={{ borderBottom: '1px solid #5C5C5C' }}>
            View File
          </Link>
        ),
      },
    ],
    []
  )
  const is_SessionExpire = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  // rendering API
  useEffect(() => {
    userList()
  }, [
    filter_status.appoint_type,
    filter_status.test_type,
    filter_status.appointment_status,
    filter_status.appoint_date,
  ])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 404'
            ? 'This record does not exist'
            : error.message === 'Request failed with status code 401'
              ? is_SessionExpire()
              : error.message
        }
      />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center m-4'>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <>
        <div className='row rounded bg-white m-4'>
          <div className='d-flex justify-content-between'>
            <div>
              <h3
                style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}
              >
                All Tests
              </h3>
            </div>
            <div>
              <AddNewUserButton link={`/patients/profile/${params.id}/lab/booklab/new`} buttonText={'Book Lab Test'} />
            </div>
          </div>
          <Table
            pageNumber={page}
            columns={columns}
            data={patientList}
            routingLink={''}
            pageCount={pageCount}
            fetchData={fetchData}
            loadMoreList={handleLoadMoreData}
            lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            setLoading={setLoading}
            FilterForm={<FilterForm />}
          />
        </div>
      </>
    )
  }
}
