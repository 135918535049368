import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import {
  faList,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import InputFieldError from '../../../Components/errors/InputFieldError'
import { Link } from 'react-router-dom'
import { API_CONSTANTS } from '../../../API_CONSTANTS'

import Toast from '../../../Components/Toast/Toast'
import ButtonLoader from '../../../Components/loaders/ButtonLoader'
import { Container, Row, Col } from 'react-bootstrap'

export default function AddPatientForm() {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [errorMessage, seterrorMessage] = useState('')
  const [createProfileError, setcreateProfileError] = useState('')
  const [maxNumLength, setmaxNumLength] = useState(parseInt(10))
  const [disableButton, setdisableButton] = useState(true)
  const [otp, setOtp] = useState('')
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [btnSpin, setbtnSpin] = useState(false)
  const [createBtnSpin, setcreateBtnSpin] = useState(false)
  const handlePhoneNumber = (e) => {
    seterrorMessage('')
    let element = e.target
    element.value = element.value.replace(/[^0-9]$/gm, '')

    if (element.value.charAt(0) === '0') {
      setmaxNumLength(11)
    } else {
      setmaxNumLength(10)
    }
    setPhoneNumber(element.value)
  }
  const handleSendOtp = () => {
    if (phoneNumber === '') {
      seterrorMessage('Please enter a number')
      return ''
    } else if (phoneNumber.length !== maxNumLength) {
      seterrorMessage('Please enter a valid number')
    } else if (phoneNumber.charAt(0) !== '0' && maxNumLength === 11) {
      seterrorMessage('Please enter a valid number ')
    } else if (phoneNumber.charAt(1) !== '3' && maxNumLength === 11) {
      seterrorMessage('Please enter a valid number ')
    } else if (
      phoneNumber.charAt(2) === '6' ||
      phoneNumber.charAt(2) === '7' ||
      phoneNumber.charAt(2) === '8' ||
      (phoneNumber.charAt(2) === '9' && maxNumLength === 11)
    ) {
      seterrorMessage('Please enter a valid number ')
    } else if (phoneNumber.charAt(0) !== '3' && maxNumLength === 10) {
      seterrorMessage('Please enter a valid number')
    } else if (
      (phoneNumber.charAt(1) === '6' && maxNumLength === 10) ||
      (phoneNumber.charAt(1) === '7' && maxNumLength === 10) ||
      (phoneNumber.charAt(1) === '8' && maxNumLength === 10) ||
      (phoneNumber.charAt(1) === '9' && maxNumLength === 10)
    ) {
      seterrorMessage('Please enter a valid number')
    } else if (errorMessage === '') {
      setbtnSpin(true)
      const data = {
        phone_no:
          '+92' +
          (phoneNumber.charAt(0) === '0' ? phoneNumber.slice(1) : phoneNumber),
        type: 1,
      }
      axios
        .post(`${API_CONSTANTS.SENDOTPBASEURLPATIENT}auth/sendOtp`, data, {
          headers: API_CONSTANTS.headers(),
        })
        .then((response) => {
          if (response.data.responseCode === 200) {
            handleToast(
              'OTP sent successfully',
              faCheckCircle,
              '#009b94',
              'show-success'
            )
            setdisableButton(false)
            setbtnSpin(false)
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            window.location.reload()
            window.localStorage.clear()
          }
          setbtnSpin(false)
        })
    }
  }

  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }

  const handleOtpChange = (e) => {
    let element = e.target
    element.value = element.value.replace(/[^0-9]/gi, '')
    setOtp(element.value)
    setcreateProfileError('')
  }

  const handleCreateAccount = () => {
    if (otp === '') {
      setcreateProfileError('Please enter a valid Otp')
    } else if (otp.length !== 6) {
      setcreateProfileError('Please enter a valid Otp')
    } else if (!createProfileError) {
      setcreateBtnSpin(true)
      const data = {
        phone_no:
          '+92' +
          (phoneNumber.charAt(0) === '0' ? phoneNumber.slice(1) : phoneNumber),
        type: 1,
        code: otp,
      }
      axios
        .post(`${API_CONSTANTS.SENDOTPBASEURLPATIENT}auth/verifyOtp`, data, {
          headers: API_CONSTANTS.headers(),
        })
        .then((r) => {
          if (r.data.message === 'Success') {
            handleToast(
              'Patient add successfully',
              faCheckCircle,
              '#009b94',
              'show-success'
            )
            setcreateBtnSpin(false)
            setPhoneNumber('')
            setOtp('')
          }
        })
        .catch((e) => {
          console.log(e)
          setcreateBtnSpin(false)
          setPhoneNumber('')
          setOtp('')
        })
    }
  }

  return (
    <>
      <Container fluid style={{ padding: '32px' }}>
        <div className='patient-add-form-wrapper'>
          <Row>
            <Col>
              <div className='d-flex justify-content-between patient-add-form-link'>
                <div>
                  <h5 className={'form-heading m-0'}>Add New Patient</h5>
                </div>
                <div>
                  <Link to={'/patients'}>
                    <span className={'form-backLink'}>
                      <FontAwesomeIcon
                        icon={faList}
                        className={'mr-2 fa-icon color-99D7D4'}
                        style={{ color: '#009B94' }}
                      />
                      <span
                        className={
                          'patient-list-route poppins normal weight-400 size-14 line-20 space-0-5 color-1e1e1e'
                        }
                        style={{ paddingLeft: '8px' }}
                      >
                        Patient List
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='d-flex'>
                <span
                  className={
                    errorMessage
                      ? 'd-flex form-select-error form-phoneNumber-Wrapper'
                      : 'd-flex form-phoneNumber-Wrapper'
                  }
                  style={{ width: '320px' }}
                >
                  <select className='form-select' style={{ width: '50px' }}>
                    <option className='select-add'> +92</option>
                  </select>
                  <input
                    value={phoneNumber}
                    type='text'
                    className={'form-select pb '}
                    placeholder='Phone Number'
                    style={{ width: '270px' }}
                    onChange={handlePhoneNumber}
                    min='10'
                    maxLength={maxNumLength}
                  />
                </span>

                <span style={{ paddingLeft: '16px' }}>
                  <button
                    type='button'
                    className={`btn btn-otp ml-4`}
                    onClick={handleSendOtp}
                  >
                    <span style={{ display: btnSpin ? btnSpin : 'none' }}>
                      <ButtonLoader />
                    </span>
                    Send OTP
                  </button>
                </span>
              </div>
              <div style={{ marginLeft: '24px', height: '24px' }}>
                {errorMessage ? (
                  <InputFieldError errorMessage={errorMessage} />
                ) : (
                  ''
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <input
                value={otp}
                type='text'
                className={
                  createProfileError
                    ? 'enter-otp-field-error'
                    : 'enter-otp-field'
                }
                placeholder='Enter OTP'
                style={{ width: '320px' }}
                onChange={handleOtpChange}
                min='6'
                maxLength='6'
              />
              <div style={{ paddingLeft: '22px', height: '20px' }}>
                {createProfileError ? (
                  <InputFieldError errorMessage={createProfileError} />
                ) : (
                  ''
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className='btn btn-create create-button'
                type='button'
                disabled={disableButton}
                onClick={handleCreateAccount}
              >
                <span
                  style={{ display: createBtnSpin ? createBtnSpin : 'none' }}
                >
                  <ButtonLoader />
                </span>
                Create Account
              </button>
            </Col>
          </Row>
        </div>
      </Container>

      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />

      <style jsx='true'>{`
        .patient-add-form-wrapper {
          height: 85vh;
          width: 100%;
          background: #ffffff;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 6px;
        }
        .container-wrapper {
          height: 90vh;
        }

        .form-select {
          padding: 0;
        }
        .patient-add-form-link {
          padding: 24px 24px;
        }
        .form-heading {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #1e1e1e;
        }
        .form-select {
          border: none;
          background: none;
        }

        .enter-otp-field {
          width: 320px;
          margin-left: 26px;
          border: none;
          // margin-top: 36px;
          border-bottom: 1px solid #cccccc;
          padding-bottom: 6px;
        }

        .enter-otp-field-error {
          width: 320px;
          margin-left: 26px;
          border: none;
          // margin-top: 36px;
          border-bottom: 1px solid #b00020;
          padding-bottom: 6px;
        }
        .enter-otp-field-error:focus {
          outline: none;
        }
        .enter-otp-field:focus {
          outline: none;
        }
        p {
          margin-bottom: 0px;
        }
        .form-select:focus {
          outline: none;
          box-shadow: none;
        }
        .form-phoneNumber-Wrapper {
          margin-left: 24px;
          border-bottom: 1px solid #cccccc;
          padding-bottom: 6px;
        }
        .btn-otp {
          color: #009b94;
          border: 1px solid #009b94 !important;
          border-radius: 4px;
          padding: 8px 16px;
        }
        .btn-otp:hover {
          color: #009b94;
          border: 1px solid #009b94 !important;
          border-radius: 4px;
          background: rgba(0, 155, 148, 0.07);
        }
        .btn-otp: focus {
          box-shadow: none;
        }

        .btn-create {
          background: #009b94;
          border-radius: 4px;
          color: #ffffff;
        }
        .btn-create:hover {
          color: #ffffff;
        }

        .btn-proceed {
          color: #009b94;
        }
        .btn-proceed:hover {
          color: #009b94;
        }
        .input-error-border {
          border-bottom: 1px solid #b00020;
        }
        .form-select-error {
          border-bottom: 1px solid #b00020;
          border-top: 0px;
          border-right: 0px;
          border-left: 0px;
          background: none;
        }
        .create-button {
          margin-left: 24px;
          margin-top: 4px;
        }
      `}</style>
    </>
  )
}
