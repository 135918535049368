import { useState } from "react";
import styles from "../../../Style/corporate.module.css";
import { useSelector } from "react-redux";
export default function CorporateOverView() {
  const { value, loading } = useSelector(
    (state) => state.getCorporateProfileReducer
  );
  return (
    <section>
      <div className={styles.corporateOverViewContainer}>
        <div className={styles.corporateDataContainer}>
          <div className={styles.corporateInfo}>
            <table>
              <tr>
                <th>Company Name</th>
                <td>
                  {loading ? (
                    <p class="card-text placeholder-glow">
                      <span class="placeholder col-7"></span>
                    </p>
                  ) : (
                    value.companyName
                  )}
                </td>
              </tr>
              <tr>
                <th>Sector/ Industry</th>
                <td>{value.corporateType}</td>
              </tr>
              <tr>
                <th>Contact Number</th>
                <td>{value.phoneNo}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{value.email}</td>
              </tr>
            </table>
          </div>
          <div className={styles.corporateStats}>
            <div className={styles.statsCard}>
              <h6>Total Employees</h6>
              <p>
                {`${value.addedEmployeeCount} / ${value.employeeCount}` ||
                  "N/A"}
              </p>
            </div>
            <div className={styles.statsCard}>
              <h6>Calls Availed</h6>
              <p>{value.callAvailed}</p>
            </div>
            <div className={styles.statsCard}>
              <h6>Calls Available </h6>
              <p>{value.callAvailable}</p>
            </div>
            <div className={styles.statsCard}>
              <h6>Plan Active</h6>
              <p>{value?.currentPlan || "N/A"}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
