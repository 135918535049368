import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case 'LOADING_TRUE': {
      return {
        ...state,
        loading: true,
      }
    }
    case 'LOADING_FALSE': {
      return {
        ...state,
        loading: false,
      }
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      }
    }
  }
}

//Doctor related filter search form
export default function FilterForm() {
  const filter_status = {
    appointment_status: useSelector(
      (state) => state.patientServicesFilterReducer.value
    ),
    appoint_type: useSelector(
      (state) => state.patientServicesFilterReducer.value2
    ),

    created_at: useSelector(
      (state) => state.patientServicesFilterReducer.value1
    ),
  }
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    appoint_type: filter_status.appoint_type,
    created_at: filter_status.created_at,
    appointment_status: filter_status.appointment_status,
    loading: false,
  })
  //

  const dispatch = useDispatch()
  //
  const { appoint_type, created_at, appointment_status, loading } = formData
  //
  const handleSubmit = (event) => {
    event.preventDefault()
    setFormDataDispatch({ type: 'LOADING_TRUE' })
    if (created_at !== undefined) {
      dispatch({
        type: 'ServicesFilter/created_at',
        payload: created_at,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
    if (appointment_status !== undefined) {
      dispatch({
        type: 'ServicesFilter/' + appointment_status,
      })
    }
    if (appoint_type !== undefined) {
      dispatch({
        type: 'ServicesFilter/' + appoint_type,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className='mt-4 d-flex flex-column justify-content-center align-items-center'
      >
        <div
          class='form-group mt-3 w-100
        px-2'
        >
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, color: '#000', fontSize: 16 }}
          >
            Service Type
          </label>
          <select
            className='form-control'
            id='exampleFormControlSelect1'
            onChange={setFormDataDispatch}
            name='appoint_type'
            value={appoint_type}
          >
            <option name='all' value='NurseBooked'>
              All
            </option>
            <option value='Booked'>Book Appointment video</option>
            <option value='Instant'>Instant Consultation Video</option>
            <option value='FollowUp'>Follow Up's Consultations</option>
          </select>
        </div>
        <div
          class='form-group mt-3 w-100
        px-2'
        >
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, color: '#000', fontSize: 16 }}
          >
            Service Status
          </label>
          <select
            className='form-control'
            id='exampleFormControlSelect1'
            onChange={setFormDataDispatch}
            name='appointment_status'
            value={appointment_status}
          >
            <option name='all' value='all'>
              All
            </option>
            <option value='reserved'>Reserved</option>
            <option value='booked'>Booked</option>
            <option value='completed'>Completed</option>
            <option value='pending'>Pending</option>
            <option value='cancelled'>Cancelled</option>
            <option value='inProgress'>In Progress</option>
            <option value='missed'>Missed</option>
          </select>
        </div>
        <div class='form-group mt-3  w-100 px-2'>
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, color: '#000', fontSize: 16 }}
          >
            Booking Date
          </label>
          <input
            onChange={setFormDataDispatch}
            name='created_at'
            type='date'
            class='form-control filter-input'
            id='appointmentDate'
            value={created_at}
          />
        </div>
        <button className='btn mt-3 filter-form-button'>
          {loading || 'Apply'}
          {loading && (
            <div
              className='spinner-border spinner-border-sm text-white mx-1'
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </div>
          )}
        </button>
      </form>
      <style jsx>{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 95%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
      `}</style>
    </>
  )
}
