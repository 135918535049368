/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from "react";
import Table from "../../../../../Components/Table";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { API_CONSTANTS } from "../../../../../API_CONSTANTS";

export default function CallRecordTable() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [recording, setRecording] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  //
  const params = useParams();
  const filter_status = {
    dateTime: useSelector(
      (state) => state.patientTransactionFilterReducer.value
    ),
    amount: useSelector(
      (state) => state.patientTransactionFilterReducer.value1
    ),
    transaction_status: useSelector(
      (state) => state.patientTransactionFilterReducer.value2
    ),
  };
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = lastEvaluatedKey;

      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        const slicedRecording = recording.slice(startRow, endRow);

        setRecording(slicedRecording);
        setPageCount(Math.ceil(slicedRecording.length / pageSize));
      }
    },
    [recording, lastEvaluatedKey]
  );
  const recordingList_API = () => {
    axios
      .get(`${API_CONSTANTS.BASEURL_1}admin/recordings/${params.id}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
        setRecording(res.data.response.Items); // Set the recording state to an empty array if it's undefined
      })
      .catch((error) => {
      });
  };
  useEffect(() => {
    recordingList_API();
  }, []);
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/recordings/${params.id}?last=${lastEvaluatedKey}`,
        {
          dateTime: `${filter_status.dateTime}`,
          amount: `${filter_status.amount}`,
          transaction_status: `${filter_status.transaction_status}`,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var patientListNextPage = response.data.response.Items;
        setRecording([...recording, ...patientListNextPage]);
      })
  };
  const columns = useMemo(
    () => [
      {
        Header: "Recording List",
        accessor: "sid",
      },
      {
        Header: "Action",
        accessor: "recordingLink",
        Cell: ({ value }) => (
          <audio
            controls
            style={{ height: "35px" }}
          >
            <source src={value} type="audio/mp3" />
          </audio>
        )
      },
    ],
    []
  );
  // Table rendering

  return (
    <>
      <div className="bg-white d-flex flex-column rounded">
        <div>
          <h3 style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}>
            Call Recording Logs
          </h3>
        </div>
        <Table
          columns={columns}
          data={recording}
          routingLink={""}
          pageCount={pageCount}
          fetchData={fetchData}
          loadMoreList={handleLoadMoreData}
          lastEvaluatedKey={lastEvaluatedKey}
          loading={loading}
          setLoading={setLoading}
          FilterForm={""}
        />
      </div>
      <style jsx="true">
        {`
          .table-row:hover {
            box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
              0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%) !important;
            cursor: auto !important;
          }
          td {
            text-align: left !important;
          }
        `}
      </style>
    </>
  );
}
