import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
import Switch from 'react-switch'
import { Button, Modal } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import Toast from '../../../../../Components/Toast/Toast'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
// Component Of Status Change Program
export default function Status({ responseState }) {
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [planStatus, setPlanStatus] = useState(responseState.planStatus === 'active')
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }

  useEffect(() => {
    setPlanStatus(responseState.planStatus === 'active')
  }, [responseState.planStatus])

  function handleToggleChange() {
      setShow(true)
  }
  const updatePlanStatus = () => {
    const updatedPlanStatus = planStatus ? 'inactive' : 'active'
    const updatedValues = {
      ...responseState,
      planStatus: updatedPlanStatus,
      validDuration: String(responseState.validDuration),
      noOfCalls: String(responseState.noOfCalls),
    };
    delete updatedValues.pId
    delete updatedValues.created_at
    delete updatedValues.updated_at
    delete updatedValues.date
    setIsLoading(true)
    axios
      .patch(`${API_CONSTANTS.BASEURL_1}admin/subscriptionplan/${responseState.pId}`, updatedValues, {
        headers: API_CONSTANTS.headers(),
      })
      .then((response) => {
        if (response.data.responseCode === 200) {
          setPlanStatus(updatedPlanStatus === 'active')
        }
        if (response.data.message === 'Success') {
          handleToast(
            'Plan Status Change Successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
        }
        setShow(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const handleCloseModal = () => {
    setShow(false)
  }
  return (
    <>
      <div className="form-check form-switch p-0">
        <Switch
          id="planStatus"
          name="isPlanActive"
          checked={planStatus}
          onChange={handleToggleChange}
          onColor="#009B94"
          offColor="#8E8E8E"
          uncheckedIcon={false}
          checkedIcon={false}
          height={16}
          width={32}
        />
        <label className="form-check-label" htmlFor="planStatus">
          {planStatus}
        </label>
      </div>
      <Modal show={show} onHide={handleCloseModal} className='d-flex justify-content-center align-items-center'>
      <Modal.Body className='d-flex justify-content-around align-items-center flex-column' style={{maxHeight: '255px'}}>
          <h4 style={{ color: '#AF1300' }}>Warning !</h4>
          <p style={{ textAlign: 'center'}}>Are you sure you want to change the plan status?</p>
          <div className='d-flex' style={{ gap: '20px'}}>
          <Button style={{ color: '#FFFFFF', backgroundColor: '#B00020', border: 'none' }} onClick={updatePlanStatus}>
          Confirm {isLoading && <Spinner animation='border' size='sm' />}
            </Button>
            <Button
              style={{ color: '#4A4A4A', backgroundColor: '#FFFFFF', border: '1px solid #4A4A4A' }}
              onClick={() => handleCloseModal()}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <style jsx='true'>
        {`
          .react-switch .react-switch-handle {
            width: 12px;
            height: 12px;
            background: ;
          }
          .react-switch .react-switch-handle:before {
            content: '';
          }
          .react-switch .react-switch-handle:after {
            content: '';
          }
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
            padding: 10px 10px !important;
          }
          .error-message {
            color: red;
          }
          .add-record {
            background: #009b94 !important;
            color: #fff;
          }
        `}
      </style>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  );
}
