import DiseasePresetTable from './Table'
import AddNewUserButton from '../../../../Components/AddNewUserButton'
export default function DiseasePreset() {
  return (
    <>
      <AddNewUserButton
        link={'/disease-preset/new'}
        buttonText={'Add Disease Preset'}
      />
      <DiseasePresetTable />
    </>
  )
}
