import React, { useState, useReducer } from 'react'
import Modal from 'react-bootstrap/Modal'
import EditDiseaseButton from './EditDiseaseButton'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import { faCheckCircle } from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../../Components/Toast/Toast'
import modalCrossIcon from '../../../../assets/icons/modal-cross-icon.png'
import moment from 'moment'
// reducer function
const stateReducer = (state, action) => {
  if (action.type === 'REQUEST_INIT') {
    return {
      ...state,
      loading: true,
    }
  }

  if (action.type === 'TOAST_TRUE') {
    return {
      ...state,
      loading: false,
      ToastMessage: action.payload.message,
      showToast: 'show show-success',
      ToastIcon: faCheckCircle,
      iconColor: '#009b94',
    }
  }
  if (action.type === 'TOAST_FALSE') {
    return {
      ...state,
      showToast: ' ',
    }
  }
}

// View Disease
export default function ViewDisease({ disease, diseaseList }) {
  const [states, setStates] = useReducer(stateReducer, {
    loading: false,
    showToast: '',
    ToastIcon: '',
    iconColor: '',
    ToastMessage: '',
  })
  const { showToast, ToastIcon, iconColor, ToastMessage, loading } = states
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  // handle delete function
  const handleDelete = () => {
    setStates({ type: 'REQUEST_INIT' })
    axios
      .delete(`${API_CONSTANTS.BASEURL_1}admin/diseaseSet/${disease.dId}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setStates({
          type: 'TOAST_TRUE',
          payload: { message: res.data.response.message },
        })
      })
      .then(() => {
        setTimeout(() => setStates({ type: 'TOAST_FALSE' }), 2000)
      })
      .then(() => handleClose())
      .then(() => diseaseList())
      .catch((err) => {
        alert(err)
        setStates({ type: 'TOAST_FALSE' })
      })
  }

  return (
    <>
      <button
        className='btn on-hover p-0'
        onClick={handleShow}
        style={{
          background: 'none',
          border: 'none',
          outline: 'none',
          fontSize: 14,
          color: '#00568A',
        }}
      >
        View detail
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header className='d-flex align-items-start flex-column'>
          <div className='w-100 d-flex justify-content-end'>
            <button
              style={{ background: 'none', border: 'none' }}
              onClick={handleClose}
            >
              <img src={modalCrossIcon} alt='' />
            </button>
          </div>
          <Modal.Title className='w-100'>
            <div className='d-flex flex-column'>
              <span>
                <p
                  style={{
                    color: '#747A7A',
                    fontSize: 12,
                    fontWeight: 500,
                    marginBottom: 0,
                  }}
                >
                  Disease Name
                </p>
                <p
                  style={{
                    color: '#1E1E1E',
                    fontSize: 14,
                    textTransform: 'capitalize',
                  }}
                >
                  {disease.title}
                </p>
              </span>
              <span>
                <p
                  style={{
                    color: '#747A7A',
                    fontSize: 12,
                    fontWeight: 500,
                    marginBottom: 0,
                  }}
                >
                  Date/Time
                </p>
                <p
                  style={{
                    color: '#1E1E1E',
                    fontSize: 14,
                    textTransform: 'capitalize',
                  }}
                >
                  {moment(disease.created_at).format('LLLL').slice(0, -8) +
                    ' / ' +
                    moment(disease.created_at).format('LLLL').slice(-8)}
                </p>
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-column '>
              <p
                className='mx-1'
                style={{
                  color: '#1E1E1E',
                  fontSize: 16,
                  textTransform: 'capitalize',
                  fontWeight: 500,
                  marginBottom: 0,
                }}
              >
                Do's
              </p>
              <div className='d-flex flex-row'>
                {disease.dos.map((item) => (
                  <p
                    className='mx-1'
                    style={{
                      color: '#5C5C5C',
                      fontSize: 14,
                      background: '#ddd',
                      borderRadius: 5,
                      padding: '2px 4px ',
                    }}
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div>
            <div className='d-flex flex-column'>
              <p
                className='mx-1'
                style={{
                  color: '#1E1E1E',
                  fontSize: 16,
                  textTransform: 'capitalize',
                  fontWeight: 500,
                  marginBottom: 0,
                }}
              >
                Dont's
              </p>
              <div className='d-flex flex-row flex-wrap'>
                {disease.donts.map((item) => (
                  <p
                    className='mx-1'
                    style={{
                      color: '#5C5C5C',
                      fontSize: 14,
                      background: '#ddd',
                      borderRadius: 5,
                      padding: '2px 4px ',
                    }}
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='delete-button '
            onClick={handleDelete}
            style={{
              background: 'none',
              color: '#B00020',
              border: '1px solid #B00020',
              padding: '5px 20px',
              fontWeight: 500,
              borderRadius: 5,
            }}
          >
            Delete{' '}
            {loading && (
              <div class='spinner-border spinner-border-sm' role='status'>
                <span class='visually-hidden'>Loading...</span>
              </div>
            )}
          </button>
          <EditDiseaseButton data={disease} />
        </Modal.Footer>
      </Modal>
      <style jsx='true'>{`
        .on-hover:hover {
          text-decoration: underline;
        }

        .delete-button:hover {
          background: #b00020 !important;
          color: #fff !important;
        }
        .delete-button:focus {
          box-shadow: 0 0 0 0.25rem rgb(176 0 32 /25%) !important ;
        }
      `}</style>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}
