import React from 'react'
import { useSelector } from 'react-redux'
//
import TransactionsList from './TransactionsList'
import BalanceAndPayments from './BalanceAndPayments'
//
// this is wallet component of patient
export default function Wallet() {
  const { loading } = useSelector((state) => state.getPatientProfileReducer)
  return (
    <>
      {loading && <div className='loading'></div>}
      <div className='container-fluid my-3 '>
        <div className='row '>
          <div className='col-3 '>
            <BalanceAndPayments />
          </div>
          <div className='col-9'>
            <TransactionsList />
          </div>
        </div>
      </div>
      <style jsx='true'>{`
        .profile-card-width th {
          font-weight: 500;
          padding: 18px;
        }
        .profile-card-width td {
          text-align: right;
          padding: 18px;
        }
        .profile-card-width tr {
          border-bottom: 1px solid #dadada;
        }
        .cancelled {
          background: #ffe0e0;
          color: Red;
        }
        .booked {
          background: #d9f7e7a6;
          color: Green;
        }
        .missed {
          background: #ffe0e0;
          color: Red;
        }
        .reserved {
          background: #fff6e9;
          color: Orange;
        }
        .reschedule {
          background: #00b8af1a;
          color: #009b94;
        }
        .InProgress {
          background: #e0f3fb;
          color: #00568a;
        }
        .completed {
          background: #d9f7e7a6;
          color: #1a9452;
        }
        .loading {
          position: fixed;
          z-index: 999;
          height: 2em;
          width: 2em;
          overflow: show;
          margin: auto;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }

        /* Transparent Overlay */
        .loading:before {
          content: '';
          display: block;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: radial-gradient(
            rgba(20, 20, 20, 0.8),
            rgba(0, 0, 0, 0.8)
          );

          background: -webkit-radial-gradient(
            rgba(20, 20, 20, 0.8),
            rgba(0, 0, 0, 0.8)
          );
        }

        /* :not(:required) hides these rules from IE9 and below */
        .loading:not(:required) {
          /* hide "loading..." text */
          font: 0/0 a;
          color: transparent;
          text-shadow: none;
          background-color: transparent;
          border: 0;
        }

        .loading:not(:required):after {
          content: '';
          display: block;
          font-size: 10px;
          width: 1em;
          height: 1em;
          margin-top: -0.5em;
          -webkit-animation: spinner 150ms infinite linear;
          -moz-animation: spinner 150ms infinite linear;
          -ms-animation: spinner 150ms infinite linear;
          -o-animation: spinner 150ms infinite linear;
          animation: spinner 150ms infinite linear;
          border-radius: 0.5em;
          -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
            rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
            rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
            rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
            rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
            rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
            rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
            rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
          box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
            rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
            rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
            rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
            rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
            rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
            rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
            rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
        }

        /* Animation */

        @-webkit-keyframes spinner {
          0% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @-moz-keyframes spinner {
          0% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @-o-keyframes spinner {
          0% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes spinner {
          0% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      `}</style>
    </>
  )
}
