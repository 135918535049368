/*
author: Umer Shabir 
date: 05-june-2022
title: this file is about all the dashboard infromation, chart, total user etc.
*/
// dependencies
import React, { useEffect, useReducer } from "react";
import totalAdmin from "../../resources/images/totalAdmin.png";
import totalDoctor from "../../resources/images/totalDoctor.png";
import totalPatient from "../../resources/images/patients.png";
import pendingDoctor from "../../resources/images/pendingDoctor.png";
import totalCashBackground from "../../resources/images/totalCashBackground.png";
import DonutChart from "react-donut-chart";
import moment from "moment";
import { Link } from "react-router-dom";
import { getDashboardData } from "./dashboardSlice";
import { getDashboardNumbers } from "./dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
// declaring variables for date/week/month filteration.
let currentDate = moment(new Date());
let weekOfTheMonth = Math.ceil(currentDate.date() / 7);
const d = new Date();
let currentMonth = d.getMonth() + 1;
// Main dashboard page component
export default function DashboardPage() {
  const valueReducer = (state, event) => {
    return {
      ...state,
      [event.target.name]: parseInt(event.target.value),
    };
  };
  const [selected, setSelected] = useReducer(valueReducer, {
    week: weekOfTheMonth,
    month: currentMonth,
  });
  const { week, month } = selected;
  const dispatch = useDispatch();
  const { value } = useSelector((state) => state.getDashboardDataReducer);
  const { valueState } = useSelector(
    (state) => state.getDashboardNumbersReducer
  );
  const handleReload = () => {
    dispatch(getDashboardData());
  };

  const oneTimeLoadChapi = value.admins;
  useEffect(() => {
    oneTimeLoadChapi === "" && dispatch(getDashboardData());
    dispatch(getDashboardNumbers(selected));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  if (localStorage.getItem("adminRole") === "CustomerSupport") {
    return <div></div>;
  } else if (localStorage.getItem("adminRole") === "HealthCare") {
    return <div></div>;
  } else if (localStorage.getItem("adminRole") === "AccountsDept") {
    return <div></div>;
  } else if (localStorage.getItem("adminRole") === "DevTeam") {
    return <div></div>;
  }
  return (
    <>
      <div className="container-fluid p-4">
        {/*this is top stats about doctor patient and admins */}
        <div className="row">
          <div className="col">
            <div className="d-flex justify-content-between gap-2">
              <div className="d-flex">
                <div className="d-flex bg-white rounded align-items-center px-3 py-2">
                  <span
                    style={{
                      background: "#00CFDF24",
                      borderRadius: 50,
                      padding: "5px 8px",
                    }}
                  >
                    <img
                      src={totalAdmin}
                      alt=""
                      style={{
                        width: 18,
                        height: 15,
                      }}
                    />
                  </span>
                  <span className="d-flex flex-column ms-3">
                    <h6
                      className="m-0"
                      style={{ fontSize: 16, color: "#222222" }}
                    >
                      {value.admins}
                    </h6>
                    <p
                      className="m-0"
                      style={{ fontSize: 12, color: "#888888" }}
                    >
                      Total Admins
                    </p>
                  </span>
                </div>
                <div className="d-flex bg-white rounded align-items-center px-3 py-2 ms-3">
                  <span
                    style={{
                      background: "#00568A24",
                      borderRadius: 50,
                      padding: "5px 9px",
                    }}
                  >
                    <img
                      src={totalDoctor}
                      alt=""
                      style={{
                        width: 16,
                        height: 20,
                      }}
                    />
                  </span>
                  <span className="d-flex flex-column ms-3">
                    <h6
                      className="m-0"
                      style={{ fontSize: 16, color: "#222222" }}
                    >
                      {value.doctors}
                    </h6>
                    <p
                      className="m-0"
                      style={{ fontSize: 12, color: "#888888" }}
                    >
                      Total Doctors
                    </p>
                  </span>
                </div>
                <div className="d-flex bg-white rounded align-items-center px-3 py-2 ms-3">
                  <span
                    style={{
                      background: "#E2450E24",
                      borderRadius: 50,
                      padding: "5px 7px",
                    }}
                  >
                    <img
                      src={totalPatient}
                      alt=""
                      style={{
                        width: 24,
                        height: 24,
                      }}
                    />
                  </span>
                  <span className="d-flex flex-column ms-3">
                    <h6
                      className="m-0"
                      style={{ fontSize: 16, color: "#222222" }}
                    >
                      {value.patients}
                    </h6>
                    <p
                      className="m-0"
                      style={{ fontSize: 12, color: "#888888" }}
                    >
                      Total Patients
                    </p>
                  </span>
                </div>
              </div>
              <span>
                <button
                  type="button"
                  className="btn"
                  style={{
                    background: "#009B9414",
                    border: "1px solid #5C5C5C",
                  }}
                  onClick={handleReload}
                >
                  <i className="fas fa-sync"></i>
                </button>
              </span>
            </div>
          </div>
        </div>

        <div className="centerer d-flex gap-3">
          {/*this is cash stats module */}
          <div className="left">
            <div className="d-flex flex-column bg-white rounded my-3 p-4 h-100">
              <div className="d-flex cashflow">
                <span
                  className="p-3 d-flex align-items-center justify-content-start"
                  style={{ flexGrow: 2, fontWeight: 500 }}
                >
                  <p className="m-0 me-2"> Cashflow</p>
                </span>
                <div
                  className="d-flex flex-column text-white p-3 totalCashBackground"
                  style={{
                    flexGrow: 3,
                    background: "#009B94",
                    borderRadius: 12,
                    backgroundImage: `url(${totalCashBackground})`,
                    backgroundRepeat: "repeat-y",
                    backgroundPosition: "right top",
                  }}
                >
                  <span style={{ fontSize: 14, fontWeight: 300 }}>Total</span>
                  <span
                    className="span2"
                    style={{ fontSize: 26, fontWeight: 500 }}
                  >
                    {valueState.total_cash}.00
                  </span>
                </div>
              </div>
              <div
                className="d-flex justify-content-evenly align-items-center mt-4 daytomonth"
                style={{ borderBottom: "3px solid #F5F7F9" }}
              >
                <div className="d-flex flex-column mt-2">
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#888888",
                    }}
                  >
                    Daily
                  </span>
                  <span className="d-flex align-items-end mt-2">
                    <p
                      style={{ fontSize: 20, fontWeight: 600 }}
                      className="mt-1"
                    >
                      {valueState.daily}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: 17,
                        fontWeight: 400,
                        color: "#888888",
                        marginLeft: 10,
                      }}
                      className="mt-1"
                    >
                      Rs
                    </p>
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <span
                    className="d-flex align-items-center"
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#888888",
                    }}
                  >
                    <p className="m-0 me-2">Weekly</p>
                    <form>
                      <select
                        value={week}
                        onChange={setSelected}
                        className=""
                        name="week"
                        aria-label="Default select example"
                        style={{
                          background: "#009B9414",
                          border: "1px solid #E7E4E4",
                        }}
                      >
                        <option value="">--select--</option>
                        <option value="1">1</option>
                        <option
                          value="2"
                          disabled={
                            weekOfTheMonth < 2 && month !== currentMonth
                          }
                        >
                          2
                        </option>
                        <option
                          value="3"
                          disabled={
                            weekOfTheMonth < 3 && month === currentMonth
                          }
                        >
                          3
                        </option>
                        <option
                          value="4"
                          disabled={
                            weekOfTheMonth < 4 && month === currentMonth
                          }
                        >
                          4
                        </option>
                        <option
                          value="5"
                          disabled={
                            weekOfTheMonth !== 5 && month === currentMonth
                          }
                        >
                          5
                        </option>
                      </select>
                    </form>
                  </span>
                  <span className="d-flex align-items-end">
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                      {valueState.weekly}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: 17,
                        fontWeight: 400,
                        color: "#888888",
                        marginLeft: 10,
                      }}
                    >
                      Rs
                    </p>
                  </span>
                </div>
                <div className="d-flex flex-column">
                  <span
                    className="d-flex align-items-center"
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#888888",
                    }}
                  >
                    <p className="m-0 me-2">Monthly</p>
                    <form>
                      <select
                        value={month}
                        onChange={setSelected}
                        name="month"
                        className=""
                        aria-label="Default select example"
                        style={{
                          background: "#009B9414",
                          border: "1px solid #E7E4E4",
                        }}
                      >
                        <option value="">--select--</option>
                        <option value="1">January</option>
                        <option value="2" disabled={currentMonth < 2}>
                          February
                        </option>
                        <option value="3" disabled={currentMonth < 3}>
                          March
                        </option>
                        <option value="4" disabled={currentMonth < 4}>
                          April
                        </option>
                        <option value="5" disabled={currentMonth < 5}>
                          May
                        </option>
                        <option value="6" disabled={currentMonth < 6}>
                          June
                        </option>
                        <option value="7" disabled={currentMonth < 7}>
                          July
                        </option>
                        <option value="8" disabled={currentMonth < 8}>
                          August
                        </option>
                        <option value="9" disabled={currentMonth < 9}>
                          September
                        </option>
                        <option value="10" disabled={currentMonth < 10}>
                          October
                        </option>
                        <option value="11" disabled={currentMonth < 11}>
                          November
                        </option>
                        <option value="12" disabled={currentMonth !== 12}>
                          December
                        </option>
                      </select>
                    </form>
                  </span>
                  <span className="d-flex align-items-end">
                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                      {valueState.monthly}
                    </p>{" "}
                    <p
                      style={{
                        fontSize: 17,
                        fontWeight: 400,
                        color: "#888888",
                        marginLeft: 10,
                      }}
                    >
                      Rs
                    </p>
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-evenly gap-1 chart">
                <div className="chart-container position-relative pt-2 p-1">
                  <DonutChart
                    data={[
                      {
                        label: "Expense",
                        value: valueState.total_expense,
                      },
                      {
                        label: "Income",
                        value: valueState.total_income,
                      },
                    ]}
                    colors={["#FC7100", "#009B93"]}
                    height={250}
                    outerRadius={0.8}
                    innerRadius={0.7}
                    width={250}
                    clickToggle={false}
                    // interactive={true}
                    legend={false}
                    strokeColor={"#fff"}
                    selectedOffset={0.03}
                    className={"shadow rounded-circle"}
                    onMouseEnter={(item) => (item.className = "hoverClass")}
                  />
                </div>
                <div className="d-flex flex-column">
                  <div
                    className="d-flex align-items-center py-2 px-3 "
                    style={{
                      background: "rgba(0, 155, 147, 0.12)",
                      borderRadius: 12,
                      color: "#009B93",
                      marginBottom: 20,
                    }}
                  >
                    <i
                      className="fas fa-arrow-down"
                      style={{ fontSize: 22 }}
                    ></i>
                    <span style={{ marginLeft: 10 }}>
                      <p style={{ fontWeight: 500, margin: 0 }}>
                        {valueState.total_income} Rs
                      </p>
                      <p style={{ fontWeight: 400, fontSize: 12, margin: 0 }}>
                        Income
                      </p>
                    </span>
                  </div>
                  <div
                    className="d-flex align-items-center py-2 px-3 "
                    style={{
                      background: "rgba(252, 113, 0, 0.15)",
                      borderRadius: 12,
                      color: "#FC7100",
                    }}
                  >
                    <i className="fas fa-arrow-up" style={{ fontSize: 22 }}></i>
                    <span style={{ marginLeft: 10 }}>
                      <p style={{ fontWeight: 500, margin: 0 }}>
                        {valueState.total_expense} Rs
                      </p>
                      <p style={{ fontWeight: 400, fontSize: 12, margin: 0 }}>
                        Expense
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*this is consultations module */}
          <div className="right">
            <div className="d-flex flex-column bg-white rounded my-3 p-4 h-100">
              <span className="d-flex" style={{ fontWeight: 500 }}>
                Consultation
                <p style={{ color: "#888888", fontWeight: 400, marginLeft: 5 }}>
                  - Current Consultation
                </p>
              </span>
              <div className="d-flex w-100 flex-wrap">
                {value.consulations.map((item, index) => (
                  <div
                    className="d-flex w-50 flex-column px-4 pb-3 mt-3 consultation-border"
                    key={index}
                  >
                    <div className="d-flex justify-content-between">
                      <Link
                        to={`patients/profile/${item.userId}/overview`}
                        style={{
                          color: "#121212",
                          fontSize: 14,
                          margin: 0,
                          fontWeight: 500,
                        }}
                      >
                        {item.patientInfo ? item.patientInfo.name : "N/A"}
                      </Link>
                      <Link
                        className={item.appointment_status}
                        to={`consultations/profile/${item.appointmentId}/overview`}
                        style={{
                          fontSize: 14,
                          margin: 0,
                          fontWeight: 400,
                          padding: "3px 12px",
                          borderRadius: "20px",
                        }}
                      >
                        {item.appointment_status.slice(0, 1).toUpperCase() +
                          item.appointment_status.slice(1)}
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between">
                      <Link
                        to={`doctors/profile/${item.doctorId}/overview`}
                        style={{
                          color: "#ADADAD",
                          fontSize: 14,
                          margin: 0,
                          fontWeight: 500,
                        }}
                      >
                        {item.doctorName}
                      </Link>
                      <p
                        style={{
                          color: "#ADADAD",
                          fontSize: 12,
                          margin: 0,
                          fontWeight: 400,
                        }}
                      >
                        {moment(item.appoint_date).calendar()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <Link to="/consultations" className="text-center">
                <u>View all</u>
              </Link>
            </div>
          </div>
        </div>
        <div className="doctordetail d-flex row my-3">
          <div className="approval">
            <div className="d-flex flex-column bg-white rounded mt-3 p-4">
              <span className="d-flex" style={{ fontWeight: 500 }}>
                Doctors
                <p style={{ color: "#888888", fontWeight: 400, marginLeft: 5 }}>
                  - Approval Pending
                </p>
              </span>
              <div className="d-flex pending-doctors-number align-items-center px-3 ">
                <span>
                  <img src={pendingDoctor} alt="" />
                </span>
                <div
                  className="d-flex flex-column p-2 mx-3"
                  style={{ color: "#FF5E00" }}
                >
                  <span style={{ fontWeight: 500 }}>
                    {value.pending_doctors.length}
                  </span>
                  <p style={{ fontSize: 12, margin: 0 }}>Pending</p>
                </div>
              </div>
              {value.pending_doctors.map((member, index) => (
                <div
                  className="d-flex align-items-center px-1 my-2 pb-2"
                  key={index}
                  style={{ borderBottom: "2px solid #F1F5F8" }}
                >
                  <span>
                    <img
                      src={
                        member.url
                          ? member.url
                          : "https://img.icons8.com/material/72/000000/user-male-circle--v1.png"
                      }
                      alt=""
                      style={{ width: 40, height: 40, borderRadius: 8 }}
                    />
                  </span>
                  <div className="d-flex flex-column p-2 mx-3">
                    <Link
                      to={`doctors/profile/${member.Id}/overview`}
                      style={{ fontWeight: 500 }}
                    >
                      {member.Fname}
                    </Link>
                    <p style={{ fontSize: 12, margin: 0 }}>
                      {member.specialization}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="rated">
            <div className="d-flex flex-column bg-white rounded mt-3 p-4">
              <span className="d-flex" style={{ fontWeight: 500 }}>
                Doctors{" "}
                <p style={{ color: "#888888", fontWeight: 400, marginLeft: 5 }}>
                  - Top/Low Rated
                </p>
              </span>
              <div className="d-flex">
                <div className="d-flex flex-column w-50">
                  <span
                    className="d-flex flex-column align-items-center py-2 px-3 align-self-center"
                    style={{
                      color: "#009B93",
                      background: "rgba(0, 155, 147, 0.15)",
                      borderRadius: 12,
                    }}
                  >
                    <i className="fas fa-arrow-up" style={{ fontSize: 22 }}></i>
                    Top
                  </span>
                  {value.top_doctors.map((member, index) => (
                    <div
                      className="d-flex align-items-center px-1 my-2 pb-2"
                      key={index}
                      style={{ borderBottom: "2px solid #F1F5F8" }}
                    >
                      <span>
                        <img
                          src={
                            member.url
                              ? member.url
                              : "https://img.icons8.com/material/72/000000/user-male-circle--v1.png"
                          }
                          alt=""
                          style={{ width: 40, height: 40, borderRadius: 8 }}
                        />
                      </span>
                      <div className="d-flex flex-column p-2 mx-3">
                        <Link
                          to={`doctors/profile/${member.Id}/overview`}
                          style={{ fontWeight: 500 }}
                        >
                          {member.Fname}
                        </Link>
                        <p style={{ fontSize: 12, margin: 0 }}>
                          {member.specialization}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-flex flex-column w-50">
                  <span
                    className="d-flex flex-column align-items-center py-2 px-3 align-self-center"
                    style={{
                      color: "#FC7100",
                      background: "rgba(252, 113, 0, 0.15)",
                      borderRadius: 12,
                    }}
                  >
                    Low
                    <i
                      className="fas fa-arrow-down"
                      style={{ fontSize: 22 }}
                    ></i>
                  </span>
                  {value.low_doctors.map((member, index) => (
                    <div
                      className="d-flex align-items-center px-1 my-2 pb-2"
                      key={index}
                      style={{ borderBottom: "2px solid #F1F5F8" }}
                    >
                      <span>
                        <img
                          src={
                            member.url
                              ? member.url
                              : "https://img.icons8.com/material/72/000000/user-male-circle--v1.png"
                          }
                          alt=""
                          style={{ width: 40, height: 40, borderRadius: 8 }}
                        />
                      </span>
                      <div className="d-flex flex-column p-2 mx-3">
                        <Link
                          to={`doctors/profile/${member.Id}/overview`}
                          style={{ fontWeight: 500 }}
                        >
                          {member.Fname}
                        </Link>
                        <p style={{ fontSize: 12, margin: 0 }}>
                          {member.specialization}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .pending-doctors-number {
          background: rgba(255, 94, 0, 0.15);
          border-radius: 12px;
        }
        .cancelled {
          background: #ffe0e0;
          color: Red;
          height: 28px;
        }
        .booked {
          background: #d9f7e7a6;
          color: Green;
          height: 28px;
        }
        .missed {
          background: #ffe0e0;
          color: Red;
          height: 28px;
        }
        .fakeCall {
          background: #ffe0e0;
          color: Red;
          height: 28px;
        }
        .reserved {
          background: #fff6e9;
          color: Orange;
          height: 28px;
        }
        .reschedule {
          background: #00b8af1a;
          color: #009b94;
          height: 28px;
        }
        .InProgress {
          background: #e0f3fb;
          color: #00568a;
          height: 28px;
        }
        .testCall {
          background: #e0f3fb;
          color: #00568a;
          height: 28px;
        }
        .completed {
          background: #d9f7e7a6;
          color: #1a9452;
          height: 28px;
        }
        .unattended {
          background: #ffe0e0;
          color: Red;
          height: 28px;
        }
        .called {
          background: #e0f3fb;
          color: #00568a;
          height: 28px;
        }
        .consultation-border:after {
          content: "";
          height: 2px;
          width: 80%;
          background: #e7e4e4;
          margin-top: 10px;
        }
        .hoverClass {
          opacity: 1 !important;
        }
        .approval {
          width: 25% !important;
        }
        .rated {
          width: 50% !important;
        }
        .left {
          width: 50% !important;
        }
        .right {
          width: 50% !important;
        }
        @media only screen and (max-width: 1050px) {
          .cashflow {
            display: flex;
            flex-wrap: wrap;
          }
          .totalCashBackground .span2 {
            font-size: 22px !important;
          }
          .daytomonth {
            flex-wrap: wrap;
          }
          .daytomonth p {
            font-size: 14px !important;
          }
          .chart {
            flex-wrap: wrap;
          }
          .doctordetail {
            flex-wrap: wrap;
          }
          .approval {
            width: 45% !important;
          }
          .rated {
            width: 75% !important;
          }
          .centerer {
            flex-wrap: wrap;
          }
          .left {
            width: 100% !important;
          }
          .right {
            width: 100% !important;
          }
        }
        @media screen and (max-width: 500px) {
          .approval {
            width: 100% !important;
          }
          .rated {
            width: 100% !important;
          }
        }
      `}</style>
    </>
  );
}
