/*
author: Umer shabir 
date: April-2022.
file: this is the overview component of doctor profile shows all the basic informations of doctor.
modified: 17-10-2022 Umer Shabir
*/
// dependencies
import { useSelector } from 'react-redux'
import moment from 'moment'
import React from 'react'
import calender from '../../../../../resources/images/svgs/calendar.svg'
import clock from '../../../../../resources/images/svgs/clock.svg'
import request from '../../../../../resources/images/svgs/request.svg'
import Vector from '../../../../../resources/images/svgs/Vector.png'
import { Link } from 'react-router-dom'
import Loader from '../../../../../Components/Loader'
import { useParams } from 'react-router-dom'
// Doctor profile overview link component
export default function NurseProfileOverview() {
  let params = useParams()
  const { value, loading } = useSelector((state) => state.getNurseReducer)
  // console.log(value)
  return (
    <>
      {loading && <Loader />}
      <div className='container-fluid my-3 '>
        <div className='mainover d-flex gap-3 '>
          <div className='leftover'>
            <div className='col'>
              <table className='bg-white rounded profile-card-width mb-3 w-100'>
                <tbody>
                  <tr>
                    <th>Address</th>
                    <td>{value.address}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col'>
              <table className='bg-white rounded profile-card-width mb-3 w-100'>
                <tbody>
                  <tr>
                    <th><b>Current Appointment</b></th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col'>
              <table className='bg-white rounded profile-card-width mb-3 w-100'>
                <thead></thead>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>
                      {value.personal_info ? value.personal_info.firstName : ''}
                      <br />
                      {value.personal_info ? value.personal_info.lastName : ''}
                    </td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>{value.phoneNo}</td>
                  </tr>
                  <tr>
                    <th>
                      Email
                    </th>
                    <td>{value.email}</td>
                  </tr>
                  <tr>
                    <th>D.O.B</th>
                    <td>{value.dob}</td>
                  </tr>
                  <tr>
                    <th>Gender</th>
                    <td>{value.gender}</td>
                  </tr>
                  <tr>
                    <th>
                      Backup <br /> Contact
                    </th>
                    <td>{value?.backupNumber}</td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>{value.city}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col '>
              <table className='bg-white rounded profile-card-width mb-3 w-100 '>
                <thead></thead>
                <tbody>
                  {/* <tr>
                    <th>Specialization</th>
                    <td>{value.specialization}</td>
                  </tr> */}
                  <tr>
                    <th>License Number </th>
                    <td>{value.liscenseNumber}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col'>
              <table className='bg-white rounded profile-card-width mb-3 w-100'>
                <thead></thead>
                <tbody>
                  <tr>
                    <th>Next of Kin</th>
                    <td>{value.nextOfKin ? value.nextOfKin.name : ''}</td>
                  </tr>
                  <tr>
                    <th>Next of Kin Relation</th>
                    <td>{value.nextOfKin ? value.nextOfKin.relation : ' '}</td>
                  </tr>
                  <tr>
                    <th>Next of Kin Phone</th>
                    <td>{value.nextOfKin ? value.nextOfKin.phone_no : ''}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='rightover mx-2'>
            <div
              className='row '
              style={{ gap: ' 5px', marginRight: '14px', flexWrap: 'unset' }}
            >
              <div className='col-3 bg-white rounded p-3'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex flex-column'>
                    <span style={{ fontSize: '14px', fontWeight: '500' }}>
                      Current Appt.
                    </span>
                    <span
                      className='mt-1'
                      style={{ fontSize: '14px', fontWeight: '400' }}
                    >
                      {value.currentAppointmentCount ? value.currentAppointmentCount : 'No Current Appointments'}
                    </span>
                  </div>
                  <div>
                    <img src={calender} alt='' />
                  </div>
                </div>
              </div>
              <div className='col-3 bg-white rounded p-3'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex flex-column'>
                    <span style={{ fontSize: '14px', fontWeight: '500' }}>
                      Available
                    </span>
                    <span
                      className='mt-1'
                      style={{ fontSize: '14px', fontWeight: '400' }}
                    >
                      {value.available}
                    </span>
                  </div>
                  <div>
                    <img src={Vector} alt='' />
                  </div>
                </div>
              </div>
              <div className='col-3 bg-white rounded p-3'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex flex-column'>
                    <span style={{ fontSize: '14px', fontWeight: '500' }}>
                      Upcoming Appts.
                    </span>
                    <span
                      className='mt-1'
                      style={{ fontSize: '14px', fontWeight: '400' }}
                    >
                      {value.upcomingAppointmentCount ? value.upcomingAppointmentCount : 'No Upcoming Appointments'}
                    </span>
                  </div>
                  <div>
                    <img src={clock} alt='' />
                  </div>
                </div>
              </div>
              <div className='col-3 bg-white rounded p-3 '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex flex-column'>
                    <span style={{ fontSize: '14px', fontWeight: '500' }}>
                      Requests
                    </span>
                    <span
                      className='mt-1'
                      style={{ fontSize: '14px', fontWeight: '400' }}
                    >
                      {value.pastAppointmentCount} Requests
                    </span>
                  </div>
                  <div>
                    <img src={request} alt='' />
                  </div>
                </div>
              </div>
            </div>
            <div
              className='row mt-3   '
              style={{ gap: '10px', marginRight: '10px', flexWrap: 'unset' }}
            >
              <div className='col-6 bg-white rounded p-2  '>
                <div className='d-flex justify-content-between align-items-center'>
                  <span style={{ fontWeight: '500' }}>Current Appointment</span>
                  <Link
                    className='btn '
                    style={{ color: '#8E8E8E', fontSize: '12px' }}
                    to={`/consultations/profile/${value.pastAppointmentId}/overview`}
                  >
                    View Details
                  </Link>
                </div>
                <div>
                  <span style={{ color: '#8E8E8E', fontSize: '14px' }}>
                    Date :{' No Current Appointments'}
                  </span>
                  {/* <span style={{ fontSize: 14 }}>
                    {moment(
                      value.currentAppointment
                        ? value.currentAppointment.dateTime
                        : 'No Current Appointments'
                    ).format('LLLL')}
                  </span> */}
                </div>
                <div className=' d-flex justify-content-between'>
                  <span>
                    <img
                      src={
                        value.pastAppointment
                          ? value.pastAppointment.doctorProfileUrl
                          : 'https://www.iconpacks.net/icons/1/free-doctor-icon-284-thumb.png'
                      }
                      alt=''
                      style={{ width: 30, borderRadius: 25, height: 30 }}
                    />
                    <span
                      style={{
                        fontSize: 12,
                        color: ' #5C5C5C',
                        marginLeft: '10px',
                      }}
                    >
                      {value.pastAppointment
                        ? value.pastAppointment.patientName
                        : ''}
                    </span>
                  </span>
                  <span
                    // className={value.pastAppointment.appointment_status}
                    style={{ padding: '3px 12px', borderRadius: '20px' }}
                    className='completed'
                  >
                    {value.pastAppointment
                      ? value.pastAppointment.appointment_status
                      : ''.replace(/(?:^|\s)\S/g, function (a) {
                        return a.toUpperCase()
                      })}
                  </span>
                </div>
              </div>
              <div className='col-6 bg-white rounded p-2 '>
                <div className='d-flex justify-content-between align-items-center'>
                  <span style={{ fontWeight: '500' }}>
                    Upcoming Appointment
                  </span>

                  <Link
                    className='btn '
                    style={{ color: '#8E8E8E', fontSize: '12px' }}
                    to={`/consultations/profile/${value.upcomingAppointment
                      ? value.upcomingAppointment.appointmentId
                      : ''
                      }/overview`}
                  >
                    View Details
                  </Link>
                </div>
                <div>
                  <span style={{ color: '#8E8E8E', fontSize: '14px' }}>
                    Date :{' '}No Upcoming Appointments
                  </span>
                  {/* <span style={{ fontSize: 14 }}>
                    { }
                    {moment(
                      value.upcomingAppointment
                        ? value.upcomingAppointment.dateTime
                        : 'No Upcoming Appointments'
                    ).format('LLLL')}
                  </span> */}
                </div>
                <div className=' d-flex justify-content-between'>
                  <span>
                    <img
                      src={
                        value.upcomingAppointment
                          ? value.upcomingAppointment.patientProfileUrl
                          : 'https://www.iconpacks.net/icons/1/free-doctor-icon-284-thumb.png'
                      }
                      alt=''
                      style={{ width: 30, borderRadius: 25, height: 30 }}
                    />
                    <span
                      style={{
                        fontSize: 12,
                        color: ' #5C5C5C',
                        marginLeft: '10px',
                      }}
                    >
                      {value.upcomingAppointment
                        ? value.upcomingAppointment.patientName
                        : ''}
                    </span>
                  </span>
                  <span
                    // className={value.appointmentInfo.appointment_status}
                    style={{ padding: '3px 12px', borderRadius: '20px' }}
                    className='completed'
                  >
                    {value.upcomingAppointment
                      ? value.upcomingAppointment.appointment_status
                      : 'N/A'.replace(/(?:^|\s)\S/g, function (a) {
                        return a.toUpperCase()
                      })}
                  </span>
                </div>
              </div>
            </div>
            <div
              className='row mt-3'
              style={{ gap: ' 5px', marginRight: '0px', flexWrap: 'unset' }}
            >
              <div className='col bg-white rounded p-3'>
                <div className='d-flex w-100 justify-content-between align-items-center'>
                  <div
                    className='after-class'
                    style={{ position: 'relative', flexGrow: 1 }}
                  >
                    <p style={{ fontWeight: 500, margin: 0 }}>KPI</p>
                  </div>
                  <div
                    className='after-class'
                    style={{ position: 'relative', flexGrow: 1.5 }}
                  >
                    <p
                      style={{
                        color: '#5C5C5C',
                        margin: 0,
                        marginBottom: 7,
                        fontWeight: 500,
                      }}
                    >
                      Total delayed appointment
                    </p>
                    <p
                      className='m-0'
                      style={{ color: '#1E1E1E', fontSize: 14 }}
                    >
                      {value.delay?.totaldelay ? value.delay.totaldelay : '0'}
                    </p>
                  </div>
                  <div
                    className='d-flex flex-column after-class'
                    style={{ position: 'relative', flexGrow: 2 }}
                  >
                    <p
                      style={{
                        color: '#5C5C5C',
                        margin: 0,
                        marginBottom: 7,
                        fontWeight: 500,
                      }}
                    >
                      Current month delayed
                    </p>
                    <div className='d-flex' style={{ marginTop: 0 }}>
                      <span className='d-flex'>
                        <p
                          className='m-0 me-2'
                          style={{ color: '#1E1E1E', fontSize: 14 }}
                        >
                          {value.delay?.currentMonth
                            ? value.delay.currentMonth
                            : '0'}
                        </p>{' '}
                        <small style={{ color: '#AAAAAA' }}>
                          Current month
                        </small>
                      </span>
                    </div>
                  </div>
                  <div
                    className='after-class'
                    style={{ position: 'relative', flexGrow: 1 }}
                  >
                    <Link
                      to={`/nurses/profile/${params.id}/delayedappointments`}
                      style={{
                        color: '#6D6DBB',
                      }}
                      className='underline'
                    >
                      See details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='row bg-white rounded mt-3'
              style={{ marginRight: '0px' }}
            >
              <div className='col-md-12  '>
                <div className=' row '>
                  <h4 className='pt-2 pb-0' style={{ fontSize: '16px' }}>
                    Nurse services
                  </h4>
                </div>
                <div className=' d-flex flex-wrap  pb-3 pt-2 '>
                  {value.services &&
                    value.services.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <span className={' skin-services  '}>{data}</span>
                        </React.Fragment>
                      )
                    })}
                </div>
              </div>
            </div>
            <div
              className='row bg-white rounded mt-3'
              style={{ marginRight: '0px' }}
            >
              <div className='col  '>
                <div className='d-flex row border-bottom'>
                  <h4 className='pt-2 pb-0' style={{ fontSize: '16px' }}>
                    Nurse Experience
                  </h4>
                  {value.experiences &&
                    value.experiences.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <h6 className='mt-2' style={{ fontSize: '14px' }}>
                            {data.title}
                          </h6>
                          <span style={{ fontSize: '12px' }}>
                            {data.company} &nbsp;| &nbsp;{' '}
                            {moment(data.start_date).format('MMMM YYYY')} &nbsp;
                            - &nbsp;
                            {data.end_date
                              ? moment(data.end_date).format('MMMM YYYY')
                              : data.currently_work
                                ? 'Currently working here'
                                : ''}
                          </span>
                          <span
                            className='pt-1 pb-2 border-bottom '
                            style={{ fontSize: '12px' }}
                          >
                            {data.location}
                          </span>
                        </React.Fragment>
                      )
                    })}
                </div>
              </div>
            </div>
            <div
              className='row bg-white rounded mt-3'
              style={{ marginRight: '0px' }}
            >
              <div className='col  '>
                <div className='d-flex row border-bottom'>
                  <h4 className='pt-2 pb-0' style={{ fontSize: '16px' }}>
                    Nurse Education
                  </h4>
                  {value.educationalDocuments &&
                    value.educationalDocuments.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <h6 className='mt-2' style={{ fontSize: '14px' }}>
                            {data.institute_name}
                          </h6>
                          <span style={{ fontSize: '12px' }}>
                            {data.degree} &nbsp;| &nbsp;
                            {moment(data.start_date).format('MMMM YYYY')} &nbsp;
                            - &nbsp; {moment(data.end_date).format('MMMM YYYY')}
                          </span>
                          <span
                            className='pt-1 pb-2 border-bottom '
                            style={{ fontSize: '12px' }}
                          >
                            {data.location}
                          </span>
                        </React.Fragment>
                      )
                    })}
                </div>
              </div>
            </div>
            <div
              className='row bg-white rounded mt-3'
              style={{ marginRight: '0px' }}
            >
              <div className='col  '>
                <div className='d-flex row border-bottom'>
                  <h4 className='pt-2 pb-0' style={{ fontSize: '16px' }}>
                    Nurse Certifications
                  </h4>
                  {value.certifications &&
                    value.certifications.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <h6 className='mt-2' style={{ fontSize: '14px' }}>
                            {data.title}
                          </h6>
                          <span style={{ fontSize: '12px' }}>
                            {data.institute} &nbsp;| &nbsp;
                            {moment(data.start_date).format('MMMM YYYY')} &nbsp;
                            - &nbsp; {moment(data.end_date).format('MMMM YYYY')}
                          </span>
                          <span
                            className='pt-1 pb-2 border-bottom '
                            style={{ fontSize: '12px' }}
                          ></span>
                        </React.Fragment>
                      )
                    })}
                </div>
              </div>
            </div>
            <div
              className='row bg-white rounded mt-3'
              style={{ marginRight: '0px' }}
            >
              <div className='col  '>
                <div className=' row border-bottom'>
                  <h4 className='pt-2 pb-0' style={{ fontSize: '16px' }}>
                    Nurse Volunteer Experience
                  </h4>
                  {value.voluntaryservices &&
                    value.voluntaryservices.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <h6 className='mt-2' style={{ fontSize: '14px' }}>
                            {data.cause}
                          </h6>
                          <span style={{ fontSize: '12px' }}>
                            {data.organization} &nbsp;| &nbsp;{' '}
                            {moment(data.start_date).format('MMMM YYYY')} &nbsp;
                            - &nbsp; {moment(data.end_date).format('MMMM YYYY')}
                          </span>
                          <span
                            className='pt-1 pb-2 border-bottom '
                            style={{ fontSize: '12px' }}
                          ></span>
                        </React.Fragment>
                      )
                    })}
                </div>
              </div>
            </div>
            <div
              className='row bg-white rounded mt-3'
              style={{ marginRight: '0px' }}
            >
              <div className='col  '>
                <div className='d-flex row border-bottom'>
                  <h4 className='pt-2 pb-0' style={{ fontSize: '16px' }}>
                    Nurse Honors & Awards
                  </h4>
                  {value.achievementAwards &&
                    value.achievementAwards.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <h6 className='mt-2' style={{ fontSize: '14px' }}>
                            {data.title}
                          </h6>
                          <span style={{ fontSize: '12px' }}>
                            {data.associated_with} &nbsp;| &nbsp;{' '}
                            {moment(data.issue_date).format('MMMM YYYY')} &nbsp;
                            - &nbsp; {moment(data.end_date).format('MMMM YYYY')}
                          </span>
                          <span
                            className='pt-1 pb-2 border-bottom '
                            style={{ fontSize: '12px' }}
                          >
                            {data.issuer}
                          </span>
                        </React.Fragment>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx='true'>{`
        .leftover{
          width: 25%;
        }
        .rightover{
          width: 75%;
        }
        .skin-services {
          background-color: #e5e5e5;

          font-weight: 400;
          font-size: 12px;
          margin: 2px;
          border-radius: 2px;
          text-align: center !important;
          padding: 6px;

          color: #1e1e1e;
        }
        table {
          font-size: 14px;
        }
        th {
          font-weight: 500;
          padding: 15px;
        }
        td {
          text-align: right;
          padding: 15px;
        }
        tr {
          border-bottom: 1px solid #dadada;
        }
        .cancelled {
          background: #ffe0e0;
          color: Red;
        }
        .booked {
          background: #d9f7e7a6;
          color: Green;
        }
        .missed {
          background: #ffe0e0;
          color: Red;
        }
        .reserved {
          background: #fff6e9;
          color: Orange;
        }
        .reschedule {
          background: #00b8af1a;
          color: #009b94;
        }
        .InProgress {
          background: #e0f3fb;
          color: #00568a;
        }
        .completed {
          background: #d9f7e7a6;
          color: #1a9452;
        }
        .after-class:nth-child(-n + 3):after {
          content: '';
          width: 2px;
          height: 45px;
          position: absolute;
          border-radius: 5px;
          top: 8px;
          right: 10px;
          background: #dadada;
        }
        .after-class:nth-child(-n + 1):after {
          content: '';
          width: 2px;
          height: 45px;
          position: absolute;
          border-radius: 5px;
          top: -10px;
          bottom: 10px;
          right: 10px;
          background: #dadada;
        }
        .underline {
          text-decoration: underline !important;
        }
        @media screen and (max-width: 1550px) {
          .leftover{
            width: 50%;
          }
          .rightover{
            width: 100%;
          }
        }
        @media screen and (max-width: 1050px) {
          .mainover{
            flex-wrap: wrap;
          }
        }
        @media screen and (max-width: 500px) {
          .leftover{
            width: 100%;
          }
        }
      `}</style>
    </>
  )
}
