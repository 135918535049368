import React, { useMemo, useEffect, useState } from 'react'
import Table from '../../../../../Components/Table'
import axios from 'axios'

import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import AlertMessage from '../../../../../Components/AlertMessage'
import FilterForm from './FilterForm'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
import AddNewUserButton from '../../../../../Components/AddNewUserButton'

//
//
export default function Services() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [patientList, setpatientList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0)
  const params = useParams()
  const [page, setPage] = useState(1)

  const filter_status = {
    appointment_status: useSelector(
      (state) => state?.patientServicesFilterReducer?.value
    ),
    appoint_type: useSelector(
      (state) => state?.patientServicesFilterReducer?.value2
    ),

    created_at: useSelector(
      (state) => state?.patientServicesFilterReducer?.value1
    ),
  }
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setpatientList(patientList.slice(startRow, endRow))

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(patientList.length / pageSize))
      }
    },
    [patientList, lastEvaluatedKey]
  )
  //
  const handleFilters = () => {
    let filterData = {
      appointment_status: `${filter_status.appointment_status}`,
      created_at: `${filter_status.created_at}`,
      appoint_type: `${filter_status.appoint_type}`,
    }
    if (Object.values(filterData).every((key) => key === '')) {
      return {}
    } else
      return Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== '')
      )
  }
  // API Hatching data of all admins
  const userList = () => {
    axios
      .post(`${API_CONSTANTS.BASEURL}admin/patients/${params.Id}/appointments`,
        { appoint_type: "NurseBooked" }, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        // console.log(res)
        setIsLoading(true)
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
        setpatientList(res.data.response.Items)
      })
      .catch((error) => {
        if (error.response.state === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setIsLoading(true)
        setError(error)
      })
  }
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL}admin/patients/${params.id}/appointments?last=${lastEvaluatedKey}`,
        handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
        var patientListNextPage = response.data.response.Items
        setpatientList([...patientList, ...patientListNextPage])
        setPage(page + 1)
      })
      .then(() => setLoading(true))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Service Type',
        // accessor: 'appoint_type'
        accessor: (item) =>
          item.appoint_type === 'NurseBooked'
          && 'Nurse Booked'
      },
      {
        Header: 'Booked by',
        accessor: (item) =>
          item.patientInfo
            ? item.patientInfo.name + ' ' + `(${item.patientInfo.relation})`
            : '',
      },
      {
        Header: 'Service Status',
        accessor: (property) =>
          property.appointment_status.replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase()
          }),
        Cell: ({ cell: { value } }) => value || 'N/A',
      },

      {
        Header: 'Nurse Name',
        accessor: (item) =>
          item.doctorInfo
            ? item.doctorInfo.firstName + ' ' + item.doctorInfo.lastName
            : '',
        Cell: ({ cell: { value } }) => value || 'N/A',
      },
      {
        Header: 'Date/Time',
        accessor: (a) => moment(a.appoint_date).format('DD-MM-YYYY HH:mm A'),
      },
      {
        Header: 'Booked On',
        accessor: (a) => moment(a.created_at).format('DD-MM-YYYY'),
      },
    ],
    []
  )
  const is_SessionExpire = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  // rendering API
  useEffect(() => {
    userList()
  }, [
    filter_status.appointment_status,
    filter_status.created_at,
    filter_status.appoint_type,
  ])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 404'
            ? 'This record does not exist'
            : error.message === 'Request failed with status code 401'
              ? is_SessionExpire()
              : error.message
        }
      />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center m-4'>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <>
        <div className='row rounded bg-white m-4'>
          <div className='d-flex justify-content-between'>
            <div>
              <h3
                style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}
              >
                All Services
              </h3>
            </div>
            <div>
              <AddNewUserButton link={`/patients/profile/${params.id}/services/bookservices/new`} buttonText={'Book Service'} />
            </div>
          </div>
          <Table
            pageNumber={page}
            columns={columns}
            data={patientList}
            routingLink={'/services/profile'}
            pageCount={pageCount}
            fetchData={fetchData}
            loadMoreList={handleLoadMoreData}
            lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            setLoading={setLoading}
            FilterForm={<FilterForm />}
          />
        </div>
      </>
    )
  }
}
