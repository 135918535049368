import React from 'react'
import DoctorList from './Components/DoctorList/index'
//
export default function Doctor() {
  return (
    <>
      <DoctorList />
    </>
  )
}
