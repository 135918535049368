import React from 'react'
import { useTable } from 'react-table'
import { useSelector } from 'react-redux'
import ImageViewer from './ViewFile'
//
export default function RecordTable() {
  const { value } = useSelector((state) => state.getConsultationProfileReducer)

  const data = value.sharedMedicalRecord
  // columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'patient_name',
      },
      {
        Header: 'Doctor',
        accessor: 'doctor_name',
      },
      {
        Header: 'Type',
        accessor: 'record_type',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Attached File',
        accessor: (res) => (
          <ImageViewer
            images={res.image}
            patientName={res.patient_name}
            date={res.date}
          />
        ),
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })

  return (
    <table
      className='medical-record '
      {...getTableProps()}
      style={{ width: '100%' }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} style={{ paddingLeft: '0px' }}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} style={{ paddingLeft: '0px' }}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
      <style jsx='true'>{`
        .medical-record td {
          text-align: left;
        }
      `}</style>
    </table>
  )
}
