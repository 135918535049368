/*
Author: Zeeshan 
Date: 01-05-2023
File: blogs list,
*/
// dependencies
import React, { useMemo, useEffect, useState, useRef } from 'react'
import Table from '../../../Components/Table'
import axios from 'axios'
import { API_CONSTANTS } from '../../../API_CONSTANTS'
import Spinner from 'react-bootstrap/Spinner'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'
import { Button, Modal } from 'react-bootstrap'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../Components/Toast/Toast'
import AlertMessage from '../../../Components/AlertMessage/index'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
// Edit Button Component
function Edit(props) {
  const { name } = props
  return (
    <Link to={`/blogs/EditPost/${name.name}`} className='editButton'>
      Edit
      <style jsx='true'>
        {`
          .editButton {
            text-decoration: underline !important;
            color: #009b94;
          }
          .editButton:hover {
            color: #009b94;
          }
        `}
      </style>
    </Link>
  )
}

// Delete Button & API integration Component
function Delete(props) {
  const { name, getList } = props
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  function handleDeleteConfirmation() {
    setIsLoading(true) // set loading to true before API call is made
    axios
      .delete(`${API_CONSTANTS.BASEURL_1}admin/blogs/${name}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((response) => {
        setIsLoading(false) // set loading to false on success
        if (response.data.message === 'Success') {
          handleToast(
            'Blog Delete Successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
        }
        getList()
      })
      .then(() => setShowDeleteConfirmation(false))
      .catch((error) => {
        setIsLoading(false) // set loading to false on error
        console.error(error)
      })
  }

  return (
    <>
      <Button
        style={{
          color: '#B00020',
          backgroundColor: '#ffffff',
          border: 'none',
          textDecoration: 'underline',
        }}
        onClick={() => setShowDeleteConfirmation(true)}
      >
        Delete
      </Button>
      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant='danger' onClick={handleDeleteConfirmation}>
            Delete {isLoading && <Spinner animation='border' size='sm' />}
          </Button>
        </Modal.Footer>
      </Modal>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}
function Publish(props) {
  const { name, getList } = props
  const [show, setShow] = useState(false)
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  function handlePublish() {
    setIsLoading(true) 
    axios
      .patch(`${API_CONSTANTS.BASEURL_1}admin/blogs/publish/${name}/status/true`, {}, {
        headers: API_CONSTANTS.headers(),
      })
      .then((response) => {
        setIsLoading(false)
        if (response.data.message === "Success") {
          handleToast(
            "Blog Publish Successfully",
            faCheckCircle,
            "#009b94",
            "show-success"
          );
        }
        getList()
      })
      .then(() => setShow(false))
      .catch((error) => {
        setIsLoading(false) // set loading to false on error
        console.error(error)
      })
  }

  return (
    <>
      <Button
        className='btnn'
        onClick={() => setShow(true)}
      >
        Publish
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Publish Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Publish?</Modal.Body>
        <div className='d-flex justify-content-center py-3 gap-3'>
          <Button
            style={{
              color: '#B00020',
              backgroundColor: '#ffffff',
              border: '1px solid #B00020',
            }}
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
          <Button
            className='btnn'
            onClick={handlePublish}
          >
            Publish {isLoading && <Spinner animation='border' size='sm' />}
          </Button>
        </div>
      </Modal>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}

// function handlePublish() {
//   const [loading, setLoading] = useState(false)
//   // Publish Blog API
//   setLoading(true);
//   axios
//     .patch(
//       `${API_CONSTANTS.BASEURL_1}admin/blogs/publish/${params.name}/status/true`,
//       {},
//       {
//         headers: API_CONSTANTS.headers(),
//       }
//     )
//     .then((response) => {
//       setLoader({ type: "PUBLISHLOADING_END" });
//       if (response.data.message === "Success") {
//         handleToast(
//           "Blog Publish Successfully",
//           faCheckCircle,
//           "#009b94",
//           "show-success"
//         );
//         setPublishStatus(true)
//       }
//     })
//     .catch((error) => {
//       setLoader({ type: "PUBLISHLOADING_END" })
//       console.log(error)
//     })
// }
// admin users table list component.
export default function BlogsList() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [blogList, setBlogList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0)
  const [page, setPage] = useState(0)
  const filter_Status = useSelector((state) => state.adminFilteredReducer.value)
  const isMounted = useRef(false)
  // pagination call back
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // Give this fetch an ID
      const fetchId = lastEvaluatedKey
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setBlogList(blogList.slice(startRow, endRow))

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(blogList.length / pageSize))
      }
    },
    [blogList, lastEvaluatedKey]
  )

  const blogsList = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/blogs`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true)
        setBlogList(res.data.response.Items)
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.localStorage.clear()
          window.location.reload()
        }
        setIsLoading(true)
        setBlogList([])
        // setError(error)
      })
  }

  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/blogs?last=${lastEvaluatedKey}`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
        var adminListNextPage = response.data.response.Items
        setBlogList([...blogList, ...adminListNextPage])
        setPage(page + 1)
      })
      .then(() => setLoading(true))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Topic',
        accessor: 'title',
      },
      {
        Header: 'Publish Date',
        accessor: (item) => moment(item.date).format('D-MM-YYYY'),
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Reading Time',
        accessor: (item) => `${item.readTime} Minutes`,
      },
      {
        Header: 'Edit Blog',
        accessor: (item) => <Edit name={item} />,
      },
      {
        Header: 'Delete Blog',
        accessor: (item) => <Delete name={item.name} getList={blogsList} />,
      },
      {
        Header: 'Publish Status',
        accessor: 'isPublished',
        Cell: ({ value, row }) => {
          if (value === true || value === 'True') {
            return (
              <CheckCircleIcon style={{ color: '#009B94' }} />
            );
          } else {
            return (
              <Publish name={row.original.name} getList={blogsList} />
            );
          }
        },
      },
    ],
    []
  )
  const is_SessionExpire = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  // rendering API
  useEffect(() => {
    !isMounted.current && blogsList()
  }, [filter_Status])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 401'
            ? is_SessionExpire()
            : error.message === 'Request failed with status code 404'
              ? 'record not found'
              : error.message
        }
      />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <div className='row rounded bg-white m-4 overflow-auto'>
        <div className='p-0'>
          <h3 style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}>
            All Blogs
          </h3>
        </div>
        <Table
          pageNumber={page}
          columns={columns}
          data={blogList}
          routingLink={''}
          pageCount={pageCount}
          fetchData={fetchData}
          loadMoreList={handleLoadMoreData}
          lastEvaluatedKey={lastEvaluatedKey}
          loading={loading}
          setLoading={setLoading}
        // FilterForm={<AdminFilterForm />}
        />
      </div>
    )
  }
}
