import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import axios from 'axios'
import Toast from '../../../Components/Toast/Toast'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { API_CONSTANTS } from '../../../API_CONSTANTS'

//
//
//
export default function BlockProfileButton({ get_admin_details, overview }) {
  // Toast Message ---------
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [spin, setSpin] = useState('')
  const params = useParams()
  const dispatch = useDispatch()
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  const handle_blocked = () => {
    setSpin('spinner-border spinner-border-sm')
    const data = {
      Id: overview.id,
    }
    axios
      .patch(`${API_CONSTANTS.BASEURL}admin/updateUserState/${params.id}/type/1/state/disable`, data, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        handleToast(
          res.data.response.message,
          faCheckCircle,
          '#009b94',
          'show-success'
        )
        setSpin('')
      })
      .then(() => {
        setTimeout(() => dispatch({ type: 'getPatientProfile/block' }), 1000)
      })
      .catch((error) => {
        if (error.message.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setSpin('')
      })
  }
  return (
    <>
      <button
        className='btn custom-button block-button'
        onClick={handle_blocked}
      >
        <span className={spin + ' me-2'} />
        Block
      </button>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style jsx='true'>
        {`
          .block-button {
            border: 2px solid #5c5c5c;
            color: #5c5c5c;
          }
          .block-button:hover {
            background: #5c5c5c !important;
            color: #fff;
          }
        `}
      </style>
    </>
  )
}
