import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case "LOADING_TRUE": {
      return {
        ...state,
        loading: true,
      };
    }
    case "LOADING_FALSE": {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      };
    }
  }
}

//Doctor related filter search form
export default function PatientFilterForm({ patientList }) {
  const filter_status = {
    gender: useSelector((state) => state.patientFilterReducer.value1),
    created_at: useSelector((state) => state.patientFilterReducer.value2),
    appoint_date_from: useSelector(
      (state) => state.patientFilterReducer.value3
    ),
  };
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    gender: filter_status.gender,
    created_at: filter_status.created_at,
    appoint_date_from: filter_status.appoint_date_from,
    loading: false,
  });

  //

  const dispatch = useDispatch();
  //
  const { created_at, gender, appoint_date_from, loading } = formData;
  //
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormDataDispatch({ type: "LOADING_TRUE" });
    if (appoint_date_from !== undefined) {
      dispatch({
        type: "patientFilter/appoint_date_from",
        payload: appoint_date_from,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
    if (created_at !== undefined) {
      dispatch({
        type: "patientFilter/created_at",
        payload: created_at,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
    if (gender === filter_status.gender) {
      patientList();
    } else {
      dispatch({ type: "patientFilter/" + gender });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="mt-4 d-flex flex-column justify-content-center align-items-center"
      >
        <div
          className="form-group mt-3 w-100
        px-2"
        >
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Gender
          </label>
          <select
            className="form-control"
            id="exampleFormControlSelect1"
            onChange={setFormDataDispatch}
            name="gender"
            defaultValue={gender}
          >
            <option name="all" value="all">
              All
            </option>
            <option>Male</option>
            <option>Female</option>
            <option>Other</option>
          </select>
        </div>
        <div className="form-group mt-3 px-2 w-100">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Registered Date From
          </label>
          <input
            onChange={setFormDataDispatch}
            name="appoint_date_from"
            type="date"
            className="form-control filter-input"
            id="exampleFormControlSelect2"
            value={appoint_date_from}
          />
        </div>
        <div className="form-group mt-3 px-2 w-100">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Registered Date To
          </label>

          <input
            onChange={setFormDataDispatch}
            name="created_at"
            type="date"
            className="form-control filter-input"
            id="exampleFormControlSelect2"
            value={created_at}
          />
        </div>
        <button className="btn mt-3 filter-form-button">
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: "20px" }}
            />
          )}{" "}
          {loading || "Apply"}
        </button>
      </form>
      <style jsx="true">{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 95%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
      `}</style>
    </>
  );
}
