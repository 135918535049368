import React, { useMemo, useEffect, useState } from "react";
import Table from "../../../Components/Table/index";
import axios from "axios";
import { API_CONSTANTS } from "../../../API_CONSTANTS";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { useSelector } from "react-redux";
import style from "../../../Style/corporate.module.css";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import AlertMessage from "../../../Components/AlertMessage";
import { useParams } from "react-router";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import moment from "moment/moment";
function Edit(data) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [addPlanLoading, setAddPlanLoading] = useState(false)
  const [startDate, setStartDate] = useState(data?.data?.planStartDate || '');
  const [endDate, setEndDate] = useState(data?.data?.planEndDate || '');
  // console.log(data?.data)
  const params = useParams();
  const handleAddPartner = (e) => {
    setAddPlanLoading(true);
    axios
      .patch(
        API_CONSTANTS.BASEURL_4 + `admin/corporates/${params.id}/subscribe/${data?.data?.appointmentId}`,
        {
          startDate,
          endDate,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setAddPlanLoading(false);
        setOpen(false);
        toast(res.data.response.message, {
          type: "success",
        });
      })
      .catch((err) => {
        toast(err.response.data.response.message, {
          type: "error",
        });
        setAddPlanLoading(false);
      });
  };
  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
      >
        Edit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={partnerStyles}>
          <div className={style.addPartnerModalContainer}>
            <Button
              className={style.closeBtnPartnerModal}
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
            <div className={style.addPartnerModal}>
              <TextField
                id="dateInput"
                label="Start Date"
                variant="standard"
                name="startDate"
                onChange={(e) => setStartDate(e.target.value)}
                required
                value={startDate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: moment().format("YYYY-MM-DD"),
                }}
              />
              <TextField
                id="dateInput"
                label="End Date"
                variant="standard"
                name="endDate"
                onChange={(e) => setEndDate(e.target.value)}
                required
                value={endDate}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: moment(startDate).format("YYYY-MM-DD"),
                }}
              />
            </div>
            <div className={style.actionBtnModal + " mt-4 ps-3"}>
              <LoadingButton
                className={style.actionBtn}
                variant="contained"
                loading={addPlanLoading}
                onClick={handleAddPartner}
                disabled={startDate && endDate ? false : true}
              >
                Add Plan
              </LoadingButton>
              <Button
                onClick={handleClose}
                color="error"
                className={style.actionBtn}
              >
                Discard
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  )
}

// styling
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};
const partnerStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
};
export default function Plans() {
  // variables
  const { value } = useSelector((state) => state.getCorporateProfileReducer);
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [addPartner, setAddPartner] = useState({
    startDate: "",
    endDate: "",
    pId: "CRPL-PHSG-81177",
  });
  const { startDate, endDate, pId } = addPartner;
  const [page, setPage] = useState(0);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [patientList, setpatientList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addPlanLoading, setAddPlanLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [planslist, setPlansList] = useState([]);
  const fetchIdRef = React.useRef(0);

  // functions
  const getDataFromFields = (e) => {
    setAddPartner((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleOpen = () => {
    setOpen(true);
    axios
      .get(
        API_CONSTANTS.BASEURL_1 +
        `admin/subscriptionplan?corporateplan=true&activeplan=true`,
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        setPlansList(res.data.response.Items[0].list);
        console.log(res.data.response.Items[0].list);
      })
      .catch((err) => console.log(err));
  };
  const handleClose = () => setOpen(false);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setpatientList(patientList.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(patientList.length / pageSize));
      }
    },
    [patientList, lastEvaluatedKey]
  );
  //
  // API Hatching data of all admins
  const userList = () => {
    axios
      .get(`${API_CONSTANTS.BASEURL_4}admin/corporates/${params.id}/plans`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setIsLoading(true);
        setpatientList(res.data.response.Items);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setIsLoading(true);
        setpatientList([]);
        // setError(error)
      });
  };
  //
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_4}admin/corporates?last=${lastEvaluatedKey}`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var patientListNextPage = response.data.response.Items;
        setpatientList([...patientList, ...patientListNextPage]);
        setPage(page + 1);
      })
      .then(() => setLoading(true));
  };
  // handle add new partner
  const handleAddPartner = (e) => {
    setAddPlanLoading(true);
    axios
      .post(
        API_CONSTANTS.BASEURL_4 + `admin/corporates/${params.id}/subscribe`,
        {
          pId,
          startDate,
          endDate,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setAddPlanLoading(false);
        setOpen(false);
        toast(res.data.response.message, {
          type: "success",
        });
      })
      .catch((err) => {
        toast(err.response.data.response.message, {
          type: "error",
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Plan Name",
        accessor: (item) => item.patientInfo.planName,
      },
      {
        Header: "Start Date",
        accessor: (property) =>
          property.planStartDate
            ? moment(property.planStartDate).format("DD-MM-YYYY")
            : "N/A",
      },

      {
        Header: "End Date",
        accessor: (property) =>
          property.planEndDate
            ? moment(property.planEndDate).format("DD-MM-YYYY")
            : "N/A",
      },
      {
        Header: "Calls per employee",
        accessor: (item) => item.patientInfo.callsPerEmp,
      },

      {
        Header: "Status",
        accessor: (item) => (
          <ActivatedDeactivated
            id={params.id}
            planStatus={item.planStatus}
            name={item}
          />
        ),
      },
      {
        Header: "Price",
        accessor: (item) =>
          item.patientInfo ? item.patientInfo.totalPrice : "-",
      },
      {
        Header: "Action",
        accessor: (item) => <Edit data={item} />,
      },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  // rendering API
  useEffect(() => {
    if (patientList.length < 1) {
      userList();
    }
  }, []);

  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 404"
            ? "This record does not exist"
            : error.message === "Request failed with status code 401"
              ? is_SessionExpire()
              : error.message
        }
      />
    );
  } else {
    return (
      <>
        <section className={style.corporatePageSection}>
          {/* add new corporate  */}
          <Button
            variant="contained"
            className={style.addPartnerBtn}
            onClick={handleOpen}
          >
            <AddIcon />
            Activate New Plan
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={partnerStyles}>
              <div className={style.addPartnerModalContainer}>
                <Button
                  className={style.closeBtnPartnerModal}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Button>

                <div className={style.addPartnerModal}>
                  <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">
                      Select Plan
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={pId}
                      label="Select Plan"
                      name="pId"
                      onChange={getDataFromFields}
                    >
                      <MenuItem value="" selected disabled>
                        --Select--
                      </MenuItem>
                      {planslist.map((item, indx) => (
                        <MenuItem value={item.pId} key={indx}>
                          {item.planName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    id="dateInput"
                    label="Start Date"
                    variant="standard"
                    name="startDate"
                    onChange={getDataFromFields}
                    required
                    value={startDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: moment().format("YYYY-MM-DD"),
                    }}
                  />
                  <TextField
                    id="dateInput"
                    label="End Date"
                    variant="standard"
                    name="endDate"
                    onChange={getDataFromFields}
                    required
                    value={endDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: moment(startDate).format("YYYY-MM-DD"),
                    }}
                  />
                </div>
                <div className={style.actionBtnModal + " mt-4 ps-3"}>
                  <LoadingButton
                    className={style.actionBtn}
                    variant="contained"
                    loading={addPlanLoading}
                    onClick={handleAddPartner}
                    disabled={pId && startDate && endDate ? false : true}
                  >
                    Add Plan
                  </LoadingButton>
                  <Button
                    onClick={handleClose}
                    color="error"
                    className={style.actionBtn}
                  >
                    Discard
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
          {/* corporate table listing  */}
          <div className="row rounded bg-white m-4">
            <h3
              style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}
            >
              {value.companyName} Plans
              {!isLoading && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "#24AAA4" }}
                />
              )}
            </h3>
            <Table
              pageNumber={page}
              columns={columns}
              data={patientList}
              routingLink={``}
              pageCount={pageCount}
              fetchData={fetchData}
              loadMoreList={handleLoadMoreData}
              lastEvaluatedKey={lastEvaluatedKey}
              loading={loading}
              recordDownload={true}
              setLoading={setLoading}
            />
          </div>
        </section>
        <ToastContainer />
      </>
    );
  }
}
/////----------- activate plans/ deactivate plans --///
const ActivatedDeactivated = ({ id, planStatus, name }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(
    planStatus === "Active" ? true : false
  );
  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };
  const handleChange = (e) => {
    setOpen(true);
  };
  // handle api response
  const handleClick = () => {
    setLoading(true);
    axios
      .post(
        API_CONSTANTS.BASEURL_4 +
        `admin/corporates/${id}/subscribe?activatePlan=true`,
        {
          appointmentId: name.appointmentId,
          subscriptionStatus: checked ? "inActive" : "Active",
        },
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        setLoading(false);
        setChecked(!checked);
        setOpen(false);
        toast(res.data.response.message, {
          type: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        toast(
          error.response?.data?.response?.message
            ? error.response?.data?.response?.message
            : "Something went wrong please try again later",
          {
            type: "error",
          }
        );
      });
  };
  return (
    <div>
      <Switch checked={checked} onChange={handleChange} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div className={style.plansModalContainer}>
            {checked ? (
              <CancelIcon color="error" style={{ fontSize: "2rem" }} />
            ) : (
              <CheckCircleIcon color="success" style={{ fontSize: "2rem" }} />
            )}
            <p
              className={style.modalTextError}
              style={{
                color: checked ? " #af1300" : "#1E1E1E",
              }}
            >
              {checked ? "Deactivate Service" : "Activate Service"}
            </p>
            <p className={style.modalText}>
              {checked
                ? `Are you sure you want to Deactivate`
                : "Are you sure you want to Activate"}
            </p>
            <div className={style.actionBtnModal}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                className={style.actionBtn}
              >
                Close
              </Button>
              <LoadingButton
                variant="contained"
                color={checked ? "error" : "success"}
                size="small"
                onClick={handleClick}
                className={style.actionBtn}
                loading={loading}
              // loadingPosition="end"
              >
                {checked ? "Deactivate" : "Activate"}
              </LoadingButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
