/*
Author: Umer Shabir 
Date: 16-03-2022
File: Filter component
*/
// dependencies
import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table/dist/react-table.development'
// this is the delay time of filtering input
const TWO_HUNDRED_MS = 200
// Global Filter
export default function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, TWO_HUNDRED_MS)
  return (
    <>
      <div>
        <i className='fas fa-search mr-2' style={{ color: '#99d7d4' }}></i>
        <input
          type='text'
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
          placeholder={`Search`}
          className='global_filter_search'
        />
      </div>
      <style jsx='true'>{`
        .global_filter_search {
          padding: 10px;
          outline: none;
          border: none;
          border-bottom: 2px solid #99d7d4;
          color: #8e8e8e;
        }
      `}</style>
    </>
  )
}
