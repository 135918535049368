import React, { useMemo, useEffect, useState } from "react";
import Table from "../../../../Components/Table/index";
import axios from "axios";
import { API_CONSTANTS } from "../../../../API_CONSTANTS";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import style from "../../../../Style/corporate.module.css";
import AlertMessage from "../../../../Components/AlertMessage";
// styling

export default function CorporateTransactions() {
  // variables
  const [page, setPage] = useState(0);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [patientList, setpatientList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  // functions
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setpatientList(patientList.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(patientList.length / pageSize));
      }
    },
    [patientList, lastEvaluatedKey]
  );
  //
  // API Hatching data of all admins
  const userList = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_4}admin/corporates/tranactions`,
        {},

        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
        setpatientList(res.data.response.Items ? res.data.response.Items : []);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setIsLoading(true);
        setpatientList([]);
        // setError(error)
      });
  };
  //
  // handling load more data
  const handleLoadMoreData = () => {
    setLoading(false);
    axios
      .post(
        `${API_CONSTANTS.BASEURL_4}admin/corporates?last=${lastEvaluatedKey}`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var patientListNextPage = response.data.response.Items;
        setpatientList([...patientList, ...patientListNextPage]);
        setPage(page + 1);
      })
      .catch((error) => {
        // if (error.response.status === 401) {
        //   window.location.reload();
        //   window.localStorage.clear();
        // }
        setLoading(true);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Company Name",
        accessor: "corporateName",
      },
      {
        Header: "Plan Name",
        accessor: (property) =>
          property.patientInfo.planName ? property.patientInfo.planName : "N/A",
      },

      {
        Header: "Date of Payment",
        accessor: (property) => moment(property.dateTime).format("MMM D, YYYY"),
      },
      {
        Header: "Instrument Type",
        accessor: (item) => item.paid_through,
      },

      {
        Header: "Instrument Number",
        accessor: (item) => item.transactionId,
      },
      {
        Header: "Payment",
        accessor: (item) => item.amount,
      },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  // rendering API
  useEffect(() => {
    if (patientList.length < 1) {
      userList();
    }
  }, []);
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 404"
            ? "This record does not exist"
            : error.message === "Request failed with status code 401"
            ? is_SessionExpire()
            : error.message
        }
      />
    );
  } else {
    return (
      <>
        <section className={style.corporatePageSection}>
          {/* corporate table listing  */}
          <div className="row rounded bg-white m-4">
            <h3
              style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}
            >
              All Corporate Transactions
              {!isLoading && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "#24AAA4" }}
                />
              )}
            </h3>
            <Table
              pageNumber={page}
              columns={columns}
              data={patientList}
              routingLink={``}
              pageCount={pageCount}
              fetchData={fetchData}
              loadMoreList={handleLoadMoreData}
              lastEvaluatedKey={lastEvaluatedKey}
              loading={loading}
              recordDownload={true}
              setLoading={setLoading}
            />
          </div>
        </section>
      </>
    );
  }
}
/////----------- activate plans/ deactivate plans --///
