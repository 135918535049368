/*
Author:Umer shabir,
revised date:06-12-20223,
file: is about consultations profile,  
*/
// dependencies
import React, { useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import { Outlet } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getConsultation } from "./overview/consultationOverviewSlice";
import { Link } from "react-router-dom";

//
export default function ConsultationProfilePage() {
  // variables
  const params = useParams();
  const dispatch = useDispatch();
  const { value } = useSelector((state) => state.getConsultationProfileReducer);
  // links
  const PROFILE_NAV_LINKS = [
    {
      navLink: "Overview",
      navRoute: `/consultations/profile/${params.id}/overview`,
    },
    {
      navLink: "e-Prescription",
      navRoute: `/consultations/profile/${params.id}/e-prescription`,
    },
    {
      navLink: "Recording",
      navRoute: `/consultations/profile/${params.id}/recording`,
    },
    {
      navLink: "Consultation History",
      navRoute: `/consultations/profile/${params.id}/calllogs`,
    },
    {
      navLink: "Reviews",
      navRoute: `/consultations/profile/${params.id}/reviews`,
    },
  ];
  // hook
  useEffect(() => dispatch(getConsultation(params.id)), [params.id, dispatch]);
  return (
    <>
      {value.message === "No Record found.." && (
        <div className="loading">There is no record</div>
      )}
      <div className="container-fluid profile-header">
        {/* header */}
        <div className="row pt-4 mb-1 medi">
          <div className="col">
            <div className="d-flex ms-4">
              <Link
                to={`/patients/profile/${value?.userId}/overview`}
                className="patient-link"
              >
                <div className="patient d-flex ms-4 align-items-start me-4">
                  <span className="me-4">
                    <div className="patient-avatar">
                      <img
                        src={
                          value.patientInfo?.url ||
                          "https://img.icons8.com/material/72/000000/user-male-circle--v1.png"
                        }
                        alt=""
                        style={{ width: 72, borderRadius: 50, height: 72 }}
                      />
                    </div>
                  </span>
                  <div className="d-flex flex-column mt-2">
                    <h5
                      style={{
                        fontWeight: 500,
                        marginBottom: 15,
                        marginTop: -4,
                      }}
                    >
                      {value.patientInfo?.name}
                    </h5>
                    <h6
                      style={{
                        fontWeight: 400,
                        color: "#5C5C5C",
                        marginBottom: 0,
                      }}
                    >
                      {"Patient ID " + value.patientInfo?.phone}
                    </h6>
                  </div>
                </div>
              </Link>
              <span className="border-line"></span>
              <Link
                to={`/doctors/profile/${value?.doctorId}/overview`}
                className="patient-link"
              >
                <div className="doctor d-flex ms-4 align-items-start">
                  <span className="me-4">
                    <div className="patient-avatar">
                      <img
                        src={value.doctorInfo?.profileUrl}
                        alt=""
                        style={{ width: 72, borderRadius: 50, height: 72 }}
                      />
                    </div>
                  </span>
                  <div className="d-flex flex-column mt-2">
                    <h5
                      style={{
                        fontWeight: 500,
                        marginBottom: 15,
                        marginTop: -4,
                      }}
                    >
                      {value.doctorInfo?.title +
                        " " +
                        value.doctorInfo?.firstName +
                        " " +
                        value.doctorInfo?.lastName}
                    </h5>
                    <h6
                      style={{
                        fontWeight: 400,
                        color: "#5C5C5C",
                        marginBottom: 0,
                      }}
                    >
                      {"Doctor ID " + value.doctorInfo?.pmdc}
                    </h6>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* links navbar */}
        <div className="row pt-4 media">
          <div className="col">
            <div className="d-flex ms-4" style={{ columnGap: 10 }}>
              {PROFILE_NAV_LINKS.map((navItem) => (
                <NavLink
                  to={navItem.navRoute}
                  key={navItem.navLink}
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  style={{ fontSize: 14 }}
                >
                  {navItem.navLink}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* outlet to render children routes */}
      <div className="containter-fluid">
        <Outlet />
      </div>
      {/* styling  */}
      <style jsx="true">
        {`
          .profile-header {
            background: #e0f3f2;
            height: 160px;
          }
          .active {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .active:hover {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .inactive {
            padding: 7px 15px;
          }
          .border-line {
            border-left: 2px solid #009b94;
            border-radius: 5px;
          }
          .patient-link:hover {
            background-color: #c8efe1 !important;
          }
          @media screen and (max-width: 680px) {
            .medi {
              margin-bottom: 0px !important;
              padding-top: 0px !important;
            }
            .media {
              padding-top: 0px !important;
            }
          }
        `}
      </style>
    </>
  );
}
