import React from 'react'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import style from '../../addData.module.css'
import axios from 'axios'
import { useDispatch } from 'react-redux'
export default function Delete({ name }) {
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch()
  const deletingData = () => {
    setLoading(true)

    axios
      .delete(`${API_CONSTANTS.BASEURL_1}admin/settings`, {
        headers: API_CONSTANTS.headers(),
        data: {
          type: 'Conditions',
          name: name,
        },
      })
      .then((res) => {
        dispatch({
          type: 'getDataSlice/deleteConditions',
          payload: name,
        })
        setLoading(false)
      })
      .catch((error) => {
        alert(error.response.data.message)
        setLoading(false)
      })
  }
  return (
    <>
      <button className={style.button + ' ' + 'btn'} onClick={deletingData}>
        {loading ? (
          <div
            className='spinner-border spinner-border-sm'
            role='status'
            style={{ color: '#FF6760' }}
          >
            <span className='sr-only'>Loading...</span>
          </div>
        ) : (
          <i className='fas fa-trash-alt' style={{ color: '#FF6760' }}></i>
        )}
      </button>
    </>
  )
}
