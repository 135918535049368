import React, { useState, useEffect, useReducer } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import axios from 'axios'

import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { getProfile } from './patientProfileSlice'
import toArrayBuffer from 'to-array-buffer'
import { API_CONSTANTS } from '../../../API_CONSTANTS'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../Components/Toast/Toast'
//
//

// edit profile button component
export default function EditProfileButton() {
  // getting redux state
  const { value } = useSelector((state) => state.getPatientProfileReducer)
  // variables declarations
  const params = useParams()
  const dispatch = useDispatch()
  const profileId = value.profiles?.self.profileId

  // ------------------ toast states
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('') //  using this state for show the toast message
  const [ToastIcon, setToastIcon] = useState(faCheckCircle) // using this state for changing the toast message icon color
  const [iconColor, setIconColor] = useState('') // using this state for changing the toast message icon color

  const [file, setFile] = useState(
    'https://img.icons8.com/material/72/000000/user-male-circle--v1.png'
  )
  const formReducer = (state, event) => {
    if (event.update) {
      return {
        name: value.Fname,
        dob: value.dob ? value.dob : undefined,
        gender: value.gender ? value.gender : undefined,
        location: value.profiles.self.location ? value.profiles.self.location : undefined,
        url: file,
        relation: 'Myself',
      }
    } else if (event.image) {
      return {
        name: value.Fname,
        dob: value.dob ? value.dob : undefined,
        gender: value.gender ? value.gender : undefined,
        location: value.profiles.self.location ? value.profiles.self.location : undefined,
        relation: 'Myself',
        url: file,
      }
    } else
      return {
        ...state,
        [event.target.name]: event.target.value,
        url: file,
      }
  }
  // profile form data
  const [formData, setFormData] = useReducer(formReducer, {
    name: value?.Fname,
    dob: value.dob ? value.dob : undefined,
    gender: value.gender ? value.gender : undefined,
    location: value.profiles?.self?.location ? value.profiles?.self?.location : undefined,
    relation: 'Myself',
  })
  const { name, dob, gender, relation, location } = formData
  // modal states and functions
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  // showing and uploading image
  const uploadFile = (e) => {
    const [file] = e.target.files || e.dataTransfer.files;
    // preview img
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    //preview onload function.
    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result);
      const base64Image = toArrayBuffer(fileRes);
      axios
        .post(
          `${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${params.id}/type/new`,
          base64Image,
          {
            headers: API_CONSTANTS.imgheaders(),
          }
        )
        .then((response) => {
          setFile(response.data.response.url);
          setFormData({ image: true, url: response.data.response.url });
        })
        .catch((e) => alert(e));
    };
  };

  // submiting the form
  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if the image has been changed
    const isImageChanged = file !== value.profiles.self.url;

    if (
      !isImageChanged &&
      name === value.Fname &&
      dob === value.dob &&
      location === value.location &&
      gender === value.gender
    ) {
      setShow(false);
    } else {
      const updatedFormData = { ...formData };

      if (isImageChanged) {
        updatedFormData.url = file;
      }

      axios
        .patch(
          `${API_CONSTANTS.BASEURL}admin/patients/${params.id}/profile/${profileId}`,
          updatedFormData,
          {
            headers: API_CONSTANTS.headers(),
          }
        )
        .then((res) => {
          setShow(false);
          handleToast(
            res.data.response.message,
            faCheckCircle,
            '#009B94',
            'show-success'
          );
        })
        .catch((error) => {
          handleToast(
            error.data.response.message,
            faTimesCircle,
            '#B00020',
            'show-danger'
          );
          if (error.response.status === 401) {
            window.location.reload();
            window.localStorage.clear();
          }

          setShow(false);
        });

      setTimeout(() => dispatch(getProfile(params.id)), 2000);
    }
  };
  // updating the state of profile form while it is in redux so we need to call it in useEffect()
  useEffect(() => {
    setFile(
      value.profiles?.self?.url
        ? value.profiles?.self?.url
        : 'https://img.icons8.com/material/72/000000/user-male-circle--v1.png'
    )
  }, [value])
  useEffect(() => {
    setFormData({ update: true })
  }, [value])
  // return
  // ---------------- Handle Toast function
  // --------  This function is for call the Toast message  ------

  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  return (
    <>
      <button className='btn custom-button edit-button' onClick={handleShow}>
        <FontAwesomeIcon icon={faCog} className={'edit-icon me-2'} />
        Edit Profile
      </button>
      <Modal show={show}>
        <Modal.Body className='d-flex flex-column'>
          <img src={file} className='align-self-center avatar' alt='avatar' />
          <div className='upload-image'>
            <button
              style={{
                display: 'block',
                fontSize: 25,
                color: '#79E80E',
                padding: 0,
              }}
              onClick={() => document.getElementById('getFile').click()}
              className='btn'
            >
              <i className='fas fa-camera'></i>
            </button>
          </div>
          <form className='d-flex flex-column' >
            <input
              type='file'
              id='getFile'
              style={{ display: 'none' }}
              onChange={uploadFile}
              accept='image/*'
            />
            <label htmlFor='name'> Name</label>
            <input
              type='text'
              name='name'
              className='custom-input-field '
              onChange={setFormData}
              value={name?.charAt(0).toUpperCase() + name?.slice(1)}
              required
            />
            <label htmlFor='name'>Location </label>
            <input
              type='text'
              name='location'
              className='custom-input-field '
              onChange={setFormData}
              value={location?.charAt(0).toUpperCase() + location?.slice(1)}
              required
            />

            <label htmlFor='DateofBirth'> Date of Birth</label>
            <input
              type='date'
              name='dob'
              className='custom-input-field '
              onChange={setFormData}
              value={dob}
              required
            />
            <label htmlFor='relation'>Relation</label>
            <select
              name='relation'
              className='custom-input-field '
              onChange={setFormData}
              value={relation}
            >
              <option value='Myself' selected>
                Myself
              </option>
            </select>
            <label htmlFor='Gender'>Gender</label>
            <select
              name='gender'
              className='custom-input-field '
              onChange={setFormData}
              value={gender}
              required
            >
              <option value=''>choose an option</option>
              <option value='Female'>Female</option>
              <option value='Male'>Male</option>
              <option value='Other'>Other</option>
            </select>

            <span style={{ marginTop: '10px' }} className='align-self-stretch'>
              <button type='submit' className='btn btn-save w-100' onClick={handleSubmit}>
                Save Changes
              </button>
            </span>
          </form>
          <button className='btn btn-outline-danger mt-2' onClick={handleClose}>
            Discard
          </button>
        </Modal.Body>
      </Modal>

      <style jsx='true'>
        {`
          .edit-button {
            border: 2px solid #009b94;
            color: #009b94;
          }
          .edit-button:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-save {
            background: #009b94 !important;
            color: #fff;
            padding: 10px 10px !important;
          }
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
          }
          .custom-input-field {
            width: 100%;
            border: none;
            border-bottom: 1px solid #cccccc;
            margin: 10px 0;
          }
          .custom-input-field:focus-visible {
            outline: none;
            border-bottom: 1px solid #009b94;
          }
          label {
            font-size: 12px;
            color: #8e8e8e;
          }
          .error-message {
            font-size: 13px;
            color: red;
          }
          .avatar {
            vertical-align: middle;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-bottom: 30px;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
          }
          .upload-image {
            position: absolute;
            top: 101px;
            left: 292px;
          }
        `}
      </style>

      {/* Importing the Toast Component  */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}
