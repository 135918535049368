import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import RescheduleAppoint from './Reschedule'
import Toast from '../../../../../Components/Toast/Toast'
import FallowUp from './FallowUp'
import { Link } from 'react-router-dom'
//
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
} from '@fortawesome/fontawesome-free-solid'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
import CancelAppoint from './CancelAppoint'
//
export default function AppointmentStatus() {
  const [Createspin, setCreateSpin] = useState('')

  const { value } = useSelector((state) => state.getConsultationProfileReducer)
  //
  const params = useParams()

  const [ToastStates, setToastStates] = useState({
    message: '',
    show: '',
    color: '',
    icon: faTimesCircle,
  })
  // --------  This function is for call the Toast message  ------
  const handleToast = (message, icon, iconColor, className) => {
    setToastStates({
      ...ToastStates,
      icon: icon,
      message: message,
      color: iconColor,
      show: `show ${className}`,
    })
    setTimeout(() => setToastStates({ ...ToastStates, show: '' }), 2900)
  }
  //
  const handleReminder = () => {
    axios
      .get(
        API_CONSTANTS.CONSULTATION_APPOINMENTSENDREMINDER(value.appointmentId),
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        handleToast(
          res.data.response.message,
          faCheckCircle,
          '#ffffff',
          'show-success'
        )
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        handleToast(
          error.data.response.message,
          faTimesCircle,
          '#ffffff',
          'show-danger'
        )
      })
  }
  //

  const [status, setStatus] = useState(value.appointmentInfo.appointment_status)

  useEffect(() => {
    setStatus(value.appointmentInfo.appointment_status)
  }, [value])

  const handle_Cancel_Appointment = (reason, handleClose) => {
    setCreateSpin('spinner-border spinner-border-sm')
    const data = { reason: reason }
    axios
      .patch(API_CONSTANTS.CONSULTATION_APPOINMENTCANCEL(params.id), data, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setCreateSpin('')
        setStatus('cancelled')
        handleToast(
          res.data.response.message,
          faCheckCircle,
          '#ffffff',
          'show-success'
        )
        handleClose()
      })
      .catch((e) => {
        setCreateSpin('')
        handleToast(
          e.data.response.message,
          faExclamationTriangle,
          '#ffffff',
          'show-info'
        )
      })
  }
  //
  const appointmentTime = moment(value.appointmentInfo.appointment_date).format(
    'LLLL'
  )
  // appointment time
  const appointmentTimeToCompare = moment(
    value.appointmentInfo.appointment_date
  ).valueOf()
  // current time
  const currentTime = Date.now()
  console.log(appointmentTimeToCompare)
  console.log(currentTime)
  console.log(status)

  return (
    <>
      <div className='row bg-white rounded p-3'>
        <div className='col'>
          <div className='d-flex flex-column'>
            <h3
              style={{
                fontSize: 16,
                fontWeight: 500,
                textTransform: 'capitalize',
              }}
            >
              {value.appointmentInfo.appointment_type === 'Booked'
                ? 'Book Appointment: ' + value.appointmentInfo.medium
                : value.appointmentInfo.appointment_type === 'Instant'
                ? 'Instant Consultation: ' + value.appointmentInfo.medium
                : 'Follow Up: ' + value.appointmentInfo.medium}

              <span className='ms-3'>
                {value.appointmentInfo.follow_appointment_id !== ' ' ? (
                  <Link
                    to={`/services/profile/${value.appointmentInfo.follow_appointment_id}/overview`}
                    className='appointLink'
                  >
                    {' '}
                    follow up
                  </Link>
                ) : value.appointmentInfo?.parent_appointment_id ? (
                  <Link
                    to={`/services/profile/${value.appointmentInfo?.parent_appointment_id}/overview`}
                    className='appointLink'
                  >
                    {' '}
                    see appointment
                  </Link>
                ) : (
                  ''
                )}
              </span>
            </h3>
            <table
              className='appointment-status'
              style={{ fontSize: 14, color: '#8E8E8E', fontWeight: 400 }}
            >
              <thead></thead>
              <tbody>
                <tr>
                  <th>Appointment Date:</th>
                  <td>{appointmentTime}</td>
                </tr>
                <tr>
                  <th>Booked on:</th>
                  <td>
                    {moment(
                      value.appointmentInfo.booked_on.slice(0, 10)
                    ).format('DD-MM-YYYY')}
                  </td>
                </tr>
                <tr>
                  <th>Amount Paid: </th>
                  <td>
                    Rs: {value.appointmentInfo.charges}.00{' '}
                    <i
                      className='fas fa-info-circle'
                      style={{ color: '#009B94' }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Patient Name:</th>
                  <td>
                    <img
                      alt={'profile img'}
                      src={value.appointmentInfo.profileUrl}
                      width={30}
                      height={30}
                      style={{
                        borderRadius: '30px',
                      }}
                    />
                    <span className='ms-2'>
                      {`${value.appointmentInfo.name} (${value.appointmentInfo.relation})`}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className='col'>
          <div
            className='d-flex flex-column justify-content-between'
            style={{ height: '100%' }}
          >
            <div className='d-flex justify-content-end align-items-center'>
              <span>
                {status === 'booked' &&
                  appointmentTimeToCompare >= currentTime && (
                    <button
                      style={{
                        border: 'none',
                        background: 'none',
                        marginRight: 8,
                        color: '#8E8E8E',
                      }}
                      className='send-reminder'
                      onClick={handleReminder}
                    >
                      Send Reminder
                    </button>
                  )}
              </span>
              <span
                className={status}
                style={{
                  padding: '3px 12px',
                  borderRadius: '20px',
                  textTransform: 'capitalize',
                }}
              >
                {status === 'booked' ? 'Upcoming' : status}
              </span>
            </div>
            <div className='d-flex justify-content-end '>
              <span className={'mx-1'}>
                {status === 'completed' &&
                  value.appointmentInfo.follow_appointmentId === ' ' && (
                    <FallowUp />
                  )}
              </span>
              <span className={'mx-1'}>
                {status === 'missed' || status === 'booked' ? (
                  <RescheduleAppoint />
                ) : (
                  ''
                )}
              </span>
              <span>
                {/* {status === 'booked' &&
                  appointmentTimeToCompare >= currentTime && (
                    <button
                      className='rounded hover-cancel'
                      onClick={handle_Cancel_Appointment}
                    >
                      Cancel
                      <span className={Createspin} />
                    </button>
                  )} */}
                <CancelAppoint
                  status={status}
                  appointmentTimeToCompare={appointmentTimeToCompare}
                  currentTime={currentTime}
                  handle_Cancel_Appointment={handle_Cancel_Appointment}
                  Createspin={Createspin}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <Toast
        cName={ToastStates.show}
        icon={ToastStates.icon}
        color={ToastStates.color}
        text={ToastStates.message}
      />
      <style jsx='true'>
        {`
          .appointLink {
            font-size: 11px;
            color: #009b93;
            text-decoration: underline !important;
          }
          .send-reminder:hover {
            text-decoration: underline;
          }

          .hover-cancel {
            background: #fff;
            border: 1px solid #b00020;
            color: #b00020;
            padding: 8px 15px;
            fontweight: 500;
          }
          .hover-cancel:hover {
            background: #b00020;
            color: #fff;
            transition: all ease 0.2s;
          }
          .appointment-status td {
            text-align: left;
            padding: 5px;
          }
          .appointment-status tr {
            border: none;
            padding: 5px;
          }
          .appointment-status th {
            padding: 5px 0;
          }
          .cancelled {
            background: #ffe0e0;
            color: Red;
          }
          .booked {
            background: #d9f7e7a6;
            color: Green;
          }
          .missed {
            background: #ffe0e0;
            color: Red;
          }
          .reserved {
            background: #fff6e9;
            color: Orange;
          }
          .reschedule {
            background: #00b8af1a;
            color: #009b94;
          }
          .InProgress {
            background: #e0f3fb;
            color: #00568a;
          }
          .completed {
            background: #d9f7e7a6;
            color: #1a9452;
          }
        `}
      </style>
    </>
  )
}
