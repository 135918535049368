/* eslint-disable react-hooks/exhaustive-deps */

import Spinner from "react-bootstrap/Spinner";
import React from "react";
import { useEffect, useRef } from "react";
import FilterSideBarModal from "./FilterSideBarModal";
// react table importing from react table library
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table/dist/react-table.development";
import GlobalTableFilter from "./GlobalTableFilter";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";
// import { useDispatch } from 'react-redux'

function Table({
  recordDownload = false,
  columns,
  onDownloadBtn,
  data,
  fetchData,
  manualPagination = false,
  routingLink,
  loadMoreList,
  lastEvaluatedKey,
  loading,
  setLoading,
  FilterForm,
  pageNumber,
}) {
  const history = useNavigate();
  // const dispatch = useDispatch()
  // properties extracting from useTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // routing for admin profile function
  const handleRowClick = (row) => {
    if (routingLink !== "") {
      // if (row.original.appoint_start_time) {
      //   dispatch({
      //     type: 'appointmentOverViewSlice/overViewValues',
      //     payload: row.original,
      //   })
      // }
      history(
        `${routingLink}/${
          row.original.Id ? row.original.Id : row.original.appointmentId
        }/overview`
      );
    }
  };
  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize });
    setPageSize(data.length > 0 ? data.length : 1);
  }, [fetchData, pageIndex, pageSize]);
  // Load More function
  useEffect(() => {
    gotoPage(pageNumber);
  }, [pageNumber]);
  // console.log(data);
  const handleLoadMore = () => {
    setLoading(false);
    loadMoreList();
  };
  // to export file into excel file
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "table",
    sheet: "list",
  });

  // for printing the table
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div className="d-flex w-100 justify-content-between px-3">
        <GlobalTableFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <FilterSideBarModal Filter={FilterForm} />
      </div>
      <div className="table-container" style={{ overflowX: "auto" }}>
        <table
          {...getTableProps()}
          style={{
            font: "poppins",
            fontWeight: "400",
            fontSize: "14px",
            minWidth: "100%",
            width: "max-content",
          }}
          ref={tableRef}
        >
          <table className="w-100" ref={componentRef}>
            <thead>
              {headerGroups.map((headerGroup, indx) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  ref={tableRef}
                  key={indx}
                >
                  {headerGroup.headers.map((column, indx) => (
                    <th
                      key={indx}
                      {...column.getHeaderProps()}
                      style={{ fontWeight: "600" }}
                    >
                      {column.render("Header")}

                      {column.render("Header") !== " " ? (
                        <span
                          className="mx-2"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps
                          )}
                          style={{ color: "#99d7d4" }}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className="fas fa-sort-alpha-down-alt print-class"></i>
                            ) : (
                              <i className="fas fa-sort-alpha-up print-class"></i>
                            )
                          ) : (
                            <i className="fas fa-sort print-class"></i>
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, indx) => {
                prepareRow(row);
                return (
                  <>
                    <tr
                      key={indx}
                      {...row.getRowProps()}
                      className="border-top border-bottom table-row "
                      onClick={() => handleRowClick(row)}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <td
                              {...cell.getCellProps()}
                              style={{
                                textTransform: "capitalize",
                                width: cell.column.id === " " && "50px",
                              }}
                            >
                              {cell.render("Cell")}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
              {/* <tr>
                <td colSpan="10000">
                  Showing {page.length} of ~{pageSize} results
                </td>
              </tr> */}
            </tbody>
          </table>
        </table>
      </div>
      {/*pagination UI*/}
      <div className="pagination d-flex justify-content-between align-items-center w-100">
        <div className="mx-1">
          {/* <span className="mx-1" style={{ color: "#8E8E8E", fontSize: "14px" }}>
            Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              className="pagination-filter"
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "50px" }}
            />
          </span> */}
          {/* <select
            value={pageSize}
            className="pagination-filter mx-1"
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ color: "#8E8E8E", fontSize: "14px" }}
          >
            {[5, 10, 20, 30].map((pageSize) => (
              <option
                key={pageSize}
                value={pageSize}
                style={{ color: "#8E8E8E" }}
              >
                Show {pageSize}
              </option>
            ))}
          </select> */}
          {/* <span className="mx-1" style={{ color: "#8E8E8E", fontSize: "14px" }}>
            Page
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span> */}
          {/* <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className={
              (canPreviousPage || "button-disabled") + " mx-1 pagination-button"
            }
          >
            <i className="fas fa-angle-double-left"></i>
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={
              (canPreviousPage || "button-disabled") + " mx-1 pagination-button"
            }
          >
            <i className="fas fa-chevron-left"></i>
          </button>{" "}
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={
              (canNextPage || "button-disabled") + " mx-1 pagination-button"
            }
          >
            <i className="fas fa-chevron-right"></i>
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className={
              (canNextPage || "button-disabled") + " mx-1 pagination-button"
            }
          >
            <i className="fas fa-angle-double-right"></i>
          </button>{" "} */}
          <button
            className="btn mx-2 "
            style={{ background: "#009B94", color: "#fff" }}
            onClick={handleLoadMore}
            disabled={!lastEvaluatedKey}
          >
            {loading || (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginLeft: "6px" }}
              />
            )}
            {loading && "Load more"}
          </button>
        </div>
        <div className="d-flex justify-content-between">
          <button
            className="btn mx-2"
            style={{ background: "#fff", color: "#fff" }}
            onClick={recordDownload ? onDownloadBtn : onDownload}
          >
            <i
              className="fas fa-download mx-2"
              style={{ color: "#009B94" }}
            ></i>
            <span style={{ color: "#009B94" }}>Export</span>
          </button>
          <button
            className="btn "
            style={{ background: "#fff", color: "#fff" }}
            onClick={handlePrint}
          >
            <i className="fas fa-print mx-2" style={{ color: "#009B94" }}></i>{" "}
            <span style={{ color: "#009B94" }}>Print</span>
          </button>
        </div>
      </div>
      {/*I used JSX styling for CSS, It prevent conflict and you don't have to create new CSS file, and get rid from importing*/}
      <style jsx="true">{`
        th {
          padding: 12px;
          background-color: #fcfcfd;
        }
        td {
          padding: 12px;
        }
        .table-row:hover {
          box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
            0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
          cursor: pointer;
        }
        .pagination {
          height: 56px;
        }
        .pagination-button {
          background: none;
          color: #009b94;
          font-weight: bold;
          border: none;
          padding: 5px 15px;
        }
        .pagination-button:focus {
          outline: none;
        }
        .pagination-button:active {
          transform: scale(1.3);
        }
        .pagination-button:hover {
          background: #eee;
          border-radius: 50px;
          padding: 8px 15px;
        }
        .pagination-filter {
          padding: 10px;
          outline: none;
          border: none;
          color: #8e8e8e;
          font-size: 14px;
        }
        .button-disabled {
          color: #999;
        }
        @media print {
          .print-class {
            display: none;
          }
        }
        @media only screen and (max-width: 1330px) {
          th {
            padding: 12px;
          }
          td {
            padding: 12px;
          }
          .pagination {
            height: 50px;
          }
        }
        @media only screen and (max-width: 1230px) {
          .pagination {
            height: 50px;
          }
          .pagination-button {
            border: none;
            padding: 5px;
            margin: 0px;
          }
          .pagination-button:hover {
            padding: 5px;
          }
          .pagination-filter {
            padding: 5px;
            font-size: 12px;
          }
        }
        @media only screen and (max-width: 1130px) {
          .pagination {
            height: 75px;
          }
        }
      `}</style>
    </>
  );
}

export default Table;
