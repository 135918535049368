import { createSlice } from '@reduxjs/toolkit'
//

export const ServicesFilterSlice = createSlice({
  name: 'servicesFilter',
  initialState: {
    value: '',
    value1: '',
  },
  reducers: {
    all: (state, action) => {
      state.value1 = ''
    },
    completed: (state, action) => {
      state.value1 = 'completed'
    },
    InProgress: (state, action) => {
      state.value1 = 'InProgress'
    },
    Assigned: (state, action) => {
      state.value1 = 'Assigned'
    },
    pending: (state, action) => {
      state.value1 = 'pending'
    },
    videoCall: (state, action) => {
      state.value = ''
    },
    Booked: (state, action) => {
      state.value = 'Booked'
    },
    Instant: (state, action) => {
      state.value = 'Instant'
    },
    FollowUp: (state, action) => {
      state.value = 'FollowUp'
    },
  },
})

export const ServicesFilterReducer = ServicesFilterSlice.reducer
