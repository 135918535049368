import React, { useEffect, useReducer } from 'react'
import { useParams } from 'react-router'
import axios from 'axios'
import moment from 'moment'

import Spinner from 'react-bootstrap/Spinner'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'

//
const notificationsReducer = (state, action) => {
  switch (action.type) {
    case 'NOTIFICATION_FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case 'NOTIFICATION_FETCH_INIT_MORE_TRUE': {
      return {
        ...state,
        moreLoading: true,
      }
    }
    case 'NOTIFICATION_FETCH_INIT_MORE_FALSE': {
      return {
        ...state,
        moreLoading: false,
      }
    }
    case 'NOTIFICATION_FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload.list,
        token: action.payload.key,
      }
    case 'NOTIFICATION_FETCH_MORE':
      return {
        ...state,
        moreLoading: false,
        isError: false,
        data: state.data.concat(action.payload.list),
        token: action.payload.key,
      }
    case 'NOTIFICATION_FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorData: action.payload,
      }
    default:
      throw new Error()
  }
}
//
function PatientNotifications() {
  const [notifications, dispatchNotifications] = useReducer(
    notificationsReducer,
    {
      data: [],
      isLoading: false,
      token: '',
      isError: false,
      errorData: {},
      moreLoading: false,
    }
  )
  // variables
  const { data, isLoading, isError, errorData, token, moreLoading } =
    notifications
  const params = useParams()
  // load more notifications
  function LoadMore() {
    dispatchNotifications({ type: 'NOTIFICATION_FETCH_INIT_MORE_TRUE' })
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/users/notificaiton/${params.id}?last=${token}`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((result) =>
        dispatchNotifications({
          type: 'NOTIFICATION_FETCH_MORE',
          payload: {
            list: result.data.response.Items,
            key: result.data.response?.LastEvaluatedKey,
          },
        })
      )
      .then(() =>
        dispatchNotifications({ type: 'NOTIFICATION_FETCH_INIT_MORE_FALSE' })
      )
      .catch((error) =>
        dispatchNotifications({
          type: 'NOTIFICATION_FETCH_FAILURE',
          payload: error.response.data.response.message,
        })
      )
  }
  // fetching notification
  const handleFetchNotifications = () => {
    dispatchNotifications({
      type: 'NOTIFICATION_FETCH_INIT',
    })
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/users/notificaiton/${params.id}`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        dispatchNotifications({
          type: 'NOTIFICATION_FETCH_SUCCESS',
          payload: {
            list: res.data.response.Items,
            key: res.data.response.LastEvaluatedKey,
          },
        })
        console.log(res)
      })
      .catch((error) => {
        dispatchNotifications({
          type: 'NOTIFICATION_FETCH_FAILURE',
          payload: error.response.data.response.message,
        })
      })
  }
  //

  // useEffect
  useEffect(() => {
    handleFetchNotifications()
  }, [])

  return (
    <>
      <div
        className='row m-4 rounded p-2'
        style={{ backgroundColor: '#F9F9F9' }}
      >
        <div className='col-8'>
          <div className='d-flex flex-column '>
            {/* <span>
              <select name='' id=''>
                <option value=''>All</option>
                <option value=''>Upcoming</option>
                <option value=''>Completed</option>
                <option value=''>Cancelled</option>
                <option value=''>Missed</option>
                <option value=''>In Progress</option>
                <option value=''>Follow up</option>
              </select>
            </span> */}
            {isLoading && (
              <div className='d-flex justify-content-center mt-4 w-100'>
                <Spinner animation='border' style={{ color: '#24AAA4' }} />
              </div>
            )}
            {(data ? data : []).map((item, index) => (
              <div
                className='d-fex flex-column p-3 rounded my-2 notification'
                key={index}
              >
                <div className='d-flex justify-content-between align-items-center'>
                  <span className='d-flex align-items-center'>
                    <img
                     src={(item?.url === " " || item?.url === "None") ?
                     'https://e7.pngegg.com/pngimages/178/595/png-clipart-user-profile-computer-icons-login-user-avatars-monochrome-black-thumbnail.png'
                     : item?.url}
                      alt=''
                      className='rounded'
                      style={{ width: 30, height: 30, borderRadius: '50%' }}
                    />
                    <h6
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        textTransform: 'uppercase',
                        color: '#3F3F3F',
                        marginLeft: 5,
                        marginBottom: 0,
                      }}
                    >
                      {item.name}
                    </h6>
                  </span>
                  <p style={{ color: '#3F3F3F' }}>
                    {moment(item.created_at).startOf('minute').fromNow()}
                  </p>
                </div>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    marginBottom: 0,
                  }}
                >
                  {item.title}
                </p>
                <p
                  style={{
                    fontSize: 15,
                    fontWeight: 400,
                    textTransform: 'initial',
                    marginBottom: 0,
                  }}
                >
                  {item.body}
                </p>
              </div>
            ))}
          </div>
          <button
            type='button'
            className='btn d-flex btn-dark align-items-center '
            style={{ background: '#009B94', border: '#009B94' }}
            onClick={LoadMore}
            disabled={!token}
          >
            Load more
            {moreLoading && (
              <div
                className='spinner-border spinner-border-sm ms-1'
                role='status'
              ></div>
            )}
          </button>
        </div>
      </div>

      <style jsx='true'>{`
        .notification {
          background-color: #fff;
          transition: all ease-in 0.1s;
        }
        .notification:hover {
          background-color: rgba(0, 155, 148, 0.08);
          border-left: 5px solid #009b94;
          transition: all ease-in 0.1s;
        }
      `}</style>
    </>
  )
}

export default PatientNotifications
