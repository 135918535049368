import React, { useMemo, useEffect, useState } from 'react'
import Table from '../../../../../Components/Table/index'
import axios from 'axios'
import AppointmentFilterForm from './AppointmentFilterForm'
import { useSelector } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import AlertMessage from '../../../../../Components/AlertMessage'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
//
export default function Appointments() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [appointmentList, setappointmentList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0)
  const [page, setPage] = useState(0)
  //
  const params = useParams()
  //
  const filterValue = useSelector((state) => state.appointmentFilterReducer)
  //
  const filter_status = {
    appointment_status: filterValue.value,
    appoint_date_from: filterValue.value1,
    appoint_date_to: filterValue.value2,
    created_at: filterValue.value3,
  }
  //
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setappointmentList(appointmentList.slice(startRow, endRow))

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(appointmentList.length / pageSize))
      }
    },
    [appointmentList, lastEvaluatedKey]
  )
  //
  const handleFilters = () => {
    let filterData = {
      appointment_status: `${filter_status.appointment_status}`,
      appoint_date_from: `${filter_status.appoint_date_from}`,
      appoint_date_to: `${filter_status.appoint_date_to}`,
      created_at: `${filter_status.created_at}`,
    }
    if (Object.values(filterData).every((key) => key === '')) {
      return {}
    } else
      return Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== '')
      )
  }
  // API Hatching data of doctor appointments
  const userList = () => {
    axios
      .post( `${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/appointments`, handleFilters(), {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setIsLoading(true)
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
        setappointmentList(
          res.data.response.Items ? res.data.response.Items : []
        )
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setIsLoading(true)
        setError(error)
      })
  }

  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/appointments?last=${lastEvaluatedKey}`,
        handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
        var appointmentListNextPage = response.data.response.Items
        setappointmentList([...appointmentList, ...appointmentListNextPage])
        setPage(page + 1)
      })
      .then(() => setLoading(true))
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Appointment Type',
        accessor: 'appoint_type',
      },
      {
        Header: 'Patient Name',
        accessor: 'patientInfo.name',
        Cell: ({ cell: { value } }) => value || 'N/A',
      },
      {
        Header: 'Status',
        accessor: (property) => {
          const status = property.appointment_status.replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase()
          })
          if (status === 'Reschedule') {
            return 'Rescheduled'
          }
          return status
        },
      },
      {
        Header: 'Appointment Date/time',
        accessor: (a) => moment(a.appoint_date).format('DD-MM-YYYY/h:mm A'),
      },

      {
        Header: 'Booked On',
        accessor: (a) => moment(a.created_at).format('DD-MM-YYYY'),
      },
    ],
    []
  )
  const is_SessionExpire = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  useEffect(() => {
    userList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter_status.appoint_date_to,
    filter_status.appoint_date_from,
    filter_status.appointment_status,
    filter_status.created_at,
  ])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 404'
            ? is_SessionExpire()
            : error.message
        }
      />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center mt-4'>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <div className='row rounded bg-white m-4'>
        <div>
          <h3 style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}>
            All Appointments
          </h3>
        </div>
        <Table
          pageNumber={page}
          columns={columns}
          data={appointmentList}
          routingLink={'/consultations/profile'}
          pageCount={pageCount}
          fetchData={fetchData}
          loadMoreList={handleLoadMoreData}
          lastEvaluatedKey={lastEvaluatedKey}
          loading={loading}
          setLoading={setLoading}
          FilterForm={<AppointmentFilterForm />}
        />
      </div>
    )
  }
}
//
