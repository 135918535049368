import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { faPlus, faTimesCircle } from '@fortawesome/fontawesome-free-solid'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import edit_Icon from '../../../../../resources/images/svgs/edit.svg'
import delete_Icon from '../../../../../resources/images/svgs/delete.svg'
import { useParams } from 'react-router-dom'
import Toast from '../../../../../Components/Toast/Toast'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'

const Payment_Info_Form = (props) => {
  const params = useParams()

  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const handleClose2 = () => setShow2(false)
  const handleClose3 = () => setShow3(false)
  const stage = '4'

  const [Createspin, setCreateSpin] = useState('')
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [index_update, setIndex_update] = useState('')
  const [payment_Detailes, setPayment_Detailes] = useState([])

  // toast calling function
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }

  // Remove the payment object from array
  const handle_Remove_payment_Detailes = (e) => {
    const name = e.target.getAttribute('data-id')
    // console.log(name);
    const index = parseInt(name)
    setPayment_Detailes([
      ...payment_Detailes.slice(0, index),
      ...payment_Detailes.slice(index + 1),
    ])
  }

  // Remove the payment object from array
  const handle_update_payment_Detailes = (e) => {
    const index = e.target.getAttribute('data-id')
    // console.log(index);
    setIndex_update(index)
    setShow3(true)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ paymentMethods: payment_Detailes }}
        // validationSchema={Yup.object({})}
        onSubmit={(values) => {
          setCreateSpin('spinner-border spinner-border-sm')
          axios
            .patch(`${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/paymentInfo`, values, {
              headers: API_CONSTANTS.headers(),
            })
            .then((res) => {
              setCreateSpin('')
              // console.log(res);
              handleToast(
                res.data.response.message,
                faCheckCircle,
                'rgba(0,155,148,0.93)',
                'show-success'
              )
              setTimeout(() => props.alert(stage), 2000)
            })
            .catch((e) => {
              setCreateSpin('')
              // console.log(e);
              handleToast(
                e.response.data.response.message || 'server error',
                faTimesCircle,
                '#b20909',
                'show-danger'
              )
            })
        }}
      >
        {(formik) => {
          // console.log(overview.adminRole);
          return (
            <Form>
              <div className='row py-3 ml-5 gutter-flx'>
                <div className='col-md-8 col-sm-12 border-bottom py-2 '>
                  <div className={'d-flex justify-content-between w-100'}>
                    <span>Payment Method</span>
                    <span>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={'fa-lpus'}
                        onClick={() => setShow2(true)}
                      />
                    </span>
                  </div>

                  {formik.values.paymentMethods.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={
                            'd-flex justify-content-between w-100 py-1'
                          }
                        >
                          <div className={'col-md-10 p-0'}>
                            <h6 className={'m-0'}>{data.bank_name}</h6>
                            <p className={'m-0 sub-title-heading py-1'}>
                              {data.account_number}
                            </p>
                            <p className={'m-0 sub-title-heading '}>
                              {data.account_title}
                            </p>
                          </div>
                          <div
                            className={
                              'col-md-2 p-0 justify-content-end d-flex'
                            }
                          >
                            <span>
                              <img
                                src={edit_Icon}
                                width={15}
                                height={16}
                                data-id={index}
                                onClick={handle_update_payment_Detailes}
                                className={'mx-3'}
                                alt={'edit-icon'}
                              />
                              <img
                                src={delete_Icon}
                                width={16}
                                height={16}
                                data-id={index}
                                onClick={handle_Remove_payment_Detailes}
                                alt={'delete-icon'}
                              />
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
              <div className={'card-footer bg-white px-0 border-0'}>
                <div className={' px-2 py-4'}>
                  <button
                    className={'btn btn-save-Procced'}
                    type={'submit'}
                    // onClick={() => props.alert(stage)}
                  >
                    <span className={Createspin} />
                    Save & Proceed
                  </button>
                  {/* <button
                    className={"btn btn btn-submit mx-3 "}
                    disabled={true}
                  >
                    Submit
                  </button>
                  <button className={"btn btn-save-exit"}>Save & Exit</button> */}
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Payment Method</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              bank_name: '',
              account_title: '',
              account_number: '',
            }}
            validationSchema={Yup.object({
              bank_name: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the valid bank name'),
              account_title: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the valid bank account title'),
              account_number: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the valid bank account title'),
            })}
            onSubmit={(values) => {
              // console.log(values);
              setPayment_Detailes((oldArray) => [...oldArray, values])
              setShow2(false)
              // handle_update_Admin(values);
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'bank_name'}
                          placeholder={'Ex: Habib bank Limited'}
                          value={formik.values.bank_name}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Bank Name *</label>
                        {formik.touched.bank_name && formik.errors.bank_name ? (
                          <div className={'error-field'}>
                            {formik.errors.bank_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'account_title'}
                          placeholder={'Ex: Shahid Khan'}
                          value={formik.values.account_title}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Account Title</label>
                        {formik.touched.account_title &&
                        formik.errors.account_title ? (
                          <div className={'error-field'}>
                            {formik.errors.account_title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: 000025659875684 or 03314569879'}
                          name={'account_number'}
                          value={formik.values.account_number}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Account Number</label>
                        {formik.touched.account_number &&
                        formik.errors.account_number ? (
                          <div className={'error-field'}>
                            {formik.errors.account_number}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <div className={'aaddmethod'}>Add Payment Method</div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              bank_name:
                payment_Detailes[index_update] &&
                payment_Detailes[index_update].bank_name,
              account_title:
                payment_Detailes[index_update] &&
                payment_Detailes[index_update].account_title,
              account_number:
                payment_Detailes[index_update] &&
                payment_Detailes[index_update].account_number,
            }}
            validationSchema={Yup.object({
              bank_name: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the valid bank name'),
              account_title: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the valid bank account title'),
              account_number: Yup.string()
                .max(25, 'Must be 15 characters or less')
                .required('Please enter the valid bank account title'),
            })}
            onSubmit={(values) => {
              console.log(values.bank_name)
              let temp_state = [...payment_Detailes]
              let temp_element = { ...temp_state[index_update] }
              temp_element.bank_name = values.bank_name
              temp_state[index_update] = temp_element
              setPayment_Detailes(temp_state)
              setShow3(false)
              // handle_update_Admin(values);
            }}
          >
            {(formik) => {
              // console.log(overview.adminRole);
              return (
                <Form>
                  <div className='row py-3'>
                    <div className={'col-md-12 py-2'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'bank_name'}
                          placeholder={'Ex: Habib bank Limited'}
                          value={formik.values.bank_name}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Bank Name *</label>
                        {formik.touched.bank_name && formik.errors.bank_name ? (
                          <div className={'error-field'}>
                            {formik.errors.bank_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          name={'account_title'}
                          placeholder={'Ex: Shahid Khan'}
                          value={formik.values.account_title}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Account Title</label>
                        {formik.touched.account_title &&
                        formik.errors.account_title ? (
                          <div className={'error-field'}>
                            {formik.errors.account_title}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 py-3'}>
                      <div className='group mb-2'>
                        <input
                          type='text'
                          autoComplete={'off'}
                          placeholder={'Ex: 000025659875684 or 03314569879'}
                          name={'account_number'}
                          value={formik.values.account_number}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                        />
                        <span className='highlight'></span>
                        <span className='bar'></span>
                        <label className={'p_label'}>Account Number</label>
                        {formik.touched.account_number &&
                        formik.errors.account_number ? (
                          <div className={'error-field'}>
                            {formik.errors.account_number}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className={'col-md-12 pt-3 text-center'}>
                      <button type={'submit'} className={'btn btn-save'}>
                        {/*<span className={saveSpin} />*/}
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* ------------------------- Toast Message Code  ------------------------------- */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style>{`
       .gutter-flx{
        --bs-gutter-x: -1.rem;
      }
     

error-feild{
   font-size: 14px;
          letter-spacing: 0.5px;
          color: #e52444;
}

.input-danger,
.input-danger:focus {
  border-bottom: 1px solid #e52444 !important;
}

.error-field {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #e52444;
}

.btn-proceed {
  border: none !important;
  color: #009b94;
}

.btn-proceed:hover {
  border: none;
  box-shadow: none !important;
  text-decoration: underline;
  color: rgba(1, 190, 181, 0.87);
}

.card-footer {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/*====================  Input field CSS Styling  ===========================*/

.group {
  position: relative;
  margin-bottom: 25px;
  width: 100% !important;
}

input,
.group select {
  font-size: 15px;
  padding: 12px 4px 2px 0px;
  display: block;
  border: none;
  border-bottom: 1px solid #cccccc;
}

input {
  width: 100% !important;
}

.group select {
      width: 468px;
    /* height: 19px; */
    margin-top: 10px;
    /* padding: 0px; */
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-position-x: 38px;
    background-position-y: 21px;
    background-size: 844px 5px;
    cursor: pointer;
    // border: none;
    position: absolute;
    top: -9px;
    left: 0px;
}
input:focus,
select {
  outline: none;
  border-bottom: 1px solid #009b94;
}

/* LABEL ======================================= */
label.p_label {
  color: #8e8e8e;
  font-size: 14px;
  font-weight: 400 !important;
  position: absolute;
  pointer-events: none;
  left: 1px;
  top: -10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label,
.group select:valid ~ label,
.group select:focus ~ label {
  top: -10px;
  font-size: 14px;
  color: #8e8e8e;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after,
.group select:focus ~ .bar:before,
.group select:focus ~ .bar:after {
  width: 50%;
}


.customModal {
  background: #b2dbbf;
  max-width: 500px;
  width: 100%;
}

.btn-save-Procced,
.btn-save-exit {
  margin: 0px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  height: 40px !important;
  border: 1px solid #009b94;
  border-radius: 4px;
  letter-spacing: 1.25px;
  color: #009b94;
  background-color: #ffffff;
}

.btn-save-exit {
  color: #5c5c5c;
  border: 1px solid #5c5c5c;
}
 
      `}</style>
    </>
  )
}

export default Payment_Info_Form
