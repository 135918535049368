/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState, useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
import './NurseService.css'
// import Delete from '../../../../resources/images/svgs/delete.svg'
// import Edit from '../../../../resources/images/svgs/edit.svg'
import axios from "axios"
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import Add from "./Add"
import Spinner from 'react-bootstrap/Spinner'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import CloseIcon from '@mui/icons-material/Close'
import PDelete from '../../../../resources/images/svgs/Plan_Delete.svg'
import Toast from '../../../../Components/Toast/Toast'
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import toArrayBuffer from 'to-array-buffer'

function Edit({ responseState, getList }) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [type, setType] = useState(responseState.date)
    const [loading, setLoading] = useState({
        apiLoading: false,
        imgLoading: false,
    })
    const { apiLoading, imgLoading } = loading;
    const [file, setFile] = useState(responseState.url)
    const [ToastMessage, setToastMessage] = useState('')
    const [showToast, setShowToast] = useState('')
    const [ToastIcon, setToastIcon] = useState(faTimesCircle)
    const [iconColor, setIconColor] = useState('')
    // Toast Message Function 
    const handleToast = (message, icon, iconColor, className) => {
        setToastIcon(icon)
        setToastMessage(message)
        setShowToast(`show ${className}`)
        setIconColor(iconColor)
        setTimeout(() => setShowToast(''), 2900)
    }
    const initialValues = {
        title: responseState.title,
        servicePrice: responseState.servicePrice,
        description: responseState.description,
        how_it_works: responseState.how_it_works,
        what_it_includes: responseState.what_it_includes,
        faq: responseState.faq || [],
    }
    // Velidation Of Form
    const validationSchema = Yup.object({
        title: Yup.string().required('Service Name is required'),
        servicePrice: Yup.string().required('Service Pricing is required'),
        description: Yup.string().required('Description is required'),
        how_it_works: Yup.string().required('How It Works is required'),
        what_it_includes: Yup.string().required('What It Includes? is required'),
        faq: Yup.array()
            .of(
                Yup.object().shape({
                    question: Yup.string().required('Question is required'),
                    answer: Yup.string().required('Answer is required'),
                })
            )
            .required('At least one FAQ is required'),
    })
    // API Integeration of Image Upload
    const uploadFile = (e) => {
        setLoading({ imgLoading: true });
        const [file] = e.target.files || e.dataTransfer.files;
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = async () => {
            const fileRes = btoa(reader.result);
            setFile(`data:image/png;base64,${fileRes}`)
            const base64Image = toArrayBuffer(fileRes)
            console.log(file);
            axios
                .post(
                    `${API_CONSTANTS.BASEURL
                    }admin/image-upload-api/user/${'a80422ab-1f3c-4676-8200-fc38a156599f '}/type/new`,
                    base64Image,
                    {
                        headers: API_CONSTANTS.imgheaders(),
                    }
                )
                .then((response) => {
                    setFile(response.data.response.url)
                    setLoading({ imgLoading: false })
                })
                .catch((e) => alert(e))
        }
    };
    // API Integeration 
    const handleSubmit = (values, { setSubmitting }) => {
        setLoading({ apiLoading: true })
        values.type = type
        values.url = file
        values.faq = values.faq.map((faq) => ({
            question: faq.question.trim(),
            answer: faq.answer.trim(),
        }))
        axios.patch(
            `${API_CONSTANTS.BASEURL_3}admin/nurse/services/${responseState.name}`,
            values,
            {
                headers: API_CONSTANTS.headers(),
            }
        )
            .then(response => {
                if (response.data.message === 'Success') {
                    handleToast(
                        'Service Updated Successfully',
                        faCheckCircle,
                        '#009b94',
                        'show-success'
                    )
                }
                handleClose()
                getList(type)
            })
            .catch((error) => alert(error.response.data.response.message))
            .finally(() => {
                setLoading({ apiLoading: false })
                setSubmitting(false)
            })
    }

    return (
        <>
            <button className='btn' onClick={handleShow}>
                <i className='fas fa-pen' style={{ color: '#8E8E8E' }}></i>
            </button>
            <Modal show={show} size="lg">
                <Modal.Body className="d-flex flex-column">
                    <div className='d-flex justify-content-between'>
                        <h4>Edit Service </h4>
                        <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, isSubmitting }) => (
                            <Form>
                                <div className='d-flex w-100 flex-column'>
                                    <div className='d-flex flex-column'>
                                        <img
                                            src={file}
                                            className='avatar'
                                            alt='avatar'
                                        />
                                        <div className='uploadImage'>
                                            <button
                                                type="button"
                                                style={{
                                                    display: 'block',
                                                    fontSize: 25,
                                                    color: '#79E80E',
                                                    padding: 0,
                                                }}
                                                onClick={() => document.getElementById('getFile').click()}
                                                className='btn'
                                            >
                                                {imgLoading ? (
                                                    <div
                                                        className='spinner-border spinner-border-sm text-success'
                                                        role='status'
                                                    >
                                                        <span className='sr-only'>Loading...</span>
                                                    </div>
                                                ) : (
                                                    <i className='fas fa-camera'></i>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                    <input
                                        type='file'
                                        id='getFile'
                                        style={{ display: 'none' }}
                                        onChange={uploadFile}
                                        accept='image/*'
                                    />
                                    <div className="form-inline row p-2 g-3">
                                        <div className="col-md-4 mb-4">
                                            <label className="form-label h2_label">
                                                Service Name
                                            </label>
                                            <Field
                                                type="text"
                                                name="title"
                                                // placeholder="Service Name"
                                                className="form-control border-0 border-bottom"
                                                style={{ paddingTop: '0px' }}
                                            />
                                            <ErrorMessage
                                                name="title"
                                                component="div"
                                                className="error-message"
                                            />
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <label className="form-label h2_label">
                                                Service Price
                                            </label>
                                            <Field
                                                type="text"
                                                name="servicePrice"
                                                // placeholder="Service Price"
                                                className="form-control border-0 border-bottom"
                                                style={{ paddingTop: '0px' }}
                                            />
                                            <ErrorMessage
                                                name="servicePrice"
                                                component="div"
                                                className="error-message"
                                            />
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <label className="form-label h2_label">
                                                Type
                                            </label>
                                            <div className="dropdown">
                                                <select
                                                    className="col-md-6 form-control border-0 border-bottom"
                                                    name="type"
                                                    value={type}
                                                    onChange={(e) => setType(e.target.value)}
                                                    style={{ paddingTop: '0px' }}
                                                >
                                                    <option value=""> </option>
                                                    <option value="Long">Long</option>
                                                    <option value="Short">Short</option>
                                                </select>
                                                <i className="fas fa-chevron-down dropdown-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 p-2">
                                        <label className="form-label h_label" >
                                            Description
                                        </label>
                                        <Field
                                            type="text"
                                            name="description"
                                            placeholder="Description"
                                            className="form-control"
                                            as="textarea"
                                        />
                                        <ErrorMessage
                                            name="description"
                                            component="div"
                                            className="error-message"
                                        />
                                    </div>
                                    <div className='form-inline p-2 row g-3'>
                                        <div className="col-md-6">
                                            <label className="form-label h_label">
                                                How It Works
                                            </label>
                                            <Field
                                                type="text"
                                                name="how_it_works"
                                                placeholder="How It Works"
                                                className="form-control"
                                                as="textarea"
                                            />
                                            <ErrorMessage
                                                name="how_it_works"
                                                component="div"
                                                className="error-message"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label h_label">
                                                What It Includes?
                                            </label>
                                            <Field
                                                type="text"
                                                name="what_it_includes"
                                                placeholder="What It Includes?"
                                                className="form-control"
                                                as="textarea"
                                            />
                                            <ErrorMessage
                                                name="what_it_includes"
                                                component="div"
                                                className="error-message"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 p-2">

                                        <FieldArray name="faq">
                                            {(arrayHelpers) => (
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <label className="form-label h_label" >
                                                            FAQs
                                                        </label>
                                                        <button className='button' type="button" onClick={() => arrayHelpers.push({ question: "", answer: "" })}>
                                                            <i className={`fas fa-plus iconClass2`}></i>
                                                            Add More
                                                        </button>
                                                    </div>
                                                    {values.faq.map((faq, index) => (
                                                        <div key={index} className='col-md-12 gap-3 d-flex'>
                                                            <div className="mb-2">
                                                                <label>Question {index + 1}</label>
                                                                <Field
                                                                    type="text"
                                                                    name={`faq[${index}].question`}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            <div className="mb-2">
                                                                <label>Answer {index + 1}</label>
                                                                <Field
                                                                    type="text"
                                                                    name={`faq[${index}].answer`}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                            {index > 0 && (
                                                                <button className='btn2' type="button" onClick={() => arrayHelpers.remove(index)}>
                                                                    Remove
                                                                </button>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </FieldArray>
                                    </div>
                                </div>
                                <div className='actionbuttons'>
                                    <button
                                        className='btn'
                                        type="submit"
                                        style={{
                                            backgroundColor: '#009B94',
                                            color: '#fff'
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        Save Changes{' '}
                                        {apiLoading && (
                                            <div
                                                className='spinner-border spinner-border-sm text-white'
                                                role='status'
                                            >
                                                <span className='sr-only'>Loading...</span>
                                            </div>
                                        )}
                                    </button>
                                    <button
                                        className='btn'
                                        type="reset"
                                        style={{
                                            color: '#B00020',
                                            border: '1px solid #B00020'
                                        }}
                                        onClick={() => handleClose()}
                                    >
                                        Discard
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <Toast
                cName={showToast}
                icon={ToastIcon}
                color={iconColor}
                text={ToastMessage}
            />
        </>
    );
}
// Delete Function Component 
function Delete({ responseState, getList }) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [isLoading, setIsLoading] = useState(false)
    const [ToastMessage, setToastMessage] = useState('')
    const [showToast, setShowToast] = useState('')
    const [ToastIcon, setToastIcon] = useState(faTimesCircle)
    const [iconColor, setIconColor] = useState('')
    const handleToast = (message, icon, iconColor, className) => {
        setToastIcon(icon)
        setToastMessage(message)
        setShowToast(`show ${className}`)
        setIconColor(iconColor)
        setTimeout(() => setShowToast(''), 2900)
    }
    const handleDelete = () => {
        setIsLoading(true);
        axios
            .delete(`${API_CONSTANTS.BASEURL_3}admin/nurse/services/${responseState.name}`,
                {
                    headers: API_CONSTANTS.headers(),
                })
            .then((response) => {
                if (response.data.message === 'Success') {
                    handleToast(
                        'Service Deleted Successfully',
                        faCheckCircle,
                        '#009b94',
                        'show-success'
                    )
                }
                setIsLoading(false)
                setShow(false)
                getList(responseState.date)
            })
    }
    return (
        <>
            <button className='btn' onClick={handleShow}>
                <i className='fas fa-trash-alt' style={{ color: '#FF6760' }}></i>
            </button>
            <Modal show={show}
                className='d-flex justify-content-center align-items-center'
            >
                <Modal.Body className='d-flex justify-content-around align-items-center flex-column' style={{ maxHeight: '255px' }}>
                    <div style={{
                        width: '32px',
                        height: '32px'
                    }}>
                        <img src={PDelete} alt='' />
                    </div>
                    <h4 style={{ color: '#AF1300' }}>Delete</h4>
                    <p style={{ textAlign: 'center' }}>Are you sure you want to Delete this Service?</p>
                    <div className='d-flex' style={{ gap: '20px' }}>
                        <Button style={{ color: '#FFFFFF', backgroundColor: '#B00020', border: 'none' }} onClick={handleDelete}>
                            Delete {isLoading && <Spinner animation='border' size='sm' />}
                        </Button>
                        <Button
                            style={{ color: '#4A4A4A', backgroundColor: '#FFFFFF', border: '1px solid #4A4A4A' }}
                            onClick={() => handleClose()}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Toast
                cName={showToast}
                icon={ToastIcon}
                color={iconColor}
                text={ToastMessage}
            />
        </>
    )
}
// Edit And Delete Component Call Component 
function ActionButtons({ item, getList }) {
    return (
        <div className='d-flex'>
            <Edit responseState={item} getList={getList} />
            <Delete responseState={item} getList={getList} />
        </div>
    )
}

//Main Component Of Nurse Service List 
export default function NurseServiceList() {
    const [service, setService] = useState('')
    const [activeType, setActiveType] = useState('Short')
    function getList(type) {
        axios
            .get(`${API_CONSTANTS.BASEURL_3}admin/nurse/services/type/${type}`, {
                headers: API_CONSTANTS.headers()
            })
            .then((res) => {
                setService(res.data.response.Items)
                setActiveType(type)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    useEffect(() => {
        getList("Short")
    }, [])
    return (
        <section className="container-fluid my-3">
            <div className={'card w-100'}>
                <div className="d-flex justify-content-between align-items-center m-3">
                    <div className="button py-3 d-flex gap-3">
                        <Button onClick={() => getList("Short")} style={{
                            backgroundColor: activeType === 'Short' ? '#009B94' : '#E0F3F2',
                            color: activeType === 'Short' ? '#FFFFFF' : '#000000',
                            border: '1px solid #009B94'
                        }} className="nbtn">Short Term Service</Button>
                        <Button onClick={() => getList("Long")} style={{
                            backgroundColor: activeType === 'Long' ? '#009B94' : '#E0F3F2',
                            color: activeType === 'Long' ? '#FFFFFF' : '#000000',
                            border: '1px solid #009B94'
                        }} className="nbtn">Long Term Service</Button>
                    </div>
                    <Add getList={getList} />
                </div>
                <div className="d-flex flex-wrap py-2">
                    {service.length < 1 ? [] : service.map((item) => (
                        <div
                            key={item.id}
                            className="d-flex align-items-center rounded border justify-content-between px-3 py-2 box2"
                            style={{ width: "33%" }}
                        >
                            <div className="d-flex align-items-center gap-3">
                                <img className="pic" src={item.url} alt=" " />
                                <span>
                                    <h6>{item.title.length > 15 ? item.title.slice(0, 15) + '...' : item.title}</h6>
                                    <p style={{ marginBottom: "0px" }}>Rs. {item.servicePrice}</p>
                                </span>
                            </div>
                            <div>
                                <ActionButtons item={item} getList={getList} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <style jsx='true'>{`
      @media screen and (max-width: 1050px) {
        .box2{
          width: 50% !important;
        }
      }
      @media only screen and (max-width: 768px) {
        .container{
          max-width: 90% !important;
        }
      }
      @media screen and (max-width: 400px) {
        .box2{
          width: 100% !important;
        }
      }
      `} 
      </style>
        </section>
    )
}