import React from 'react'
import ServicesList from './Components/ServicesList'
//
export default function NurseServices() {
  return (
    <>
      <ServicesList />
    </>
  )
}
