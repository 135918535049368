import { createSlice } from '@reduxjs/toolkit'
//
export const patientMedicalHistoryFilterSlice = createSlice({
  name: 'MedicalHistoryFilter',
  initialState: {
    value: 'All',
    value1: '',
    value2: '',
  },
  reducers: {
    profileId: (state, action) => {
      state.value = action.payload
    },
    date: (state, action) => {
      const { payload } = action
      state.value1 = payload
    },
    medicalRecordFrom: (state, action) => {
      const { payload } = action
      state.value2 = payload
    },
    resetState: (state) => {
      state.value = 'All'
      state.value1 = ''
      state.value2 = ''
    },
  },
})

export const patientMedicalHistoryFilterReducer =
  patientMedicalHistoryFilterSlice.reducer
