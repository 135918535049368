import { createSlice } from '@reduxjs/toolkit'
//

export const doctorFilterSlice = createSlice({
  name: 'doctorFilter',
  initialState: {
    value: '',
    value1: '',
    value2: '',
    value3: '',
  },
  reducers: {
    all: (state, action) => {
      state.value = ''
    },
    Dermatologist: (state, action) => {
      state.value = 'Dermatologist'
    },
    Cardiologist: (state, action) => {
      state.value = 'Cardiologist'
    },
    Hematologist: (state, action) => {
      state.value = 'Hematologist'
    },
    Nephrologist: (state, action) => {
      state.value = 'Nephrologist'
    },
    GeneralPhysician: (state, action) => {
      state.value = 'General Physician'
    },
    // second filter value
    allgender: (state, action) => {
      state.value1 = ''
    },
    Male: (state, action) => {
      state.value1 = 'Male'
    },
    Female: (state, action) => {
      state.value1 = 'Female'
    },
    Other: (state, action) => {
      state.value1 = 'Other'
    },
    // 3rd filter value
    created_at: (state, action) => {
      const { payload } = action
      state.value2 = payload
    },
    appoint_date_from: (state, action) => {
      const { payload } = action
      state.value3 = payload
    },
  },
})
export const { all, Hematologist, Dermatologist, Cardiologist, created_at, appoint_date_from } =
  doctorFilterSlice.actions
export default doctorFilterSlice.reducer
