import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case "LOADING_TRUE": {
      return {
        ...state,
        loading: true,
      };
    }
    case "LOADING_FALSE": {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      };
    }
  }
}
// Admin related filter search form
export default function FilterForm() {
  const filter_status = {
    appoint_type: useSelector((state) => state.ServicesFilterReducer.value),

    appointment_status: useSelector(
      (state) => state.ServicesFilterReducer.value1
    ),
  };
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    appoint_type: filter_status.appoint_type,
    appointment_status: filter_status.appointment_status,
    loading: false,
  });
  //
  const dispatch = useDispatch();
  //
  const { appoint_type, appointment_status, loading } = formData;
  // this is will handle submit

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormDataDispatch({ type: "LOADING_TRUE" });
    if (appoint_type !== undefined) {
      dispatch({
        type: "servicesFilter/" + appoint_type,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
    if (appointment_status !== undefined) {
      dispatch({
        type: "servicesFilter/" + appointment_status,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
  };

  //
  return (
    <>
      <form onSubmit={handleSubmit} className="mt-4 p-2">
        <div
          className="form-group mt-3
        "
        >
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Services Type
          </label>
          <select
            className="form-control"
            id="exampleFormControlSelect1"
            onChange={setFormDataDispatch}
            name="appoint_type"
            value={appoint_type}
          >
            <option name="all" value="videoCall">
              All
            </option>
            <option value="Urgent Care">Urgent Care</option>
            <option value="Short Term">Short Term</option>
            <option value="Long Term">Long Term</option>
            {/*<option value='FollowUp'>Follow Up's Consultations</option>*/}
          </select>
        </div>
        <div className="form-group mt-3">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Status
          </label>
          <select
            className="form-control filter-input my-2"
            id="exampleFormControlSelect1"
            onChange={setFormDataDispatch}
            name="appointment_status"
            value={appointment_status}
          >
            <option name="all" value={"all"}>
              All
            </option>
            <option value={"completed"}>Completed</option>
            <option value={"InProgress"}>InProgress</option>
            <option value={"Assigned"}>Assigned</option>
            <option value={"Pending"}>Pending</option>
          </select>
        </div>
        {/* <div class='form-group mt-3 '>
          <label for='exampleFormControlSelect1' style={{ fontWeight: 400 }}>
            Consultation Date
          </label>
          <input
            onChange={setFormDataDispatch}
            name='appoint_date'
            type='date'
            className='form-control filter-input'
            id=' exampleFormControlSelect1'
            value={appoint_date}
          />
        </div> */}

        <button className="btn mt-3 filter-form-button">
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: "20px" }}
            />
          )}{" "}
          {loading || "Apply"}
        </button>
      </form>
      <style jsx="true">{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 100%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
      `}</style>
    </>
  );
}
