import { useState } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import Button from '@mui/material/Button'
import axios from 'axios'
import { API_CONSTANTS } from '../../API_CONSTANTS'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
//
const Edit = ({ data = '', text, type, method, editRoute }) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  let values = {
    method: method,
    path: data,
    new_path: value,
  }
  const confirm = () => {
    setLoading(true)
    axios
      .patch(API_CONSTANTS.BASEURL_2 + 'admin/roles/' + type, values, {
        headers: API_CONSTANTS.headers(),
      })
      .then(() => {
        setLoading(false)
        editRoute(data, value, method)
        setValue('')
        handleClose()
      })
  }
  return (
    <div>
      <Button onClick={handleOpen}>{text}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Edit : {data}
          </Typography>
          <TextField
            id='outlined-basic'
            label={'edit'}
            variant='outlined'
            style={{ margin: '20px 0', width: '100%' }}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <div className='d-flex justify-content-end'>
            <Button
              variant='text'
              style={{ color: 'red', marginRight: 10 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant='outlined' onClick={confirm}>
              Confirm{' '}
              {loading && (
                <CircularProgress size={15} style={{ marginLeft: 10 }} />
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
//
// deleting component
const Delete = ({ data = '', text, method, type, deleteroute }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const confirm = () => {
    setLoading(true)
    axios
      .delete(API_CONSTANTS.BASEURL_2 + '/admin/roles/' + type, {
        data: {
          method: method,
          path: data,
        },
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setLoading(false)
        deleteroute(data, method)
        handleClose()
      })
  }
  return (
    <div>
      <Button onClick={handleOpen}>{text}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            You are going to delete '{data}' path
          </Typography>
          <div className='d-flex justify-content-end my-3'>
            <Button
              variant='text'
              style={{ color: 'red', marginRight: 10 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant='outlined' onClick={confirm}>
              Confirm{' '}
              {loading && (
                <CircularProgress size={15} style={{ marginLeft: 10 }} />
              )}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
// path component
export default function Listing({
  matter,
  type,
  method,
  editRoute,
  deleteroute,
}) {
  return (
    <div className='d-flex justify-content-between align-items-center px-5 my-2  border-bottom py-2'>
      <div className='d-flex'>
        <p className='m-0 ms-5 '>{matter}</p>
      </div>
      <div className='d-flex align-items-center'>
        <Edit
          data={matter}
          text={<EditIcon />}
          type={type}
          method={method}
          editRoute={editRoute}
        />

        <Delete
          data={matter}
          method={method}
          type={type}
          deleteroute={deleteroute}
          text={<RemoveCircleOutlineIcon style={{ color: 'red' }} />}
        />
      </div>
    </div>
  )
}
