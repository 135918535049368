/*
Author: Umer Shabir 
Date: September-2022
File: loader component, you can use it where all page need to reload.
*/
// Dependencies
import React from 'react'
import './style.css'
// Loader component
export default function Loader() {
  return <div className='loading'></div>
}
