import React, { useEffect, useState } from 'react'
import axios from 'axios'
import EditBalance from './EditBalance'
import AddPaymentMethod from './AddPaymentMethod'
import DeletePaymentMethod from './DeletePaymentMethod'
import { useParams } from 'react-router'
import jazzCash from '../../../../../resources/images/jazzCash.png'
import easyPaisa from '../../../../../resources/images/easyPaisa.png'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
//
//

//
export default function BalanceAndPayments() {
  const params = useParams()
  const [payments, setPayments] = useState({
    paymentMethods: [
      {
        CNICLastDigits: '',
        mobileNo: '',
        type: '',
        paymentId: '',
      },
    ],
  })
  const getProfileWalletInfo = () => {
    axios
      .get(`${API_CONSTANTS.BASEURL}admin/patients/${params.id}/paymentMethod`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((response) => setPayments(response.data.response.data))
      .catch((error) => alert(error))
  }
  useEffect(() => {
    getProfileWalletInfo()
  }, [])
  return (
    <>
      <div className='col'>
        <div className='d-flex bg-white justify-content-between align-items-center rounded p-3 mb-3'>
          <div>
            <h4 style={{ fontSize: 16, fontWeight: 500 }}>Current Balance</h4>
            <p style={{ fontSize: 24, fontWeight: 500, color: '#5C5C5C' }}>
              Rs. {payments.wallet}.00
            </p>
          </div>
          <EditBalance
            current={payments.wallet}
            getProfileWalletInfo={getProfileWalletInfo}
          />
        </div>
      </div>
      <div className='col'>
        <table className='bg-white rounded profile-card-width mb-3 w-100'>
          <thead></thead>
          <tbody>
            <tr>
              <th>Refund Requests</th>
              <td>{payments.withdrawalRequests}</td>
            </tr>
            <tr>
              <th>Refunds Pending</th>
              <td>{payments.withdrawalPending}</td>
            </tr>
            <tr>
              <th>Refund Amount Pending</th>
              <td>{payments.withdrawalAmount}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='col'>
        <div className='d-flex flex-column bg-white rounded p-3 mb-3'>
          <div className='d-flex justify-content-between align-items-center w-100 mb-4'>
            <h4 style={{ fontSize: 16, fontWeight: 500 }}>Payment Methods</h4>
            <AddPaymentMethod getPaymentDetails={getProfileWalletInfo} />
          </div>
          {payments.paymentMethods.map((item, index) => (
            <div
              className='d-flex justify-content-between align-items-center w-100 my-2 border-bottom p-2'
              key={index}
            >
              <div className='d-flex align-items-center'>
                <img
                  src={
                    item.type !== ''
                      ? item.type === 'JazzCash'
                        ? jazzCash
                        : easyPaisa
                      : ''
                  }
                  className={item.type}
                  alt=''
                />
                <p style={{ margin: '0 0 0 15px' }}>{item.type}</p>
                <p style={{ margin: '0 0 0 15px' }}>
                  {item.mobileNo
                    ? item.mobileNo.slice(0, 4) +
                      '*'.repeat(item.mobileNo.length - 7) +
                      item.mobileNo.slice(-3)
                    : ''}
                </p>
              </div>
              {item.paymentId ? (
                <DeletePaymentMethod
                  paymentId={item.paymentId}
                  getProfileWalletInfo={getProfileWalletInfo}
                />
              ) : (
                ''
              )}
            </div>
          ))}
          <div>
            {payments.paymentMethods.length === 0 && (
              <p
                style={{
                  fontSize: 14,
                  color: '#5C5C5C',
                  textAlign: 'center',
                  margin: 'auto 0',
                }}
              >
                No payment methods
              </p>
            )}
          </div>
        </div>
      </div>
      <style jsx='true'>{`
        .JazzCash {
          width: 30px;
          height: 25px;
        }
        .EasyPaisa {
          width: 20px;
          height: 24px;
        }
      `}</style>
    </>
  )
}
