// dependencies
import React from 'react'
import style from '../../addData.module.css'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import toArrayBuffer from 'to-array-buffer'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import { useDispatch } from 'react-redux'
export default function Edit({ item }) {
  const [loading, setLoading] = React.useState({
    apiLoading: false,
    imgLoading: false,
  })
  const { apiLoading, imgLoading } = loading
  const [modalShow, setModalShow] = React.useState(false)
  const [file, setFile] = React.useState(item.url)
  const [value, setValue] = React.useState('')
  const dispatch = useDispatch()
  // editing for the image
  const uploadFile = (e) => {
    setLoading({ imgLoading: true })
    const [file] = e.target.files || e.dataTransfer.files
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = async () => {
      const fileRes = btoa(reader.result)
      setFile(`data:image/png;base64,${fileRes}`)
      const base64Image = toArrayBuffer(fileRes)
      console.log(file)
      axios
        .post(
          `${
            API_CONSTANTS.BASEURL
          }admin/image-upload-api/user/${'a80422ab-1f3c-4676-8200-fc38a156599f '}/type/new`,
          base64Image,
          {
            headers: API_CONSTANTS.imgheaders(),
          }
        )
        .then((response) => {
          setFile(response.data.response.url)
          setLoading({ imgLoading: false })
        })
        .catch((e) => alert(e))
    }
  }
  // updating editing
  const editingData = () => {
    setLoading({ apiLoading: true })
    axios
      .patch(
        `${API_CONSTANTS.BASEURL_1}admin/settings`,
        {
          name: item.Id,
          new_name: value ? value : item.Id,
          type: 'Specializations',
          image: file,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: 'getDataSlice/editSpecializations',
          payload: {
            lastValue: item.Id,
            newName: value ? value : item.Id,
            url: file,
          },
        })
        setValue('')
        setLoading({ apiLoading: false })
        setModalShow(false)
      })
      .catch((error) => {
        alert(error.response.data.message)
        setLoading(false)
      })
  }
  return (
    <>
      <button
        className={style.button + ' ' + 'btn'}
        onClick={() => setModalShow(true)}
      >
        <i className='fas fa-pencil-alt' style={{ color: '#8E8E8E' }}></i>
      </button>
      <Modal
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit: {item?.Id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex w-100 flex-column'>
            <div className='d-flex flex-column'>
              <img
                src={file}
                className={`align-self-center ${style.avatar}`}
                alt='avatar'
              />
              <div className={style.uploadImage}>
                <button
                  style={{
                    display: 'block',
                    fontSize: 25,
                    color: '#79E80E',
                    padding: 0,
                  }}
                  onClick={() => document.getElementById('getFile').click()}
                  className='btn'
                >
                  {imgLoading ? (
                    <div
                      className='spinner-border spinner-border-sm text-success'
                      role='status'
                    >
                      <span className='sr-only'>Loading...</span>
                    </div>
                  ) : (
                    <i className='fas fa-camera'></i>
                  )}
                </button>
              </div>
            </div>
            <input
              type='file'
              id='getFile'
              style={{ display: 'none' }}
              onChange={uploadFile}
              accept='image/*'
            />
            <input
              type='text'
              placeholder='Edit Specialization name'
              value={value}
              className='w-100 border-bottom pt-3 '
              style={{ outline: 'none', border: 'none' }}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}>
          <div className={style.actionbuttons}>
            <button
              className={style.saveButton + ' ' + 'btn'}
              onClick={editingData}
            >
              Save Changes{' '}
              {apiLoading && (
                <div
                  className='spinner-border spinner-border-sm text-white'
                  role='status'
                >
                  <span className='sr-only'>Loading...</span>
                </div>
              )}
            </button>
            <button
              className={style.cancelButton + ' ' + 'btn'}
              onClick={() => setModalShow(false)}
            >
              Discard
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
