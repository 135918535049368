import { createSlice } from "@reduxjs/toolkit";
// reducer
export const tableFilterModal = createSlice({
  name: "tableFilterModal",
  initialState: {
    show: false,
  },
  reducers: {
    setShowModal: (state) => {
      state.show = true;
    },
    setHideModal: (state) => {
      state.show = false;
    },
  },
});

export const tableFilterModalReducer = tableFilterModal.reducer;
