import React, { useState, useReducer } from 'react'
import toArrayBuffer from 'to-array-buffer'
import Modal from 'react-bootstrap/Modal'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { getProfile } from '../../../patientProfileSlice'
import axios from 'axios'

import { API_CONSTANTS } from '../../../../../../API_CONSTANTS'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../../../../Components/Toast/Toast'

export default function AddMember() {
  const [file, setFile] = useState(
    'https://img.icons8.com/material/72/000000/user-male-circle--v1.png'
  )

  const formReducer = (state, event) => {
    if (event.reset) {
      return { name: '', dob: '', relation: '', gender: '', location: '' }
    }
    return {
      ...state,
      [event.target.name]: event.target.value,
      url: file,
    }
  }

  const [formData, setFormData] = useReducer(formReducer, {
    name: '',
    dob: '',
    relation: '',
    gender: '',
    location: '',
  })

  const { name, dob, relation, gender, location } = formData
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setFormData({ reset: true });
    setFile('https://img.icons8.com/material/72/000000/user-male-circle--v1.png')
    setShow(false)
  }
  const handleShow = () => setShow(true)
  const params = useParams()
  const dispatch = useDispatch()
  // ------------------ toast states
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('') //  using this state for show the toast message
  const [ToastIcon, setToastIcon] = useState(faCheckCircle) // using this state for changing the toast message icon color
  const [iconColor, setIconColor] = useState('') // using this state for changing the toast message icon color
  const [spin, setSpin] = useState('')

  const uploadFile = (e) => {
    const [file] = e.target.files || e.dataTransfer.files
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = async () => {
      const fileRes = btoa(reader.result)
      setFile(`data:image/png;base64,${fileRes}`)
      const base64Image = toArrayBuffer(fileRes)
      axios
        .post(
          `${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${params.id}/type/new`,
          base64Image,
          {
            headers: API_CONSTANTS.imgheaders(),
          }
        )
        .then((response) => {
          setFile(response.data.response.url)
        })
        .catch((e) => alert(e))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (name !== '') {
      axios
        .post(`${API_CONSTANTS.BASEURL}admin/patients/${params.id}/profile`, formData, {
          headers: API_CONSTANTS.headers(),
        })
        .then((res) => {
          dispatch(getProfile(params.id))
          handleToast(
            res.data.response.message,
            faCheckCircle,
            '#009B94',
            'show-success'
          )
        })
        .catch((error) => {
          handleToast(
            error.data.response.message,
            faTimesCircle,
            '#B00020',
            'show-danger'
          )
        })
      setFormData({ reset: true })
      setFile(
        'https://img.icons8.com/material/72/000000/user-male-circle--v1.png'
      )
      setShow(false)
    }
  }
  // ---------------- Handle Toast function
  // --------  This function is for call the Toast message  ------
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  return (
    <>
      <button className='add-record btn' onClick={handleShow}>
        <i
          className='fas fa-plus'
          style={{ marginRight: 8, color: '#99D7D4' }}
        ></i>
        Add Member
      </button>
      <Modal show={show}>
        <Modal.Body className='d-flex flex-column'>
          <img src={file} className='align-self-center avatar' alt='avatar' />
          <div className='upload-image'>
            <button
              style={{
                display: 'block',
                fontSize: 25,
                color: '#79E80E',
                padding: 0,
              }}
              onClick={() => document.getElementById('getFile').click()}
              className='btn'
            >
              <i className='fas fa-camera'></i>
            </button>
          </div>
          <form className='d-flex flex-column' onSubmit={handleSubmit}>
            <input
              type='file'
              id='getFile'
              style={{ display: 'none' }}
              onChange={uploadFile}
              accept='image/*'
            />
            <label for='name'> Name</label>
            <input
              type='text'
              name='name'
              className='custom-input-field '
              onChange={setFormData}
              value={name}
              required
            />
            <label for='name'>City </label>
            <input
              type='text'
              name='location'
              className='custom-input-field '
              onChange={setFormData}
              value={location}
              required
            />
            <label for='DateofBirth'> Date of Birth</label>
            <input
              type='date'
              name='dob'
              className='custom-input-field '
              onChange={setFormData}
              value={dob}
              required
            />
            <label for='relation'>Relation</label>
            <select
              type='select'
              name='relation'
              className='custom-input-field '
              onChange={setFormData}
              value={relation}
              required
            >
              <option value=''>choose an option</option>
              <option value='Son'>Son</option>
              <option value='Daughter'>Daughter</option>
              <option value='Father'>Father</option>
              <option value='Spouse'>Spouse</option>
              <option value='Mother'>Mother</option>
              <option value='Other'>Other</option>
            </select>
            <label for='Gender'>Gender</label>
            <select
              type='text'
              name='gender'
              className='custom-input-field '
              onChange={setFormData}
              value={gender}
              required
            >
              <option value=''>choose an option</option>
              <option value='Other'>Other</option>
              <option value='Female'>Female</option>
              <option value='Male'>Male</option>
            </select>
            <span style={{ marginTop: '10px' }} className='align-self-stretch'>
              <button type='submit' className='btn btn-save w-100'>
                Save Changes
              </button>
            </span>
          </form>
          <button className='btn btn-outline-danger mt-2' onClick={handleClose}>
            Discard
          </button>
        </Modal.Body>
      </Modal>

      <style jsx='true'>
        {`
          .edit-button {
            border: 2px solid #009b94;
            color: #009b94;
          }
          .edit-button:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-save {
            background: #009b94 !important;
            color: #fff;
            padding: 10px 10px !important;
          }
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
          }
          .custom-input-field {
            width: 100%;
            border: none;
            border-bottom: 1px solid #cccccc;
            margin: 10px 0;
          }
          .custom-input-field:focus-visible {
            outline: none;
            border-bottom: 1px solid #009b94;
          }
          label {
            font-size: 12px;
            color: #8e8e8e;
          }
          .error-message {
            font-size: 13px;
            color: red;
          }
          .avatar {
            vertical-align: middle;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-bottom: 30px;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
          }
          .upload-image {
            position: absolute;
            top: 101px;
            left: 266px;
          }
          .add-record {
          }
        `}
      </style>
      {/* Importing the Toast Component  */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}
