/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react'
import Table from '../../../../../Components/Table'
import axios from 'axios'

import moment from 'moment'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

//
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
//
//
export default function CallLogTable() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [transactions, setTransactions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0)
  //
  const params = useParams()
  const filter_status = {
    dateTime: useSelector(
      (state) => state.patientTransactionFilterReducer.value
    ),
    amount: useSelector(
      (state) => state.patientTransactionFilterReducer.value1
    ),
    transaction_status: useSelector(
      (state) => state.patientTransactionFilterReducer.value2
    ),
  }
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setTransactions(transactions.slice(startRow, endRow))

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(transactions.length / pageSize))
      }
    },
    [transactions, lastEvaluatedKey]
  )
  //
  // API Hatching data of all admins
  const transactionsList_API = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/appointmentlogs/${params.id}`,
        {
          dateTime: filter_status.dateTime,
          amount: filter_status.amount,
          transaction_status: filter_status.transaction_status,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then(
        (res) => {
          setIsLoading(true)
          setLastEvaluatedKey(res.data.response.LastEvaluatedKey)
          setTransactions(res.data.response.Items)
        },
        (error) => {
          setIsLoading(true)
          setError(error)
        }
      )
  }
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/appointmentlogs/${params.id}?last=${lastEvaluatedKey}`,
        {
          dateTime: `${filter_status.dateTime}`,
          amount: `${filter_status.amount}`,
          transaction_status: `${filter_status.transaction_status}`,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
        var patientListNextPage = response.data.response.Items
        setTransactions([...transactions, ...patientListNextPage])
      })
      .then(() => setLoading(true))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Time',
        accessor: (a) => moment(a.created_at).format('DD-MM-YYYY'),
      },
      {
        Header: 'Doctor Bandwidth',
        accessor: (property) =>
          property.service ? 'Booked Appointment: ' + property.service : '',
      },
      {
        Header: 'Patient Bandwidth',
        accessor: 'amount',
      },
    ],
    []
  )
  // rendering API

  // Table rendering

  return (
    <>
      <div className='bg-white d-flex flex-column rounded'>
        <div>
          <h3 style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}>
            Call Logs
          </h3>
        </div>
        <Table
          columns={columns}
          data={transactions}
          routingLink={''}
          pageCount={pageCount}
          fetchData={fetchData}
          loadMoreList={handleLoadMoreData}
          lastEvaluatedKey={lastEvaluatedKey}
          loading={loading}
          setLoading={setLoading}
          FilterForm={''}
        />
      </div>
    </>
  )
}
