import React, { useMemo, useEffect, useState } from "react";
import Table from "../../Components/Table/index";
import axios from "axios";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import AlertMessage from "../../Components/AlertMessage";
import { API_CONSTANTS } from "../../API_CONSTANTS";
import LabFilterForm from "./LabFilterForm";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/fontawesome-free-solid";
import Toast from "../../Components/Toast/Toast";
import Modal from "react-bootstrap/Modal";

function Delete({ data, lab }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ToastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState("");
  const [ToastIcon, setToastIcon] = useState(faTimesCircle);
  const [iconColor, setIconColor] = useState("");
  const [loading, setLoading] = useState(false);
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon);
    setToastMessage(message);
    setShowToast(`show ${className}`);
    setIconColor(iconColor);
    setTimeout(() => setShowToast(""), 2900);
  };

  const handleDelete = () => {
    setLoading(true)
    axios
      .delete(`${API_CONSTANTS.LABURL}admin/lab/appointments/${data.appointmentId}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setLoading(false)
        if (res.data.message === "Success") {
          handleToast(
            "Lab Appointment Deleted Successfully",
            faCheckCircle,
            "#009b94",
            "show-success"
          );
        }
        setShow(false);
        lab()
      })
  };
  return (
    <>
      <button className="Deleted" onClick={handleShow}>
        <i className="fas fa-trash-alt" style={{ color: "#FF6760" }}></i>
      </button>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: 18 }}>Caution!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Lab Appointment?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            style={{
              border: "1px solid #79747E",
              color: "#B00020",
              background: "none",
            }}
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn"
            style={{ background: "#009B93", color: "#fff" }}
            onClick={handleDelete}
          >
            {loading && <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />} Delete
          </button>
        </Modal.Footer>
      </Modal>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style jsx="true">{`
          .Deleted {
            border: none !important;
            background: none !important;
            padding: 3px 6px;
            border-radius: 4px;
          }
          .Deleted:hover {
            background: #b5c9c8 !important;
          }
        `}</style>
    </>
  );
}
export default function LabList() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [labList, setLabList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);

  const filter_status = {
    test_type: useSelector((state) => state.LabFilterReducer.value),
    appoint_type: useSelector((state) => state.LabFilterReducer.value1),
    appoint_date: useSelector((state) => state.LabFilterReducer.value2),
    appoint_date_from: useSelector((state) => state.LabFilterReducer.value4),
    appointment_status: useSelector((state) => state.LabFilterReducer.value3),
  };
  const handleFilters = () => {
    let filterData = {
      test_type: `${filter_status.test_type ? filter_status.test_type : ""}`,
      appoint_type: `${filter_status.appoint_type ? filter_status.appoint_type : ""
        }`,
      appoint_date: `${filter_status.appoint_date ? filter_status.appoint_date : ""
        }`,
      appoint_date_from: `${filter_status.appoint_date_from ? filter_status.appoint_date_from : ""
        }`,
      appointment_status: `${filter_status.appointment_status ? filter_status.appointment_status : ""
        }`,
    };
    if (Object.values(filterData).every((key) => key === "")) {
      return {};
    } else
      return Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== "")
      );
  };
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = lastEvaluatedKey;
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setLabList(labList.slice(startRow, endRow));
        setPageCount(Math.ceil(labList.length / pageSize));
      }
    },
    [labList, lastEvaluatedKey]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lab = () => {
    axios
      .post(`${API_CONSTANTS.LABURL}admin/lab/appointments`, handleFilters(), {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setIsLoading(true);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
        setLabList(res.data.response.Items);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setIsLoading(true);
        setLabList([]);
      });
  };
  const [page, setPage] = useState(0);

  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.LABURL}admin/lab/appointments?last=${lastEvaluatedKey}`,
        handleFilters(),
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var labListNextPage = response.data.response.Items;
        setLabList([...labList, ...labListNextPage]);
        setPage(page + 1);
      })
      .then(() => setLoading(true));
  };
  const onDownloadBtn = () => {
    const filters = handleFilters();
    if (!filters || Object.keys(filters).length === 0) {
      alert("Please select valid filters before proceeding.");
      return;
    }
    axios
      .post(
        `${API_CONSTANTS.LABURL}admin/lab/appointments?loadall=true`,
        filters, // Use the valid filters here
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        const s3Url = response.data.response.s3_url;
        const link = document.createElement("a");
        link.href = s3Url;
        link.download = "consultation.csv"; // Set the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(true);
      })
      .catch((error) => {
        // Handle error cases here
        console.error("An error occurred:", error);
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: "Patient Name",
        accessor: "patientName",
      },
      {
        Header: "Date",
        accessor: (item) =>
          moment(item.appoint_date, "YYYY-MM-DD").format("D-MM-YYYY"),
      },
      {
        Header: "Time",
        accessor: (item) => moment(item.appoint_date).format("h:mm A"),
      },
      {
        Header: "Status",
        accessor: "appointment_status",
        Cell: ({ value }) =>
          value === "lab_confirmed" ? (
            <span>Email Sent</span>
          ) : value === "lab_pending" ? (
            <span>Pending</span>
          ) : value === "lab_cancelled" ? (
            <span>Canceled</span>
          ) : value === "lab_completed" ? (
            <span>Verified</span>
          ) : (
            <span>{value}</span>
          ),
      },
      {
        Header: "Lab",
        accessor: "labName",
      },
      {
        Header: "Type",
        accessor: "testType",
        Cell: ({ value }) =>
          value === "labVisit" ? (
            <span>Lab Visit</span>
          ) : value === "homeSample" ? (
            <span>Home Sample</span>
          ) : (
            <span>{value}</span>
          ),
      },
      {
        Header: "Action",
        accessor: "View File",
        Cell: ({ row }) => (
          <Link
            to={`/lab/profile/${row.original.appointmentId}/overview`}
            style={{ borderBottom: "1px solid #5C5C5C" }}
          >
            View File
          </Link>
        ),
      },
      {
        Header: " ",
        accessor: (data) => <Delete data={data} lab={lab} />,
      },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  useEffect(() => {
    lab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter_status.appoint_type,
    filter_status.test_type,
    filter_status.appointment_status,
    filter_status.appoint_date,
    filter_status.appoint_date_from,
  ]);
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 401"
            ? is_SessionExpire()
            : error.message === "Request failed with status code 404"
              ? "record not found"
              : error.message
        }
      />
    );
  } else {
    return (
      <>
        <div className="row rounded bg-white m-4">
          <div className="d-flex align-items-start">
            <h3
              style={{
                fontSize: "16px",
                fontWeight: "500",
                padding: "18px",
              }}
            >
              All Lab Test
            </h3>
            {!isLoading && (
              <div className="d-flex justify-content-center  ">
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "#24AAA4", marginTop: "20px" }}
                />
              </div>
            )}
          </div>
          <Table
            pageNumber={page}
            columns={columns}
            data={labList}
            routingLink={""}
            pageCount={pageCount}
            fetchData={fetchData}
            loadMoreList={handleLoadMoreData}
            lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            setLoading={setLoading}
            FilterForm={<LabFilterForm lab={lab} />}
            recordDownload={true}
            onDownloadBtn={onDownloadBtn}
          />
        </div>
        <style jsx="true">{`
          .add-record {
            background: #009b94 !important;
            color: #fff;
          }
          .btn:focus {
            box-shadow: none !important;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .patient-search-input {
            border-radius: 0;
          }
        `}</style>
      </>
    );
  }
}
