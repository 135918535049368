import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'

export const getLabOverview = createAsyncThunk(
  'getLabOverview',
  async (paramsID) => {
    const response = await axios
      .get(`${API_CONSTANTS.LABURL}admin/lab/appointments/${paramsID}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => res.data.response.message
      )
    return response
  }
)
// reducer
export const getLabOverviewSlice = createSlice({
  name: 'getLabOverview',
  initialState: {
    value: {
      email: '',
      patientPhone: '',
      created_at: '',
      appoint_type: '',
      testUid: [
        {
          uuid: "",
          quantity: "",
        }
      ],
      labName: '',
      testType: '',
      prescription_Url: null,
      testDetails: [],
      patientName: "",
      name: "",
      doctorId: "",
      charges: "",
      updated_at: "",
      appointment_status: "",
      appointmentId: "",
      userId: "",
      appointment: "",
      appoint_start_time: "",
      appoint_date: "",
      patientAddress: ""
    },
  },
  extraReducers: {
    [getLabOverview.pending]: (state) => {
      state.loading = true
    },
    [getLabOverview.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.value = payload
    },
    [getLabOverview.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const getLabReducer = getLabOverviewSlice.reducer
