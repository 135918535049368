import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import axios from 'axios'

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/fontawesome-free-solid'
import { useParams } from 'react-router-dom'
import Toast from '../../../../../Components/Toast/Toast'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'

const Account_Info_Form = () => {
  const param = useParams()
  const doctorphoneNo = useSelector(
    (state) => state.getDoctorReducer.value.phoneNo
  )

  const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ // Regex for Email.
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [error_email, setError_email] = useState('')
  const [Createspin, setCreateSpin] = useState('')
  const [CreateEmailspin, setCreateEmailSpin] = useState('')
  const [CreateOTPspin, setCreateOTPspin] = useState('')

  const [Error_otp, setError_Otp] = useState('')

  const [showToast, setShowToast] = useState('') //  using this state for show the toast message
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('') // using this state for changing the toast message icon color
  const [ToastMessage, setToastMessage] = useState('')

  // Email handle function
  const getEmail = (e) => {
    setEmail(e.target.value)
    setError_email('')
  }
  const getOtp = (e) => {
    let element = e.target
    element.value = element.value.replace(/[^0-9]/gi, '')
    setOtp(e.target.value)
    setError_Otp('')
  }

  const validateEmail = () => {
    if (email === '') {
      setError_email('Enter an email')
      console.log('ere')
      // setClassemail("input-danger");
      // setApiEmail("");
    }
    if (email !== '') {
      if (email.match(regexEmail)) {
        setError_email('')
        setCreateEmailSpin('spinner-border spinner-border-sm')
        handle_email_api_call(email)
      } else {
        setError_email('Please enter a valid email')
        return false
      }
    }
  }
  const validateOtp = () => {
    if (otp === '') {
      setError_Otp('Enter an otp')
    }
    if (otp !== '') {
      if (otp.length === 6) {
        setError_Otp('')
        setCreateOTPspin('spinner-border spinner-border-sm')
        handle_otp_api_call(otp)
      } else {
        setError_Otp('Enter valid Otp')
      }
    }
  }

  const handle_otp_api_call = (otp) => {
    const data = {
      emailOtp: otp,
    }
    axios
      .post(API_CONSTANTS.DOCTOR_ACCOUNT_EMAIL(param.id), data, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        console.log(res.data.response.message)
        setCreateOTPspin('')
        handleToast(
          res.data.response.message,
          faCheckCircle,
          'rgba(0,155,148,0.93)',
          'show-success'
        )
      })
      .catch((e) => {
        setCreateSpin('')
        console.log(e)
      })
  }

  const handle_email_api_call = (email) => {
    const data = {
      email: email,
    }
    axios
      .post(API_CONSTANTS.DOCTOR_ACCOUNT_OTP(param.id), data, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        console.log(res.data.response.message)
        setCreateEmailSpin('')
        handleToast(
          res.data.response.message,
          faCheckCircle,
          'rgba(0,155,148,0.93)',
          'show-success'
        )
      })
      .catch((e) => {
        setCreateEmailSpin('')
      })
  }

  const handle_email = (e) => {
    e.preventDefault()
    if (email === '') {
      setError_email('Please enter a email')
    } else {
      validateEmail()
    }
  }
  const handle_otp = (e) => {
    e.preventDefault()
    if (otp === '') {
      setError_Otp('Please enter a otp')
    } else {
      validateOtp()
    }
  }

  const verifyDoctorApi = () => {
    axios
      .post(
        API_CONSTANTS.DOCTOR_VERIFY_INFO(param.id),
        {},
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        setCreateSpin('false')

        handleToast(
          res.data.response.message,
          faCheckCircle,
          'rgba(0,155,148,0.93)',
          'show-success'
        )
      })
      .catch((e) => {
        handleToast('server error', faTimesCircle, '#b20909', 'show-danger')
        setCreateSpin('')
      })
  }

  // --------  This function is for call the Toast message  ------
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  return (
    <>
      <Formik
        initialValues={{
          profileSubmitted: true,
        }}
        onSubmit={(values) => {
          setCreateSpin('spinner-border spinner-border-sm')
          axios
            .patch(API_CONSTANTS.DOCOTR_ACCOUNT_INFO(param.id), values, {
              headers: API_CONSTANTS.headers(),
            })
            .then((res) => {
              setCreateSpin('')

              handleToast(
                res.data.response.message,
                faCheckCircle,
                'rgba(0,155,148,0.93)',
                'show-success'
              )
              res.data.responseCode === 200
                ? verifyDoctorApi()
                : console.log('server error')
            })
            .catch((e) => {
              // setCreateSpin('')

              handleToast(
                'server error',
                faTimesCircle,
                '#b20909',
                'show-danger'
              )
            })
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className='row'>
                <div className={'col-md-4'}>
                  <div className='group mb-0'>
                    <input
                      type='text'
                      autoComplete='Off'
                      value={doctorphoneNo}
                      className={'m-0 pb-0 pt-3'}
                    />
                    <span className='highlight' />
                    <span className='bar' />
                    <label className='p_label'>Phone Number</label>
                  </div>
                </div>
              </div>
              <div className={'row pt-4'}>
                <div className={'col-md-4'}>
                  <div className='group mb-2'>
                    <input
                      type='text'
                      autoComplete='Off'
                      className={'m-0'}
                      name={'email'}
                      value={email}
                      onChange={(e) => getEmail(e)}
                    />
                    <span className='highlight' />
                    <span className='bar' />
                    <label className='p_label ' for='email'>
                      Email Address (optional)
                    </label>
                  </div>
                  <p className={'m-0 error-field'}>{error_email}</p>
                </div>
                <div className={'col-md-5'}>
                  <button
                    type={'button'}
                    onClick={handle_email}
                    className={'btn btn-white'}
                  >
                    <span className={CreateEmailspin} />
                    Send OTP via Mail
                  </button>
                </div>
              </div>
              <div className={'row pt-4'}>
                <div className={'col-md-4'}>
                  <div className='group mb-2'>
                    <input
                      type='text'
                      autoComplete='Off'
                      className={'m-0 pb-0 pt-3'}
                      name={'otp'}
                      value={otp}
                      maxLength={6}
                      onChange={(e) => getOtp(e)}
                    />
                    <span className='highlight' />
                    <span className='bar' />
                    <label className='p_label'>Enter OTP</label>
                  </div>
                  <p className={'m-0 error-field'}>{Error_otp}</p>
                </div>
                <div className={'col-md-4'}>
                  <button className={'btn  btn-otp mt-0'} onClick={handle_otp}>
                    Verify
                  </button>
                </div>
              </div>

              <div className={'card-footer bg-white px-0 border-0'}>
                <div className={' py-4'}>
                  <button className={'btn btn btn-submit  '} type={'submit'}>
                    <span className={Createspin} />
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>

      {/* ------------------------- Toast Message Code  ------------------------------- */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style>{`
  .btn-white{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 24px;
text-align: center;
letter-spacing: 1.25px;
color: #009B94;
background: #FFFFFF;
border: 1px solid #009B94;
box-sizing: border-box;
border-radius: 6px;
  }
    .btn-white:hover{

color: #009B94;

  }
.btn-submit{
  padding: 10px px;
background: #009B94;
border-radius: 4px;
text-align: center;
letter-spacing: 1.25px;
color: #FFFFFF;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
}
.btn-submit:hover{

color: #FFFFFF;

}
.btn-otp{
  padding: 8px 20px;
background: #009B94;
border-radius: 4px;
text-align: center;
letter-spacing: 1.25px;
color: #FFFFFF;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
}
.btn-otp:hover{
;
color: #FFFFFF;

}
.error-field {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #e52444;
}
.group {
  position: relative;
  margin-bottom: 25px;
  width: 100% !important;
}

input,
.group select {
  font-size: 15px;
  padding: 12px 4px 2px 0px;
  display: block;
  border: none;
  border-bottom: 1px solid #cccccc;
}

input {
  width: 100% !important;
}

input:focus,
select {
  outline: none;
  border-bottom: 1px solid #009b94;
}

/* LABEL ======================================= */
label.p_label {
  color: #8e8e8e;
  font-size: 14px;
  font-weight: 400 !important;
  position: absolute;
  pointer-events: none;
  left: 1px;
  top: -10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label,
.group select:valid ~ label,
.group select:focus ~ label {
  top: -10px;
  font-size: 14px;
  color: #8e8e8e;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after,
.group select:focus ~ .bar:before,
.group select:focus ~ .bar:after {
  width: 100%;
}


















.btn-save-Procced,
.btn-save-exit {
  margin: 0px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
  height: 40px !important;
  border: 1px solid #009b94;
  border-radius: 4px;
  letter-spacing: 1.25px;
  color: #009b94;
  background-color: #ffffff;
}

.btn-save-exit {
  color: #5c5c5c;
  border: 1px solid #5c5c5c;
}











      
      `}</style>
    </>
  )
}

export default Account_Info_Form
