/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
import Toast from '../../../../Components/Toast/Toast'
import {
    faCheckCircle,
    faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addToPackage } from './packageSlice';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "../../Component/Overview/EditLab/Editlab.css";
import toArrayBuffer from "to-array-buffer"

export default function AddPackage() {
    const { packages } = useSelector((state) => state.packageReducer);
    const [ToastMessage, setToastMessage] = useState('')
    const [showToast, setShowToast] = useState('')
    const [ToastIcon, setToastIcon] = useState(faTimesCircle)
    const [selectTest, setSelectTest] = useState([])
    const [searchQuery, setSearchQuery] = useState("");
    const [labName, setLabName] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [url, setUrl] = useState(
        "https://www.freeiconspng.com/uploads/upload-icon-16.png"
    );
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [iconColor, setIconColor] = useState('')
    const [show, setShow] = useState(false);
    const dispatch = useDispatch()
    const handleToast = (message, icon, iconColor, className) => {
        setToastIcon(icon)
        setToastMessage(message)
        setShowToast(`show ${className}`)
        setIconColor(iconColor)
        setTimeout(() => setShowToast(''), 2900)
    }

    const initialValues = {
        subscriptionName: '',
        location: '',
        subCharges: '',
        charges: '',
        // type: '',
        planStatus: "active",
        saveRupees: '',
    }
    const validationSchema = Yup.object({
        subscriptionName: Yup.string().required('Package Name is Missing'),
        location: Yup.string().required('Location is Missing'),
        subCharges: Yup.string().required('Original Price is Missing'),
        charges: Yup.string().required('Discounted Price is Missing'),
        saveRupees: Yup.string().required('discount is Missing'),
    })
    const Id = localStorage.getItem("editorId")
    const handleImageChange = (e) => {
        const [file] = e.target.files || e.dataTransfer.files
        // preview img
        const reader = new FileReader()
        reader.readAsBinaryString(file)
        //preview onload function.
        reader.onload = async () => {
            // this is the base64 data
            const fileRes = btoa(reader.result)
            const base64Image = toArrayBuffer(fileRes)
            axios
                .post(
                    `${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${Id}/type/new`,
                    base64Image,
                    {
                        headers: API_CONSTANTS.imgheaders(),
                    }
                )
                .then((response) => {
                    setLoading(false)
                    setUrl(response.data.response.url)
                })
                .catch(() => {
                    setLoading(false)
                })
        };
    };
    const handleSubmit = (values, { setSubmitting }) => {
        const updatedValues = {
            ...values,
            subCharges: String(values.subCharges),
            charges: String(values.charges),
            saveRupees: String(values.saveRupees),
            selectTest: packages.map((item) => ({ Sk: item.Sk })),
            url: url,
            labName: labName
        };
        axios
            .post(`${API_CONSTANTS.LABURL}admin/labs/plans`, updatedValues, {
                headers: API_CONSTANTS.headers(),
            })
            .then(response => {
                if (response.data.message === 'Success') {
                    handleToast(
                        'Package added successfully',
                        faCheckCircle,
                        '#009b94',
                        'show-success'
                    )
                }
            })
            .catch(error => {
                // toast.error('Failed to add medicine')
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
    const getLabsData = () => {
        let param = '';
        if (labName === 'Chughtai Lab') {
            param = 'chughtai';
        } else if (labName === 'IDC Lab') {
            param = 'idc';
        }
        setIsLoading(true);
        axios
            .get(`${API_CONSTANTS.LABURL}admin/labs/tests?vendor=${param}`, {
                headers: API_CONSTANTS.headers(),
            })
            .then((res) => {
                setIsLoading(false);
                setSelectTest(res.data.response.Items);
            })
            .catch((error) => {
                alert(error);
                setIsLoading(false);
            });
    };
    const handleSearch = () => {
        let param = '';
        if (labName === 'Chughtai Lab') {
            param = 'chughtai';
        } else if (labName === 'IDC Lab') {
            param = 'idc';
        }
        setIsLoading(true);
        axios
            .get(`${API_CONSTANTS.LABURL}admin/labs/tests`, {
                headers: API_CONSTANTS.headers(),
                params: {
                    name: searchQuery,
                    vendor: param,
                }
            })
            .then((res) => {
                setSearchResults(res.data.response.Items);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };
    const handleAddToCart = (item) => {
        const itemId = item.Sk;
        const isAlreadyInCart = packages.some((cartItem) => cartItem.Sk === itemId);
        // Dispatch the addToCart action from the cart slice
        if (!isAlreadyInCart) {
            // Dispatch the addToCart action from the cart slice
            dispatch(
                addToPackage({
                    ...item,
                    quantity: 1,
                    totalPrice: item.productPrice,
                    subTotalPrice: item.subPrice,
                })
            );
            handleToast(
                "Test Added to Cart",
                faCheckCircle,
                "#009b94",
                "show-success"
            );
        } else {
            // Show a message or notification indicating that the test is already in the cart
            handleToast(
                "Test is already in the Cart",
                faCheckCircle,
                "#009b94",
                "show-success"
            );
        }
    };
    useEffect(() => {
        if (searchQuery.length >= 1) {
            handleSearch();
        } else {
            setSearchResults([]);
        }
    }, [searchQuery]);
    return (
        <section className='container-fluid'>
            <div className="w-100">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {(formikProps) => {
                        const { setFieldValue, values } = formikProps;
                        return (
                            <Form className="form-inline row g-3 m-3 bg-white p-3">
                                <div className="col-md-6">
                                    <label htmlFor="inputName1" className="form-label">
                                        Package Name
                                    </label>
                                    <Field
                                        type="text"
                                        name="subscriptionName"
                                        placeholder="Basic Package"
                                        className="form-control"
                                        id="inputName1"
                                    />
                                    <ErrorMessage name="subscriptionName" component="div" className="error-message" />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="inputName4" className="form-label">
                                        Charges
                                    </label>
                                    <Field
                                        name="subCharges"
                                        type="number"
                                        placeholder="Original Price"
                                        className="form-control"
                                        onChange={(e) => {
                                            const newSubCharges = e.target.value;
                                            setFieldValue('subCharges', newSubCharges);
                                            // Automatically update charges field based on subCharges and saveRupees
                                            const discountedPrice = newSubCharges - (newSubCharges * values.saveRupees / 100);
                                            setFieldValue('charges', discountedPrice.toFixed(0) || '');
                                        }}
                                    />
                                    <ErrorMessage name="subCharges" component="div" className="error-message" />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="inputName3" className="form-label">
                                        Discount In Percentage
                                    </label>
                                    <Field
                                        name="saveRupees"
                                        type="number"
                                        placeholder="Discount In Percentage"
                                        className="form-control"
                                        onChange={(e) => {
                                            const newSaveRupees = e.target.value;
                                            setFieldValue('saveRupees', newSaveRupees);
                                            // Automatically update charges field based on subCharges and saveRupees
                                            const discountedPrice = values.subCharges - (values.subCharges * newSaveRupees / 100);
                                            setFieldValue('charges', discountedPrice.toFixed(0) || '');
                                        }}
                                    />
                                    <ErrorMessage name="saveRupees" component="div" className="error-message" />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="inputName4" className="form-label">
                                        Discounted Charges
                                    </label>
                                    <Field
                                        type="number"
                                        name="charges"
                                        placeholder="Discounted Price (Rs.123)"
                                        className="form-control"
                                        id="inputName4"
                                        disabled
                                    />
                                    <ErrorMessage name="charges" component="div" className="error-message" />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="inputName3" className="form-label">
                                        Location
                                    </label>
                                    <Field
                                        type="text"
                                        name="location"
                                        placeholder="Lahore"
                                        className="form-control"
                                        id="inputName3"
                                    />
                                    <ErrorMessage name="location" component="div" className="error-message" />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="inputName2" className="form-label">
                                        Lab Name
                                    </label>
                                    <div className="dropdown">
                                        <select
                                            className="col-md-6 form-control"
                                            name="labName"
                                            value={labName}
                                            onChange={(e) => setLabName(e.target.value)}
                                        >
                                            <option value="">Choose One Lab</option>
                                            <option value="Chughtai Lab">Chughtai Lab</option>
                                            <option value="IDC Lab">IDC Lab</option>
                                        </select>
                                        <div className="dropdown-icon-container">
                                            <i className="fas fa-chevron-down dropdown-icon"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label style={{
                                        fontWeight: '400'
                                    }}>Select an image:</label>
                                    <div className="Authorimage d-flex justify-content-between">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            name="previewUrl"
                                            onChange={handleImageChange}
                                        />
                                        {loading ? <Spinner animation="border" size="sm" /> :
                                            <div
                                                className="image_view mx-1"
                                                style={{
                                                    backgroundImage: `url(${url})`,
                                                }}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1 py-2 gutter-flx">
                                    <div className="col-md-12">
                                        <div
                                            className={
                                                "justify-content-between d-flex border-bottom my-4"
                                            }
                                        >
                                            <span>Select Test</span>
                                            <Button
                                                onClick={() => {
                                                    setShow(true)
                                                    getLabsData()
                                                }
                                                }
                                                style={{
                                                    backgroundColor: "#009B94",
                                                    marginBottom: "3px",
                                                    border: "none",
                                                }}
                                                disabled={!labName}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                        <ul className="testList">
                                            {packages?.map((item, index) => (
                                                <li className="cart w-100" key={index}>
                                                    <div className="d-flex justify-content-between flex-wrap">
                                                        <h6>
                                                            {" "}
                                                            Test:{" "}
                                                            {item.MedicineName.length > 20
                                                                ? item.MedicineName.slice(0, 20) + "..."
                                                                : item.MedicineName}{" "}
                                                        </h6>
                                                        <p
                                                            style={{
                                                                color: "#009B94",
                                                                fontWeight: "500",
                                                            }}
                                                        >
                                                            {item.discount}% off
                                                        </p>
                                                    </div>
                                                    <div className="card_footer d-flex justify-content-between align-items-center flex-wrap">
                                                        <p
                                                            style={{
                                                                color: "#009B94",
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            <span>Rs. {item.totalPrice} </span>
                                                            <span
                                                                style={{
                                                                    textDecoration: "line-through",
                                                                    color: "#989898",
                                                                }}
                                                            >
                                                                Rs. {item.subTotalPrice}
                                                            </span>
                                                        </p>
                                                        <div
                                                            className="align-items-center d-flex border p-1"
                                                            key={index}
                                                        >
                                                            <button
                                                                className="cartButton"
                                                                type="button"
                                                                onClick={() =>
                                                                    dispatch({
                                                                        type: "packageSlicer/removePackageItem",
                                                                        payload: item.barcode,
                                                                    })
                                                                }
                                                            >
                                                                <DeleteOutlineIcon
                                                                    style={{
                                                                        color: "#009B94",
                                                                        width: "20px",
                                                                    }}
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="mt-3 mb-3 d-flex gap-2">
                                    {loading ? <Spinner animation="border" size="sm" /> : <button type="submit" className="btn btn-save">
                                        Add Test
                                    </button>}
                                    <button type="reset" className="btn btn-outline-danger" style={{ height: '43px' }}>
                                        Discard
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <Modal show={show} onHide={() => setShow(false)} size="m">
                <Modal.Header closeButton style={{ paddingBottom: 0 }}>
                    <span
                        className={" d-flex justify-content-center"}
                        style={{
                            color: "#5C5C5C",
                            fontSize: "16px",
                            fontWeightL: "600",
                            textAlign: "center",
                            width: "100%",
                        }}
                    >
                        Add Labs Test
                    </span>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div className="d-flex gap-2 pb-5">
                            <input
                                placeholder="Search Test Here"
                                value={searchQuery}
                                className="p-2 w-100 rounded searchTest"
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                        <div
                            className="d-flex flex-column"
                            style={{ maxHeight: "450px", overflowY: "auto" }}
                        >
                            {searchQuery
                                ? searchResults.map((item, index) => (
                                    <div
                                        className="card w-100 mb-3"
                                        style={{
                                            boxShadow: "0px 1px 2px rgba(30, 30, 30, 0.16)",
                                            borderRadius: "12px",
                                        }}
                                        key={index}
                                    >
                                        <div className="card_body_modal">
                                            <div className="d-flex justify-content-between">
                                                <div className="modal_des">
                                                    <h6>{item.MedicineName}</h6>
                                                    <p style={{ color: "#009B94", fontWeight: "600" }}>
                                                        <span>Rs. {item.productPrice} </span>
                                                        <span
                                                            style={{
                                                                textDecoration: "line-through",
                                                                color: "#989898",
                                                            }}
                                                        >
                                                            Rs. {item.subPrice}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div
                                                    className="d-flex flex-column justify-content-between"
                                                    style={{ color: "#009B94", textAlign: "end" }}
                                                >
                                                    <p>{item.discount}% off</p>
                                                    <Button
                                                        className="addTestButton"
                                                        onClick={() => handleAddToCart(item)}
                                                    >
                                                        Add Test
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : selectTest.map((item, index) => (
                                    <div
                                        className="card w-100 mb-3"
                                        style={{
                                            boxShadow: "0px 1px 2px rgba(30, 30, 30, 0.16)",
                                            borderRadius: "12px",
                                        }}
                                        key={index}
                                    >
                                        <div className="card_body_modal">
                                            <div className="d-flex justify-content-between">
                                                <div className="modal_des">
                                                    <h6>{item.MedicineName}</h6>
                                                    <p style={{ color: "#009B94", fontWeight: "600" }}>
                                                        <span>Rs. {item.productPrice} </span>
                                                        <span
                                                            style={{
                                                                textDecoration: "line-through",
                                                                color: "#989898",
                                                            }}
                                                        >
                                                            Rs. {item.subPrice}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div
                                                    className="d-flex flex-column justify-content-between"
                                                    style={{ color: "#009B94", textAlign: "end" }}
                                                >
                                                    <p>{item.discount}% off</p>
                                                    <Button
                                                        className="addTestButton"
                                                        onClick={() => handleAddToCart(item)}
                                                    >
                                                        Add Test
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            <div className="d-flex justify-content-center pb-2">
                                {isLoading && (
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        style={{ color: "#009b94" }}
                                    />
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
            <style jsx='true'>
                {`
            .btn-save:hover {
                background: #009b94 !important;
                color: #fff;
            }
            .btn-discard {
                background: none;
                color: red;
                padding: 10px 10px !important;
            }
            .fas fa-plus:hover{
                color:#009b94 !important;
            }
            .error-message {
                color: red;
            }
            .add-record {
                background: #009b94 !important;
                color: #fff;
            }
            .add-record:hover{
                background:  #fff !important;
                color: #009b94 !important;
            }
            `}
            </style>
            {/* <ToastContainer position="top-center" /> */}
            <Toast
                cName={showToast}
                icon={ToastIcon}
                color={iconColor}
                text={ToastMessage}
            />
        </section>
    )
}
