// Import the libraries
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
// Import the Form components
import Personal_Info_Form from './Personal_Info_Form'
import Professional_Info_Form from './Professional_Info_Form'
import Account_Info_Form from './Account_Info_Form'
import Payment_Info_Form from './Payment_Info_Form'
import Additional_Info_Form from './Additional_Info_Form'

// Functional components definition.
const DoctorInfoForm = () => {
  const [num, setNum] = useState('1')

  const selectTag = [
    { id: '1', name: 'Personal Info', value: '1' },
    { id: '2', name: 'Professional Info', value: '2' },
    { id: '3', name: 'Payment Info', value: '3' },
    { id: '4', name: 'Additional Info', value: '4' },
    { id: '5', name: 'Account Info', value: '5' },
  ]

  const formChangeHandler = (e) => {
    setNum(e.target.value)
  }

  // -------------  Function for prev step of form

  const next = () => {
    let add = parseInt(num)
    add += 1
    setNum(add.toString())
    if (num >= '5') {
      setNum('5')
    }
    // console.log(num)
  }

  // -------------  Function for prev step of form
  const prev = () => {
    let remove = parseInt(num)
    remove -= 1
    setNum(remove.toString())
    if (num <= '1') {
      setNum('1')
    }
    // console.log(num)
  }

  const parentdata = (data) => {
    // alert(data);
    setNum(data)
  }

  return (
    <>
      <section className={'content'}>
        <div className={'container-fluid p-3'}>
          <div className={'card w-100'}>
            <div
              className={
                ' card-header bg-white border-0 p-4 d-flex justify-content-between'
              }
            >
              <span>
                <span className={'dropdown_title'}>Profile: </span>
                <select
                  className={'dropdown_title border-0 select'}
                  onChange={formChangeHandler}
                >
                  {selectTag.map((data) => {
                    return (
                      <React.Fragment key={data.id}>
                        <option
                          className={'dropdown_title'}
                          value={data.value}
                          selected={num === data.id ? 'selected' : ''}
                        >
                          {data.name}
                        </option>
                      </React.Fragment>
                    )
                  })}
                </select>
              </span>
              <span className='float-right'>
                Step {num} to 5
                <span className={'mx-1'}>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className={'mx-4 perv'}
                    onClick={prev}
                  />
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className={'next'}
                    onClick={next}
                  />
                </span>
              </span>
            </div>
            <div className={'card-body pb-0'}>
              {num === '1' && <Personal_Info_Form alert={parentdata} />}
              {num === '2' && <Professional_Info_Form alert={parentdata} />}
              {num === '3' && <Payment_Info_Form alert={parentdata} />}
              {num === '4' && <Additional_Info_Form alert={parentdata} />}
              {num === '5' && <Account_Info_Form />}
            </div>
          </div>
        </div>
      </section>
      <style>{`
     
      }
      .perv,
.next {
    color: #99d7d4 !important;
}

.perv:hover,
.next:hover {
    color: #009b94 !important;
}
.dropdown_title:focus {
  outline:none !impotant;
}

. select {
      width: 326px;
     height: 19px; 
    margin-top: 10px;
     padding: 0px; 
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-position-x: 38px;
    background-position-y: 21px;
    background-size: 551px 5px;
    cursor: pointer;
     border: none;
    position: absolute;
    top: -9px;
    left: 0px;
}

      
      `}</style>
    </>
  )
}

export default DoctorInfoForm
