/*
Authore: Umer Shabir
Date: May-2022
File: 
*/
// dependencies
import React from 'react'
import PatientList from '../../Pages/Patient/PatientList/PatientList'
import AddNewUserButton from '../../Components/AddNewUserButton'
// Patient user list module.
export default function Patient() {
  return (
    <>
      <AddNewUserButton link={'/patients/new'} buttonText={'Add New Patient'} />
      <PatientList />
    </>
  )
}
