import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import { useParams } from 'react-router'

import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../../../Components/Toast/Toast'
//
export default function DeletePaymentMethod({
  paymentId,
  getProfileWalletInfo,
}) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const params = useParams()
  // ------------------ toast states
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('') //  using this state for show the toast message
  const [ToastIcon, setToastIcon] = useState(faCheckCircle) // using this state for changing the toast message icon color
  const [iconColor, setIconColor] = useState('') // using this state for changing the toast message icon color

  //
  //
  //
  const handleDelete = () => {
    axios
      .delete(`${API_CONSTANTS.BASEURL}admin/patients/${params.id}/paymentMethod/${paymentId}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        handleToast(
          res.data.response.message,
          faCheckCircle,
          '#009B94',
          'show-success'
        )
        getProfileWalletInfo()
      })
      .then(() => setShow(false))
      .catch((error) => {
        handleToast(
          error.data.response.message,
          faTimesCircle,
          '#B00020',
          'show-danger'
        )
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
      })
  }
  // ---------------- Handle Toast function
  // --------  This function is for call the Toast message  ------

  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  return (
    <>
      <button
        type='button'
        className='justify-self-end btn'
        onClick={handleShow}
        style={{
          margin: 0,
          padding: 0,
          background: 'none',
          outline: 'none',
          border: 'none',
          fontSize: 20,
        }}
      >
        <i className='fas fa-trash-alt' style={{ color: '#FF6760' }}></i>
      </button>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: 18 }}>
            Caution <span style={{ color: 'red', fontSize: 20 }}>!</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this payment method?
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-success' onClick={handleClose}>
            Close
          </button>
          <button className='btn btn-outline-danger' onClick={handleDelete}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
      {/* Importing the Toast Component  */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}
