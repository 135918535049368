import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { API_CONSTANTS } from "../../../../../API_CONSTANTS"
import CloseIcon from "@mui/icons-material/Close"
// import { ToastContainer, toast } from "react-toastify"
import Toast from '../../../../../Components/Toast/Toast'
import Spinner from "react-bootstrap/Spinner"
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import ReactTagInput from '@pathofdev/react-tag-input'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function AddMember() {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [isLoading, setIsLoading] = useState(false)
  const [validDurationSpan, setValidDurationSpan] = useState("")
  const [planCategory, setPlanCategory] = useState("")
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [includes, setIncludes] = useState([])
  const [selectedSpecialization, setSelectedSpecialization] = useState("")
  const [specialization, setSpecialization] = useState([])
  // Toast Message 
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  // Initial Values for Add subscription Form
  const initialValues = {
    planStatus: "active",
    termsAndConditions: "",
    charges: "",
    subscriptionName: "",
    validDuration: "",
    saveRupees: "",
    noOfCalls: "",
  };
  // Velidation of Form 
  const validationSchema = Yup.object({
    planStatus: Yup.string().required('Plan status is required'),
    termsAndConditions: Yup.string().required('Terms and conditions are required'),
    charges: Yup.number().min(1, 'Charges must be greater than 0').required('Charges are required'),
    subscriptionName: Yup.string().required('Subscription name is required'),
    validDuration: Yup.number().min(1, 'Valid duration must be greater than 0').required('Valid duration is required'),
    saveRupees: Yup.number().required('Save rupees value is required'),
    noOfCalls: Yup.number().min(1, 'No of calls must be greater than 0').required('No of calls is required')
  })
  // API integration of Add subsrciption Form 
  const handleSubmit = (values, { setSubmitting }) => {
    setIsLoading(true);
    values.speclization = specialization
    values.includes = includes
    values.validDurationSpan = validDurationSpan;
    values.planCategory = planCategory;
    axios
      .post(`${API_CONSTANTS.BASEURL_1}admin/subscriptionplan`, values, {
        headers: API_CONSTANTS.headers(),
      })
      .then((response) => {
        if (response.data.message === 'Success') {
          handleToast(
            'Subscription Plan added Successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
        }
        handleClose();
      })
      .finally(() => {
        setIsLoading(false)
        setSubmitting(false)
      });
  };
  return (
    <>
      <button className="add-record btn mx-4 mt-4" onClick={handleShow}>
        <i className="fas fa-plus" style={{ marginRight: 8 }}></i>
        Add New Plan
      </button>
      {/* Modal of Form */}
      <Modal show={show} size="lg">
        <Modal.Body className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <h4>Add New Plan</h4>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="form-inline row g-3">
              <div className="col-md-6 mb-4">
                <Field
                  type="text"
                  name="subscriptionName"
                  placeholder="Plan Name"
                  className="form-control border-0 border-bottom"
                />
                <ErrorMessage
                  name="subscriptionName"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-6">
                <Field
                  type="number"
                  name="saveRupees"
                  placeholder="Save Rupees"
                  className="form-control border-0 border-bottom"
                />
                <ErrorMessage
                  name="saveRupees"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-6 mb-4">
                <Field
                  type="number"
                  name="noOfCalls"
                  placeholder="No. of Calls"
                  className="form-control border-0 border-bottom"
                />
                <ErrorMessage
                  name="noOfCalls"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-6">
                <Field
                  type="number"
                  name="charges"
                  placeholder="Price In Rupees"
                  className="form-control border-0 border-bottom"
                />
                <ErrorMessage
                  name="charges"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="row">
                <label className="form-label" style={{
                  marginTop: '10px',
                  fontSize: '1rem',
                  fontWeight: '500'
                }}>
                  Specialization
                </label>
                <div className="col-md-6 mb-4">
                  <select
                    className="col-md-6 form-control border-0 border-bottom"
                    name="selectedSpecialization"
                    value={selectedSpecialization}
                    onChange={(e) => {
                      setSpecialization([...specialization, e.target.value])
                      setSelectedSpecialization("")
                    }}
                  >
                    <option value="">Choose Specialization</option>
                    <option value="Anesthesiologists">Anesthesiologists</option>
                    <option value="Cardiologists">Cardiologists</option>
                    <option value="Dermatologists">Dermatologists</option>
                    <option value="Endocrinologists">Endocrinologists</option>
                    <option value="Gastroenterologists">Gastroenterologists</option>
                    <option value="Hematologists">Hematologists</option>
                    <option value="Internists">Internists</option>
                    <option value="Medical Geneticists">Medical Geneticists</option>
                    <option value="General Physicians">General Physicians</option>
                  </select>
                  <div className="dropdown">
                  <FontAwesomeIcon icon={faChevronDown} className="drop" />
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <ReactTagInput
                    tags={specialization.length < 1 ? [] : specialization}
                    placeholder={` `}
                    editable={true}
                    name='specialization'
                    value={specialization}
                    removeOnBackspace={true}
                    className="form-control border-0 border-bottom"
                    onChange={(e) => {
                      setSpecialization(e)
                    }}
                    validateOnChange={true}
                  />
                  <ErrorMessage
                    name="speclization"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <p style={{
                marginBottom: '0px',
                fontSize: '1rem',
                fontWeight: '500'
              }}>
                Duration
              </p>
              <div className="col-md-6 mb-4">
                <Field
                  type="number"
                  name="validDuration"
                  placeholder="Duration in Number"
                  className="form-control border-0 border-bottom"
                />
                <ErrorMessage
                  name="validDuration"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-6">
                <div className="dropdown">
                  <select
                    className="col-md-6 form-control border-0 border-bottom"
                    name="validDurationSpan"
                    value={validDurationSpan}
                    onChange={(e) => setValidDurationSpan(e.target.value)}
                  >
                    <option value="">Choose One Duration</option>
                    <option value="days">Days</option>
                    <option value="month">Month</option>
                  </select>
                  <div className="dropdown-icon-container">
                    <i className="fas fa-chevron-down dropdown-icon"></i>
                  </div>
                </div>
                <ErrorMessage name="validDurationSpan" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <div className="dropdown">
                  <select
                    className="col-md-6 form-control border-0 border-bottom"
                    name="planCategory"
                    value={planCategory}
                    onChange={(e) => setPlanCategory(e.target.value)}
                  >
                    <option value="">Choose Plan Category</option>
                    <option value="1 month">1 Month</option>
                    <option value="3 months">3 Month</option>
                    <option value="6 months">6 Month</option>
                    <option value="12 months">12 Month</option>
                  </select>
                  <div className="dropdown-icon-container">
                    <i className="fas fa-chevron-down dropdown-icon"></i>
                  </div>
                </div>
                <ErrorMessage name="validDurationSpan" component="div" className="error-message" />
              </div>
              <div className="col-md-12">
                <label className="form-label" style={{
                  fontSize: '1rem',
                  fontWeight: '500'
                }}>
                  What It Includes
                </label>
                <ReactTagInput
                  tags={includes.length < 1 ? [] : includes}
                  placeholder={`Hit Enter to type the next lines`}
                  editable={true}
                  name='includes'
                  value={includes}
                  removeOnBackspace={true}
                  onChange={(e) => {
                    setIncludes(e)
                  }}
                  validateOnChange={true}
                />
                <ErrorMessage
                  name="includes"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="col-md-12">
                <label className="form-label" style={{
                  fontSize: '1rem',
                  fontWeight: '500'
                }}>
                  Terms and Conditions
                </label>
                <Field
                  type="text"
                  name="termsAndConditions"
                  placeholder="All Terms and Conditions here"
                  className="form-control"
                  as="textarea"
                />
                <ErrorMessage
                  name="termsAndConditions"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="row d-flex justify-content-center mt-5 mb-3 gap-3">
                <div className="col-md-5">
                  <button type="submit" className="btn btn-save w-100">
                    Save Changes{" "}
                    {isLoading && <Spinner animation="border" size="sm" />}
                  </button>
                </div>
                <div className="col-md-5">
                  <button
                    type="reset"
                    className="btn btn-outline-danger w-100"
                    onClick={handleClose}
                    style={{ height: "43px" }}
                  >
                    Discard Changes
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
      <style jsx="true">
        {`
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
            padding: 10px 10px !important;
          }
          .fas fa-plus:hover {
            color: #009b94 !important;
          }
          .error-message {
            color: red;
          }
          .dropdown-icon-container {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }     
          .drop{
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-140%);
          }     
          .add-record {
            background: #009b94 !important;
            color: #fff;
          }
          .add-record:hover {
            background: #fff !important;
            color: #009b94 !important;
          }
          .react-tag-input:hover{
            border-color: #009b94 !important;
          }
          .react-tag-input__tag__remove{
            background: none !important;
          }
          .react-tag-input__tag__remove::before{
            background-color: #009b94 !important;
          }
          .react-tag-input__tag__remove::after{
            background-color: #009b94 !important;
          }
        `}
      </style>
      {/* Toast Message Component render*/}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  );
}
