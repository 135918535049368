import React, { useState, useReducer } from 'react'
import {
  faTimesCircle,
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { Modal, Spinner, Button } from 'react-bootstrap'
import './bookservice.css'
import { API_CONSTANTS } from '../../../../../../API_CONSTANTS'
import moment from 'moment'
import axios from 'axios'
import Toast from '../../../../../../Components/Toast/Toast'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Services from '../../../../../../resources/images/svgs/Service.svg'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useParams } from 'react-router'

function Service({ data, index, addService, removeService }) {
  let [count, setCount] = useState(0)
  const [clicked, setClicked] = useState(false)
  let { title, servicePrice, description, name } = data
  const handleIncreament = () => {
    setCount(++count)
    setClicked(true)
    addService({
      title: title,
      qty: count,
      total: count >= 1 ? (servicePrice * count).toString() : servicePrice.toString(),
      charges: servicePrice.toString(),
      serviceId: name,
    })
    setTimeout(() => setClicked(false), 200)
  }
  const handleDecrement = () => {
    if (count > 0) {
      setCount((prev) => prev - 1)
      setClicked(true)
      removeService({
        title: title,
        qty: count - 1,
        total: (count - 1) >= 1 ? (servicePrice * (count - 1)).toString() : servicePrice.toString(),
        charges: servicePrice.toString(),
        serviceId: name,
      })
      setTimeout(() => setClicked(false), 200)
    }
  }
  return (
    <div
      className='card w-100 mb-3'
      style={{
        boxShadow: '0px 1px 2px rgba(30, 30, 30, 0.16)',
        borderRadius: '12px',
      }}
      key={index}
    >
      <div className='card_body_modal'>
        <div className='card_head_modal'>
          <div className='Modal_image'>
            <img
              src={Services}
              alt={''}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
          <div className='modal_des'>
            <h6 className='card-title'>{title}</h6>
            <span className='description'>{description}</span>
            <p style={{ color: '#009B94', }}>
              Rs. {count >= 1 ? (servicePrice * count).toString() : servicePrice.toString()}
            </p>
          </div>
          <div className={`modal_count_new d-flex justify-content-center align-items-center  border ${clicked ? 'clicked' : ''}`}
            style={{ color: '#1C6BA4' }}>
            <button className='btn' type='button' onClick={handleDecrement}>
              <RemoveIcon style={{ borderRight: '1px solid #D7DDEA', color: '#1C6BA4', fontSize: '1rem' }} />
            </button>
            <p>{count}</p>
            <button className='btn' type='button' onClick={handleIncreament}>
              <AddIcon style={{ borderLeft: '1px solid #D7DDEA', color: '#1C6BA4', fontSize: '1rem' }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
const slotsReducer = (state, action) => {
  switch (action.type) {
    case 'API_INIT': {
      return {
        ...state,
        apiLoading: true,
        docId: action.payload,
      }
    }
    case 'API_SUCC': {
      return {
        ...state,
        apiLoading: false,
        apiData: action.payload,
      }
    }
    case 'API_REJECT': {
      return {
        ...state,
        apiLoading: false,
        error: true,
        errorData: action.payload,
      }
    }
    case 'APPOINTMENT_SLOTS': {
      return {
        ...state,
        appointmentSlots: action.payload,
        apiData: [...state.apiData],
      }
    }
    case 'SHOW_INIT': {
      return {
        ...state,
        show: true,
      }
    }
    case 'SHOW_END': {
      return {
        ...state,
        show: false,
      }
    }
    case 'SHOW2_INIT': {
      return {
        ...state,
        show2: true,
      }
    }
    case 'SHOW2_END': {
      return {
        ...state,
        show2: false,
      }
    }
    default: {
    }
  }
}
// reducer funciton
const reduer = (state, action) => {
  switch (action.type) {
    case 'SET_SERVICES': {
      return {
        ...state,
        services: action.payload.services,
        serviceType: action.payload.serviceType,
      }
    }
    case 'SET_MODAL_SERVICE_LIST': {
      return {
        ...state,
        modalServices: action.payload,
      }
    }
    case 'SET_SERVICE_LIST': {
      return {
        ...state,
        serviceList: action.payload,
      }
    }
    case 'SET_LOCATION': {
      return {
        ...state,
        location: action.payload
      }
    }
    default: {
      console.log('booking services')
    }
  }
}
// booking component
export default function BookService() {
  const [appointmentSlotId, setAppointmentSlotId] = useState('')
  const [appoint_date, setAppoint_date] = useState('')
  const [start_time, setStart_time] = useState('')
  const params = useParams()
  const [clicked, setClicked] = useState(false)
  const [activeCardIndex, setActiveCardIndex] = useState(-1);
  const [bookingServices, setBookingServices] = useReducer(reduer, {
    serviceList: [],
    location: '',
    isSave: false,
    paymentType: 'COD',
    serviceType: '',
    services: [],
    modalServices: [],
    appointmentSlotId: '',

  })
  const {
    location,
    serviceType,
    serviceList,
    services,
    modalServices,
  } = bookingServices
  // const appointment_amount = serviceList.reduce((total, item) => total + item.total, 0).toString()
  const appointment_amount = serviceList.length > 0 ?
    serviceList.reduce((total, item) => total + parseInt(item.total), 0).toString() : '0';

  const [ToastStates, setToastStates] = useState({
    message: '',
    show: '',
    color: '',
    icon: faTimesCircle,
  })
  // handling toast
  const handleToast = (message, icon, iconColor, className) => {
    setToastStates({
      ...ToastStates,
      icon: icon,
      message: message,
      color: iconColor,
      show: `show ${className}`,
    })
    setTimeout(() => setToastStates({ ...ToastStates, show: '' }), 2900)
  }
  // reducer function for slots handling
  const [timeSlots, setTimeSlots] = useReducer(slotsReducer, {
    apiLoading: false,
    apiData: [],
    error: false,
    errorData: '',
    show: false,
    show2: false,
    appointmentSlots: {
      morning_slots: [],
      evening_slots: [],
      afternoon_slots: [],
    },
  })
  const { apiLoading, apiData, appointmentSlots, show, show2 } = timeSlots
  // handling modal show/hide functions
  const handleClose = () => setTimeSlots({ type: 'SHOW_END' })
  const handleCloseNew = () => {
    setTimeSlots({ type: 'SHOW2_END' })
    setBookingServices({ type: 'SET_MODAL_SERVICE_LIST', payload: [] })
  }

  const handleAppointSlots = (data) => {
    setTimeSlots({
      type: 'APPOINTMENT_SLOTS',
      payload: data,
    })
  }
  const getSlotId = (id, startTime) => {
    setAppointmentSlotId(id)
    setStart_time(startTime)
  }
  const startTimeMoment = moment(start_time, 'h:mm A')
  const endTimeMoment = startTimeMoment.add(1, 'hour')
  const end_time = endTimeMoment.format('h:mm A')
  const handleShow = () => {
    setTimeSlots({ type: 'SHOW_INIT' })
    getSlotData()
  }
  const handleShowNew = () => {
    setTimeSlots({ type: 'SELECT_MODAL_DATA', payload: [] })
    setTimeSlots({ type: 'SHOW2_INIT' })
  }
  const getSlotData = (id) => {
    setTimeSlots({ type: 'API_INIT', payload: id })
    axios
      .get(`${API_CONSTANTS.BASEURL_3}admin/nurse/appointmentSlots`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setTimeSlots({
          type: 'API_SUCC',
          payload: res.data.response.Items,
        })
      })
      .catch((error) => {
        handleToast(
          error.data.response.message,
          faExclamationTriangle,
          '#ffffff',
          'show-info'
        )
      })
  }
  // getting services data
  const getServicesData = (type) => {
    axios
      .get(`${API_CONSTANTS.BASEURL_3}admin/nurse/services/type/${type}`, {
        headers: API_CONSTANTS.headers()
      })
      .then((res) => {
        setBookingServices({
          type: 'SET_SERVICES',
          payload: {
            services: res.data.response.Items ? res.data.response.Items : [],
            serviceType: type,
          },
        })
      })
      .catch((error) => {
      })
  }
  const handleAddService = (data) => {
    let adding = modalServices.filter(
      (item) => item.serviceId !== data.serviceId
    )
    setBookingServices({
      type: 'SET_MODAL_SERVICE_LIST',
      payload: [...adding, data],
    })
  }
  const handleRemoveService = (data) => {
    let remove = modalServices.filter((item) => item.serviceId !== data.serviceId)
    setBookingServices({ type: 'SET_MODAL_SERVICE_LIST', payload: [...remove, data] })
  }
  const handleServicesList = () => {
    const filteredServices = modalServices.filter((item) => item.qty >= 1)
    setBookingServices({ type: 'SET_SERVICE_LIST', payload: filteredServices })
    setTimeSlots({ type: 'SHOW2_END' })
  }

  function handleSubmit(Id) {
    const data = {
      start_time: start_time,
      end_time: end_time,
      appointmentSlotId: appointmentSlotId,
      appoint_date: appoint_date,
      serviceList: serviceList,
      location: location,
      appointment_amount: appointment_amount,
      isSave: false,
      paymentType: 'COD',
      serviceType: serviceType,
    }
    axios.post(`${API_CONSTANTS.BASEURL_3}admin/patient/${params.id}/nurse/book`, data, {
      headers: API_CONSTANTS.headers()
    })
      .then((res) => {
        if (res.data.message === 'Success') {
          handleToast(
            'Service booked Successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
          setAppointmentSlotId('');
          setAppoint_date('');
          setStart_time('');
          setBookingServices({
            type: 'SET_SERVICE_LIST',
            payload: [],
          })
          setBookingServices({
            type: 'SET_LOCATION',
            payload: '',
          })
        }
      })
  }
  const handleIncrement = (index) => {
    const updatedList = [...serviceList]
    updatedList[index].qty += 1
    updatedList[index].total = (updatedList[index].qty * updatedList[index].charges).toString()
    setBookingServices({ type: 'SET_SERVICE_LIST', payload: updatedList })
    setClicked(true)
    setActiveCardIndex(index)
    setTimeout(() => setClicked(false), 200)
  }

  const handleDecrement = (index) => {
    const updatedList = [...serviceList]
    if (updatedList[index].qty > 0) {
      updatedList[index].qty -= 1
      updatedList[index].total = (updatedList[index].qty * updatedList[index].charges).toString()
      setBookingServices({ type: 'SET_SERVICE_LIST', payload: updatedList })
    }
    setClicked(true)
    setActiveCardIndex(index)
    setTimeout(() => setClicked(false), 200)
  }
  const handleRemoveItem = (indexToRemove) => {
    const updatedServiceList = serviceList.filter((item, index) => index !== indexToRemove)
    setBookingServices({ type: 'SET_SERVICE_LIST', payload: updatedServiceList })
    setClicked(true)
    setActiveCardIndex(-1)
    setTimeout(() => setClicked(false), 200)
  }
  const resetBookingState = () => {
    setAppointmentSlotId('')
    setAppoint_date('')
    setStart_time('')
    setBookingServices({
      type: 'SET_SERVICE_LIST',
      payload: [],
    })
    setBookingServices({
      type: 'SET_LOCATION',
      payload: '',
    })
  }
  const areAllFieldsFilled = () => {
    return (
      start_time !== '' &&
      end_time !== '' &&
      appointmentSlotId !== '' &&
      appoint_date !== '' &&
      serviceList.length > 0 &&
      location !== '' &&
      appointment_amount !== '' &&
      serviceType !== ''
    );
  };
  return (
    <>
      <section className={'form-group'}>
        <div className={'container-fluid'} style={{ padding: '32px' }}>
          <div className={'card w-100'}>
            <div className={'card-body'} style={{ padding: '1rem' }}>
              <span> Book Service </span>
              <div className='tab row mt-4'>
                <div className={'col-md-3 address '}>
                  <label>Full Address</label>
                  <input
                    type='text'
                    name='Address'
                    value={location}
                    onChange={(e) =>
                      setBookingServices({ type: 'SET_LOCATION', payload: e.target.value })
                    }
                  />
                </div>
                <div className={'col-md-3'}>
                  <div className='servicetype mb-2'>
                    <select
                      name='serviceType'
                      style={{ margintop: '8px' }}
                      value={serviceType}
                      onChange={(e) => getServicesData(e.target.value)}
                    >
                      <option value=''></option>
                      <option value='All'>All</option>
                      <option value='Long'>Long Term</option>
                      <option value='Short'>Short Term</option>
                    </select>
                    <label className='servicelabel'>Service Type</label>
                  </div>
                </div>
              </div>
              <div className='row mt-2 py-5 gutter-flx'>
                <div className='col-md-6 col-sm-12 '>
                  <div className={'justify-content-between d-flex border-bottom'}>
                    <span>Services</span>
                    <Button
                      onClick={handleShowNew}
                      style={{ backgroundColor: '#009B94', marginBottom: '3px', border: 'none' }}>
                      Add
                    </Button>
                  </div>
                  <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                    {serviceList.map((item, index) => (
                      <div className='M_Card d-flex flex-wrap' key={index}>
                        <div className='card_body'>
                          <div className='card_head d-flex justify-content-between flex-wrap'>
                            <h6 class='card-title'> Nursing: {item.title.length > 15 ? item.title.slice(0, 15) + '...' : item.title} </h6>
                            <div
                              className={`modal_count_new justify-content-center align-items-center d-flex border 
                              ${clicked && activeCardIndex === index ? "clicked" : ""
                                }`}
                              key={index}
                            >
                              {item.qty > 1 ? (
                                <button className='btn' type='button' onClick={() => handleDecrement(index)}>
                                  <RemoveIcon style={{ borderRight: '1px solid #D7DDEA', color: '#1C6BA4' }} />
                                </button>
                              ) : (
                                <button className='btn' type='button' onClick={() => handleRemoveItem(index)}>
                                  <DeleteOutlineIcon style={{ borderRight: '1px solid #D7DDEA', color: '#1C6BA4' }} />
                                </button>
                              )}
                              <p>{item.qty}</p>
                              <button className='btn' type='button'
                                onClick={() => handleIncrement(index)}
                              >
                                <AddIcon style={{ borderLeft: '1px solid #D7DDEA', color: '#1C6BA4' }} />
                              </button>
                            </div>
                          </div>
                          <p><span style={{ color: '#8E8E8E' }}>Quantity :</span>{' x ' + item.qty} </p>
                          <div
                            className='card_footer d-flex justify-content-between flex-wrap'
                          >
                            <div className='image'>
                              <img src={Services} alt={''} />
                              <span>{item.title.length > 15
                                // eslint-disable-next-line no-useless-concat
                                ? item.title.slice(0, 15) + '...' + ' x ' + item.qty
                                : item.title + ' x ' + item.qty}</span>
                            </div>
                            <p
                              style={{
                                color: '#009B94',
                              }}
                            >
                              RS. {item.total}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className='row mt-2 gutter-flx'
                style={{ marginBottom: '35px' }}
              >
                <div className='col-md-6 col-sm-12'>
                  <div className={'d-flex justify-content-between border-bottom'}>
                    <span>Select Slot</span>
                    <span>
                      <Button onClick={handleShow} style={{ backgroundColor: '#009B94', marginBottom: '3px', border: 'none' }}>
                        Add
                      </Button>
                    </span>
                  </div>
                </div>
                <div className='appoint'>
                  {appoint_date ? (
                    <p>{moment(appoint_date).format('dddd, MMMM, D, y') + ' ' + start_time}</p>
                  ) : null}
                </div>
              </div>
              <span>Payment</span>
              <br />
              <label
                className=' mt-4'
                style={{
                  color: '#8E8E8E',
                  fontSize: '12px',
                }}
              >
                Payment Type
              </label>
              <div className='tab row'>
                <span className='newwidth col-md-3 border-bottom'>Cash</span>
                <span className='allwidth col-md-3 border-bottom px-0'>
                  {'Rs. ' + appointment_amount}
                </span>
              </div>
              <div className='mt-5'>
                <button className='book' onClick={handleSubmit} disabled={!areAllFieldsFilled()}>Book Service</button>
                <button className='discard' onClick={resetBookingState}>Discard</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show2} onHide={handleCloseNew} size='m'>
        <Modal.Header closeButton style={{ paddingBottom: 0 }}>
          <span
            className={' d-flex justify-content-center'}
            style={{
              color: '#5C5C5C',
              fontSize: '16px',
              fontWeightL: '600',
              textAlign: 'center',
              width: '100%',
            }}
          >
            Add Service
          </span>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className='d-flex flex-column'>
              {services.map((item, index) => (
                <Service
                  data={item}
                  index={index}
                  addService={handleAddService}
                  removeService={handleRemoveService}
                />
              ))}
              <div className='f_button'>
                <Button
                  onClick={handleServicesList}
                  style={{
                    background: '#009B94',
                    border: 'none',
                    width: '129px',
                    height: '48px',
                  }}
                >
                  Add Service
                </Button>
                <Button
                  type='button'
                  style={{
                    background: 'none',
                    color: '#B00020',
                    border: 'none',
                    width: '129px',
                    height: '48px',
                  }}
                  onClick={handleCloseNew}
                >
                  Discard
                </Button>
              </div>
            </div>
          </Modal.Body>
        </form>
      </Modal>
      <Modal show={show} onHide={handleClose} size='m'>
        <Modal.Header closeButton style={{ paddingBottom: 0 }}>
          <span
            className={' d-flex justify-content-center'}
            style={{
              color: '#5C5C5C',
              fontSize: '16px',
              fontWeightL: '600',
              textAlign: 'center',
              width: '100%',
            }}
          >
            Select Slot
          </span>
        </Modal.Header>
        <form
        // onSubmit={handleSubmit}
        >
          <Modal.Body>
            <div className='d-flex flex-column'>
              <div className='d-flex flex-column border-bottom'>
                <p
                  className={'pt-1'}
                  style={{ fontSize: '30px', fontWeight: '600' }}
                >
                  Schedule
                </p>
                {apiLoading ? (
                  <Spinner animation='border' variant='success' size='sm' />
                ) : (
                  <div className='scrollmenu d-flex pb-2'>
                    {apiData?.map((i, index) => (
                      <span
                        onClick={() => handleAppointSlots(i.appointment_slots)}
                        key={index}
                      >
                        <input
                          type='radio'
                          className='btn-check '
                          name='appointDate'
                          id={i.slot_date}
                          value={i.slot_date}
                          onChange={(e) => setAppoint_date(e.target.value)}
                        />
                        <label
                          className={`btn date_focused d-flex flex-column p-3 
                          justify-content-center align-items-center labelrdioclass 
                          ${appoint_date === i.slot_date && 'labelSelectedClass'
                            }`}
                          htmlFor={i.slot_date}
                          style={{ height: '84px', width: '77px' }}
                        >
                          <p
                            className='m-0 '
                            style={{ fontSize: '16px', fontWeight: 'bold' }}
                          >
                            {moment(i.slot_date).format('DD,MMM')}
                          </p>
                          <p style={{ fontSize: '14px' }}>
                            {i.slot_day.slice(0, 3).toUpperCase()}
                          </p>
                        </label>
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div
                className='d-flex flex-column mt-3'
                style={{
                  overflowY: 'scroll',
                  height: appointmentSlots ? '400px' : 'auto',
                }}
              >
                <div className='d-flex flex-column'>
                  <p className='my-2 mx-1' style={{ color: '#7E7E7E' }}>
                    Morning
                  </p>
                  <div className='d-flex flex-wrap'>
                    {appointmentSlots?.morning_slots?.map((slot, index) => (
                      <span key={index}>
                        <input
                          type='radio'
                          className='btn-check'
                          name='morningSlots'
                          id={slot.appointmentSlotId}
                          value={slot.appointmentSlotId}
                          onChange={(e) =>
                            // eslint-disable-next-line no-useless-concat
                            getSlotId(e.target.value, slot.start + ' ' + 'AM')
                          }
                          disabled={slot.booked}
                        />
                        <label
                          className={`btn labelrdioclass ${appointmentSlotId === slot.appointmentSlotId &&
                            'labelSelectedClass'
                            }`}
                          htmlFor={slot.appointmentSlotId}
                        >
                          {moment(slot.start, 'HH:mm').format('h:mm A')}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <p className='my-2 mx-1' style={{ color: '#7E7E7E' }}>
                    Afternoon
                  </p>
                  <div className='d-flex flex-wrap'>
                    {appointmentSlots?.afternoon_slots?.map((slot, index) => (
                      <span key={index}>
                        <input
                          type='radio'
                          className='btn-check'
                          name='afternoonSlots'
                          id={slot.appointmentSlotId}
                          value={slot.appointmentSlotId}
                          onChange={(e) =>
                            // eslint-disable-next-line no-useless-concat
                            getSlotId(e.target.value, slot.start + ' ' + 'PM')
                          }
                          disabled={slot.booked}
                        />
                        <label
                          className={`btn labelrdioclass ${appointmentSlotId === slot.appointmentSlotId &&
                            'labelSelectedClass'
                            }`}
                          htmlFor={slot.appointmentSlotId}
                        >
                          {moment(slot.start, 'HH:mm').format('h:mm A')}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <p className='my-2 mx-1' style={{ color: '#7E7E7E' }}>
                    Evening
                  </p>
                  <div className='d-flex flex-wrap'>
                    {appointmentSlots?.evening_slots?.map((slot, index) => (
                      <span key={index}>
                        <input
                          type='radio'
                          className='btn-check'
                          name='eveningSlots'
                          id={slot.appointmentSlotId}
                          value={slot.appointmentSlotId}
                          onChange={(e) =>
                            // eslint-disable-next-line no-useless-concat
                            getSlotId(e.target.value, slot.start + ' ' + 'PM')
                          }
                          disabled={slot.booked}
                        />
                        <label className={`btn labelrdioclass ${appointmentSlotId === slot.appointmentSlotId &&
                          'labelSelectedClass'}`}
                          htmlFor={slot.appointmentSlotId} >
                          {moment(slot.start, 'HH:mm').format('h:mm A')}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className={
            'd-flex flex-column justify-content-center align-items-center my-4'
          }>
            <div
              className='align-self-end px-5'
              style={{ fontSize: 10, color: '#5B5B5B' }}
            >
              <i className='far fa-clock mx-1'></i>
              {moment(appoint_date).format('dddd, MMMM, D, ') + ' ' + start_time}
            </div>
            <Button
              className={''}
              // type='submit'
              style={{
                background: '#009B94',
                border: 'none',
                width: '400px',
                height: '47px',
              }}
              onClick={handleClose}
            >
              Confirm Slot
            </Button>
          </div>
        </form>
      </Modal>
      <Toast
        cName={ToastStates.show}
        icon={ToastStates.icon}
        color={ToastStates.color}
        text={ToastStates.message}
      />
      <style jsx='true'>
        {`
          .btn:focus{
            box-shadow: none !important
          }
        `}
      </style>
    </>
  )
}
