import React from 'react'
import LabTests from './labTests'
import AddTest from './addTest'
export default function TestList() {
    return (
        <>
            <div className='d-flex justify-content-end  mt-3 pe-4'>
                <AddTest />
            </div>
            <LabTests />
            <style jsx='true'>{`
                .add-user-btn {
                  background: #009b94;
                  color: #fff;
                  outline: none !important;
                  border: #009b94 !important;
                }
                .add-user-btn:hover {
                  background: #fff;
                  color: #009b94;
                  border: #009b94 !important;
                }
            `}</style>
        </>
    )
}
