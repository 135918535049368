// dependencies
import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Toast from '../../../../Components/Toast/Toast'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
// Add Admin Form
export default function AddAdminForm() {
  // Regex for Email.
  const regexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
  // Regex for Password.
  const regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm
  // ----------------- Api States
  const [apiEmail, setApiEmail] = useState('')
  const [apiPassword, setApiPassword] = useState('')
  const [apiName, setApiName] = useState('')
  const [apiPhoneNo, setApiPhoneNo] = useState('')
  const [apiSelect, setApiSelect] = useState('')
  // ------------------ toast states
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('') //  using this state for show the toast message
  const [ToastIcon, setToastIcon] = useState(faCheckCircle) // using this state for changing the toast message icon color
  const [iconColor, setIconColor] = useState('') // using this state for changing the toast message icon color
  const [spin, setSpin] = useState('')
  // -------------------------------
  const [email, setEmail] = useState('')
  const [classemail, setClassemail] = useState('')
  const [error_email, setError_email] = useState('')
  // -------------------------------
  const [password, setPassword] = useState('')
  const [classpassword, setClasspassword] = useState('')
  const [error_password, setError_password] = useState('')
  // -------------------------------
  const [name, setName] = useState('')
  const [classname, setClassname] = useState('')
  const [error_name, setError_name] = useState('')
  // -------------------------------
  const [phone, setPhone] = useState('')
  const [classphone, setClassphone] = useState('')
  const [error_phone, setError_phone] = useState('')
  const [phoneLength, setphoneLength] = useState('15')
  //  -----------------------------
  const [role, setRole] = useState('')
  const [classSelect, setClassSelect] = useState('')
  const [error_Select, setError_Select] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  // ---------------------------> get value functions
  const result = apiEmail.toLocaleLowerCase()
  // Email handle function
  const getEmail = (e) => {
    setEmail(e.target.value)
    setClassemail('')
    setError_email('')
  }

  const validateEmail = () => {
    if (email === '') {
      setError_email('Enter an email')
      setClassemail('input-danger')
      setApiEmail('')
      return false
    }

    if (email !== '') {
      if (email.match(regexEmail)) {
        setError_email('')
        setClassemail('')
        // console.log(email);
        setApiEmail(email)
        return true
      } else {
        setError_email('Please enter a valid email')
        setClassemail('input-danger')
        setApiEmail('')
        return false
      }
    }
  }

  // password handle function
  const getPassword = (e) => {
    setPassword(e.target.value)
    setClasspassword('')
    setError_password('')
  }
  const validatePassword = () => {
    if (password === '') {
      setError_password('Please enter a password')
      setClasspassword('input-danger')
      setApiPassword('')
      return false
    }
    if (password !== '') {
      if (password.length < 8) {
        setError_password('Minimum 8 characters required')
        setClasspassword('input-danger')
        setApiPassword('')
        return false
      } else {
        if (password.match(regexPassword)) {
          setError_password('')
          setClasspassword('')
          setApiPassword(password)
          return true
        } else {
          setError_password(
            'Password must have at least one digit, uppercase, lower case & special character'
          )
          setClasspassword('input-danger')
          setApiPassword('')
          return false
        }
      }
    }
  }

  // name handle function
  const getName = (e) => {
    let element = e.target
    element.value = element.value.replace(/[^A-Za-z\s]/gi, '')
    setName(e.target.value)
    setClassname('')
    setError_name('')
  }
  const validateName = () => {
    if (name === '') {
      setError_name('Please enter a full name')
      setClassname('input-danger')
      setApiName('')
      return ''
    }
    if (name !== '') {
      if (name.length < 5) {
        setError_name('Minimum 5 characters required')
        setClassname('input-danger')
        setApiName('')
        return ''
      } else {
        setClassname('')
        setError_name('')
        setApiName(name)
        return name
      }
    }
  }

  // phone handle function
  const getPhone = (e) => {
    let element = e.target
    element.value = element.value.replace(/[^0-9]/gi, '')
    setPhone(e.target.value)
    setError_phone('')
    setClassphone('')

    if (phone.charAt(0) === '0') {
      console.log('11')
      setphoneLength(11)
    }
    if (phone.charAt(0) === '3') {
      console.log('10')
      setphoneLength(10)
    }
  }
  const validatePhone = () => {
    // console.log(phone);
    if (phone === '') {
      // console.log("error");
      setError_phone('Please enter a valid phone number')
      setClassphone('input-danger')
      setApiPhoneNo('')
      return ''
    }
    if (phone !== '') {
      if (phone.length < phoneLength) {
        setError_phone('Please enter a valid phone number')
        setClassphone('input-danger')
        setApiPhoneNo('')

        return ''
      } else {
        if (phone.charAt(0) === '0') {
          if (phone.charAt(1) === '3') {
            if (
              phone.charAt(2) === '0' ||
              phone.charAt(2) === '1' ||
              phone.charAt(2) === '2' ||
              phone.charAt(2) === '3' ||
              phone.charAt(2) === '4' ||
              phone.charAt(2) === '5'
            ) {
              // console.log("11 sdfsdf +1");
              setError_phone('')
              setClassphone('')
              let number = ''
              let remove_zero = parseInt(phone, 10)
              number = '+92' + remove_zero.toString()
              // console.log(number + " with 0");
              // setPhone(number);
              setApiPhoneNo(number)

              return number
            } else {
              // console.log("invalide");
              setError_phone('Please enter a valid number')
              setClassphone('input-danger')
              setApiPhoneNo('')

              return ''
            }
          } else {
            setError_phone('Please enter a valid number')
            setClassphone('input-danger')
            setApiPhoneNo('')

            return ''
          }
        } else if (phone.charAt(0) === '3') {
          // console.log("invalidee");

          if (
            phone.charAt(1) === '0' ||
            phone.charAt(1) === '1' ||
            phone.charAt(1) === '2' ||
            phone.charAt(1) === '3' ||
            phone.charAt(1) === '4' ||
            phone.charAt(1) === '5'
          ) {
            // console.log("10 sdfsdf +1");
            let number = ''
            setError_phone('')
            setClassphone('')
            number = '+92' + phone
            // console.log(number + " without 0");
            // setPhone(number);
            setApiPhoneNo(number)

            return number
          } else {
            // console.log("invalidee");
            setError_phone('Please enter a valid number')
            setClassphone('input-danger')
            setApiPhoneNo('')
            return ''
          }
        } else {
          setError_phone('Please enter a valid number')
          setClassphone('input-danger')
          setApiPhoneNo('')
          return ''
        }
      }
    }
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // select handle function
  const getSelect = (e) => {
    setRole(e.target.value)
    setClassSelect('')
    setError_Select('')
  }

  const validateSelect = (e) => {
    // console.log(role);
    // console.log("fine1");

    if (role !== '') {
      setClassSelect('')
      setError_Select('')
      setApiSelect(role)
      return role
    } else {
      setClassSelect('input-danger')
      setError_Select('Please select the role')
      setApiSelect('')

      return ''
    }
  }
  // ---------------- Handle Toast function
  // --------  This function is for call the Toast message  ------

  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }

  // ---------------- Handle form submit function

  const handleSubmitForm = (e) => {
    e.preventDefault()
    if (email === '') {
      setError_email('Please enter a email')
      setClassemail('input-danger')
    } else {
      validateEmail()
      // console.log("email ok at all");
    }
    if (password === '') {
      setError_password('Please enter a password')
      setClasspassword('input-danger')
    } else {
      validatePassword()
    }
    if (name === '') {
      setError_name('Please enter a full name')
      setClassname('input-danger')
    } else {
      // console.log("name ok at all");
      validateName()
    }
    if (phone === '') {
      setError_phone('Please enter a number')
      setClassphone('input-danger')
    } else {
      // console.log("name ok at all");
      validatePhone()
    }
    if (role === '') {
      setClassSelect('input-danger')
      setError_Select('Please select the role')
    } else {
      // console.log("name ok at all");
      validateSelect()
    }
    if (
      apiEmail === '' ||
      apiPhoneNo === '' ||
      apiName === '' ||
      apiPassword === '' ||
      apiSelect === ''
    ) {
      // stop calling API
      // console.log("stop calling API");
    } else {
      // calling the fucking API.
      Calling_API()
    }
  }

  const Calling_API = () => {
    setSpin('spinner-border spinner-border-sm')
    const data = {
      phoneNo: apiPhoneNo,
      name: apiName,
      role: apiSelect,
      email: result,
      password: apiPassword,
    }
    axios
      .put(`${API_CONSTANTS.BASEURL}admin`, data, {
        headers: API_CONSTANTS.headers(),
      })
      .then((r) => {
        // console.log(r.data.response.message);
        if (r.data.responseCode === 200) {
          setSpin('')
          handleToast(
            r.data.response.message,
            faCheckCircle,
            '#009B94',
            'show-success'
          )
          setTimeout(() => {
            setEmail('')
            setName('')
            setPhone('')
            setPassword('')
            setRole('')
            setApiEmail('')
            setApiName('')
            setApiPassword('')
            setApiPhoneNo('')
            setApiSelect('')
          }, 2000)
        }
      })
      .catch((error, e) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        handleToast(
          e.response.data.response.message,
          faTimesCircle,
          '#B00020',
          'show-danger'
        )
        setSpin('')
      })
  }

  return (
    <>
      <section className={'content '}>
        <div className={'container-fluid p-3'}>
          <div className={'card w-100'} style={{ height: '800px' }}>
            <div className='d-flex justify-content-between p-4'>
              <span style={{ fontWeight: '600' }}>Add New Admin </span>

              <Link to={'/admins'} className=''>
                <span className='me-2' style={{ color: '#99D7D4' }}>
                  <i className='fas fa-list'></i>
                </span>
                Admin List
              </Link>
            </div>
            <div className={'card-body'}>
              <form style={{ width: '320px' }} autoComplete='none'>
                <div className='group mb-2' style={{ height: 61 }}>
                  <input
                    type='text'
                    autoComplete='off'
                    className={classemail + ' m-0 custom-input-field'}
                    value={email}
                    onChange={getEmail}
                    onBlur={validateEmail}
                    required
                    placeholder='Email'
                  />
                  <span className='highlight' />
                  <p className={'m-0 error-message'}>{error_email}</p>
                </div>
                <div className='group mb-2' style={{ height: 61 }} >
                  <div className='d-flex'>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className={classpassword + ' m-0 custom-input-field'}
                      onChange={getPassword}
                      onBlur={validatePassword}
                      value={password}
                      autoComplete='new-password'
                      required
                      placeholder='Password'
                    />
                    <div className='password-toggle' 
                    style={{borderBottom:'1px solid #cccccc'}}
                    onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <i className='fas fa-eye-slash'></i>
                      ) : (
                        <i className='fas fa-eye'></i>
                      )}
                    </div>
                  </div>
                  <span className='highlight' />
                  <p className={'m-0 error-message'} style={{ width: '650px' }}>
                    {error_password}
                  </p>
                </div>
                <div className='group mb-2' style={{ height: 61 }}>
                  <input
                    type='text'
                    autoComplete='Off'
                    className={classname + ' m-0 custom-input-field'}
                    value={name}
                    onChange={getName}
                    onBlur={validateName}
                    required
                    placeholder='Full Name'
                  />
                  <span className='highlight' />
                  <p className={'m-0 error-message'}>{error_name}</p>
                </div>
                <div
                  className='group mb-2 '
                  style={{ position: 'relative', height: 61 }}
                >
                  {/* <select className='custom-input-field'>
                    <option>+92</option>
                  </select> */}
                  <input
                    type='text'
                    maxLength={phoneLength}
                    autoComplete='off'
                    value={phone}
                    className={classphone + ' m-0  custom-input-field'}
                    onChange={getPhone}
                    onBlur={validatePhone}
                    required
                    placeholder='Phone Number'
                  />
                  <span className='highlight' />
                  <p className={'m-0 error-message'}>{error_phone}</p>
                </div>
                <div className='groupd mb-2' style={{ height: 61 }}>
                  <div
                    className='input-block'
                    style={{ height: 61, marginLeft: '-3px' }}
                  >
                    <select
                      spellCheck='false'
                      className={`select ${classSelect} custom-input-field p-0 py-2`}
                      onChange={getSelect}
                      onBlur={validateSelect}
                      value={role}
                      required
                      style={{ color: '#1E1E1E' }}
                    >
                      <option
                        value=''
                        disabled
                        selected
                        style={{ color: '#8E8E8E' }}
                      >
                        Department
                      </option>

                      <option value='Admin'>Admin</option>
                      <option value='DoctorAdmin'>Doctor Admin</option>
                      <option value='Consultant'>Consultant</option>
                      <option value='HealthCare'>Healthcare Team</option>
                      <option value='CustomerSupport'>Customer Support</option>
                      <option value='AccountsDept'>Accounts Department</option>
                      <option value='DevTeam'>Development Team</option>
                    </select>
                    <p className={'m-0 error-message'}>{error_Select}</p>
                  </div>
                </div>
                <button
                  className='btn-createAccount'
                  onClick={handleSubmitForm}
                >
                  <span className={spin} />
                  <span className='mx-2'>Create Account</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Importing the Toast Component  */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style jsx='true'>
        {`
          .custom-input-field {
            width: 100%;
            border: none;
            border-bottom: 1px solid #cccccc;
          }
          .custom-input-field:focus-visible {
            outline: none;
            border-bottom: 1px solid #009b94;
          }
          .btn-createAccount {
            background: #009b94 !important;
            color: #fff;
            padding: 12px 16px;
            border: none;
            border-radius: 4px;
          }
          .btn-createAccount:hover {
            background: #fff !important;
            color: #009b94 !important;
            border: 1px solid #009b94;
          }
          .error-message {
            font-size: 13px;
            color: red;
          }
        `}
      </style>
    </>
  )
}
