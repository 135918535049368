import "./appointmentOverView.css";
import React, { useState, useReducer } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { API_CONSTANTS } from "../../../../../API_CONSTANTS";
import { useSelector } from "react-redux";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import DoctorSearch from "./DoctorSearch";
import { useParams } from "react-router";
import Toast from "../../../../../Components/Toast/Toast";
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
} from "@fortawesome/fontawesome-free-solid";
// slots reducer function to handle the states
const slotsReducer = (state, action) => {
  switch (action.type) {
    case "API_INIT": {
      return {
        ...state,
        apiLoading: true,
        docId: action.payload,
      };
    }
    case "API_SUCC": {
      return {
        ...state,
        apiLoading: false,
        apiData: action.payload,
      };
    }
    case "API_REJECT": {
      return {
        ...state,
        apiLoading: false,
        error: true,
        errorData: action.payload,
      };
    }
    case "APPOINTMENT_SLOTS": {
      return {
        ...state,
        appointmentSlots: action.payload,
        apiData: [...state.apiData],
      };
    }
    case "RESECDULE_INIT": {
      return {
        ...state,
        uploading: true,
      };
    }
    case "RESECDULE_COMPETE": {
      return {
        ...state,
        uploading: false,
      };
    }
    default: {
      console.log("hello");
    }
  }
};
// Reschedule appointment
export default function Reschedule() {
  // defining state variables
  const { value } = useSelector((state) => state.getConsultationProfileReducer);
  const [slotId, setSlotId] = useState("");
  const [appointDate, setAppointDate] = useState("");
  const params = useParams();
  // selecting doctor Id from redux store
  const doctorId = useSelector(
    (state) => state.getConsultationProfileReducer.value.doctorId
  );
  const [ToastStates, setToastStates] = useState({
    message: "",
    show: "",
    color: "",
    icon: faTimesCircle,
  });
  // --------  This function is for call the Toast message  ------
  const handleToast = (message, icon, iconColor, className) => {
    setToastStates({
      message: message,
      icon: icon,
      color: iconColor,
      show: `show ${className}`,
    });
    setTimeout(() => setToastStates({ ...ToastStates, show: "" }), 2900);
  };
  const [timeSlots, setTimeSlots] = useReducer(slotsReducer, {
    uploading: false,
    apiLoading: false,
    apiData: [],
    error: false,
    errorData: "",
    docId: doctorId,
    appointmentSlots: {
      morning_slots: [],
      evening_slots: [],
      afternoon_slots: [],
    },
  });
  // extracting variable from reducer hook
  const { apiLoading, uploading, apiData, appointmentSlots, docId } = timeSlots;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // onclick data dispatch
  const handleAppointSlots = (data) => {
    setTimeSlots({
      type: "APPOINTMENT_SLOTS",
      payload: data,
    });
  };
  // to show the model
  const handleShow = () => {
    setShow(true);
    getSlotData(doctorId);
  };
  // getting new doctor time slots
  const getSlotData = (id) => {
    setTimeSlots({ type: "API_INIT", payload: id });
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/patients/doctor/appointmentSlots`,
        {
          doctorId: id,
          medium: "Video",
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setTimeSlots({
          type: "API_SUCC",
          payload: res.data.response.Items,
        });
      })
      .catch((error) => {
        handleToast(
          error.data.response.message,
          faExclamationTriangle,
          "#ffffff",
          "show-info"
        );
        setTimeSlots({ type: "API_REJECT", payload: error.data.response });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setTimeSlots({ type: "RESECDULE_INIT" });
    if (slotId && appointDate) {
      axios
        .post(
          `${API_CONSTANTS.BASEURL_1}admin/patients/${value.userId}/appointment/${params.id}/reschedule`,
          {
            doctorId: docId,
            appointmentSlotId: slotId,
            appoint_date: appointDate,
          },
          {
            headers: API_CONSTANTS.headers(),
          }
        )
        .then((res) => {
          setTimeSlots({ type: 'RESECDULE_COMPETE' })
          if (res.data.message === "Success") {
            handleToast(
              "Appointment Rescheduled Successfully",
              faCheckCircle,
              "#009b94",
              "show-success"
            )
          }
        })
        .catch((error) => {
          setTimeSlots({ type: "RESECDULE_COMPETE" });
          setShow(false);
          handleToast(
            error.response.data.response.message,
            faExclamationTriangle,
            "#ffffff",
            "show-info"
          );
        });
    }
  };
  // useEffect(() => {
  //   if (doctorId) {
  //     console.log('hello')
  //     getSlotData()
  //   }
  // }, [])
  return (
    <>
      <Button
        onClick={handleShow}
        className={" btn hover-Reschedule fallowup_class"}
      >
        Reschedule
      </Button>

      <Modal show={show} onHide={handleClose} size="m">
        <Modal.Header closeButton style={{ paddingBottom: 0 }}>
          <span
            className={"d-flex justify-content-center"}
            style={{
              color: "#5C5C5C",
              fontSize: "16px",
              fontWeightL: "600",
              textAlign: "center",
              width: "100%",
            }}
          >
            Reschedule
          </span>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="d-flex flex-column">
              <DoctorSearch doctorSlots={getSlotData} />

              <div className="d-flex flex-column">
                <p
                  className={"pt-2"}
                  style={{
                    fontSize: "30px",
                    color: "#0E1012",
                    fontWeight: "600",
                  }}
                >
                  Schedule
                </p>
                {apiLoading ? (
                  <Spinner animation="border" variant="success" size="sm" />
                ) : (
                  <div className="scrollmenu d-flex pb-2">
                    {apiData?.map((i, index) => (
                      <span
                        onClick={() => handleAppointSlots(i.appointment_slots)}
                        key={index}
                      >
                        <input
                          type='radio'
                          className='btn-check'
                          name='appointDate'
                          id={i.slot_date}
                          value={i.slot_date}
                          onChange={(e) => setAppointDate(e.target.value)}
                        />
                        <label
                          className={`btn date_focused d-flex flex-column p-3 justify-content-center align-items-center labelrdioclass ${
                            appointDate === i.slot_date && "labelSelectedClass"
                          }`}
                          htmlFor={i.slot_date}
                          style={{ padding: "2rem 1rem" }}
                        >
                          <p
                            className="m-0 "
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {moment(i.slot_date).format("DD,MMM")}
                          </p>
                          <p style={{ fontSize: "14px" }}>
                            {i.slot_day.slice(0, 3).toUpperCase()}
                          </p>
                        </label>
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div
                className="d-flex flex-column mt-3"
                style={{
                  overflowY: "scroll",
                  height: appointmentSlots ? "400px" : "auto",
                }}
              >
                <div className="d-flex flex-column">
                  <p className="my-2 mx-1" style={{ color: "#7E7E7E" }}>
                    Morning
                  </p>
                  <div className="d-flex flex-wrap">
                    {appointmentSlots?.morning_slots?.map((slot, index) => (
                      <span key={index}>
                        <input
                          type="radio"
                          className="btn-check"
                          name="morningSlots"
                          id={slot.appointmentSlotId}
                          value={slot.appointmentSlotId}
                          onChange={(e) => setSlotId(e.target.value)}
                          disabled={slot.booked || slot.locked}
                        />
                        <label
                          className={`btn labelrdioclass ${
                            slotId === slot.appointmentSlotId &&
                            "labelSelectedClass"
                          }`}
                          htmlFor={slot.appointmentSlotId}
                        >
                          {moment(slot.start, "HH:mm").format("h:mm A")}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <p className="my-2 mx-1" style={{ color: "#7E7E7E" }}>
                    Afternoon
                  </p>
                  <div className="d-flex flex-wrap">
                    {appointmentSlots?.afternoon_slots?.map((slot, index) => (
                      <span key={index}>
                        <input
                          type="radio"
                          className="btn-check"
                          name="afternoonSlots"
                          id={slot.appointmentSlotId}
                          value={slot.appointmentSlotId}
                          onChange={(e) => setSlotId(e.target.value)}
                          disabled={slot.booked || slot.locked}
                        />
                        <label
                          className={`btn labelrdioclass ${
                            slotId === slot.appointmentSlotId &&
                            "labelSelectedClass"
                          }`}
                          htmlFor={slot.appointmentSlotId}
                        >
                          {moment(slot.start, "HH:mm").format("h:mm A")}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <p className="my-2 mx-1" style={{ color: "#7E7E7E" }}>
                    Evening
                  </p>
                  <div className="d-flex flex-wrap">
                    {appointmentSlots?.evening_slots?.map((slot, index) => (
                      <span key={index}>
                        <input
                          type="radio"
                          className="btn-check"
                          name="eveningSlots"
                          id={slot.appointmentSlotId}
                          value={slot.appointmentSlotId}
                          onChange={(e) => setSlotId(e.target.value)}
                          disabled={slot.booked || slot.locked}
                        />
                        <label
                          className={`btn labelrdioclass ${
                            slotId === slot.appointmentSlotId &&
                            "labelSelectedClass"
                          }`}
                          htmlFor={slot.appointmentSlotId}
                        >
                          {moment(slot.start, "HH:mm").format("h:mm A")}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className={"d-flex justify-content-center my-4"}>
            <Button
              className={"btn"}
              type="submit"
              style={{
                background: "#009B94",
                border: "none",
                width: "400px",
                height: "47px",
              }}
            >
              {uploading && (
                <Spinner animation="border" variant="white" size="sm" />
              )}{" "}
              Confirm Reschedule
            </Button>
          </div>
        </form>
      </Modal>
      <Toast
        cName={ToastStates.show}
        icon={ToastStates.icon}
        color={ToastStates.color}
        text={ToastStates.message}
      />
    </>
  );
}
