import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import Spinner from "react-bootstrap/Spinner";
// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case 'LOADING_TRUE': {
      return {
        ...state,
        loading: true,
      }
    }
    case 'LOADING_FALSE': {
      return {
        ...state,
        loading: false,
      }
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      }
    }
  }

}
//Doctor related filter search form
export default function FilterForm() {
  const filter_status = {
    date: useSelector(
      (state) => state.patientTransactionFilterReducer.value
    ),
    transaction_status: useSelector(
      (state) => state.patientTransactionFilterReducer.value2
    ),
    appoint_date_from: useSelector(
      (state) => state.patientTransactionFilterReducer.value3
    ),
  }
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    date: filter_status.date,
    transaction_status: filter_status.transaction_status,
    appoint_date_from: filter_status.appoint_date_from,
    loading: false,
  })
  //
  const dispatch = useDispatch()
  //
  const { date, transaction_status, appoint_date_from, loading } = formData
  //
  const handleSubmit = (event) => {
    event.preventDefault()
    setFormDataDispatch({ type: 'LOADING_TRUE' })
    if (
      date !== undefined ||
      transaction_status !== undefined ||
      appoint_date_from !== undefined

    ) {
      dispatch({
        type: 'TransactionFilter/date',
        payload: date,
      })
      dispatch({
        type: 'TransactionFilter/appoint_date_from',
        payload: appoint_date_from,
      })
      dispatch({
        type: 'TransactionFilter/' + transaction_status,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} className='mt-4'>
        <div className='for-group mt-3 px-2'>
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, fontSize: 16, color: '#000' }}
          >
            Date From
          </label>
          <input
            onChange={setFormDataDispatch}
            name='appoint_date_from'
            type='date'
            className='form-control filter-input'
            id='appointmentDate'
            value={appoint_date_from}
          />
        </div>
        <div className='for-group mt-3 px-2'>
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, fontSize: 16, color: '#000' }}
          >
            Date
          </label>
          <input
            onChange={setFormDataDispatch}
            name='date'
            type='date'
            className='form-control filter-input'
            id='appointmentDate'
            value={date}
          />
        </div>

        <div className='form-group mt-3 px-2'>
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, fontSize: 16, color: '#000' }}
          >
            Transaction Status
          </label>
          <select
            className='form-control'
            id='exampleFormControlSelect1'
            onChange={setFormDataDispatch}
            name='transaction_status'
            value={transaction_status}
          >
            <option value='all'>All</option>
            <option value='completed'>Completed </option>
            <option value='refunded'>Refunded</option>
          </select>
        </div>
        <button className='btn mt-3 filter-form-button'>


          {loading && <Spinner
            as='span'
            animation='border'
            size='sm'
            role='status'
            aria-hidden='true'
            style={{ marginRight: '20px' }}
          />} {loading || 'Apply'}</button>
      </form>
      <style jsx>{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 95%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
      `}</style>
    </>
  )
}
