import React, { useMemo, useEffect, useState } from 'react'
import Table from '../../../../../Components/Table/index'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner'
import AlertMessage from '../../../../../Components/AlertMessage'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
import Status from './Status'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import CloseIcon from '@mui/icons-material/Close'
import PDelete from '../../../../../resources/images/svgs/Plan_Delete.svg'
import Toast from '../../../../../Components/Toast/Toast'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ReactTagInput from '@pathofdev/react-tag-input'

//Edit Function Component
function Edit({ responseState, UserPlanList }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [isLoading, setIsLoading] = useState(false)
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [includes, setIncludes] = useState(responseState.includes)
  const [selectedSpecialization, setSelectedSpecialization] = useState("")
  const [specialization, setSpecialization] = useState(responseState.speclization)
  const [planCategory, setPlanCategory] = useState(responseState.planCategory)
  // Toast Message Function 
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  const [validDurationSpan, setValidDurationSpan] = useState(responseState.validDurationSpan)
  const initialValues = {
    speclization: responseState.speclization.join(', '),
    planStatus: responseState.planStatus,
    termsAndConditions: responseState.termsAndConditions,
    charges: responseState.charges,
    subscriptionName: responseState.subscriptionName,
    validDuration: responseState.validDuration,
    saveRupees: responseState.saveRupees,
    noOfCalls: responseState.noOfCalls,
  };
  // Velidation Of Form
  const validationSchema = Yup.object({
    // speclization: Yup.string().required('Specialization is required'),
    planStatus: Yup.string().required('Plan status is required'),
    termsAndConditions: Yup.string().required('Terms and conditions are required'),
    charges: Yup.number().min(1, 'Charges must be greater than 0').required('Charges are required'),
    subscriptionName: Yup.string().required('Subscription name is required'),
    validDuration: Yup.number().min(1, 'Valid duration must be greater than 0').required('Valid duration is required'),
    saveRupees: Yup.number().required('Save rupees value is required'),
    noOfCalls: Yup.number().min(1, 'No of calls must be greater than 0').required('No of calls is required')
  })
  // API Integeration 
  const handleSubmit = (values, { setSubmitting }) => {
    setIsLoading(true)
    values.speclization = values.speclization.split(',').map(item => item.trim())
    // values.includes = values.includes.split(',').map(item => item.trim())
    values.includes = includes
    values.validDurationSpan = validDurationSpan
    values.planCategory = planCategory
    axios.patch(
      `${API_CONSTANTS.BASEURL_1}admin/subscriptionplan/${responseState.pId}`,
      values,
      {
        headers: API_CONSTANTS.headers(),
      }
    )
      .then(response => {
        if (response.data.message === 'Success') {
          handleToast(
            'Subscription Plan Update Successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
        }
        UserPlanList()
        handleClose()
      })
      .finally(() => {
        setIsLoading(false)
        setSubmitting(false)
      })
  }
  return (
    <>
      <button className='btn' onClick={handleShow}>
        <i className='fas fa-pen' style={{ color: '#8E8E8E' }}></i>
      </button>
      <Modal show={show} size="lg">
        <Modal.Body className="d-flex flex-column">
          <div className='d-flex justify-content-between'>
            <h4>Edit Subscription Plan</h4>
            <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </div>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            <Form className="form-inline row g-3">
              <div className="col-md-6 mb-4">
                <Field
                  type="text"
                  name="subscriptionName"
                  placeholder="Plan Name"
                  className="form-control border-0 border-bottom"
                />
                <ErrorMessage name="subscriptionName" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <Field
                  type="number"
                  name="saveRupees"
                  placeholder="Save Rupees"
                  className="form-control border-0 border-bottom"
                />
                <ErrorMessage name="saveRupees" component="div" className="error-message" />
              </div>
              <div className="col-md-6 mb-4">
                <Field
                  type="number"
                  name="noOfCalls"
                  placeholder="No. of Calls"
                  className="form-control border-0 border-bottom"
                />
                <ErrorMessage name="noOfCalls" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <Field
                  type="number"
                  name="charges"
                  placeholder="Price In Rupees"
                  className="form-control border-0 border-bottom"
                />
                <ErrorMessage name="charges" component="div" className="error-message" />
              </div>
              <div className="row">
                <label className="form-label" style={{
                  marginTop: '10px',
                  fontSize: '1rem',
                  fontWeight: '500'
                }}>
                  Specialization
                </label>
                <div className="col-md-6 mb-4">
                  <select
                    className="col-md-6 form-control border-0 border-bottom"
                    name="selectedSpecialization"
                    value={selectedSpecialization}
                    onChange={(e) => {
                      setSpecialization([...specialization, e.target.value])
                      setSelectedSpecialization("")
                    }}
                  >
                    <option value="">Choose Specialization</option>
                    <option value="Anesthesiologists">Anesthesiologists</option>
                    <option value="Cardiologists">Cardiologists</option>
                    <option value="Dermatologists">Dermatologists</option>
                    <option value="Endocrinologists">Endocrinologists</option>
                    <option value="Gastroenterologists">Gastroenterologists</option>
                    <option value="Hematologists">Hematologists</option>
                    <option value="Internists">Internists</option>
                    <option value="Medical Geneticists">Medical Geneticists</option>
                    <option value="General Physicians">General Physicians</option>
                  </select>
                  <div className="dropdown">
                    <FontAwesomeIcon icon={faChevronDown} className="drop" />
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  {/* <Field
                  type="text"
                  name="speclization"
                  placeholder="Specialization of Dr.(separated by commas)"
                  className="form-control border-0 border-bottom"
                /> */}

                  <ReactTagInput
                    tags={specialization.length < 1 ? [] : specialization}
                    placeholder={` `}
                    editable={true}
                    name='specialization'
                    value={specialization}
                    removeOnBackspace={true}
                    className="form-control border-0 border-bottom"
                    onChange={(e) => {
                      setSpecialization(e)
                    }}
                    validateOnChange={true}
                  />
                  <ErrorMessage
                    name="speclization"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <p style={{
                marginBottom: '0px',
                fontSize: '1rem',
                fontWeight: '500'
              }}>
                Duration
              </p>
              <div className="col-md-6 mb-4">
                <Field
                  type="number"
                  name="validDuration"
                  placeholder="Number"
                  className="form-control border-0 border-bottom"
                />
                <ErrorMessage name="validDuration" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <div className="dropdown">
                  <select
                    className="col-md-6 form-control border-0 border-bottom"
                    name="validDurationSpan"
                    value={validDurationSpan}
                    onChange={(e) => setValidDurationSpan(e.target.value)}
                  >
                    <option value="">Choose One</option>
                    <option value="days">Days</option>
                    <option value="month">Month</option>
                  </select>
                  <div className="dropdown-icon-container">
                    <i className="fas fa-chevron-down dropdown-icon"></i>
                  </div>
                </div>
                <ErrorMessage name="validDurationSpan" component="div" className="error-message" />
              </div>
              <div className="col-md-6">
                <div className="dropdown">
                  <select
                    className="col-md-6 form-control border-0 border-bottom"
                    name="planCategory"
                    value={planCategory}
                    onChange={(e) => setPlanCategory(e.target.value)}
                  >
                    <option value="">Choose Plan Category</option>
                    <option value="1 month">1 Month</option>
                    <option value="3 months">3 Month</option>
                    <option value="6 months">6 Month</option>
                    <option value="12 months">12 Month</option>
                  </select>
                  <div className="dropdown-icon-container">
                    <i className="fas fa-chevron-down dropdown-icon"></i>
                  </div>
                </div>
                <ErrorMessage name="validDurationSpan" component="div" className="error-message" />
              </div>
              <div className="col-md-12">
                <label className="form-label" style={{
                  fontSize: '1rem',
                  fontWeight: '500'
                }}>
                  What It Includes
                </label>
                <ReactTagInput
                  tags={includes.length < 1 ? [] : includes}
                  placeholder={`Hit Enter to type the next lines`}
                  editable={true}
                  name='includes'
                  value={includes}
                  removeOnBackspace={true}
                  onChange={(e) => {
                    setIncludes(e)
                  }}
                  validateOnChange={true}
                />
                <ErrorMessage name="includes" component="div" className="error-message" />
              </div>
              <div className="col-md-12">
                <label className="form-label" style={{
                  fontSize: '1rem',
                  fontWeight: '500'
                }}>
                  Terms and Conditions
                </label>
                <Field
                  type="text"
                  name="termsAndConditions"
                  placeholder="All Terms and Conditions here"
                  className="form-control"
                  as="textarea"
                />
                <ErrorMessage name="termsAndConditions" component="div" className="error-message" />
              </div>
              <div className="row d-flex justify-content-center mt-5 mb-3">
                <div className="col-md-6">
                  <button type="submit" className="btn btn-save w-100">
                    Save Changes {isLoading && <Spinner animation='border' size='sm' />}
                  </button>
                </div>
                <div className="col-md-6" >
                  <button type="button" className="btn btn-outline-danger w-100" onClick={handleClose} style={{ height: '43px' }}>
                    Discard Changes
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
      <style jsx='true'>
        {`
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
            padding: 10px 10px !important;
          }
          .error-message {
            color: red;
          }
          .add-record {
            background: #009b94 !important;
            color: #fff;
          }
          .dropdown-icon-container {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }
          .drop{
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-140%);
          }
        `}
      </style>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  );
}
// Delete Function Component 
function Delete({ responseState, UserPlanList }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [isLoading, setIsLoading] = useState(false)
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  const handleDelete = () => {
    setIsLoading(true);
    axios
      .delete(`${API_CONSTANTS.BASEURL_1}admin/subscriptionplan/${responseState.pId}`,
        {
          headers: API_CONSTANTS.headers(),
        })
      .then((response) => {
        if (response.data.message === 'Success') {
          handleToast(
            'Subscription Plan Deleted Successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
        }
        setIsLoading(false)
        UserPlanList()
        setShow(false)
      })
  }
  return (
    <>
      <button className='btn' onClick={handleShow}>
        <i className='fas fa-trash-alt' style={{ color: '#FF6760' }}></i>
      </button>
      <Modal show={show}
        className='d-flex justify-content-center align-items-center'
      >
        <Modal.Body className='d-flex justify-content-around align-items-center flex-column' style={{ maxHeight: '255px' }}>
          <div style={{
            width: '32px',
            height: '32px'
          }}>
            <img src={PDelete} alt='' />
          </div>
          <h4 style={{ color: '#AF1300' }}>Delete !</h4>
          <p style={{ textAlign: 'center' }}>Are you sure you want to Delete this subscription Plan?</p>
          <div className='d-flex' style={{ gap: '20px' }}>
            <Button style={{ color: '#FFFFFF', backgroundColor: '#B00020', border: 'none' }} onClick={handleDelete}>
              Delete {isLoading && <Spinner animation='border' size='sm' />}
            </Button>
            <Button
              style={{ color: '#4A4A4A', backgroundColor: '#FFFFFF', border: '1px solid #4A4A4A' }}
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}

// Edit And Delete Component Call Component 
function ActionButtons({ responseState, userPlanList }) {
  return (
    <div style={{ width: "125px" }} className='d-flex'>
      <Edit responseState={responseState} UserPlanList={userPlanList} />
      <Delete responseState={responseState} UserPlanList={userPlanList} />
    </div>
  )
}
// SubScription List Component
export default function SubscriptionUserList() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('')
  const [planList, setPlanList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const fetchIdRef = React.useRef(0)
  //Filters
  const filter_status = {
    status: useSelector((state) => state.nurseFilterReducer.value),
    gender: useSelector((state) => state.nurseFilterReducer.value1),
    // age: useSelector((state) => state.nurseFilterReducer.value2),
    created_at: useSelector((state) => state.nurseFilterReducer.value2),
  }

  //
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = lastEvaluatedKey
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex
        const endRow = startRow + pageSize
        setPlanList(planList.slice(startRow, endRow))
        setPageCount(Math.ceil(planList.length / pageSize))
      }
    },
    [planList, lastEvaluatedKey]
  )
  // Get API of Subscription Plan 
  const userPlanList = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/subscriptionplan`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true)
        setLastEvaluatedKey(res.data.response.Items[0].LastEvaluatedKey)
        setPlanList(res.data.response?.Items[0]?.list)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setIsLoading(true)
        setPlanList([])
      })
  }
  // const [page, setPage] = useState(0)

  // const handleLoadMoreData = () => {
  //   axios
  //     .get(
  //       `${API_CONSTANTS.BASEURL_1}admin/nurses?last=${lastEvaluatedKey}`,
  //       {
  //         headers: API_CONSTANTS.headers(),
  //       }
  //     )
  //     .then((response) => {
  //       setLastEvaluatedKey(response.data.response.LastEvaluatedKey)
  //       var nurseListNextPage = response.data.response.Items
  //       setNurseList([...nurseList, ...nurseListNextPage])
  //       setPage(page + 1)
  //     })
  //     .then(() => setLoading(true))
  // }
  const columns = useMemo(
    () => [
      {
        Header: 'Subscription Name',
        accessor: (data) => data.subscriptionName || 'No Name',
      },
      {
        Header: 'No. Of Calls',
        accessor: 'noOfCalls',
      },
      {
        Header: 'Duration',
        accessor: 'validDuration',
      },
      {
        Header: 'Price in Rupee',
        accessor: 'charges',
      },
      {
        Header: 'Status',
        // accessor: 'planStatus',
        accessor: (responseState) => <Status responseState={responseState} />
      },
      {
        Header: ' ',
        accessor: (responseState) => <ActionButtons responseState={responseState} userPlanList={userPlanList} />,
      },
    ],
    []
  )
  const is_SessionExpire = () => {
    window.localStorage.clear()
    window.location.reload()
  }
  useEffect(() => {
    userPlanList()
  }, [
    filter_status.created_at,
    filter_status.gender,
    filter_status.status,
  ])
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 401'
            ? is_SessionExpire()
            : error.message === 'Request failed with status code 404'
              ? 'record not found'
              : error.message
        } />
    )
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center  '>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    )
  } else {
    return (
      <>
        <div className='row rounded bg-white m-4'>
          <div>
            <h3
              style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}
            >
              All Subscription Plans
            </h3>
          </div>
          <Table
            // pageNumber={page}
            columns={columns}
            data={planList}
            routingLink={''}
            pageCount={pageCount}
            fetchData={fetchData}
            // loadMoreList={handleLoadMoreData}
            lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            setLoading={setLoading}
          // FilterForm={<NurseFilterForm nurseList={userList} />}
          />
        </div>
        <style jsx='true'>{`
          .btn:focus {
            box-shadow: 0 0 0 0.25rem rgb(0 155 148 / 25%) !important;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .patient-search-input {
            border-radius: 0;
          }
        `}</style>

      </>
    )
  }
}
