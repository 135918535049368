import React from 'react'
import AddNewUserButton from '../../../../Components/AddNewUserButton'
import ServicesTableList from './ServicesTableList'
export default function ServicesList() {
  return (
    <>
      <AddNewUserButton
        link={'/services/new'}
        buttonText={'Book Services'}
      />
      <ServicesTableList />
    </>
  )
}
