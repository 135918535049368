/* eslint-disable no-useless-concat */
import './appointmentOverView.css'
import React, { useState, useReducer } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Spinner } from 'react-bootstrap'
import Toast from '../../../../../Components/Toast/Toast'
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
} from '@fortawesome/fontawesome-free-solid'
// slots reducer function to handle the states
const slotsReducer = (state, action) => {
  switch (action.type) {
    case 'API_INIT': {
      return {
        ...state,
        apiLoading: true,
        docId: action.payload,
      }
    }
    case 'API_SUCC': {
      return {
        ...state,
        apiLoading: false,
        apiData: action.payload,
      }
    }
    case 'API_REJECT': {
      return {
        ...state,
        apiLoading: false,
        error: true,
        errorData: action.payload,
      }
    }
    case 'APPOINTMENT_SLOTS': {
      return {
        ...state,
        appointmentSlots: action.payload,
        apiData: [...state.apiData],
      }
    }
    default: {
      console.log('hello')
    }
  }
}
// Reschedule appointment
export default function Reschedule() {
  // defining state variables
  const [slotId, setSlotId] = useState('')
  const [appointDate, setAppointDate] = useState('')
  const [slotTime, setSlotTime] = useState('')
  // const params = useParams()
  // selecting doctor Id from redux store
  const doctorId = useSelector(
    (state) => state.getConsultationProfileReducer.value.doctorId
  )
  const appintId = useSelector(
    (state) => state.getConsultationProfileReducer.value.appointmentId
  )

  const [ToastStates, setToastStates] = useState({
    message: '',
    show: '',
    color: '',
    icon: faTimesCircle,
  })
  // --------  This function is for call the Toast message  ------
  const handleToast = (message, icon, iconColor, className) => {
    setToastStates({
      ...ToastStates,
      icon: icon,
      message: message,
      color: iconColor,
      show: `show ${className}`,
    })
    setTimeout(() => setToastStates({ ...ToastStates, show: '' }), 2900)
  }
  const [timeSlots, setTimeSlots] = useReducer(slotsReducer, {
    apiLoading: false,
    apiData: [],
    error: false,
    errorData: '',
    docId: doctorId,
    appointmentSlots: {
      morning_slots: [],
      evening_slots: [],
      afternoon_slots: [],
    },
  })
  const getSlotId = (id, startTime) => {
    setSlotId(id)
    setSlotTime(startTime)
  }
  // extracting variable from reducer hook
  const { apiLoading, apiData, appointmentSlots,  } =
    timeSlots
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  // onclick data dispatch
  const handleAppointSlots = (data) => {
    setTimeSlots({
      type: 'APPOINTMENT_SLOTS',
      payload: data,
    })
  }
  // to show the model
  const handleShow = () => {
    setShow(true)
    getSlotData(doctorId)
  }
  // getting new doctor time slots
  const getSlotData = (id) => {
    setTimeSlots({ type: 'API_INIT', payload: id })
    axios
      .get(API_CONSTANTS.CONSULTATION_FOLLOWUP_SLOTS(doctorId, appintId), {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setTimeSlots({
          type: 'API_SUCC',
          payload: res.data.response.Items,
        })
      })
      .catch((error) => {
        // setTimeSlots({ type: 'API_REJECT', payload: error.data.response })
        handleToast(
          error.data.response.message,
          faExclamationTriangle,
          '#ffffff',
          'show-info'
        )
        console.log(handleToast())
      })
  }
  const handleSubmit = (e) => {
    e.preventDefault()

    if (slotId && appointDate) {
      axios
        .post(
          API_CONSTANTS.CONSULTATION_FOLLOWUP(doctorId),
          {
            appointmentId: appintId,
            appointmentSlotId: slotId,
            date: appointDate,
          },
          {
            headers: API_CONSTANTS.headers(),
          }
        )
        .then((res) => {
          handleToast(
            res.data.response.message,
            faCheckCircle,
            '#ffffff',
            'show-success'
          )
        })
        .catch((error) => {
          handleToast(
            error.data.response.message,
            faExclamationTriangle,
            '#ffffff',
            'show-info'
          )
        })
    }
  }
  // useEffect(() => {
  //   if (doctorId) {
  //     console.log('hello')
  //     getSlotData()
  //   }
  // }, [])
  return (
    <>
      <Button
        onClick={handleShow}
        className={' btn hover-Reschedule fallowup_class'}
      >
        Follow Up
      </Button>

      <Modal show={show} onHide={handleClose} size='m'>
        <Modal.Header closeButton style={{ paddingBottom: 0 }}>
          <span
            className={' d-flex justify-content-center'}
            style={{
              color: '#5C5C5C',
              fontSize: '16px',
              fontWeightL: '600',
              textAlign: 'center',
              width: '100%',
            }}
          >
            Follow Up
          </span>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className='d-flex flex-column'>
              <div className='d-flex flex-column border-bottom'>
                <p
                  className={'pt-1'}
                  style={{ fontSize: '30px', fontWeight: '600' }}
                >
                  Schedule
                </p>
                {apiLoading ? (
                  <Spinner animation='border' variant='success' size='sm' />
                ) : (
                  <div className='scrollmenu d-flex pb-2'>
                    {apiData?.map((i, index) => (
                      <span
                        onClick={() => handleAppointSlots(i.appointment_slots)}
                        key={index}
                      >
                        <input
                          type='radio'
                          className='btn-check '
                          name='appointDate'
                          id={i.slot_date}
                          value={i.slot_date}
                          onChange={(e) => setAppointDate(e.target.value)}
                        />
                        <label
                          className={`btn date_focused d-flex flex-column p-3 justify-content-center align-items-center labelrdioclass ${
                            appointDate === i.slot_date && 'labelSelectedClass'
                          }`}
                          htmlFor={i.slot_date}
                          style={{ height: '84px', width: '77px' }}
                        >
                          <p
                            className='m-0 '
                            style={{ fontSize: '16px', fontWeight: 'bold' }}
                          >
                            {moment(i.slot_date).format('DD,MMM')}
                          </p>
                          <p style={{ fontSize: '14px' }}>
                            {i.slot_day.slice(0, 3).toUpperCase()}
                          </p>
                        </label>
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div
                className='d-flex flex-column mt-3'
                style={{
                  overflowY: 'scroll',
                  height: appointmentSlots ? '400px' : 'auto',
                }}
              >
                <div className='d-flex flex-column'>
                  <p className='my-2 mx-1' style={{ color: '#7E7E7E' }}>
                    Morning
                  </p>
                  <div className='d-flex flex-wrap'>
                    {appointmentSlots?.morning_slots?.map((slot, index) => (
                      <span key={index}>
                        <input
                          type='radio'
                          className='btn-check'
                          name='morningSlots'
                          id={slot.appointmentSlotId}
                          value={slot.appointmentSlotId}
                          onChange={(e) =>
                            getSlotId(e.target.value, slot.start + ' ' + 'AM')
                          }
                          disabled={slot.booked}
                        />
                        <label
                          className={`btn labelrdioclass ${
                            slotId === slot.appointmentSlotId &&
                            'labelSelectedClass'
                          }`}
                          htmlFor={slot.appointmentSlotId}
                        >
                          {slot?.start + ' AM'}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <p className='my-2 mx-1' style={{ color: '#7E7E7E' }}>
                    Afternoon
                  </p>
                  <div className='d-flex flex-wrap'>
                    {appointmentSlots?.afternoon_slots?.map((slot, index) => (
                      <span key={index}>
                        <input
                          type='radio'
                          className='btn-check'
                          name='afternoonSlots'
                          id={slot.appointmentSlotId}
                          value={slot.appointmentSlotId}
                          onChange={(e) =>
                            getSlotId(e.target.value, slot.start + ' ' + 'PM')
                          }
                          disabled={slot.booked}
                        />
                        <label
                          className={`btn labelrdioclass ${
                            slotId === slot.appointmentSlotId &&
                            'labelSelectedClass'
                          }`}
                          htmlFor={slot.appointmentSlotId}
                        >
                          {slot?.start + ' PM'}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <p className='my-2 mx-1' style={{ color: '#7E7E7E' }}>
                    Evening
                  </p>
                  <div className='d-flex flex-wrap'>
                    {appointmentSlots?.evening_slots?.map((slot, index) => (
                      <span key={index}>
                        <input
                          type='radio'
                          className='btn-check'
                          name='eveningSlots'
                          id={slot.appointmentSlotId}
                          value={slot.appointmentSlotId}
                          onChange={(e) =>
                            getSlotId(e.target.value, slot.start + ' ' + 'PM')
                          }
                          disabled={slot.booked}
                        />
                        <label
                          className={`btn labelrdioclass ${
                            slotId === slot.appointmentSlotId &&
                            'labelSelectedClass'
                          }`}
                          htmlFor={slot.appointmentSlotId}
                        >
                          {slot?.start + ' PM'}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div
            className={
              'd-flex flex-column justify-content-center align-items-center my-4'
            }
          >
            <div
              className='align-self-end px-5'
              style={{ fontSize: 10, color: '#5B5B5B' }}
            >
              <i className='far fa-clock mx-1'></i>

              {moment(appointDate).format('dddd, MMMM, D,') + ' ' + slotTime}
            </div>
            <Button
              className={''}
              type='submit'
              style={{
                background: '#009B94',
                border: 'none',
                width: '400px',
                height: '47px',
              }}
            >
              Confirm Follow Up
            </Button>
          </div>
        </form>
      </Modal>
      <Toast
        cName={ToastStates.show}
        icon={ToastStates.icon}
        color={ToastStates.color}
        text={ToastStates.message}
      />
    </>
  )
}
