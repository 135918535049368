import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import axios from 'axios'
import { API_CONSTANTS } from '../../../API_CONSTANTS'

export default function SeeMore({ feedback, reset }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleFeedBackAPI = () => {
    handleShow()
    axios
      .patch(
        `${API_CONSTANTS.BASEURL_1}admin/users/markread/${feedback.userId}`,
        { Sk: feedback.Sk },
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {})
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
      })
  }

  return (
    <>
      <button onClick={handleFeedBackAPI} className={'bnuttonseemore'}>
        see more
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className={'col-12 d-flex mt-2'}>
            <span className={'col-7 modal-name '}>Name</span>
            <span className={'col-5 modal-name'}>Age</span>
          </div>
          <div className={'col-12 d-flex'}>
            <span className={'col-7 modal-namefetch '}>{feedback.Fname}</span>
            <span className={'col-5 modal-namefetch'}>
              {moment().diff(feedback.dob, 'years') + ' years'}
            </span>
          </div>
          <div className={'col-12 d-flex mt-2'}>
            <span className={'col-7 modal-name '}>Phone #</span>
            <span className={'col-5 modal-name'}>Gender</span>
          </div>
          <div className={'col-12 d-flex'}>
            <span className={'col-7 modal-namefetch '}>{feedback.phoneNo}</span>
            <span className={'col-5 modal-namefetch'}>{feedback.gender}</span>
          </div>
          <div className={'col-12 d-flex mt-2  '}>
            <span className={'col-7 modal-name '}>Date/Time</span>
            <span className={'col-7 modal-name '}>Location</span>
          </div>
          <div className={'col-12 d-flex border-bottom'}>
            <span className={'col-7 modal-namefetch mb-2 '}>
              {feedback ? moment(feedback.created_at).format('dddd') : ''},
              &nbsp;
              {feedback ? moment(feedback.created_at).format('ll') : ''}
              &nbsp;/&nbsp;
              {feedback ? moment(feedback.created_at).format('LT') : ''}
            </span>
            
            <span className={'col-5 modal-namefetch'}>{feedback.profile.location}</span>
          </div>
          <div className={'col-12 d-flex mt-2'}>
            <span className={'modal-description '}>{feedback.feedback}</span>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: 'none' }}></Modal.Footer>
      </Modal>

      <style jsx={'true'}>
        {`
          .modal-description {
            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: #5c5c5c;
          }
          .bnuttonseemore {
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            text-decoration-line: underline;
            color: #00568a;
            border: none;
            background: none;
          }
          .modal-name {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;

            color: #747a7a;
          }
          .modal-namefetch {
            font-family: Poppins;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #1e1e1e;
          }
        `}
      </style>
    </>
  )
}
