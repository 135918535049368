import { createSlice } from "@reduxjs/toolkit";
export const labEditCartSlicer = createSlice({
    name: "labEditCartSlicer",
    initialState: {
        cart: [],
        totalAmount: 0,
    },
    reducers: {
        addToCart: (state, action) => {
            state.cart = [...state.cart, action.payload];
            state.totalAmount = state.cart.reduce(
                (accumulator, item) => accumulator + parseInt(item.totalPrice),
                0
            );
            state.subTotalPrice = state.cart.reduce(
                (accumulator, item) => accumulator + parseInt(item.subTotalPrice),
                0
            );
        },
        addAmount: (state) => {
            state.totalAmount = state.cart.reduce(
                (accumulator, item) => accumulator + item.productPrice,
                0
            );
        },
        removeCartItem: (state, action) => {
            state.cart = state.cart.filter((item) => item.barcode !== action.payload);
            state.totalAmount = state.cart.reduce(
                (accumulator, item) => accumulator + parseInt(item.productPrice),
                0
            );
        },
        removeAllItems: (state) => {
            state.cart = [];
            state.totalAmount = 0;
        },
        addQuantity: (state, action) => {
            state.cart = state.cart.map((cartItem) => {
                if (cartItem.barcode === action.payload.barcode) {
                    cartItem.totalPrice = action.payload.totalPrice
                    cartItem.subTotalPrice = action.payload.subTotalPrice
                    return {
                        ...cartItem,
                        quantity: action.payload.quantity,
                    }
                } else return cartItem
            })
            state.totalAmount = state.cart.reduce(
                (accumulator, item) => accumulator + parseInt(item.totalPrice),
                0
            );
        }
    },
});

export const { addToCart } = labEditCartSlicer.actions;
export const labEditCartReducer = labEditCartSlicer.reducer;
