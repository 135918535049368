import React, { useMemo, useEffect, useState } from 'react';
import Table from '../../../../Components/Table/index';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import 'react-toastify/dist/ReactToastify.css';
// import FilterForm from './FilterForm'
//
import AlertMessage from '../../../../Components/AlertMessage';
import { API_CONSTANTS } from '../../../../API_CONSTANTS/index';
import ActionButtons from './ActionButtons';

export default function RecordTable() {
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const fetchIdRef = React.useRef(0);
  const [responseState, setResponseState] = useState(null); // 
  // console.log(responseState)
  //

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setTransactions(transactions.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(transactions.length / pageSize));
      }
    },
    [transactions, lastEvaluatedKey]
  );
  //
  // API Hatching data of all transactions
  const MEDICINELIST_API = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/medicines`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
        setTransactions(res.data.response.Items);
        setResponseState(res.data.response.Items);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setIsLoading(true);
        setError(error);
      });
  };

  // search medicines function
  const [medicineName, setMedicineName] = useState('');
  
  const [isLoadingg, setIsLoadingg] = useState(false);
  const [validationError, setValidationError] = useState('');

  const handleMedicineNameChange = (event) => {
    setMedicineName(event.target.value);
   
    setValidationError('');
  };



  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate inputs
    if (!medicineName) {
      setValidationError('Please enter either Medicine Name ');
      return;
    }

    if (medicineName.length < 1 ) {
      setValidationError('Input length should be at least 1 characters');
      return;
    }

    setIsLoadingg(true);

    const queryParams = new URLSearchParams();
    queryParams.append('medicine_name', medicineName);
  

    axios
      .get(`${API_CONSTANTS.SEARCH_MEDICINELIST()}?${queryParams.toString()}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setTransactions(res.data.response)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
      })
      .finally(() => {
        setIsLoadingg(false);
      });
  };

  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/medicines?last=${lastEvaluatedKey}`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var patientListNextPage = response.data.response.Items
          ? response.data.response.Items
          : [];
        setTransactions([...transactions, ...patientListNextPage]);
        setPage(page + 1);
      })
      .then(() => setLoading(true));
  };

  useEffect(() => {
    MEDICINELIST_API();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Medicine Name',
        accessor: 'MedicineName',
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Barcode',
        accessor: 'barcode',
      },
      {
        Header: ' ',
        accessor: (responseState) => <ActionButtons responseState={responseState} />,
      },
    ],
    []
  );

  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === 'Request failed with status code 404'
            ? 'This record does not exist'
            : error.message === 'Request failed with status code 401'
            ? is_SessionExpire()
            : error.message
        }
      />
    );
  } else if (!isLoading) {
    return (
      <div className='d-flex justify-content-center mt-4'>
        <Spinner animation='border' style={{ color: '#24AAA4' }} />
      </div>
    );
  } else {
    return (
      <>
         <form className='form-inline' onSubmit={handleSubmit}>
        <div className='row mt-1 mx-2 md-1'>
          <div className='col-3 d-flex'>
            <div className='form-group'>
              <input
                type='text'
                className='form-control'
                id='inputMedicineName'
                placeholder='Medicine Name'
                value={medicineName}
                onChange={handleMedicineNameChange}
                style={{borderRadius: '0'}}
              />
            </div>
            <button type='submit' className='add-record btn mb-2' style={{borderRadius: '0'}} disabled={isLoadingg}>
            <i className='fas fa-search' style={{borderRadius: '0'}}></i>
              {isLoadingg}
            </button>
          </div>
        </div>
        {validationError && (
          <div className='row mt-2 mx-2'>
            <div className='col-12'>
              <p className='text-danger'>{validationError}</p>
            </div>
          </div>
        )}
      </form>

        <div className='row row rounded bg-white m-4'>
          <div>
            <h3 style={{ fontSize: '16px', fontWeight: '500', padding: '18px' }}>
              All Medicines
            </h3>
          </div>
          <Table
            pageNumber={page}
            columns={columns}
            data={transactions}
            routingLink={''}
            pageCount={pageCount}
            fetchData={fetchData}
            loadMoreList={handleLoadMoreData}
            lastEvaluatedKey={lastEvaluatedKey}
            loading={loading}
            setLoading={setLoading}
            // FilterForm={<FilterForm />}
          />
        </div>
      </>
    );
  }
}
