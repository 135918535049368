import React, { useState, useReducer, useEffect } from 'react'
import {
  faTimesCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import { Modal, Spinner, Button } from 'react-bootstrap'
import TextField from "@mui/material/TextField"
import MenuItem from '@mui/material/MenuItem'
import './booklab.css'
import { API_CONSTANTS } from '../../../../../../API_CONSTANTS'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'
import Toast from '../../../../../../Components/Toast/Toast'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useParams } from 'react-router'
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux'
import { addToCart } from "./LabCartSlice";
// reducer funciton
const reduer = (state, action) => {
  switch (action.type) {
    case "SET_SERVICES": {
      return {
        ...state,
        services: action.payload.services,
      };
    }
    default: {
      return state
    }
  }
};
// booking component
export default function BookService() {
  const [show, setShow] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  // const value = useSelector((state) => state.labCartReducer.cart)
  const { cart } = useSelector((state) => state.labCartReducer)
  const [dateTime, setDateTime] = useState("");
  const [patientName, setPatientName] = useState('')
  const [labName, setLabName] = useState('')
  const [typeTest, setTypeTest] = useState('')
  const [address, setAddress] = useState('')
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [secondaryPhone, setSecondaryPhone] = useState("");
  const [age, setAge] = useState("")
  const [email, setEmail] = useState("")
  const [focused, setFocused] = useState(false);
  const [bookingTest, setBookingTest] = useReducer(reduer, {
    services: [],
  })
  const { services } = bookingTest
  const [ToastStates, setToastStates] = useState({
    message: "",
    show: "",
    color: "",
    icon: faTimesCircle,
  });
  // handling toast
  const handleToast = (message, icon, iconColor, className) => {
    setToastStates({
      ...ToastStates,
      icon: icon,
      message: message,
      color: iconColor,
      show: `show ${className}`,
    });
    setTimeout(() => setToastStates({ ...ToastStates, show: "" }), 2900);
  };
  // handling modal show/hide functions
  const handleClose = () => setShow(false)
  // getting services data
  const getLabsData = () => {
    let param = '';
    if (labName === 'chugtai') {
      param = 'chughtai';
    } else if (labName === 'idc') {
      param = 'idc';
    }
    setShow(true)
    setLoading(true)
    axios
      .get(`${API_CONSTANTS.LABURL}admin/labs/tests?vendor=${param}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        setBookingTest({
          type: "SET_SERVICES",
          payload: {
            services: res.data.response.Items ? res.data.response.Items : [],
          },
        })
        setLoading(false);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      })
  }
  const handleSearch = () => {
    let param = '';
    if (labName === 'chugtai') {
      param = 'chughtai';
    } else if (labName === 'idc') {
      param = 'idc';
    }
    setLoading(true)
    axios
      .get(`${API_CONSTANTS.LABURL}admin/labs/tests`, {
        headers: API_CONSTANTS.headers(),
        params: {
          name: searchQuery,
          vendor: param,
        }
      })
      .then((res) => {
        setSearchResults(res.data.response.Items);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (searchQuery.length >= 1) {
      handleSearch();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);
  function handleSubmit() {
    setIsLoading(true)
    const formattedDate = dayjs(dateTime).format('YYYY-MM-DD');
    const formattedTime = dayjs(dateTime).format('HH:mm');
    const data = {
      patientName: patientName,
      date: formattedDate,
      time: formattedTime,
      labName: labName,
      typeTest: typeTest,
      age: age,
      ...(secondaryPhone && { secondaryPhone: secondaryPhone }),
      ...(email && { email: email }),
      testUid: cart.map(item => ({ uuid: item.Sk, quantity: item.quantity.toString() })),
    }
    axios.post(`${API_CONSTANTS.LABURL}admin/lab/appointments/book/${params.id}`,
      typeTest === "homeSample" ? { ...data, address } : data, {
      headers: API_CONSTANTS.headers()
    })
      .then((res) => {
        if (res.data.message === 'Success') {
          setIsLoading(false)
          handleToast(
            'Lab Test booked Successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
          setBookingTest({
            type: 'SET_SERVICES',
            payload: { services: [] },
          })
          dispatch({
            type: 'labCartSlicer/removeAllItems',
          });
          setLabName('')
          setPatientName('')
          setDateTime('')
          setTypeTest('')
          setAge('')
          setAddress('')
        } else {
          // Handle API error here
          handleToast(
            'Error updating Lab Test',
            faTimesCircle,
            '#B00020',
            'show-error'
          );
        }
      }).catch((error) => {
        setIsLoading(false)
        handleToast(
          'Please Check Date, Time & other info properly while Adding Lab Test',
          faTimesCircle,
          '#B00020',
          'show-error'
        );
      })

  }
  const resetBookingState = () => {
    setLabName('')
    setPatientName('')
    setDateTime('')
    setTypeTest('')
    setAddress('')
    setAge('')
    dispatch({
      type: 'labCartSlicer/removeAllItems',
    });
  }
  const handleDisabled = () => {
    if (typeTest === "homeSample") {
      if (patientName && dateTime && labName && address) {
        return false;
      }
      return true;
    } else {
      if (patientName && dateTime && labName) {
        return false;
      }
      return true;
    }
  };
  const handleFocus = () => {
    setFocused(true)
  }
  const handleBlur = () => {
    setFocused(false)
  }
  const handleAddToCart = (item) => {
    const itemId = item.Sk;
    const cartHasItems = cart.length > 0;
    const firstItemVendor = cartHasItems ? cart[0].vendor : null;

    // Check if the new item's vendor matches the vendor of the items in the cart
    const isVendorMatch = cartHasItems ? item.vendor === firstItemVendor : true;
    if (!isVendorMatch) {
      // If the vendor doesn't match, show a toast message and don't dispatch the add to cart action
      handleToast(
        "Please select tests from the same lab you have selected already",
        faTimesCircle,
        '#B00020',
        'show-danger'
      )
    } else {
      const isAlreadyInCart = cart.some((cartItem) => cartItem.Sk === itemId);
      // Dispatch the addToCart action from the cart slice
      if (!isAlreadyInCart) {
        // Dispatch the addToCart action from the cart slice
        dispatch(addToCart({ ...item, quantity: 1, totalPrice: item.productPrice, subTotalPrice: item.subPrice }));
        handleToast(
          "Test Added to Cart",
          faCheckCircle,
          "#009b94",
          "show-success"
        );
      } else {
        // Show a message or notification indicating that the test is already in the cart
        handleToast(
          "Test is already in the Cart",
          faCheckCircle,
          "#009b94",
          "show-success"
        );
      }
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          patientName: patientName,
          dateTime: dateTime,
          labName: labName,
          typeTest: typeTest,
          address: address,
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, touched, errors }) => (
          <Form>
            <section className={"form-group"}>
              <div className={"container-fluid"} style={{ padding: "32px" }}>
                <div className={"bg-white w-100"}>
                  <div className={"card_body"} style={{ padding: "1rem" }}>
                    <span> Book Test </span>
                    <div className="row mt-4 gap-2">
                      <div className={"col-md-5"}>
                        <Field
                          as={TextField}
                          type="text"
                          name="patientName"
                          value={patientName}
                          onChange={(e) => setPatientName(e.target.value)}
                          label="Patient Name"
                          variant="outlined"
                          fullWidth
                          error={touched.patientName && !!errors.patientName}
                          helperText={touched.patientName && errors.patientName}
                        />
                      </div>
                      <div className={"col-md-3"}>
                        <Field
                          as={TextField}
                          type="number"
                          name="age"
                          value={age}
                          onChange={(e) => setAge(e.target.value)}
                          label="Age (in Years)"
                          variant="outlined"
                          fullWidth
                          error={
                            touched.secondaryPhone && !!errors.secondaryPhone
                          }
                          helperText={
                            touched.secondaryPhone && errors.secondaryPhone
                          }
                        />
                      </div>
                      <div className={"col-md-3"}>
                        <TextField
                          type={focused ? "datetime-local" : "text"}
                          name="scheduleDateTime"
                          value={dateTime}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onChange={(e) => setDateTime(e.target.value)}
                          label="Select Date and Time"
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="row gap-2">
                      <div className="col-md-5 my-4">
                        <TextField
                          select
                          name="labName"
                          value={labName}
                          onChange={(e) => setLabName(e.target.value)}
                          label="Lab"
                          variant="outlined"
                          fullWidth
                          error={touched.labName && !!errors.labName}
                          helperText={touched.labName && errors.labName}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="chugtai">Chugtai Lab</MenuItem>
                          <MenuItem value="idc">
                            Islamabad Diagnostic Centre
                          </MenuItem>
                        </TextField>
                      </div>
                      <div className="col-md-6 my-4">
                        <TextField
                          select
                          name="typeTest"
                          value={typeTest}
                          onChange={(e) => setTypeTest(e.target.value)}
                          label="Type"
                          variant="outlined"
                          fullWidth
                          error={touched.typeTest && !!errors.typeTest}
                          helperText={touched.typeTest && errors.typeTest}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="homeSample">Home Sample</MenuItem>
                          <MenuItem value="labVisit">Lab Visit</MenuItem>
                        </TextField>
                      </div>
                    </div>
                    <div className="row gap-2">
                      {typeTest === "homeSample" ?
                        <div className={"col-md-5"}>
                          <Field
                            as={TextField}
                            type="text"
                            name="address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            label="Address"
                            variant="outlined"
                            fullWidth
                            error={touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                          />
                        </div> : null}
                      <div className={"col-md-3"}>
                        <Field
                          as={TextField}
                          type="number"
                          name="secondaryPhone"
                          value={secondaryPhone}
                          onChange={(e) => setSecondaryPhone(e.target.value)}
                          label="Phone No. (Optional)"
                          variant="outlined"
                          fullWidth
                          error={
                            touched.secondaryPhone && !!errors.secondaryPhone
                          }
                          helperText={
                            touched.secondaryPhone && errors.secondaryPhone
                          }
                        />
                      </div>
                      <div className={"col-md-3"}>
                        <Field
                          as={TextField}
                          type="text"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          label="Email (Optional)"
                          variant="outlined"
                          fullWidth
                          error={touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                        />
                      </div>
                    </div>
                    <div className="row mt-1 py-2 gutter-flx">
                      <div className="col-md-11">
                        <div
                          className={
                            "justify-content-between d-flex border-bottom my-4"
                          }
                        >
                          <span>Test</span>
                          <Button
                            onClick={getLabsData}
                            style={{ backgroundColor: '#009B94', marginBottom: '3px', border: 'none' }}
                            disabled={!labName}>
                            Add
                          </Button>
                        </div>
                        <ul className="testList">
                          {cart.map((item, index) => (
                            <li
                              className="cart w-100"
                              key={index}
                            >
                              <div className="d-flex justify-content-between flex-wrap">
                                <h6>
                                  {" "}
                                  Test:{" "}
                                  {item.MedicineName.length > 20
                                    ? item.MedicineName.slice(0, 20) + "..."
                                    : item.MedicineName}{" "}
                                </h6>
                                <p style={{ color: '#009B94', fontWeight: '500' }}>
                                  {item.discount}% off
                                </p>
                              </div>
                              <div className="card_footer d-flex justify-content-between align-items-center flex-wrap">
                                <p style={{ color: "#009B94", fontWeight: '600' }}>
                                  <span>Rs.{" "}{item.totalPrice}{" "}</span>
                                  <span style={{ textDecoration: "line-through", color: '#989898' }}>Rs.{" "}{item.subTotalPrice}</span>
                                </p>
                                <div
                                  className="modal_count justify-content-between align-items-center d-flex border p-0"
                                  key={index}
                                >
                                  {item.quantity > 1 ? (
                                    <button
                                      className='cartButton'
                                      type="button"
                                      onClick={() => dispatch({
                                        type: 'labCartSlicer/addQuantity',
                                        payload: {
                                          barcode: item.barcode,
                                          quantity: item.quantity - 1,
                                          totalPrice: parseInt(item.totalPrice) - parseInt(item.productPrice),
                                          subTotalPrice: parseInt(item.subTotalPrice) - parseInt(item.subPrice)
                                        }
                                      })}
                                    >
                                      <RemoveIcon
                                        style={{
                                          borderRight: "1px solid #D7DDEA",
                                          color: "#009B94",
                                          width: "20px",
                                        }}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      className='cartButton'
                                      type="button"
                                      onClick={() => dispatch({
                                        type: 'labCartSlicer/removeCartItem',
                                        payload: item.barcode
                                      })}
                                    >
                                      <DeleteOutlineIcon
                                        style={{
                                          borderRight: "1px solid #D7DDEA",
                                          color: "#009B94",
                                          width: "20px",
                                        }}
                                      />
                                    </button>
                                  )}
                                  <p>{item.quantity}</p>
                                  <button
                                    className='cartButton'
                                    type="button"
                                    onClick={() => dispatch({
                                      type: 'labCartSlicer/addQuantity',
                                      payload: {
                                        barcode: item.barcode,
                                        quantity: item.quantity + 1,
                                        totalPrice: parseInt(item.productPrice) + parseInt(item.totalPrice),
                                        subTotalPrice: parseInt(item.subPrice) + parseInt(item.subTotalPrice)
                                      }
                                    })}
                                  >
                                    <AddIcon
                                      style={{
                                        borderLeft: "1px solid #D7DDEA",
                                        color: "#009B94",
                                        width: "20px",
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className='mt-5'>
                      <button className='book' onClick={handleSubmit} disabled={handleDisabled()}>
                        {isLoading && <Spinner animation="border" size="sm" />}
                        Book Test
                      </button>
                      <button className='discard' type='reset' onClick={resetBookingState}>Discard</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Form>
        )}
      </Formik>
      <Modal show={show} onHide={handleClose} size="m" style={{}}>
        <Modal.Header closeButton style={{ paddingBottom: 0 }}>
          <span
            className={" d-flex justify-content-center"}
            style={{
              color: "#5C5C5C",
              fontSize: "16px",
              fontWeightL: "600",
              textAlign: "center",
              width: "100%",
            }}
          >
            Add Labs Test
          </span>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className='d-flex gap-2 mb-5'>
              <input placeholder='Search Test Here' value={searchQuery}
                className='p-2 w-100 rounded searchTest'
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className='d-flex flex-column' style={{ maxHeight: '450px', overflowY: 'auto' }}>
              <div className="d-flex justify-content-center pb-2">
                {loading && <Spinner animation="border" size="sm" style={{ color: '#009b94' }} />}
              </div>
              {searchQuery ? (
                searchResults.map((item, index) => (
                  <div
                    className="card w-100 mb-3"
                    style={{
                      boxShadow: "0px 1px 2px rgba(30, 30, 30, 0.16)",
                      borderRadius: "12px",
                    }}
                    key={index}
                  >
                    <div className="card_body_modal">
                      <div className="d-flex justify-content-between">
                        <div className="modal_des">
                          <h6>{item.MedicineName}</h6>
                          <p style={{ color: "#009B94", fontWeight: '600' }}>
                            <span>Rs.{" "}{item.productPrice}{" "}</span>
                            <span style={{ textDecoration: "line-through", color: '#989898' }}>Rs.{" "}{item.subPrice}</span>
                          </p>
                        </div>
                        <div className="d-flex flex-column justify-content-between" style={{ color: '#009B94', textAlign: 'end' }}>
                          <p>
                            {item.discount}% off
                          </p>
                          <Button className='addTestButton' onClick={() => handleAddToCart(item)}>
                            Add Test
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                services.map((item, index) => (
                  <div
                    className="card w-100 mb-3"
                    style={{
                      boxShadow: "0px 1px 2px rgba(30, 30, 30, 0.16)",
                      borderRadius: "12px",
                    }}
                    key={index}
                  >
                    <div className="card_body_modal">
                      <div className="d-flex justify-content-between">
                        <div className="modal_des">
                          <h6>{item.MedicineName}</h6>
                          <p style={{ color: "#009B94", fontWeight: '600' }}>
                            <span>Rs.{" "}{item.productPrice}{" "}</span>
                            <span style={{ textDecoration: "line-through", color: '#989898' }}>Rs.{" "}{item.subPrice}</span>
                          </p>
                        </div>
                        <div className="d-flex flex-column justify-content-between" style={{ color: '#009B94', textAlign: 'end' }}>
                          <p>
                            {item.discount}% off
                          </p>
                          <Button className='addTestButton' onClick={() => handleAddToCart(item)}>
                            Add Test
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </Modal.Body>
        </form>
      </Modal>
      <Toast
        cName={ToastStates.show}
        icon={ToastStates.icon}
        color={ToastStates.color}
        text={ToastStates.message}
      />
      <style jsx="true">
        {`
          .btn:focus {
            box-shadow: none !important;
          }
          .btn:active {
            border: none !important;
          }
          .searchTest{
            border: 1px solid #D7DDEA;
            border-radius: 6px;
            outline: none;
          }
        `}
      </style>
    </>
  );
}
