import React, { Component } from 'react'
import ConsultationsList from './Components/ConsultationList'
//
export default function Consultations() {
  return (
    <>
      <ConsultationsList />
    </>
  )
}
