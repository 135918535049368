import React, { useState, useReducer } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'
import { API_CONSTANTS, headers } from '../../../API_CONSTANTS'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Spinner } from 'react-bootstrap'
import DoctorSearch from './DoctorSearch'
import { useParams } from 'react-router'
import { useTable } from 'react-table'

import {
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
} from '@fortawesome/fontawesome-free-solid'
// slots reducer function to handle the states
const slotsReducer = (state, action) => {
  switch (action.type) {
    case 'API_INIT': {
      return {
        ...state,
        apiLoading: true,
        docId: action.payload,
      }
    }
    case 'API_SUCC': {
      return {
        ...state,
        apiLoading: false,
        apiData: action.payload,
      }
    }
    case 'API_REJECT': {
      return {
        ...state,
        apiLoading: false,
        error: true,
        errorData: action.payload,
      }
    }
    case 'APPOINTMENT_SLOTS': {
      return {
        ...state,
        appointmentSlots: action.payload,
        apiData: [...state.apiData],
      }
    }
    default: {
      console.log('hello')
    }
  }
}

// Reschedule appointment
export default function Reschedule(props) {
  // defining state variables
  const [slotId, setSlotId] = useState({})
  const [appointDate, setAppointDate] = useState('')
  const params = useParams()

  // selecting doctor Id from redux store
  const doctorId = useSelector(
    (state) => state.getConsultationProfileReducer.value.doctorId
  )
  const [ToastStates, setToastStates] = useState({
    message: '',
    show: '',
    color: '',
    icon: faTimesCircle,
  })
  // --------  This function is for call the Toast message  ------
  const handleToast = (message, icon, iconColor, className) => {
    setToastStates({
      ...ToastStates,
      icon: icon,
      message: message,
      color: iconColor,
      show: `show ${className}`,
    })
    setTimeout(() => setToastStates({ ...ToastStates, show: '' }), 2900)
  }
  const [timeSlots, setTimeSlots] = useReducer(slotsReducer, {
    apiLoading: false,
    apiData: [],
    error: false,
    errorData: '',
    docId: doctorId,
    appointmentSlots: {
      morning_slots: [],
      evening_slots: [],
      afternoon_slots: [],
    },
  })
  // extracting variable from reducer hook
  const { apiLoading, apiData, error, errorData, appointmentSlots, docId } =
    timeSlots
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  // debit card and video state f
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValueVideo, setSelectedValueVideo] = useState('');
  // patient get profiles data
  const [patientList, setpatientList] = useState([]);
    const [profileData , setprofileData] = useState({})
//console.log(patientList[0].Id)
const [uuid , setUuid] = useState('')

  const [patientSearch, setPatientSearch] = useState({
    number: '',
    countryCode: '+92',
  })
  const { number, countryCode } = patientSearch
  // onclick data dispatch
  const handleAppointSlots = (data) => {
    setTimeSlots({
      type: 'APPOINTMENT_SLOTS',
      payload: data,
    })
  }

  // to show the model
  const handleShow = () => {
    setShow(true)
//    getSlotData(doctorId)
  }
  // getting new doctor time slots

  // patient Search list


  const getSlotData = (id) => {
    setTimeSlots({ type: 'API_INIT', payload: id })
    axios
      .post(
        `${API_CONSTANTS.BASEURL_1}admin/patients/doctor/appointmentSlots`,
        {
          doctorId: id,
          medium: 'Video',
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setTimeSlots({
          type: 'API_SUCC',
          payload: res.data.response.Items,
        })
      })
      .catch((error) => {
        handleToast(
          error.data.response.message,
          faExclamationTriangle,
          '#ffffff',
          'show-info'
        )
        setTimeSlots({ type: 'API_REJECT', payload: error.data.response })
      })
  }
  console.log(props.amountProp)
const handleSubmit = (e) => {
  e.preventDefault();
  
    if (slotId && appointDate && uuid) {
      axios
        .post(
          `${API_CONSTANTS.BASEURL_1}admin/patients/${uuid}/bookAppointment`,
          {
            doctorId: docId,
            appointmentSlotId: slotId.appointmentSlotId,
            appoint_date: appointDate,
            paymentType: selectedValue,
            communicationMode: selectedValueVideo,
            appointmentId: props.orderId,
            patientId: profileData.profileId,
            patientName: profileData.name,
            appoint_start_time: slotId.start,
            appoint_end_time: slotId.end,
            amount : parseFloat(props.amountProp)
          },
          {
            headers: API_CONSTANTS.headers(),
          }
        )
        .then((res) => {
          handleToast(
            res.data.response.message,
            faCheckCircle,
            '#ffffff',
            'show-success'
          );
        })
        .catch((error) => {
          handleToast(
            error.data.response.message,
            faExclamationTriangle,
            '#ffffff',
            'show-info'
          );
        });
    }
 
};

                // columns
  const columns = React.useMemo(
    () => [
       {
        Header: ' #',
         accessor: (res) => <input className="form-check-input" type="radio" id={res.profileId} value={res.profileId}
         name='id-for-patient'
            onChange={(e) => setprofileData({ profileId: res.profileId, name: res.name})}
         style={{borderRadius:"0px" , color:'rgb(0, 155, 148)'}} />,
      },
      {
        Header: 'Name',
         accessor: (res) => res?.name,
      },

      {
        Header: 'Relation',
          accessor: (res) => res?.relation,

      },
      {
        Header: 'Age',
           accessor: (res) =>
          !res.dob
            ? ''
            : moment().diff(res.dob, 'year') + ' years',
      },


    ],
    []
  )
   const data =  patientList[0]?.profiles ? patientList[0]?.profiles : []


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data })
  // patient search profile function
  // columns
   const handleSearchInput = (event, state) => {
    if (event.reset) {
      return { number: '', countryCode: '+92' }
    }
    return setPatientSearch({
      ...state,
      number: event.target.value.slice(0, event.target.maxLength),
      countryCode: '+92',
    })
  }
  const handlePatientSearch = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_1}admin/patientSearch/${patientSearch.countryCode}${patientSearch.number}`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )

      .then((res) => {
      setpatientList(res.data.response.Items)
      setUuid(res.data.response.Items[0].Id)
      })
      .then(() => setPatientSearch({ countryCode: '+92', number: '' }))
      .catch((error) => alert(error.response.data.response.message))
  }

  return (
    <>
      <Button
        onClick={handleShow}
        className={' btn hover-Reschedule fallowup_class'}
      >
        Book Appointment
      </Button>

      <Modal show={show} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <span
            className={'d-flex justify-content-center'}
            style={{ color: '#5C5C5C', fontSize: '14px', fontWeightL: '600' }}
          >
           Book Appointment
          </span>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
           <div className="row">
             <div className="col-6"> <Modal.Body>
            <div className='d-flex flex-column'>
              <DoctorSearch doctorSlots={getSlotData} />

              <div className='d-flex flex-column'>
                <p
                  className={'pt-2'}
                  style={{
                    fontSize: '30px',
                    fontWeight: '700px',
                    color: '#0E1012',
                  }}
                >
                  Schedule
                </p>
                {apiLoading ? (
                  <Spinner animation='border' variant='success' size='sm' />
                ) : (
                  <div className='scrollmenu d-flex pb-2'>
                    {apiData?.map((i, index) => (
                      <span
                        onClick={() => handleAppointSlots(i.appointment_slots)}
                        key={index}
                      >
                        <input
                          type='radio'
                          className='btn-check '
                          name='appointDate'
                          id={i.slot_date}
                          value={i.slot_date}
                          onChange={(e) => setAppointDate(e.target.value)}
                        />
                        <label
                          className='btn labelrdioclass date_focused d-flex flex-column p-3'
                          htmlFor={i.slot_date}
                          style={{ height: '84px', width: '77px' }}
                        >
                          <p className='m-0 ' style={{ fontSize: '14px' }}>
                            {moment(i.slot_date).format('DD,MMM')}
                          </p>
                          <p style={{ fontSize: '14px' }}>
                            {i.slot_day.slice(0, 3).toUpperCase()}
                          </p>
                        </label>
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div
                className='d-flex flex-column mt-3'
                style={{
                  overflowY: 'scroll',
                  height: appointmentSlots ? '400px' : 'auto',
                }}
              >
                <div className='d-flex flex-column'>
                  <p className='my-2 mx-1'>Morning</p>
                  <div className='d-flex flex-wrap'>
                    {appointmentSlots?.morning_slots?.map((slot, index) => (
                      <span key={index}>

                     <input
                          type='radio'
                          className='btn-check'
                              name='morningSlots'
                          id={slot.appointmentSlotId}
                           value={slot.appointmentSlotId}
                          onChange={(e) => setSlotId({ start: slot.start, end: slot.end, appointmentSlotId: e.target.value })}
                              disabled={slot.booked}
                           />

                        <label
                          className='btn labelrdioclass'
                          htmlFor={slot.appointmentSlotId}
                        >
                          {slot?.start}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <p className='my-2 mx-1'>Afternoon</p>
                  <div className='d-flex flex-wrap'>
                    {appointmentSlots?.afternoon_slots?.map((slot, index) => (
                      <span key={index}>
                        <input
                          type='radio'
                              className='btn-check'
                           name='afternoonSlots'
                           id={slot.appointmentSlotId}
                           value={slot.appointmentSlotId}
                           onChange={(e) => setSlotId({ start: slot.start, end: slot.end, appointmentSlotId: e.target.value })}
                           disabled={slot.booked}
                            />
                        <label
                          className='btn labelrdioclass'
                          htmlFor={slot.appointmentSlotId}
                        >
                          {slot?.start}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <p className='my-2 mx-1'>Evening</p>
                  <div className='d-flex flex-wrap'>
                    {appointmentSlots?.evening_slots?.map((slot, index) => (
                      <span key={index}>
                       <input
                        type='radio'
                      className='btn-check'
                     name='eveningSlots'
                          id={slot.appointmentSlotId}
                          value={slot.appointmentSlotId}
                            onChange={(e) => setSlotId({ start: slot.start, end: slot.end, appointmentSlotId: e.target.value })}
                            disabled={slot.booked}
                                />
                        <label
                          className='btn labelrdioclass '
                          htmlFor={slot.appointmentSlotId}
                        >
                          {slot?.start}
                        </label>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body></div>

               <div className="col-6 flex-wrap ">
                <div className="mx-2">
               <select className="form-select form-select-lg "
                aria-label=".form-select-lg example"
            style={{ height:'55px',marginTop:'32px',}}
               onChange={e => setSelectedValue(e.target.value)}>
            <option selected> Select Payment Method </option>
              <option value="DebitCard">Debit Card</option>
           <option value="jazzcash">Jazz Cash</option>
              <option value="easypaisa">EasyPaisa</option>
           </select>

          <select className="form-select form-select-lg " aria-label=".form-select-lg example" style={{ height:'55px',marginTop:'32px',}}
              onChange={e => setSelectedValueVideo(e.target.value)}
          >
          <option selected> Select Communication Mode  </option>
          <option value="video">Video</option>
           </select>
           </div>

                   <div className="mt-3 mx-2 ">
                     <div className='input-group  ' style={{ width: '100%',    marginTop:'32px', }}>
                      <div className='form-outline d-flex' style={{ width:'92%'}}>
                      <select
                      name='countryCode'
                        style={{
                      borderRadius: '7px 0 0 7px',
                      borderRight: '3px solid #009B94',
                      background: '#009B9414',
                      color: '#4E4E4E99',
                      height:'55px',
                       }}
                     defaultValue={countryCode}
                     >
                     <option selected onChange={handleSearchInput}>
                     +92
                    </option>
                    </select>
                   <input
                   type='number'
                   id='form1'
                   className='form-control patient-search-input'
                   placeholder='Search'
                  value={number}
                  name='number'
                  onChange={handleSearchInput}
                   maxLength='10'
                    />
                </div>
                   <button
               type='button'
               className='btn'
               onClick={handlePatientSearch}
               disabled={
                patientSearch.number.slice(0, 1) === '3' &&
                patientSearch.number.length === 10
                  ? false
                  : true
              }
              style={{ backgroundColor: 'rgb(0, 155, 148)', color: '#fff' }}
            >
              <i className='fas fa-search'></i>
            </button>

              </div>

               </div>

               <div className="mt-4"></div>
    <table
      className='medical-record'
      {...getTableProps()}
      style={{ width: '100%' }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} style={{}}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} style={{}}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
      <style jsx='true'>{`
        .medical-record td {
          text-align: left;
        }
      `}</style>
    </table>
               </div>

               </div>




             <div className={'d-flex justify-content-center mt-5 mb-3'}>
             <Button
              className={'btn'}
              type='submit'
              style={{
                background: '#009B94',
                border: 'none',
                width: '400px',
                height: '47px',
              }}
            >
              Confirm Book Appointment
            </Button>
          </div>
        </form>
      </Modal>

      <style jsx={'ture'}>
        {`
        div.scrollmenu {
         border-radius: 8px;
         overflow: auto;
         white-space: nowrap;
            }

        div.scrollmenu a {
        display: inline-block;
       color: white;
       text-align: center;
       padding: 14px;
      text-decoration: none;
        }

         div.scrollmenu a:hover {
          background-color: #777;
         }
        .labelrdioclass{
        background: #FFFFFF;
          border: 1px solid #CCCCCC;
         border-radius: 8px;
         margin:4px;
         ,
        }
        .labelrdioclass:hover{
         color:#009B94;
        background: rgba(0, 155, 148, 0.08);
         border: 1px solid #009B94;
         border-radius: 8px;
        }
        .date_focused:focus{
       color:#009B94;
        background: rgba(0, 155, 148, 0.08);

        }
        .resetclass {
         font-weight: 400;
         font-size: 16px;
         text-align: center;

          color: #5C5C5C;
        }
          .hover-Reschedule {
            background: #fff;
            border: 1px solid #009b94;
            color: #009b94;
            padding: 8px 15px;
            fontweight: 500;
          }
          .hover-Reschedule:hover {
            background: #009b94;
            border: 1px solid #009b94;
            color: #fff;
            padding: 8px 15px;
            fontweight: 500;

          }
          .fallowup_class:active {
    background: #009b94;
    color: #fff;
    border: 1px solid #009b94;
}
.fallowup_class:focus {
    background: #009b94;
    color: #fff;
    border: 1px solid #009b94;
}

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
        `}
      </style>
    </>
  )
}
