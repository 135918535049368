import React from 'react'
import RecordTable from './RecordTable'
// import AddRecord from './AddRecord'
export default function PatientMedicalRecord() {
  return (
    <>
      <div className='row bg-white mt-2 p-3 rounded'>
        <div className='col-12'>
          <div className='d-flex justify-content-between align-items-center'>
            <span style={{ fontSize: 16, fontWeight: 500 }}>
              Shared Medical Records
            </span>
            {/* <AddRecord /> */}
          </div>
        </div>
        <div className='col-12'>
          <RecordTable />
        </div>
      </div>
    </>
  )
}
