import style from '../../addData.module.css'
import Add from './Add'
import Edit from './Edit'
import Delete from './Delete'
import { useSelector } from 'react-redux'
export default function Conditions() {
  const { loading, value } = useSelector((state) => state.getDataReducer)
  return (
    <div className='container bg-white rounded p-3 mt-5'>
      <div className='row '>
        <div className='row'>
          <div className='col-12'>
            <div className='d-flex justify-content-between align-items-center'>
              <h4>Conditions</h4>
              <Add />
            </div>
          </div>
          {loading ? (
            <div className='d-flex w-100 justify-content-center'>
              <div
                className='spinner-border spinner-border-sm text-success'
                role='status'
              >
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          ) : (
            <div className='col-12 mt-4'>
              <div className='row'>
                {value.Conditions.map((item, indx) => (
                  <div className='col-4 mb-4 box' key={indx}>
                    <div className='d-flex align-items-center rounded border justify-content-between px-3 py-2'>
                      <h6 className='m-0'>{item}</h6>
                      <div className={style.actionbuttons}>
                        <Edit name={item} />
                        <Delete name={item} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <style jsx='true'>{`
      @media screen and (max-width: 1340px) {
        .box{
          width: 50% !important;
        }
      }
      @media only screen and (max-width: 768px) {
        .container{
          max-width: 90% !important;
        }
      }
      @media screen and (max-width: 400px) {
        .box{
          width: 100% !important;
        }
      }
      `} 
      </style>
    </div>
  )
}
