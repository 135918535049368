import axios from 'axios'
import React, { useState, useReducer } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { API_CONSTANTS } from '../../../../../../API_CONSTANTS'
import { useParams } from 'react-router'
import moment from 'moment'
import Edit from './Edit'
import LockDay from './LockDay'
import Toast from '../../../../../../Components/Toast/Toast'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import calendarReloadIcon from '../../../../../../assets/icons/calendar-reload-icon.png'
// slotsReducer
const slotsReducer = (state, action) => {
  switch (action.type) {
    case 'ROTATING_TRUE': {
      return {
        ...state,
        rotating: true,
      }
    }
    case 'ROTATING_FALSE': {
      return {
        ...state,
        rotating: false,
      }
    }
    case 'PENDING': {
      return {
        ...state,
        loading: true,
      }
    }
    case 'FULFILLED': {
      return {
        ...state,
        loading: false,
        value: action.payload,
      }
    }
    default:
      return {
        ...state,
        [action.target.name]: action.target.value,
      }
  }
}

// SlotsCalender
export default function AvailabilityCalender() {
  const [open, setOpen] = useState(false)
  const [slots, setSlots] = useReducer(slotsReducer, {
    loading: false,
    rotating: false,
    value: null,
  })
  const [toast, setToast] = useState({
    icon: '',
    message: '',
    iconColor: '',
    cName: '',
  })
  const { loading, value, rotating } = slots
  const params = useParams()
  // handling collapse button function
  const handleClick = () => {
    if (!open && value === null) {
      setSlots({ type: 'PENDING' })
      axios
        .get(
          `${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/slotting_calendar`,
          {
            headers: API_CONSTANTS.headers(),
          }
        )
        .then((res) =>
          setSlots({ type: 'FULFILLED', payload: res.data.response })
        )
        .catch((err) => {
          setSlots({ type: 'REJECTED', payload: err })
        })
    }
  }
  // day on off fucntion
  const handleDayOnOff = (date) => {
    axios
      .patch(
        `${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/appointment/lockDay`,
        {
          slot_date: date,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setToast({
          icon: faCheckCircle,
          message: res.data.response.message,
          iconColor: '',
          cName: 'show show-success',
        })
        setTimeout(() => {
          setToast({
            icon: '',
            message: '',
            iconColor: '',
            cName: '',
          })
        }, 1500)
      })
      .then(() =>
        axios
          .get(
            `${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/slotting_calendar`,
            {
              headers: API_CONSTANTS.headers(),
            }
          )
          .then((res) =>
            setSlots({ type: 'FULFILLED', payload: res.data.response })
          )
          .catch((err) => {
            setSlots({ type: 'REJECTED', payload: err })
          })
      )
      .catch((err) => {
        setToast({
          icon: faTimesCircle,
          message: err.data.response.message,
          iconColor: '',
          cName: 'show show-danger',
        })
        setTimeout(() => {
          setToast({
            icon: '',
            message: '',
            iconColor: '',
            cName: '',
          })
        }, 1500)
      })
  }
  // handling updated time slot function
  const handleUpdatedTimeSlot = () => {
    setSlots({ type: 'ROTATING_TRUE' })
    axios
      .get(
        `${API_CONSTANTS.BASEURL}admin/doctors/${params.id}/slotting_calendar`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) =>
        // console.log(res)
        setSlots({ type: 'FULFILLED', payload: res.data.response })
        
      )
      .then(() => setSlots({ type: 'ROTATING_FALSE' }))
      .catch((err) => {
        setSlots({ type: 'REJECTED', payload: err })
      })
  }
  return (
    <div className='row m-4 rounded p-2 bg-light'>
      <div className='col-12'>
        <div className='d-flex flex-column'>
          <div className='d-flex justify-content-end' style={{ columnGap: 20 }}>
            <button
              className='d-flex justify-content-end  text-1'
              style={{
                outline: 'none',
                borderRadius: '5px',
                background: 'none',
                border: 'none',
                color: '#009B94',
                fontSize: '25px',
                transition: 'all ease-in 0.5',
              }}
              onClick={handleUpdatedTimeSlot}
            >
              <img
                src={calendarReloadIcon}
                alt=''
                className={rotating ? 'rotating' : ''}
              />
            </button>
            <button
              className='d-flex justify-content-end  text-1'
              onClick={() => {
                setOpen(!open)
                handleClick()
              }}
              aria-controls='example-collapse-text'
              aria-expanded={open}
              style={{
                outline: 'none',
                borderRadius: '5px',
                background: 'none',
                border: 'none',
                color: '#009B94',
                fontSize: '25px',
                transition: 'all ease-in 0.5',
              }}
            >
              {open || <i className='fas fa-sort-down'></i>}
              {open && <i className='fas fa-caret-up'></i>}
            </button>
          </div>

          {open && (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ transition: 'all ease-in 0.5' }}
            >
              {
                <div className='d-flex justify-content-center mt-4 flex-column w-100'>
                  {loading && (
                    <div className='d-flex justify-content-center align-items-center'>
                      <Spinner
                        animation='border text-center'
                        style={{ color: '#24AAA4' }}
                      />
                    </div>
                  )}

                  <div className='d-flex justify-content-start align-items-center w-100 flex-wrap'>
                    {value === null
                      ? []
                      : value.map((item, index) => (
                          <div
                            key={index}
                            className={
                              ' d-flex flex-column justify-content-center align-items-center border m-2 rounded p-1 m-1 ' +
                              (item.booking ? 'booked' : '') +
                              (item.lock_day ? ' disabled' : '')
                            }
                            style={{ width: 222, position: 'relative' }}
                          >
                            <div className='d-flex justify-content-between align-items-center w-100'>
                              <span
                                style={{
                                  color: item.lock_day ? '#CCCCCC' : '#000',
                                }}
                              >
                                {item.slot_day.slice(0, 1).toUpperCase() +
                                  item.slot_day.slice(1, 3) +
                                  ',' +
                                  ' ' +
                                  moment(item.slot_date)
                                    .format('LL')
                                    .slice(0, 3)}
                              </span>
                              <span className='d-flex align-items-center'>
                                <Edit
                                  day={item}
                                  session={item.day_sessions}
                                  booked={item.booking}
                                  update={handleUpdatedTimeSlot}
                                />

                                <LockDay
                                  lock={item.lock_day ? false : true}
                                  booked={item.booking}
                                  date={item.slot_date}
                                  dayAction={handleDayOnOff}
                                />
                              </span>
                            </div>
                            <p
                              className='text-start w-100 display-6'
                              style={{
                                color: item.lock_day ? '#CCCCCC' : '#000',
                              }}
                            >
                              {item.slot_date.slice(-2)}
                            </p>
                            <div className='w-100 d-flex justify-content-start align-items-center flex-column'>
                              {item.day_sessions.map((session, index) => (
                                <span
                                  className='w-100 d-flex justify-content-between align-items-center px-1'
                                  key={index}
                                  style={{
                                    color: item.lock_day ? '#CCCCCC' : '#000',
                                  }}
                                >
                                  <p className='m-0'>
                                    {moment({
                                      year: 2010,
                                      month: 3,
                                      day: 5,
                                      hour: parseInt(
                                        session.startTime.slice(0, 2)
                                      ),
                                      minute: parseInt(
                                        session.startTime.slice(-2)
                                      ),
                                      second: 3,
                                      millisecond: 123,
                                    }).format('LT')}
                                  </p>
                                  <p className='m-0'>
                                    {moment({
                                      year: 2010,
                                      month: 3,
                                      day: 5,
                                      hour: parseInt(
                                        session.endTime.slice(0, 2)
                                      ),
                                      minute: parseInt(
                                        session.endTime.slice(-2)
                                      ),
                                      second: 3,
                                      millisecond: 123,
                                    }).format('LT')}
                                  </p>
                                  <span>
                                    <label
                                      htmlFor='video-chat'
                                      className='form-check-label video-chat'
                                      style={{
                                        cursor: 'pointer',
                                        color:
                                          session.video === true
                                            ? '#009B94'
                                            : '#777',
                                      }}
                                    >
                                      {' '}
                                      <i
                                        className='fas fa-video'
                                        style={{
                                          color: item.lock_day
                                            ? '#CCCCCC'
                                            : '#009B94',
                                        }}
                                      ></i>
                                      <input
                                        type='checkbox'
                                        id='video-chat'
                                        style={{ display: 'none' }}
                                      />
                                    </label>
                                  </span>
                                  <span>
                                    <label
                                      htmlFor='text-chat'
                                      className='form-check-label '
                                      style={{
                                        cursor: 'pointer',
                                        color:
                                          session.chat === true
                                            ? '#009B94'
                                            : '#777',
                                      }}
                                    >
                                      {' '}
                                      <i
                                        className='fas fa-comment-medical'
                                        style={{
                                          color: item.lock_day
                                            ? '#CCCCCC'
                                            : '#777',
                                        }}
                                      ></i>
                                    </label>
                                    <input
                                      type='checkbox'
                                      id='text-chat'
                                      style={{ display: 'none' }}
                                      onChange={() => console.log('hellowrl')}
                                    />
                                  </span>
                                </span>
                              ))}
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              }
            </div>
          )}
        </div>
        <Toast
          cName={toast.cName}
          icon={toast.icon}
          text={toast.message}
          color={toast.iconColor}
        />
      </div>
      <style jsx='true'>{`
        .booked:before {
          content: '';
          position: absolute;
          top: -14px;
          font-size: 11px;
          color: #009b94;
          font-weight: bold;
          margin: 0;
          left: 0;
          padding: 0;
          background: #009b94;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
        .booked:hover:after {
          content: 'Booked';
          position: absolute;
          top: -17px;
          font-size: 11px;
          color: #009b94;
          font-weight: bold;
          margin: 0;
          left: 10px;
          padding: 0;
        }
        .rotating {
          -webkit-animation-name: spin;
          -webkit-animation-duration: 4000ms;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-timing-function: linear;
          -moz-animation-name: spin;
          -moz-animation-duration: 4000ms;
          -moz-animation-iteration-count: infinite;
          -moz-animation-timing-function: linear;
          -ms-animation-name: spin;
          -ms-animation-duration: 4000ms;
          -ms-animation-iteration-count: infinite;
          -ms-animation-timing-function: linear;
          animation-name: spin;
          animation-duration: 4000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
        @keyframes spin {
          from {
            transform: rotate(360deg);
          }
          to {
            transform: rotate(0deg);
          }
        }
      `}</style>
    </div>
  )
}
