/*
Author: Umer shabir
Date: 18-03-2022
File: this is 
*/
// dependencies
import React from 'react'
// modal closing component
function CloseModal({ closeModal }) {
  // call back function to close the modal
  const callbackCloseModel = () => {
    closeModal()
  }
  return (
    <>
      <button
        className='btn btn-hover'
        style={{ color: '#009B93', fontSize: 16 }}
        onClick={callbackCloseModel}
      >
        <span>
          <i className='fas fa-times'></i>
        </span>
      </button>
    </>
  )
}
// Filter resetting button
export default function FilterActionButtons({ closeModal }) {
  return (
    <>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <CloseModal closeModal={closeModal} />
        </div>
      </div>
    </>
  )
}
