import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { chugtai, idc } from "./filterSlice";
// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case "LOADING_TRUE": {
      return {
        ...state,
        loading: true,
      };
    }
    case "LOADING_FALSE": {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      };
    }
  }
}
//Doctor related filter search form
export default function LabFilterForm() {
  const filter_status = {
    test_type: useSelector((state) => state.LabFilterReducer2.value),
    appoint_type: useSelector((state) => state.LabFilterReducer2.value1),
    appoint_date: useSelector((state) => state.LabFilterReducer2.value2),
    appointment_status: useSelector((state) => state.LabFilterReducer2.value3),
  };
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    test_type: filter_status.test_type,
    appoint_type: filter_status.appoint_type,
    appoint_date: filter_status.appoint_date,
    appointment_status: filter_status.appointment_status,
    loading: false,
  });
  const dispatch = useDispatch();
  //
  const { test_type, appointment_status, appoint_date, appoint_type, loading } =
    formData;
  // console.log(formData)
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormDataDispatch({ type: "LOADING_TRUE" });
    if (test_type !== null) {
      dispatch({
        type: "labFilter/" + test_type,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
      }, 1000);
    }
    if (appointment_status !== null) {
      dispatch({
        type: "labFilter/" + appointment_status,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
      }, 1000);
    }
    if (appoint_type !== null) {
      dispatch({
        type: "labFilter/" + appoint_type,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
      }, 1000);
    }
    if (appoint_date !== null) {
      dispatch({
        type: "labFilter/appoint_date",
        payload: appoint_date,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
      }, 1000);
    }
  };
  const handleAppointTypeChange = (event) => {
    setFormDataDispatch({
      type: "UPDATE_FIELD",
      target: { name: "appoint_type", value: event.target.value },
    });
  };
  const handleApplyClick = () => {
    if (appoint_type === "LabBooked#chugtai") {
      dispatch(chugtai());
    } else if (appoint_type === "LabBooked#idc") {
      dispatch(idc());
    }
    // Add dispatch for other filter criteria as needed
  };
  return (
    <>
      <div style={{ marginTop: "-34px", float: "right" }}>
        <button
          className="btn btn-hover"
          style={{ color: "#009B93", fontSize: 16 }}
        >
          <i className="fas fa-redo"></i>
        </button>
      </div>
      <form onSubmit={handleSubmit} className="mt-4 p-2">
        <div className="form-group">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Lab
          </label>
          <select
            className="form-control filter-input"
            onChange={handleAppointTypeChange}
            name="appoint_type"
            value={appoint_type}
          >
            <option value="">All</option>
            <option value="LabBooked#chugtai">Chugtai</option>
            <option value="LabBooked#idc">IDC</option>
          </select>
        </div>
        <div>
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Status
          </label>
          <select
            className="form-control filter-input"
            onChange={setFormDataDispatch}
            name="appointment_status"
            value={appointment_status}
          >
            <option value="">All</option>
            <option value="lab_confirmed">Confirmed</option>
            <option value="lab_completed">Completed</option>
            <option value="lab_missed">Missed</option>
            <option value="lab_inprogress">Inprogress</option>
            <option value="lab_pending">Pending</option>
            <option value="lab_cancelled">Cancelled</option>
          </select>
        </div>
        <div class="form-group mt-3">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Type
          </label>
          <select
            className="form-control"
            onChange={setFormDataDispatch}
            name="test_type"
            value={test_type}
          >
            <option value="">All</option>
            <option value="homeSample">Home Sample</option>
            <option value="labVisit">Lab Visit</option>
          </select>
        </div>
        <div className="form-group mt-3">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Date
          </label>
          <input
            onChange={setFormDataDispatch}
            name="appoint_date"
            type="date"
            className="form-control filter-input"
            value={appoint_date}
          />
        </div>
        <button
          className="btn mt-3 filter-form-button"
          onClick={handleApplyClick}
        >
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: "20px" }}
            />
          )}{" "}
          {loading || "Apply"}
        </button>
      </form>
      <style jsx>{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 100%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
        .btn-hover:hover {
          background: #eee;
        }
      `}</style>
    </>
  );
}
