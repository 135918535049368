import React, { useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case 'LOADING_TRUE': {
      return {
        ...state,
        loading: true,
      }
    }
    case 'LOADING_FALSE': {
      return {
        ...state,
        loading: false,
      }
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      }
    }
  }
}
//Doctor related filter search form
export default function FilterForm() {
  // dispatching constant
  const dispatch = useDispatch()
  // importing patient profile state.
  const profiles = useSelector(
    (state) => state.getPatientProfileReducer.value.profiles.other
  )
  const profilesId = useSelector(
    (state) => state.getPatientProfileReducer.value.profiles.self.profileId
  )
  // importing filter state from the reducer function
  const filter_status = {
    member: useSelector(
      (state) => state.patientMedicalHistoryFilterReducer.value
    ),
    date: useSelector(
      (state) => state.patientMedicalHistoryFilterReducer.value1
    ),
    medicalRecordFrom: useSelector(
      (state) => state.patientMedicalHistoryFilterReducer.value2
    ),
  }

  // initialising reducer hook
  const [formData, setFormDataDispatch] = useReducer(filterReducer, {
    member: filter_status.member,
    date: filter_status.date,
    medicalRecordFrom: filter_status.medicalRecordFrom,
    loading: false,
  })
  // extracting the variable
  const { date, member, medicalRecordFrom, loading } = formData
  // handling submit button
  const handleSubmit = (event) => {
    event.preventDefault()
    setFormDataDispatch({ type: 'LOADING_TRUE' })
    if (medicalRecordFrom !== undefined) {
      dispatch({
        type: 'MedicalHistoryFilter/medicalRecordFrom',
        payload: medicalRecordFrom,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
    if (date !== undefined) {
      dispatch({
        type: 'MedicalHistoryFilter/date',
        payload: date,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
    if (member !== undefined) {
      dispatch({
        type: 'MedicalHistoryFilter/profileId',
        payload: member,
      })
      setTimeout(() => {
        setFormDataDispatch({ type: 'LOADING_FALSE' })
      }, 1000)
    }
  }

  return (
    <>
      <form onSubmit={'handleSubmit'} className='mt-4'>
        <div
          className='form-group mt-3
        px-2'
        >
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, color: '#000', fontSize: 16 }}
          >
            Record For
          </label>
          <select
            className='form-control'
            id='exampleFormControlSelect1'
            onChange={setFormDataDispatch}
            name='member'
            value={member}
          >
            <option name='all' value='All'>
              All
            </option>
            <option name='all' value={profilesId}>
              My Self
            </option>
            {profiles.map((item, index) => (
              <option key={index} value={item.profileId}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className='form-group mt-3 px-2'>
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, color: '#000', fontSize: 16 }}
          >
            Record Date From
          </label>

          <input
            onChange={setFormDataDispatch}
            name='medicalRecordFrom'
            type='date'
            className='form-control filter-input'
            id='exampleFormControlSelect2'
            value={medicalRecordFrom}
          />
        </div>
        <div className='form-group mt-3 px-2'>
          <label
            for='exampleFormControlSelect1'
            style={{ fontWeight: 400, color: '#000', fontSize: 16 }}
          >
            Record Date To
          </label>

          <input
            onChange={setFormDataDispatch}
            name='date'
            type='date'
            className='form-control filter-input'
            id='exampleFormControlSelect2'
            value={date}
          />
        </div>
        <button
          className='btn filter-form-button mt-5 mx-2 d-flex justify-content-center align-items-center'
          style={{ height: 40 }}
          onClick={handleSubmit}
        >
          {loading || 'Apply'}
          {loading && (
            <div
              className='spinner-border spinner-border-sm text-white mx-1'
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </div>
          )}
        </button>
      </form>
      <style jsx>{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 95%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
      `}</style>
    </>
  )
}
