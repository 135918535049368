import { createSlice } from '@reduxjs/toolkit'
//
export const patientServicesFilterSlice = createSlice({
  name: 'ServicesFilters',
  initialState: {
    values: {
      value: '',
      value1: '',
      value2: '',
      value3: '',
    }
  },
  reducers: {
    all: (state, action) => {
      state.values.value = ''
    },
    booked: (state, action) => {
      state.values.value = 'booked'
    },
    missed: (state, action) => {
      state.values.value = 'missed'
    },
    cancelled: (state, action) => {
      state.values.value = 'cancelled'
    },
    InProgress: (state, action) => {
      state.values.value = 'InProgress'
    },
    completed: (state, action) => {
      state.values.value = 'completed'
    },
    unattended: (state, action) => {
      state.values.value = 'unattended'
    },
    called: (state, action) => {
      state.values.value = 'called'
    },
    testCall: (state, action) => {
      state.values.value = 'testCall'
    },
    reschedule: (state, action) => {
      state.values.value = 'reschedule'
    },
    fakeCall: (state, action) => {
      state.values.value = 'fakeCall'
    },
   
    appoint_date_from: (state, action) => {
      const { payload } = action
      state.values.value3 = payload
    },
    appoint_date_to: (state, action) => {
      const { payload } = action
      state.values.value1 = payload
    },
    allTypes: (state, action) => {
      state.values.value2 = ''
    },
    Booked: (state, action) => {
      state.values.value2 = 'Booked'
    },
    Instant: (state, action) => {
      state.values.value2 = 'Instant'
    },
    FollowUp: (state, action) => {
      state.values.value2 = 'FollowUp'
    },
  },
})

export const patientConsultationFilterReducer = patientServicesFilterSlice.reducer