import React, { useState, useReducer } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { getProfile } from '../../../patientProfileSlice'
import toArrayBuffer from 'to-array-buffer'
import { API_CONSTANTS } from '../../../../../../API_CONSTANTS'
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import Toast from '../../../../../../Components/Toast/Toast'

function Edit({ response }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [file, setFile] = useState(
    response.url
      ? response.url
      : 'https://img.icons8.com/material/72/000000/user-male-circle--v1.png'
  )
  // ------------------ toast states
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('') //  using this state for show the toast message
  const [ToastIcon, setToastIcon] = useState(faCheckCircle) // using this state for changing the toast message icon color
  const [iconColor, setIconColor] = useState('') // using this state for changing the toast message icon color
  const [spin, setSpin] = useState('')
  const formReducer = (state, event) => {
    if (event.imageChanged) {
      return {
        name: response.name,
        dob: response.dob,
        relation: response.relation,
        gender: response.gender,
        url: file,
      }
    }
    return {
      ...state,
      [event.target.name]: event.target.value,
      url: file,
    }
  }
  const [formData, setFormData] = useReducer(formReducer, {
    name: response.name,
    dob: response.dob,
    relation: response.relation,
    gender: response.gender,
  })
  const { name, dob, relation, gender } = formData
  const params = useParams()
  const dispatch = useDispatch()
  // uploading the image and displaying
  const uploadFile = (e) => {
    const [file] = e.target.files || e.dataTransfer.files
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    //preview onload function.
    reader.onload = async () => {
      const fileRes = btoa(reader.result)
      const base64Image = toArrayBuffer(fileRes)
      setFile(`data:image/png;base64,${fileRes}`)
      axios
        .post(
          `${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${params.id}/type/${response.profileId}`,
          base64Image,
          {
            headers: API_CONSTANTS.imgheaders(),
          }
        )
        .then((data) => {
          //response
          if (data.status === 200) {
            const profile = data.data.response.data.profiles.find(
              (row) => row.profileId === response.profileId
            )
            setFile(profile.url)
            setFormData({ imageChanged: true })
          } else {
            alert('Some error occue due to some reasom please try again.')
          }
        })
        .catch((e) => alert(e))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    axios
      .patch(
        `${API_CONSTANTS.BASEURL}admin/patients/${params.id}/profile/${response.profileId}`,
        formData,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        handleToast(
          res.data.response.message,
          faCheckCircle,
          '#009B94',
          'show-success'
        )
        dispatch(getProfile(params.id))
      })
      .catch((error) => {
        handleToast(
          error.data.response.message,
          faTimesCircle,
          '#B00020',
          'show-danger'
        )
      })
    setShow(false)
  }
  // ---------------- Handle Toast function
  // --------  This function is for call the Toast message  ------

  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  return (
    <>
      <button className='btn' onClick={handleShow}>
        <i className='fas fa-pen' style={{ color: '#8E8E8E' }}></i>
      </button>
      <Modal show={show}>
        <Modal.Body className='d-flex flex-column'>
          <img src={file} className='align-self-center avatar' alt='avatar' />
          <div className='upload-image'>
            <button
              style={{
                display: 'block',
                fontSize: 25,
                color: '#79E80E',
                padding: 0,
              }}
              onClick={() => document.getElementById('getFile').click()}
              className='btn'
            >
              <i className='fas fa-camera'></i>
            </button>
          </div>
          <form className='d-flex flex-column' onSubmit={handleSubmit}>
            <input
              type='file'
              id='getFile'
              style={{ display: 'none' }}
              onChange={uploadFile}
              accept='image/*'
            />
            <label for='name'> Name</label>
            <input
              type='text'
              name='name'
              className='custom-input-field '
              onChange={setFormData}
              value={name}
              required
            />
            <label for='DateofBirth'> Date of Birth</label>
            <input
              type='date'
              name='dob'
              className='custom-input-field '
              onChange={setFormData}
              value={dob}
              required
            />
            <label for='Relation'>Relation</label>
            <select
              name='relation'
              className='custom-input-field '
              onChange={setFormData}
              value={relation}
              required
            >
              <option value=''>choose an option</option>
              <option value='Son'>Son</option>
              <option value='Daughter'>Daughter</option>
              <option value='Father'>Father</option>
              <option value='Spouse'>Spouse</option>
              <option value='Mother'>Mother</option>
              <option value='Other'>Other</option>
            </select>
            <label for='Gender'>Gender</label>
            <select
              name='gender'
              className='custom-input-field '
              onChange={setFormData}
              value={gender}
              required
            >
              <option value=''>choose an option</option>
              <option value='Other'>Other</option>
              <option value='Female'>Female</option>
              <option value='Male'>Male</option>
            </select>
            <span style={{ marginTop: '10px' }} className='align-self-stretch'>
              <button type='submit' className='btn btn-save w-100'>
                Save Changes
              </button>
            </span>
          </form>
          <button className='btn btn-outline-danger mt-2' onClick={handleClose}>
            Discard
          </button>
        </Modal.Body>
      </Modal>

      <style jsx='true'>
        {`
          .edit-button {
            border: 2px solid #009b94;
            color: #009b94;
          }
          .edit-button:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-save {
            background: #009b94 !important;
            color: #fff;
            padding: 10px 10px !important;
          }
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
          }
          .custom-input-field {
            width: 100%;
            border: none;
            border-bottom: 1px solid #cccccc;
            margin: 10px 0;
          }
          .custom-input-field:focus-visible {
            outline: none;
            border-bottom: 1px solid #009b94;
          }
          label {
            font-size: 12px;
            color: #8e8e8e;
          }
          .error-message {
            font-size: 13px;
            color: red;
          }
          .avatar {
            vertical-align: middle;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-bottom: 30px;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
          }
          .upload-image {
            position: absolute;
            top: 101px;
            left: 266px;
          }
        `}
      </style>
      {/* Importing the Toast Component  */}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}

// Delete
function Delete({ response }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const params = useParams()
  const dispatch = useDispatch()
  // ------------------ toast states
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('') //  using this state for show the toast message
  const [ToastIcon, setToastIcon] = useState(faCheckCircle) // using this state for changing the toast message icon color
  const [iconColor, setIconColor] = useState('') // using this state for changing the toast message icon color
  const [spin, setSpin] = useState('')
  //
  const handleDelete = () => {
    axios
      .delete(
        `${API_CONSTANTS.BASEURL}admin/patients/${params.id}/profile/${response.profileId}`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )

      .then(() => setShow(false))
      .then(() => dispatch(getProfile(params.id)))
      .catch((error) => {
        handleToast(
          error.data.response.message,
          faTimesCircle,
          '#B00020',
          'show-danger'
        )
      })
  }
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  return (
    <>
      <button className='btn' onClick={handleShow}>
        <i className='fas fa-trash-alt' style={{ color: '#FF6760' }}></i>
      </button>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: 18 }}>Caution!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this member?</Modal.Body>
        <Modal.Footer>
          <button
            className='btn'
            style={{
              border: '1px solid #79747E',
              color: '#B00020',
              background: 'none',
            }}
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className='btn'
            style={{ background: '#009B93', color: '#fff' }}
            onClick={handleDelete}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
//
export default function ActionButtons({ response }) {
  return (
    <div>
      {/*edit button and modal */}
      <Edit response={response} />
      {/*delete button and modal */}
      <Delete response={response} />
    </div>
  )
}
