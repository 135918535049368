import React, { useEffect, useState } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { Outlet } from 'react-router'
//
import { useDispatch, useSelector } from 'react-redux'
//
import { getDoctorOverview } from './Overview/doctorOverviewSlice'
//
import EditProfileButton from './EditProfileButton'
import BlockProfileButton from './BlockProfileButton'
import UnblockProfileButton from './UnblockProfileButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
//
export default function DoctorProfilePages() {
  const params = useParams()
  const dispatch = useDispatch()

  const { value } = useSelector((state) => state.getDoctorReducer)
  const [overview, setOverview] = useState(value)

  const PROFILE_NAV_LINKS = [
    {
      navLink: 'Overview',
      navRoute: `/doctors/profile/${params.id}/overview`,
    },
    {
      navLink: 'Availability',
      navRoute: `/doctors/profile/${params.id}/availability`,
    },

    {
      navLink: 'Appointments',
      navRoute: `/doctors/profile/${params.id}/appointments`,
    },

    {
      navLink: 'Delayed Appointments',
      navRoute: `/doctors/profile/${params.id}/delayedappointments`,
    },
    {
      navLink: 'Notifications',
      navRoute: `/doctors/profile/${params.id}/notifications`,
    },
    {
      navLink: 'Logs',
      navRoute: `/doctors/profile/${params.id}/logs`,
    },
    {
      navLink: 'Reviews',
      navRoute: `/doctors/profile/${params.id}/reviews`,
    },
  ]
  useEffect(() => {
    let mounted = true
    if (mounted) {
      dispatch(getDoctorOverview(params.id))
    }
    mounted = false
  }, [])

  return (
    <>
      <div className='container-fluid profile-header'>
        <div className='row pt-4 mb-1 prof'>
          <div className='col'>
            <div className='d-flex ms-4'>
              <div className='patient d-flex ms-4 align-items-start me-4'>
                <span className='me-4'>
                  <div className='patient-avatar'>
                    <img
                      src={
                        value.personal_info
                          ? value.personal_info.profileUrl
                          : ''
                      }
                      alt=''
                      style={{ width: 72, borderRadius: 50, height: 72 }}
                    />
                  </div>
                </span>
                <div className='d-flex flex-column mt-2'>
                  <h5
                    style={{ fontWeight: 500, marginBottom: 15, marginTop: -4 }}
                  >
                    {value.Fname}
                  </h5>
                  <h6
                    style={{
                      fontWeight: 400,
                      color: '#5C5C5C',
                      marginBottom: 0,
                    }}
                  >
                    {'Doctor ID  : ' + value.pmdc}
                  </h6>
                </div>
                <span>
                  {value.is_blocked && (
                    <span
                      className='btn mx-2'
                      style={{ background: '#b00020', color: '#fff' }}
                    >
                      <FontAwesomeIcon icon={faBan} className={'mx-1'} />
                      Blocked
                    </span>
                  )}
                </span>
              </div>
              <div className='col'>
                <div
                  className='d-flex justify-content-end'
                  style={{ columnGap: 5 }}
                >
                  <EditProfileButton
                    overview={value}
                    getDoctorOverview={getDoctorOverview}
                  />
                  {value.is_blocked || (
                    <BlockProfileButton
                      overview={overview}
                      getDoctorOverview={getDoctorOverview}
                    />
                  )}
                  {/* {value.is_blocked || <DeactiveProfileButton />} */}
                  {value.is_blocked && (
                    <UnblockProfileButton
                      overview={overview}
                      getDoctorOverview={getDoctorOverview}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row pt-4'>
          <div className='col'>
            <div className='d-flex ms-4' style={{ columnGap: 10 }}>
              {PROFILE_NAV_LINKS.map((navItem) => (
                <NavLink
                  to={navItem.navRoute}
                  key={navItem.navLink}
                  className={({ isActive }) =>
                    isActive ? 'active' : 'inactive'
                  }
                  style={{ fontSize: 14 }}
                >
                  {navItem.navLink}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='containter-fluid'>
        <Outlet />
      </div>
      <style jsx='true'>
        {`
          .profile-header {
            background: #e0f3f2;
            height: 160px;
          }
          .active {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .active:hover {
            color: #009b94;
            padding: 7px 15px;
            border-bottom: 3px solid #009b94;
          }
          .inactive {
            padding: 7px 15px;
          }
          .border-line {
            border-left: 2px solid #009b94;
            border-radius: 5px;
          }
          @media only screen and (max-width: 1200px) and (min-width: 1050px) {
            .prof{
              margin-bottom: 0px !important;
              padding-top: 10px !important;
            }
          }
          @media screen and (max-width: 950px) {
            .prof{
              margin-bottom: 0px !important;
              padding-top: 10px !important;
            }
          }
        `}
      </style>
    </>
  )
}
