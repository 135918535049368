import React, { useMemo, useEffect, useState } from "react";
import Table from "../../../Components/Table/index";
import axios from "axios";
import { API_CONSTANTS } from "../../../API_CONSTANTS";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { useSelector } from "react-redux";
import style from "../../../Style/corporate.module.css";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
// import PatientFilterForm from "./PatientFilterForm";
import AlertMessage from "../../../Components/AlertMessage";
// styling
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};
const partnerStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
};
export default function Transactions() {
  // variables
  const { value } = useSelector((state) => state.getCorporateProfileReducer);
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [addPartner, setAddPartner] = useState({
    corporateName: "",
    pId: "",
    paymentDate: "",
    paymentType: "",
    charges: "",
    transactionId: "",
  });
  const {
    corporateName,
    pId,
    paymentDate,
    paymentType,
    charges,
    transactionId,
  } = addPartner;
  const [page, setPage] = useState(0);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [patientList, setpatientList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addTransactionLoading, setAddTransactionLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  const [planslist, setPlansList] = useState([]);

  // functions
  const getDataFromFields = (e) => {
    setAddPartner((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleOpen = () => {
    setOpen(true);
    axios
      .get(
        API_CONSTANTS.BASEURL_1 +
          `admin/subscriptionplan?corporateplan=true&activeplan=true`,
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        setPlansList(res.data.response.Items[0].list);
      })
      .catch((err) => console.log(err));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setpatientList(patientList.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(patientList.length / pageSize));
      }
    },
    [patientList, lastEvaluatedKey]
  );
  //
  // API Hatching data of all admins
  const userList = () => {
    axios
      .get(
        `${API_CONSTANTS.BASEURL_4}admin/corporates/${params.id}/tranactions`,
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true);
        setpatientList(res.data.response.Items);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setIsLoading(true);
        setpatientList([]);
        // setError(error)
      });
  };
  //
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_4}admin/corporates/${params.id}/tranactions?last=${lastEvaluatedKey}`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var patientListNextPage = response.data.response.Items;
        setpatientList([...patientList, ...patientListNextPage]);
        setPage(page + 1);
      })
      .then(() => setLoading(true));
  };
  // handle add new partner
  const handleAddTransaction = (e) => {
    setAddTransactionLoading(true);
    console.log(addPartner);
    axios
      .post(
        API_CONSTANTS.BASEURL_4 + `admin/corporates/${value.Id}/tranactions`,
        {
          corporateName: value.companyName,
          pId,
          paymentDate,
          paymentType,
          charges,
          transactionId,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setAddTransactionLoading(false);
        toast(res.data.response.message, {
          type: "success",
        });
        setAddPartner({
          corporateName: "",
          pId: "",
          paymentDate: "",
          paymentType: "",
          charges: "",
          transactionId: "",
        });
      })
      .catch((err) => {
        setAddTransactionLoading(false);
        toast(err.response.data.response.message, {
          type: "error",
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Plan Name",
        accessor: (property) =>
          property.patientInfo
            ? property.patientInfo.planName.charAt(0).toUpperCase() +
              property.patientInfo.planName.slice(1)
            : "UnNamed",
      },
      {
        Header: "Date of Payment",
        accessor: (property) =>
          property.dateTime
            ? moment(property.dateTime).format("YYYY-M-DD")
            : "N/A",
      },

      {
        Header: "Instrument Type",
        accessor: (property) =>
          property.paid_through ? property.paid_through : "N/A",
      },
      {
        Header: "Instrument Number",
        accessor: (item) => item?.transactionId,
      },

      {
        Header: "Price",
        accessor: (item) => item?.amount,
      },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  // rendering API
  useEffect(() => {
    if (patientList.length < 1) {
      userList();
    }
  }, []);
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 404"
            ? "This record does not exist"
            : error.message === "Request failed with status code 401"
            ? is_SessionExpire()
            : error.message
        }
      />
    );
  } else {
    return (
      <>
        <section className={style.corporatePageSection}>
          {/* add new corporate  */}
          <Button
            variant="contained"
            className={style.addPartnerBtn}
            onClick={handleOpen}
          >
            <AddIcon />
            Add New Transaction
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={partnerStyles}>
              <div className={style.addPartnerModalContainer}>
                <Button
                  className={style.closeBtnPartnerModal}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Button>
                <div className={style.addPartnerModal}>
                  <TextField
                    id="standard-basic"
                    label="Company Name"
                    variant="standard"
                    name="companyName"
                    onChange={getDataFromFields}
                    required
                    value={value.companyName}
                    inputProps={{
                      readOnly: true,
                    }}
                  />

                  <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">
                      Select Plan
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={pId}
                      label="Select Plan"
                      name="pId"
                      onChange={getDataFromFields}
                    >
                      <MenuItem value="" selected disabled>
                        --Select--
                      </MenuItem>
                      {planslist.map((item, indx) => (
                        <MenuItem value={item.pId} key={indx}>
                          {item.planName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    id="dateInput"
                    label="Start Date"
                    variant="standard"
                    name="paymentDate"
                    onChange={getDataFromFields}
                    required
                    value={paymentDate}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: moment().format("YYYY-MM-DD"),
                    }}
                  />
                  <TextField
                    id="dateInput"
                    label="Instrument Type"
                    variant="standard"
                    name="paymentType"
                    onChange={getDataFromFields}
                    required
                    value={paymentType}
                  />

                  <TextField
                    id="standard-basic"
                    label="Instrument Number"
                    variant="standard"
                    name="transactionId"
                    onChange={getDataFromFields}
                    required
                    value={transactionId}
                  />
                  <TextField
                    id="standard-basic"
                    label="Payment"
                    variant="standard"
                    name="charges"
                    onChange={getDataFromFields}
                    required
                    type="number"
                    value={charges}
                  />
                </div>
                <div className={style.actionBtnModal + " mt-4 ps-3"}>
                  <LoadingButton
                    className={style.actionBtn}
                    variant="contained"
                    loading={addTransactionLoading}
                    // loadingPosition="end"
                    onClick={handleAddTransaction}
                    // disabled={
                    //   companyName &&
                    //   noOfEmployee &&
                    //   corporateType &&
                    //   phoneNo &&
                    //   email &&
                    //   password
                    //     ? false
                    //     : true
                    // }
                  >
                    Add Transaction
                  </LoadingButton>
                  <Button
                    onClick={handleClose}
                    color="error"
                    className={style.actionBtn}
                  >
                    Discard
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
          {/* corporate table listing  */}
          <div className="row rounded bg-white m-4">
            <h3
              style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}
            >
              {value.companyName} Transactions
              {!isLoading && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "#24AAA4" }}
                />
              )}
            </h3>

            <Table
              pageNumber={page}
              columns={columns}
              data={patientList}
              routingLink={``}
              pageCount={pageCount}
              fetchData={fetchData}
              loadMoreList={handleLoadMoreData}
              lastEvaluatedKey={lastEvaluatedKey}
              loading={loading}
              recordDownload={true}
              setLoading={setLoading}
            />
          </div>
        </section>
        <ToastContainer />
      </>
    );
  }
}
