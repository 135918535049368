/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer } from "react"
import axios from "axios"
import { API_CONSTANTS } from "../../../API_CONSTANTS"
import "./editorfile.css"
import { useParams } from "react-router"
import Editorquill from "./Editorquill"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { NodeHtmlMarkdown } from "node-html-markdown"
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/fontawesome-free-solid"
import Toast from "../../../Components/Toast/Toast"
import toArrayBuffer from "to-array-buffer"
import Spinner from "react-bootstrap/Spinner"
import { Button } from "@mui/material"
import { remark } from "remark"
import remarkHtml from "remark-html"
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import ReactTagInput from '@pathofdev/react-tag-input'

// Reducer Function for Loading Statement
function loaderReducer(state, action) {
  switch (action.type) {
    case "SAVELOADING_INIT": {
      return {
        ...state,
        saveLoading: true,
      }
    }
    case "SAVELOADING_END": {
      return {
        ...state,
        saveLoading: false,
      }
    }
    case "IMAGELOADING_INIT": {
      return {
        ...state,
        imageLoading: true,
      }
    }
    case "IMAGELOADING_END": {
      return {
        ...state,
        imageLoading: false,
      }
    }
    case "PROFILEIMAGE_INIT": {
      return {
        ...state,
        profileImage: true,
      };
    }
    case "PROFILEIMAGE_END": {
      return {
        ...state,
        profileImage: false,
      };
    }
    default: {
      console.log("hello");
    }
  }
}

// Main Editor Function
export default function EditorNew() {
  const [loader, setLoader] = useReducer(loaderReducer, {
    saveLoading: false,
    imageLoading: false,
    profileImage: false,
  })
  const { saveLoading, imageLoading, profileImage } = loader
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [category, setCategory] = useState("")
  const [subCategory, setsubCategory] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const clearCache = () => {
    // Assuming you are using localStorage for caching
    localStorage.removeItem("cachedBlogData");
  }
  const params = useParams();
  const [readtime, setReadtime] = useState('')
  const [file, setFile] = useState(
    "https://www.freeiconspng.com/uploads/upload-icon-16.png"
  )
  const [auth, setAuth] = useState(
    "https://img.icons8.com/material/72/000000/user-male-circle--v1.png"
  );
  const [ToastMessage, setToastMessage] = useState("")
  const [showToast, setShowToast] = useState("")
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState("")
  const [authorName, setAuthorName] = useState("")
  const [notificationBody, setNotificationBody] = useState("")
  const [locationFilter, setLocationFilter] = useState("")
  const [scheduleDateTime, setScheduleDateTime] = useState("")
  const [publish, setPublish] = useState("")
  const [choose, setChoose] = useState('')
  const [blogContent, setBlogContent] = useState([])
  const [isfocused, setIsFocused] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur = () => {
    setIsFocused(false)
  }
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(""), 2900)
  };
  const markdownToPlainText = (markdown) => {
    const plainText = remark().use(remarkHtml).processSync(markdown).toString()
    return plainText;
  }
  const Id = localStorage.getItem("editorId")
  const Admin = localStorage.getItem("adminRole")

  const handleImageChange = (e) => {
    const [file] = e.target.files || e.dataTransfer.files
    // preview img
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    //preview onload function.
    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      const base64Image = toArrayBuffer(fileRes)
      setLoader({ type: "IMAGELOADING_INIT" })
      axios
        .post(
          `${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${Id}/type/new`,
          base64Image,
          {
            headers: API_CONSTANTS.imgheaders(),
          }
        )
        .then((response) => {
          setLoader({ type: "IMAGELOADING_END" })
          // console.log(response.data.response.url)
          setFile(response.data.response.url)
          // console.log(response)
        })
        .catch(() => {
          setLoader({ type: "IMAGELOADING_END" })
        })
    };
  };
  const handleProfileChange = (e) => {
    const [file] = e.target.files || e.dataTransfer.files
    // preview img
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    //preview onload function.
    reader.onload = async () => {
      // this is the base64 data
      const fileRes = btoa(reader.result)
      const base64Image = toArrayBuffer(fileRes)
      setLoader({ type: "PROFILEIMAGE_INIT" })
      axios
        .post(
          `${API_CONSTANTS.BASEURL}admin/image-upload-api/user/${Id}/type/new`,
          base64Image,
          {
            headers: API_CONSTANTS.imgheaders(),
          }
        )
        .then((response) => {
          setLoader({ type: "PROFILEIMAGE_END" })
          setAuth(response.data.response.url)
        })
        .catch(() => {
          setLoader({ type: "PROFILEIMAGE_END" })
        });
    };
  };

  const handleSubmitNew = (e) => {
    e.preventDefault()
  };
  const checkFormValidity = () => {
    const requiredFields = [description, category, subCategory, title,
      readtime, authorName, notificationBody, locationFilter, publish, choose, blogContent];
    const isValid = requiredFields.every(field => field !== "");
    const isFileValid = file !== "https://www.freeiconspng.com/uploads/upload-icon-16.png";
    const isAuthValid = auth !== "https://img.icons8.com/material/72/000000/user-male-circle--v1.png";

    // Update the isFormValid state
    setIsFormValid(isValid && isFileValid && isAuthValid);
  };

  useEffect(() => {
    if (params.name) {
      setIsEdit(true)
      axios
        .get(`${API_CONSTANTS.BASEURL_1}admin/blogs/${params.name}`, {
          headers: API_CONSTANTS.headers(),
        })
        .then((response) => {
          return response.data.response.Item
        })
        .then((res) => {
          setCategory(res.category)
          setsubCategory(res.subCategory)
          setBlogContent(res.blogContent ? res.blogContent : [])
          setTitle(res.title)
          setFile(res.url)
          setReadtime(res.readTime)
          setAuthorName(res.authorName)
          setAuth(res.authorProfileUrl)
          setNotificationBody(res.notificationBody)
          setLocationFilter(res.locationFilter)
          setScheduleDateTime(res.scheduleDateTime)
          setChoose(res.appType)
          const isPublished = res.isPublished.toLowerCase() === "true"
          setPublish(isPublished ? "True" : "False")
          const plainTextDescription = markdownToPlainText(res.description)
          setDescription(plainTextDescription);
        })
        .catch((error) => {
          console.log(error)
        });
    }
  }, [params.name]);

  // Add & Update Api Integration.
  const handleSubmit = () => {
    const data = {
      description: NodeHtmlMarkdown.translate(description),
      url: file,
      category: category,
      subCategory: subCategory,
      title: title,
      readTime: parseInt(readtime),
      authorName: authorName,
      authorProfileUrl: auth,
      blogContent: blogContent,
      notificationBody: notificationBody,
      locationFilter: locationFilter,
      scheduleDateTime: scheduleDateTime,
      isPublished: publish,
      appType: parseInt(choose),
    }
    if (isEdit) {
      // Update Blog API
      // console.log(auth)
      setLoader({ type: "SAVELOADING_INIT" })
      axios
        .patch(`${API_CONSTANTS.BASEURL_1}admin/blogs/${params.name}`, data, {
          headers: API_CONSTANTS.headers(),
        })
        .then((response) => {
          setLoader({ type: "SAVELOADING_END" })
          if (response.data.message === "Success") {
            handleToast(
              "Blog Updated Successfully",
              faCheckCircle,
              "#009b94",
              "show-success"
            )
          } else if (response.data.message === "Validation Erro") {
            handleToast(
              response.data.response.message,
              faCheckCircle,
              "#009b94",
              "show-success"
            )
          }
          clearCache()
        })
        .catch((error) => {
          setLoader({ type: "SAVELOADING_END" })
          handleToast(
            error.data.response.message,
            faCheckCircle,
            "#009b94",
            "show-success"
          )
        });
    } else {
      // Add Blog API
      setLoader({ type: "SAVELOADING_INIT" });
      axios
        .post(`${API_CONSTANTS.BASEURL_1}admin/blogs`, data, {
          headers: API_CONSTANTS.headers(),
        })
        .then((response) => {
          setLoader({ type: "SAVELOADING_END" })
          if (response.data.message === "Success") {
            handleToast(
              "Blog Added Successfully",
              faCheckCircle,
              "#009b94",
              "show-success"
            );
          }
          setDescription("")
          setCategory("")
          setsubCategory("")
          setTitle("")
          setReadtime("")
          setFile("")
          setAuth("")
          setAuthorName("")
          setPublish("")
          setBlogContent("")
          setChoose()
          clearCache()
        })
        .catch((error) => {
          setLoader({ type: "SAVELOADING_END" });
        })
    }
  };
  useEffect(() => {
    checkFormValidity();
  }, [description, file, category, subCategory, title, readtime,
    authorName, auth, notificationBody, locationFilter, publish, choose, blogContent]);

  const handleChange = (htl) => {
    setDescription(htl)
  };
  return (
    <>
      <section>
        <div className="header">
          <div className='title'>
            <TextField
              type="text"
              name="date"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              label="Title"
              variant="outlined"
              fullWidth
            />
          </div>
          <div className='options'>
            {/* {Admin === "HealthCare" ? (
              <TextField
                select
                name='publish'
                value={publish}
                onChange={(e) => setPublish(e.target.value)}
                label='Send Publish Notification?'
                variant='outlined'
                fullWidth
              >
                <MenuItem value='True'>No</MenuItem>
              </TextField>) : ( */}
              <TextField
                select
                name='publish'
                value={publish}
                onChange={(e) => setPublish(e.target.value)}
                label='Send Publish Notification?'
                variant='outlined'
                fullWidth
              >
                <MenuItem value='False'>Yes</MenuItem>
                <MenuItem value='True'>No</MenuItem>
              </TextField>
            {/* )} */}
          </div>
          <div className="button"
            style={{ padding: '0px' }}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              style={{ padding: "12px 16px", margin: '0px', width: '130px' }}
              disabled={!isFormValid}
            >
              {isEdit ? "Update Blog" : "Save Blog"}{" "}
              {saveLoading && <Spinner animation="border" size="sm" />}
            </Button>
          </div>
        </div>
        <div className="header1">
          <div className='notification px-1'>
            <TextField
              type="text"
              name="notificationBody"
              value={notificationBody}
              onChange={(e) => setNotificationBody(e.target.value)}
              label="Notification Body"
              variant="outlined"
              fullWidth
            />
          </div>
          <div className='options2 px-1'>
            <TextField
              select
              name='category'
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              label='Category'
              variant='outlined'
              fullWidth
            >
              <MenuItem value='Healthcare'>Healthcare</MenuItem>
              <MenuItem value='Diseases'>Diseases</MenuItem>
              <MenuItem value='Precautions'>Precautions</MenuItem>
              <MenuItem value='MentalHealth'>MentalHealth</MenuItem>
              <MenuItem value='BloodPressure'>BloodPressure</MenuItem>
              <MenuItem value='BloogSugar'>BloodSugar</MenuItem>
              <MenuItem value='HeartRate'>HeartRate</MenuItem>
              <MenuItem value='WeightBMI'>WeightBMI</MenuItem>
            </TextField>
          </div>
          <div className='options2 px-1'>
            <TextField
              select
              name='subCategory'
              value={subCategory}
              onChange={(e) => setsubCategory(e.target.value)}
              label='Sub Category'
              variant='outlined'
              fullWidth
            >
              <MenuItem value='Medications'>Medications</MenuItem>
              <MenuItem value='Vaccinations'>Vaccinations</MenuItem>
              <MenuItem value='FirstAid'>First Aid</MenuItem>
              <MenuItem value='HighBloodPressure'>High Blood Pressure</MenuItem>
              <MenuItem value='Anxiety'>Anxiety</MenuItem>
              <MenuItem value='Depression'>Depression</MenuItem>
              <MenuItem value='Slow'>Slow</MenuItem>
              <MenuItem value='obeseclass1,'>obese class 1,</MenuItem>
              <MenuItem value='obeseclass2'>obese class 2</MenuItem>
              <MenuItem value='obeseclass3'>obese class 3</MenuItem>
              <MenuItem value='StressManagement'>Stress Management</MenuItem>
              <MenuItem value='PersonalHygiene'>Personal Hygiene</MenuItem>
              <MenuItem value='SafetyMeasures'>Safety Measures</MenuItem>
              <MenuItem value='EnvironmentalPrecautions'>Environmental Precautions</MenuItem>
              <MenuItem value='InfectiousDiseases'>Infectious Diseases</MenuItem>
              <MenuItem value='ChronicDiseases'>Chronic Diseases</MenuItem>
              <MenuItem value='GeneticDisorders'>Genetic Disorders</MenuItem>
              <MenuItem value='HeartRate'>Heart Rate</MenuItem>
              <MenuItem value='Underweight'>Under Weight</MenuItem>
              <MenuItem value='Overweight'>Over Weight</MenuItem>
              <MenuItem value='Hypotension'>Hypotension</MenuItem>
              <MenuItem value='Normal'>Normal</MenuItem>
              <MenuItem value='Low'>Low</MenuItem>
              <MenuItem value='Fast'>Fast</MenuItem>
              <MenuItem value='Elevated'>Elevated</MenuItem>
              <MenuItem value='Hypertensive'>Hypertensive</MenuItem>
              <MenuItem value='Hypertension'>Hypertension</MenuItem>
              <MenuItem value='Pre-diabetes'>Pre-diabetes</MenuItem>
              <MenuItem value='Diabetes'>Diabetes</MenuItem>
              <MenuItem value='Unknown'>Unknown</MenuItem>
            </TextField>
          </div>
          <div className='options1 px-1'>
            <TextField
              select
              name='locationFilter'
              value={locationFilter}
              onChange={(e) => setLocationFilter(e.target.value)}
              label='Location'
              variant='outlined'
              fullWidth
            >
              <MenuItem value='none'>None</MenuItem>
              <MenuItem value='lahore'>lahore</MenuItem>
              <MenuItem value='islamabad'>Islamabad</MenuItem>
              <MenuItem value='faisalabad'>Faisalabad</MenuItem>
              <MenuItem value='rawalpindi'>Rawalpindi</MenuItem>
              <MenuItem value='gujranwala'>Gujranwala</MenuItem>
              <MenuItem value='peshawar'>Peshawar</MenuItem>
              <MenuItem value='multan'>Multan</MenuItem>
              <MenuItem value='hyderabad'>Hyderabad</MenuItem>
              <MenuItem value='quetta'>Quetta</MenuItem>
              <MenuItem value='karachi'>Karachi</MenuItem>
            </TextField>
          </div>
        </div>
        <div className="main-editor">
          <div className="editor">
            <ReactQuill
              onChange={handleChange}
              value={description}
              modules={Editorquill.modules}
              formats={Editorquill.formats}
              bounds={".app"}
            />
          </div>
          <div className="category">
            <p>Settings</p>
            <div className="image_upload">
              <form onSubmit={handleSubmitNew}>
                <label style={{
                  fontWeight: '400'
                }}>Select an image:</label>
                <div className="Authorimage d-flex justify-content-between">
                  <input
                    type="file"
                    accept="image/*"
                    name="previewUrl"
                    onChange={handleImageChange}
                  />
                  {imageLoading && <Spinner animation="border" size="sm" />}
                  <div
                    className="image_view mx-1"
                    style={{
                      backgroundImage: `url(${file})`,
                    }}
                  />
                </div>
              </form>
            </div>
            <div className='px-1 mt-3'>
              <TextField
                type="text"
                name="readtime"
                value={readtime}
                placeholder="00 Minutes"
                onChange={(e) => setReadtime(e.target.value)}
                label="Reading Time"
                variant="outlined"
                fullWidth
              />
            </div>
            <div className='px-1 mt-3'>
              <TextField
                type="text"
                name="authorName"
                placeholder="Author Name"
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
                label="Author Name"
                variant="outlined"
                fullWidth
              />
            </div>
            <div className="my-2">
              <form onSubmit={handleSubmitNew}>
                <label style={{
                  fontWeight: '400'
                }}>Author image:</label>
                <div className="Authorimage d-flex justify-content-between">
                  <input
                    type="file"
                    accept="image/*"
                    name="authorProfileUrl"
                    onChange={handleProfileChange}
                  />
                  {profileImage && <Spinner animation="border" size="sm" />}
                  <div
                    className="image_view"
                    style={{
                      backgroundImage: `url(${auth})`,
                    }}
                  />
                </div>
              </form>
            </div>
            <div>
              {/* {Admin === "HealthCare" ? (
                <TextField
                  select
                  name='choose'
                  value={choose}
                  onChange={(e) => setChoose(e.target.value)}
                  label='Choose App ?'
                  variant='outlined'
                  fullWidth
                >
                  <MenuItem value={3}>BeFit</MenuItem>
                </TextField>) : ( */}
                <TextField
                  select
                  name='choose'
                  value={choose}
                  onChange={(e) => setChoose(e.target.value)}
                  label='Choose App ?'
                  variant='outlined'
                  fullWidth
                >
                  <MenuItem value={2}>All</MenuItem>
                  <MenuItem value={1}>EShaafi</MenuItem>
                  <MenuItem value={3}>BeFit</MenuItem>
                </TextField>
              {/* )} */}
            </div>
            <div className='px-1 mt-3'>
              <TextField
                type={isfocused ? "datetime-local" : "text"}
                name="scheduleDateTime"
                value={scheduleDateTime}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => setScheduleDateTime(e.target.value)}
                label="Select Date and Time"
                variant="outlined"
                fullWidth
              />
            </div>
            <div className="col-md-12 mt-2 p-1">
              <label className="form-label" style={{
                fontWeight: '400'
              }}>
                Blog Content:
              </label>
              <ReactTagInput
                tags={blogContent?.length < 1 ? [] : blogContent}
                placeholder={`Hit Enter to type the next lines`}
                editable={true}
                name='includes'
                value={blogContent}
                removeOnBackspace={true}
                onChange={(e) => {
                  setBlogContent(e)
                }}
                validateOnChange={true}
              />
            </div>
          </div>
        </div>
      </section>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  )
}
