import { createSlice } from '@reduxjs/toolkit'
//

export const patientFilterSlice = createSlice({
  name: 'patientFilter',
  initialState: {
    value1: '',
    value2: '',
    value3: '',
  },
  reducers: {
    all: (state, action) => {
      state.value1 = ''
    },
    Male: (state, action) => {
      state.value1 = 'Male'
    },
    Female: (state, action) => {
      state.value1 = 'Female'
    },
    Other: (state, action) => {
      state.value1 = 'Other'
    },
    // 3rd filter value
    created_at: (state, action) => {
      const { payload } = action
      state.value2 = payload
    },
    appoint_date_from: (state, action) => {
      const { payload } = action
      state.value3 = payload
    },
  },
})
export const { all, Male, Female, Other, created_at, appoint_date_from } =
  patientFilterSlice.actions
export default patientFilterSlice.reducer
