/*
Created By: Haseeb,
Modified By : Umer Shabir 
File: Login Component. To use the entire app need to login, all the login is here.
modified by Umer Shabir: 03-11-2022.
*/
// Dependencies.
import React, { useState } from "react";
import axios from "axios";
import eshaafiLogo from "../../assets/images/logo.png";
import { useNavigate } from "react-router";
import { API_CONSTANTS } from "../../API_CONSTANTS";
import { useDispatch } from "react-redux";
// == FontAwesome Icon libraries  ====//
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faLock,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
// import Toast from '../Toast/Toast'
// ===================================  Functional Component coding start from here ==========
export default function AdminLogin() {
  const history = useNavigate();
  const dispatch = useDispatch();
  //   Declare States
  const [iconColor, setIconColor] = useState(""); // using this state for changing the toast message icon color
  const [message, setMessage] = useState(""); //  using this state for toast message
  const [showToast, setShowToast] = useState(""); //  using this state for show the toast message
  const [Icon, setIcon] = useState(false); //  using this state for show the toast message
  const [spin, setSpin] = useState();
  const [getEmailClass, setEmailClass] = useState("");
  const [getPassClass, setPassClass] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [e_message, setE_message] = useState("");
  const [p_message, setP_message] = useState("");
  // const [editorId, setEditorId] = useState('')
  // console.log(editorId)
  // sending Device token
  const sendDeviceToken = async () => {
    const token = await JSON.parse(localStorage.getItem("deviceToken"));
    axios
      .post(
        API_CONSTANTS.BASEURL + "auth/deviceToken",
        {
          token: token,
          os_name: "web",
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .catch((err) => console.log(err));
  };
  // call login api
  const call_Login_API = (e) => {
    e.preventDefault();
    setSpin("spinner-border spinner-border-sm");
    if (email || password) {
      if (email && password) {
        setEmailClass("");
        setPassClass("");
        let data = {
          email: email,
          password: password,
          type: 0,
        };
        try {
          axios
            .post(`${API_CONSTANTS.BASEURL}admin/auth`, data)
            .then((r) => {
              // setEditorId(r.data.response.info.Id)
              localStorage.removeItem("storeData");
              if (r.data.message === "Success") {
                setSpin("");
                localStorage.setItem(
                  "storedData",
                  JSON.stringify(r.data.response.IdToken)
                );
                localStorage.setItem(
                  "adminRole",
                  r.data.response.info.adminRole
                );
                localStorage.setItem(
                  "adminName",
                  r.data.response.info.adminName
                );
                localStorage.setItem("editorId", r.data.response.info.Id);
                dispatch({
                  type: "adminLoginSlice/editorIdStored", 
                  payload: r.data.response.info.Id,
                });
              }
              return r.data.response.IdToken;
            })
            .then((token) => {
              // await sendDeviceToken();
              dispatch({
                type: "adminLoginSlice/accessPermited",
                payload: token,
              });
              history("/", { replace: true });
              return token;
            })
            .then(() => {
              setTimeout(() => {
                sendDeviceToken();
              }, 3000);
            })
            .catch((e) => {
              setMessage(e.response.data.response.message);
              setShowToast("show show-danger");
              setIconColor("#B00020");
              setTimeout(() => setShowToast(""), 2900);
              setSpin("");
            });
        } catch (e) {
          console.error("error", e);
        }
      } else if (email) {
        setEmailClass("");
        setPassClass("form-control-danger");
        setP_message("Please enter password");
        setTimeout(() => setSpin(""), 500);
      } else if (password) {
        setPassClass("");
        setE_message("Please enter email");
        setEmailClass("form-control-danger");
        setP_message("");
        setTimeout(() => setSpin(""), 500);
      }
    } else {
      setEmailClass("form-control-danger");
      setPassClass("form-control-danger");
      setE_message("Please enter email");
      setP_message("Please enter password");
      setTimeout(() => setSpin(""), 500);
    }
  };

  const getEmail = (e) => {
    setEmail(e.target.value);
    setE_message("");
    setEmailClass("");
  };

  const getPassword = (e) => {
    setPassword(e.target.value);
    setP_message("");
    setPassClass("");
  };

  const show_hide_passowrd = () => {
    setIcon(!Icon);
  };
  return (
    <>
      <div
        className={"l-card card boxShadow"}
        style={{ width: "430px", float: "right" }}
      >
        <img
          className={"card-img-top logo p-2"}
          src={eshaafiLogo}
          alt={"logo"}
          style={{ background: "#FFFFFF", borderRadius: "50px" }}
        />
        <div className={"card-body "}>
          <form
            onSubmit={call_Login_API}
            className="d-flex flex-column justify-content-center "
          >
            <div className={"input-group"}>
              <div className={"input-group-prepend"}>
                <span className={"input-group-text"} onChange={getEmail}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </div>
              <input
                className={`form-control ${getEmailClass}`}
                placeholder="Enter email"
                aria-label="Email"
                aria-describedby="basic-addon1"
                type={"text"}
                onChange={getEmail}
                value={email}
              />
            </div>
            <label className={"form-label"}>{e_message}</label>
            <div className={"input-group"}>
              <div className={"input-group-prepend"}>
                <span className={"input-group-text"} onChange={getEmail}>
                  <FontAwesomeIcon icon={faLock} />
                </span>
              </div>
              <input
                className={`form-control l-input ${getPassClass} `}
                placeholder="Enter password"
                aria-label="password"
                aria-describedby="basic-addon1"
                type={Icon ? "text" : "password"}
                onChange={getPassword}
                value={password}
              />
              <span className={`icon-eye ${Icon ? "" : "icon-eyeRemo"}`}>
                <FontAwesomeIcon
                  icon={Icon ? faEye : faEyeSlash}
                  onClick={show_hide_passowrd}
                />
              </span>
            </div>
            <label className={"form-label"}>{p_message}</label>
            <div className={"py-2"}>
              <button
                className={"btn btn-info w-100"}
                type="submit"
                style={{ margin: 0 }}
              >
                <span className={spin} />
                Login as Admin
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* ----------------Toast Message Code------------*/}
      <div id="snackbar" className={showToast}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          color={iconColor}
          style={{ marginRight: "8px" }}
        />
        {message}
      </div>
      <style jsx="true">
        {`
          .l-input {
            border-top-right-radius: 0.25rem !important;
            border-bottom-right-radius: 0.25rem !important;
          }
          .form-label {
            font-weight: 400 !important;
            padding: 2px 42px;
            color: #b64646;
          }

          .logo {
            text-align: center;
            margin: -2.5rem auto 1rem auto;
            padding: 2px;
            width: 65px !important;
            height: 70px;
          }

          .form-control-danger {
            border-color: #fe8694 !important;
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
          }
          .input-group-text {
            padding: 10px 12px !important;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
          }

          input {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          }

          .btn-info {
            color: white !important;
            border: none !important;
            background: rgba(0, 155, 148, 0.78) !important;
            letter-spacing: 1px;
          }

          .btn-info:hover {
            background: rgba(0, 155, 148, 1) !important;
          }

          .spinner-border-sm {
            margin-right: 12px;
          }

          .boxShadow {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
          }

          .icon-eye {
            padding-top: 6px;
            padding-right: 12px;
            margin-left: -32px !important;
            z-index: 38;
          }
          .icon-eye:hover {
            color: rgba(0, 155, 148, 0.76);
          }
          .icon-eyeRemo {
            margin-left: -32px !important;
          }

          @media only screen and (max-width: 450px) {
            .card {
              width: 23rem !important;
            }
          }
        `}
      </style>
    </>
  );
}
