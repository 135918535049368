/*
Authore: Umer Shabir
Date: April-2022
File: blocking component for admin profile page.
*/
// dependencies
import React, { useState } from 'react'
import { API_CONSTANTS, headers } from '../../../../API_CONSTANTS'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Toast from '../../../../Components/Toast/Toast'
import { useDispatch } from 'react-redux'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
// Block admin profile component
export default function BlockProfileButton({ overview }) {
  // Toast Message ---------
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [spin, setSpin] = useState('')
  const params = useParams()
  const dispatch = useDispatch()
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  const handle_blocked = () => {
    setSpin('spinner-border spinner-border-sm')
    const data = {
      email: overview.email,
    }
    axios
      .patch(`${API_CONSTANTS.BASEURL}admin/disableAdmin/${params.id}`, data, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        handleToast(
          res.data.response.message === 'Admin Disabled Successfully'
            ? 'Admin blocked Successfully'
            : '',
          faCheckCircle,
          '#009b94',
          'show-success'
        )
        setSpin('')
      })
      .then(() => {
        setTimeout(() => dispatch({ type: 'getAdminProfile/block' }), 1000)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload()
          window.localStorage.clear()
        }
        setSpin('')
      })
  }
  return (
    <>
      <button
        className='btn custom-button block-button'
        onClick={handle_blocked}
      >
        <span className={spin + ' me-2'} />
        Block
      </button>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style jsx='true'>
        {`
          .block-button {
            border: 2px solid #5c5c5c;
            color: #5c5c5c;
          }
          .block-button:hover {
            background: #5c5c5c !important;
            color: #fff;
          }
        `}
      </style>
    </>
  )
}
