/*
Authore: Umer Shabir 
Date: april-2022
File: Redux reducer function for storing admin profile state.
*/
// dependencies.
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../API_CONSTANTS'
// thunk function
export const getProfile = createAsyncThunk('getProfile', async (paramsID) => {
  const response = await axios
    .get(`${API_CONSTANTS.BASEURL}admin/${paramsID}`, {
      headers: API_CONSTANTS.headers(),
    })
    .then((res) => res.data.response.Item)

  return response
})
// Slice funtion
export const getProfileSlice = createSlice({
  name: 'getAdminProfile',
  initialState: {
    value: {
      adminName: '',
      email: '',
      phone: '',
      is_blocked: false,
    },
  },
  reducers: {
    block: (state) => {
      state.value.is_blocked = true
    },
    unBlock: (state) => {
      state.value.is_blocked = false
    },
  },
  extraReducers: {
    [getProfile.pending]: (state) => {
      state.loading = true
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.value = payload
    },
    [getProfile.rejected]: (state) => {
      state.loading = false
    },
  },
})
// exporting Reducer function
export const getAdminProfileReducer = getProfileSlice.reducer
