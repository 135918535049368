import { createSlice } from '@reduxjs/toolkit'

export const adminLoginSlice = createSlice({
  name: 'adminLoginSlice',
  initialState: {
    adminIdToken: localStorage.getItem('storedData')
      ? localStorage.getItem('storedData')
      : false,
      editorId: localStorage.getItem("editorId")
      ? localStorage.getItem("editorId")
      : null,
      adminRole: localStorage.getItem("adminRole")
      ? localStorage.getItem("adminRole")
      : null,
  },
  reducers: {
    accessPermited: (state, action) => {
      state.adminIdToken = action.payload
    },
    accessDenyied: (state) => {
      state.adminIdToken = false
    },
    editorIdStored: (state, action) => {
      state.editorId = action.payload; // Store the editorId in the state
    },
  },
})

export const adminLoginReducer = adminLoginSlice.reducer
