import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import axios from "axios"
import { API_CONSTANTS } from "../../../../API_CONSTANTS"
import CloseIcon from "@mui/icons-material/Close"
// import { ToastContainer, toast } from "react-toastify"
import Toast from '../../../../Components/Toast/Toast'
import Spinner from "react-bootstrap/Spinner"
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/fontawesome-free-solid'
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function AddPromo() {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const [isLoading, setIsLoading] = useState(false)
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [promoStatus, setPromoStatus] = useState("")
  const [discountType, setDiscountType] = useState("")
  const [startDateTime, setStartDateTime] = useState("")
  const [expiryDate, setExpiryDate] = useState("")
  const [promoName, setPromoName] = useState("")
  const [promoCode, setPromoCode] = useState("")
  const [discountAmount, setDiscountAmount] = useState("")
  const [isfocused, setIsFocused] = useState(false)
  const [isfocused2, setIsFocused2] = useState(false)
  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur = () => {
    setIsFocused(false)
  }
  const handleFocus2 = () => {
    setIsFocused2(true)
  }
  const handleBlur2 = () => {
    setIsFocused2(false)
  }
  const handleClose = () => {
    setShow(false)
    setPromoName('')
    setPromoCode('')
    setPromoStatus('')
    setDiscountAmount('')
    setStartDateTime('')
    setExpiryDate('')
    setDiscountType('')
  }
  // Toast Message 
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  // Custom validation function
  const validateForm = () => {
    const currentDate = new Date().toISOString().split("T")[0];

    const showErrorToast = (message) => {
      handleToast(message, faTimesCircle, 'red', 'show-error');
      return false;
    };

    const isInvalidPromoCode = promoCode.length < 5 || promoCode.length > 50;
    const isInvalidPromoName = promoName.length < 3 || promoName.length > 50;

    if (isInvalidPromoName) {
      return showErrorToast('Promo Name must be at least 5 characters.');
    }
    if (isInvalidPromoCode) {
      return showErrorToast('Promo Code must be at least 3 characters.');
    }
    if (!promoName || !promoCode || !startDateTime || !expiryDate || !discountType || !discountAmount || !promoStatus) {
      return showErrorToast('Please fill in all fields.');
    }
    if (isNaN(discountAmount) || discountAmount < 1 || discountAmount > 1000) {
      return showErrorToast('Discount amount must be a number between 1 and 1000.');
    }
    if (expiryDate < currentDate) {
      return showErrorToast('Please select an upcoming expiry date.');
    }
    return true;
  };

  // API integration of Add subsrciption Form 
  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    const values = {
      promoName: promoName,
      promoCode: promoCode,
      promoStatus: promoStatus,
      discountAmount: parseInt(discountAmount),
      startDateTime: startDateTime,
      expires_at: expiryDate,
      discountType: discountType,
    }
    setIsLoading(true);
    axios
      .post(`${API_CONSTANTS.BASEURL_1}admin/promo_code`, values, {
        headers: API_CONSTANTS.headers(),
      })
      .then((response) => {
        if (response.data.message === 'Success') {
          handleToast(
            'Promo Codd added Successfully',
            faCheckCircle,
            '#009b94',
            'show-success'
          )
        }
        handleClose();
      })
      .finally(() => {
        setIsLoading(false)
      });
  };
  const expiryDates = () => {
    if ((expiryDate) && !isNaN(new Date(expiryDate))) {
      return new Date(expiryDate).toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    } else {
      return expiryDate
    }
  }
  const startDateTimes = () => {
    if ((startDateTime) && !isNaN(new Date(startDateTime))) {
      return new Date(startDateTime).toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    } else {
      return startDateTime
    }
  }
  return (
    <>
      <button className="add-record btn mx-4 mt-4" onClick={handleShow}>
        <i className="fas fa-plus" style={{ marginRight: 8 }}></i>
        Add New Promo Code
      </button>
      {/* Modal of Form */}
      <Modal show={show} size="lg">
        <Modal.Body className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <h4>Add New Promo Code</h4>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
          <div className="d-flex gap-1 my-3">
            <div className="col-md-6">
              <TextField
                type="text"
                name="promoName"
                value={promoName}
                onChange={(e) => setPromoName(e.target.value)}
                label="Promo Name"
                variant="outlined"
                fullWidth
                required
              />
            </div>
            <div className="col-md-6">
              <TextField
                type="text"
                name="promoCode"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                label="Promo Code"
                variant="outlined"
                fullWidth
                required
              />
            </div>
          </div>
          <div className="d-flex gap-1 my-3">
            <div className="col-md-6">
              <TextField
                type={isfocused ? "date" : "text"}
                name="startDateTime"
                value={isfocused ? startDateTime : startDateTimes()}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => setStartDateTime(e.target.value)}
                label="Start Date and Time"
                variant="outlined"
                fullWidth
                required
              />
            </div>
            <div className="col-md-6">
              <TextField
                type={isfocused2 ? "date" : "text"}
                name="expiryDate"
                value={isfocused2 ? expiryDate : expiryDates()}
                onFocus={handleFocus2}
                onBlur={handleBlur2}
                onChange={(e) => setExpiryDate(e.target.value)}
                label="Expiry Date and Time"
                variant="outlined"
                fullWidth
                required
              />
            </div>
          </div>
          <div className="d-flex gap-1 my-3">
            <div className="col-md-6">
              <TextField
                select
                name='discountType'
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
                label='Discount In'
                variant='outlined'
                fullWidth
                required
              >
                <MenuItem value="Rupees">Rs.</MenuItem>
                <MenuItem value="Percentage">Percentage</MenuItem>
              </TextField>
            </div>
            <div className="col-md-6">
              <TextField
                type="number"
                name="discountAmount"
                value={discountAmount}
                onChange={(e) => setDiscountAmount(e.target.value)}
                label="Discount"
                variant="outlined"
                fullWidth
                required
              />
            </div>
          </div>
          <div className="col-md-6 my-3">
            <TextField
              select
              name='promoStatus'
              value={promoStatus}
              onChange={(e) => setPromoStatus(e.target.value)}
              label='Status'
              variant='outlined'
              fullWidth
              required
            >
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value="inactive">InActive</MenuItem>
            </TextField>
          </div>
          <div className="row d-flex justify-content-center mt-5 mb-3 gap-3">
            <div className="col-md-5">
              <button type="submit" className="btn btn-save w-100" onClick={handleSubmit}>
                Save Changes{" "}
                {isLoading && <Spinner animation="border" size="sm" />}
              </button>
            </div>
            <div className="col-md-5">
              <button
                type="reset"
                className="btn btn-outline-danger w-100"
                onClick={handleClose}
                style={{ height: "43px" }}
              >
                Discard Changes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <style jsx="true">
        {`
          .btn-save:hover {
            background: #009b94 !important;
            color: #fff;
          }
          .btn-discard {
            background: none;
            color: red;
            padding: 10px 10px !important;
          }
          .fas fa-plus:hover {
            color: #009b94 !important;
          }
          .error-message {
            color: red;
          }
          .dropdown-icon-container {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }     
          .drop{
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-140%);
          }     
          .add-record {
            background: #009b94 !important;
            color: #fff;
          }
          .add-record:hover {
            background: #fff !important;
            color: #009b94 !important;
          }
          .react-tag-input:hover{
            border-color: #009b94 !important;
          }
          .react-tag-input__tag__remove{
            background: none !important;
          }
          .react-tag-input__tag__remove::before{
            background-color: #009b94 !important;
          }
          .react-tag-input__tag__remove::after{
            background-color: #009b94 !important;
          }
        `}
      </style>
      {/* Toast Message Component render*/}
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
    </>
  );
}
