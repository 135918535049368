import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_CONSTANTS } from "../../../API_CONSTANTS";

export const getCorporate = createAsyncThunk("getCorporate", async (id) => {
  const response = await axios(
    API_CONSTANTS.BASEURL_4 + `admin/corporates/${id}`,
    {
      headers: API_CONSTANTS.headers(),
    }
  );
  return response.data.response.Item;
});
export const getCorporateSlicer = createSlice({
  name: "getCorporate",
  initialState: {
    value: {
      currentPlanId: "",
      callAvailed: "",
      created_at: "",
      currentPlan: "",
      companyName: "",
      callAvailable: "",
      corporateInfo: {
        calls: "",
        endDate: "",
        corpPlanStatus: "",
        created_at: "",
        validDuration: "",
        includes: [],
        remainingCalls: "",
        startDate: "",
        corporateID: "",
      },
      employeeCount: "",
      profileStatus: "",
      email: "",
      phoneNo: "",
      updated_at: "",
      corporateStatus: "",
      date: "",
      Sk: "",
      Id: "",
      corporateID: "",
      corporateType: "",
      type: 10,
    },
  },
  reducers: {},
  extraReducers: {
    [getCorporate.pending]: (state) => {
      state.loading = true;
    },
    [getCorporate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.value = payload;
    },
    [getCorporate.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const getCorporateProfileReducer = getCorporateSlicer.reducer;
