// dependencies
import { configureStore } from "@reduxjs/toolkit";
//doctor profile reducers
import add_doctor_Reducers from "../Pages/Doctor/Components/AddDoctor/add_doctor_Reducers";
import doctorFilterReducer from "../Pages/Doctor/Components/DoctorList/doctorFilterSlice";
import { getDoctorReducer } from "../Pages/Doctor/Components/DoctorProfile/Overview/doctorOverviewSlice";
import { getSessionReducer } from "../Pages/Doctor/Components/DoctorProfile/Availability/SetTimeSlots/sessionSlice";
import { diseaseDataReducer } from "../Pages/Doctor/sub-menu/DiseasePreset/editDiseaseSlice";
// admin profile reducers
import adminFilteredReducer from "../Pages/admin/Components/AdminList/adminFilterSlicer";
import { getAdminProfileReducer } from "../Pages/admin/Components/AdminProfilePage/AdminProfileSlice";
import { adminLoginReducer } from "../Components/Login/adminLoginSlice";
// patient profile reducers
import patientFilterReducer from "../Pages/Patient/PatientList/PatientFilterSlice";
import { getPatientProfileReducer } from "../Pages/Patient/PatientProfile/patientProfileSlice";
// import { patientConsultationFilterReducer } from "../Pages/Patient/PatientProfile/profilePages/Services/filterSlice";
import { patientConsultationFilterReducer } from "../Pages/Patient/PatientProfile/profilePages/Consultations/filterSlice";
import { patientTransactionFilterReducer } from "../Pages/Patient/PatientProfile/profilePages/Wallet/Transaction/filterSlice";
import { patientMedicalHistoryFilterReducer } from "../Pages/Patient/PatientProfile/profilePages/MedicalHistory/filterSlice";
// consultation reducers
import { getConsultationProfileReducer } from "../Pages/consultation/Components/ConsultationProfile/overview/consultationOverviewSlice";
import { getReviewReducer } from "../Pages/consultation/Components/ConsultationProfile/reviews/reviewSlice";
import { consultationFilterReducer } from "../Pages/consultation/Components/ConsultationList/consultationFilterSlice";
import { appointmentFilterReducer } from "../Pages/Doctor/Components/DoctorProfile/Appointments/appointmentFilterSlice";
// main  dashboard state reducers
import { getDashboardDataReducer } from "../Components/Dashboard/dashboardSlice";
import { getDashboardNumbersReducer } from "../Components/Dashboard/dashboardSlice";
import { sideBarWidthReducer } from "../Components/SideBar/sideBarWidthSlice";
import { delayedFilterReducer } from "../Pages/Doctor/Components/DoctorProfile/DelayedAppointments/filterSlice";
// app data add data reducer
import { getDataReducer } from "../Pages/addData/addDataSlice";
// transaction app states
import { TransactionFilterReducer } from "../Pages/Transaction/TransactionList/filterSlice";
// Nurse data reducer
import { getNurseReducer } from "../Pages/Nurse/Components/NurseProfile/Overview/nurseOverviewSlice";
import { nurseFilterReducer } from "../Pages/Nurse/Components/NurseList/nurseFilterSlice";
import { getNurseSessionReducer } from "../Pages/Nurse/Components/NurseProfile/Availability/SetTimeSlots/sessionSlice";
import { appointmentsFilterReducer } from "../Pages/Nurse/Components/NurseProfile/Appointments/appointmentFilterSlice";
// Services Reducer
import { ServicesFilterReducer } from "../Pages/servicesnurse/Components/ServicesList/ServicesFilterSlice";
import { ReviewFilterReducer } from "../Pages/Review/ReviewFilterSlice";
import { getServicesProfileReducer } from "../Pages/servicesnurse/Components/ServicesProfile/overview/ServicesOverviewSlice";
// notifications reducer
import { notificationReducer } from "../Pages/notifications/notificationsSlice";
// Lab reducer
import { LabFilterReducer } from "../Pages/Lab/LabFilterSlice";
import { getLabReducer } from "../Pages/Lab/Component/Overview/LabOverviewSlice";
import { LabFilterReducer2 } from "../Pages/Patient/PatientProfile/profilePages/Lab/filterSlice";
import { getCorporateProfileReducer } from "../Pages/corporates/corporatePage/slicer";
import { labCartReducer } from "../Pages/Patient/PatientProfile/profilePages/Lab/BookLab/LabCartSlice";
import { labEditCartReducer } from "../Pages/Lab/Component/Overview/EditLab/labEditCartSlice";
import { tableFilterModalReducer } from "../Components/Table/filterSlicer";
import { packageReducer } from "../Pages/Lab/subPages/packages/packageSlice";
// configuration the state store
const store = configureStore({
  reducer: {
    adminLoginReducer,
    getDashboardDataReducer,
    getDashboardNumbersReducer,
    adminFilteredReducer,
    doctorFilterReducer,
    getAdminProfileReducer,
    patientFilterReducer,
    getConsultationProfileReducer,
    getReviewReducer,
    add_doctor_Reducers,
    consultationFilterReducer,
    getPatientProfileReducer,
    getDoctorReducer,
    appointmentFilterReducer,
    patientConsultationFilterReducer,
    patientTransactionFilterReducer,
    sideBarWidthReducer,
    getSessionReducer,
    patientMedicalHistoryFilterReducer,
    diseaseDataReducer,
    delayedFilterReducer,
    TransactionFilterReducer,
    getDataReducer,
    getNurseReducer,
    nurseFilterReducer,
    getNurseSessionReducer,
    appointmentsFilterReducer,
    ServicesFilterReducer,
    ReviewFilterReducer,
    notificationReducer,
    getServicesProfileReducer,
    LabFilterReducer,
    getLabReducer,
    LabFilterReducer2,
    getCorporateProfileReducer,
    labCartReducer,
    labEditCartReducer,
    tableFilterModalReducer,
    packageReducer,
  },
});

export default store;
