import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Toast from '../../../Components/Toast/Toast'
import { useDispatch } from 'react-redux'

import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { API_CONSTANTS } from '../../../API_CONSTANTS'
//
//
export default function UnblockProfileButton({ get_admin_details, overview }) {
  const [ToastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState('')
  const [ToastIcon, setToastIcon] = useState(faTimesCircle)
  const [iconColor, setIconColor] = useState('')
  const [spin, setSpin] = useState('')
  const params = useParams()
  const dispatch = useDispatch()
  const handleToast = (message, icon, iconColor, className) => {
    setToastIcon(icon)
    setToastMessage(message)
    setShowToast(`show ${className}`)
    setIconColor(iconColor)
    setTimeout(() => setShowToast(''), 2900)
  }
  //
  //
  const handle_Unblocked = () => {
    setSpin('spinner-border spinner-border-sm')
    const data = {
      Id: overview.id,
    }
    axios
      .patch(`${API_CONSTANTS.BASEURL}admin/updateUserState/${params.id}/type/1/state/enable`, data, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => {
        handleToast(
          res.data.response.message,
          faCheckCircle,
          '#009b94',
          'show-success'
        )
        setSpin('')
      })
      .then(() => {
        setTimeout(() => dispatch({ type: 'getPatientProfile/unblock' }), 1000)
      })
      .catch((e) => {
        setSpin('')
      })
  }
  return (
    <>
      <button
        className='btn custom-button unblock-button'
        onClick={handle_Unblocked}
      >
        <span className={spin + ' me-2'} />
        Unblock
      </button>
      <Toast
        cName={showToast}
        icon={ToastIcon}
        color={iconColor}
        text={ToastMessage}
      />
      <style jsx='true'>{`
        .unblock-button {
          background: #009b94 !important;
          border: 2px solid #009b94;
          color: #fff;
        }
        .unblock-button:hover {
          background: #009b94c4 !important;
          color: #fff !important;
        }
      `}</style>
    </>
  )
}
