import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
export default function EditDiseaseButton({ data }) {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch({ type: 'diseaseData/handleDiseaseData', payload: data })
  }
  return (
    <>
      <Link
        to={`/disease-preset/edit/${data.title.replace(/\s/g, '')}`}
        style={{
          background: 'none',
          color: '#009B94',
          border: '1px solid #009B94',
          padding: '5px 35px',
          fontWeight: 500,
        }}
        className='btn edit-button'
        onClick={handleClick}
      >
        Edit
      </Link>
      <style jsx='true'>
        {`
          .edit-button:hover {
            background: #009b94 !important;
            color: #fff !important;
          }
        `}
      </style>
    </>
  )
}
