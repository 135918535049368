import { useState } from "react";
import { TextField, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import styles from "../../../Style/corporate.module.css";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { API_CONSTANTS } from "../../../API_CONSTANTS";

export default function CorporateSettings() {
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
  const { value } = useSelector((state) => state.getCorporateProfileReducer);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const handleClick = () => {
    setLoading(true);
    axios
      .patch(
        API_CONSTANTS.BASEURL_4 +
          `admin/corporate/updateAdmin/${value.Id}?changepassword=true`,
        {
          password,
        },
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        setLoading(false);
        setPassword("");
        toast(res.data.response.message, {
          type: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        toast("something goes wrong try again", {
          type: "error",
        });
      });
  };
  return (
    <section>
      <div className={styles.settingsContainer}>
        <div className={styles.settingForm}>
          <TextField
            label="Email"
            variant="standard"
            value={value.email}
            className={styles.settingsField}
            InputProps={{
              readOnly: true,
            }}
          />
          <div>
            <TextField
              label="Password"
              variant="standard"
              className={styles.settingsField}
              onChange={(e) => setPassword(e.target.value)}
              required
              value={password}
            />
            {!password && (
              <p
                style={{
                  fontSize: 11,
                }}
              >
                Password length must be 8 character long, including atleast one
                uppercase, one speacial letter, and one numeric value.
              </p>
            )}
            {password && (
              <p
                style={{
                  color: passwordRegex.test(password) ? "green" : "red",
                  fontSize: 12,
                }}
              >
                {passwordRegex.test(password)
                  ? "Good to go"
                  : `Password length must be 8 character long, including one
                        Uppercase and one special letter including`}
              </p>
            )}
          </div>

          <LoadingButton
            className={styles.actionBtn}
            variant="contained"
            loading={loading}
            // loadingPosition="end"
            onClick={handleClick}
            disabled={false}
          >
            Save Changes
          </LoadingButton>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}
