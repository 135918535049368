import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { getReview } from './reviewSlice'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'
//
const starRating = [
  <i className='fas fa-star' style={{ fontSize: '20px' }} />,
  <i className='fas fa-star' style={{ fontSize: '20px' }} />,
  <i className='fas fa-star' style={{ fontSize: '20px' }} />,
  <i className='fas fa-star' style={{ fontSize: '20px' }} />,
  <i className='fas fa-star' style={{ fontSize: '20px' }} />,
]

export default function Reviews() {
  const { data } = useSelector((state) => state.getReviewReducer)
  const { value } = useSelector((state) => state.getConsultationProfileReducer)
  const params = useParams()
  const dispatch = useDispatch()

  const [reviewResponse, setReviewResponse] = useState(null)
  // reviews API
  useEffect(
    () => dispatch(getReview(params.id)),
    [params.id, dispatch, reviewResponse]
  )

  // Approved API
  const approvedReview = () => {
    axios
      .patch(
        `${API_CONSTANTS.BASEURL}admin/feedback/${params.id}/verify`,
        {},
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        setReviewResponse(res.data.response)
      })
  }
  return (
    <>
      <div className='container-fluid p-4'>
        <div className='row bg-white rounded p-4'>
          <div className='col-12'>
            <div className='d-flex justify-content-between'>
              <div className='d-flex'>
                <span>
                  <img
                    src={value.patientInfo.url? value.patientInfo.url: 'https://img.icons8.com/material/72/000000/user-male-circle--v1.png'}
                    alt=''
                    style={{
                      width: 40,
                      borderRadius: 50,
                      height: 40,
                      marginRight: '10px',
                    }}
                  />
                </span>
                <span>
                  <h6 className='m-0'>{value?.patientInfo?.name}</h6>
                  <small>to doctor</small>
                </span>
              </div>
              <div className='d-flex' style={{ columnGap: '15px' }}>
                <button
                  className={
                    data?.isReviewVerified ? 'approved' : 'not-approved'
                  }
                  onClick={() => approvedReview()}
                  disabled={!data?.reviewByDoctors && !data?.reviewByPatients}
                >
                  {data?.isReviewVerified && (
                    <i className='fas fa-check' style={{ margin: '0 5px' }} />
                  )}
                  {data?.isReviewVerified ? 'Approved' : 'Approve'}
                </button>
              </div>
            </div>
          </div>
          <div className='col-8 my-3'>
            <q>{data?.reviewByPatients ? data?.reviewByPatients?.review : ''}</q>
          </div>
          <div className='col-12 border-top p-2 mt-3'>
            <table
              className='bg-white rounded profile-card-width mb-3'
              style={{ width: '500px' }}
            >
              <thead></thead>
              <tbody>
                <tr>
                  <th>Rate video quality</th>
                  <td className='d-flex' style={{ columnGap: '15px' }}>
                    {starRating.map((star, index) => (
                      <span
                        key={index}
                        className={
                          data?.reviewByPatients
                            ? index + 1 <= data?.reviewByPatients?.videoCall
                              ? 'active-star'
                              : 'inactive-star'
                            : ''
                        }
                      >
                        {star}
                      </span>
                    ))}
                  </td>
                </tr>
                {/* <tr>
                  <th>Rate your satisfaction level</th>
                  <td className='d-flex' style={{ columnGap: '15px' }}>
                    {starRating.map((star, index) => (
                      <span
                        key={index}
                        className={
                          data?.reviewByPatients
                            ? index + 1 <= data?.reviewByPatients?.satisfaction
                              ? 'active-star'
                              : 'inactive-star'
                            : ''
                        }
                      >
                        {star}
                      </span>
                    ))}
                  </td>
                </tr>
                <tr>
                  <th>Rate the doctor’s communication</th>
                  <td className='d-flex' style={{ columnGap: '15px' }}>
                    {starRating.map((star, index) => (
                      <span
                        key={index}
                        className={
                          data?.reviewByPatients
                            ? index + 1 <= data?.reviewByPatients?.communication
                              ? 'active-star'
                              : 'inactive-star'
                            : ''
                        }
                      >
                        {star}
                      </span>
                    ))}
                  </td>
                </tr> */}
                <tr>
                  <th>Rate the doctor’s behaviour</th>
                  <td className='d-flex' style={{ columnGap: '15px' }}>
                    {starRating.map((star, index) => (
                      <span
                        key={index}
                        className={
                          data?.reviewByPatients
                            ? index + 1 <= data?.reviewByPatients?.behaviour
                              ? 'active-star'
                              : 'inactive-star'
                            : ''
                        }
                      >
                        {star}
                      </span>
                    ))}
                  </td>
                </tr>
                <tr>
                  <th>Do you recommend this doctor?</th>
                  <td className='d-flex' style={{ columnGap: '10px' }}>
                    <span style={{ color: '#CCCCCC' }}>
                      <span
                        className={
                          data?.reviewByPatients
                            ? data?.reviewByPatients?.recommended === true
                              ? 'recommended'
                              : ''
                            : ''
                        }
                      >
                        <i className='fas fa-thumbs-up'></i>
                      </span>
                      <span className='mx-1' style={{ color: '#5C5C5C' }}>
                        Yes, I do
                      </span>
                    </span>
                    <span style={{ color: '#CCCCCC' }}>
                      <span
                        className={
                          data?.reviewByPatients
                            ? data?.reviewByPatients?.recommended === false
                              ? 'not-recommended'
                              : ''
                            : ''
                        }
                      >
                        <i className='fas fa-thumbs-down'></i>
                      </span>
                      <span className='ms-1' style={{ color: '#5C5C5C' }}>
                        {' '}
                        No, i don’t
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className='row bg-white rounded p-4 mt-3'>
          <div className='col-12'>
            <div className='d-flex justify-content-between'>
              <div className='d-flex'>
                <span>
                  <img
                    src={value?.doctorInfo?.profileUrl}
                    alt=''
                    style={{
                      width: 40,
                      borderRadius: 50,
                      height: 40,
                      marginRight: '10px',
                    }}
                  />
                </span>
                <span>
                  <h6 className='m-0'>
                    {value?.doctorInfo?.title +
                      ' ' +
                      value?.doctorInfo?.firstName +
                      ' ' +
                      value?.doctorInfo?.lastName}
                  </h6>
                  <small>to Patient</small>
                </span>
              </div>
            </div>
          </div>
          <div className='col-8 my-3'>
            <q>
              {data?.reviewByDoctors
                ? data?.reviewByDoctors?.review
                  ? data?.reviewByDoctors?.review
                  : ''
                : ''}
            </q>
          </div>
          <div className='col-12 border-top p-2 mt-3'>
            <table
              className='bg-white rounded profile-card-width mb-3'
              style={{ width: '500px' }}
            >
              <thead></thead>
              <tbody>
                <tr>
                  <th>Rate Patient Behaviour</th>
                  <td className='d-flex' style={{ columnGap: '15px' }}>
                    {starRating.map((star, index) => (
                      <span
                        key={index}
                        className={
                          data?.reviewByDoctors
                            ? index + 1 <= data?.reviewByDoctors?.behaviour
                              ? 'active-star'
                              : 'inactive-star'
                            : ''
                        }
                      >
                        {star}
                      </span>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <style jsx='true'>
        {`
          th {
            font-size: 14px;
            font-weight: 400;
            color: #5c5c5c;
            padding: 10px 0;
          }
          td {
            text-align: right;
            padding: 10px 0;
          }
          .active-star i {
            color: #009b94;
          }
          .inactive-star i {
            color: #99d7d4;
          }
          .not-recommended i {
            color: #b00020;
            font-size: 20px;
          }
          .recommended i {
            color: #009b94;
            font-size: 20px;
          }
          .not-approved {
            background: #E0F3F2;
            color: #009B94;
            padding: 0px 10px;
            border-radius: 5px;
            border: 1px solid #009B94;
          }
          .not-approved:hover {
            color: #009B94 !important;
            background-color: #fff !important;
            border: 1px solid #009B94;
          }
          .approved {
            background: #009b94;
            color: #fff;
            padding: 0px 10px;
            border: none;
            border-radius: 5px;
          }
          button[disabled] {
            pointer-events: none; 
            opacity: 0.6;
          }
        `}
      </style>
    </>
  )
}
