import React from "react";
import eshaafiLogo from "../../../../assets/images/logo.png";
import QR from "../../../../assets/images/QR.png";
import rx from "../../../../assets/images/rx.svg";
import { decode as base64_decode } from "base-64";
import moment from "moment";

export default function Pdf() {
  // try {
  //   console.log("hello");
  //   const queryString = window.location.search;
  //   const urlParams = new URLSearchParams(queryString);
  //   const data = urlParams.get("data");
  //   console.log(data);
  //   let decoded = base64_decode(data);
  //   console.log(decoded);
  //   const obj = JSON.parse(decoded);
  //   let firstPage = obj.e_prescription.slice(0, 3);
  //   let secondPage = obj.e_prescription.slice(6);
  //   let date = new Date().toString().split(" ").splice(1, 3).join(" ");
  //   console.log(obj.e_prescription);
  //   const testsArr = obj.e_prescription.filter(
  //     (item) => item.category === "test"
  //   );
  //   console.log(testsArr);
  // } catch (error) {
  //   // Handle the error gracefully, e.g., display a message to the user or log the error.
  //   console.error("An error occurred:", error);
  //   // Optionally, set some fallback values or handle the error state here.
  //   let obj = { e_prescription: [] };
  //   let firstPage = [];
  //   let secondPage = [];
  //   let date = new Date().toString().split(" ").splice(1, 3).join(" ");
  //   const testsArr = [];
  // }
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const data = urlParams.get("data");
  let decoded = base64_decode(data);

  const obj = JSON.parse(decoded);
  let firstPage = obj.e_prescription
    .filter((item) => item.category !== "test")
    .slice(0, 6);
  let secondPage = obj.e_prescription.slice(6);
  let date = new Date().toString().split(" ").splice(1, 3).join(" ");

  const testsArr = obj.e_prescription.filter(
    (item) => item.category === "test"
  );
  // return <h1>hello</h1>;
  return (
    <>
      <div className="d-flex flex-column ">
        <section className="printing bg-white  pt-4 px-2 d-flex flex-column ">
          <div className="d-flex flex-column">
            <div className="row ">
              <div className="col">
                <div className="d-flex" style={{ marginLeft: "15px" }}>
                  <span style={{ marginLeft: "15px" }}>
                    <img src={eshaafiLogo} alt="" className={" logo-style "} />
                  </span>
                  <div className="d-flex flex-column mx-1">
                    <h3 className={"sehaafitext"}>eShaafi</h3>
                    <span className="blue-border"></span>
                    <p className="care-text">Care that never Quits</p>
                    <p className="address">
                      7th Floor, Business Hub Plaza, Block D <br /> DHA Phase 8,
                      Lahore, Punjab
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mx-4">
                <div className="d-flex justify-content-end">
                  <p className={"address mt-2 "}>
                    you can download app <br />
                    using this QR code
                  </p>
                  <span>
                    <img
                      src={QR}
                      alt=""
                      width={"80px"}
                      height={"80px"}
                      className={"QR-code"}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className={"card-style mx-1 px-1 mb-0 pb-0 mt-3"}>
              <div className={"d-flex px-1"}>
                <table className={"m-0 p-0"}>
                  <tbody>
                    <tr className="p-0 m-0">
                      <th className="p-0 m-0">
                        <span className="patient-text">Patient Name :</span>
                      </th>
                      <td>
                        <span className="patient-objext mx-1">
                          {obj.patient_info ? obj.patient_info.name : ""}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className="patient-text">Age :</span>
                      </th>
                      <td>
                        <span className="patient-objext mx-1">
                          {/* {moment().diff(
                            moment(
                              obj.patient_info ? obj.patient_info.dob : "N/A",
                              "YYYY-MM-DD"
                            ),
                            "years"
                          )} */}
                          {obj.patient_info ? obj.patient_info.age : ""}
                          {" Years"}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ marginLeft: "20px" }}>
                  <tbody>
                    <tr>
                      <th>
                        <span className="patient-text">Gender :</span>
                      </th>
                      <td>
                        <span className="patient-objext mx-1">
                          {obj.patient_info ? obj.patient_info.gender : ""}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {" "}
                        <span className="patient-text"> MR NO :</span>
                      </th>
                      <td>
                        <span className="patient-objext mx-1 ">
                          {obj.patient_info ? obj.patient_info.mr_no : ""}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ marginLeft: "20px" }}>
                  <tbody>
                    <tr>
                      <th>
                        <span className="patient-text">Date :</span>
                      </th>
                      <td>
                        <span className="patient-objext mx-1">{date}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {" "}
                        <span className="patient-text"> </span>
                      </th>
                      <td>
                        <span className="patient-objext mx-1 ">
                          {/* {obj.patient_info ? obj.patient_info.mr_no : ""} */}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="col">
                  <div className="justify-content-end d-flex ">
                    <div className="flex-column ">
                      <span className="docter-text">
                        {obj.doctor_info ? obj.doctor_info.name : ""}
                      </span>
                      <p className="docter-pmdc">
                        {obj.doctor_info ? obj.doctor_info.specialization : ""}{" "}
                        {}| {obj.doctor_info ? obj.doctor_info.pmdc : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className="docter-date mt-2" style={{ marginLeft: 5 }}>
              {/* {date} */}
            </span>
          </div>
          <div className="d-flex flex-column print-table-class">
            <div className="d-flex">
              <div
                className="row mx-1 w-100"
                style={{
                  height: "703px",
                }}
              >
                <div className="col-9 p-0">
                  <div
                    className="me-3 card-style"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <table className={"w-100 "}>
                      <thead>
                        <tr style={{ background: "#eee" }} className={"w-100"}>
                          <th style={{ width: "5px" }}>
                            <img
                              src={rx}
                              alt=""
                              style={{ marginLeft: 5, marginTop: 3 }}
                            />
                          </th>
                          <th className="medcine-name px-2 ">Medicine name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {firstPage.map((data, index) => (
                          <tr className={""}>
                            <td style={{ display: "flex", margin: "10px 0" }}>
                              <span className={"days-text mx-2"}>
                                {index + 1}.
                              </span>
                            </td>
                            <td style={{ padding: "5px 0" }}>
                              <div
                                className={
                                  " medcin-instruction  d-flex flex-column px-2"
                                }
                              >
                                <span
                                  className={"medcinename breaktext wordbreak"}
                                >
                                  {data.medicine_name}
                                </span>
                                <span className="txt-align breaktext  wordbreak">
                                  {data.instruction}
                                </span>
                                <span className={"txtdosage text-end mb-3"}>
                                  <span className={"d-flex flex-row-reverse"}>
                                    <span> {data.quantity}</span>
                                    <span> &nbsp;</span>
                                    {
                                      <>
                                        <span>
                                          {data.category === "Lotion" ||
                                          data.category === "Cream" ||
                                          data.category === "Inj" ||
                                          data.category === "oil" ||
                                          data.category === "Oil" ||
                                          data.category === "Spray" ||
                                          data.category === "Drops" ||
                                          data.category === "Sachet" ||
                                          data.category === "Gel" ||
                                          data.category === "Balm" ||
                                          data.category === "Bandages" ||
                                          data.category === "oil"
                                            ? "بار"
                                            : ""}
                                        </span>
                                        <span> &nbsp;</span>
                                      </>
                                    }
                                    <span>{data.category}</span>
                                    <span> &nbsp;</span>
                                    {data.category === "Lotion" ||
                                      data.category === "Cream" ||
                                      data.category === "oil" ||
                                      data.category === "Inj" ||
                                      data.category === "Oil" ||
                                      data.category === "Spray" ||
                                      data.category === "Drops" ||
                                      data.category === "Sachet" ||
                                      data.category === "Gel" ||
                                      data.category === "facewash" ||
                                      data.category === "Balm" ||
                                      data.category === "Bandages" ||
                                      data.category === "test" || (
                                        <>
                                          {data.intake.breakfast === true
                                            ? "صبح"
                                            : ""}
                                          <span> &nbsp;</span>
                                          {data.intake.lunch === true
                                            ? "دوپہر"
                                            : ""}
                                          <span> &nbsp;</span>
                                          {data.intake.dinner === true
                                            ? "  شام"
                                            : ""}

                                          <span>
                                            {data.dosage_type === "During"
                                              ? "  کھانے کے دوران"
                                              : data.dosage_type === "After"
                                              ? "  کھانے کے بعد"
                                              : "  کھانے سے پہلے"}
                                          </span>
                                        </>
                                      )}
                                    <span> &nbsp;</span>
                                    <span>
                                      {data.category === "test"
                                        ? "کرواے"
                                        : "استعمال کریں"}
                                    </span>
                                    <span> &nbsp;</span>
                                    <span>{`${data.duration})`}</span>
                                    <span> &nbsp;</span>
                                    <span>{`(دن تک `}</span>
                                  </span>
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      style={{ marginLeft: "10px" }}
                      className={"d-flex text-center"}
                    >
                      {obj.follow_up ? (
                        <span>
                          <span
                            style={{ fontWeight: "600", marginRight: "10px" }}
                            className={"txt-compiaints  d-flex "}
                          >
                            {obj.follow_up_appoint_date ? "Follow up:" : ""}
                            <p className={"txt-compiaints text-center px-1 "}>
                              <span className={"px-1"}>
                                Make an appointment before ,
                              </span>

                              {moment(
                                obj ? obj.follow_up_appoint_date : ""
                              ).format("LL")}
                            </p>
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-3 card-style  ">
                  <div className={"flex-column "}>
                    <div>
                      <span className={"m-0 p-0 txt-compiaints"}>
                        Test(s)/Investigation(s)
                      </span>
                      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                        {testsArr.map((item, index) => (
                          <li key={index}>
                            <p className="m-0 medcinename breaktext wordbreak text-capitalize">
                              {item.medicine_name}
                            </p>
                            <p
                              className="txt-align breaktext  wordbreak"
                              style={{ whiteSpace: "no-wrap" }}
                            >
                              {item.instruction}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {obj ? (
                      <div>
                        <span className={"m-0 p-0 txt-compiaints"}>
                          {" "}
                          Investigations{" "}
                        </span>
                        <p className={"txt-align"}>{obj.complaints}</p>
                      </div>
                    ) : (
                      ""
                    )}
                    {obj ? (
                      <div>
                        <span className={"txt-compiaints"}>Diagnosis </span>
                        <p className={"txt-align"}>{obj.diagnosis}</p>
                      </div>
                    ) : (
                      ""
                    )}

                    {obj ? (
                      <div>
                        <span className={"m-0 p-0 txt-compiaints"}>
                          Clinical Details:
                        </span>
                        <p className={"txt-align"}>{obj.clinicalDetails}</p>
                      </div>
                    ) : (
                      ""
                    )}

                    {obj ? (
                      <div>
                        <span className={"m-0 p-0 txt-compiaints"}>
                          Drug Aliergies:{" "}
                        </span>
                        <p className={"txt-align"}>{obj.drugAllergies}</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row  d-flex flex-column bottom-line px-3 pt-4 ">
              <div className=" card-style   mb-2 px-2 ">
                <div className={"border rounded  my-2"}>
                  <span className={"flex-column d-flex"}>
                    <p className={"genrate-text p-2"}>
                      ہدایات
                      <p className={"txt-align px-2 "}>{obj.instructions}</p>
                    </p>
                  </span>
                </div>
              </div>

              <span className={"border rounded  mt-2"} />
              <div className={"d-flex justify-content-between  mt-3 mb-4 "}>
                <span className={"electronically  d-flex"}>
                  This is an electronically verified prescription
                </span>
                <span className={"genrate-text"}>
                  <span>
                    ہدایات : مکمل خوراک لیں اور بغیر مشورے کے دوا کو بند، کم یا
                    تبدیل نہ کریں۔ شکریہ
                  </span>
                </span>
              </div>
            </div>
          </div>
        </section>

        {/*second page lenght*/}
        {secondPage.length >= 1 && (
          <section className="printing bg-white mt-1  pt-5  px-2 d-flex flex-column ">
            <div className="d-flex flex-column">
              <div className="row ">
                <div className="col">
                  <div className="d-flex" style={{ marginLeft: "15px" }}>
                    <span style={{ marginLeft: "15px" }}>
                      <img src={eshaafiLogo} alt="" className={" logo-style"} />
                    </span>
                    <div className="d-flex flex-column mx-1">
                      <h3 className={"sehaafitext"}>eShaafi</h3>
                      <span className="blue-border"></span>
                      <p className="care-text">Care that never Quits</p>
                      <p className="address">
                        7th Floor, Business Hub Plaza, Block <br />D DHA Phase
                        8, Lahore, Punjab
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col mx-4">
                  <div className="d-flex justify-content-end">
                    <p className={"address mt-2 "}>
                      you can download app <br />
                      using this QR code
                    </p>
                    <span>
                      <img
                        src={QR}
                        alt=""
                        width={"80px"}
                        height={"80px"}
                        className={"QR-code"}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className={"card-style mx-1 px-1 mb-0 pb-0 mt-3"}>
                <div className={"d-flex px-1"}>
                  <table className={"m-0 p-0"}>
                    <tbody>
                      <tr className="p-0 m-0">
                        <th className="p-0 m-0">
                          <span className="patient-text">Patient Name :</span>
                        </th>
                        <td>
                          <span className="patient-objext mx-1">
                            {obj.patient_info ? obj.patient_info.name : ""}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span className="patient-text">Age :</span>
                        </th>
                        <td>
                          <span className="patient-objext mx-1">
                            {moment().diff(
                              moment(
                                obj.patient_info ? obj.patient_info.dob : "",
                                "YYYY-MM-DD"
                              ),
                              "years"
                            )}
                            Years
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table style={{ marginLeft: "20px" }}>
                    <tbody>
                      <tr>
                        <th>
                          <span className="patient-text">Gender :</span>
                        </th>
                        <td>
                          <span className="patient-objext mx-1">
                            {obj.patient_info ? obj.patient_info.gender : ""}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {" "}
                          <span className="patient-text"> MR NO :</span>
                        </th>
                        <td>
                          <span className="patient-objext mx-1 ">
                            {obj.patient_info ? obj.patient_info.mr_no : ""}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col">
                    <div className="justify-content-end d-flex ">
                      <div className="flex-column ">
                        <span className="docter-text">
                          {obj.doctor_info ? obj.doctor_info.name : ""}
                        </span>
                        <p className="docter-pmdc">
                          {obj.doctor_info
                            ? obj.doctor_info.specialization
                            : ""}{" "}
                          {}| {obj.doctor_info ? obj.doctor_info.pmdc : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <span className="docter-date mt-2" style={{ marginLeft: 5 }}>
                {date}
              </span>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex">
                <div
                  className="row mx-1 w-100"
                  style={{
                    height: "703px",
                  }}
                >
                  <div className="col-9  p-0 ">
                    <div
                      className="me-3 card-style"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <table className={"w-100 "}>
                        <thead>
                          <tr
                            style={{ background: "#eee" }}
                            className={"w-100"}
                          >
                            <th style={{ width: "5px" }}>
                              <img
                                src={rx}
                                alt=""
                                style={{ marginLeft: 5, marginTop: 3 }}
                              />
                            </th>
                            <th className="medcine-name px-2 ">
                              Medicine name
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {secondPage.map((data, index) => (
                            <tr className={""}>
                              <td style={{ display: "flex", margin: "10px 0" }}>
                                <span className={"days-text mx-2"}>
                                  {index + 7}.
                                </span>
                              </td>
                              <td style={{ padding: "5px 0" }}>
                                <div
                                  className={
                                    " medcin-instruction  d-flex flex-column px-2"
                                  }
                                >
                                  <span
                                    className={
                                      "medcinename breaktext wordbreak"
                                    }
                                  >
                                    {data.medicine_name}{" "}
                                  </span>

                                  <span className="txt-align breaktext wordbreak">
                                    {data.instruction}
                                  </span>
                                  <span className={"txtdosage text-end mb-3"}>
                                    <span className={"d-flex flex-row-reverse"}>
                                      <span> {data.quantity}</span>
                                      <span> &nbsp;</span>

                                      {
                                        <>
                                          <span>
                                            {data.category === "Lotion" ||
                                            data.category === "Cream" ||
                                            data.category === "oil"
                                              ? "بار"
                                              : ""}
                                          </span>
                                          <span> &nbsp;</span>
                                        </>
                                      }
                                      <span>{data.category}</span>
                                      <span> &nbsp;</span>
                                      {data.category === "Lotion" ||
                                        data.category === "Cream" ||
                                        data.category === "oil" ||
                                        data.category === "Inj" ||
                                        data.category === "oil" ||
                                        data.category === "Oil" ||
                                        data.category === "Spray" ||
                                        data.category === "Drops" ||
                                        data.category === "Sachet" ||
                                        data.category === "Gel" ||
                                        data.category === "Balm" ||
                                        data.category === "Bandages" ||
                                        data.category === "test" || (
                                          <>
                                            {data.intake.breakfast === true
                                              ? "صبح"
                                              : ""}
                                            <span> &nbsp;</span>
                                            {data.intake.lunch === true
                                              ? "دوپہر"
                                              : ""}
                                            <span> &nbsp;</span>
                                            {data.intake.dinner === true
                                              ? "  شام"
                                              : ""}

                                            <span>
                                              {data.dosage_type === "During"
                                                ? "  کھانے کے دوران"
                                                : data.dosage_type === "After"
                                                ? "  کھانے کے بعد"
                                                : "  کھانے سے پہلے"}
                                            </span>
                                          </>
                                        )}
                                      <span> &nbsp;</span>
                                      <span>{"استعمال کریں"}</span>
                                      <span> &nbsp;</span>
                                      <span>{`${data.duration})`}</span>
                                      <span> &nbsp;</span>
                                      <span>{`(دن تک `}</span>
                                    </span>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div
                        style={{ marginLeft: "10px" }}
                        className={"d-flex text-center"}
                      >
                        {obj.follow_up ? (
                          <span>
                            <span
                              style={{ fontWeight: "600", marginRight: "10px" }}
                              className={"txt-compiaints  d-flex"}
                            >
                              {obj.follow_up_appoint_date ? "Follow up:" : ""}
                              <p className={"txt-compiaints text-center px-1 "}>
                                <span className={"px-1"}>
                                  Make an appointment before ,
                                </span>

                                {moment(
                                  obj ? obj.follow_up_appoint_date : ""
                                ).format("LL")}
                              </p>
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex flex-column bottom-line mx-1 ">
                <div className=" card-style  mt-4 mb-2 px-2 ">
                  <div className={"border rounded  my-2"}>
                    <span className={"flex-column d-flex"}>
                      <p className={"genrate-text p-2"}>
                        ہدایات
                        <p className={"txt-align px-2 "}>{obj.instructions}</p>
                      </p>
                    </span>
                  </div>
                </div>

                <span className={"border rounded px-2 mt-2"} />
                <div className={"d-flex justify-content-between  mt-3 mb-4 "}>
                  <span className={" electronically  d-flex"}>
                    This is an electronically verified prescription
                  </span>
                  <span className={"genrate-text"}>
                    <span>
                      ہدایات : مکمل خوراک لیں اور بغیر مشورے کے دوا کو بند، کم
                      یا تبدیل نہ کریں۔ شکریہ
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <style jsx="true">{`
        @media print {
          @page {
            size: A4 portrait;
            margin: 1px;
            font-family: Poppins !important;
            font-family: Sawarabi Mincho !important;
          }
          .printing {
            height: 99vh !important;
            font-family: Poppins !important;
            font-family: Sawarabi Mincho !important;
          }
          .print-table-class {
            height: inherit !important;
            justify-content: flex-between !important;
          }
          .bottom-line {
            display: block;
            position: fixed;
            bottom: 20px;
          }
        }
        body {
          width: 21cm;
          // height: 29.7cm;
          margin: 0 auto;
        }
        @page {
          size: A4 portrait;
          margin: 1px;
        }
        .txtdosage {
          font-family: Scheherazade New;
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #020b14;
        }
        .genrate-text {
          font-weight: 400;
          font-size: 12px;
          color: #192a3e;
          font-family: Scheherazade New;
        }
        .electronically {
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 16px;
          color: #192a3e;
        }
        .med-instructions {
          font-size: 10px;
        }
        .txt-compiaints {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: #020b14;
        }
        .txt-align {
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: #192a3e;
        }
        .wordbreak {
          width: 77%;
          word-break: break-all;
        }
        .card-style {
          background: #ffffff;
          box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
            0px 1.85px 6.25px rgba(0, 0, 0, 0.06);
          border-radius: 4px;
          border: 1px solid #d7ddea;
        }
        .inst-text {
          font-size: 12px;
          font-weight: 800px;
          color: #192a3e;
          margin-top: -7px;
        }
        .complaints-text {
          font-size: 16px;
          font-weight: 800px;
          color: #020b14;
        }
        .fallow {
          font-size: 21px;
          font-weight: 800px;
          color: #192a3e;
        }
        .logo-style {
          width: 55px !important;
          height: 60px !important;
        }
        .sehaafitext {
          font-family: Poppins !important;
          font-style: normal;
          margin-bottom: 2px !important;
          color: #00568a !important;
          font-weight: 600;
          font-size: 23px;
          line-height: 16px;
          letter-spacing: 1.7px;
          position: relative;
        }
        .sehaafitext:after {
          content: "";
          width: 132px;
          height: 3px;
          background-color: #00568a;
          position: absolute;
          bottom: -6px;
          left: 0;
        }

        .care-text {
          margin-bottom: 0px;
          color: #00568a;
          font-family: Poppins !important;
          font-style: normal !important ;
          font-weight: 600;
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 1.2px;
          margin-top: 5px;
        }
        .address {
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 9px;
          color: #7e7e7e;
        }
        .patient-text {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          color: #707683;
        }
        .patient-objext {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 16px;
          color: #192a3e;
        }
        .docter-text {
          font-family: Sawarabi Mincho;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #192a3e;
        }
        .docter-pmdc {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 9px;
          color: #707683;
        }
        .docter-date {
          font-family: Poppins;
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          color: #192a3e;
        }
        .medcine-name {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          color: #020b14;
        }
        .freq-text {
          font-family: Poppins;
          font-style: normal;
          font-weight: 700;
          font-size: 9px;
          color: #020b14;
        }
        .morn-text {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 7px;
          color: #020b14;
        }
        .medcinename {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          color: #00568a;
        }
        .dot-text {
          font-size: 16px !important;
          font-weight: 800px !important;
          margin-left: 1px !important;
          color: #020b14 !important;
        }
        .medcin-instruction {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 9px;
          color: #020b14;
        }
        .days-text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 9px;
          color: #020b14;
        }
        .daystext {
          font-size: 10px;
          font-weight: 300px;
          color: #ffffff;
        }
        .fallow-text {
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          color: #192a3e;
        }
        .prara-text {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 9px;
          color: #192a3e;
          margin-top: -23px;
        }
      `}</style>
    </>
  );
}
