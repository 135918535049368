/*
Author: Umer Shabir 
Date: 20-March-2022
File: This is the Dashboard Navbar Component.
*/
// Dependencies
import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import EditPasswordButton from './EditPasswordButton'
// Navbar Component.
export default function NavBar() {
  const dispatch = useDispatch()

  const HandleLogout = () => {
    dispatch({ type: 'adminLoginSlice/accessDenyied' })
    localStorage.clear()
  }
  return (
    <>
      <div className='bg-white d-flex justify-content-between align-items-center p-2 admin-nav-bar'>
        <div className='ms-2 d-flex'>
          <span style={{ marginLeft: '40px' }}>
            <Link to='/' style={{ color: '#009B94' }}>
              Admin Dashboard
            </Link>
          </span>
        </div>

        <div
          className='d-flex justify-content-between'
          style={{ fontSize: '16px' }}
        >
          <div className='dropdown'>
            <Link to="/notifications" className='btn' type='button'>
              <span style={{ color: '#99D7D4', fontSize: '25px' }}>
                <i className='far fa-bell'></i>
              </span>
            </Link>
          </div>
          <div className='dropdown'>
            <button
              className='btn'
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <span style={{ fontSize: '25px' }}>
                <i className='fas fa-user-circle'></i>
              </span>
            </button>
            <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
              <li>
                <span
                  className='dropdown-item custom-dropdown-item'
                >
                  <EditPasswordButton />
                </span>
              </li>
              <li>
                <span
                  className='dropdown-item custom-dropdown-item'
                  onClick={HandleLogout}
                >
                  Logout
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <style jsx='true'>
        {`
          .admin-nav-bar {
            height: 60px;
            position: fixed;
            width: 100%;
            z-index: 9 !important;
          }
          .admin-nav-bar :: after {
            height: 60px;
            position: fixed;
            width: calc(100% - 256px);
            z-index: 1 !important;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          }
          .custom-search-field {
            border: none;
          }
          .custom-search-field:focus-visible {
            outline: none;
            border-bottom: 2px solid #99d7d4 !important;
          }
          .custom-dropdown-item:active {
            background: #99d7d4;
            color: #fff;
          }
          .custom-dropdown-item {
            cursor: pointer;
          }
          .notification-count {
            background: #ff9300;
            color: #fff;
            padding: 0px 5px;
            border-radius: 50px;
            position: absolute;
            right: 0;
          }
          .burgerMenu {
            display: none;
          }
          .menubars {
            cursor: pointer;
          }
        `}
      </style>
    </>
  )
}
