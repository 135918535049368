import React, { useState } from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import EditAttributesIcon from '@mui/icons-material/EditAttributes'
import GetAppIcon from '@mui/icons-material/GetApp'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import Listing from './Listing'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import axios from 'axios'
import { API_CONSTANTS } from '../../API_CONSTANTS'
//
function CustomizedInputBase({ type, method, addroute }) {
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const postData = () => {
    setLoading(true)
    axios
      .post(
        API_CONSTANTS.BASEURL_2 + 'admin/roles',
        {
          role: type,
          method: method,
          path: value,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setLoading(false)
        addroute(value, method)
        setValue('')
      })
  }
  return (
    <Paper
      component='form'
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={'Add new ' + method + ' route'}
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
      <IconButton
        color='primary'
        sx={{ p: '10px' }}
        aria-label='directions'
        onClick={postData}
        disabled={!value || loading}
      >
        <KeyboardDoubleArrowRightIcon />
      </IconButton>
    </Paper>
  )
}
//
export default function Path({ data, editRoute, deleteroute, addroute }) {
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component='nav'
      aria-labelledby='nested-list-subheader'
    >
      <>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            {data.name === 'DELETE' ? (
              <DeleteIcon />
            ) : data.name === 'PATCH' ? (
              <EditAttributesIcon />
            ) : data.name === 'POST' ? (
              <UpgradeIcon />
            ) : (
              <GetAppIcon />
            )}
          </ListItemIcon>
          <ListItemText primary={data.name} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {data.path.map((item, index) => (
              <Listing
                key={index}
                matter={item}
                type={data.type}
                method={data.name}
                editRoute={editRoute}
                deleteroute={deleteroute}
              />
            ))}
          </List>
          <CustomizedInputBase
            type={data.type}
            method={data.name}
            addroute={addroute}
          />
        </Collapse>
      </>
    </List>
  )
}
