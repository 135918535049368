import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../../API_CONSTANTS'

export const getDoctorOverview = createAsyncThunk(
  'getDoctorOverview',
  async (paramsID) => {
    const response = await axios
      .get( `${API_CONSTANTS.BASEURL}admin/doctors/${paramsID}`, {
        headers: API_CONSTANTS.headers(),
      })
      .then((res) => res.data.response)
    return response
  }
)
// reducer
export const getDoctorOverviewSlice = createSlice({
  name: 'getDoctorOverview',
  initialState: {
    value: {
      specialization: '',
      pastAppointmentId: '',
      validDays: '',
      coverLetter: '',
      delay: {
        currentMonth: '0',
        totaldelay: '0',
      },
      certifications: [],
      nextOfKin: {
        name: '',
        phone_no: '',
        relation: '',
      },
      phoneNo: '',
      city: '',
      personal_info: {
        profileUrl: '',
        firstName: '',
        lastName: '',
        title: '',
      },
      chatCharges: '',
      upcomingAppointmentCount: '',
      videoCallCharges: '',
      dob: '',
      educationalDocuments: [
        {
          end_date: '',
          edId: '',
          degree: '',
          location: '',
          institute_name: '',
          field_of_study: '',
          url: '',
          start_date: '',
        },
      ],
      achievementAwards: [],
      recommended: '',
      voluntaryservices: [],
      pastAppointmentCount: '',
      totalAppointmentCount: '',
      yearsOfExperience: '',
      rating: {
        count: '',
        behaviour: '',
        satisfaction: '',
        communication: '',
        recommended: '',
        averageRating: '',
      },
      services: ['', '', '', ''],
      review: [
        {
          patientInfo: '',
          appoint_date: '',
          review: '',
          recommended: false,
        },
        {
          patientInfo: '',
          appoint_date: '',
          review: '',
          recommended: true,
        },
        {
          patientInfo: '',
          appoint_date: '',
          review: '',
          recommended: true,
        },
      ],
      conditionsTreated: ['', '', ''],
      gender: '',
      upcomingAppointment: {
        appointmentId: ' ',
        dateTime: '',
        appointment_status: ' ',
        doctorProfileUrl: ' ',
        doctorName: ' ',
        patientName: ' ',
        patientProfileUrl: ' ',
      },
      emergencyNumber: '',
      Fname: '',
      Id: '',
      experiences: [
        {
          exId: '',
          employment_type: '',
          company: '',
          location: '',
          currently_work: '',
          title: '',
          url: '',
          start_date: '',
          end_date: '',
        },
      ],
      pastAppointment: {
        appointmentId: '',
        dateTime: '',
        appointment_status: '',
        doctorProfileUrl: '',
        doctorName: '',
        patientName: '',
        patientProfileUrl: '',
      },
      pmdc: '',
      is_blocked: false,
    },
  },
  reducers: {
    block: (state) => {
      state.value.is_blocked = true
    },
    unblock: (state) => {
      state.value.is_blocked = false
    },
  },
  extraReducers: {
    [getDoctorOverview.pending]: (state) => {
      state.loading = true
    },
    [getDoctorOverview.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.value = payload
    },
    [getDoctorOverview.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const getDoctorReducer = getDoctorOverviewSlice.reducer
