import React, { useMemo, useEffect, useState } from "react";
import Table from "../../Components/Table/index";
import axios from "axios";
import { API_CONSTANTS } from "../../API_CONSTANTS";
import Spinner from "react-bootstrap/Spinner";
import style from "../../Style/corporate.module.css";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

// import PatientFilterForm from "./PatientFilterForm";
import AlertMessage from "../../Components/AlertMessage";
// styling
const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};
const partnerStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
};
export default function CorportatesList() {
  // variables
  const [open, setOpen] = useState(false);
  const [addPartner, setAddPartner] = useState({
    companyName: "",
    noOfEmployee: "",
    corporateType: "",
    phoneNo: "",
    email: "",
    password: "",
  });
  const { companyName, noOfEmployee, corporateType, phoneNo, email, password } =
    addPartner;
  const [page, setPage] = useState(0);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [patientList, setpatientList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addPartnerLoading, setAddPartnerLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
  // functions
  const getDataFromFields = (e) => {
    setAddPartner((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setAddPartner({
      companyName: "",
      noOfEmployee: "",
      corporateType: "",
      phoneNo: "",
      email: "",
      password: "",
    });
    setOpen(false);
  };

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = lastEvaluatedKey;
      // We'll even set a delay to simulate a server here

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setpatientList(patientList.slice(startRow, endRow));

        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(Math.ceil(patientList.length / pageSize));
      }
    },
    [patientList, lastEvaluatedKey]
  );
  //
  // API Hatching data of all admins
  const userList = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_4}admin/corporates`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setIsLoading(true);
        setLastEvaluatedKey(res.data.response.LastEvaluatedKey);
        setpatientList(res.data.response.Items);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          window.localStorage.clear();
        }
        setIsLoading(true);
        setpatientList([]);
        // setError(error)
      });
  };
  //
  // handling load more data
  const handleLoadMoreData = () => {
    axios
      .post(
        `${API_CONSTANTS.BASEURL_4}admin/corporates?last=${lastEvaluatedKey}`,
        {},
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((response) => {
        setLastEvaluatedKey(response.data.response.LastEvaluatedKey);
        var patientListNextPage = response.data.response.Items;
        setpatientList([...patientList, ...patientListNextPage]);
        setPage(page + 1);
      })
      .then(() => setLoading(true));
  };
  // handle add new partner
  const handleAddPartner = (e) => {
    setAddPartnerLoading(true);
    e.preventDefault();
    axios
      .post(
        API_CONSTANTS.BASEURL_4 + `admin/corporate/auth`,
        {
          companyName,
          noOfEmployee,
          corporateType,
          phoneNo,
          email,
          password,
        },
        {
          headers: API_CONSTANTS.headers(),
        }
      )
      .then((res) => {
        setAddPartnerLoading(false);
        toast(res.data.response.message, {
          type: "success",
        });
        setAddPartner({
          companyName: "",
          noOfEmployee: "",
          corporateType: "",
          phoneNo: "",
          email: "",
          password: "",
        });
      })
      .catch((err) => {
        setAddPartnerLoading(false);
        toast(err.response.data.response.message, {
          type: "error",
        });
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Partners",
        accessor: (property) =>
          property.companyName
            ? property.companyName.charAt(0).toUpperCase() +
              property.companyName.slice(1)
            : "UnNamed",
      },
      {
        Header: "No. of Employees",
        accessor: (property) =>
          property.employeeCount ? property.employeeCount : "N/A",
      },

      {
        Header: "Industry",
        accessor: (property) =>
          property.corporateType ? property.corporateType : "N/A",
      },
      {
        Header: "Plan Activated",
        accessor: (item) =>
          item.corporateInfo ? item.corporateInfo.planName : "-",
      },
      {
        Header: "Start Date",
        accessor: (item) =>
          item.corporateInfo
            ? moment(item.corporateInfo.startDate).format("DD-MM-YYYY")
            : "-",
      },
      {
        Header: "End Date",
        accessor: (item) =>
          item.corporateInfo
            ? moment(item.corporateInfo.endDate).format("DD-MM-YYYY")
            : "-",
        // moment(item.created_at, "YYYY-MM-DD").format("D-MM-YYYY"),
      },
      {
        Header: "Corporate Status",
        accessor: (item) => (
          <ActivatedDeactivated
            id={item}
            planStatus={item.corporateStatus}
            name={item.companyName}
          />
        ),
      },
      {
        Header: "Action",
        accessor: (property) => (
          <Link
            to={`/corporates/${property.corporateID}/${property.Id}/overview`}
            className={style.viewPartnerLink}
          >
            View
          </Link>
        ),
      },
    ],
    []
  );
  const is_SessionExpire = () => {
    window.localStorage.clear();
    window.location.reload();
  };
  // this a check for filter if patientlist length is equal to one and user want all the patient.

  // rendering API
  useEffect(() => {
    if (patientList.length < 1) {
      userList();
    }
  }, []);
  // Table rendering
  if (error) {
    return (
      <AlertMessage
        errorMessage={
          error.message === "Request failed with status code 404"
            ? "This record does not exist"
            : error.message === "Request failed with status code 401"
            ? is_SessionExpire()
            : error.message
        }
      />
    );
  } else {
    return (
      <>
        <section className={style.corporatePageSection}>
          {/* add new corporate  */}
          <Button
            variant="contained"
            className={style.addPartnerBtn}
            onClick={handleOpen}
          >
            <AddIcon />
            Add New Partner
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={partnerStyles}>
              <div className={style.addPartnerModalContainer}>
                <Button
                  className={style.closeBtnPartnerModal}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Button>
                <form onSubmit={handleAddPartner}>
                  <div className={style.addPartnerModal}>
                    <TextField
                      id="standard-basic"
                      label="Company Name"
                      variant="standard"
                      name="companyName"
                      onChange={getDataFromFields}
                      required
                      value={companyName}
                    />
                    <TextField
                      id="standard-basic"
                      label="No. of Employees"
                      variant="standard"
                      name="noOfEmployee"
                      onChange={getDataFromFields}
                      required
                      type="number"
                      value={noOfEmployee}
                    />
                    <TextField
                      id="standard-basic"
                      label="Sector/ Industry"
                      variant="standard"
                      name="corporateType"
                      onChange={getDataFromFields}
                      required
                      value={corporateType}
                    />
                    <TextField
                      id="standard-basic"
                      label="Contact Number"
                      variant="standard"
                      name="phoneNo"
                      onChange={getDataFromFields}
                      required
                      type="tel"
                      value={phoneNo}
                    />
                    <TextField
                      id="standard-basic"
                      label="Email"
                      name="email"
                      variant="standard"
                      onChange={getDataFromFields}
                      required
                      type="email"
                      value={email}
                    />
                    <div>
                      <TextField
                        id="standard-basic"
                        label="Password"
                        name="password"
                        variant="standard"
                        onChange={getDataFromFields}
                        required
                        value={password}
                      />
                      {!password && (
                        <p
                          style={{
                            fontSize: 11,
                          }}
                        >
                          Password length must be 8 character long, including
                          atleast one uppercase, one speacial letter, and one
                          numeric value.
                        </p>
                      )}
                      {password && (
                        <p
                          style={{
                            color: passwordRegex.test(password)
                              ? "green"
                              : "red",
                            fontSize: 12,
                          }}
                        >
                          {passwordRegex.test(password)
                            ? "Good to go"
                            : `Password length must be 8 character long, including one
                        Uppercase and one special letter including`}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={style.actionBtnModal + " mt-4 ps-3"}>
                    <LoadingButton
                      className={style.actionBtn}
                      variant="contained"
                      loading={addPartnerLoading}
                      type="submit"
                      // onClick={handleAddPartner}
                      disabled={
                        companyName &&
                        noOfEmployee &&
                        corporateType &&
                        phoneNo &&
                        email &&
                        passwordRegex.test(password)
                          ? false
                          : true
                      }
                    >
                      Add Partner
                    </LoadingButton>
                    <Button
                      onClick={handleClose}
                      color="error"
                      className={style.actionBtn}
                    >
                      Discard
                    </Button>
                  </div>
                </form>
              </div>
            </Box>
          </Modal>
          {/* corporate table listing  */}
          <div className="row rounded bg-white m-4">
            <h3
              style={{ fontSize: "16px", fontWeight: "500", padding: "18px" }}
            >
              All Corporate Partners
              {!isLoading && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ color: "#24AAA4" }}
                />
              )}
            </h3>
            <Table
              pageNumber={page}
              columns={columns}
              data={patientList}
              routingLink={``}
              pageCount={pageCount}
              fetchData={fetchData}
              loadMoreList={handleLoadMoreData}
              lastEvaluatedKey={lastEvaluatedKey}
              loading={loading}
              recordDownload={true}
              setLoading={setLoading}
            />
          </div>
        </section>
        <ToastContainer />
      </>
    );
  }
}
/////----------- activate plans/ deactivate plans --///
const ActivatedDeactivated = ({ id, planStatus, name }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(
    planStatus === "Active" ? true : false
  );
  // console.log(id);
  const handleClose = () => setOpen(false);
  const handleChange = (e) => {
    // e.target.checked;
    setOpen(true);
  };
  // handle api response
  const handleClick = () => {
    setLoading(true);
    axios
      .patch(
        API_CONSTANTS.BASEURL_4 +
          `admin/corporate/updateAdmin/${id.Id}?changeStatus=true`,
        {
          corporateStatus: checked ? "inActive" : "Active",
          // companyName: name,
        },
        { headers: API_CONSTANTS.headers() }
      )
      .then((res) => {
        setLoading(false);
        setChecked(!checked);
        setOpen(false);
        toast(`Status changed successfully`, {
          type: "success",
        });
      })
      .catch((erro) => {
        setLoading(false);
        toast(`Something goes wrong try again`, {
          type: "error",
        });
      });
  };
  return (
    <div>
      <Switch checked={checked} onChange={handleChange} />
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles}>
          <div className={style.plansModalContainer}>
            {checked ? (
              <CancelIcon color="error" style={{ fontSize: "2rem" }} />
            ) : (
              <CheckCircleIcon color="success" style={{ fontSize: "2rem" }} />
            )}
            <p
              className={style.modalTextError}
              style={{
                color: checked ? " #af1300" : "#1E1E1E",
              }}
            >
              {checked ? "Deactivate Service" : "Activate Service"}
            </p>
            <p className={style.modalText}>
              {checked
                ? `Are you sure you want to Deactivate the status for ${name}?`
                : `Are you sure you want to active the status for ${name}?`}
            </p>
            <div className={style.actionBtnModal}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                className={style.actionBtn}
              >
                Close
              </Button>
              <LoadingButton
                variant="contained"
                color={checked ? "error" : "success"}
                size="small"
                onClick={handleClick}
                className={style.actionBtn}
                loading={loading}
                // loadingPosition="end"
              >
                {checked ? "Deactivate" : "Activate"}
              </LoadingButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
