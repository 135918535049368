import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_CONSTANTS } from '../../../../../../API_CONSTANTS'
// API calling
export const getSession = createAsyncThunk('getSession', async (paramsId) => {
  const response = await axios
    .get(`${API_CONSTANTS.BASEURL_3}admin/nurses/${paramsId}/sessions`, {
      headers: API_CONSTANTS.headers(),
    })
    .then((res) => res.data.response)
  return response
})
// handling Slice
export const getNurseSessionSlice = createSlice({
  name: 'getSession',
  initialState: {
    value: {
      // chat: '',
      chatAppointmentFee: '',
      duration: 60,
      friday: false,
      fridaySlots: [],
      monday: false,
      mondaySlots: [],
      saturday: false,
      saturdaySlots: [],
      schedule: 7,
      sunday: false,
      sundaySlots: [],
      thursday: false,
      thursdaySlots: [],
      tuesday: false,
      tuesdaySlots: [],
      // video: '',
      videoAppointmentFee: '',
      wednesday: false,
      wednesdaySlots: [],
    },
  },
  reducers: {},
  extraReducers: {
    [getSession.pending]: (state) => {
      state.loading = true
    },
    [getSession.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.value = payload
    },
    [getSession.rejected]: (state) => {
      state.loading = false
    },
  },
})

export const getNurseSessionReducer = getNurseSessionSlice.reducer
