import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

// reducer function
function filterReducer(state, action) {
  switch (action.type) {
    case "LOADING_TRUE": {
      return {
        ...state,
        loading: true,
      };
    }
    case "LOADING_FALSE": {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
        [action.target.name]: action.target.value,
      };
    }
  }
}

//Doctor related filter search form
export default function ReviewFilterForm() {
  const filter_status = {
    status: useSelector((state) => state.ReviewFilterReducer.value),
    reviewBy: useSelector((state) => state.ReviewFilterReducer.value1),
    reviewDate: useSelector((state) => state.ReviewFilterReducer.value2),
    reviewDateFrom: useSelector((state) => state.ReviewFilterReducer.value3),
  };
  const [formData, setFormDataDispatch] = React.useReducer(filterReducer, {
    status: filter_status.status,
    reviewDateFrom: filter_status.reviewDateFrom,
    reviewDate: filter_status.reviewDate,
    reviewBy: filter_status.reviewBy,
    loading: false,
  });
  const { status, reviewDateFrom, reviewDate, reviewBy, loading } = formData;

  const dispatch = useDispatch();
  //
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormDataDispatch({ type: "LOADING_TRUE" });
    if (status !== null) {
      dispatch({
        type: "reviewFilter/" + status,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
    if (reviewBy !== null) {
      dispatch({
        type: "reviewFilter/" + reviewBy,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
    if (reviewDateFrom !== null) {
      dispatch({
        type: "reviewFilter/reviewDateFrom",
        payload: reviewDateFrom,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
    if (reviewDate !== null) {
      dispatch({
        type: "reviewFilter/reviewdate",
        payload: reviewDate,
      });
      setTimeout(() => {
        setFormDataDispatch({ type: "LOADING_FALSE" });
        dispatch({ type: "tableFilterModal/setHideModal" });
      }, 1000);
    }
  };

  return (
    <>
      <div style={{ marginTop: "-34px", float: "right" }}>
        <button
          className="btn btn-hover"
          style={{ color: "#009B93", fontSize: 16 }}
        >
          <i className="fas fa-redo"></i>
        </button>
      </div>

      <form onSubmit={handleSubmit} className="mt-4 p-2">
        <div className="form-group">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Status
          </label>
          <select
            className="form-control filter-input"
            onChange={setFormDataDispatch}
            name="status"
            value={status}
          >
            <option value="all">All</option>
            <option value="approve">Approve</option>
            <option value="notapprove">Not Approve</option>
          </select>
        </div>
        <div class="form-group mt-3">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Review By
          </label>
          <select
            className="form-control"
            onChange={setFormDataDispatch}
            name="reviewBy"
            value={reviewBy}
          >
            <option value="allreview">All</option>
            <option value="doctors">Review By Doctors</option>
            <option value="patients">Review By patients</option>
          </select>
        </div>
        <div className="form-group mt-3">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Date From
          </label>
          <input
            onChange={setFormDataDispatch}
            name="reviewDateFrom"
            type="date"
            className="form-control filter-input"
            value={reviewDateFrom}
          />
        </div>
        <div className="form-group mt-3">
          <label for="exampleFormControlSelect1" style={{ fontWeight: 400 }}>
            Date To
          </label>
          <input
            onChange={setFormDataDispatch}
            name="reviewDate"
            type="date"
            className="form-control filter-input"
            value={reviewDate}
          />
        </div>
        <button className="btn mt-3 filter-form-button">
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: "20px" }}
            />
          )}{" "}
          {loading || "Apply"}
        </button>
      </form>
      <style jsx>{`
        .filter-form-button {
          background: #009b93;
          color: #fff;
          width: 100%;
        }
        .filter-form-button:hover {
          color: #fff;
          background: #009b93 !important;
        }
        .filter-input:focus {
          border-color: #009b93 !important;
          box-shadow: 0 0 0 0.2rem rgba(0, 155, 148, 25%) !important;
        }
        select option:hover {
          background: #009b93 !important;
        }
        .btn-hover:hover {
          background: #eee;
        }
      `}</style>
    </>
  );
}
