export const PROFILE_NAV_LINKS = [
  {
    navLink: "Specializations",
    navRoute: `/adddata/specializations`,
  },
  {
    navLink: "Conditions Treated",
    navRoute: `/adddata/conditions`,
  },
  {
    navLink: "Services",
    navRoute: `/adddata/services`,
  },
  {
    navLink: "Medicine",
    navRoute: `/adddata/medicine`,
  },
  {
    navLink: "Subscription Plan",
    navRoute: `/adddata/subscription`,
  },
  {
    navLink: "Nursing Services",
    navRoute: `/adddata/nurseservice`,
  },
  {
    navLink: "Health History",
    navRoute: `/adddata/healthHistory`,
  },
  {
    navLink: "Promo Code",
    navRoute: `/adddata/promo`,
  },
  {
    navLink: "Send Notifications",
    navRoute: `/adddata/sendnotifications`,
  },
];
